import { Component, Input } from "@angular/core";
import { MdmService } from '@omni/services/mdm/mdm.service';
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { NewChangeRequestComponent } from "@omni/components/mdm/new-change-request/new-change-request";
import { CrDetailsComponent } from "@omni/components/mdm/contact/cr-details/cr-details";
import { DeviceService } from "@omni/services/device/device.service";
import { UIService } from '@omni/services/ui/ui.service';
import { DynamicFormType } from '@omni/models/dynamic-form-component.model';
import { BusinessProcessType, DynamicForm, FormType } from '@omni/classes/dynamic-form/dynamic-form.class';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ChangeRequestStatusCode, ChangeRequestType, MDMRequestType } from "@omni/classes/mdm/source-type-optionset.class";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { NavParams } from "@ionic/angular";
import { IntegrationType } from "@omni/classes/onekey/ok-integration.class";
import { AuthenticationService } from "@omni/services/authentication.service";
import { DatePipe } from "@angular/common";
import { ConsentService } from "@omni/services/consent/consent.service";


@Component({
  selector: 'contact-cr',
  templateUrl: 'contact-cr.html',
  styleUrls: ['contact-cr.scss']
})
export class ContactCrComponent {

  @Input() source: any[];
  public selected: any = null;


  constructor(
    public mdmService: MdmService,
    private navService: NavigationService,
    private device: DeviceService,
    private uiService: UIService,
    private dynamicFormsService: DynamicFormsService,
    private notificationService: NotificationService,
    public translate: TranslateService,
    private contactService: ContactOfflineService,
    public navParams: NavParams,
    private authService: AuthenticationService,
    private readonly datePipe: DatePipe,
    private consentService: ConsentService
  ) {
  }

  ngOnInit() {
    if (this.navParams && this.navParams.data && this.navParams.get('openedToolFromCrDetails') && this.navParams.get('openedToolFromCrDetails') === true) {
      const selectedCrContact = this.contactService.selectedContactCr;
      if (selectedCrContact) this.viewDetails(selectedCrContact);
    }
  }

  async viewDetails(selected) {
    let currentData;
    let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.DISPLAYFORM, selected['indskr_mdm'] == BusinessProcessType.SanofiChina ? BusinessProcessType.SanofiChina : undefined);;
    if (!contactForm) {
      this.notificationService.notify(this.translate.instant('ONE_KEY_DISPLAY_FORM_NO_FORMS_ERR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
      this.uiService.dismissLoader();
      return;
    }
    if (this.device.isOffline) {
      //To add lookup field data in formatted way for Display form
      const lookupfieldsForSelectedContact = selected['lookupfields'];
      console.log(lookupfieldsForSelectedContact);

      if(lookupfieldsForSelectedContact){
        lookupfieldsForSelectedContact.forEach((lookupfield)=>{

          let customKey = `_${lookupfield['name']}_value`;
          selected[customKey]= lookupfield['id'];
        })
      }
      //To add mdm as contactsourcetype value 
      selected['indskr_contactsourcetype']= 548910001;
      selected['indskr_contactsourcetype@OData.Community.Display.V1.FormattedValue']=  "MDM";
      selected['indskr_contactsourcetype_Formatted']= "MDM";
      
      currentData = selected;
      console.log(currentData);
    } else {
      await this.uiService.displayLoader();
      try {
        let previousStatus = selected.statuscode;
        await this.mdmService.fetchContactCRConfiguredDisplay(false, false, selected).then((res) => {

          if (res && Array.isArray(res)) {
            currentData = res[0];
          } else {
            currentData = res;
          }

          let bannerMessage = ""
          let btnText = ""
          if (currentData.statuscode !== previousStatus) {
            if (currentData.statuscode === ChangeRequestStatusCode.APPROVED) {
              bannerMessage = this.translate.instant('BUSINESS_CHNAGE_REQUEST_APPROVED_SUCCESSFULLY');
              btnText = "VIEW";
            } else if (currentData.statuscode === ChangeRequestStatusCode.REJECTED || currentData.statuscode === ChangeRequestStatusCode.PENDING_APPROVAL) {
              bannerMessage = this.translate.instant('BUSINESS_CHNAGE_REQUEST_NOT_APPROVED');
              if (currentData['indskr_mdmrequesttype'] === MDMRequestType.UPDATE_EMAIL) {
                bannerMessage = this.translate.instant('EMAIL_CHNAGE_REQUEST_NOT_APPROVED');
              }
              btnText = "CLOSE";
            } else if (currentData.statuscode === ChangeRequestStatusCode.SUBMITTED) {
              btnText = "CLOSE";
              if (currentData.indskr_contactsourcetype == ChangeRequestType.MDM)  {
                bannerMessage = this.translate.instant('ONE_KEY_CHANGE_REQUEST_SUCCESSFULLY_SUBMITTED');
              } else {
                bannerMessage = this.translate.instant('BUSINESS_CHANGE_REQUEST_CREATE_CONTACT_SUCCESSFULL');
              }
            }
            let viewContactButtonClickedInBanner: () => void = () => {
              if (currentData.statuscode === ChangeRequestStatusCode.APPROVED) {
                this.mdmService.chnagerequestApproved$.next(true);
              }
            }
            this.notificationService.notify(bannerMessage, 'Change request', 'top', ToastStyle.INFO, 3000, true,this.translate.instant(btnText), viewContactButtonClickedInBanner);
          }
          console.log(currentData);
          this.uiService.dismissLoader();
        });
      } catch (e) {
        console.log("error occurred fetching contactCR online: ", e);
        await this.uiService.dismissLoader();
      }

    }
    this.selected = currentData;
    if (this.selected && this.selected.indskr_contactcrid) {
      this._highlightContactCr(this.selected.indskr_contactcrid);
    }
    this.mdmService.currentSelected.next(currentData);
    this.uiService.activeView = 'ContactPageRightPaneNav';
    this.uiService.showNewActivity = false;
    this.navService.setChildNavRightPaneView(true);
    await this.navService.setChildNavRoot(CrDetailsComponent, PageName.CrDetailsComponent, PageName.ContactPageComponent, {
      from: 'Contact',
      displayFormDetails: contactForm,
      dynamicFormType: DynamicFormType.CONFIGUREDFORM
    });
    
    // To update email address - contact, consent, activites timeline, serach index
    if (!this.device.isOffline && currentData['indskr_mdmrequesttype'] === MDMRequestType.UPDATE_EMAIL 
      && currentData.statuscode != selected.statuscode && currentData.statuscode === ChangeRequestStatusCode.APPROVED) {
      const contactId = currentData['_indskr_contact_value'] || '';
      
      if (contactId) {
        await this.uiService.displayLoader();
        try {
          await Promise.all([
            this.contactService.fetchContactsForConfiguredDisplay(true, false, contactId, false),
            this.consentService.updateConsentActivityEmailByContactId(contactId),
            this.consentService.getAllConsentActivitiesForProductsAndEmails(false)
          ]);
          console.log("Email address is updated based on approved Email CR");
          await this.uiService.dismissLoader();
        } catch (error) {
          console.error("Email address update error: ", error);
          await this.uiService.dismissLoader();
        }
      }
    }

  }
  private async _highlightContactCr(selectedContactCrId: string) {
    const contactCrItem = document.getElementById(selectedContactCrId);
    if (contactCrItem) {
        document.getElementById(selectedContactCrId).scrollIntoView(false);
    }
  }

  getDisplayDate(value): string {
    return this.datePipe.transform(value, 'MMM dd yyyy', undefined, this.translate.currentLang);
  }

  getMDMType(item): string {
    return item.indskr_mdmrequesttype === 548910000 ? `${this.translate.instant('CONTACT_CR_NEW')}` : item.indskr_mdmrequesttype === 808210000 ? this.translate.instant('ADD_AFFILIATION') : `${this.translate.instant('CONTACT_CR_UPDATE')}`;
  }
}
