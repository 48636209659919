import { Injectable } from '@angular/core';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { AuthenticationService } from '@omni/services/authentication.service';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '@omni/services/disk/disk.service';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { DB_ALLDOCS_QUERY_OPTIONS, DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS, PREFIX_SEARCH_ENDKEY_UNICODE } from '@omni/config/pouch-db.config';
import { Endpoints } from 'src/config/endpoints.config';
import { DeviceService } from '@omni/services/device/device.service';
import { UIService } from '@omni/services/ui/ui.service';
import { IoFileService } from '@omni/services/io-file-service/io-file-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Guid } from 'typescript-guid';
import _ from 'lodash';
import { BehaviorSubject, firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { NavigationService } from '@omni/services/navigation/navigation.service';
import { Utility } from '@omni/utility/util';
import { CONTRACT_SIGNATURE_MODE, ContractType, KCode, PROCEDURE_CONTRACT_EMAIL_ATTACHEMENTS, PROCEDURE_CONTRACT_HEIRACRCHY, PROCEDURE_CONTRACT_STATUS, PROCEDURE_CONTRACT_TYPES, ProcedureContract } from '@omni/classes/procedure-contract/procedure-contract.class';
import { differenceInDays, differenceInHours, endOfDay, format } from 'date-fns';
import { DeltaService, EntityNames, EntitySyncInfo } from '../delta/delta.service';
import { TrackAction } from '@omni/utility/common-enums';
import { TranslateService } from '@ngx-translate/core';
import { getConfigFieldPickListRequestURL, parseConfigFieldPicklistResponse } from '@omni/utility/common.utility';
import { ConfigFieldOptionResponse, ConfigFieldOptionValue } from '@omni/classes/activity/activity.class';
import { ConfiguredFields } from '@omni/classes/authentication/configured.field.class';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { electronApi } from '@omni/services/electron-api';
import { ResourceEmailTemplate } from '@omni/classes/email-templates/email-template.class';
import { DatePipe } from '@angular/common';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { customerAddress } from '@omni/classes/account/account.class';

export interface FilterOption {
  text: string,
  value: string
}
@Injectable({
  providedIn: 'root'
})
export class ProcedureContractService {
  public procedureContracts: ProcedureContract[] = [];
  public procedureContractsToList: ProcedureContract[] = [];
  public isRightPaneNavActive = false;
  public procedureSignatureTypes = [];
  refreshProcedureContract: BehaviorSubject<ProcedureContract[]> = new BehaviorSubject([]);
  public isProcedureContractCreationActive: boolean = false; // For Discard alert from account tool 
  childContracts: BehaviorSubject<ProcedureContract[]> = new BehaviorSubject([]);
  contractTempid = '';
  public contractTypes: ContractType[] = [];
  public configFieldOptionsValues = [];
  public KCodesData: KCode[] = [];
  public isTimeLineContracts = false;
  private timeLineContracts: BehaviorSubject<ProcedureContract[]> = new BehaviorSubject([]);
  public timeLineContracts$ : Observable<ProcedureContract[]> = this.timeLineContracts.asObservable();

  constructor(
    private disk: DiskService,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    public dynamics: DynamicsClientService,
    private uiService: UIService,
    private dateService: DateTimeFormatsService,
    private deviceService: DeviceService,
    private fileservice: IoFileService,
    public navService: NavigationService,
    private readonly deltaService: DeltaService,
    public translate: TranslateService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private socialSharing: SocialSharing,
    private datePipe: DatePipe,
    private accountService: AccountOfflineService,
  ) { }

  public async fetchProcedureContracts(fullSync, loadFromDBOnly, contractId?) {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT)) return;
    const offlineData = await this.loadProcedureContractsFromLocalDB();

    if (offlineData) {
      this.procedureContracts = offlineData.raw ? offlineData.raw : [];
    }

    if (loadFromDBOnly) { return };

    try {
      const fetchXML = this.buildFetchXML(fetchQueries.salesOrders.fetchProcedureContracts, offlineData, fullSync, contractId);

      await this.dynamics.executeFetchQuery('indskr_procedurecontracts', fetchXML).then(async (response) => {
        this.processProcedureContractResponse(response, fullSync);

        if (this.authenticationService.user.buSettings?.['indskr_expireprocedurecontractsbasedonenddate']) {
          let updatedProc = this.expireContracts(this.procedureContracts);
          this.procedureContracts = updatedProc;
        }
        this.refreshProcedureContract.next(this.procedureContracts);
        this.saveProcedureContractsInLocalDB(this.procedureContracts);
      });
      return true;
    } catch (error) {
      console.log(error);
      this.procedureContracts = offlineData.raw ? offlineData.raw : [];
      return false;
    }
  }

  private buildFetchXML(baseFetchXML: string, offlineData: any, fullSync: boolean, contractId: string | null): string {
    let fetchXML = baseFetchXML;
    let deltaFilter = '';

    if (offlineData?.lastModified) {
      const modifiedon = this.dateService.formatDateForFetchXML(offlineData.lastModified);
      deltaFilter = `<condition attribute="modifiedon" operator="ge" value="` + modifiedon + `"/>`;
    }

    fetchXML = fetchXML.replace('{deltaSyncFilter}', !fullSync ? deltaFilter : '');

    if (this.authenticationService.user.procedureContractConfiguredFields.length) {
      let configFields = '';
      this.authenticationService.user.procedureContractConfiguredFields.forEach((configField) => {
        configFields = configFields.concat(`<attribute name="${configField.fieldName}" />`);
      });
      fetchXML = fetchXML.replace('{configFields}', configFields);
    }

    let kcodeattributes = '';
    if (this.contractTypes?.some(a => a.indskr_displaykcodefields)) {
      kcodeattributes = `
        <attribute name="jnjjapan_kcode_1" />
        <attribute name="jnjjapan_kcode_2" />
        <attribute name="jnjjapan_kcode_3" />
        <attribute name="jnjjapan_otherkcodename" />
      `;
    }

    fetchXML = fetchXML.replace('{KCodeFields}', kcodeattributes);
    fetchXML = fetchXML.replace('{accountId}', '');

    if (contractId) {
      // realTimeFilter
      fetchXML = fetchXML.replace('{realTimeFilter}', this.getRealTimeSynFilter(contractId));
    }

    return fetchXML;
  }

  private processProcedureContractResponse(response: any[], fullSync: boolean) {
    if (fullSync && !response.length) this.procedureContracts = [];

    if (response?.length) {
      let procedureContracts = this.mapContracts(response);
      // response.forEach((contract) => {
      //   let tempContract = new ProcedureContract(contract);
      //   tempContract.configuredFields = this.mapConfigFieldValuesToContract(contract);
      //   procedureContracts.push(tempContract);
      // });

      // procedureContracts = _.unionBy(procedureContracts, (procedureContract: ProcedureContract) => procedureContract.indskr_procedurecontractid);
      if (fullSync || !this.procedureContracts.length) this.procedureContracts = procedureContracts;

      if (!fullSync && this.procedureContracts.length > 0) {
        procedureContracts.forEach(procedureContract => {
          const index = this.procedureContracts.findIndex(({ indskr_procedurecontractid }) => indskr_procedurecontractid === procedureContract.indskr_procedurecontractid);
          if (index < 0) {
            this.procedureContracts.push(procedureContract);
          } else {
            this.procedureContracts[index] = procedureContract;
          }
        });
      }
    }
  }

  private getRealTimeSynFilter(contractId) {
    return (`
    <filter type="or">
      <condition attribute="indskr_procedurecontractid" operator="eq" value="${contractId}" />
      <condition attribute="indskr_parentprocedurecontractid" operator="eq" value="${contractId}"/>
    </filter>`)
  }

  public async saveProcedureContractsInLocalDB(procedureContracts: ProcedureContract[]) {
    try {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.PROCEDURE_CONTRACT, (doc) => {
        doc = {
          raw: [],
          lastModified: new Date().getTime()
        };
        doc.raw = procedureContracts;
        return doc;
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async loadProcedureContractsFromLocalDB() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_CONTRACT, true).then((doc) => {
        offlineDataStored = doc?.raw ? doc : [];
      });
      if (offlineDataStored) {
        const pendingPushForDynamics = offlineDataStored.raw.some((pr) => pr.pendingPushToDynamics);
        if (pendingPushForDynamics) this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1); // app kill scenario
      }
    }
    catch (er) {
      console.error("Failed to load procedure sub types from local db!: ", er)
      offlineDataStored = [];
    }
    return offlineDataStored;
  }

  public async fetchProcedureContractPositionGroupProducts(fullSync, loadFromDBOnly) {
    if (this.deviceService.isOffline || !this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT)) return;
    let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_CONTRACT_POSITION_GROUP_PRODUCTS);
    let lastUpdatedTime;
    if (offlineData?.raw && !fullSync) {
      if (offlineData.lastUpdatedTime) {
        lastUpdatedTime = offlineData.lastUpdatedTime;
      }
    } else {
      offlineData = {
        raw: [],
      }
    }
    if (!loadFromDBOnly) {
      let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_PROCEDURE_CONTRACT_POSITION_GROUP_PRODUCTS);
      const bulkPositionGroupProductsSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.procedure_contract_position_group_products,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      const now = new Date().getTime();
      let fetchXML = fetchQueries.salesOrders.fetchProcedureContractPositionGroupProducts;
      let deletedPostionGroupProductfetchXML;
      if (lastUpdatedTime && !fullSync) {
        let hourDifference = differenceInHours(now, new Date(lastUpdatedTime));
        hourDifference += 1
        fetchXML = fetchXML.replace('{deltaSyncFilter}', `'<condition attribute="modifiedon" operator="last-x-hours" value="${hourDifference}"/>`);
        deletedPostionGroupProductfetchXML = fetchQueries.surgeryOrders.fetchDeletedPositiongroupProductMapping;
        deletedPostionGroupProductfetchXML = deletedPostionGroupProductfetchXML.replace('{hourDifference}', `${hourDifference}`);
      } else {
        fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
      }
      fetchXML = fetchXML.replace('{UserID}', this.authenticationService.user.systemUserID);
      let response = await this.dynamics.executeFetchQuery('indskr_positiongroupses', fetchXML);
      let deletedPostionGroupProducts = [];
      if (deletedPostionGroupProductfetchXML) {
        await this.dynamics.executeFetchQuery('indskr_trackchanges', deletedPostionGroupProductfetchXML).then(res => {
          if (res && res.length > 0) {
            deletedPostionGroupProducts = res;
          }
        }).catch(err => {
          console.log("Error Occured while fetching deleted position group products mappings" + err)
        });
      }
      lastUpdatedTime = new Date().getTime();
      if (response && Array.isArray(response) && response.length != 0 && response[0]) {
        if (deletedPostionGroupProducts && deletedPostionGroupProducts.length > 0) {
          deletedPostionGroupProducts.forEach(deletedMapping => {
            let residx = response.findIndex(a => a['positiongroupproduct.indskr_positiongroupproductid'] == deletedMapping['positiongroupproductID']);
            if (residx >= 0) {
              response[residx]['track_action'] = deletedMapping['track_action']
            } else if (deletedMapping['track_action'] == TrackAction.Deleted) {
              let idx = offlineData.raw.findIndex(a => a['positiongroupproduct.indskr_positiongroupproductid'] == deletedMapping['positiongroupproductID']);
              if (idx >= 0) {
                offlineData.raw.splice(idx, 1);
              }
            }
          })
        }
        bulkPositionGroupProductsSyncInfo.totalSynced += response.length;
        if (offlineData?.raw) {
          response.forEach(item => {
            let idx = offlineData.raw.findIndex(a => a['positiongroupproduct.indskr_positiongroupproductid'] == item['positiongroupproduct.indskr_positiongroupproductid']);
            if (idx >= 0) {
              if (item['positiongroupproduct.statecode'] == 0 && item['product.statecode'] == 0 && item['track_action'] != TrackAction.Deleted) {
                offlineData.raw[idx] = item;
              } else {
                offlineData.raw.splice(idx, 1);
              }
            } else if (item['positiongroupproduct.statecode'] == 0 && item['product.statecode'] == 0 && item['track_action'] != TrackAction.Deleted) {
              offlineData.raw.push(item);
            }
          });
          offlineData.lastUpdatedTime = lastUpdatedTime;
        } else {
          offlineData = {
            raw: response.filter(item => item['positiongroupproduct.statecode'] == 0 && item['product.statecode'] == 0),
            lastUpdatedTime: lastUpdatedTime,
          }
        }
        syncState.lastUpdatedTime = lastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        this.deltaService.addEntitySyncInfo(bulkPositionGroupProductsSyncInfo);
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.PROCEDURE_CONTRACT_POSITION_GROUP_PRODUCTS, doc => {
          doc = offlineData;
          return doc;
        }).catch(error => {
          console.error('Save procedure contract position group product data in offline db error: ', error) 
        });
      }
    }
  }

  //? To enable procedure contract field in procedure log(surgery-order-info)
  public areProcedureContractFieldsFilled(surgeryOrder: SurgeryOrderActivity): boolean {
    if (!surgeryOrder.accountId ||
      !surgeryOrder.indskr_proceduretype ||
      !this.procedureContracts.length) return false;
    return true;
  }

  //? list procedure contract that satisfies the criteria
  public async setProcedureContractsToList(surgeryOrder: SurgeryOrderActivity, contractType: ContractType, isProcedureSubtypeAvailable: boolean) {
    try {
      this.procedureContractsToList = this.procedureContracts.filter((procedureContract) => {
        if (contractType.indskr_allowchildcontractcreation &&
          contractType.indskr_procedurelogassociation !== PROCEDURE_CONTRACT_HEIRACRCHY.CHILD &&
          procedureContract.indskr_parentprocedurecontractid) {
          return false;
        } else if (contractType.indskr_allowchildcontractcreation &&
          contractType.indskr_procedurelogassociation === PROCEDURE_CONTRACT_HEIRACRCHY.CHILD &&
          !procedureContract.indskr_parentprocedurecontractid) {
          return false;
        } else {
          return this.isContractMeetCriteria(procedureContract, surgeryOrder, contractType, isProcedureSubtypeAvailable);
        }
      });
    } catch (error) {
      console.log(error);
      this.procedureContractsToList = [];
    }
  }

  public isSurgeryOutsideContractPeriod(contractStartDate, contractEndDate, surgeryOrderStartDate) {
    return surgeryOrderStartDate < contractStartDate || surgeryOrderStartDate > contractEndDate;
  }

  public isProcedureLogApplicable(contractType: ContractType, procedureContract: any) {
    const hasApplicability = contractType.procedureLogApplicability.length > 0;
    if (!hasApplicability && procedureContract.statuscode !== PROCEDURE_CONTRACT_STATUS.ACTIVE) return false;
    if (hasApplicability && !contractType.procedureLogApplicability.includes(procedureContract.statuscode.toString())) return false;
    return true;
  }

  public isContractMeetCriteria(procedureContract: ProcedureContract, surgeryOrder: SurgeryOrderActivity, contractType: ContractType, isProcedureSubtypeAvailable: boolean): boolean {
    const startDate = this.dateService.formatDateForWithStartTime(procedureContract.indskr_startdate).getTime();
    const endDate = this.dateService.formatDateForWithEndTime(procedureContract.indskr_enddate).getTime();
    const isFreeProcedureType = contractType.indskr_usagetype === PROCEDURE_CONTRACT_TYPES.FREE_CONTRACT;
    let isCriteriaMet = true;

    if (procedureContract.indskr_account_value !== surgeryOrder.accountId) return false;
    if (procedureContract.indskr_contracttypes !== contractType.indskr_contracttypeid) return false;
    if (procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT) return false;
    if (!this.isProcedureLogApplicable(contractType, procedureContract)) return false;
    if (isFreeProcedureType && procedureContract.indskr_noofassistanceavailed === procedureContract.indskr_maximumnoofassistance) return false;
    if (this.isSurgeryOutsideContractPeriod(startDate, endDate, surgeryOrder.scheduledStart.getTime())) return false;
    // if (isProcedureSubtypeAvailable && !surgeryOrder.indskr_proceduresubtype) return false;
    // if (surgeryOrder.indskr_proceduresubtype && surgeryOrder.indskr_proceduresubtype !== procedureContract.indskr_proceduresubtype) return false;

    // if (contractType.indskr_allowchildcontractcreation && contractType.indskr_procedurelogassociation === PROCEDURE_CONTRACT_HEIRACRCHY.PARENT) {
    //   const month = surgeryOrder.scheduledStart.getMonth();
    //   const year = surgeryOrder.scheduledStart.getFullYear();

    //   const summaryReports =  this.summaryReportExistFortheMonth(procedureContract, month, year);
    //   if (summaryReports.length == 0) {
    //     isCriteriaMet = false;
    //   } 
    // }

    return isCriteriaMet;
  }

  public summaryReportExistFortheMonth(procedureContract: ProcedureContract, month, year) {
   return this.procedureContracts.filter((pc) => {
      if (pc.indskr_parentprocedurecontractid &&
        pc.indskr_parentprocedurecontractid === procedureContract.indskr_procedurecontractid &&
        new Date(pc.indskr_startdate).getMonth() === month && new Date(pc.indskr_startdate).getFullYear() === year
      ) {
        return pc
      }
    });
  }

  public generateContract(accountId, accountName, parentContract = null) {
    let customerAddresses = this.accountService.customerAddresses;
    let addressDetails = customerAddresses.filter((address: customerAddress) => address.accountId ===accountId);
    let newContract: any = {
      indskr_account_value: accountId,
      accountName: accountName,
      indskr_maximumnoofassistance: 0,
      indskr_noofassistanceavailed: 0,
      indskr_procedurecontractid: Guid.create().toString(),
      indskr_name: this.translate.instant('PROCEDURE_CONTRACT'),
      business_unit: this.authenticationService.user.xBusinessUnitId,
      statecode: 0
    }

    if (parentContract) {
      newContract = {
        ...newContract,
        indskr_product_value: parentContract.indskr_product_value,
        indskr_proceduretype: parentContract.indskr_proceduretype,
        indskr_procedure_value: parentContract.indskr_procedure_value,
        accountName: parentContract.accountName,
        productName: parentContract.productName,
        procedureName: parentContract.procedureName,
        procedureTypeString: parentContract.procedureTypeString,
        parentProcedureContractName: parentContract.indskr_name,
        indskr_name: parentContract.indskr_name,
        indskr_maximumnoofassistance: parentContract.indskr_maximumnoofassistance,
        specialityId: parentContract.specialityId,
        specialityName: parentContract.specialityName,
        customerAddress: parentContract.customerAddress,
        customerAddressName: parentContract.customerAddressName,
        indskr_contracttypes: parentContract.indskr_contracttypes,
        contractTypeString: parentContract.contractTypeString,
        indskr_parentprocedurecontractid: parentContract.indskr_procedurecontractid,
        indskr_productid: parentContract.indskr_productid
      }
    }
   
    if (addressDetails.length == 1) {
      addressDetails.forEach(adress => {
        newContract = {
          ...newContract,
          indskr_Address: adress.customerAddressId,
          customerAddressName: adress.customerAddressString
        }
      })
    }
  
    return newContract;
  }

  public CreateProcedureContractInLocal(contract) {
    this.procedureContracts.push(contract);
    this.refreshProcedureContract.next(this.procedureContracts);
    this.saveProcedureContractsInLocalDB(this.procedureContracts);
    if (contract.indskr_parentprocedurecontractid) {
      this.setChildContracts(contract.indskr_parentprocedurecontractid);
    }
  }

  public updateProcedureContract(contractId, updatedValue) {
    const index = this.procedureContracts.findIndex(({ indskr_procedurecontractid }) => indskr_procedurecontractid === contractId);
    if (index >= 0) {
      this.procedureContracts[index] = {
        ...this.procedureContracts[index],
        ...updatedValue
      }
    }
    this.refreshProcedureContract.next(this.procedureContracts);
    this.saveProcedureContractsInLocalDB(this.procedureContracts);
    if (this.isTimeLineContracts) {
      this.updateTimelineProcedureContracts(contractId, updatedValue);
    }
  }

  public findProcedureContractById(contractId: any) {
    if (!this.procedureContracts.length) return;
    return this.procedureContracts.find((procedureContract) => procedureContract.indskr_procedurecontractid === contractId);
  }

  public findChildProcedureContractByDate(startDate, parentContractId) {
    if (!this.procedureContracts.length) return;
    const month = new Date(startDate).getMonth();
    const year = new Date(startDate).getFullYear();

    return this.procedureContracts.filter((procedureContract) => {
      if (procedureContract.indskr_parentprocedurecontractid &&
        parentContractId === procedureContract.indskr_parentprocedurecontractid &&
        new Date(procedureContract.indskr_startdate).getMonth() === month && new Date(procedureContract.indskr_startdate).getFullYear() === year) {
        return procedureContract
      }
    });
  }

  public findSummaryReportByContractId(parentContractId) {
    return this.procedureContracts.filter((procedureContract) => {
      if (procedureContract.indskr_parentprocedurecontractid &&
        parentContractId === procedureContract.indskr_parentprocedurecontractid) {
        return procedureContract
      }
    });
  }

  public async getSurgeryInfoAssociatedWithContract(contractId: any, isDuplicateChildContracts: boolean, startDate?, endDate?) {
    let condition = '';
    let fetchXML = fetchQueries.salesOrders.fetchAssociatedSurgeryOrder;

    if (contractId && !isDuplicateChildContracts) {
      condition += `<condition attribute="indskr_procedurecontract" operator="eq" value="${contractId}" />`
    }

    if (contractId && isDuplicateChildContracts) {
      let contractIdString = '';
      let contractIds = typeof contractId === "string" ? [contractId] : contractId;
      contractIds.forEach(p => contractIdString += '<value>' + p + '</value>');

      condition += `<condition attribute="indskr_procedurecontract" operator="in">  
        ${contractIdString}
        </condition>`
    }

    if (startDate && endDate) {
      startDate = this.dateService.formatDateForFetchXML(startDate);
      endDate = this.dateService.formatDateForFetchXML(endDate);

      condition += `
       <condition attribute="indskr_scheduleddate" operator="on-or-after" value="${startDate}" />
      <condition attribute="indskr_scheduledenddate" operator="on-or-before" value="${endDate}" />`
    }

    fetchXML = fetchXML.replace('{condition}', condition);
    return await this.dynamics.executeFetchQuery('salesorders', fetchXML).catch((error) => {
      console.log('Error while fetching proceudure log associated with procedure contract', error)
    });
  }

  public async UploadOfflineProcedureContracts(): Promise<void> {
    let offlineProcedureContracts = await this.loadProcedureContractsFromLocalDB();
    let payload;
    if (Array.isArray(offlineProcedureContracts?.raw) && offlineProcedureContracts.raw.length !== 0) {
      payload = offlineProcedureContracts.raw.filter(item => item.pendingPushToDynamics).map(proceduerContract => {
        return this.createNewContractOfflinePayload(proceduerContract);
      });
      if (!payload || payload.length === 0) return;
      try {
        const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions() ?? {};
        const httpOptions = {
          headers: new HttpHeaders({ 'X-Zone-Id': timeZone })
        }
        const url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.procedureContract.UPLOAD_OFFLINE_PROCEDURE_CONTRACT;
        let response: any = [];
        response = await firstValueFrom(this.http.patch(url, payload, httpOptions));;
        if (response) {
          response.forEach(res => {
            offlineProcedureContracts.raw.forEach(element => {
              element.pendingPushToDynamics = false;
            });
          })
        }
        this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 0);
        this.procedureContracts = offlineProcedureContracts.raw;
        this.saveProcedureContractsInLocalDB(offlineProcedureContracts.raw);
      } catch (error) {
        console.log('contract error', error);
      }
    }
  }

  public generateContractName(procedureContract: ProcedureContract) {
    let name: string;
    const { indskr_startdate, accountName, contractTypeString, indskr_parentprocedurecontractid } = procedureContract;

    if (indskr_startdate && indskr_parentprocedurecontractid) {
      const startDate = new Date(indskr_startdate);
      name = `${format(startDate, 'MMMMYYYY')}`;
    }

    if (accountName) {
      name = name ? name + ' - ' + accountName : accountName;
    }

    if (contractTypeString) name = name + ' - ' + contractTypeString;
    return name;
  }

  public async createProcedureContractOnline(payload: object) {
    try {
      const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions() ?? {};
      const httpOptions = {
        headers: new HttpHeaders({ 'X-Zone-Id': timeZone })
      }
      const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.procedureContract.CREATE_NEW_PROCEDURE_CONTRACT;
      const response = await firstValueFrom(this.http.post(url, payload, httpOptions));
      return response;
    } catch (error) {
      console.log(error);
      throw error
    }
  }

  public createNewContractOfflinePayload(proceduerContract: ProcedureContract) {
    try {
      let payload: any = this.initializePayload(proceduerContract);

      payload = this.addConfiguredFields(payload, proceduerContract);
      payload = this.addNewContractProperties(payload, proceduerContract);
      payload = this.addKCodeFields(payload, proceduerContract);

      return payload;
    } catch (error) {
      console.log('Error while creating payload for procedure contract');
      return null;
    }
  }

  private initializePayload(proceduerContract: ProcedureContract): any {
    const payload: any = {
      indskr_procedurecontractid: proceduerContract.indskr_procedurecontractid,
      indskr_account_value: proceduerContract.indskr_account_value,
      business_unit: this.authenticationService.user.xBusinessUnitId
    };

    const dateFields = [
      'indskr_enddate',
      'indskr_startdate',
      'indskr_postsurgerysignaturecapturedate',
      'indskr_presurgerysignaturecapturedate'
    ];

    const differentKeyFields = {
      'indskr_proceduresubtype': 'indskr_proceduresubtypes',
    };

    const excludeFields = [
      'accountName', 'productName', 'procedureName', 'procedureTypeString',
      'proceudreSubTypeString', 'pendingPushToDynamics', 'parentProcedureContractName',
      'specialityName', 'customerAddressName', 'contractTypeString', 'isNew',
      'contactName', 'statusString', 'configuredFields', 'k_code_one_id',
      'k_code_one_name', 'k_code_other_name', 'k_code_three_id',
      'k_code_three_name', 'k_code_two_id', 'k_code_two_name'
    ];

    Object.keys(proceduerContract).forEach((key) => {
      if (!excludeFields.includes(key) &&
        ((typeof proceduerContract[key] === 'number' && proceduerContract[key] >= 0) || proceduerContract[key])) {
        let payloadKey = differentKeyFields[key] || key;
        payload[payloadKey] = dateFields.includes(key) ? new Date(proceduerContract[key]).getTime() : proceduerContract[key];
      }
    });

    return payload;
  }

  private addConfiguredFields(payload: any, proceduerContract: ProcedureContract) {
    if (proceduerContract.configuredFields) {
      payload['appconfigfields'] = [];
      Object.keys(proceduerContract.configuredFields).forEach((key) => {
        let configuredFieldExceptions = ['indskr_lu_specialty', 'indskr_product', 'indskr_productid', 'indskr_address', 'indskr_contact'];
        let configuredField = this.authenticationService.user.procedureContractConfiguredFields.find((configField) => {
          return configField.fieldName === key && !configuredFieldExceptions.some(field => field == key);
        });
        if (configuredField && proceduerContract.configuredFields[key] != null) {
          let generatedPayload = this.generatePayloadForConfiguredFields(
            proceduerContract.configuredFields[key],
            configuredField.fieldName,
            configuredField.fieldType
          );
          payload['appconfigfields'].push(generatedPayload);
        }
      });
    }

    return payload;
  }

  private addNewContractProperties(payload: any, proceduerContract: ProcedureContract) {
    if (proceduerContract.isNew) {
      payload = { ...payload, offline_procedurecontractid: "offline_" + new Date().getTime().toString() };

      const contractType = this.findContractTypeById(proceduerContract.indskr_contracttypes);
      if (contractType?.indskr_allowduplicatechildcontractcreation) {
        payload = { ...payload, isDuplicateContractAllowed: true };
      }
    }

    return payload;
  }

  private addKCodeFields(payload: any, proceduerContract: ProcedureContract) {
    if (proceduerContract.k_code_one_id || proceduerContract.k_code_two_id || proceduerContract.k_code_three_id || proceduerContract.k_code_other_name) {
      if (!payload.appconfigfields) {
        payload['appconfigfields'] = [];
      }
      if (proceduerContract.k_code_one_id) {
        payload['appconfigfields'].push(this.getServicePayloadForKCodeField('jnjjapan_kcode_1', proceduerContract));
      }
      if (proceduerContract.k_code_two_id) {
        payload['appconfigfields'].push(this.getServicePayloadForKCodeField('jnjjapan_kcode_2', proceduerContract));
      }
      if (proceduerContract.k_code_three_id) {
        payload['appconfigfields'].push(this.getServicePayloadForKCodeField('jnjjapan_kcode_3', proceduerContract));
      }
      if (proceduerContract.k_code_other_name) {
        payload['appconfigfields'].push(this.getServicePayloadForKCodeField('jnjjapan_otherkcodename', proceduerContract));
      }
    }

    return payload;
  }

  public getContentTypeFromBase64(base64String: string) {
    if (base64String) {
      const regex = /^data:(.+?);base64,/;
      const matches = regex.exec(base64String);
      return matches ? matches[1] : null;
    }
  }

  public async downloadContract(url: string) {
    try {
      if (this.deviceService.deviceFlags.electron) {
        return await this.downloadInElectron(url);
      } else {
        return await this.downloadInOtherDevices(url);
      }
    } catch (error) {
      return false;
    }
  }

  private async downloadInElectron(url: string): Promise<any> {
    return await this.fileservice.downloadInElectron(url);
  }

  private async downloadInOtherDevices(url: string): Promise<string | void> {
    const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions() || {};

    const httpOptions = {
      headers: new HttpHeaders({ 'X-Zone-Id': timeZone }),
      responseType: 'text' as const,
      observe: 'response' as const
    };

    const resp = await firstValueFrom(this.http.get(url, httpOptions));
    if (!resp) return;

    const base64String = resp.body;
    const fileName = this.fileservice.extractFileNameFromResponse(resp.headers.get('Content-Disposition'));

    const blob = await this.fileservice.convertBase64ToBlob(base64String, this.getContentTypeFromBase64(base64String));
    return await this.handleDeviceSpecificDownload(blob, base64String, fileName);
  }

  private async handleDeviceSpecificDownload(blob: Blob, base64String: string, fileName: string): Promise<any> {
    if (this.deviceService.isNativeApp) {
      if (this.deviceService.deviceFlags.ios) {
        return await this.handleIOSDownload(blob, fileName);
      } else if (this.deviceService.deviceFlags.android) {
        return await this.fileservice.writeToExternalStoageInAndroid(base64String, fileName);
      }
    } else {
      this.fileservice.downloadBlobFileInBrowser(blob, fileName);
    }
  }

  private async handleIOSDownload(blob: Blob, fileName: string): Promise<string | void> {
    const arrayBuffer = await this.fileservice.convertBlobToArrayBuffer(blob);
    if (arrayBuffer) {
      return this.fileservice.writeFileBufferToSharedDirectory(fileName, arrayBuffer, { replace: false });
    }
  }

  // download pre and post contract doc
  public async generateContractDocumentUrl(contract: ProcedureContract, fileName: string) {
    try {
      let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.procedureContract.DOWNLOAD_CONTRACT_DOC;
      url = url.replace('{{contractId}}', contract.indskr_procedurecontractid);
      url = url.replace('{{fileName}}', encodeURIComponent(fileName));
      return url;
    } catch (error) {
      console.log('error in download', error);
      return null;
    }
  }

  public async generateUrl(contract: ProcedureContract) {
    try {
      let typeConfig =  this.authenticationService.user.ioConfigurations?.find(o=>o.configName=="ProcedureContractCustomFieldsForPDF");
      // const kCodeEnabled = this.authenticationService.user.buSettings?.['indskr_procedurecontracturl'];

      let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.procedureContract.DOWNLOAD_GENERATED_CONTRACT_DOC;
      url = url.replace('{{contractId}}', contract.indskr_procedurecontractid);
      const contractType = this.findContractTypeById(contract.indskr_contracttypes);
      url = url.replace('{{usageType}}', contractType.indskr_usagetype.toString());
      const buid = this.authenticationService.user.xBusinessUnitId;
      url = url.replace('{{buId}}', buid.toString());

      if (typeConfig && typeConfig.configValue && typeConfig.configValue == "Yes") {
        url = url.concat(`&featureFlags=fetchKCode`)
      }

      return url;
    } catch (error) {
      console.log('error in download', error);
      return null;
    }
  }

  public async uploadContractDocument(file, payload, contractId,) {
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("data", JSON.stringify(payload));
      let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.procedureContract.UPLOAD_CONTRACT_DOC;
      url = url.replace('{{contractId}}', contractId);
      const response = await firstValueFrom(this.http.post(url, formData));
      return response;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  public async UpdateProcedureContractOnline(payload: object, procedureContractid: string) {
    try {
      await this.uiService.displayLoader();
      const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions();
      const httpOptions = {
        headers: new HttpHeaders({ 'X-Zone-Id': timeZone })
      }
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.procedureContract.UPDATE_PROCEDURE_CONTRACT
      url = url.replace('{{procedureContractId}}', procedureContractid)
      const response = await lastValueFrom(this.http.patch(url, payload, httpOptions));
      await this.uiService.dismissLoader();
      return response;
    } catch (error) {
      console.log(error);
      await this.uiService.dismissLoader();
      return false;
    }
  }

  public async fetchProcedureSignatureType(loadFromDBOnly) {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT)) return;

    if (loadFromDBOnly) {
      this.procedureSignatureTypes = await this.loadProcedureSignatureTypeFromLocalDB();
      return;
    }

    const response = await this.dynamics.retrieveGlobalOptionSet("Name='indskr_signaturecapturemode'");

    if (response?.Options?.length > 0) {
      let ptypes = response.Options?.map((r) => {
        return ({
          label: r.Label.UserLocalizedLabel.Label,
          value: r.Value
        })
      })
      this.saveProcedureSignatureTypeInLocalDB(ptypes);
      this.procedureSignatureTypes = ptypes;
    }
  }

  private async saveProcedureSignatureTypeInLocalDB(procedureSignatureTypes: any) {
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.PROCEDURE_CONTRACT_SIGNATURE_TYPE, (doc) => {
      doc = {
        raw: [],
        lastModified: doc.lastModified
      };
      doc.raw = procedureSignatureTypes;
      return doc;
    });
  }

  public async loadProcedureSignatureTypeFromLocalDB() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_CONTRACT_SIGNATURE_TYPE, true).then((doc) => {
        offlineDataStored = doc?.raw ? doc.raw : [];
      });
    }
    catch (er) {
      console.error("Failed to load procedure signature types from local db!: ", er)
      offlineDataStored = [];
    }
    return offlineDataStored;
  }

  public findProcedureSignatureTypeById(procedureSignatureId) {
    if (!this.procedureSignatureTypes.length) return;
    return this.procedureSignatureTypes.find((signatureType) => signatureType.value === procedureSignatureId)
  }

  public setChildContracts(parentContractId) {
    let childContracts = this.procedureContracts.filter(({ indskr_parentprocedurecontractid }) => indskr_parentprocedurecontractid && indskr_parentprocedurecontractid === parentContractId)
    this.childContracts.next(childContracts);
  }

  // to update procedure allocated and proceure used after updating crteria fields(date, account)
  public updatePaidMultipleDayContract(contractId, response, procedureLogAssociation) {
    let parentId = contractId;
    const month = new Date(response.indskr_scheduleddate).getMonth();
    const year = new Date(response.indskr_scheduleddate).getFullYear();
    let childContract;

    if (procedureLogAssociation === PROCEDURE_CONTRACT_HEIRACRCHY.CHILD) {
      childContract = this.procedureContracts.find((pc) => pc.indskr_procedurecontractid === parentId);
      if (childContract) {
        parentId = childContract.indskr_parentprocedurecontractid
      }
    } else {
      childContract = this.getChildContractByMonthAndYear(month, year, parentId);
    }

    if (response.indskr_maximumnoofassistance_parent) {
      this.updateProcedureContract(parentId, {
        indskr_maximumnoofassistance: response.indskr_maximumnoofassistance_parent,
        indskr_noofassistanceavailed: response.indskr_noofassistanceavailed_parent
      });
    }

    if (childContract) {
      this.updateProcedureContract(childContract.indskr_procedurecontractid, {
        indskr_maximumnoofassistance: response.indskr_maximumnoofassistance_parent,
        indskr_noofassistanceavailed: response.indskr_noofassistanceavailed_child
      });
    }
  }

  public decrementProcedureUsedCount(contractId) {
    const contract = this.findProcedureContractById(contractId);
    if (contract) {
      this.updateProcedureContract(contractId, {
        indskr_noofassistanceavailed: contract.indskr_noofassistanceavailed - 1
      });
    }
  }

  public incrementProcedureUsedCount(contractId) {
    const contract = this.findProcedureContractById(contractId);
    if (contract) {
      this.updateProcedureContract(contractId, {
        indskr_noofassistanceavailed: contract.indskr_noofassistanceavailed + 1
      });
    }
  }

  public getChildContractByMonthAndYear(month, year, parentId) {
    const childContract = this.procedureContracts.find((procedureContract) => {
      let cmonth = new Date(procedureContract.indskr_startdate).getMonth();
      let cyear = new Date(procedureContract.indskr_startdate).getFullYear();
      if (cmonth === month && cyear === year && procedureContract.indskr_parentprocedurecontractid === parentId) return procedureContract;
    });

    return childContract;
  }

  public generatePayload(params: any) {
    let payload = {};
    const excludeFields = [
      'accountName',
      'productName',
      'procedureName',
      'procedureTypeString',
      'proceudreSubTypeString',
      'pendingPushToDynamics',
      'parentProcedureContractName',
      'specialityName',
      'customerAddressName',
      'contractTypeString',
      'isNew',
      'contactName',
      'statusString',
      'configuredFields'
    ];

    const dateFields = [
      'indskr_enddate',
      'indskr_startdate',
      'indskr_postsurgerysignaturecapturedate',
      'indskr_presurgerysignaturecapturedate'
    ];

    const differentKeyFields = {
      'indskr_proceduresubtype': 'indskr_proceduresubtypes',
    };

    Object.keys(params).forEach((key) => {
      if (!excludeFields.includes(key) &&
        ((typeof params[key] === 'number' && params[key] >= 0) || params[key])) {
        let payloadKey = differentKeyFields[key] || key;
        payload[payloadKey] = dateFields.includes(key) ? new Date(params[key]).getTime() : params[key];
      }
    });

    return payload;
  }

  public async fetchContractTypes(fullSync, loadFromDBOnly) {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT)) return;
    if(fullSync) this.contractTypes = [];
    const offlineData = await this.loadContractTypesFromLocalDB();

    try {
      let deltaFilter = '';

      if (loadFromDBOnly) {
        this.contractTypes = offlineData.raw ? offlineData.raw : [];
        return;
      }

      if (offlineData?.lastModified) {
        const modifiedon = this.dateService.formatDateForFetchXML(offlineData.lastModified);
        deltaFilter = `<condition attribute="modifiedon" operator="ge" value="` + modifiedon + `"/>`;
      }

      let fetchXML = fetchQueries.salesOrders.fetchContractTypes;
      fetchXML = fetchXML.replace('{deltaSyncFilter}', !fullSync ? '' : '');
      fetchXML = fetchXML.replace('{fullSyncFilter}', fullSync ? '<condition attribute="statecode" operator="eq" value="0" />' : '');
      

      await this.dynamics.executeFetchQuery('indskr_contracttypes', fetchXML).then((response) => {
        if (fullSync && !response.length) this.contractTypes = [];

        if (response?.length) {
          // configuredFields
          let contractTypes: any = [];
          response.forEach((rcontractType) => {
            const index = contractTypes.findIndex((contractType) => contractType.indskr_contracttypeid === rcontractType.indskr_contracttypeid)
            if (index >= 0) {
              contractTypes[index].configuredFields.push(rcontractType['aa.indskr_appfieldid']);
            } else {
              contractTypes.push(new ContractType(rcontractType));
            }
          });

          contractTypes = _.unionBy(contractTypes, (contractType: ContractType) => contractType.indskr_contracttypeid);
          if (!this.contractTypes.length) this.contractTypes = contractTypes;

          if (!fullSync && this.contractTypes.length > 0) {
            contractTypes.forEach(contractType => {
              const index = this.contractTypes.findIndex(({ indskr_contracttypeid }) => indskr_contracttypeid === contractType.indskr_contracttypeid);
              if (index < 0 && contractType.statecode !== 1) {
                this.contractTypes.push(contractType);
              } else if (contractType.statecode === 1) { // if contract deactivated remove from local
                this.contractTypes.splice(index, 1);
              } else {
                this.contractTypes[index] = contractType;
              }
            });
          }
        } else if (!fullSync && offlineData.raw) {
          this.contractTypes = offlineData.raw ? offlineData.raw : [];
          return;
        }
        this.saveContractTypesInLocalDB(this.contractTypes);
      });
      return true;
    } catch (error) {
      console.log(error);
      this.contractTypes = offlineData?.raw ? offlineData.raw : [];
      return false;
    }
  }

  public async fetchKCodesData(fullSync, loadFromDBOnly) {
    if (!this.contractTypes?.some(a => a.indskr_displaykcodefields)) return;
    const offlineData = await this.loadKCodesDataFromLocalDB();

    try {
      let deltaFilter = '';

      if (loadFromDBOnly) {
        this.KCodesData = offlineData.raw ? offlineData.raw : [];
        return;
      }

      if (offlineData?.lastModified) {
        const modifiedon = this.dateService.formatDateForFetchXML(offlineData.lastModified);
        deltaFilter = `<condition attribute="modifiedon" operator="ge" value="` + modifiedon + `"/>`;
      }

      let fetchXML = fetchQueries.salesOrders.fetchKCodes;
      fetchXML = fetchXML.replace('{deltaSyncFilter}', !fullSync ? deltaFilter : '');

      if (fullSync) {
        fetchXML = fetchXML.replace('{fullSyncFilter}', '<condition attribute="statecode" operator="eq" value="0" />');
        fetchXML = fetchXML.replace('{fullSyncFilter2}', '<condition attribute="statecode" operator="eq" value="0" />');
      } else {
        fetchXML = fetchXML.replace('{fullSyncFilter}', '');
        fetchXML = fetchXML.replace('{fullSyncFilter2}', '');
      }

      await this.dynamics.executeFetchQuery('jnjjapan_kcodes', fetchXML).then((response) => {
        if (fullSync && !response.length) this.KCodesData = [];

        if (response?.length) {
          // configuredFields
          let KCodesDataTemp: any = [];
          response.forEach((kCode) => {
            KCodesDataTemp.push(new KCode(kCode));
          });

          if (fullSync || !this.KCodesData.length) this.KCodesData = KCodesDataTemp;

          if (!fullSync && this.KCodesData.length > 0) {
            response.forEach(kCode => {
              const index = this.KCodesData.findIndex(({ jnjjapan_kcodeid }) => jnjjapan_kcodeid === kCode.jnjjapan_kcodeid);
              if (index < 0 && kCode.statecode !== 1 && kCode['ab.statecode'] !== 1) {
                this.KCodesData.push(new KCode(kCode));
              } else if (kCode.statecode === 1 || kCode['ab.statecode'] === 1) { // if kcode deactivated remove from local
                this.KCodesData.splice(index, 1);
              } else {
                this.KCodesData[index] = new KCode(kCode);
              }
            });
          }
        } else if (!fullSync && offlineData.raw) {
          this.KCodesData = offlineData.raw ? offlineData.raw : [];
          return;
        }
        this.saveKCodesDataInLocalDB(this.KCodesData);
      });
      return true;
    } catch (error) {
      console.log(error);
      this.KCodesData = offlineData?.raw ? offlineData.raw : [];
      return false;
    }
  }

  public async saveKCodesDataInLocalDB(KCodes: KCode[]) {
    try {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.K_CODES_DATA, (doc) => {
        doc = {
          raw: [],
          lastModified: new Date().getTime()
        };
        doc.raw = KCodes;
        return doc;
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async saveContractTypesInLocalDB(contractTypes: ContractType[]) {
    try {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTRACT_TYPES, (doc) => {
        doc = {
          raw: [],
          lastModified: new Date().getTime()
        };
        doc.raw = contractTypes;
        return doc;
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async loadContractTypesFromLocalDB() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.CONTRACT_TYPES, true).then((doc) => {
        offlineDataStored = doc?.raw ? doc : [];
      });
    }
    catch (er) {
      console.error("Failed to load procedure sub types from local db!: ", er)
      offlineDataStored = [];
    }
    return offlineDataStored;
  }

  public async loadKCodesDataFromLocalDB() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.K_CODES_DATA, true).then((doc) => {
        offlineDataStored = doc?.raw ? doc : [];
      });
    }
    catch (er) {
      console.error("Failed to load K Codes data from local db!: ", er)
      offlineDataStored = [];
    }
    return offlineDataStored;
  }

  public findContractTypeById(contractTypeId) {
    return this.contractTypes.find((type) => type.indskr_contracttypeid === contractTypeId);
  }

  public getContractType(contractTypeId) {
    const contractType = this.findContractTypeById(contractTypeId);
    if (!contractType) return null;
    return contractType;
  }

  public async purgeData(maxEndDateUnixTimestamp): Promise<any> {
    let offlineData = [];
    let offlineDataDoc = {
      raw: [],
      lastUpdatedTime: null,
    };
    let afterPurgeData;
    let compareToDate = Utility.changeUTCDateToLocalDateWith0Time(maxEndDateUnixTimestamp, true);
    await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_CONTRACT, true).then((doc) => {
      offlineDataDoc = doc;
      if (doc?.raw) {
        offlineData = doc.raw;
      }
    }).catch(err => {
      console.log(err);
    });
    try {
      if (offlineData?.length != 0) {
        afterPurgeData = offlineData.filter(raw => (raw['indskr_enddate'] &&
          new Date(raw['indskr_enddate']).getTime() < compareToDate.getTime() &&
          (
            raw['statuscode'] === PROCEDURE_CONTRACT_STATUS.CANCELLED || raw['statuscode'] === PROCEDURE_CONTRACT_STATUS.EXPIRED) ||
          (!raw['indskr_enddate'] && new Date(raw['createdon']).getTime() < compareToDate.getTime() &&
            raw['statuscode'] === PROCEDURE_CONTRACT_STATUS.EXPIRED)
        ));
      }
      if (afterPurgeData && Array.isArray(afterPurgeData)) {
        offlineDataDoc.raw = afterPurgeData;
        this.procedureContracts = offlineData;
        this.refreshProcedureContract.next(this.procedureContracts);
        this.saveProcedureContractsInLocalDB(this.procedureContracts);
      }

    } catch (error) {
      console.log('Error while purging procedure contract ' + error);
    }
  }

  private expireContracts(offlineData) {
    let updatedData = offlineData.filter((contract) => {
      return this.isActiveContract(contract);
    });
    return updatedData;
  }

  isActiveContract(contract: ProcedureContract) {
    if (!this.authenticationService.user.buSettings?.['indskr_expireprocedurecontractsbasedonenddate']) return true;
    if (contract.indskr_parentprocedurecontractid) return true;
    const today = new Date().getTime();
    const diff = contract.indskr_enddate ? 0 : differenceInDays(new Date(contract.createdon), new Date()); // if enddate is not there we consider created date for difference
    if ((contract.indskr_enddate && endOfDay(new Date(contract.indskr_enddate)).getTime() > today) || (!contract.indskr_enddate && diff < 30)) {
      return true;
    }
    contract.statecode = 1;
    contract.statuscode = 548910009;
    contract.statusString = this.getStatusString(contract.statuscode);
    return false;
  }

  public removeProcedureContract(contractId) {
    const index = this.procedureContracts.findIndex(({ indskr_procedurecontractid }) => indskr_procedurecontractid === contractId);
    if (index < 0) return;
    this.procedureContracts.splice(index, 1);
    this.refreshProcedureContract.next(this.procedureContracts);
    this.saveProcedureContractsInLocalDB(this.procedureContracts);
    if (this.isTimeLineContracts) {
      this.removeTimeLineProcedureContract(contractId)
    }
  }

  public removeChildProcedureContracts(parentContractId) {
    this.procedureContracts = this.procedureContracts.filter((contract) => contract.indskr_parentprocedurecontractid !== parentContractId);
    this.refreshProcedureContract.next(this.procedureContracts);
    this.saveProcedureContractsInLocalDB(this.procedureContracts);
    if (this.isTimeLineContracts) {
      this.removeTimeLineChildProcedureContracts(parentContractId)
    }
  }

  public removeTimeLineProcedureContract(contractId) {
    let proceduerContracts = this.timeLineContracts.getValue();
    const index = proceduerContracts.findIndex(id => id.indskr_procedurecontractid === contractId)
    if (index < 0) return;
    proceduerContracts.splice(index, 1);
    this.timeLineContracts.next(proceduerContracts);
  }

  public removeTimeLineChildProcedureContracts(parentContractId) {
    let proceduerContracts = this.timeLineContracts.getValue();
    let contract = proceduerContracts.filter(contract => contract.indskr_parentprocedurecontractid !== parentContractId)
    this.timeLineContracts.next(contract);
  }

  public getStatusString(statusCode: number) {
    switch (statusCode) {
      case PROCEDURE_CONTRACT_STATUS.DRAFT:
        return this.translate.instant('CONTRACT_DRAFT');
      case PROCEDURE_CONTRACT_STATUS.PRE_SIGNED:
        return this.translate.instant('PRE_SIGNED');
      // case PROCEDURE_CONTRACT_STATUS.COMPLETED:
      //   statusString = 'COMPLETED'
      //   break;
      case PROCEDURE_CONTRACT_STATUS.CANCELLED:
        return this.translate.instant('CANCELLED');
      case PROCEDURE_CONTRACT_STATUS.EXPIRED:
        return this.translate.instant('EXPIRED_LOT');
      case PROCEDURE_CONTRACT_STATUS.POST_SIGNED:
        return this.translate.instant('POST_SIGNED');
      case PROCEDURE_CONTRACT_STATUS.ACTIVE:
        return this.translate.instant('ACTIVE');
      default:
        return null;
    }
  }

  public shouldContractFieldsReadonly(contractType, contractStatus) {
    if (contractType.procedureLogApplicability.length == 0 && contractStatus !== PROCEDURE_CONTRACT_STATUS.ACTIVE) return false;
    if (contractType.procedureLogApplicability.length > 0 && contractType.procedureLogApplicability.includes(contractStatus.toString())) return false;
    return true;
  }

  //------- config fields related methods starts here --------//

  private mapConfigFieldValuesToContract(response) {
    let configFieldValues = {};

    let selectedContractType = this.findContractTypeById(response._indskr_contracttypes_value);
    if (!selectedContractType) return configFieldValues;
    let applicableConfiguredFields = this.authenticationService.user.procedureContractConfiguredFields.filter((configuredField) => {
      return selectedContractType.configuredFields.includes(configuredField.appFieldId);
    });

    applicableConfiguredFields.forEach((configuredField: ConfiguredFields) => {
      switch (configuredField.fieldType) {
        case 'String':
        case 'Memo':
        case 'Currency':
        case 'Money':
        case 'Integer':
        case 'BigInt':
        case 'Decimal':
          configFieldValues = { ...configFieldValues, [configuredField.fieldName]: response[configuredField.fieldName] }
          break;
        case 'Boolean':
          configFieldValues = { ...configFieldValues, [configuredField.fieldName]: response[configuredField.fieldName] }
          break;
        case 'DateTime':
          configFieldValues = { ...configFieldValues, [configuredField.fieldName]: response[configuredField.fieldName] ? new Date(response[configuredField.fieldName]) : null }
          break;
        case 'Picklist':
        case 'Virtual':
          configFieldValues = { ...configFieldValues, [configuredField.fieldName]: response[configuredField.fieldName] }
          break;
        case 'Uniqueidentifier':
        case 'Lookup': {
          const valueKey = `_${configuredField.fieldName}_value`;
          const formattedKey = `${valueKey}_Formatted`;
          if (response.hasOwnProperty(valueKey)) {
            configFieldValues = {
              ...configFieldValues, [configuredField.fieldName]: {
                id: response[valueKey],
                name: configuredField.fieldName,
                entity: configuredField.indskr_lookupentitysetname,
                stringValue: response[formattedKey],
                indskr_referencingentitynavigationpropertyname: configuredField.indskr_referencingentitynavigationpropertyname,
                fieldname: configuredField.fieldName,
                datatype: configuredField.fieldType,
                value: response[valueKey],
                indskr_lookupentitysetname: configuredField.indskr_lookupentitysetname,
              }
            }
          }
          break;
        }
        case 'Owner':
        case 'EntityName':
          break;
        default:
          console.error('getConfigFieldInputTextAndValue: Unhandled switch case statement: ');
          break;
      }
    });

    return configFieldValues;
  }

  public async syncProcedureContractConfigFieldOptionSetValues(loadFromDbOnly = false) {
    try {
      if (loadFromDbOnly) {
        const localRecords: any = await this.loadOfflineConfigFieldOptionSetValues();
        if (localRecords) {
          this.configFieldOptionsValues = localRecords;
        }
      } else {
        const optionSetFields = this.authenticationService.user.procedureContractConfiguredFields.filter(field => field.fieldType === 'Picklist' || field.fieldType === 'Virtual');
        await Promise.all([
          ...optionSetFields.map(field => this.fetchConfigFieldOptionSetValues(field))
        ]);
      }
    } catch (error) {
      console.error('getEventConfigFieldOptionSetValues: ', error);
    }
  }

  private async fetchConfigFieldOptionSetValues(configuredField: ConfiguredFields) {
    const syncInfo: EntitySyncInfo = {
      entityName: EntityNames.eventPicklistAttributes + configuredField.fieldName,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    let url: string;
    try {
      url = this.authenticationService.userConfig.activeInstance.url + getConfigFieldPickListRequestURL(
        configuredField.fieldType,
        configuredField.entityName,
        configuredField.fieldName
      );
      const headers = new HttpHeaders().set('X-SystemUserId', this.authenticationService.user.xSystemUserID);

      const response: ConfigFieldOptionResponse = await firstValueFrom(this.http.get<ConfigFieldOptionResponse>(url, { headers }));

      if (response) {
        const result: ConfigFieldOptionValue[] = parseConfigFieldPicklistResponse(
          response,
          configuredField.entityName,
          configuredField.fieldName,
          configuredField.fieldType,
          configuredField.fieldLabel,
        );

        if (Array.isArray(result)) {
          const options = { [configuredField.fieldName]: result };

          console.log(options);


          // Keep in the memory
          // this.eventsToolService.configFieldOptionsValues = { ...this.eventsToolService.configFieldOptionsValues, ...options };

          this.configFieldOptionsValues = { ...this.configFieldOptionsValues, ...options };

          // Upsert DB
          const dbId: string = DB_KEY_PREFIXES.PROCEDURE_CONTRACT_PICKLIST_OPTIONSETS + configuredField.fieldName;
          try {
            await this.disk.updateOrInsert(dbId, doc => ({ options }));
          } catch (error) {
            console.error('fetchConfigFieldOptionSetValues: DB upsert: ', error);
            this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, url, error);
          }

          syncInfo.totalSynced = result.length;
        }
      }
    } catch (error) {
      console.error('fetchConfigFieldOptionSetValues: ', error);
      this.deltaService.addSyncErrorToEntitySyncInfo(syncInfo, url, error);
      const localData = await this.loadOfflineConfigFieldOptionSetValues(configuredField.fieldName);
      if (localData) {
        this.configFieldOptionsValues = { ...this.configFieldOptionsValues, ...localData };
      }
    }
    this.deltaService.addEntitySyncInfo(syncInfo);
  }

  private async loadOfflineConfigFieldOptionSetValues(fieldName?: string): Promise<any> {
    let dbId = DB_KEY_PREFIXES.PROCEDURE_CONTRACT_PICKLIST_OPTIONSETS;
    let options: any;

    if (fieldName) {
      //   // Retrieve one field's record
      try {
        dbId = dbId + fieldName;
        const doc = await this.disk.retrieve(dbId, true);
        if (doc?.hasOwnProperty('options')) {
          options = doc.options;
        }
      } catch (error) {
        console.error(`loadOfflineConfigFieldOptionSetValues: retrieve ${fieldName}: `, error);
      }
    } else {
      //   // Retrieve all fields records
      let option = {
        selector: {
          '_id': {
            $gte: DB_KEY_PREFIXES.PROCEDURE_CONTRACT_PICKLIST_OPTIONSETS,
            $lte: DB_KEY_PREFIXES.PROCEDURE_CONTRACT_PICKLIST_OPTIONSETS + PREFIX_SEARCH_ENDKEY_UNICODE
          },
        }
      };

      try {
        const localRecords: { options: any }[] = await this.disk.find(option);
        if (Array.isArray(localRecords)) {
          options = localRecords.reduce((acc, cur) => {
            if (cur.hasOwnProperty('options')) {
              acc = {
                ...acc,
                ...cur.options,
              };
            }
            return acc;
          }, {});
        }
      } catch (error) {
        console.error('loadOfflineConfigFieldOptionSetValues: retrieve all: ', error);
      }
    }

    return options;
  }

  generatePayloadForConfiguredFields(inputValue, fieldName, fieldType) {
    let payload: any = {
      "fieldname": fieldName,
      "datatype": fieldType,
      "value": inputValue
    }

    if (fieldType === 'DateTime') {
      const offsetDate = new Date(inputValue);
      const tzIndependentDateObj = new Date(
        offsetDate.getUTCFullYear(),
        offsetDate.getUTCMonth(),
        offsetDate.getUTCDate(),
        offsetDate.getUTCHours(),
        offsetDate.getUTCMinutes(),
        0,
        0
      );
      payload.value = inputValue ? format(tzIndependentDateObj, this.dateTimeFormatsService.dateTimeToUpper) : '';
    } else if (fieldType === 'Lookup') {
      payload = { ...inputValue }
    }

    return payload;
  }

  public async addIoConfigDefaultValuesToEventActivity(contract: ProcedureContract) {
    let configFieldValues = {};
    const supportedDataTypes = ['Picklist', 'Boolean'];

    if (!contract.indskr_contracttypes) return configFieldValues;
    let selectedContractType = this.findContractTypeById(contract.indskr_contracttypes);
    if (!selectedContractType) return configFieldValues;

    let defaultConfigFields = this.authenticationService.user.procedureContractConfiguredFields.filter((configuredField) => {
      return (selectedContractType.configuredFields.includes(configuredField.appFieldId) && configuredField.indskr_optionsetdefaultvalue && supportedDataTypes.includes(configuredField.fieldType));
    });

    if (defaultConfigFields && defaultConfigFields.length > 0) {
      defaultConfigFields.forEach(a => {
        if (a.datatype == 'Lookup' || a.fieldType == 'Lookup') {
          configFieldValues = {
            ...configFieldValues,
            id: a.indskr_optionsetdefaultvalue,
            name: a.fieldName,
            entity: a.indskr_lookupentitysetname,
            stringValue: a.indskr_optionsetdefaultext,
            indskr_referencingentitynavigationpropertyname: a.indskr_referencingentitynavigationpropertyname
          };
        } else if (a.datatype == 'Boolean' || a.fieldType == 'Boolean') {
          configFieldValues = { ...configFieldValues, [a.fieldName]: a.indskr_optionsetdefaultvalue == 'true' ? true : false }
        } else if (a.datatype == 'Picklist' || a.fieldType == 'Picklist' || a.datatype == 'Virtual' || a.fieldType == 'Virtual') {
          configFieldValues = { ...configFieldValues, [a.fieldName]: a.indskr_optionsetdefaultvalue ? parseInt(a.indskr_optionsetdefaultvalue) : a.indskr_optionsetdefaultvalue };
        } else {
          configFieldValues = { ...configFieldValues, [a.fieldName]: a.indskr_optionsetdefaultvalue };
        }
      });
    }

    return configFieldValues;
  }

  getConfigFieldInputTextAndValue(fieldType: string, fieldName: string, fieldValue: any): { inputText: string, inputValue: any } {
    let inputText: string = '';
    let inputValue: any = null;
    inputValue = fieldValue;

    switch (fieldType) {
      case 'String':
      case 'Memo':
      case 'Currency':
      case 'Money':
      case 'Integer':
      case 'BigInt':
      case 'Decimal':
        if (!(fieldValue === undefined || fieldValue === null)) {
          inputText = '' + fieldValue;
        }
        break;
      case 'Boolean':
        inputText = fieldValue === true ? this.translate.instant('YES') : fieldValue === false ? this.translate.instant('No') : '';
        break;
      case 'DateTime':
        inputText = this.datePipe.transform(fieldValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        inputValue = fieldValue;
        break;
      case 'Picklist':
      case 'Virtual':
        inputText = this.getOptionSetValueText(fieldType, fieldName, fieldValue);
        inputValue = fieldValue;
        break;
      case 'Lookup':
        {
          let field = fieldValue;
          if (field?.stringValue) {
            inputText = field.stringValue;
            inputValue = field.id;
          }
          break;
        }
      default:
        console.error('getConfigFieldInputTextAndValue: Unhandled switch case statement: ', fieldType, fieldName);
        break;
    }

    return { inputText, inputValue };
  }

  private getOptionSetValueText(fieldType: string, fieldName: string, fieldValue: any): string {
    let valueText: string = '';
    const configFieldOptionValues: ConfigFieldOptionValue[] = this.configFieldOptionsValues[fieldName];

    if (Array.isArray(configFieldOptionValues)) {
      try {
        if (fieldType === 'Picklist') {
          const option = configFieldOptionValues.find(o => o.value === fieldValue);
          if (option) {
            valueText = option.label;
          }
        } else if (fieldType === 'Virtual' && fieldValue) {
          const fieldValues: number[] = fieldValue.split(',').map(v => Number(v));
          const fieldLabels: string[] = [];
          for (const field of configFieldOptionValues) {
            if (fieldValues.includes(field.value)) {
              fieldLabels.push(field.label);
            }
          }

          if (fieldLabels.length > 0) {
            valueText = fieldLabels[0];

            if (fieldLabels.length > 1) {
              valueText = valueText + ' + ' + (fieldLabels.length - 1);
            }
          }
        }
      } catch (error) {
        console.error(
          'getOptionSetValueText: ',
          error,
          fieldType, fieldName, fieldValue,
        );
      }
    }

    return valueText;
  }

  //------- config fields related methods ends here --------//


  async downloadAllAttachements(procedureContract: ProcedureContract) {
    let urls = [];
    const { indskr_contracttypes, indskr_signaturecapturemode, indskr_postsignaturecapturemode, indskr_presurgerysignaturedocument, indskr_postsurgerysignaturedocument } = procedureContract;
    this.uiService.displayLoader();
    const contractType = this.findContractTypeById(indskr_contracttypes);
    const regex = /\/([^\/]+)$/;
    if (contractType?.indskr_attachmenttypes?.length > 0) {
      if (indskr_signaturecapturemode === CONTRACT_SIGNATURE_MODE.Digital || indskr_postsignaturecapturemode === CONTRACT_SIGNATURE_MODE.Digital || contractType.indskr_attachmenttypes.includes(PROCEDURE_CONTRACT_EMAIL_ATTACHEMENTS.ORIGINAL_COPY)) {
        urls.push(await this.generateUrl(procedureContract));
      }

      if (indskr_signaturecapturemode !== CONTRACT_SIGNATURE_MODE.Digital && contractType.indskr_attachmenttypes.includes(PROCEDURE_CONTRACT_EMAIL_ATTACHEMENTS.PRE_SIGNED_COPY) && indskr_presurgerysignaturedocument) {
        urls.push(await this.generateContractDocumentUrl(procedureContract, regex.exec(indskr_presurgerysignaturedocument)[1]));
      }

      if (indskr_postsignaturecapturemode !== CONTRACT_SIGNATURE_MODE.Digital && contractType.indskr_attachmenttypes.includes(PROCEDURE_CONTRACT_EMAIL_ATTACHEMENTS.POST_SIGNED_COPY) && indskr_postsurgerysignaturedocument) {
        urls.push(await this.generateContractDocumentUrl(procedureContract, regex.exec(procedureContract.indskr_postsurgerysignaturedocument)[1]));
      }

      if (urls.length) {
        let attachmentPaths = await this.fileservice.downloadFile(urls);
        return attachmentPaths;
      } else {
        this.uiService.dismissLoader();
        return [];
      }
    } else {
      this.uiService.dismissLoader();
      return [];
    }
  }

  //----------------- send email ------------------//

  async openEmailChooser(procedureContract: ProcedureContract): Promise<void> {
    try {
      const attachMentInfo: any = await this.downloadAllAttachements(procedureContract);

      const contractType = this.findContractTypeById(procedureContract.indskr_contracttypes);
      let message = '';
      let subject = '';

      if (contractType?.indskr_emailtemplate) {
        const template: any = await this.getEmailTemaplates(contractType.indskr_emailtemplate);
        if (template) {
          message = await this.fillEmailTemplateWithData(template.indskr_body, procedureContract);
          subject = template.indskr_email_subject
          let typeConfig =  this.authenticationService.user.ioConfigurations?.find(o=>o.configName=="ProcedureContractCustomFieldsForPDF")
          if(typeConfig){
            subject = `【発注のお願い】有償立会い実施確認書(${procedureContract.accountName}/${procedureContract.configuredFields?.['jnjjapan_distributorcompanyname']?.stringValue ?? ''})/${procedureContract.configuredFields['jnjjapan_witnessconfirmationnumber']}`;
          }
        }
      }
      this.uiService.dismissLoader();
      if (this.deviceService.deviceFlags.electron) {
        await electronApi.sendOutlookEmail('', '', '', subject, message, attachMentInfo);
      } else {
        const options = {
          message,
          subject,
          files: attachMentInfo
        }

        if (this.deviceService.deviceFlags.android) {
          this.socialSharing.shareWithOptions(options).then((res) => {
            console.log(res);
          }).catch((error) => {
            alert(JSON.stringify(error));
            console.log(error);
          }).finally(() => {
            this.fileservice.removeFiles(attachMentInfo);
          });
        } else {
          this.socialSharing.share(message, subject, attachMentInfo).then((res) => {
            console.log(res);
          }).catch((error) => {
            alert(JSON.stringify(error));
            console.log(error);
          }).finally(() => {
            this.fileservice.removeFiles(attachMentInfo);
          });
        }
      }
    } catch (error) {
      console.log('Failed to open email chooser', error);
    }

  }

  async getEmailTemaplates(templateId: string): Promise<ResourceEmailTemplate | undefined> {
    try {
      const data: ResourceEmailTemplate[] = await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_EMAIL_TEMPLATES) as ResourceEmailTemplate[];
      const template = data.find((emailTemplate) => emailTemplate.indskr_emailtemplateid === templateId);
      return template;
    } catch (error) {
      console.log('Error while getting template for contract');
      throw new Error("Error while getting template");
    }
  }

  isDate(dateString) {
    // Regular expression for typical ISO 8601 date formats
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[\+\-]\d{2}:\d{2})?$/;

    const date = new Date(dateString);
    return (iso8601Regex.test(dateString) || !isNaN(date.getTime()));
  }

  setValue(input) {
    let value = input ?? '';
    if ((typeof value === 'string' || typeof value === 'number' || typeof value === 'object') && this.isDate(value)) {
      value = this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
    }
    return value;
  }

  async fillEmailTemplateWithData(template: string, contract: ProcedureContract) {
    let selectedContractType = this.findContractTypeById(contract.indskr_contracttypes);
    if (!selectedContractType) return '';

    let configFields = this.authenticationService.user.procedureContractConfiguredFields.filter((configuredField) => {
      return (selectedContractType.configuredFields.includes(configuredField.appFieldId));
    });

    return template.replace(/%\w+%/g, (match) => {
      const key = match.slice(1, -1);
      const isCoreField = key in contract;

      let value;
      if (isCoreField) {
        value = this.setValue(contract[key]);
      } else if (contract.configuredFields && key in contract.configuredFields) {
        const configuredField = configFields.find((configField) => configField.fieldName === key);
        const { inputText } = this.getConfigFieldInputTextAndValue(configuredField.fieldType, configuredField.fieldName, contract.configuredFields[key]);
        value = inputText;
      } else {
        value = contract[key] ?? '';
      }

      return value;
    });


  }

  public getServicePayloadForKCodeField(field, procedureContract) {
    if (field == 'jnjjapan_kcode_1') {
      return {
        datatype: "Lookup",
        entity: "jnjjapan_kcodes",
        fieldname: "jnjjapan_kcode_1",
        id: procedureContract.k_code_one_id,
        value: procedureContract.k_code_one_id,
        indskr_lookupentitysetname: "jnjjapan_kcodes",
        indskr_referencingentitynavigationpropertyname: "jnjjapan_KCode_1",
        name: "jnjjapan_kcode_1",
        stringValue: procedureContract.k_code_one_name,
      }
    } else if (field == 'jnjjapan_kcode_2') {
      return {
        datatype: "Lookup",
        entity: "jnjjapan_kcodes",
        fieldname: "jnjjapan_kcode_2",
        id: procedureContract.k_code_two_id,
        value: procedureContract.k_code_two_id,
        indskr_lookupentitysetname: "jnjjapan_kcodes",
        indskr_referencingentitynavigationpropertyname: "jnjjapan_KCode_2",
        name: "jnjjapan_kcode_2",
        stringValue: procedureContract.k_code_two_name,
      }
    } else if (field == 'jnjjapan_kcode_3') {
      return {
        datatype: "Lookup",
        entity: "jnjjapan_kcodes",
        fieldname: "jnjjapan_kcode_3",
        id: procedureContract.k_code_three_id,
        value: procedureContract.k_code_three_id,
        indskr_lookupentitysetname: "jnjjapan_kcodes",
        indskr_referencingentitynavigationpropertyname: "jnjjapan_KCode_3",
        name: "jnjjapan_kcode_3",
        stringValue: procedureContract.k_code_three_name,
      }
    } else if (field == 'jnjjapan_otherkcodename') {
      return {
        datatype: "String",
        entity: "jnjjapan_kcodes",
        fieldname: "jnjjapan_otherkcodename",
        id: procedureContract.k_code_other_name,
        value: procedureContract.k_code_other_name,
        indskr_lookupentitysetname: "jnjjapan_kcodes",
        name: "jnjjapan_otherkcodename",
        stringValue: procedureContract.k_code_other_name,
      }
    }

  }

  //----------- Contract for timeline ------------- //

  private buildFetchXMLForTimeline(accountId: string = '') {
    let fetchXML = fetchQueries.salesOrders.fetchProcedureContracts;
    fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
    let configFields = '';
    let kcodeattributes = '';
   
    if(accountId){
      fetchXML = fetchXML.replace('{accountId}', `<condition attribute="indskr_account" operator="eq" value="` + accountId + `"/>`)
    }

    if (this.authenticationService.user.procedureContractConfiguredFields.length) {
      this.authenticationService.user.procedureContractConfiguredFields.forEach((configField) => {
        configFields = configFields.concat(`<attribute name="${configField.fieldName}" />`);
      });
    }

    if (this.contractTypes?.some(a => a.indskr_displaykcodefields)) {
      kcodeattributes = `
      <attribute name="jnjjapan_kcode_1" />
      <attribute name="jnjjapan_kcode_2" />
      <attribute name="jnjjapan_kcode_3" />
      <attribute name="jnjjapan_otherkcodename" />
      `;
    }

    fetchXML = fetchXML.replace('{configFields}', configFields);
    fetchXML = fetchXML.replace('{KCodeFields}', kcodeattributes);

    return fetchXML;
  }

  mapContracts(response): ProcedureContract[] {
    let procedureContracts = response.map(contract => {
      let tempContract = new ProcedureContract(contract);
      tempContract.configuredFields = this.mapConfigFieldValuesToContract(contract);
      return tempContract;
    });
    procedureContracts = _.unionBy(procedureContracts, (procedureContract: ProcedureContract) => procedureContract.indskr_procedurecontractid);
    return procedureContracts;
  }

  async fetchProcedureContractsForTimeLine(accountId: string) {
    this.timeLineContracts.next([]);
    await this.uiService.displayLoader();
    const fetchXML = this.buildFetchXMLForTimeline(accountId);

    return await this.dynamics.executeFetchQuery('indskr_procedurecontracts', fetchXML).then(async (response) => {
      let proceduerContracts = this.mapContracts(response);
      if (this.authenticationService.user.buSettings?.['indskr_expireprocedurecontractsbasedonenddate']) {
        let updatedProc = this.expireContracts(proceduerContracts);
        this.sortProcedureContracts(updatedProc)
      }
      this.sortProcedureContracts(proceduerContracts)
    }).catch(err => {
      console.log('Faild to fetch procedure contract')
    });
  }

  async sortProcedureContracts(proceduerContracts) {
    proceduerContracts.sort((a, b) => new Date(b.createdon).getTime() - new Date(a.createdon).getTime());
    this.timeLineContracts.next(proceduerContracts);
    await this.uiService.dismissLoader();
    return proceduerContracts;
  }

  getTimelineProcedureContracts(){
    return this.timeLineContracts.getValue();
  }

  updateTimelineProcedureContracts(contractId, updatedValue) {
    let proceduerContracts = this.timeLineContracts.getValue();
    let index = this.procedureContracts.findIndex((procedureContract) => procedureContract.indskr_procedurecontractid === contractId);
    if (index >= 0) {
      this.procedureContracts[index] = {
        ...this.procedureContracts[index],
        ...updatedValue
      }
    }
    this.timeLineContracts.next(proceduerContracts);
  }

  public getFilterText(filters: { productFilter: FilterOption, statusFilter: FilterOption, contractTypeFilter: FilterOption }, filteredItems: number): string {
    let str: string[] = [];

    if (filters.statusFilter) {
      str.push(filters.statusFilter.text);
    }

    if (filters.productFilter) {
      str.push(filters.productFilter.text);
    }

    if (filters.contractTypeFilter) {
      str.push(filters.contractTypeFilter.text);
    }

    if (str.length === 0) {
      filters = { statusFilter: null, productFilter: null, contractTypeFilter: null };
      str.push(this.translate.instant('ALL_PROCEDURE_CONTRACTS'));
    }

    return `${str.join(', ')} (${filteredItems})`;
  }

  public getContractTypeFilterOptions(procedureContracts: ProcedureContract[]): { text: string, value: string }[] {
    const contractTypes = [];
    procedureContracts.forEach(procedureContract => {
      if (procedureContract.contractTypeString) {
        contractTypes.push({ text: procedureContract.contractTypeString, value: procedureContract.indskr_contracttypes })
      }
    });

    return _.uniqBy(contractTypes, 'text');
  }
}
