import {Component, Input, ViewChild, ElementRef} from "@angular/core";
import {DeviceService} from "../../../services/device/device.service";
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import _ from 'lodash';
import { TranslateService } from "@ngx-translate/core";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { takeUntil } from "rxjs/operators";
import { NothingSelectedView } from "@omni/components/shared/nothing-selected-view/nothing-selected-view";
import { SetBookingActivity, SetBookingStatus } from "@omni/classes/activity/set-booking.activity.class";
import { Subject } from "rxjs";
import { DynamicForm, FormType } from "@omni/classes/dynamic-form/dynamic-form.class";
import { DynamicFormsService } from "@omni/services/dynamic-forms/dynamic-forms-service";
import { DynamicFormComponentModel, DynamicFormType } from "@omni/models/dynamic-form-component.model";
import { DynamicFormComponent } from "@omni/components/shared/ind-dynamic-form/ind-dynamic-form";
import { AlertService } from "@omni/services/alert/alert.service";
import { addMinutes, differenceInCalendarDays, differenceInMinutes, format, isSameDay, isValid } from "date-fns";
import { OperationDetail } from "@omni/data-services/order-activity/order-activity.data.service";
import { SetBookingDataService } from "@omni/data-services/set-booking/set-booking.data.service";
import { NotificationService, ToastStyle } from "@omni/services/notification/notification.service";
import { UIService } from "@omni/services/ui/ui.service";
import { LoadingController, ModalController } from '@ionic/angular';
import { AuthenticationService } from '../../../services/authentication.service';
import { IONote } from '../../../classes/io/io-note.class';
import { isBefore, isPast} from 'date-fns';
import { MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX, toBase64 } from '@omni/utility/util';
import { DiskService,OFFLINE_DB_LINKED_ENTITY_NAME } from "@omni/services/disk/disk.service";
import { EventsService } from "@omni/services/events/events.service";
import { DB_KEY_PREFIXES } from "@omni/config/pouch-db.config";
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { ActivityService, ActivitySource } from "@omni/services/activity/activity.service";
import { ProcedureTrackerActivityDataService } from  "@omni/data-services/procedure-tracker-activity/procedure-tracker-activity.data.service";
import { UserTypes } from "@omni/classes/activity/procedure-tracker.activity.class";
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { AppointmentActivity, OFFLINE_ID_PREFIX } from "@omni/classes/activity/appointment.activity.class";
import { InitiateMeetingPayload, MeetingDataService, UpdateMeetingPayload } from "@omni/data-services/meeting/meeting.data.service";
import { Contact } from "@omni/classes/contact/contact.class";
import { Account } from "@omni/classes/account/account.class";
import { AccountOfflineService } from "@omni/services/account/account.offline.service";
import { ContentMatchingService } from "@omni/services/content-matching/content-matching.service";
import { MeetingStructureService } from "@omni/services/meeting-structure/meeting-structure.service";
import { ActivityDataService } from "@omni/data-services/activity/activity.service";
import { ActivitiesDetailsPaneComponent } from "../activities-details-pane/activities-details-pane";
import { MainCardViewDataModel } from "@omni/models/MainCardViewDataModel";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { BrandOfflineService } from '@omni/services/brand/brand.service';
import { TrackingEventNames, TrackService } from "@omni/services/logging/tracking.service";
import { NewActivityComponent } from "../new-activity/new-activity";
import { CaseActivity } from "@omni/classes/case-intake/case-activity.class";
import { CaseManagementDetailComponent } from "@omni/components/case-management/case-management-detail/case-management-detail";
import { AccesingMode, CaseManagementService } from "@omni/services/case-management/case-management.service";
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'set-booking-activity-detail',
  templateUrl: 'set-booking-activity-detail.html',
  styleUrls:['set-booking-activity-detail.scss']
})
export class SetBookingActivityDetailsComponent {

  @Input() from: any;
  @Input() activity: SetBookingActivity;
  @Input() isCloneFlow: false;
  @Input() initFetchData: boolean = true;
  public currentSetBookingActivity: SetBookingActivity;
  private isLayoverPushView: boolean;
  private layoverViewPushedFrom: string;
  public isReadOnlyMode = true;

  public setBookingDetailsPageTitle: IndPageTitleViewDataModel;
  public kitBookingActivitiesHeader: IndSectionHeaderViewDataModel;
  public notesSectionHeader: IndSectionHeaderViewDataModel;
  private readonly ngDestroy$ = new Subject<boolean>();

  public displayFormDetails;
  public rawFormData;
  public dynamicFormType:DynamicFormType;
  public setBookingLinkedEntityDBPrefix:string = DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY;

  public activitiesDataModel: MainCardViewDataModel[];
  public applicableMeetings: AppointmentActivity[] = [];
  public applicableCustomerInquiries: CaseActivity[] =[];

  private linkEntitiesReqPayload: any = [];
  public notesSectionHeaderViewData:IndSectionHeaderViewDataModel;
  public tempNoteText: string = '';
  public isAttachmentAdded: boolean = false;
  public isSaveNotesEnabled:boolean = false;
  private base64str;
  private attachmentFile: any;
  public currentKitBookingNotes:Array<IONote> = [];
  public isKitBookingNotesAttachmentEnabled = true;
  public notesPlaceholder:string = '';
  private isPreViewMode: boolean = false;
  public isKitBookingNotesEnabled: boolean= true;
  public attachmentTitle: string = '';
  public isActivitiesSectionExpanded: boolean = true;
  @ViewChild('attachInput') attachInput: ElementRef;

  constructor(
    private readonly device: DeviceService,
    public readonly footerService: FooterService,
    private readonly translate: TranslateService,
    private readonly navService: NavigationService,
    public contactService: ContactOfflineService,
    private readonly dynamicFormsService: DynamicFormsService,
    private readonly alertService: AlertService,
    private readonly setBookingDataService: SetBookingDataService,
    private readonly notificationService: NotificationService,
    public readonly uiService: UIService,
    private loadingCtrl: LoadingController,
    public authService: AuthenticationService,
    private disk: DiskService,
    private readonly events: EventsService,
    public activityService: ActivityService,
    private procedureTrackerDataService: ProcedureTrackerActivityDataService,
    public modalCtrl: ModalController,
    public accountService: AccountOfflineService,
    public meetingService: MeetingDataService,
    private activityDataService: ActivityDataService,
    private contentMatchService: ContentMatchingService,
    private meetingStructureService: MeetingStructureService,
    public readonly dateTimeFormatsService: DateTimeFormatsService,
    private brandService: BrandOfflineService,
    private readonly trackingService: TrackService,
    public caseService: CaseManagementService,
  ) {
  }

  async ngOnInit() {
    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
    }
    if(this.activity){
      this.currentSetBookingActivity = _.cloneDeep(this.activity);
    }

    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this._initPageTitle();
    });

    // add refresh event
    this.events.observe('refresh-kit-booking-activities').pipe(takeUntil(this.ngDestroy$)).subscribe((status) =>{
      this._initKitBookingActivitiesDataModel();
    });

    this.events.observe('initSetBookingDisplayForm').pipe(takeUntil(this.ngDestroy$)).subscribe(async (status) =>{
      await this._initSetBookingDisplayForm();
      this._initPageTitle();
    });
    this.events.observe('initSetBookingFooter').pipe(takeUntil(this.ngDestroy$)).subscribe(async (status) =>{
      await this._initFooterButtons();
    });

    await this._initSetBookingDisplayForm(this.initFetchData);
    this._initPageTitle();
    this._initActivitiesSectionHeader();
    this._initKitBookingActivitiesDataModel();
    this._initKitBookingNotesViewData();
    this.initKitBookingNotesData();
    if(this.currentSetBookingActivity.indskr_status == SetBookingStatus.CANCELERROR && this.authService.user.buSettings["indskr_kitbookingcancelerrormessage"]){
      this.alertService.showAlert({
        header: this.translate.instant('NOTE'),
        message: this.authService.user.buSettings["indskr_kitbookingcancelerrormessage"],
        buttons:[this.translate.instant("OK")]
      }
      ).then(async (res) => {

      })
    }
    //if clone functionality
    if(this.isCloneFlow)
      this._editSetBooking(true);
  }

  ionViewDidEnter() {
    this._initFooterButtons();
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  public get enableBackButton() {
    return this.device.isMobileDevice;
  }

  private _initPageTitle():void {
    let buttons=[];
    const isReadOnlyMode = this.device.isOffline ||
                        this.currentSetBookingActivity.indskr_status == SetBookingStatus.CANCELLED ||
                        this.currentSetBookingActivity.indskr_status == SetBookingStatus.PENDINGCANCEL ||
                        this.currentSetBookingActivity.indskr_status == SetBookingStatus.PENDINGSYNC ||
                        this.currentSetBookingActivity.indskr_status == SetBookingStatus.CANCELERROR ||
                        this.currentSetBookingActivity.indskr_status == SetBookingStatus.RETURNREQUESTED ||
                        this.currentSetBookingActivity.isTeamKitBooking;
    if(this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_KITBOOKING)){
      buttons.push({
          id: "assignsetbooking",
          imgSrc: 'assets/imgs/assign_owner_white.svg',
          name: this.translate.instant('ASSIGN'),
          isDisabled : false,
          align: "right",
        });
    }
    if (this.enableBackButton) {
      buttons.push({
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      });
    }
    const isScrapButtonDisabled = isReadOnlyMode ||
      this.currentSetBookingActivity.indskr_status == SetBookingStatus.COMPLETED ||
      !(this.currentSetBookingActivity?.indskr_status == SetBookingStatus.DRAFT ||
        this.currentSetBookingActivity?.indskr_status == SetBookingStatus.SUBMITTED);
    buttons.push(
      {
        id: 'scrapsetbooking',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: isScrapButtonDisabled? true : false,
        align: 'right'
      },
      {
        id: 'editsetbooking',
        imgSrc: 'assets/imgs/header_edit.svg',
        name: this.translate.instant('EDIT'),
        isDisabled: isReadOnlyMode? true : false,
        align: 'right'
      },
      {
        id: "pluse-icon",
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        name: this.translate.instant('CREATE'),
        isDisabled: this.from == 'Meeting' || this.from == 'CaseManagement',
        align: "right"
      }
    );

    this.setBookingDetailsPageTitle = {
      id: 'set-booking-activity-details-page-title',
      title: this.currentSetBookingActivity.subject,
      controls: buttons
    }

  }

  private async _initSetBookingDisplayForm(fetchData: boolean=true) {
    if(this.currentSetBookingActivity){
      let setBookingForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_setbooking", FormType.DISPLAYFORM);
      let formType = DynamicFormType.CONFIGUREDFORM;
      if(!setBookingForm){
        return;
      }
      const nonVisibleFormSection = this.setBookingDataService.formSectionConfigure?.filter(config => config['_indskr_mobileappform_value'] == setBookingForm.mobileFormId && !config['indskr_visibility']);
      this.dynamicFormType = formType;
      setBookingForm.metadata.forEach(tab => {
        tab.controls.forEach(control => {
          if (control.dataType && control.attributeName && !_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase() == control.attributeName.toLowerCase())) {
            control.isVisible = false;
            control.forceHide = true;
          } else if (!control.dataType && control.subgrid && !_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase() == control.attributeName.toLowerCase())) {
            control.isVisible = false;
            control.forceHide = true;
          }
        });
      });
      this.displayFormDetails = setBookingForm;
      if(!this.device.isOffline && fetchData){
        this.uiService.displayLoader();
        await this.setBookingDataService.fetchRealTimeSetBookingDetails(this.currentSetBookingActivity);
        this.uiService.dismissLoader();
      }
      this.rawFormData = this.currentSetBookingActivity.rawData;
      this._initFooterButtons();
    }
  }

  private _initKitBookingActivitiesDataModel() {
    this.applicableMeetings = [];
    this.applicableCustomerInquiries =[];
    this.activitiesDataModel = [];
    this.activityService.activities.map((o) => {
      if (o instanceof AppointmentActivity && o['indskr_kitbooking']  == this.currentSetBookingActivity.ID) {
        this.applicableMeetings.push(o);
      }
      if (o instanceof CaseActivity && o['indskr_kitbooking']  == this.currentSetBookingActivity.ID) {
        this.applicableCustomerInquiries.push(o);
      }
    })
    if(this.applicableMeetings && this.applicableMeetings.length>0) {
      this.applicableMeetings.forEach((f: AppointmentActivity) => {
        let viewModel: MainCardViewDataModel = {
          id: f.ID,
          fixedHeight: true,
          primaryTextLeft: f.subject,
          secondaryTextLeft: f.getStatusString,
          sortDate: f.scheduledStart,
          secondaryTextLeftTwo: isValid(f.scheduledStart) ? format(new Date(f.scheduledStart), this.dateTimeFormatsService.dateToUpper) : '',
          showArrow: this.from != 'Meeting' && !this.currentSetBookingActivity.isTeamKitBooking,
          arrowType: 'chevron-forward-outline',
          clickHandler: (id: string, event) => this.goToActivityDetails(id)
        }
        this.activitiesDataModel.push(viewModel);
      });
    }
    if(this.applicableCustomerInquiries && this.applicableCustomerInquiries.length>0) {
      this.applicableCustomerInquiries.forEach((f: CaseActivity) => {
        let viewModel: MainCardViewDataModel = {
          id: f.ID,
          fixedHeight: true,
          primaryTextLeft: f.caseName? f.caseName: f._case_type.name,
          secondaryTextLeft: this.getCaseState(f._case_status_value),
          sortDate: f.scheduledStart,
          secondaryTextLeftTwo: isValid(f.scheduledStart) ? format(new Date(f.scheduledStart), this.dateTimeFormatsService.dateToUpper) : '',
          showArrow: this.from != 'CaseManagement' && !this.currentSetBookingActivity.isTeamKitBooking,
          arrowType: 'chevron-forward-outline',
          clickHandler: (id: string, event) => this.goToActivityDetails(id)
        }
        this.activitiesDataModel.push(viewModel);
      });
    }
  }

  private _initFooterButtons(){
    if(this.currentSetBookingActivity.indskr_status == SetBookingStatus.CANCELLED
      || this.currentSetBookingActivity.indskr_status == SetBookingStatus.CANCELERROR
      || this.currentSetBookingActivity.indskr_status == SetBookingStatus.PENDINGCANCEL
      || this.currentSetBookingActivity.indskr_status == SetBookingStatus.PENDINGSYNC
      || this.currentSetBookingActivity.indskr_status == SetBookingStatus.RETURNREQUESTED
      || this.currentSetBookingActivity.isTeamKitBooking)
      this.footerService.initButtons(FooterViews.SetBooking, {disableButtons : true})
    else if(!(this.currentSetBookingActivity.indskr_status == SetBookingStatus.DRAFT || this.currentSetBookingActivity.indskr_status == SetBookingStatus.SUBMITTED))
      this.footerService.initButtons(FooterViews.SetBooking, {disableScrapButton : true})
    else
      this.footerService.initButtons(FooterViews.SetBooking);
  }
  public getCaseState(caseState):string{
    switch (caseState) {
      case 'Open':
        caseState = this.translate.instant('OPEN');
          break;
      case 'Approved':
        caseState = this.translate.instant('APPROVE');
          break;
      case 'For Review':
          caseState = this.translate.instant('FOR_REVIEW');
          break;
      case 'In Progress':
          caseState = this.translate.instant('IN_PROGRESS');
          break;
      case 'Intake':
            caseState = this.translate.instant('INTAKE');
            break;
      case 'Pending Sync':
            caseState = this.translate.instant('PENDING_SYNC');
            break;
      default:
          caseState = caseState;
          break;
    }
    return caseState;
  }

  private async onClosePage(isCancel: boolean = false, fetchData: boolean = true) {
    isCancel = await this.setBookingDataService.checkSetBookingDiscardConfirmationRequired();
    if (isCancel) {
      if (this.from == 'Meeting' || this.from == 'CaseManagement') {
        // this.activityService.selectedActivity = this.activityService.prevSelectedActivity;
        this.navService.popChildNavPageWithPageTracking();
        this.navService.setChildNavRightPaneView(false);
      } else if (this.isLayoverPushView) {
        if(this.navService.getActiveChildNavViewPageName() == PageName.DynamicFormComponent){
          this.navService.popChildNavPageWithPageTracking();
          await this._initSetBookingDisplayForm(fetchData);
          this._initPageTitle();
        }else {
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.MedicalInsightPageComponent);
          this.uiService.showRightPane = false;
          this.navService.setChildNavRightPaneView(false);
        }
      }
      this.linkEntitiesReqPayload = [];
    }
  }


  public onButtonClicked(buttonId: string) {
    switch (buttonId) {
      case 'scrapsetbooking':
        this._scrapSetBooking();
        break;
      case 'editsetbooking':
        this._editSetBooking();
        break;
      case 'assignsetbooking':
        this._assignSetBooking();
        break;
      case 'close':
        this.onClosePage(true);
        break;
      case 'pluse-icon':
        this.handlePlusButton();
        //this.createMeeting();
        break;
      default:
        break;
    }
  }

  private _scrapSetBooking(isFromEdit:boolean=false):void {
    if(!this.device.isOffline){
      this.events.publish('deleteMeeting', this.currentSetBookingActivity, true);
    }
  }

  private async _editSetBooking(cloneFlow:boolean = false) {
    //Check Limited Release Kit Booking Message Flow
    if(!cloneFlow){
      if(!(await this._checkLimitedReleaseKitBookingFlow())){
        return;
      }
    }

    let setBookingForm: DynamicForm = this.dynamicFormsService.getFormDefinitionForEntity("indskr_setbooking", FormType.EDITFORM);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if (!setBookingForm) {
      return;
    }
    let setbookingRaw = this.currentSetBookingActivity.rawData;
    this._decryptPatientIdForEditing(setbookingRaw);
    let setBookingIdData = {
      indskr_setbookingid: this.currentSetBookingActivity.ID,
    };
    let existingLinkedEntityData = [];
    // if(this.contactService._defaultLinkedEntityMappingData) {
    //   existingLinkedEntityData = this.contactService._defaultLinkedEntityMappingData.filter(contact => contact.contactid === this.contactService.contactInformation.ID);
    // }
    const nonVisibleFormSection = this.setBookingDataService.formSectionConfigure?.filter(config => config['_indskr_mobileappform_value'] == setBookingForm.mobileFormId && !config['indskr_visibility']);
    setBookingForm.metadata.forEach(tab => {
      tab.controls.forEach(control => {
        if (control.dataType && control.attributeName && !_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase() == control.attributeName.toLowerCase())) {
          control.isVisible = false;
          control.forceHide = true;
        } else if (!control.dataType && control.subgrid && !_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase() == control.attributeName.toLowerCase())) {
          control.isVisible = false;
          control.forceHide = true;
        }
      });
    });

    let formDetails: DynamicFormComponentModel;
    if(this.currentSetBookingActivity["rawData"]["indskr_status"]==SetBookingStatus.DRAFT ||
       this.currentSetBookingActivity["rawData"]["indskr_status"]==SetBookingStatus.SUBMITTED ||
       this.currentSetBookingActivity["rawData"]["indskr_status"]==SetBookingStatus.SYNCERROR ||
       this.currentSetBookingActivity["rawData"]["indskr_status"]==SetBookingStatus.REQUESTED ){
      formDetails = {
        title: this.translate.instant('EDIT') + " " + this.currentSetBookingActivity.subject,
        LookupEntitySetName: 'indskr_setbookings',
        showLeftHeaderButton: true,
        leftHeaderBtnIcon: 'chevron-back-outline',
        showRightHeaderButton: true,
        rightHeaderImgSrcArray: ['assets/imgs/header_save.svg', 'assets/imgs/submit_white.svg', 'assets/imgs/header_cancel.svg'],
        rightHeaderBtnTextArray: [this.translate.instant('Save'), this.translate.instant('Submit'), this.translate.instant('SCRAP')],
        rightHeaderBtnIdsArray: ['SAVEKITBOOKING','SUBMITKITBOOKING', 'SCRAPKITBOOKING'],
        formMetadata: setBookingForm,
        formEntityObject: setbookingRaw,
        mainEntityObjectId: setBookingIdData,
        dynamicFormType: formType,
        linkedEntityValue: existingLinkedEntityData,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: DynamicFormComponentModel) => this._handleSetBookingFormComponentEvent(data, eventTarget),
      };
    }
    else{
      formDetails = {
        title: this.translate.instant('EDIT') + " " + this.currentSetBookingActivity.subject,
        LookupEntitySetName: 'indskr_setbookings',
        showLeftHeaderButton: true,
        leftHeaderBtnIcon: 'chevron-back-outline',
        showRightHeaderButton: true,
        rightHeaderImgSrcArray: ['assets/imgs/header_save.svg', 'assets/imgs/header_cancel.svg'],
        rightHeaderBtnTextArray: [this.translate.instant('Save'), this.translate.instant('SCRAP')],
        rightHeaderBtnIdsArray: ['SAVEKITBOOKING', 'SCRAPKITBOOKING'],
        formMetadata: setBookingForm,
        formEntityObject: setbookingRaw,
        mainEntityObjectId: setBookingIdData,
        dynamicFormType: formType,
        linkedEntityValue: existingLinkedEntityData,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: DynamicFormComponentModel) => this._handleSetBookingFormComponentEvent(data, eventTarget),
      };
    }

    this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, PageName.ActivitiesPageComponent, {
      MODE: 'EDIT_MODE',
      FORM_DETAIL: formDetails,
      LINKED_ENTITY_DB_PREFIX: this.setBookingLinkedEntityDBPrefix,
      RAW_DATA: this.rawFormData,
      REF_ENTITY_NAME: 'indskr_setbookingid'
    });
    this.footerService.initButtons(FooterViews.Accounts);
  }

  private async _assignSetBooking() {
    //populate users on UI
    let data=[];
    if (this.activityService.repUserList.length) {
      data = this.procedureTrackerDataService.users.filter((user) => user.userId != this.authService.user.xSystemUserID && user.userType && user.userType.includes(UserTypes.SALES_REP)).map(user => {
        return {
          id: user.userId,
          title: user.userFullName
        }
      });
    }
    data = _.orderBy(data, 'title');
    const userModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: data,
          isMultipleSelectionEnabled: false,
          title: this.translate.instant('USERS'),
          dividerTitle: this.translate.instant('ALL_USERS'),
          isSearchEnabled: true,
          confirmationCheckRequired: true,
          confirmationAlertData: {
            title: this.translate.instant('ARE_YOU_SURE'),
            message: this.translate.instant('ASSIGN_OWNER_WARNING'),
            confirmButtonText: this.translate.instant('ASSIGN'),
            cancelButtonText: this.translate.instant('CANCEL')
          },
          needcustomMessage: true
        }
      },
      backdropDismiss: false
    });
    userModal.present();
    userModal.onDidDismiss().then(async (obj) => {
      if (obj && obj.data && obj.data.isDone) {
        const data = obj.data;
        if (_.isEmpty(data.selectedItems)) {

        } else {
          try {
            const selectedUserID = data.selectedItems[0].id;
            this.currentSetBookingActivity.serviceDTO = {
              indskr_setbookingid: this.currentSetBookingActivity.ID,
              lookupfields: [
                {
                  name:"ownerid",
                  entity:"systemusers",
                  id: selectedUserID,
                }
              ],
            };
            //if sales consultant or team member, dont remove from agenda list
            //disk retrieval of teamMembers
            let teamMembersArray = await this.disk.retrieve(DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY + "indskr_setbookingteammember");
            let currentUserIsTeamMember = teamMembersArray && teamMembersArray.raw?.some((kitbookingobj) => kitbookingobj?.indskr_setbookingid == this.currentSetBookingActivity.ID && kitbookingobj["indskr_setbookingteammember.indskr_member"] == this.authService.user.xSystemUserID);
            //direct check for sales consultant
            let currentUserIsSalesConsultant = this.currentSetBookingActivity["rawData"]["_indskr_salesconsultant_value"] == this.authService.user.xSystemUserID;
            if(currentUserIsTeamMember || currentUserIsSalesConsultant){
              const action:OperationDetail = {
                onDynamics: true,
                onLocalDatabase: true,
                onLocalCopy: true,
                operationDetail: {
                  code: 'UPDATESETBOOKINGOWNER101',
                  message: 'Update Set Booking Owner'
                }
              };
              await this.setBookingDataService.updateSetBookingActivity(action,[this.currentSetBookingActivity],new Date().getTime()).then(async (res)=> {
                if(res) {
                  this.currentSetBookingActivity.ownerId = selectedUserID;
                }
                //this.uiService.dismissLoader();
              });
            } else{
                const action:OperationDetail = {
                  onDynamics: true,
                  onLocalDatabase: false,
                  onLocalCopy: false,
                  operationDetail: {
                    code: 'UPDATESETBOOKINGOWNER101',
                    message: 'Update Set Booking Owner'
                  }
                };
                await this.setBookingDataService.updateSetBookingActivity(action,[this.currentSetBookingActivity],new Date().getTime()).then(async (res)=> {
                  if(res) {
                    //remove from agenda list -> scrap flow
                    const action:OperationDetail = {
                      onDynamics: false,
                      onLocalDatabase: true,
                      onLocalCopy: true,
                      operationDetail: {
                        code: 'removesetbookingonassignmentchange',
                        message: 'Remove Set Booking Activity on Assignment Change'
                      }
                    };
                    this.currentSetBookingActivity.indskr_status = SetBookingStatus.CANCELLED;
                    this.currentSetBookingActivity.statusString = "Cancelled";
                    this.currentSetBookingActivity.serviceDTO = {
                      indskr_setbookingid: this.currentSetBookingActivity.ID,
                      indskr_status: SetBookingStatus.CANCELLED,
                    };
                    await this.setBookingDataService.updateSetBookingActivity(action,[this.currentSetBookingActivity],new Date().getTime()).then((res)=> {
                      if (!this.uiService.toolsActivityActive) {
                        this.events.publish("refreshAgenda");
                      } else this.uiService.agendaRefreshRequired = true;
                      if (this.navService.getActiveChildNavViewPageName() == PageName.SetBookingActivityDetailsComponent) {
                        this.uiService.showRightPane = false;
                        this.activityService.selectedActivity = null;
                        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
                      }
                    });
                  }
                  //this.uiService.dismissLoader();
              });
            }
          } catch (error) {
              console.log("Error in assigning kit booking" + error);
          }
        }
      }
    })
  }

  async handlePlusButton(){
    this.activityService.activitySource = ActivitySource.SET_BOOKING_ACTIVITY_DETAIL;
    //this.trackingService.tracking('KitBookingNewActivity', TrackingEventNames.SETBOOKING);
    //this.uiService.showNewActivity = true;
    if(this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent){
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      this.uiService.showRightPane = true;
      this.uiService.showNewActivity = false;
      await this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.SetBookingActivityDetailsComponent,
        { from: PageName.SetBookingActivityDetailsComponent, setBooking: this.currentSetBookingActivity }).then(( )=> {
          let startTime, endTime
          let preSelectedTime = {};
          if(this.currentSetBookingActivity) {
            startTime = this.currentSetBookingActivity['scheduledStart'] ? new Date(this.currentSetBookingActivity['scheduledStart']) : new Date();
            if(this.currentSetBookingActivity['scheduledEnd']) endTime = new Date(this.currentSetBookingActivity['scheduledEnd']);
            else {
              endTime = new Date();
              endTime = addMinutes(startTime, 30);
            }
          }
          preSelectedTime['startTime'] = startTime;
          preSelectedTime['endTime'] = endTime
          this.events.publish("updateNewTimeOffDateTime", {preSelectedTime});
        });
    }
  }

  public async createMeeting() {
    let loader = await this.loadingCtrl.create();
    loader.present();

    let startTime, endTime, kitBookingId;
    let contact: Contact = undefined;
    let account: Account = undefined;
    let subject: string = `${this.translate.instant('MEETING')}`

    // Prepare Paylod
    if(this.currentSetBookingActivity) {
      startTime = this.currentSetBookingActivity['scheduledStart'] ? new Date(this.currentSetBookingActivity['scheduledStart']) : new Date();
      if(this.currentSetBookingActivity['scheduledEnd']) endTime = new Date(this.currentSetBookingActivity['scheduledEnd']);
      else {
        endTime = new Date();
        endTime = addMinutes(startTime, 30);
      }

      kitBookingId = this.currentSetBookingActivity.ID;
      if(this.currentSetBookingActivity.rawData) {
        let accountId = this.currentSetBookingActivity.rawData['_indskr_accountnamesoldto_value'];
        if(accountId) account = this.accountService.getAccountById(accountId);
        let contactId = this.currentSetBookingActivity.rawData['_indskr_surgeoncontact_value'];
        if(contactId) contact = this.contactService.getContactByID(contactId);
      }
    }

    let payload: InitiateMeetingPayload = new InitiateMeetingPayload(subject,"",startTime.getTime(),endTime.getTime(),"", OFFLINE_ID_PREFIX + new Date().getTime(), "", null, kitBookingId);
    let response = await this.meetingService.createNewMeeting(payload);

    if(response) {
      let requestSuccess;
      this.activityService.selectedActivity = response;
      if (this.activityService.selectedActivity instanceof AppointmentActivity) {
        if(contact) {
          this.activityService.selectedActivity.contacts.push(contact);
          if(contact.addressesList && contact.addressesList.length){
            const primaryAddress = contact.addressesList.find((address) => address.isPrimary).compositeAdd;
            this.activityService.selectedActivity.location = primaryAddress;
          }
        }
        if(account) {
          this.accountService.moveAccountToSelected(account);
          let affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([account]);
          if (affiliatedContacts.length === 1) {
            this.activityService.selectedActivity.contacts.push(...affiliatedContacts);
          }
          if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
            await this.meetingService.addAccountsToMeeting(this.activityService.selectedActivity);
          }
        }
        if(this.activityService.selectedActivity['contacts']) {
          requestSuccess = await this.meetingService.addContactsToMeeting(this.activityService.selectedActivity, true);
          this.contentMatchService.isNewContactAddedToMeeting = true;
        }
        let payload = new UpdateMeetingPayload(
          subject,
          this.activityService.selectedActivity.location
        );
        if (!requestSuccess || this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)){
            await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
        } else{
            try {
              await this.meetingService.updateMeeting((this.activityService.selectedActivity as AppointmentActivity), payload);
            } catch (error) {
              console.error('Set Booing DetailsComponent: addNewMeeting: ', error);
              this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity, false, true);
            }
        }

        this.uiService.agendaRefreshRequired = true;
        this.events.publish("refreshAgenda");
        this.activityService.signalActivityConflictCheck$.next(this.activityService.selectedActivity);
        this.meetingStructureService.createEmbeddedInteractions(<AppointmentActivity>this.activityService.selectedActivity);
        this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.SetBookingActivityDetailsComponent);
        this.activityDataService.activityDetailsLoaded = true;
        loader.dismiss();
      }

    } else {
      loader.dismiss();
      console.error('createNewMeeting: No response!');
        if (!this.meetingService.isErrorStartDateCreateMeeting) {
          // Failed to create a meeting on non app environment. Alert user.
          this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_ERROR_CREATING_NEW_MEETING'), 'New Activity', 'top', ToastStyle.DANGER);
        }
        if (!this.meetingService.isErrorStartDateCreateMeeting) {
          this.meetingStructureService.createEmbeddedInteractions(null);
        }
        this.meetingService.isErrorStartDateCreateMeeting = false;
    }
  }

  private async _handleSetBookingFormComponentEvent(data, eventTarget){
    if(eventTarget){
      if(eventTarget == 'DONEBUTTONCLICK' || eventTarget == 'SUBMITKITBOOKING'){
        if(data && data.formValue) {
          if(eventTarget== 'SUBMITKITBOOKING'){
            this.trackingService.tracking('KitBookingSubmitClick',TrackingEventNames.KITBOOKING);
          }
          //check if any limited release products
          if(eventTarget== 'SUBMITKITBOOKING' && data.formValue["indskr_setbookingid"]){
            let setBookingProductsArray = await this.disk.retrieve(DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY + "indskr_setbookingproduct");
            let linkEntityProductsData = setBookingProductsArray && (setBookingProductsArray.raw).filter(x => x["indskr_setbookingid"] === data.formValue["indskr_setbookingid"] && (!x.hasOwnProperty('indskr_setbookingproduct.statecode') || (x.hasOwnProperty('indskr_setbookingproduct.statecode') && x['indskr_setbookingproduct.statecode'] == 0)));
            let selectedProductIds = [];
            if(linkEntityProductsData || this.linkEntitiesReqPayload) {
              if(this.linkEntitiesReqPayload && this.linkEntitiesReqPayload.length>0) {
                this.linkEntitiesReqPayload.forEach(payload => {
                  if(payload.values && payload.values.lookupfields && payload.values.lookupfields.length>0) {
                    payload.values.lookupfields.forEach((field)=> { if(field.name==="indskr_product") {selectedProductIds.push(field.id);}});
                  }
                });
              } else if(linkEntityProductsData && linkEntityProductsData.length>0){ //case when changes are saved earlier and submitted now
                linkEntityProductsData.forEach(pro => {
                  selectedProductIds.push(pro["indskr_setbookingproduct.indskr_product"])
                });
              }
            }
            if(selectedProductIds && selectedProductIds.length>0) {
              let flag=false;
              let productLimitedRelease = await this.setBookingDataService.getLimitedReleaseInfoForSelectedProduct(selectedProductIds);
              if(productLimitedRelease && productLimitedRelease.length>0) {
                const limitedReleaseProducts = productLimitedRelease.filter(pr => pr.indskr_limitedrelease);
                flag = limitedReleaseProducts.length > 0;
                if(limitedReleaseProducts && limitedReleaseProducts.length > 1){
                  this.notificationService.notify(this.translate.instant('KIT_BOOKING_LIMITED_RELEASE_ERROR_MSG'), 'New Activity', 'top', ToastStyle.DANGER);
                  return;
                }
              }
              if(flag){
                //if yes, check data.formValue has phmc field filled out
                let idx=-1;
                if (data.formValue.hasOwnProperty('lookupfields')) {
                  idx = data.formValue['lookupfields'].findIndex(a => a.name == "indskr_PHMCAccount" && !(a['isDeleted'] && a['isDeleted'] === true)&& a.id);
                }
                if(idx >=0 && this.authService.user.buSettings["indskr_limitedreleasebookingmessage"]){
                  let res = await this.alertService.showAlert({
                    header: this.translate.instant('NOTE'),
                    message: this.authService.user.buSettings["indskr_limitedreleasebookingmessage"],
                    backdropDismiss: false,
                    //buttons:[{text:this.translate.instant('OK'), role:"ok"}]
                  },this.translate.instant('OK'));
                  if(res && res.role && res.role == "cancel") return;
                  //this.uiService.displayLoader();
                }
                //if not, display error msg and break out of the function
                else if(idx<0){
                  this.notificationService.notify(this.translate.instant('KIT_BOOKING_PHMC_ERROR_MSG'), 'New Activity', 'top', ToastStyle.DANGER);
                  //this.uiService.dismissLoader();
                  return;
                }
              }
            }
          }
          //check if urgent kitbooking
          if(eventTarget == 'SUBMITKITBOOKING' && this.authService.user.buSettings["indskr_urgentkitbookingmessage"] && data.formValue["indskr_urgent"]){
            //this.notificationService.notify(this.authService.user.buSettings["indskr_urgentkitbookingmessage"],"setbookingedit");
            await this.alertService.showAlert({
              header: this.translate.instant('NOTE'),
              message: this.authService.user.buSettings["indskr_urgentkitbookingmessage"],
              backdropDismiss: false,
              buttons:[{text:this.translate.instant('OK'), role:"ok"}]
            })
          }

          //check if late kitbooking
          if(eventTarget == 'SUBMITKITBOOKING'
              && (this.authService.user.buSettings["indskr_latekitbookingdays"]
                  || this.authService.user.buSettings["indskr_latekitbookingdays"]==0)
              && this.authService.user.buSettings["indskr_latekitbookingmessage"]){
            let daysDiff = differenceInCalendarDays(data.formValue["indskr_startdate"], new Date())
            if(daysDiff >=0 && daysDiff<=this.authService.user.buSettings["indskr_latekitbookingdays"]){
              let res = await this.alertService.showAlert({
                header: "Booking required within "+this.authService.user.buSettings["indskr_latekitbookingdays"]+" days",
                message: this.authService.user.buSettings["indskr_latekitbookingmessage"],
                backdropDismiss: false
              }, this.translate.instant('SUBMIT'))
              if(res && res.role && res.role == "cancel") return;
            }
          }

          //check if start date is before current date
          if(eventTarget == 'SUBMITKITBOOKING'){
            const daysDiff = differenceInCalendarDays(data.formValue["indskr_startdate"], new Date())
            const diffInMin = differenceInMinutes(data.formValue["indskr_startdate"], new Date());
            if(daysDiff < 0 || (daysDiff === 0 && diffInMin < 0)){
              this.notificationService.notify(this.translate.instant('DATE_PICKER_START_DATE_CAN_NOT_BEFORE_CURRENT_DATE', {kitbookingaction:"submitted"}), 'New Activity', 'top', ToastStyle.DANGER);
              return;
            }
          }

          this.uiService.displayLoader();
          let taskScrap=[];
          if(this.linkEntitiesReqPayload && this.linkEntitiesReqPayload.length>0){
            for (let reqPayload of this.linkEntitiesReqPayload) {
              if(reqPayload.values && reqPayload.values.statecode && reqPayload.values.statecode == 1) taskScrap.push(this.dynamicFormsService.createLinkedEntity(reqPayload.values, reqPayload.entity));
            }
          }
          await Promise.all(taskScrap).then(()=>{}).catch(()=>{});
          let tasks=[];
          if(this.linkEntitiesReqPayload && this.linkEntitiesReqPayload.length>0){
            for (let reqPayload of this.linkEntitiesReqPayload) {
              if(!(reqPayload.values && reqPayload.values.statecode && reqPayload.values.statecode == 1)) tasks.push(this.dynamicFormsService.createLinkedEntity(reqPayload.values, reqPayload.entity));
            }
            this.linkEntitiesReqPayload = [];
          }
          // Handle Set Booking Update
          let newSetBooking = new SetBookingActivity(data.formValue);
          newSetBooking.allDayEvent = !isSameDay(newSetBooking.scheduledStart,newSetBooking.scheduledEnd);
          newSetBooking.serviceDTO = data.formValue;
          newSetBooking.serviceDTO['indskr_setbookingid'] = this.currentSetBookingActivity.ID;
          // Remove Default case team attribute if the user didn't change the value. Otherwise, it will trigger unnecessary workflow in Dynamics.
          if(this.currentSetBookingActivity.rawData['indskr_defaultcaseteam'] != undefined && this.currentSetBookingActivity.rawData['indskr_defaultcaseteam'] != null) {
            if(this.currentSetBookingActivity.rawData['indskr_defaultcaseteam'] == data.formValue['indskr_defaultcaseteam'])
              delete newSetBooking.serviceDTO['indskr_defaultcaseteam'];
          }
          // For Kit Booking Create and Edit form Hardcode the attribute names for start date and end date fields as all the validation and logics are hardcoded to specific attribute names
          // These hardcoded attribute names are reverted when form is closed on Save operation
          if (newSetBooking.serviceDTO['indskr_startdate']) {
            newSetBooking.serviceDTO['indskr_operationstartdate'] = newSetBooking.serviceDTO['indskr_startdate']
            delete newSetBooking.serviceDTO.indskr_startdate;
          }
          if (newSetBooking.serviceDTO['indskr_enddate']) {
            newSetBooking.serviceDTO['indskr_operationenddate'] = newSetBooking.serviceDTO['indskr_enddate']
            delete newSetBooking.serviceDTO.indskr_enddate;
          }
          if (this.currentSetBookingActivity?.indskr_status !=null  &&  eventTarget == 'SUBMITKITBOOKING') {
            newSetBooking.serviceDTO['indskr_status'] = SetBookingStatus.PENDINGSYNC;
            newSetBooking.statusString = 'Pending Sync';
          }
          const action:OperationDetail = {
            onDynamics: true,
            onLocalDatabase: true,
            onLocalCopy: true,
            operationDetail: {
              code: 'UPDATESETBOOKINGACTIVITY',
              message: 'Update Set Booking Activity'
            }
          }
          tasks.push(
            this.setBookingDataService.updateSetBookingActivity(action,[newSetBooking],new Date().getTime())
          );
            Promise.all(tasks).then(async (res) => {
              if(res) {
                await this.setBookingDataService.fetchRealTimeSetBookingDetails(newSetBooking);
                let rawDetails = newSetBooking.rawData;
                if(rawDetails) {
                  this.setBookingDataService._isCreateEditKitBookingFormDirty = false;
                  if(data && data.eventsHandler) {
                    if(eventTarget == "SUBMITKITBOOKING") this.notificationService.notify( this.translate.instant('KIT_BOOKING_SUBMITTED'), "ContactInfoComponent", "top", ToastStyle.INFO, 3000);
                    else  this.notificationService.notify( this.translate.instant('UPDATED_SUCCESS'), "ContactInfoComponent", "top", ToastStyle.INFO, 3000);
                    data.eventsHandler(res,'NEWDATAUPDATED');
                    if(eventTarget=="SUBMITKITBOOKING") this.onClosePage(true, false);
                  }
                }
              }
              this.uiService.dismissLoader();
            }).catch(()=>{
              this.uiService.dismissLoader();
            })
        }
      } else if(eventTarget == 'SAVEKITBOOKING'){
        if(this.device.isOffline) return;
        if(data && data.formValue) {
          this.uiService.displayLoader();
          let taskScrap=[];
          if(this.linkEntitiesReqPayload && this.linkEntitiesReqPayload.length>0){
            for (let reqPayload of this.linkEntitiesReqPayload) {
              if(reqPayload.values && reqPayload.values.statecode && reqPayload.values.statecode == 1) taskScrap.push(this.dynamicFormsService.createLinkedEntity(reqPayload.values, reqPayload.entity));
            }
          }
          await Promise.all(taskScrap).then(()=>{}).catch(()=>{});
          let tasks=[];
          if(this.linkEntitiesReqPayload && this.linkEntitiesReqPayload.length>0){
            for (let reqPayload of this.linkEntitiesReqPayload) {
              if(!(reqPayload.values && reqPayload.values.statecode && reqPayload.values.statecode == 1)) tasks.push(this.dynamicFormsService.createLinkedEntity(reqPayload.values, reqPayload.entity));
            }
            this.linkEntitiesReqPayload = [];
          }
          // Handle Set Booking Update
          let newSetBooking = new SetBookingActivity(data.formValue);
          newSetBooking.allDayEvent = !isSameDay(newSetBooking.scheduledStart,newSetBooking.scheduledEnd);
          newSetBooking.serviceDTO = _.cloneDeep(data.formValue);
          newSetBooking.serviceDTO['indskr_setbookingid'] = this.currentSetBookingActivity.ID;
          // Remove Default case team attribute if the user didn't change the value. Otherwise, it will trigger unnecessary workflow in Dynamics.
          if(this.currentSetBookingActivity && this.currentSetBookingActivity.rawData && this.currentSetBookingActivity.rawData['indskr_defaultcaseteam'] != undefined && this.currentSetBookingActivity.rawData['indskr_defaultcaseteam'] != null) {
            if(this.currentSetBookingActivity.rawData['indskr_defaultcaseteam'] == data.formValue['indskr_defaultcaseteam'])
              delete newSetBooking.serviceDTO['indskr_defaultcaseteam'];
          }
          // For Kit Booking Create and Edit form Hardcode the attribute names for start date and end date fields as all the validation and logics are hardcoded to specific attribute names
          // These hardcoded attribute names are reverted when form is closed on Save operation
          if (newSetBooking.serviceDTO['indskr_startdate']) {
            newSetBooking.serviceDTO['indskr_operationstartdate'] = newSetBooking.serviceDTO['indskr_startdate']
            delete newSetBooking.serviceDTO.indskr_startdate;
          }
          if (newSetBooking.serviceDTO['indskr_enddate']) {
            newSetBooking.serviceDTO['indskr_operationenddate'] = newSetBooking.serviceDTO['indskr_enddate']
            delete newSetBooking.serviceDTO.indskr_enddate;
          }
          const action:OperationDetail = {
            onDynamics: true,
            onLocalDatabase: true,
            onLocalCopy: true,
            operationDetail: {
              code: 'UPDATESETBOOKINGACTIVITY',
              message: 'Update Set Booking Activity'
            }
          }
          tasks.push(
            this.setBookingDataService.updateSetBookingActivity(action,[newSetBooking],new Date().getTime())
          );
          if(!this.device.isOffline) {
            Promise.all(tasks).then((res)=>{
                this.setBookingDataService.fetchRealTimeSetBookingDetails(newSetBooking).then(() => {
                  let rawDetails = newSetBooking.rawData;
                  if(rawDetails) {
                    this.setBookingDataService._isCreateEditKitBookingFormDirty = false;
                    if(data && data.eventsHandler) {
                      if(eventTarget == "SUBMITKITBOOKING") this.notificationService.notify( this.translate.instant('KIT_BOOKING_SUBMITTED'), "ContactInfoComponent", "top", ToastStyle.INFO, 3000);
                      else  this.notificationService.notify( this.translate.instant('UPDATED_SUCCESS'), "ContactInfoComponent", "top", ToastStyle.INFO, 3000);
                      data.eventsHandler(res,'NEWDATAUPDATED');
                    }
                  }
                  this.uiService.dismissLoader();
                }).catch(()=>{
                this.uiService.dismissLoader();
              })
            }).catch(()=>{
              this.uiService.dismissLoader();
            })
          } else {
            this.uiService.dismissLoader();
            this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'),'');
          }

        }
      } else if(eventTarget == 'CANCELBUTTONCLICK') {
        this.onClosePage((data && data.displayConfirmation));
      } else if(eventTarget == 'SCRAPKITBOOKING') {
        this._scrapSetBooking(true);
      } else if(eventTarget == 'ADDEDLINKEDENTITY' || eventTarget == 'SCRAPLINKEDENTITY') {
        if(data && data.entity && data.values) {
          this.addLinkEntitiesForSetBooking(data);
        }
      } else if(eventTarget == 'ISFORMDIRTY'){
        this.setBookingDataService._isCreateEditKitBookingFormDirty = data;
      }
    }
  }

  private addLinkEntitiesForSetBooking(linkEntity) {
    if (_.isEmpty(linkEntity))
      this.linkEntitiesReqPayload = [];
    else {
      const linkEntityPayload = {
        "values": linkEntity["values"],
        "entity": linkEntity["entity"],
        "linkedEntityId": linkEntity["linkedEntityExistingDataId"],
        "addedNewDataId": linkEntity["linkedEntityAddedNewDataId"]
      }
      if (!_.isEmpty(this.linkEntitiesReqPayload)) {
        let index;
        if (linkEntity["linkedEntityExistingDataId"]) {
          index = this.linkEntitiesReqPayload.findIndex(x => x.linkedEntityId == linkEntity["linkedEntityExistingDataId"]);
        } else {
          index = this.linkEntitiesReqPayload.findIndex(x => x.addedNewDataId == linkEntity["linkedEntityAddedNewDataId"]);
        }
        if (index != -1) {
          if(linkEntity["linkedEntityAddedNewDataId"] && !linkEntity["linkedEntityExistingDataId"] && linkEntityPayload.values.statecode == 1) this.linkEntitiesReqPayload.splice(index, 1);
          else this.linkEntitiesReqPayload[index] = linkEntityPayload;
        } else this.linkEntitiesReqPayload.push(linkEntityPayload);
      } else {
        this.linkEntitiesReqPayload.push(linkEntityPayload);
      }
    }
  }

  public onSectionHeaderControlClick(id:string){
    switch(id){
      case 'save_note':
        this.handleSaveNote();
        break;
      case 'attach_note':
        this.handleNoteAttachment();
        break;
      case 'kit_booking_activities_section_collapsible':
        this.isActivitiesSectionExpanded = !this.isActivitiesSectionExpanded;
        this._initActivitiesSectionHeader();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private handleNoteAttachment(){
    if(this.isKitBookingNotesAttachmentEnabled){
      try {
        this.attachInput.nativeElement.click();
      } catch (error) {
        console.log(error);
      }
    }
  }

  public async handleSaveNote() {
    if (!_.isEmpty(this.tempNoteText) || this.isAttachmentAdded) {
      // this.uiService.displayLoader();

      const loader = await this.loadingCtrl.create();
      loader.present();

      this.isSaveNotesEnabled = false;
      const now = new Date().getTime().toString();
      const payload = {
        notetext: this.tempNoteText,
        objectid: this.currentSetBookingActivity.ID,
        createdon: now,
        ownerid: this.authService.user.systemUserID,
        ownerName: this.authService.user.displayName,
        deleted: false,
      };

      if (this.base64str) {
        payload['isdocument'] = true;
        payload['documentbody'] = this.base64str;
        payload['filename'] = this.attachmentFile.name;
        payload['filesize'] = this.attachmentFile.size;
        payload['mimetype'] = this.attachmentFile.type;
      }

      let currentNote = new IONote(payload);
      if (!this.device.isOffline) {
        await this.setBookingDataService.uploadKitBookingNotesOnline(currentNote).then(res => {
          if (res && Array.isArray(res) && res.length > 0 && res[0].noteid) {
            //on success -> guid of the note-> save to current note
            currentNote.noteId = res[0].noteid;
            //save to offline db(dbNotes)
            this.setBookingDataService.saveKitBookingNotesOffline(currentNote);
          }
        });

      }
      this.currentKitBookingNotes.push(currentNote);
      this._sortKitBookingNotes();
      this.tempNoteText = '';
      this.removeAttachment(null);
      this.isSaveNotesEnabled = false;
      this._initKitBookingNotesViewData();
      loader.dismiss();
    }
  }

  private _sortKitBookingNotes(){
      if(this.currentKitBookingNotes && this.currentKitBookingNotes.length > 1){
        this.currentKitBookingNotes = this.currentKitBookingNotes.sort((a,b)=>{
          return (isBefore(a.createdTime, b.createdTime) ? 1 : -1);
        });
      }
    }

  public removeAttachment(event){
      try {
        this.attachInput.nativeElement.value = null;
      } catch (error) {
        console.log(error);
      }
      this.attachmentTitle = '';
      this.isAttachmentAdded = false;
      this.attachmentFile = null;
      this.base64str = null;
      this._initKitBookingNotesViewData();
    }

  private _initActivitiesSectionHeader() {
    this.kitBookingActivitiesHeader = {
      id: 'kit_booking_activities_section_header',
      title: this.translate.instant('ACTIVITIES'),
      controls: [{
        id: 'kit_booking_activities_section_collapsible',
        icon: this.isActivitiesSectionExpanded ? 'assets/imgs/up-arrow.svg' : 'assets/imgs/down-arrow.svg',
        isDisabled: false
      }],
    };
  }

  private _initKitBookingNotesViewData(){
      const isReadOnlyMode = this.device.isOffline || this.currentSetBookingActivity.indskr_status == SetBookingStatus.CANCELLED
                              || this.currentSetBookingActivity.indskr_status == SetBookingStatus.CANCELERROR
                              || this.currentSetBookingActivity.indskr_status == SetBookingStatus.PENDINGCANCEL
                              || this.currentSetBookingActivity.isTeamKitBooking;
      if (this.isKitBookingNotesEnabled || this.isKitBookingNotesAttachmentEnabled) {
       let controls: any[] = [];
       if (this.isKitBookingNotesAttachmentEnabled) {
         controls.push({
           id:'attach_note',
           text: this.translate.instant('ATTACH'),
           isDisabled: isReadOnlyMode || this.isPreViewMode ,
         });
       }
       controls.push({
         id: 'save_note',
         text: this.translate.instant('SAVE'),
         isDisabled: isReadOnlyMode || _.isEmpty(this.tempNoteText) && !this.isAttachmentAdded || this.isPreViewMode,
       });
       this.notesSectionHeaderViewData = {
         id: 'kit_booking_notes_section_header',
         title: this.translate.instant('KIT_BOOKING_NOTES'),
         controls: controls
       };
       let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
       this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
     }
   }

  private async initKitBookingNotesData(){
    this.currentKitBookingNotes = [];
    let dbNotes;
    if(this.currentSetBookingActivity.isTeamKitBooking)
      dbNotes =  this.setBookingDataService.teamKitBookingLinkedEntityData["annotation"];
    else
      dbNotes = await this.disk.retrieve(DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY+OFFLINE_DB_LINKED_ENTITY_NAME.KIT_BOOKING_NOTES);
    if(dbNotes && dbNotes.raw && Array.isArray(dbNotes.raw) && dbNotes.raw.length != 0){
      dbNotes.raw.forEach(item=>{
        if(this.currentSetBookingActivity &&
        item['annotation.objectid'] == this.currentSetBookingActivity.ID &&
        item['annotation.annotationid'] &&
        item['annotation.ownerid'] &&
        item['annotation.createdon']){
          const payload = {
            annotationid: item['annotation.annotationid'],
            objectid:item['annotation.objectid'],
            notetext: item['annotation.notetext'],
            filename: item['annotation.filename'],
            filesize: item['annotation.filesize'],
            documentbody: item['annotation.documentbody'],
            mimetype: item['annotation.mimetype'],
            deleted: item['annotation.deleted'],
            createdon: item['annotation.createdon'] ? new Date(item['annotation.createdon']).getTime().toString() : null,
            ownerName: item['annotation.ownerid@OData.Community.Display.V1.FormattedValue'],
            ownerid: item['annotation.ownerid'],
            isdocument: !_.isEmpty(item['annotation.filename']),
          };
          let currentNote = new IONote(payload);
          let idx = this.currentKitBookingNotes.findIndex(note => note.noteId == currentNote.noteId);
          if(idx >= 0){
            this.currentKitBookingNotes[idx] = currentNote;
          }else{
            this.currentKitBookingNotes.push(currentNote);
          }
        }
      });
    }
    this._sortKitBookingNotes();
  }

  public notesChanged(ev): void {
    if(ev && ev.target){
      this.tempNoteText = ev.target.value;
    }else{
      this.tempNoteText = '';
    }
    if(this.tempNoteText.length > 0){
      this.isSaveNotesEnabled = true;
    }else{
      this.isSaveNotesEnabled = false;
    }
    this._initKitBookingNotesViewData();
  }

  public isNoteDeletable(note: IONote): boolean {
    return ((this.isKitBookingNotesEnabled && this.isKitBookingNotesAttachmentEnabled) || (this.isKitBookingNotesEnabled && !note.hasDocument) || (this.isKitBookingNotesAttachmentEnabled && _.isEmpty(note.noteText))) && _.isEqual(note.ownerId, this.authService.user.systemUserID);
  }

  public isNoteEditable(note: IONote): boolean {
    return (this.isKitBookingNotesEnabled || this.isKitBookingNotesAttachmentEnabled) && _.isEqual(note.ownerId, this.authService.user.systemUserID);
  }

  public async loadImageFromDevice(event) {
    if (event && event.target && event.target.files) {
      try {
        const file = event.target.files[0];
        if ((file.size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(file.name)) {
            this.base64str = await toBase64(file);
            const dataURLPattern = /^data:[^;]+;base64,/;
            this.base64str = this.base64str.replace(dataURLPattern, '');
            this.attachmentFile = file;
            this.attachmentTitle = file.name;
            this.isAttachmentAdded = true;
            this._initKitBookingNotesViewData();
          } else {
            this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'), 'Account Info', 'top', 'info');
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION', { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }), 'Account Info', 'top', 'info');
          this.removeAttachment(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  public async updateNote(ev) {
    if (ev && ev.action) {
      const idx = this.currentKitBookingNotes.findIndex(note => note.noteId == ev.noteId);
      if (idx < 0) {
        console.error("Error : coundn't find note id --> ", ev.noteId);
        return
      }
      let existingNote = this.currentKitBookingNotes[idx];
      let payload: any = {
        annotationid: existingNote.noteId,
        objectid: this.currentSetBookingActivity.ID,
        notetext: existingNote.noteText,
        filename: existingNote.documentName,
        filesize: existingNote.documentSize,
        documentbody : existingNote.documentDataURL,
        mimetype: existingNote.documentMimeType,
        createdon: existingNote.createdTime.getTime().toString(),
        ownerName: existingNote.ownerName,
        ownerid: existingNote.ownerId,
        isdocument: existingNote.hasDocument,
      };
      if (ev.action == 'DELETE' && idx >= 0) {
        payload['deleted']= true;
      } else if (ev.action == 'SAVE' && idx >= 0) {
        payload.notetext = ev.updatedText;
        if (ev.attachmentFileUpdated) {
          payload['isdocument'] = true;
          payload['documentbody'] = ev.attachmentFileDataUrl;
          payload['filename'] = ev.documentName;
          payload['filesize'] = ev.documentSize;
          payload['mimetype'] = ev.documentMimeType;
          //payload['fileupdated'] = true;
        }
        if (ev.isAttachmentRemoved) {
          payload['isdocument'] = false;
          payload['documentbody'] = payload['filename'] = payload['filesize'] = payload['mimetype'] = '';
          //payload['fileremoved'] = true;
        }
      }
      //payload.documentbody = existingNote.documentDataURL;

      const loader = await this.loadingCtrl.create();
      loader.present();
      // Update Service
      this._sortKitBookingNotes();
      let payloadNote = new IONote(payload);
      if (!this.device.isOffline) {
        await this.setBookingDataService.uploadKitBookingNotesOnline(payloadNote).then(res => {
          if (res && Array.isArray(res) && res.length > 0 && res[0].noteid) {
            if (ev.action == 'DELETE' && idx >= 0) {
              this.currentKitBookingNotes[idx].isDeleted = true;
              //save to offline db(dbNotes)
              this.setBookingDataService.saveKitBookingNotesOffline(this.currentKitBookingNotes[idx]).then(()=>{
                this.currentKitBookingNotes.splice(idx, 1);
              });
            }
            else if (ev.action == 'SAVE' && idx >= 0) {
              this.currentKitBookingNotes[idx].noteText = payload.notetext;
              if (ev.attachmentFileUpdated) {
                this.currentKitBookingNotes[idx].hasDocument = true;
                this.currentKitBookingNotes[idx].documentName = ev.documentName;
                this.currentKitBookingNotes[idx].documentSize = ev.documentSize;
                this.currentKitBookingNotes[idx].documentMimeType = ev.documentMimeType;
                this.currentKitBookingNotes[idx].documentDataURL = ev.attachmentFileDataUrl;
                //payload['fileupdated'] = true;
              }
              if (ev.isAttachmentRemoved) {
                this.currentKitBookingNotes[idx].hasDocument = false;
                this.currentKitBookingNotes[idx].documentName = '';
                this.currentKitBookingNotes[idx].documentSize = 0;
                this.currentKitBookingNotes[idx].documentMimeType = '';
                this.currentKitBookingNotes[idx].documentDataURL = '';
                //payload['fileremoved'] = true;
              }
              this.currentKitBookingNotes[idx].noteId = res[0].noteid;
              //save to offline db(dbNotes)
              this.setBookingDataService.saveKitBookingNotesOffline(this.currentKitBookingNotes[idx]);
            }

          }
        });

      }
      loader.dismiss();
    }
  }

  async goToActivityDetails(id) {
    if(this.from == 'Meeting' || this.from == 'CaseManagement' || this.currentSetBookingActivity.isTeamKitBooking) return;
    const index = this.activityService.activities.findIndex(o => o.ID == id);
    let activity;
    if (index >= 0) {
        activity = this.activityService.activities[index];
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selectedActivity = this.activityService.activities[index];
    }else{
        return;
    }
    if (!this.device.isOffline && !id.includes('offline')) {
        if( activity instanceof AppointmentActivity){
          await this.activityDataService.updateActivityDetails(activity as AppointmentActivity);
        }
    }
    let masterPage = this.navService.getCurrentMasterPageName();
    if (activity instanceof AppointmentActivity){
      this.uiService.showCancelDoneOnActivityDetails = true;
      this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent,
        masterPage, { from: PageName.SetBookingActivityDetailsComponent, activity: activity }).then(succ=> {
          this.events.publish("detectChangesOnActivityDetails");
        })
    } else if (activity instanceof CaseActivity) {
      let iCase = await this.caseService.reInitDataDependency(activity);
      this.activityService.selectedActivity = iCase;
      this.caseService.assignSelectedCase(iCase);
      this.caseService.accessedFrom = AccesingMode.IN_SET_BOOKING;
      this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent,
        masterPage, { from: PageName.SetBookingActivityDetailsComponent });
      this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
    }
  }
  private _decryptPatientIdForEditing(rawObj){
    if (rawObj && rawObj['indskr_patientid']) {
      const transitmessage = rawObj['indskr_patientid'];
      const config = this.authService.user.ioConfigurations?.find(o => o.configName == "PatientIDKey");
      const configKey = config ? config.configValue: '';
      if (configKey) {
        try {
          let key = CryptoJS.enc.Utf8.parse(configKey);
          let encrypted = transitmessage;
          let iv = CryptoJS.lib.WordArray.create();//CryptoJS.enc.Hex.parse("0000000000000000");//
          let decrypted = CryptoJS.AES.decrypt(encrypted, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
          })
          rawObj['indskr_patientid'] = decrypted.toString(CryptoJS.enc.Utf8);;
        } catch (error) {
          console.log('Error Occured While Decrypting Str'+error)
        }
      }
    }
  }

  private async _checkLimitedReleaseKitBookingFlow(): Promise<boolean> {
    let flag = true;
    if (this.currentSetBookingActivity && this.currentSetBookingActivity.indskr_status != SetBookingStatus.DRAFT) {
      let setBookingProductsArray = await this.disk.retrieve(DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY + "indskr_setbookingproduct");
      let linkEntityProductsData = setBookingProductsArray && (setBookingProductsArray.raw).filter(x => x["indskr_setbookingid"] === this.currentSetBookingActivity.ID && (!x.hasOwnProperty('indskr_setbookingproduct.statecode') || (x.hasOwnProperty('indskr_setbookingproduct.statecode') && x['indskr_setbookingproduct.statecode'] == 0)));
      let selectedProductIds = [];
      if (linkEntityProductsData) {
        if (linkEntityProductsData && linkEntityProductsData.length > 0) {
          linkEntityProductsData.forEach(pro => selectedProductIds.push(pro["indskr_setbookingproduct.indskr_product"]));
        }
      }
      if (selectedProductIds && selectedProductIds.length > 0) {
        let productLimitedRelease = await this.setBookingDataService.getLimitedReleaseInfoForSelectedProduct(selectedProductIds);
        if (productLimitedRelease && productLimitedRelease.length > 0) {
          const limitedReleaseProducts = productLimitedRelease.filter(pr => pr.indskr_limitedrelease);
          if (limitedReleaseProducts.length > 0) {
            await this.alertService.showAlert({
              header: this.translate.instant('NOTE'),
              message: this.authService.user.buSettings["indskr_limitedreleasekitbookingmessageedit"],
              backdropDismiss: false,
              buttons: [{ text: this.translate.instant('OK'), role: "ok" }]
            });
            flag = false;
          }
        }
      }
    }
    return flag;
  }

}
//   private _decryptPatientIdForEditing(rawObj){
//     try {
//       if(rawObj && rawObj['indskr_patientid']){
//         const decodedString = atob(rawObj['indskr_patientid']);
//         // Convert the decoded string to UTF-8
//         const utf8String = decodeURIComponent(escape(decodedString));
//         rawObj['indskr_patientid'] = utf8String;
//         }
//     } catch (error) {
//       console.log('Error Occured While Decrypting Str')
//     }
//   }
// }
