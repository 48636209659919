import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { MainCardViewDataModel } from '../../../../models/MainCardViewDataModel';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { Subscription } from 'rxjs';
import { MyAssistantService, NOTIFICATION } from '@omni/services/my-assistant/my-assistant.service';
import { IndNotificationDataModel } from '@omni/models/indNotificationDataModel';

const DefaultPrimaryTxtWidth = '40%';
const DefaultFirstSecondaryTxtWidth = '60%';
const DefaultSecondSecondaryTxtWidth = '80%';
/**
 * The MainCardView component is a reusable Angular component designed to display various types of data in a visually appealing card format. It utilizes the MainCardViewDataModel interface to define the structure and properties of the card data.
 * Provides options to customize card styling including CSS classes, icons, and end elements to align with application design guidelines.
 * @Component
 */
@Component({
  selector: 'main-card',
  templateUrl: 'main-card.html',
  styleUrls:['main-card.scss']
})
export class MainCardComponent {
  defaultPrimaryTxtWidth = DefaultPrimaryTxtWidth;
  defaultFirstSecondaryTxtWidth = DefaultFirstSecondaryTxtWidth;
  defaultSecondSecondaryTxtWidth = DefaultSecondSecondaryTxtWidth;

  @Input() isSkeleton: boolean = false;
  @Input() skeletonConfig: {
                              primaryTxtWidth: string,
                              firstSecondaryTxtWidth: string,
                              secondSecondaryTxtWidth: string
                            }
                          = {
                              primaryTxtWidth: DefaultPrimaryTxtWidth,
                              firstSecondaryTxtWidth: DefaultFirstSecondaryTxtWidth,
                              secondSecondaryTxtWidth: DefaultSecondSecondaryTxtWidth
                          };
  @Input() viewData: MainCardViewDataModel;
  @Input() isDeviceOnline: boolean = true;
  @Output() onEndIconClick = new EventEmitter<any>();
  labelClassName: string;
  expanded:boolean = false;
  private IcurrentNotifSubscription: Subscription;
  private subItemSelected: IndNotificationDataModel = null;

  @Input() highlightMatchKey: string;

  constructor(public translate: TranslateService,
    private datePipe: DatePipe,public dateTimeFormatsService: DateTimeFormatsService,
    public myAssistantService: MyAssistantService,) {
  }

  public ngOnInit() {
    this.labelClassName = (this.viewData && this.viewData.enableCopyPaste)?(this.viewData && !this.viewData.isAccordionForm)?'text-wrapper enableCopyPaste':'accordion-text-wrapper enableCopyPaste':(this.viewData && !this.viewData.isAccordionForm)?'text-wrapper':'accordion-text-wrapper'
    if(this.viewData && this.viewData.noLeftMarginLabel) this.labelClassName += ' noLeftMargin';
    if(this.viewData && this.viewData.labelItemCssClass) this.labelClassName += ` ${this.viewData.labelItemCssClass}`;
    if( this.viewData && this.viewData.isShowChildNotification) this.expanded = this.viewData.isSelected
  
    this.IcurrentNotifSubscription = this.myAssistantService.selectedNotificationObs$.subscribe((model: IndNotificationDataModel) => {
      this.subItemSelected = model;
    });

  }

  public handleMainItemClick(event, specificClickTarget?: string) {
    if(this.viewData && this.viewData.isShowChildNotification){
      this.expanded = !this.expanded;
      return
    }
    if (this.viewData.clickHandler) {
      this.viewData.clickHandler(this.viewData.id, event, specificClickTarget, this.viewData);
      try {
        if (event) {
          event.stopPropagation();
        }
      } catch (error) {
        // Handle Error To Do
      }
    }
  }

  public mapChildNotificationMainCard(data){

    return {
      id: data.dbKey,
      fixedHeight: true,
      isExpandable: true,
      isExpanded: true,
      expandableViewType: 'notificationList',
      expandableData: data.data,
      expandHeight: 20,
      noSeparationLine: false,
      isSelected: (this.subItemSelected && this.subItemSelected.dbKey == data.dbKey),
      primaryTextRight: null,
      secondaryTextRight: this._getFormattedDate(new Date(data.DateTime)),//format(data.DateTime, this.dateTimeFormatsService.dateTimeToUpper),
      tertiaryTextRight:data?.data?.indskr_description,
      isShowTertiaryTextRight:(data.type=='CUSTOM_NOTIFICATION')?true:false,
      showArrow: false,
      showIcon: true,
      iconName:  data.icon ? 'notification-' + data.icon : null,
      clickHandler: data.handleItemClick,
      date: new Date(data.DateTime),//this._getFormattedDate(new Date(data.DateTime)),
      showStartIcon: !data.isRed,
      startIconName: "indegene-assets/imgs/notification_bluedot.svg"
    };

  }

  private _getFormattedDate(date) {
    return this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  handleExpandClick(event){
    event.stopPropagation();
    this.expanded = !this.expanded;
    this.viewData.isSelected = !this.viewData.isSelected

  }

  public onFocusOutNumberInput(event) {
    if(event && event.target && event.target.value) {
      let value = parseInt(event.target.value);
      if(!value || (!_.isNaN(value) && typeof(value) != "number")) event.target.value = 1;
      else if (value && typeof(value) == "number") event.target.value = value;
    }
  }
}
