import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QuotePriceList, QuotesToolTab } from '@omni/classes/quotes/quotes.class';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { Currency } from '@omni/classes/marketing-management/marketing-plan.class';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { ModalController, PopoverController } from '@ionic/angular';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { AccountPageComponent } from '@omni/components/account/account-page/account-page';
import { AccountOfflineService, AccountSelectedFor } from '@omni/services/account/account.offline.service';
import { SelectListDetail } from '@omni/models/select-list-detail-model';
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { QuotesService } from '@omni/services/quote/quote-tool.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { DeviceService } from '@omni/services/device/device.service';
import { QuoteToolDataService } from '@omni/data-services/quote-tool/quote-tool-data.service';
import { QuoteDetailsComponent } from '../quote-details/quote-details.component';
import { TrackService, TrackingEventNames } from '@omni/services/logging/tracking.service';

@Component({
  selector: 'app-new-quote',
  templateUrl: './new-quote.component.html',
  styleUrls: ['./new-quote.component.scss'],
})
export class NewQuoteComponent implements OnInit, OnDestroy {
  @Input() nestedRoute = false;
  @Input() callback?: () => Promise<void>;
  pageTitle: IndPageTitleViewDataModel;
  detailHeaderModel: IndSectionHeaderViewDataModel;
  currentQuote: any = {};
  priceLists: QuotePriceList[] = [];
  ngUnSubscibe$ = new Subject<boolean>();
  _isClicked = [];
  constructor(
    public uiService: UIService,
    private device: DeviceService,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private navService: NavigationService,
    private popoverCtrl: PopoverController,
    private quoteOfflineService: QuotesService,
    private accountService: AccountOfflineService,
    private quoteDataService: QuoteToolDataService,
    public marketingPlansManagementOfflineService: MarketingPlansManagementOfflineService,
    private trackingService: TrackService,
  ) { }

  ngOnInit() {
    if (this.quoteOfflineService.preloadedData) {
      this.currentQuote = _.cloneDeep(this.quoteOfflineService.preloadedData);
    }
    this.initPageTitle();
    this.initializeAPDetailHeader();
    this.priceLists = this.quoteOfflineService.priceLists;
  }

  private initPageTitle(): void {
    const controls = [{
      id: "cancel",
      imgSrc: 'assets/imgs/header_cancel.svg',
      name: this.translate.instant('CANCEL'),
      isDisabled: false,
      align: "right"
    }, {
      id: 'confirm',
      imgSrc: 'assets/imgs/header_complete.svg',
      name: this.translate.instant('CREATE'),
      isDisabled: !this.isValid,
      align: "right"
    }
    ];
    this.pageTitle = {
      id: 'new-quotes',
      title: this.currentQuote?.name ?? this.translate.instant('QUOTES'),
      controls: controls,
    };
  }

  get isValid() {
    return (
      !_.isEmpty(this.currentQuote.name) &&
      !_.isEmpty(this.currentQuote.transactionCurrencyId) &&
      !_.isEmpty(this.currentQuote.priceLevelId) &&
      !_.isEmpty(this.currentQuote.accountId)
    );
  }

  private initializeAPDetailHeader() {
    this.detailHeaderModel = {
      id: 'quotes-detail-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: [],
    };
  }

  onPageTitleControlClick(id) {
    switch (id) {
      case 'confirm':
        this.createQuote();
        break;
      case 'cancel':
      case 'close':
        this.onCancel();
        break;
      default:
        break;
    }
  }

  getNameFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('NAME'),
      inputText: this.currentQuote.name,
      inputValue: this.currentQuote.name,
      id: 'name-field',
      placeholderLabel: this.translate.instant('NAME'),
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: true,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['name-field'] && _.isEmpty(this.currentQuote.name),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event)
    };
    return viewData;
  }

  getCurrencyFormField(): IndFormFieldViewDataModel {
    const currencyPreloaded = this.quoteOfflineService.preloadedData && this.quoteOfflineService.preloadedData.currencyName;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('CURRENCY'),
      inputText: this.currentQuote.currencyName ?? "",
      customPlaceholderLabel: this.translate.instant('SELECT_CURRENCY'),
      id: 'currency',
      isReadOnly: true,
      isDisabled: currencyPreloaded,
      showArrow: !currencyPreloaded,
      isRequired: true,
      formFieldType: FormFieldType.POPOVER_SELECT,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['currency'] && _.isEmpty(this.currentQuote.currencyName),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event),
    }
    return viewData;
  }

  getAccountsFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT'),
      inputText: this.currentQuote.accountName,
      inputValue: this.currentQuote.accountName,
      customPlaceholderLabel: this.translate.instant('SELECT_ACCOUNT'),
      id: 'account-field',
      isReadOnly: true,
      isDisabled: false,
      isRequired: true,
      showArrow: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['account-field'] && _.isEmpty(this.currentQuote.accountName),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event),
    };
    return viewData;
  }

  getPriceListFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PRICE_LIST'),
      inputText: this.currentQuote.priceLevelName,
      inputValue: this.currentQuote.priceLevelName,
      customPlaceholderLabel: this.translate.instant('SELECT_PRICE_LIST'),
      id: 'price-list-field',
      isReadOnly: true,
      showArrow: true,
      isDisabled: false,
      isRequired: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['price-list-field'] && _.isEmpty(this.currentQuote.priceLevelName),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event),
    };
    return viewData;
  }

  private handleFormFieldEvent(id, event) {
    switch (id) {
      case 'name-field':
        this._handleNameFieldChange(event)
        break;
      case "currency":
        this.openCurrencySelect(event);
        break;
      case "account-field":
        this.openAccountsSection();
        break;
      case "price-list-field":
        this.openPriceListSelect();
        break;
      default:
        break;
    }

    this._isClicked[id] = true;
  }

  private _handleNameFieldChange(event) {
    if (!event || event.target.value === undefined) return;
    this.currentQuote.name = event.target.value;
    this.initPageTitle();
  }

  private async openCurrencySelect(event) {
    let options = [];

    const currencies: Currency[] = _.cloneDeep(this.marketingPlansManagementOfflineService.currencies);
    currencies.forEach(({ id, name }: Currency) => {
      let isSelected: boolean = this.currentQuote && this.currentQuote.transactionCurrencyId && this.currentQuote.transactionCurrencyId === id;
      options.push({ id, title: name, isSelected: isSelected });
    });
    options = this.marketingPlansManagementOfflineService.sortListByFielName(options, 'title');
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'currency-select',
      data: options,
      isMultipleSelectionEnabled: false,
    };

    let dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail
      },
      cssClass: 'dropdown-list-view',
      event
    });

    dropdownPopover.onDidDismiss().then(async ({ data }) => {
      if (!data || !data.selectedItems.length) return;
      if (this.currentQuote.transactionCurrencyId && data.selectedItems[0].id === this.currentQuote.transactionCurrencyId) return;

      const currency: Currency = currencies.find(currency => currency.id === data.selectedItems[0].id);
      this.currentQuote.transactionCurrencyId = currency.id;
      this.currentQuote.currencyName = currency.name;

      if (this.currentQuote.priceLevelId && this.isPriceListOfDifferentCurrency()) {
        this.currentQuote.priceLevelId = "";
        this.currentQuote.priceLevelName = "";
      }

      this.initPageTitle();
    });
    dropdownPopover.present();
  }

  private openAccountsSection() {
    this.accountService.accessedAccountListFrom = PageName.NewQuoteComponent;
    this.accountService.accountPageMode = ComponentViewMode.SELECTION;
    this.accountService.selectedFor = AccountSelectedFor.GENERAL_SINGLE_SELECTION
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, {
      'listMode': ComponentViewMode.SELECTION,
      selectedAccountId: this.currentQuote.accountId,
      callbackEvent: (data: any) => this._handleAccountComponentCallback(data)
    }, PageName.AccountPageComponent).then(() => { });
  }

  private _handleAccountComponentCallback(data) {
    if (data.clearValue) {
      this.currentQuote.accountId = "";
      this.currentQuote.accountName = "";
    }

    if (data && data.isDone) {
      const { selectedItem } = data
      this.currentQuote.accountId = selectedItem.id;
      this.currentQuote.accountName = selectedItem.name;
    }

    this.initPageTitle();
  }

  async openPriceListSelect() {
    if (!this.priceLists.length) return;

    let priceLists: any = this.priceLists;
    if (!_.isEmpty(this.currentQuote.transactionCurrencyId)) {
      priceLists = priceLists.filter((priceList) => priceList.currencyId === this.currentQuote.transactionCurrencyId);
    }
    priceLists = priceLists.map(({ id, name }) => ({ id, title: name, isSelected: id === this.currentQuote.priceLevelId }));

    const selectListDetail: SelectListDetail = {
      isMultipleSelectionEnabled: false,
      isSearchEnabled: true,
      title: this.translate.instant('PRICE_LIST'),
      dividerTitle: this.translate.instant('ALL_PRICE_LIST'),
      data: priceLists
    };
    let selectCountryModal = await this.modalCtrl.create({
      component: SelectListComponent, componentProps: {
        viewData: selectListDetail
      }, cssClass: 'select_country', backdropDismiss: false
    });
    selectCountryModal.present();

    selectCountryModal.onDidDismiss().then(async ({ data }: any) => {
      if (!data || !data.isDone) return;
      this.currentQuote.priceLevelId = data.selectedItems[0]?.id ?? null;
      this.currentQuote.priceLevelName = data.selectedItems[0]?.title ?? null;
      if (data.selectedItems.length > 0 && _.isEmpty(this.currentQuote.transactionCurrencyId)) {
        const selectedPriceList = this.priceLists.find(priceList => priceList.id === this.currentQuote.priceLevelId);
        this.currentQuote.transactionCurrencyId = selectedPriceList.currencyId;
        this.currentQuote.currencyName = selectedPriceList.currencyName;
      }
      this.initPageTitle();
    });
  }

  isPriceListOfDifferentCurrency() {
    const currentPriceList = this.priceLists.find(priceList => priceList.id === this.currentQuote.priceLevelId);
    return currentPriceList && currentPriceList.currencyId !== this.currentQuote.transactionCurrencyId;
  }

  onCancel() {
    if (this.nestedRoute) {
      this.navService.popChildNavPageWithPageTracking();
    } else {
      this.navService.setChildNavRightPaneView(false);
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent, {
        deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'
      });
    }
    this.quoteOfflineService.createdFrom = null;
  }

  async createQuote() {
    try {
      if (this.callback && typeof this.callback !== 'function') {
        throw new Error('Callback input is not a function');
      }

      this.uiService.displayLoader();
      this.currentQuote.statuscode = 8;
      const createdQuotes = await this.quoteDataService.createQuote(this.currentQuote);
      this.trackingService.tracking('QuoteCreate', TrackingEventNames.QUOTE);
      this.uiService.dismissLoader();

      if (this.callback) {
        this.callback();
      }

      if (this.quoteOfflineService.preloadedData) {
        this.navService.popChildNavPageWithPageTracking();

        this.navService.pushChildNavPageWithPageTracking(QuoteDetailsComponent, PageName.QuoteDetailsComponent, PageName.QuotePageComponent, {
          selectedQuote: createdQuotes,
          selectedTab: QuotesToolTab.QUOTES,
          nestedRoute: true
        });
      }

    } catch (error) {
      console.log('Error during creation of quote', error)
    }
  }

  ngOnDestroy(): void {
    this.quoteOfflineService.preloadedData = null;
  }

}
