<ion-header>
    <ind-header-left [viewData]="indHeaderLeftModel" [searchDisabled]="contactService.contactPageMode == this.compViewMode.READONLY" 
      [hideFilterButton]="hideFilterButton" [(searchText)]="contactSearchText" 
      (onControlClick)="onPageTitleControlClick($event)" (onSearchChange)="onInput($event.detail)" 
      (onSearchFocus)="ionSearchFocus($event)" (onSearchBarClick)="clickSearchArea($event)" 
      (onFilterClick)="openFilterMenu($event)" (onEnterClick)="onClickEnter($event)" [filterButtonBadgeCount]="getFilterButtonBadgeCount()"  (ionBlur)="onSearchBlur($event)">
    </ind-header-left>
    <!-- Key Controls Area -->
    <ion-item-divider sticky='true' class="keyControlsArea" color="light" *ngIf="contactService.contactPageMode!=compViewMode.READONLY">
        <div class="key-container">
            <!-- contact tag buttons -->
            <ng-container *ngIf="showUserContactTag ; else showRemainingButtons">
                <ng-template #showAddToTagButton>
                    <ion-button slot="end" icon-only fill="clear" color="primary" (click)="cancelTagSelection()">
                        {{ 'CANCEL' | translate}}
                    </ion-button>
                    <ion-button slot="end" icon-only fill="clear" [disabled]="disableAddToTag" *ngIf="!showRemoveFromTag" color="primary" (click)="addToTag()">
                        {{'ADD_TO_TAG' | translate }}
                    </ion-button>
                    <ion-button slot="end" icon-only fill="clear" [disabled]="disableAddToTag" *ngIf="!showRemoveFromTag" color="primary" (click)="createNewTagAlert()">
                        {{'CREATE_NEW_TAG' | translate }}
                    </ion-button>
                    <ion-button slot="end" icon-only fill="clear" [disabled]="disableAddToTag" *ngIf="enableAssessment && !device.isOffline" color="primary" (click)="openAssessment()">
                        {{'ASSESSMENT' | translate }}
                    </ion-button>
                    <ion-button slot="end" icon-only fill="clear" color="primary" (click)="selectAllContactsForAssessment()">
                      {{'SELECT_ALL' | translate }}
                    </ion-button>
                    <ion-button slot="end" icon-only fill="clear" [disabled]="disableAddToTag" *ngIf="!showRemoveFromTag" color="primary" (click)="deselectAllContactsForAssessment()">
                      {{'CLEAR' | translate }}
                    </ion-button>
                </ng-template>
                <ng-container *ngIf="showAndRemoveTagContacts && !isClickedTagSelectBtn;else showAddToTagButton">
                    <ion-button item-left icon-only fill="clear" color="primary" (click)="saveAdvancedSearch()" [disabled]="!searching || disableSaveSearch">
                        {{ 'SAVE_SEARCH' | translate}}
                    </ion-button>
                    <ion-button slot="end" icon-only fill="clear" color="primary" *ngIf="enableGlobalSearch" (click)='searchContactGlobally()' [disabled]="isAssessmentFilter || !this.searching || this.device.isOffline">
                        {{'SEARCH_ALL_WITH_TEXT' | translate : {text: globalCustomerText} }}
                    </ion-button>
                    <ion-button slot="end" icon-only fill="clear" color="primary" [disabled]="!(this.selectedContactsFromTag.length && isAccessedFromContactToolsDrawer)" (click)="selectContactsFromTag(selectedTagData)">
                        {{'SELECT' | translate }}
                    </ion-button>
                </ng-container>

                <ion-button slot="end" icon-only fill="clear" color="primary" *ngIf="showRemoveFromTag" [disabled]="disableAddToTag" (click)="removeContactsFromTag(selectedTagData)">
                    {{'REMOVE_FROM_TAGS' | translate }}
                </ion-button>
            </ng-container>
            <!-- contact tag buttons ends -->

            <ng-template #showRemainingButtons>
                <ion-button item-left icon-only fill="clear" color="primary" (click)="saveAdvancedSearch()" [disabled]="!searching || disableSaveSearch">
                    {{ 'SAVE_SEARCH' | translate}}
                </ion-button>
                <ion-button slot="end" icon-only fill="clear" color="primary" *ngIf="enableGlobalSearch" (click)='searchContactGlobally()' [disabled]="isAssessmentFilter || !this.searching || this.device.isOffline">
                    {{'SEARCH_OTHER_WITH_TEXT_CONTACT' | translate : {text: globalCustomerText} }}
                </ion-button>
                <ion-button slot="end" icon-only fill="clear" color="primary" *ngIf="!contactService.isInGlobalSearch && enableVeevaSearch" (click)='loadVeevaWidget()' [disabled]="this.device.isOffline">
                    {{'SEARCH_ALL_VEEVA_CONTACTS' | translate : {text: globalCustomerText} }}
                </ion-button>
                <ion-button slot="end" icon-only fill="clear" color="primary" *ngIf="enableOneKeySearch" (click)='searchOneKeyContacts()' [disabled]="isAssessmentFilter || !this.searching || this.device.isOffline">
                    {{'SEARCH_ONEKEY' | translate }}
                </ion-button>
                <ion-button slot="end" icon-only fill="clear" color="primary" *ngIf="!contactService.isInGlobalSearch && enableVeevaSearch" (click)='loadVeevaMyRequestsWidget()'>
                    {{'MY_VEEVA_CHANGE_REQUESTS' | translate}}
                </ion-button>
                <ion-button slot="end" icon-only fill="clear" color="primary" *ngIf="!contactService.isInGlobalSearch && enableCreateContact" (click)='addNewContact()' [disabled]="isContactCreateDisabled">
                    {{'CREATE' | translate}}
                </ion-button>
                <ion-button slot="end" icon-only fill="clear" color="primary" *ngIf="isAccessedFromContactToolsDrawer" (click)="showSelectOptionForTag()">
                    {{'SELECT' | translate }}
                </ion-button>
                <ion-button *ngIf="contactService.isInGlobalSearch && enableGlobalSearch && !isGlobalContactAddedToMeeting" slot="end" icon-only fill="clear" color="primary" (click)="addContactToMyPosition()" [disabled]="!selectedGlobalSearchContact || this.device.isOffline">
                    {{ 'ADD' | translate}}
                </ion-button>
                <ion-button *ngIf="contactService.isOneKeySearching && enableOneKeySearch" slot="end" icon-only fill="clear" color="primary" (click)="addToPopulation()" [disabled]="contactService.selectedOKContacts.length ==0">
                    {{ 'ADD' | translate}}
                </ion-button>
                <ion-button *ngIf="!contactService.isInGlobalSearch && enableEventCreateContact && contactService.accessedContactListFrom === pagename.EventDetailsPageComponent && !this.uiService.isConsentFromToolDrawer" [disabled]="isEventContactCreateDisabled" slot="end"
                    icon-only fill="clear" color="primary" (click)="addNewContact()">
                    {{ 'CREATE' | translate}}
                </ion-button>
                <ion-button *ngIf="!contactService.isInGlobalSearch && enableEventCreateContact && contactService.accessedContactListFrom === pagename.EventDetailsPageComponent && !this.uiService.isConsentFromToolDrawer" [disabled]="isEventContactCreateDisabled || this.isDisabledSubmitAll" slot="end"
                    icon-only fill="clear" color="primary" (click)="submitAllForApproval()">
                    {{ 'SUBMIT_ALL_EVENT' | translate}}
                </ion-button>
                <ion-button slot="end" icon-only fill="clear" *ngIf="isKOLStatusEnabled" color="primary" (click)="openKOLManagement()">
                  {{'KOL_MANAGEMENT' | translate }}
                </ion-button>
                <ion-button slot="end" icon-only fill="clear" *ngIf="isMedicalInsightEnabled" color="primary" (click)="openMedicalInsight()">
                  {{'COLLABORATIVE_INSIGHTS' | translate }}
                </ion-button>
                <ion-button [disabled]="device.isOffline" *ngIf="isCustomeListManagementEnabled" slot="end" icon-only fill="clear" color="primary" (click)="openManageList()">
                  {{'MANAGE_LIST' | translate }}
                </ion-button>
            </ng-template>
        </div>
    </ion-item-divider>
    <!-- Selected Suggestion -->
    <div class="selectedSearchSuggestions" color="light" *ngIf="selectedSuggestionsData && selectedSuggestionsData.length">
      <ion-icon name="ios-close-circle" (click)="removeAllSelectedSuggestions()" class="clearSuggestions"></ion-icon>
      <span *ngFor="let suggestion of selectedSuggestionsData" class="suggestionNameBubble">
        {{suggestion.selectedFacet}}
        <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removeSelectedSuggestion(suggestion)" />
      </span>
    </div>
    <!-- Segment Tabs -->
    <ion-toolbar no-padding *ngIf="!showUserContactTag && enableCreateContact">
        <ind-tabs [data]="tabsData" [(selectedTab)]="contactsModel" (selectedTabChange)="checkContactModel($event)">
        </ind-tabs>
    </ion-toolbar>
    <!-- ComponentChange Tabs -->
    <ion-toolbar no-padding *ngIf="hasComponentChangeTab">
      <ind-tabs [data]="tabsComponentData" [(selectedTab)]="componentModel" (selectedTabChange)="checkComponentModel($event)">
      </ind-tabs>
    </ion-toolbar>
    <!-- Selected Filter -->
    <div class="selectedSearchSuggestions" color="light" *ngIf="selectedFilterData && selectedFilterData.length">
      <span *ngFor="let filter of filterNameBubble; let i=index">
        <!-- <ion-text class="category-name" *ngIf="(suggestion.categoryName=='Assessed' && selectedFilterData[i-1]?.categoryName !='UnAssessed')||(suggestion.categoryName=='UnAssessed' && selectedFilterData[i-1]?.categoryName!='Assessed')"> {{'ASSESSMENTS' | translate }} : </ion-text>
        <ion-text class="category-name" *ngIf="!(suggestion.categoryName=='Assessed'||suggestion.categoryName=='UnAssessed') && suggestion.categoryName!=selectedFilterData[i-1]?.categoryName"> {{suggestion.categoryName}} : </ion-text> -->
        <ion-text *ngIf="filter.isSavedFilter && i==0" class="category-name"> {{filter.savedFilterName}} : </ion-text>
        <ion-text *ngIf="!filter.isSavedFilter" class="category-name"> {{filter.categoryName}} : </ion-text>
        <span *ngFor="let facet of filter.suggestion" class="suggestionNameBubble">
          {{facet.selectedFacet}}
          <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removeSelectedFilter(facet, filter)" />
        </span>
      </span>
    </div>
</ion-header>

<ion-content #content [ngClass]="{'topMargin': contactService.isConsentInvoked, 'vectorimageleft': showVectorImage(), 'show-progress-bar-pad': device.isBackgroundUploadInProgress && contactService.contactPageMode==compViewMode.READONLY}" no-padding>
    <ng-container *ngIf="!showAndRemoveTagContacts; else showRemoveTagContactsList">
        <div [ngSwitch]="contactsModel" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
            <div *ngSwitchCase="'myContacts'">
                <ion-item-group [ngClass]="{'selectedContactContainer':$any(activityService.selectedActivity)?.contacts?.length>0}">
                    <!-- Section for matched/selected customers -->
                    <ind-section-header *ngIf="(!restrictedSelectedContactsViewActive && (contactService.contactPageMode===compViewMode.ADDNEW || contactService.contactPageMode===compViewMode.READONLY) && (selectedContacts.length>0 || ($any(activityService.selectedActivity)?.contacts?.length>0 && contactService.isConsentInvoked)))"
                        sticky='true' [viewData]='selectedContactsHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

                    <ion-list scrollY="true" *ngIf="!selectedContactsForNonNativeChannelSMS && (!restrictedSelectedContactsViewActive && (contactService.contactPageMode===compViewMode.ADDNEW
                                    || contactService.contactPageMode===compViewMode.READONLY))" class="stakeholder-roles-list">
                        <ion-item-group (click)="highlightContact($event, selContact)" class='selector-item'
                          [ngClass]="{'itemSelected': (selContact?.ID === (contactService?.contactPageMode != compViewMode.PREVIEW ? contactService?.contactInformation?.ID : contactService?.prevSelected?.ID))}"
                          *ngFor="let selContact of selectedContacts; let i = index;">
                            <ion-item>
                                <ion-item>
                                    <ion-row style="display: block;width: 100%;">
                                        <ion-col>
                                            <ion-label class="text-wrapper">
                                                <ion-text class='primary-text'>
                                                    <h3>
                                                      {{contactService.getFullNameForContactDisplaySequence(selContact)}} {{(selContact.isActive) ? '' : ' (Inactive)'}}
                                                          <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && selContact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                                          <img *ngIf="((contactService.accessedContactListFrom === pagename.ActivitiesDetailsPaneComponent ||
                                                                                                                    contactService.accessedContactListFrom === pagename.PresentationMeetingComponent)
                                                                                                                    && getConnectionState(selContact) === contactState.JOINED)"
                                                            src="assets/imgs/contact_conn.png" width="14" class="contact-state-icon" />
                                                          <img *ngIf="((contactService.accessedContactListFrom === pagename.ActivitiesDetailsPaneComponent ||
                                                                                                                    contactService.accessedContactListFrom === pagename.PresentationMeetingComponent)
                                                                                                                    && getConnectionState(selContact) === contactState.LEFT)"
                                                            src="assets/imgs/contact_disc.png" width="14" class="contact-state-icon" />
                                                    </h3>
                                                    <ion-badge *ngIf="contactService.isSchedulerInvoked && selContact.schedulerFrequency">
                                                        {{selContact.schedulerFrequency}}</ion-badge>
                                                    <h3 *ngIf="selContact?.isguest" class="contact-state-guest">{{ 'CONTACT_GUEST' | translate}}</h3>
                                                </ion-text>
                                                <p *ngIf="contactService.accessedContactListFrom != pagename.EmailDetailsPageComponent && contactService.accessedContactListFrom != pagename.OpportunityDetailsComponent" class="overflowHiddenAndEllipsis secondaryTextColorAndfont">
                                                    <ion-text>{{getSecondaryInfo(selContact)}}</ion-text>
                                                </p>
                                            </ion-label>
                                        </ion-col>
                                        <ion-col>
                                            <div class="selection_container" *ngIf="contactService.accessedContactListFrom == pagename.EmailDetailsPageComponent && !contactService.isConsentInvoked && emailService.selectedActivity?.channelActivityType != ChannelActivityType.WECOM">
                                                <ng-container [ngSwitch]="emailService.selectedActivity?.channelActivityType">
                                                    <ng-container *ngSwitchCase="ChannelActivityType.FACEBOOK">
                                                        <div class="round round-disabled">
                                                            <input type="checkbox" disabled [ngModel]="true" />
                                                            <label class="selected"></label>
                                                            <span style="margin-bottom:35px; margin-left: 15px;" size="25">{{ 'FACEBOOK' | translate}}</span>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="ChannelActivityType.SMS">
                                                        <div class="round round-disabled">
                                                            <input type="checkbox" disabled [ngModel]="true" />
                                                            <label class="selected"></label>
                                                            <span style="margin-bottom:35px; margin-left: 15px;" size="25">{{selContact.mobilePhone}}</span>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="ChannelActivityType.WHATSAPP">
                                                        <div class="round round-disabled">
                                                            <input type="checkbox" disabled [ngModel]="true" />
                                                            <label class="selected"></label>
                                                            <span style="margin-bottom:35px; margin-left: 15px;" size="25">{{selContact.mobilePhone}}</span>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        <div *ngFor="let emailAddressDetails of selContact.emailAddressList">
                                                            <div class="channel-item-wrapper" #roundInput [ngClass]="{'round': emailAddressDetails.isSelectable,
                                                                'round-disabled': (!emailAddressDetails.isSelectable) || (emailService.selectedActivity && emailService.selectedActivity.status != 1)}"
                                                                (click)="updateEmailAddressToSelectedContact($event, selContact, emailAddressDetails)">
                                                                <input type="checkbox" disabled [ngModel]="emailAddressDetails.isSelected" />
                                                                <label [for]="emailAddressDetails.emailAddressId" [ngClass]="{'selected':emailAddressDetails.isSelected}"></label>
                                                                <span style="margin-bottom:15px; margin-left: 15px; overflow-wrap: anywhere;" size="25">{{emailAddressDetails.emailAddress}}</span>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                            <div class="selection_container" *ngIf="opportunityStakeholderSelectionEnabled && (contactService.accessedContactListFrom == pagename.OpportunityDetailsComponent || contactService.accessedContactListFrom == pagename.NewOpportunityComponent)">
                                                <div><span *ngIf="selContact.stakeholderValue && contactService.accessedContactListFrom == pagename.OpportunityDetailsComponent">{{transactionCurrencySymbol}}</span><input class="stakeholder-input" *ngIf="isOpportunityStakeholderValueEnabled" type="number" placeholder="{{'ENTER_STAKEHOLDER_VALUE' | translate}}" [(ngModel)]="selContact.stakeholderValue" (input)="handleStakeholderValueInput($event)"></div>
                                                <div *ngFor="let role of opportunityService.stakeholderRoles">
                                                    <div class="channel-item-wrapper" #roundInput class="round" (click)="updateConnectionRoleToSelectedContact($event, selContact, role)">
                                                        <input type="checkbox" [ngModel]="slectedContactConnectionRole(selContact, role)?.isSelected" />
                                                        <label [for]="role.connectionroleid" [ngClass]="{'selected':slectedContactConnectionRole(selContact, role)?.isSelected}"></label>
                                                        <span style="margin-bottom:15px; margin-left: 15px; overflow-wrap: anywhere;" size="25">{{role.name}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ion-col>
                                    </ion-row>
                                </ion-item>
                                <img *ngIf="getVoiceSatus(selContact.ID)=== 'UNMUTE' && getConnectionState(selContact) === contactState.JOINED" [ngClass]="!selContact.isguest && contactService.contactPageMode === compViewMode.ADDNEW && getConnectionState(selContact) !== contactState.JOINED? '': 'mr-40'"
                                    slot="end" tappable="" width="28" src="assets/imgs/action_button_speaker_default_2x.png" class="">
                                <img *ngIf="getVoiceSatus(selContact.ID)=== 'MUTE' && getConnectionState(selContact) === contactState.JOINED" [ngClass]="!selContact.isguest && contactService.contactPageMode === compViewMode.ADDNEW && getConnectionState(selContact) !== contactState.JOINED? '': 'mr-40'"
                                    slot="end" tappable="" width="33" src="assets/imgs/audio_mute1.png" class="">
                                <ion-icon class='remove-icon' name="indegene-selectors-remove-icon" *ngIf="!selContact.isguest && contactService.contactPageMode === compViewMode.ADDNEW && getConnectionState(selContact) !== contactState.JOINED" slot="end" (click)="removeFromSelected($event,selContact,i)"></ion-icon>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>
                    <!-- Non-Native Channel Selected Contacts list view -->
                    <ion-list scrollY="true" *ngIf="selectedContactsForNonNativeChannelSMS && (!restrictedSelectedContactsViewActive && (contactService.contactPageMode===compViewMode.ADDNEW
                                    || contactService.contactPageMode===compViewMode.READONLY))">
                        <ion-item-group (click)="highlightContact($event, selContact)" class='selector-item'
                          [ngClass]="{'itemSelected': (selContact?.ID === (contactService?.contactPageMode != compViewMode.PREVIEW ? contactService?.contactInformation?.ID : contactService?.prevSelected?.ID))}"
                          *ngFor="let selContact of selectedContacts; let i = index;">
                            <ion-item>
                                <ion-label class="text-wrapper">
                                    <ion-text class='primary-text'>
                                        <h3>{{contactService.getFullNameForContactDisplaySequence(selContact)}} {{(selContact.isActive) ? '' : ' (Inactive)'}}
                                          <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && selContact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                        </h3>
                                    </ion-text>
                                </ion-label>
                                <div *ngIf="emailService.selectedActivity?.emailStatus != 548910000">
                                    <ng-container *ngFor="let mobileNumber of mobileNumberListforShareMessage">
                                        <div class="channel-item-wrapper" #roundInput [ngClass]="{'round': mobileNumber.isSelected,
                                            'round-disabled': (!mobileNumber.isSelected)}" *ngIf="mobileNumber.phoneNumber" (click)="nonNativePhoneNumberSelection($event, selContact, mobileNumber)">
                                            <input type="checkbox" value="{{mobileNumber.identity}}" [(ngModel)]="mobileNumber.isSelected" />
                                            <label [for]="mobileNumber.identity" [ngClass]="{'selected':mobileNumber.isSelected}"></label>
                                            <span class="number-font" size="25">{{mobileNumber.phoneNumber}}</span>
                                        </div>
                                    </ng-container>
                                </div>
                                <div *ngIf="(emailService.selectedActivity?.emailStatus == 548910000)">
                                    <ng-container *ngFor="let mobileNumber of selectedMobileNumberListforShareMessage">
                                        <div *ngIf="mobileNumber.isSelected == true" class="round round-disabled channel-item-wrapper">
                                            <input type="checkbox" disabled [ngModel]="true" />
                                            <label class="selected"></label>
                                            <span class="number-font nonNativePhoneNumText" size="25">{{mobileNumber.phoneNumber}}</span>
                                        </div>
                                    </ng-container>
                                </div>
                                <ion-icon class='remove-icon' name="indegene-selectors-remove-icon" *ngIf="!selContact.isguest && contactService.contactPageMode === compViewMode.ADDNEW && getConnectionState(selContact) !== contactState.JOINED" slot="end" (click)="removeFromSelected($event,selContact,i)"></ion-icon>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>

                    <ind-section-header *ngIf="contactService.accessedContactListFrom === pagename.MarketScanDetailsComponent && segmentedContacts.length && contactService.contactPageMode === compViewMode.ADDNEW" sticky='true'
                        [viewData]='segmentedContactsHeader' (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>

                    <ion-list scrollY="true" *ngIf="contactService.accessedContactListFrom === pagename.MarketScanDetailsComponent && segmentedContacts.length && contactService.contactPageMode === compViewMode.ADDNEW">
                        <ion-item-group (click)="highlightContact($event, segContact)" class='selector-item'
                          [ngClass]="{'itemSelected': (segContact?.ID === (contactService?.contactPageMode != compViewMode.PREVIEW ? contactService?.contactInformation?.ID : contactService?.prevSelected?.ID))}"
                          *ngFor="let segContact of segmentedContacts; let i = index;">
                            <ion-item>
                                <ion-label class="text-wrapper">
                                    <ion-text class='primary-text'>
                                        <h3 class="contact-name">{{contactService.getFullNameForContactDisplaySequence(segContact)}}
                                          <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && segContact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                        </h3>
                                    </ion-text>
                                    <p class="overflowHiddenAndEllipsis secondaryTextColorAndfont">
                                        <ion-text>{{getSecondaryInfo(segContact)}}</ion-text>
                                    </p>
                                </ion-label>
                                <ion-icon class='add-icon' name="indegene-selectors-add-icon" *ngIf="!isMarketScanContactAndAccount || segContact.accountRelationships?.length != 0" slot="end" (click)="addToSelected($event,segContact)" [ngStyle]="{'display': (segContact.isSelected ? 'none' : 'block')}"></ion-icon>
                                <ion-icon class='checkmark-icon' [ngStyle]="{'display': (segContact.isSelected ? 'block' : 'none')}" name="indegene-selectors-checkmark-icon" color="primary" slot="end"></ion-icon>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>

                    <ind-section-header *ngIf="contactService.accessedContactListFrom === pagename.EventDetailsPageComponent && newContacts.length" [viewData]='newParticipantsHeader'></ind-section-header>

                    <ion-list *ngIf="contactService.accessedContactListFrom === pagename.EventDetailsPageComponent && newContacts.length">
                        <ion-item-group *ngFor="let contact of newContacts"
                          [ngClass]="{'itemSelected': (contact?.ID === (contactService?.contactPageMode != compViewMode.PREVIEW ? contactService?.contactInformation?.ID : contactService?.prevSelected?.ID))}"
                          (click)="highlightContact($event, contact)">
                            <ion-item>
                                <ion-label class="text-wrapper">
                                    <ion-text class='primary-text'>
                                        <h3>{{contactService.getFullNameForContactDisplaySequence(contact)}}
                                          <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && contact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                        </h3>
                                    </ion-text>
                                    <p class="overflowHiddenAndEllipsis secondary-text">
                                        <ion-text>{{getSecondaryInfo(contact)}}</ion-text>
                                    </p>
                                </ion-label>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>

                    <!-- Section for selected customers for restricted selection operations -->
                    <ind-section-header *ngIf="restrictedSelectedContactsViewActive && restrictedViewSelectedContacts.length" sticky='true' [viewData]='restrictedSelectedContactsHeader'></ind-section-header>

                    <ion-list scrollY="true" *ngIf="restrictedSelectedContactsViewActive && restrictedViewSelectedContacts.length">
                        <ion-item-group (click)="openRestricedSelectionContactDetail($event,selContact)" class='selector-item'
                          [ngClass]="{'itemSelected': (selContact?.ID === (contactService?.contactPageMode != compViewMode.PREVIEW ? contactService?.contactInformation?.ID : contactService?.prevSelected?.ID))}"
                          *ngFor="let selContact of restrictedViewSelectedContacts;">
                            <ion-item>
                                <ion-label class="text-wrapper">
                                    <ion-text class='primary-text'>
                                        <h3>{{contactService.getFullNameForContactDisplaySequence(selContact)}} {{(selContact.isActive) ? '' : ' (Inactive)'}}
                                          <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && selContact.tagLabels && selContact.tagLabels.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                        </h3>
                                    </ion-text>
                                    <p class="overflowHiddenAndEllipsis secondary-text">
                                        <ion-text>{{getSecondaryInfo(selContact)}}</ion-text>
                                    </p>
                                </ion-label>
                                <ion-icon class='remove-icon disabledIcon' name="indegene-selectors-remove-icon" *ngIf="!selContact.isSelectable && contactService.contactPageMode === compViewMode.ADDNEW" slot="end"></ion-icon>
                                <ion-icon class='remove-icon' name="indegene-selectors-remove-icon" *ngIf="selContact.isSelectable" item-right (click)="restrictedViewRemoveFromSelected($event,selContact)" slot="end"></ion-icon>
                              </ion-item>
                              <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>
                    <!-- selected contact section for allocations-->

                    <ind-section-header *ngIf="contactService.contactPageMode===compViewMode.SELECTION && radioSelectedContact && selectionModeForComponent !== 'Sample-Activity-Selection'"
                        [viewData]='{id:"contact_selectedlist_header",title: translate.instant("SELECTED_CAP") + " (1)"}'></ind-section-header>
                    <ind-section-header *ngIf="contactService.contactPageMode===compViewMode.SELECTION && radioSelectedContact && selectionModeForComponent === 'Sample-Activity-Selection'"
                        [viewData]='{id:"contact_sample_selectedlist_header",title: translate.instant("SELECTED_CAP") + " (1)"}'></ind-section-header>

                    <ion-list *ngIf="contactService.contactPageMode===compViewMode.SELECTION && radioSelectedContact && contactService.accessedContactListFrom != pagename.PhoneCallDetailsComponent"  class="stakeholder-roles-list">
                        <ion-item-group (click)="highlightContact($event, radioSelectedContact)" class='selector-item'>
                            <ion-item>
                              <ion-row style="display: block;width: 100%;">
                                <ion-col>
                                  <ion-label class="text-wrapper">
                                    <ion-text class='primary-text'>
                                        <h3>{{contactService.getFullNameForContactDisplaySequence(radioSelectedContact)}}
                                          <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && radioSelectedContact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                        </h3>
                                    </ion-text>
                                    <p class="overflowHiddenAndEllipsis secondary-text">
                                        <ion-text>{{getSecondaryInfo(radioSelectedContact)}}</ion-text>
                                    </p>
                                </ion-label>
                                </ion-col>
                                <ion-col>
                                  <div class="selection_container" *ngIf="opportunityStakeholderSelectionEnabled && (contactService.accessedContactListFrom == pagename.OpportunityDetailsComponent || contactService.accessedContactListFrom == pagename.NewOpportunityComponent)">
                                      <input class="stakeholder-input" *ngIf="isOpportunityStakeholderValueEnabled" type="number" placeholder="{{'ENTER_STAKEHOLDER_VALUE' | translate}}" [(ngModel)]="radioSelectedContact.stakeholderValue" (input)="handleStakeholderValueInput($event)" >
                                      <div *ngFor="let role of opportunityService.stakeholderRoles">
                                          <div class="channel-item-wrapper" #roundInput class="round" (click)="updateConnectionRoleToSelectedContact($event, radioSelectedContact, role)">
                                              <input type="checkbox" [ngModel]="slectedContactConnectionRole(radioSelectedContact, role)?.isSelected" />
                                              <label [for]="role.connectionroleid" [ngClass]="{'selected':slectedContactConnectionRole(radioSelectedContact, role)?.isSelected}"></label>
                                              <span style="margin-bottom:15px; margin-left: 15px; overflow-wrap: anywhere;" size="25">{{role.name}}</span>
                                          </div>
                                      </div>
                                  </div>
                                </ion-col>
                              </ion-row>
                                
                                
                                <ion-icon class='remove-icon' name="indegene-selectors-remove-icon" slot="end" (click)="removeSelection($event,radioSelectedContact)"></ion-icon>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>

                    <ion-list *ngIf="contactService.contactPageMode===compViewMode.SELECTION && radioSelectedContact && contactService.accessedContactListFrom == pagename.PhoneCallDetailsComponent">
                        <ion-item-group class='selector-item' (click)="highlightContact($event, radioSelectedContact)" [ngClass]="[radioSelectedContact?.ID === contactService.contactInformation?.contactid ? 'itemSelected' : '']">
                            <ion-item>
                                <ion-label class="text-wrapper">
                                    <ion-text class='primary-text'>
                                        <h3>{{contactService.getFullNameForContactDisplaySequence(radioSelectedContact)}}
                                          <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && radioSelectedContact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                        </h3>
                                    </ion-text>
                                </ion-label>
                                <div *ngIf="contactService.accessedContactListFrom == pagename.PhoneCallDetailsComponent && contactService.contactPageMode===compViewMode.SELECTION && radioSelectedContact">
                                    <ng-container *ngFor="let mobileNumber of mobileNumberList; let i=index;">
                                        <div class="channel-item-wrapper" #roundInput [ngClass]="{'round': mobileNumber.isChecked,
                                            'round-disabled': (!mobileNumber.isChecked)}" *ngIf="mobileNumber.phoneNumber" (click)="phoneNumberSelection(i)">
                                            <input type="checkbox" value="{{mobileNumber.identity}}" [(ngModel)]="mobileNumber.isChecked" />
                                            <label [for]="mobileNumber.identity" [ngClass]="{'selected':mobileNumber.isChecked}"></label>
                                            <span class="number-font" size="25">{{mobileNumber.phoneNumber}}</span>
                                        </div>
                                    </ng-container>
                                </div>
                                <ion-icon class='remove-icon' name="indegene-selectors-remove-icon" slot="end" (click)="removeSelection($event,radioSelectedContact)"></ion-icon>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>

                    <!-- contacts section for new contacts received in delta-->
                    <ind-section-header *ngIf="contactService.contactPageMode != compViewMode.READONLY && contactService.contactPageMode != compViewMode.SELECTION
                        && contactService.contactPageMode !==compViewMode.ADDNEW && !searching && !filtering && newContacts.length" [viewData]='newContactsHeader'></ind-section-header>

                    <ion-list *ngIf="contactService.contactPageMode != compViewMode.READONLY && contactService.contactPageMode != compViewMode.SELECTION &&
                      contactService.contactPageMode !==compViewMode.ADDNEW
                      && !searching && !filtering && newContacts.length">
                        <ion-item-group *ngFor="let contact of newContacts"
                          [ngClass]="{'itemSelected': (contact?.ID === (contactService?.contactPageMode != compViewMode.PREVIEW ? contactService?.contactInformation?.ID : contactService?.prevSelected?.ID))}"
                          (click)="highlightContact($event, contact)">
                            <ion-item>
                                <ion-label class="text-wrapper">
                                    <ion-text class='primary-text'>
                                        <h3>{{contactService.getFullNameForContactDisplaySequence(contact)}}
                                          <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && contact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                        </h3>
                                    </ion-text>
                                    <p class="overflowHiddenAndEllipsis secondary-text">
                                        <ion-text>{{getSecondaryInfo(contact)}}</ion-text>
                                    </p>
                                </ion-label>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>

                    <!-- contacts section for all contacts-->
                    <div [ngStyle]="{'display': (searching || filtering ? 'none' : 'block')}">
                        <ind-section-header sticky='true' id="allContacts" *ngIf="contactService.contactPageMode != compViewMode.READONLY
                            && contactService.contactPageMode != compViewMode.SELECTION && (contactSearchText || contactSearchText.length <= 2)"
                            [viewData]='allContactsHeader' (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>

                        <ind-section-header *ngIf="contactService.contactPageMode === compViewMode.SELECTION && contactService.accessedContactListFrom !== pagename.PhoneCallDetailsComponent"
                            [viewData]="resultsAllocationHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

                        <ind-section-header *ngIf="contactService.contactPageMode === compViewMode.SELECTION && contactService.accessedContactListFrom === pagename.PhoneCallDetailsComponent"
                            [viewData]="phoneResultsAllocationHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

                        <ion-list *ngIf="contactService.contactPageMode != compViewMode.READONLY && contactService.accessedContactListFrom == pagename.PhoneCallDetailsComponent">
                            <ion-item-group id="{{contact.ID}}" class='selector-item' *ngFor="let contact of contactsToDisplay"
                              [ngClass]="{'itemSelected': (contact?.ID === (contactService?.contactPageMode != compViewMode.PREVIEW ? contactService?.contactInformation?.ID : contactService?.prevSelected?.ID))}">
                                <ion-item>
                                    <ion-label class="text-wrapper">
                                        <ion-text class='primary-text' (click)="highlightContact($event, contact)">
                                            <h3 class="contact-name">{{contactService.getFullNameForContactDisplaySequence(contact)}}
                                              <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && contact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                            </h3>
                                            <ion-badge *ngIf="contactService.isSchedulerInvoked && contact.schedulerFrequency">
                                                {{contact.schedulerFrequency}}</ion-badge>
                                        </ion-text>
                                        <p class="overflowHiddenAndEllipsis secondary-text" (click)="highlightContact($event, contact)">
                                            <ion-text>{{getSecondaryInfo(contact)}}</ion-text>
                                        </p>
                                        <p *ngIf="showTertiary" class="overflowHiddenAndEllipsis tertiary-text" (click)="highlightContact($event, contact)">
                                            <ion-text>{{getTertiaryInfo(contact)}}</ion-text>
                                        </p>
                                    </ion-label>
                                    <ion-icon class='add-icon' *ngIf="isSelectable(contact)" name="indegene-selectors-add-icon" slot="end" (click)="addToSelected($event,contact)" [ngStyle]="{'display': (contact.isSelected ? 'none' : 'block')}">
                                    </ion-icon>
                                    <ion-icon class='checkmark-icon' *ngIf="contactService.contactPageMode===compViewMode.ADDNEW" name="indegene-selectors-checkmark-icon" [ngStyle]="{'display': (!contact.isSelected ? 'none' : 'block')}" slot="end"></ion-icon>
                                    <ion-icon class='add-icon' *ngIf="contactService.contactPageMode===compViewMode.SELECTION && !contact.isRadioSelected && contactService.accessedContactListFrom != pagename.PhoneCallDetailsComponent" name="indegene-selectors-add-icon" slot="end" (click)="makeSelection($event,contact)"></ion-icon>
                                    <ion-icon class='add-icon' *ngIf="contactService.contactPageMode===compViewMode.SELECTION && !contact.isRadioSelected && contactService.accessedContactListFrom == pagename.PhoneCallDetailsComponent" [ngStyle]="{'display': (!contact.mobilePhone && !contact.telephone && !contact.indskr_alternatephone1 && !contact.indskr_alternatephone2 && !contact.indskr_alternatephone3 ? 'none' : 'block')}"
                                        name="indegene-selectors-add-icon" slot="end" (click)="makeSelection($event,contact)"></ion-icon>
                                    <ion-icon class='checkmark-icon' *ngIf="contactService.contactPageMode===compViewMode.SELECTION && contact.isRadioSelected" name="indegene-selectors-checkmark-icon" color="primary" slot="end"></ion-icon>
                                    <ion-checkbox class="tag-check-box" slot="start" *ngIf="showUserContactTag" [(ngModel)]='contact.isSelectedForTag' (ionChange)="checkContactForTag($event, contact)"></ion-checkbox>
                                </ion-item>
                                <div class="separationLine"></div>
                            </ion-item-group>
                        </ion-list>

                        <ion-list *ngIf="contactService.contactPageMode != compViewMode.READONLY && contactService.accessedContactListFrom != pagename.PhoneCallDetailsComponent">
                            <ion-item-group id="{{contact.ID}}" class='selector-item' *ngFor="let contact of contactsToDisplay"
                              [ngClass]="{'itemSelected': (contact?.ID === (contactService?.contactPageMode != compViewMode.PREVIEW ? contactService?.contactInformation?.ID : contactService?.prevSelected?.ID)) && (!showUserContactTag)}">
                                <ion-item>
                                    <ion-label class="text-wrapper">
                                        <ion-text class='primary-text' (click)="highlightContact($event, contact)">
                                            <h3>{{contactService.getFullNameForContactDisplaySequence(contact)}}
                                              <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && contact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                            </h3>
                                            <ion-badge *ngIf="contactService.isSchedulerInvoked && contact.schedulerFrequency">
                                                {{contact.schedulerFrequency}}</ion-badge>
                                        </ion-text>
                                        <p class="overflowHiddenAndEllipsis secondary-text" (click)="highlightContact($event, contact)">
                                            <ion-text>{{getSecondaryInfo(contact)}}</ion-text>
                                        </p>
                                        <p *ngIf="showTertiary" class="overflowHiddenAndEllipsis tertiary-text" (click)="highlightContact($event, contact)">
                                            <ion-text>{{getTertiaryInfo(contact)}}</ion-text>
                                        </p>
                                    </ion-label>
                                    <!-- add to selected -->
                                    <ion-icon class='add-icon' *ngIf="isSelectable(contact) && !showUserContactTag && (!isMarketScanContactAndAccount || contact.accountRelationships?.length != 0)" name="indegene-selectors-add-icon" slot="end" (click)="addToSelected($event,contact)" [ngStyle]="{'display': (contact.isSelected ? 'none' : 'block')}">
                                    </ion-icon>
                                    <ion-icon class='checkmark-icon' *ngIf="contactService.contactPageMode===compViewMode.ADDNEW && (!showUserContactTag)" name="indegene-selectors-checkmark-icon" [ngStyle]="{'display': (!contact.isSelected ? 'none' : 'block')}" slot="end"></ion-icon>
                                    <ion-icon class='add-icon' *ngIf="(contactService.contactPageMode===compViewMode.SELECTION && !contact.isRadioSelected) && (!showUserContactTag) " name="indegene-selectors-add-icon" slot="end" (click)="makeSelection($event,contact)" [ngStyle]="{'display': (contactService.accessedContactListFrom == pagename.SurveyDetailsComponent && contact.isForcedHideAddIcon ? 'none' : 'block')}"></ion-icon>
                                    <ion-icon class='checkmark-icon' *ngIf="contactService.contactPageMode===compViewMode.SELECTION && contact.isRadioSelected" name="indegene-selectors-checkmark-icon" color="primary" slot="end"></ion-icon>
                                    <ion-checkbox class="tag-check-box" slot="start" *ngIf="showUserContactTag" [(ngModel)]='contact.isSelectedForTag' (ionChange)="checkContactForTag($event, contact)"></ion-checkbox>
                                </ion-item>
                                <div class="separationLine"></div>
                            </ion-item-group>
                        </ion-list>

                        <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
                            <ion-infinite-scroll-content></ion-infinite-scroll-content>
                        </ion-infinite-scroll>
                    </div>

                    <!-- contacts section for local searched contacts-->
                    <div *ngIf="(searching || filtering) && !contactService.isInGlobalSearch && !contactService.isOneKeySearching">
                        <!-- Search results -->
                        <ind-section-header *ngIf="contactService.contactPageMode != compViewMode.READONLY && contactService.contactPageMode != compViewMode.SELECTION"
                            [viewData]='localResultsContactsHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

                        <ind-section-header *ngIf="contactService.contactPageMode === compViewMode.SELECTION"
                            [viewData]="localResultsAllocationHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>

                        <ng-container *ngIf="searchedContactsToDisplay.length > 0">
                            <ion-list>
                                <ion-item-group id="{{contact.ID}}" class='selector-item' *ngFor="let contact of slicedSearchedContactsToDisplay"
                                    [ngClass]="{'itemSelected': (contact?.ID === (contactService?.contactPageMode != compViewMode.PREVIEW ? contactService?.contactInformation?.ID : contactService?.prevSelected?.ID))}">
                                    <ion-item>
                                        <ion-label class="text-wrapper" (click)="highlightContact($event, contact)">
                                            <ion-text class='primary-text' (click)="highlightContact($event, contact)">
                                                <h3 class="contact-name">{{contactService.getFullNameForContactDisplaySequence(contact)}}
                                                  <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && contact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                                </h3>
                                                <ion-badge *ngIf="contactService.isSchedulerInvoked && contact.schedulerFrequency">
                                                    {{contact.schedulerFrequency}}</ion-badge>
                                            </ion-text>
                                            <p class="overflowHiddenAndEllipsis secondary-text" (click)="highlightContact($event, contact)">
                                                <ion-text>{{getSecondaryInfo(contact)}}</ion-text>
                                            </p>
                                            <p *ngIf="showTertiary" class="overflowHiddenAndEllipsis tertiary-text" (click)="highlightContact($event, contact)">
                                                <ion-text>{{getTertiaryInfo(contact)}}</ion-text>
                                            </p>
                                        </ion-label>
                                        <ion-icon class='add-icon' *ngIf="isSelectable(contact)" name="indegene-selectors-add-icon" 
                                          slot="end" (click)="addToSelected($event,contact)" 
                                          [ngStyle]="{'display': (contact.isSelected || (contactService.accessedContactListFrom == pagename.EventDetailsPageComponent && contact.isForcedHideAddIconEvent) ? 'none' : 'block')}">
                                        </ion-icon>
                                        <ion-icon class='checkmark-icon' *ngIf="contactService.contactPageMode===compViewMode.ADDNEW" name="indegene-selectors-checkmark-icon" color="primary" slot="end" [ngStyle]="{'display': (!contact.isSelected ? 'none' : 'block')}"></ion-icon>
                                        <ion-icon class='add-icon' *ngIf="contactService.contactPageMode===compViewMode.SELECTION && !contact.isRadioSelected && contactService.accessedContactListFrom != pagename.PhoneCallDetailsComponent" name="indegene-selectors-add-icon" slot="end" (click)="makeSelection($event,contact)"></ion-icon>
                                        <ion-icon class='add-icon' *ngIf="contactService.contactPageMode===compViewMode.SELECTION && !contact.isRadioSelected && contactService.accessedContactListFrom == pagename.PhoneCallDetailsComponent" [ngStyle]="{'display': (!contact.mobilePhone && !contact.telephone && !contact.indskr_alternatephone1 && !contact.indskr_alternatephone2 && !contact.indskr_alternatephone3 ? 'none' : 'block')}"
                                            name="indegene-selectors-add-icon" slot="end" (click)="makeSelection($event,contact)"></ion-icon>
                                        <ion-icon class='checkmark-icon' *ngIf="contactService.contactPageMode===compViewMode.SELECTION && contact.isRadioSelected" name="indegene-selectors-checkmark-icon" color="primary" slot="end"></ion-icon>
                                        <ion-checkbox class="tag-check-box" slot="start" *ngIf="showUserContactTag" [(ngModel)]='contact.isSelectedForTag' (ionChange)="checkContactForTag($event, contact)"></ion-checkbox>
                                    </ion-item>
                                    <div class="separationLine"></div>
                                </ion-item-group>
                            </ion-list>
                            <ion-infinite-scroll (ionInfinite)="doSearchedContactsInfinite($event.detail,$event)" threshold="30%">
                                <ion-infinite-scroll-content></ion-infinite-scroll-content>
                            </ion-infinite-scroll>
                        </ng-container>
                        <ng-container *ngIf="searchedContactsToDisplay.length <= 0">
                            <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                        </ng-container>
                    </div>

                    <!-- contacts section for global searched contacts-->
                    <div *ngIf="contactService.isInGlobalSearch && !contactService.isOneKeySearching">
                        <ng-container *ngIf="selectedGlobalSearchContact">
                            <ind-section-header sticky='true' [viewData]='selectedGlobalSearchContactHeader'></ind-section-header>

                            <ion-list>
                                <ion-item-group class='selector-item'>
                                    <ion-item>
                                      <ion-item>
                                        <ion-row style="display: block;width: 100%;">
                                          <ion-col>
                                            <ion-label (click)="highlightContact($event, selectedGlobalSearchContact)">
                                              <ion-text class='primary-text'>
                                                <h3>
                                                  {{contactService.getFullNameForContactDisplaySequence(selectedGlobalSearchContact)}}
                                                  <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && selectedGlobalSearchContact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                                </h3>
                                              </ion-text>
                                              <p class="overflowHiddenAndEllipsis secondary-text"><ion-text>{{getSecondaryInfo(selectedGlobalSearchContact)}}</ion-text></p>
                                            </ion-label>
                                          </ion-col>
                                          <ion-col>
                                            <div class="selection-container-global-search" *ngIf="selectablLinkedEntityData && selectablLinkedEntityData.length>0">
                                              <div *ngFor="let selLinkedEntity of selectablLinkedEntityData">
                                                <div class="channel-item-wrapper-global-search" #roundInput [ngClass]="{'round': !selLinkedEntity.isDisableChkBox,'round-disabled': selLinkedEntity.isDisableChkBox || device.isOffline}" (click)="selectLinkedEntity(selLinkedEntity)">
                                                  <input type="checkbox" disabled [ngModel]="selLinkedEntity.isChecked" />
                                                  <label [for]="selLinkedEntity.selId" [ngClass]="{'selected':selLinkedEntity.isChecked, 'display-none':selLinkedEntity.isDisableChkBox}"></label>
                                                  <span style="margin-left: 15px; overflow-wrap: anywhere;" size="25">{{selLinkedEntity.selName}}</span>
                                                </div>
                                                <div *ngIf="selLinkedEntity?.approvalStatus" class="approvalStatusInfo">
                                                  <span>{{selLinkedEntity?.approvalStatus}}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </ion-col>
                                        </ion-row>
                                      </ion-item>
                                      <ion-icon class='remove-icon' name="indegene-selectors-remove-icon" slot="end" [ngClass]="hasSecondaryInfo?'remove-icon-gsc-with-secInfo':'remove-icon-gsc'" (click)="removeSelectedGSC()"></ion-icon>
                                    </ion-item>
                                    <div class="separationLine"></div>
                                </ion-item-group>
                            </ion-list>
                        </ng-container>
                        <!-- Global Search results -->
                        <ind-section-header sticky='true' [viewData]='globalContactsHeader' (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>

                        <ng-container *ngIf="contactService.globalSearchResults.length >= 0">
                            <ion-list>
                                <!-- <ion-item-group id="{{contact.ID}}" class='selector-item' *ngFor="let contact of contactService.globalSearchResults | orderBy: sortBy?.value:sortBy?.asc" (click)="highlightContact($event, contact)" [ngClass]="{'itemSelected':(contact?.ID == contactService.contactInformation?.ID)}"> -->
                                <ion-item-group id="{{contact.ID}}" class='selector-item' *ngFor="let contact of slicedGlobalSearchResults" (click)="highlightContact($event, contact)"
                                    [ngClass]="{'itemSelected': (contact?.ID === (contactService?.contactPageMode != compViewMode.PREVIEW ? contactService?.contactInformation?.ID : contactService?.prevSelected?.ID))}">
                                    <ion-item>
                                        <ion-label class="text-wrapper">
                                            <ion-text class='primary-text'>
                                                <h3 class="contact-name">
                                                  {{contactService.getFullNameForContactDisplaySequence(contact)}}
                                                  <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && contact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                                </h3>
                                            </ion-text>
                                            <p class="overflowHiddenAndEllipsis secondary-text">
                                                <ion-text>{{getSecondaryInfo(contact)}}</ion-text>
                                            </p>
                                        </ion-label>
                                        <ion-icon class='checkmark-icon' *ngIf="contact?.ID == selectedGlobalSearchContact?.ID" name="indegene-selectors-checkmark-icon" slot="end">
                                        </ion-icon>
                                        <ion-icon class='add-icon' *ngIf="contact?.ID !== selectedGlobalSearchContact?.ID" name="indegene-selectors-add-icon" slot="end" (click)="selectGSC($event,contact)">
                                        </ion-icon>
                                    </ion-item>
                                    <div class="separationLine"></div>
                                </ion-item-group>
                            </ion-list>
                            <ion-infinite-scroll (ionInfinite)="doGlobalSearchedContactsInfinite($event.detail,$event)" threshold="30%">
                                <ion-infinite-scroll-content></ion-infinite-scroll-content>
                            </ion-infinite-scroll>
                        </ng-container>
                        <ng-container *ngIf="contactService.globalSearchResults.length <= 0">
                            <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                        </ng-container>
                    </div>

                    <!-- contacts section for onekey searched contacts-->
                    <div *ngIf="contactService.isOneKeySearching">
                        <ng-container *ngIf="contactService.selectedOKContacts?.length > 0">
                            <ind-section-header sticky='true' [viewData]='selectedOKSearchContactHeader'></ind-section-header>
                            <ion-list>
                                <ion-item-group class='selector-item' *ngFor="let contact of contactService.selectedOKContacts; let i = index;" (click)="displayOKDetails(contact)" [ngClass]="{'itemSelected':(contact['individualEid']===contactService?.selectedOKContact?.value['individualEid'])}">
                                    <ion-item>
                                        <ion-label>
                                            <ion-text class='primary-text'>
                                                <h3>{{contactService.getFullName(contact)}}</h3>
                                            </ion-text>
                                        </ion-label>
                                        <ion-icon class='remove-icon' name="indegene-selectors-remove-icon" slot="end" (click)="onOKContactSelected(contact, false)"></ion-icon>
                                    </ion-item>
                                    <div class="separationLine"></div>
                                </ion-item-group>
                            </ion-list>
                        </ng-container>
                        <!-- Global Search results -->
                        <ind-section-header sticky='true' [viewData]='oneKeyContactsHeader' (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>

                        <ng-container *ngIf="onekeyApiService.oneKeyContacts.length >= 0">
                            <ion-list>
                                <ion-item-group class='selector-item' *ngFor="let contact of onekeyApiService.oneKeyContacts" [ngClass]="{'itemSelected':(contactService?.selectedOKContact?.value ? contact['individualEid']===contactService?.selectedOKContact?.value['individualEid'] : false)}">
                                    <ion-item>
                                        <ion-label class="text-wrapper" (click)="displayOKDetails(contact)">
                                            <ion-text class='primary-text'>
                                                <h3 class="contact-name">{{contactService.getFullName(contact)}}</h3>
                                            </ion-text>
                                        </ion-label>
                                        <ion-icon *ngIf="enableOneKeyDownload && !contact['isInContract'] && !contact['isSelected']" class='add-icon' name="indegene-selectors-add-icon" slot="end" (click)="addToOKSelected(contact)">
                                        </ion-icon>
                                        <ion-icon class='checkmark-icon' *ngIf="contact['isSelected']" name="indegene-selectors-checkmark-icon" slot="end">
                                        </ion-icon>
                                    </ion-item>
                                    <div class="separationLine"></div>
                                </ion-item-group>
                            </ion-list>
                        </ng-container>
                        <ng-container *ngIf="onekeyApiService.oneKeyContacts.length <= 0">
                            <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                        </ng-container>
                    </div>
                </ion-item-group>
            </div>
            <div *ngSwitchCase="'changeRequests'">
                <ind-section-header [viewData]="myContactChangeRequestsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
                <div *ngIf="!contactSearchText && !filtering && filteredContactCRs.length == 0" class="no-data-message">{{'NO_CHANGE_REQUESTS' | translate}}</div>
                <ind-no-search-result *ngIf="(contactSearchText || filtering) && filteredContactCRs.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
                <contact-cr [source]="filteredContactCRs"></contact-cr>
            </div>
            <div *ngSwitchCase="'approvalRequests'">
              <ind-section-header [viewData]="myDCRApprovableRequestsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
              <div *ngIf="!contactSearchText && !filtering && filteredDCRApprovables.length == 0" class="no-data-message">{{'NO_APPROVALS' | translate}}</div>
              <ind-no-search-result *ngIf="(contactSearchText || filtering) && filteredDCRApprovables.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
              <div *ngFor="let item of filteredDCRApprovables">
                <main-card [viewData]='item'></main-card>
            </div>
          </div>
        </div>
    </ng-container>

    <ng-template #showRemoveTagContactsList>
        <div>
            <ion-item-group>
                <ng-container *ngIf="!isAccessedFromContactToolsDrawer">
                    <ind-section-header *ngIf="(!restrictedSelectedContactsViewActive && (contactService.contactPageMode===compViewMode.ADDNEW || contactService.contactPageMode===compViewMode.READONLY) && (selectedContacts.length>0 || ($any(activityService.selectedActivity)?.contacts?.length>0 && contactService.isConsentInvoked)))"
                        sticky='true' [viewData]='selectedContactsHeader' (onControlClick)='onSectionHeaderControlClick($event)'>
                    </ind-section-header>
                    <ion-list scrollY="true" *ngIf="!selectedContactsForNonNativeChannelSMS && (!restrictedSelectedContactsViewActive && (contactService.contactPageMode===compViewMode.ADDNEW
                                          || contactService.contactPageMode===compViewMode.READONLY))">
                        <ion-item-group (click)="highlightContact($event, selContact)" class='selector-item'
                            [ngClass]="{'itemSelected': (selContact?.ID === (contactService?.contactPageMode != compViewMode.PREVIEW ? contactService?.contactInformation?.ID : contactService?.prevSelected?.ID))}"
                            *ngFor="let selContact of selectedContacts; let i = index;">
                            <ion-item>
                                <ion-item>
                                    <ion-row style="display: block;width: 100%;">
                                        <ion-col>
                                            <ion-label class="text-wrapper">
                                                <ion-text class='primary-text'>
                                                    <span>{{contactService.getFullNameForContactDisplaySequence(selContact)}}
                                                          {{(selContact.isActive) ? '' : ' (Inactive)'}}</span>
                                                  
                                                    <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && selContact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                                    <ion-badge *ngIf="contactService.isSchedulerInvoked && selContact.schedulerFrequency">
                                                        {{selContact.schedulerFrequency}}</ion-badge>
                                                    <img *ngIf="((contactService.accessedContactListFrom === pagename.ActivitiesDetailsPaneComponent ||
                                                        contactService.accessedContactListFrom === pagename.PresentationMeetingComponent)
                                                        && getConnectionState(selContact) === contactState.JOINED)" src="assets/imgs/contact_conn.png" width="14" class="contact-state-icon" />
                                                    <img *ngIf="((contactService.accessedContactListFrom === pagename.ActivitiesDetailsPaneComponent ||
                                                        contactService.accessedContactListFrom === pagename.PresentationMeetingComponent)
                                                        && getConnectionState(selContact) === contactState.LEFT)" src="assets/imgs/contact_disc.png" width="14" class="contact-state-icon" />
                                                    <h3 *ngIf="selContact?.isguest" class="contact-state-guest">{{ 'CONTACT_GUEST' | translate}}</h3>
                                                </ion-text>
                                                <p *ngIf="contactService.accessedContactListFrom != pagename.EmailDetailsPageComponent && contactService.accessedContactListFrom != pagename.OpportunityDetailsComponent" class="overflowHiddenAndEllipsis secondaryTextColorAndfont">
                                                    <ion-text>{{getSecondaryInfo(selContact)}}</ion-text>
                                                </p>
                                            </ion-label>
                                        </ion-col>
                                        <ion-col>
                                            <div class="selection_container" *ngIf="contactService.accessedContactListFrom == pagename.EmailDetailsPageComponent && !contactService.isConsentInvoked && emailService.selectedActivity?.channelActivityType != ChannelActivityType.WECOM">
                                                <ng-container [ngSwitch]="emailService.selectedActivity?.channelActivityType">
                                                    <ng-container *ngSwitchCase="ChannelActivityType.FACEBOOK">
                                                        <div class="round round-disabled">
                                                            <input type="checkbox" disabled [ngModel]="true" />
                                                            <label class="selected"></label>
                                                            <span style="margin-bottom:35px; margin-left: 15px;" size="25">{{ 'FACEBOOK' | translate}}</span>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="ChannelActivityType.SMS">
                                                        <div class="round round-disabled">
                                                            <input type="checkbox" disabled [ngModel]="true" />
                                                            <label class="selected"></label>
                                                            <span style="margin-bottom:35px; margin-left: 15px;" size="25">{{selContact.mobilePhone}}</span>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="ChannelActivityType.WHATSAPP">
                                                        <div class="round round-disabled">
                                                            <input type="checkbox" disabled [ngModel]="true" />
                                                            <label class="selected"></label>
                                                            <span style="margin-bottom:35px; margin-left: 15px;" size="25">{{selContact.mobilePhone}}</span>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        <div *ngFor="let emailAddressDetails of selContact.emailAddressList">
                                                            <div class="channel-item-wrapper" #roundInput [ngClass]="{'round': emailAddressDetails.isSelectable,
                                                                'round-disabled': (!emailAddressDetails.isSelectable) || (emailService.selectedActivity && emailService.selectedActivity.status != 1)}" (click)="updateEmailAddressToSelectedContact($event, selContact, emailAddressDetails)">
                                                                <input type="checkbox" disabled [ngModel]="emailAddressDetails.isSelected" />
                                                                <label [for]="emailAddressDetails.emailAddressId" [ngClass]="{'selected':emailAddressDetails.isSelected}"></label>
                                                                <span style="margin-bottom:15px; margin-left: 15px; overflow-wrap: anywhere;" size="25">{{emailAddressDetails.emailAddress}}</span>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                            <div class="selection_container" *ngIf="contactService.accessedContactListFrom == pagename.OpportunityDetailsComponent">
                                                <div *ngFor="let role of opportunityService.stakeholderRoles">
                                                    <input class="stakeholder-input" *ngIf="isOpportunityStakeholderValueEnabled" type="number" placeholder="{{'ENTER_STAKEHOLDER_VALUE' | translate}}" [(ngModel)]="selContact.stakeholderValue" (input)="handleStakeholderValueInput($event)" >
                                                    <div class="channel-item-wrapper" #roundInput class="round" (click)="updateConnectionRoleToSelectedContact($event, selContact, role)">
                                                        <input type="checkbox" [ngModel]="slectedContactConnectionRole(selContact, role)?.isSelected" />
                                                        <label [for]="role.connectionroleid" [ngClass]="{'selected':slectedContactConnectionRole(selContact, role)?.isSelected}"></label>
                                                        <span style="margin-bottom:15px; margin-left: 15px; overflow-wrap: anywhere;" size="25">{{role.name}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ion-col>
                                    </ion-row>
                                </ion-item>
                                <img *ngIf="getVoiceSatus(selContact.ID)=== 'UNMUTE' && getConnectionState(selContact) === contactState.JOINED" [ngClass]="!selContact.isguest && contactService.contactPageMode === compViewMode.ADDNEW && getConnectionState(selContact) !== contactState.JOINED? '': 'mr-40'"
                                    slot="end" tappable="" width="28" src="assets/imgs/action_button_speaker_default_2x.png" class="">
                                <img *ngIf="getVoiceSatus(selContact.ID)=== 'MUTE' && getConnectionState(selContact) === contactState.JOINED" [ngClass]="!selContact.isguest && contactService.contactPageMode === compViewMode.ADDNEW && getConnectionState(selContact) !== contactState.JOINED? '': 'mr-40'"
                                    slot="end" tappable="" width="33" src="assets/imgs/audio_mute1.png" class="">
                                <ion-icon class='remove-icon' name="indegene-selectors-remove-icon" *ngIf="!selContact.isguest && contactService.contactPageMode === compViewMode.ADDNEW && getConnectionState(selContact) !== contactState.JOINED" slot="end" (click)="removeFromSelected($event,selContact,i)"></ion-icon>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>

                    <ind-section-header *ngIf="contactService.contactPageMode===compViewMode.SELECTION && radioSelectedContact && selectionModeForComponent === 'Sample-Activity-Selection'"
                        [viewData]='{id:"contact_sample_selectedlist_header",title: translate.instant("SELECTED_CAP") + " (1)"}'></ind-section-header>

                    <ion-list *ngIf="contactService.contactPageMode===compViewMode.SELECTION && radioSelectedContact && contactService.accessedContactListFrom != pagename.PhoneCallDetailsComponent">
                        <ion-item-group (click)="highlightContact($event, radioSelectedContact)" class='selector-item'>
                            <ion-item>
                                <ion-label class="text-wrapper">
                                    <ion-text class='primary-text'>
                                        <h3>{{contactService.getFullNameForContactDisplaySequence(radioSelectedContact)}}
                                          <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && radioSelectedContact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                        </h3>
                                    </ion-text>
                                    <p class="overflowHiddenAndEllipsis secondary-text">
                                        <ion-text>{{getSecondaryInfo(radioSelectedContact)}}</ion-text>
                                    </p>
                                </ion-label>
                                <ion-icon class='remove-icon' name="indegene-selectors-remove-icon" slot="end" (click)="removeSelection($event,radioSelectedContact)"></ion-icon>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>
                    <ion-list *ngIf="contactService.contactPageMode===compViewMode.SELECTION && radioSelectedContact && contactService.accessedContactListFrom == pagename.PhoneCallDetailsComponent">
                        <ion-item-group class='selector-item'>
                            <ion-item>
                                <ion-label class="text-wrapper">
                                    <ion-text class='primary-text'>
                                        <h3>
                                          {{contactService.getFullNameForContactDisplaySequence(radioSelectedContact)}}
                                          <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && radioSelectedContact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                        </h3>
                                      </ion-text>
                                    <ng-container *ngFor="let mobileNumber of mobileNumberList; let i=index;">
                                        <div class="channel-item-wrapper" #roundInput [ngClass]="{'round': mobileNumber.isChecked,
                                            'round-disabled': (!mobileNumber.isChecked)}" *ngIf="mobileNumber.phoneNumber" (click)="phoneNumberSelection(i)">
                                            <input type="checkbox" value="{{mobileNumber.identity}}" [(ngModel)]="mobileNumber.isChecked" />
                                            <label [for]="mobileNumber.identity" [ngClass]="{'selected':mobileNumber.isChecked}"></label>
                                            <span class="number-font" size="25">{{mobileNumber.phoneNumber}}</span>
                                        </div>
                                    </ng-container>
                                  </ion-label>
                                <ion-icon class='remove-icon' name="indegene-selectors-remove-icon" slot="end" (click)="removeSelection($event,radioSelectedContact)"></ion-icon>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>
                </ng-container>

                <ind-section-header sticky='true' [viewData]=' showRemoveFromTag ? selectedTagDataHeaderModel : tagMyContactsHeaderModel '> </ind-section-header>
                <ion-list scrollY="true">
                    <ng-container *ngIf="selectedContactsFromTag.length ; else showEmptyContactsMessage">
                        <ion-item-group class='selector-item' *ngFor="let contact of selectedContactsFromTag"
                          [ngClass]="{'itemSelected': (contact?.ID === (contactService?.contactPageMode != compViewMode.PREVIEW ? contactService?.contactInformation?.ID : contactService?.prevSelected?.ID))}">
                            <ion-item>
                                <ion-label class="text-wrapper" (click)="highlightContact($event, contact)">
                                    <ion-text class='primary-text'>
                                        <h3>
                                          {{contactService.getFullNameForContactDisplaySequence(contact)}}
                                          <img class="targetIcon" *ngIf="hasTerritoryFAEnabled && contact.tagLabels?.includes(territoryService.currentListPeriod.tagLabel)" src="assets/imgs/target_indicator.svg">
                                        </h3>
                                        <ion-badge *ngIf="contactService.isSchedulerInvoked && contact.schedulerFrequency">
                                            {{contact.schedulerFrequency}}</ion-badge>
                                        <p class="overflowHiddenAndEllipsis secondary-text">
                                            <ion-text>{{getSecondaryInfo(contact)}}</ion-text>
                                        </p>
                                        <p *ngIf="showTertiary" class="overflowHiddenAndEllipsis tertiary-text">
                                            <ion-text>{{getTertiaryInfo(contact)}}</ion-text>
                                        </p>
                                    </ion-text>
                                </ion-label>
                                <ng-container *ngIf="!isAccessedFromContactToolsDrawer">
                                    <ion-icon class='add-icon' *ngIf="isSelectable(contact)" name="indegene-selectors-add-icon" slot="end" (click)="addToSelected($event,contact)" [ngStyle]="{'display': (contact.isSelected ? 'none' : 'block')}"></ion-icon>
                                    <ion-icon class='checkmark-icon' *ngIf="contactService.contactPageMode===compViewMode.ADDNEW" name="indegene-selectors-checkmark-icon" [ngStyle]="{'display': (!contact.isSelected ? 'none' : 'block')}" slot="end"></ion-icon>
                                    <ion-icon class='add-icon' *ngIf="contactService.contactPageMode===compViewMode.SELECTION && !contact.isRadioSelected && contactService.accessedContactListFrom != pagename.PhoneCallDetailsComponent" name="indegene-selectors-add-icon" slot="end" (click)="makeSelection($event,contact)"></ion-icon>
                                    <ion-icon class='add-icon' *ngIf="contactService.contactPageMode===compViewMode.SELECTION && !contact.isRadioSelected && contactService.accessedContactListFrom == pagename.PhoneCallDetailsComponent" [ngStyle]="{'display': (!contact.mobilePhone && !contact.telephone && !contact.indskr_alternatephone1 && !contact.indskr_alternatephone2 && !contact.indskr_alternatephone3 ? 'none' : 'block')}"
                                        name="indegene-selectors-add-icon" slot="end" (click)="makeSelection($event,contact)"></ion-icon>
                                    <ion-icon class='checkmark-icon' *ngIf="contactService.contactPageMode===compViewMode.SELECTION && contact.isRadioSelected" name="indegene-selectors-checkmark-icon" color="primary" slot="end"></ion-icon>
                                </ng-container>
                                <ion-checkbox class="tag-check-box" slot="start" *ngIf="isClickedTagSelectBtn" (ionChange)="checkContactForTag($event, contact)" [(ngModel)]='contact.isSelectedForTag'> </ion-checkbox>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ng-container>

                    <ng-template #showEmptyContactsMessage>
                        <div class="no-data-message">{{noContactForSelectedTag}}</div>
                    </ng-template>
                </ion-list>
            </ion-item-group>
        </div>
    </ng-template>
</ion-content>

<footer-toolbar [footerView]="'masterView'" [selectedView]="'contacts'"></footer-toolbar>

<search-suggestion-popover (click)="clickedInSuggestionsArea($event)" 
  (selectionMade)="handleFacetSelection($event)" (asessmentSelectionMade)="handleAssessmentSearchClick($event)" 
  [suggestionsData]="suggestionsData" [searchKey]="searchKey" 
  [listPeriodTags]="listPeriodTags" (selectListPeriodTag)="handleListPeriodTagSelection($event)"
  *ngIf='suggestionsActive && !this.hidesearchSuggestionsDropDown' (selectSavedSearch)="handleSavedSearchSelection($event)" [from]="uiService.page.ContactPageComponent" 
  (selectTag)="handleTagSelection($event)" [isFromConsentTool]="isFromConsentTool">
</search-suggestion-popover>
