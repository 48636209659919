
import { takeUntil } from 'rxjs/operators';
import { EventName, EventsService } from './../../../services/events/events.service';
import { TrackingEventNames } from './../../../services/logging/tracking.service';
import { UIService } from './../../../services/ui/ui.service';
import { MultiSelectPopover } from './../../multi-select-popover/multi-select-popover';
import { MultiSelectFilterPopoverComponent } from './../../multi-select-filter-popover/multi-select-filter-popover';
import { Component, Input } from "@angular/core";
import { RepServices } from "../../../data-services/rep/rep.services";
import { DeviceService } from "../../../services/device/device.service";
import { NavigationService, PageName } from "../../../services/navigation/navigation.service";
import { AuthenticationService } from "../../../services/authentication.service";
import { TrackService } from "../../../services/logging/tracking.service";
import { NothingSelectedView } from "../../shared/nothing-selected-view/nothing-selected-view";
import { PopoverController } from "@ionic/angular";
import { Account } from "../../../classes/account/account.class";
import { MainCardViewDataModel } from "../../../models/MainCardViewDataModel";
import { OpportunityDetailsComponent } from "../opportunity-details/opportunity-details";
import { IndSectionHeaderViewDataModel } from "../../../models/indSectionHeaderDataModel";
import { OpportunityManagementService } from "../../../services/opportunity-management/opportunity-management.service";
import { format } from 'date-fns';
import { Opportunity, ProgressStatus } from "../../../classes/opportunity-management/opportunity.class";
import { Subject } from "rxjs";
import { AccountOfflineService } from '../../../services/account/account.offline.service';
import { DateTimeFormatsService } from "../../../services/date-time-formats/date-time-formats.service";
import { TranslateService } from '@ngx-translate/core';
import { FooterService } from '@omni/services/footer/footer.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { DatePipe } from '@angular/common';
import { OpportunityManagementDataService } from '@omni/data-services/opportunity-management/opportunity-management.data.service';
import { SurveyService } from '@omni/services/survey/survey.service';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';

@Component({
  selector: 'opportunities-list',
  templateUrl: 'opportunities-list.html',
  styleUrls: ['opportunities-list.scss']
})
export class OpportunitiesListComponent {
  @Input() from: any
  @Input() account: any

  private allOpportunities: Array<Opportunity> = [];

  public isLayoverPushView: boolean = false;
  public layoverViewPushedFrom: string = '';
  public selectedAccount: Account;
  public allModelOpportunities: Array<MainCardViewDataModel> = [];
  public allOpportunitesHeader: IndSectionHeaderViewDataModel;
  ngUnSubscribe$ = new Subject<boolean>();
  public filterPopoverData;
  opportunitiesFilter: { status: string, category: string } = { status: '', category: '' };

  constructor(
    public device: DeviceService,
    public repService: RepServices,
    public authService: AuthenticationService,
    private navService: NavigationService,
    public trackingService: TrackService,
    private opportunityMgmtService: OpportunityManagementService,
    private opportunityMgmtDataService: OpportunityManagementDataService,
    private popoverCtrl: PopoverController,
    private accountService: AccountOfflineService,
    private uiService: UIService,
    private dateTimeFormatsService: DateTimeFormatsService,
    public translate: TranslateService,
    public events: EventsService,
    public footerService: FooterService,
    private datePipe: DatePipe,
    private readonly surveyService: SurveyService,
  ) {
    // if(this.opportunityMgmtService.preSelectedopportunity) {
    //   this.openOpportunityDetail(this.opportunityMgmtService.preSelectedopportunity.ID);
    //   this.opportunityMgmtService.preSelectedAccount = undefined;
    //   this.opportunityMgmtService.preSelectedopportunity = undefined;
    // }
  }

  ngOnInit() {
    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
    }

    if (this.account) {
      this.selectedAccount = this.account;
      this.initViewData();
    }

    this.opportunityMgmtService.opportunities$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((data) => {
      if (data && data.length) {
        this.initViewData();
      }
    })

    this.filterPopoverData = [
      {
        text: "",
        value: 'All',
        items: [{ text: this.translate.instant('ALL_OPPORTUNITIES'), value: 'All' }],
        handler: (selectedItem, item, itemRef) => {
          this.trackingService.tracking('SelectOpportunityFilter', TrackingEventNames.OPPORTUNITYMANAGEMENT)
          itemRef.parent.items.map((o) => {
            o.value = '';
          })
          item.value = selectedItem.value;
          this.opportunitiesFilter = Object.assign({}, this.opportunitiesFilter, { status: '', category: '' });
          this.initViewData();
        }
      },
      {
        text: this.translate.instant('TYPE'),
        value: "",
        items: [
          { text: this.translate.instant('OPEN'), value: "Open" },
          { text: this.translate.instant('CLOSED_WON'), value: "Won" },
          { text: this.translate.instant('CLOSED_LOST'), value: "Lost" }
        ],
        handler: (selectedItem, item, itemRef) => {
          this.trackingService.tracking('SelectOpportunityFilter', TrackingEventNames.OPPORTUNITYMANAGEMENT)
          itemRef.parent.items[0].value = ''
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          this.opportunitiesFilter.status = item.value;
          if (this.opportunitiesFilter.category == '' && this.opportunitiesFilter.status == '') {
            itemRef.parent.items[0].value = 'All'
          }
          this.initViewData();
        }
      },
      {
        text: this.translate.instant('CATEGORY'),
        value: "",
        items: [
          { text: this.translate.instant('MY_OPPORTUNITIES'), value: "My" },
          { text: this.translate.instant('TEAM_OPPORTUNITIES'), value: "Team" }
        ],
        handler: (selectedItem, item, itemRef) => {
          this.trackingService.tracking('SelectOpportunityFilter', TrackingEventNames.OPPORTUNITYMANAGEMENT)
          itemRef.parent.items[0].value = ''
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          this.opportunitiesFilter.category = item.value;
          if (this.opportunitiesFilter.category == '' && this.opportunitiesFilter.status == '') {
            itemRef.parent.items[0].value = 'All'
          }
          this.initViewData();
        }
      }
    ];

    if (this.authService.hasFeatureAction(FeatureActionsMap.DISCONTINUE_OPPORTUNTIY)) {
      this.filterPopoverData[1].items.push({ text: this.translate.instant('OPPORTUNITY_STATE_DISCONTINUED'), value: "Discontinued" })
    }
  }

  ngOnDestroy() {
    this.ngUnSubscribe$.next(true);
    this.ngUnSubscribe$.complete();
  }

  private initViewData() {
    this.allOpportunities = this.opportunityMgmtService.getOpportunitiesByAccountId(this.selectedAccount.id);
    if (this.opportunitiesFilter.status && this.opportunitiesFilter.category) {
      this.allOpportunities = this.allOpportunities.filter((oppo) => {
        if (((this.opportunitiesFilter.status == "Lost" && oppo.stateCode == 2 && oppo.statusCode != 548910002)
          || (this.opportunitiesFilter.status == "Discontinued" && oppo.statusCode == 548910002)
          || (oppo.stateCode != 2 && oppo.status == this.opportunitiesFilter.status))
          && ((this.opportunitiesFilter.category == 'My' && !oppo.isTeamOpportunity)
            || (this.opportunitiesFilter.category == 'Team' && oppo.isTeamOpportunity))) {
          return true
        }
      })
    }
    else if (this.opportunitiesFilter.status || this.opportunitiesFilter.category) {
      this.allOpportunities = this.allOpportunities.filter((oppo) => {
        if ((this.opportunitiesFilter.status
          && ((this.opportunitiesFilter.status == "Lost" && oppo.stateCode == 2 && oppo.statusCode != 548910002)
            || (this.opportunitiesFilter.status == "Discontinued" && oppo.statusCode == 548910002)
            || (oppo.stateCode != 2 && oppo.status == this.opportunitiesFilter.status)))
          || this.opportunitiesFilter.category && ((this.opportunitiesFilter.category == 'My' && !oppo.isTeamOpportunity)
            || (this.opportunitiesFilter.category == 'Team' && oppo.isTeamOpportunity))) {
          return true
        }
        else return false;
      })
    }
    this.allOpportunities = this.allOpportunities.sort((a, b) => {
      if (a.createdOn && b.createdOn) {
        return parseInt(b.createdOn) - parseInt(a.createdOn)
      } else {
        return 0;
      }
    });
    this.allModelOpportunities = [];
    if (this.allOpportunities && this.allOpportunities.length > 0) {

      this.allOpportunities.forEach(opportunity => {
        let viewData: MainCardViewDataModel = this.getOpportunityMainCard(opportunity);
        viewData.secondaryTextRight = this.dateTimeFormatsService.formattedCurrency(opportunity.closedValueFormatted)
        viewData.secondaryTextRightTwo = (opportunity.estimatedValue) ? this.dateTimeFormatsService.formattedCurrency(opportunity.estimatedValueFormatted) : ''
        this.allModelOpportunities.push(viewData);
      })
    }

    // if(this.allModelOpportunities.length === 0){
    //   this.events.publish('no-opportunity-for-account');
    //   this.onClosePage();
    //   return;
    // }

    this.allOpportunitesHeader = {
      id: 'open-opportunity-header',
      title: this.headerString + ' (' + this.allModelOpportunities.length + ')',
      isFilter: true,
      showArrow: true,
      arrowType: 'caret-down-sharp',
      controls: [],
    };
  }

  private getOpportunityMainCard(opportunity: Opportunity): MainCardViewDataModel {
    return {
      id: opportunity.ID,
      fixedHeight: true,
      primaryTextLeft: (opportunity.createdOn) ? this.datePipe.transform(opportunity.createdOn, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : '',
      secondaryTextLeft: '',
      showIcon: false,
      iconName: '',
      primaryTextRight: opportunity.opportunityName,
      secondaryTextRight: '',
      secondaryTextRightTwo: '',
      isSecondaryTextRightTwoPillType: true,
      showArrow: true,
      arrowType: 'chevron-forward-outline',
      mainItemCssClass: 'opprtunities-card',
      showEndIcon: !this.device._isOffline && !this.device.isAndroid() && !this.device.isIOS(),
      isSrcAttribute: true,
      endIconType: 'assets/imgs/horizontal-menu-filled.svg',
      endIconCssClass: 'showOnHover',
      clickHandler: (id: string, event, target) => {
        if (target === 'endArrow' || target === 'mainItem') {
          this.openOpportunityDetail(id);
        } else if (target === 'endIcon') {
          this.showCloneDropDown(id, event);
        }
      }
    };
  }

  public handlePlusButton(): void {
    console.log('Handle Plus Button');
  }

  public onClosePage(): void {
    this.accountService.selected = undefined;
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.OpportunityManagementPage);
    this.navService.setChildNavRightPaneView(false);
    setTimeout(() => {
      this.events.publish('accountListContentResize');
    }, 0)
  }

  public isSplitView() {
    return (window.innerWidth > 620);
  }

  public async openOpportunityDetail(id: string) {
    this.trackingService.tracking('SelectOpportunity', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    let foundOpportunity = this.opportunityMgmtService.getOpportunityDetailsbyId(id);
    if (foundOpportunity.opportunityTypeId && !this.device.isOffline) {
      // const selectedOpportunityType = this.opportunityMgmtService.opportunityTypes.find(a => a.indskr_opportunitytypeid == foundOpportunity.opportunityTypeId);
      // if (selectedOpportunityType && selectedOpportunityType.surveyTemplateId) {
      //   const foundTemplate = this.surveyService.surveyForms.find(form => form.surveyFormId == selectedOpportunityType.surveyTemplateId);
      //   if (foundTemplate) {
          this.uiService.displayLoader();
          // await this.opportunityMgmtDataService.fetchSurveyJSAtrributesForOpportunity(foundOpportunity, foundTemplate);
          await this.getSurvetJsFormValues(foundOpportunity);
          this.uiService.dismissLoader();
      //   }
      // }
    }
    if (foundOpportunity) {
      this.navService.pushChildNavPageWithPageTracking(OpportunityDetailsComponent, PageName.OpportunityDetailsComponent, PageName.OpportunityManagementPage,
        {
          from: 'OpportunityTool', opportunity: foundOpportunity,
          refreshOpportunityEvent: (data: any) => this.refreshOpportunity(data)
        });
    }
    this.uiService.dismissLoader();
  }

  private refreshOpportunity(opportunity: Opportunity) {
    const index = this.allModelOpportunities.findIndex(model => model.id == opportunity.ID);
    if (index >= 0) {
      this.allModelOpportunities[index] = this.getOpportunityMainCard(opportunity);
      this.allModelOpportunities[index].secondaryTextRight = this.dateTimeFormatsService.formattedCurrency(opportunity.closedValueFormatted)
      this.allModelOpportunities[index].secondaryTextRightTwo = (opportunity.estimatedValue) ? this.dateTimeFormatsService.formattedCurrency(opportunity.estimatedValueFormatted) : ''
    }
  }

  public async filterOpportunities(data) {
    let popover = await this.popoverCtrl
      .create({ component: MultiSelectPopover, componentProps: { root: this.filterPopoverData }, event: event })
    popover.present()
  }

  public get headerString() {
    let str: string;
    if (this.opportunitiesFilter.status) {
      str = this.opportunitiesFilter.status == "Open" ? this.translate.instant('OPEN')
        : this.opportunitiesFilter.status == "Won" ? this.translate.instant('CLOSED_WON')
          : this.opportunitiesFilter.status == "Lost" ? this.translate.instant('CLOSED_LOST')
            : this.translate.instant('OPPORTUNITY_STATE_DISCONTINUED');
      if (this.opportunitiesFilter.category) {
        if (this.opportunitiesFilter.category == 'My') {
          str += ', ' + this.translate.instant('MY_OPPORTUNITIES');
        }
        if (this.opportunitiesFilter.category == 'Team') {
          str += ', ' + this.translate.instant('TEAM_OPPORTUNITIES');
        }
      }
    }
    else if (this.opportunitiesFilter.category) {
      if (this.opportunitiesFilter.category == 'My') {
        str = this.translate.instant('MY_OPPORTUNITIES');
      }
      if (this.opportunitiesFilter.category == 'Team') {
        str = this.translate.instant('TEAM_OPPORTUNITIES');
      }
    }
    else {
      str = this.translate.instant('ALL_OPPORTUNITIES');
    }
    return str.toUpperCase();
  }

  // clone opportunities
  private async showCloneDropDown(id, event) {
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'activities-pane-more-options-select',
      showClearAllBtn: false,
      data: [{
        title: this.translate.instant('CLONE'),
        id: 'Clone',
        isSelected: false,
      }],
    };

    let target = {
      top: event.target.getBoundingClientRect().top,
      bottom: event.target.getBoundingClientRect().bottom,
      height: event.target.getBoundingClientRect().height,
      left: event.target.getBoundingClientRect().left,
      right: event.target.getBoundingClientRect().right,
      width: event.target.getBoundingClientRect().width,
      x: event.target.getBoundingClientRect().x,
      y: event.target.getBoundingClientRect().y,
    };

    let ev: any = {
      target: {
        getBoundingClientRect: () => {
          return target;
        }
      }
    };

    let moreOptionsPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: ev,
    });

    moreOptionsPopover.onDidDismiss().then(async ({ data }: any) => {
      if (data && data.isDone) {
        this.cloneOpprtunity(id);
      }
    });

    moreOptionsPopover.present();
  }

  async cloneOpprtunity(id: string) {
    const foundOpportunity: Opportunity = this.allOpportunities.find(opportunity => opportunity.ID === id);
    if (!foundOpportunity) return;
    const isOpportunityByProductsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_BY_PRODUCTS);
    const isrevenuesystemcalculated = !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_REVENUE_USER_PROVIDED);
    const isAccountPlanEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT);
    // const isCapitalSalesEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_OPTIONSET_FIELDS);
    const isCompetitiorEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_COMPETITOR_DATA);
    const isPrimaryTargetEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_PRIMARY_TARGET);

    let newOpportunity: any = this.setupClone(foundOpportunity);
    newOpportunity.isrevenuesystemcalculated = isrevenuesystemcalculated;

    // fA based filter //
    if (!isrevenuesystemcalculated && !isOpportunityByProductsEnabled) newOpportunity.productCategory = foundOpportunity.productCategory;
    if (!isrevenuesystemcalculated && !newOpportunity.isestimatedrevenuebasedonprocedureandproducts) newOpportunity.products = foundOpportunity.products;
    if (isrevenuesystemcalculated) newOpportunity.countryId = foundOpportunity.countryId;
    if (isAccountPlanEnabled) {
      newOpportunity.accountPlanID = foundOpportunity.accountPlanID;
      newOpportunity.opportunityObjectiveID = foundOpportunity.opportunityObjectiveID;
    }

    if (foundOpportunity.opportunityTypeId) await this.getSurvetJsFormValues(foundOpportunity);
    if(isPrimaryTargetEnabled){
      if(foundOpportunity.primaryTargetValue){
        newOpportunity.primaryTargetValue = foundOpportunity.primaryTargetValue;
        newOpportunity.primaryTargetName = foundOpportunity.primaryTargetName;  
      }
      if(foundOpportunity.contactID){
        newOpportunity.contactID = foundOpportunity.contactID;
        newOpportunity.contactName = foundOpportunity.contactName;  
      }
    }
    if(foundOpportunity.accountID){
      newOpportunity.accountID = foundOpportunity.accountID;
      newOpportunity.accountName = foundOpportunity.accountName;  
    }
    if(foundOpportunity.opportunityTypeId){
      newOpportunity.opportunityTypeId = foundOpportunity.opportunityTypeId;
      newOpportunity.opportunityTypename = foundOpportunity.opportunityTypename;  
    }
    if(foundOpportunity.opportunitySubTypeId){
      newOpportunity.opportunitySubTypeId = foundOpportunity.opportunitySubTypeId;
      newOpportunity.opportunitySubTypename = foundOpportunity.opportunitySubTypename;  
    }
    if(foundOpportunity.primarySpecialtyId){
      newOpportunity.primarySpecialtyId = foundOpportunity.primarySpecialtyId;
      newOpportunity.primarySpecialtyName = foundOpportunity.primarySpecialtyName;  
    }
    if(foundOpportunity.subSpecialtyId){
      newOpportunity.subSpecialtyId = foundOpportunity.subSpecialtyId;
      newOpportunity.subSpecialtyName = foundOpportunity.subSpecialtyName;  
    }
    if(foundOpportunity.surgeryId){
      newOpportunity.surgeryId = foundOpportunity.surgeryId;
      newOpportunity.surgeryNameString = foundOpportunity.surgeryNameString;  
    }

    this.uiService.displayLoader();
    this.opportunityMgmtDataService.createOpportunity({
      onDynamics: !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true
    }, [newOpportunity]).then(async () => {
      this.events.publish(EventName.NEWOPPORTUNITYCONFIRMED, newOpportunity);
      this.trackingService.tracking('OpportunityCreate',TrackingEventNames.OPPORTUNITY);
      let selectedType = this.opportunityMgmtService.opportunityTypes.find(a => a.indskr_opportunitytypeid == foundOpportunity.opportunityTypeId);
      let form = this.surveyService.surveyForms.find(form => form.surveyFormId == selectedType.surveyTemplateId);
      if (form) newOpportunity.surveyResponseDTO = await this.setSurveyJsPayload(form, foundOpportunity)

      // if (isCapitalSalesEnabled) {
      //   newOpportunity.surveyResponseDTO['indskr_source'] = foundOpportunity.indskr_source;
      //   newOpportunity.surveyResponseDTO['indskr_sourceothers'] = foundOpportunity.indskr_sourceothers;
      //   newOpportunity.surveyResponseDTO['opportunityratingcode'] = foundOpportunity.opportunityratingcode;
      //   newOpportunity.surveyResponseDTO['indskr_affordability'] = foundOpportunity.indskr_affordability;
      // }

      if(isCompetitiorEnabled){
        newOpportunity.competitors = foundOpportunity.competitors;
        newOpportunity.competitorProducts = foundOpportunity.competitorProducts;
        newOpportunity.competitorBusinessValue = foundOpportunity.competitorBusinessValue;
      }

      this.updateOpportunity(newOpportunity);
    }).catch(err => {
      console.log(err);
      this.uiService.dismissLoader();
    })
  }

  private setupClone(foundOpportunity: Opportunity) {
    let newOpprtunity = this.opportunityMgmtService.getNewOpportunityObject();
    newOpprtunity = {
      ...newOpprtunity,
      pendingPushToDynamics: true,
      accountID: foundOpportunity.accountID,
      surgeryId: foundOpportunity.surgeryId,
      stakeholders: foundOpportunity.stakeholders,
      collaborators: foundOpportunity.collaborators,
      opportunityName: foundOpportunity.opportunityName,
      purchaseProcess: foundOpportunity.purchaseProcess,
      collaboratorType: foundOpportunity.collaboratorType,
      opportunityStage: foundOpportunity.opportunityStage,
      businessUnitId: this.authService.user.xBusinessUnitId,
      opportunityTypeId: foundOpportunity.opportunityTypeId,
      opportunitySubTypeId: foundOpportunity.opportunitySubTypeId,
      businessUnitFormatted: this.authService.user.businessUnitName,
      purchaseProcessFormatted: foundOpportunity.purchaseProcessFormatted,
      opportunityBusinessProcessInstanceId: foundOpportunity.opportunityBusinessProcessInstanceId,
      isestimatedrevenuebasedonprocedureandproducts: foundOpportunity.isestimatedrevenuebasedonprocedureandproducts,
    }
    newOpprtunity.transactionCurrencyID = foundOpportunity.transactionCurrencyID;
    newOpprtunity.transactionCurrencySymbol = foundOpportunity.transactionCurrencySymbol;
    if(newOpprtunity.stakeholders){
      newOpprtunity.stakeholders.forEach(stakeholder=> {
        stakeholder.stakeholderValue = null;
      })
      newOpprtunity.stakeholderValue = 0;
      newOpprtunity.stakeholderValueFormatted = (newOpprtunity.transactionCurrencySymbol ? newOpprtunity.transactionCurrencySymbol : '')+'0.00';
    }
    const opportunityType = this.opportunityMgmtService.opportunityTypes ? this.opportunityMgmtService.opportunityTypes.find(a => a.indskr_opportunitytypeid == foundOpportunity.opportunityTypeId) : null;
    if (opportunityType && opportunityType['indskr_nostageprogressafterdays'] != null) {
      newOpprtunity.indskr_progressstatus = ProgressStatus.IN_PROGRESS;
    }
    return newOpprtunity;
  }

  private async updateOpportunity(newOpprtunity) {
    const account = this.accountService.getAccountById(newOpprtunity.accountID);
    await this.opportunityMgmtDataService.updateOpportunity({
      onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true
    }, [newOpprtunity]).then(async () => {
      if (!this.device.isOffline) await this.opportunityMgmtDataService.getOpportunitiesData(false, account.id, false, false, newOpprtunity.ID);
      this.openOpportunityDetail(newOpprtunity.ID);
    });
  }

  private async getSurvetJsFormValues(foundOpportunity: Opportunity) {
    const selectedOpportunityType = this.opportunityMgmtService.opportunityTypes.find(({ indskr_opportunitytypeid }) => indskr_opportunitytypeid == foundOpportunity.opportunityTypeId);
    if (selectedOpportunityType && selectedOpportunityType.surveyTemplateId) {
      const foundTemplate = this.surveyService.surveyForms.find(({ surveyFormId }) => surveyFormId == selectedOpportunityType.surveyTemplateId);
      if (foundTemplate) {
        await this.opportunityMgmtDataService.fetchSurveyJSAtrributesForOpportunity(foundOpportunity, foundTemplate);
      }
    }
  }

  private async setSurveyJsPayload(form, foundOpportunity) {
    let surveyTemplate = form.surveyConfiguration;
    let temp: any = {};
    for (let key in foundOpportunity.surveyResponseData) {
      const element = this.surveyService.getElementFromSurveyTemplateElements(surveyTemplate.pages[0].elements,{name:key});// surveyTemplate.pages[0].elements.find(ele => ele.name == key);
      if (!element.readOnly) {
        temp[element.metadata.logicalName] = foundOpportunity.surveyResponseData[key];
      }
    }
    return temp;
  }

  // clone opportunities
}
