import { DistanceUnits } from './../my-assistant/my-assistant.class';
import { ConfiguredFields } from './configured.field.class';
import {
  DefaultCoachingFieldNamesThatCannotBeConfigured,
  DefaultContractFieldNamesThatCannotBeConfigured,
  DefaultEventFieldNamesThatCannotBeConfigured, SupportedCoachingConfiguredFieldTypes,
  SupportedEventConfiguredFieldTypes,
  SupportedProcedureContractConfiguredFieldTypes
} from "../../config/configuredField.config";
import { configFieldSortFn, sortConfigFieldsByOrder } from '../../utility/common.utility';
/**
 * Represents our user info, handles user related tasks
 *
 * @export
 * @class User
 */
export class User {
  private ID: string;
  public isProductConsent: boolean;
  public businessPhones: Array<string>;
  public displayName: string;
  public givenName: string;
  public lastName: string;
  public jobTitle: string;
  public title: string;
  public academicTitle: string;
  public mail: string;
  public mobilePhone: string;
  public officeLocation: string;
  public localeId: string;
  public countryId: string;
  public surname: string;
  public userPrincipalName: string;
  public positionID: string;
  private businessUnitId: string;
  public businessUnitName: string;
  public positionName: string;
  public systemUserID: string;
  // public readonly codBaseList: Array<string> = ['WDE', 'WNL', 'WDK'];
  public featureActions: Array<string> = [];
  public buSettings: { [x: string]: any };
  public buConfigs: { [x: string]: any };
  public configuredFields: Array<ConfiguredFields> = [];
  public appointmentConfiguredFields: Array<ConfiguredFields> = [];
  public phonecallConfiguredFields: Array<ConfiguredFields> = [];
  public readonly eventConfiguredFields: ConfiguredFields[] = [];
  public readonly coachingConfiguredFields: ConfiguredFields[] = [];
  public readonly procedureContractConfiguredFields: ConfiguredFields[] = [];
  private notSupportedPhoneCallConfiguredFields: Array<string> = ['PartyList', 'Currency', 'File', 'Image', 'Customer'];
  private notSupportedAppointmentConfiguredFields: Array<string> = ['PartyList', 'Currency', 'File', 'Image', 'Customer'];
  private notSupportedEventConfiguredFieldTypes: Array<string> = ['PartyList', 'Currency', 'File', 'Image', 'Customer', 'Owner', 'Lookup', 'Virtual', 'Uniqueidentifier',];
  private notSupportedEventConfiguredFieldNames: Array<string> = ['indskr_eventid', 'versionnumber',];
  public positions: Array<UserPosition>;
  public childPositions: Array<childPosition> = [];
  public siblingPositions: Array<SiblingPosition> = [];
  public siblingUsersCommonParent: Array<childUser> = [];
  public siblingUsersCommonPositions: Array<childUser> = [];
  public childUsers: Array<childUser> = [];
  public timezonecode: string
  public maxMeetingStartDays: number;
  public offlineDataDuration: number;
  public distanceConfigurationForGenee: number;
  public distanceUnit: DistanceUnits
  public meeting_duration: number;
  public thresholdOfflineTime: number;
  public thresholdOffsetOfflineTime: number;
  public marketScanUpdateDuration: number;
  public pastTimeOffGracePeriod: number;
  public pastMeetingReopenPeriod: number;
  public pastPhoneCallReopenPeriod: number;
  public pastProcedureLogReopenPeriod: number;
  public marketScanCreateFrequency: number;
  public unsolicitedMeetingLimitPeriod: number;
  public unsolicitedMeetingLimitTotal: number;
  public allocationAdjustmentDuration: number = 0;
  public securityRoles: Array<any> = [];
  public iqviaCodBase: string;
  public coachingQualifyingScore: string;
  public isCustomerProfileLaunchSyncEnabled: boolean;
  public globalCategorySearchLimit: any;
  public ioConfigurations: Array<ioConfiguration> = [];
  public acceptCoaching: number;
  public acknowledgeCoaching: number;
  public customerAvailability: boolean;
  public accountsCriteriaFetchxml: string;
  public contactsCriteriaFetchxml: string;
  public timelineActivites: number;
  public timelineActivitesValue: string;
  public covisitorComplianceCheck: boolean;
  public isCovisitorComplianceMandatory: boolean;
  public covisitorComplianceDescription: string;
  public bestTimeRecommendationConfig: number;
  public showBestTimeRecommendation: boolean;
  public hideBestTimeRecommendationCount: number;
  public procedureLogDuration: string;
  public currencysymbol: string;
  public defaultCurrencyID: string;
  public enddateautocalculation:string[];
  public isConsentAddNewEmailDisabled: boolean;
  public isConsentAddNewPhoneNumberDisabled: boolean;
  public isConsentAddNewAddressDisabled: boolean;
  public isConsentAddNewFaxDisabled: boolean;
  public positionGroups: Array<any>;
  public hasBulkProcedureLog: boolean;
  public hasProcedureLog: boolean;
  public hasTagAssetsToProcedureLog: boolean;
  // public covisitorEditMeeting: boolean;
  public approvalForAllocationTransfer: boolean;
  public defaultMeasureTab:string;
  public teams:any[];
  public editTheTargetValueFieldInObjectives:boolean;
  public objectivesBasedOnOwnership:boolean;
  public customNotifications: boolean;
  public meetingLimit: number;
  public meetingProximityWarningPeriod: number;
  public gpsFunctionality:boolean;
  public validCallDistance : number;
  public indskr_mapkeymessagestopositiongroup: boolean;
  public employeeId: string = null;
  public eventTimeLine = false;
  public orgsettings;
  public photoUrl;
  public isInvitationLimitAlertMessageNotification: boolean;
  public isDisableInvitationsWhenTheLimitHasReached: boolean;

  public currentLocation: {latitude: number, longitude: number, name: string};
  public sampleLastDispatchLimit: number;
  public allowFirstTimeEmailWithoutConsent: boolean;
  public enableMaskingOfEmailOnOptOut: boolean;
  public mainPhone: string;

  get xPositionID(): string {
    return this.positionID;
  }

  get xBusinessUnitId(): string {
    return this.businessUnitId;
  }

  get xSystemUserID(): string {
    return this.systemUserID;
  }

  get userID(): string {
    return this.ID;
  }

  get isManager(): boolean {
    return this.childUsers && this.childUsers.length > 0;
  }

  get hasChildUsers(): boolean {
    return this.childUsers && this.childUsers.length > 0;
  }

  public getSupportedAppointmentConfiguredFields(): ConfiguredFields[] {
    if (this.appointmentConfiguredFields && this.appointmentConfiguredFields.length) {
      let result = [...this.appointmentConfiguredFields.filter(field => { return !this.notSupportedAppointmentConfiguredFields.includes(field.fieldType) })];
      return result;
    }
    return [];
  }

  public getSupportedPhoneCallConfiguredFields(): ConfiguredFields[] {
    if (this.phonecallConfiguredFields && this.phonecallConfiguredFields.length) {
      let result = [...this.phonecallConfiguredFields.filter(field => { return !this.notSupportedPhoneCallConfiguredFields.includes(field.fieldType) })];
      return result;
    }
    return [];
  }

  private getProcedureLogDuration(selectedValue: number): string {
    let procedureDuration: string = '';
    if (selectedValue) {
      if (selectedValue === 548910001) {
        procedureDuration = 'Weekly';
      } else if (selectedValue === 548910000) {
        procedureDuration = 'Daily';
      }
      return procedureDuration;
    }
    return 'Daily';
  }

  constructor(raw?: object) {
    if (!raw) return;
    this.thresholdOfflineTime = raw['thresholdOfflineTime'] ? raw['thresholdOfflineTime'] : 10;
    this.thresholdOffsetOfflineTime = raw['thresholdOffsetOfflineTime'] ? raw['thresholdOffsetOfflineTime'] : 2;
    this.businessPhones = raw['businessPhones'];
    this.displayName = raw['displayName'];
    this.givenName = raw['givenName'];
    this.lastName = raw['surname'];
    this.ID = raw['id'];
    this.jobTitle = raw['jobTitle'];
    this.title = raw['title'];
    this.academicTitle = raw['academicTitle'] ? raw['academicTitle'] : '';
    this.mail = raw['mail'];
    this.mobilePhone = raw['mobilePhone'];
    this.officeLocation = raw['officeLocation'];
    this.positionID = raw['positionId'];
    this.businessUnitId = raw['businessUnitId'];
    this.positionName = raw['positionName'];
    this.localeId = raw['localeId'];
    this.countryId = raw['countryId'];
    this.surname = raw['surname'];
    this.systemUserID = raw['systemUserId'];
    this.userPrincipalName = raw['userPrincipalName'];
    this.timezonecode = raw['timezonecode'];
    this.businessUnitName = raw['businessUnitName'];
    this.isProductConsent = raw['productconsent'];
    this.currencysymbol = raw['currencysymbol'];
    this.defaultCurrencyID = raw['_transactioncurrencyid_value'] || '';
    this.enddateautocalculation = raw['indskr_enddateautocalculation'] ? raw['indskr_enddateautocalculation'].split(",")  : [];

    this.offlineDataDuration = (raw['offlineDataDuration']) ? raw['offlineDataDuration'] : -1;
    this.marketScanUpdateDuration = (raw['marketScanUpdateDuration']) ? raw['marketScanUpdateDuration'] : 0;
    this.eventTimeLine = raw['indskr_eventtimeline'] ?? false;

    this.acceptCoaching = (raw['acceptCoaching'] || raw['acceptCoaching'] === 0) ? raw['acceptCoaching'] : -1;
    this.acknowledgeCoaching = (raw['acknowledgeCoaching'] || raw['acknowledgeCoaching'] === 0) ? raw['acknowledgeCoaching'] : -1;
    this.pastMeetingReopenPeriod = (raw['pastMeetingReopenPeriod']) ? raw['pastMeetingReopenPeriod'] : 0;
    this.pastPhoneCallReopenPeriod = (raw['pastPhoneCallReopenPeriod']) ? raw['pastPhoneCallReopenPeriod'] : 0;
    this.pastProcedureLogReopenPeriod = (raw['pastProcedureLogReopenPeriod']) ? raw['pastProcedureLogReopenPeriod'] : 0;
    this.allocationAdjustmentDuration = (raw['allocationAdjustmentDurationDays']) ? raw['allocationAdjustmentDurationDays'] : 0; // this is to control the adjustment Product listing.
    this.procedureLogDuration = (raw['procedureLogDuration']) ? this.getProcedureLogDuration(raw['procedureLogDuration']) : 'Daily';
    this.hasBulkProcedureLog = raw['bulkProcedureLog'] ? raw['bulkProcedureLog'] : false;
    this.hasProcedureLog = raw['procedureLog'] ? raw['procedureLog'] : false;
    this.hasTagAssetsToProcedureLog = raw['tagAssets'] ? raw['tagAssets'] : false;
    this.approvalForAllocationTransfer = raw['approvalForAllocationTransfer'] ? raw['approvalForAllocationTransfer'] : false;
    this.isConsentAddNewEmailDisabled = !raw['isConsentNewEmail'];
    this.isConsentAddNewPhoneNumberDisabled = !raw['isConsentNewPhoneNumber'];
    this.isConsentAddNewAddressDisabled = !raw['isConsentNewAddress'];
    this.isConsentAddNewFaxDisabled = !raw['isConsentNewFax'];
    this.positionGroups = raw['positionGroups'] || [];
    // this.covisitorEditMeeting = raw['covisitorEditMeeting'];
    this.defaultMeasureTab = raw['defaultMeasureTab'];
    this.customNotifications = raw['customNotifications'];
    if (raw['teams']) {
      this.teams = raw['teams'].filter(t => t.members?.some(m => m.systemuserid.includes(raw['systemUserId'])));
    }
    if (raw['orgsettings']) {
      this.orgsettings = raw['orgsettings'];
      // photoUrl is Azure directory url for photo of logged in user
      this.photoUrl = this.orgsettings['photourl'] || '';
    }
    if (raw['childUsers']) {
      raw['childUsers'].forEach(o => {
        let user = this.childUsers.find(u => u.userId == o['userid']);
        if (!user) {
          this.childUsers.push({
            fisrtName: o['firstName'] || '',
            fullName: o['userfullname'] || '',
            userId: o['userid'] || '',
            positionIDs: [o['childpositionid']]
          })
        }
        else user.positionIDs.push(o['childpositionid'])
      });
    }
    if (raw['siblinguserscommonparent']) {
      raw['siblinguserscommonparent'].forEach(o => {
        let user = this.siblingUsersCommonParent.find(u => u.userId == o['siblingUser']);
        if(!user){
          this.siblingUsersCommonParent.push({
            fisrtName: '',
            fullName: o['siblingUserName'] || '',
            userId: o['siblingUser'] || '',
            positionIDs: [o['siblingPositionId']]
          })
        }
        else user.positionIDs.push(o['siblingPositionId'])
      });
    }
    if (raw['siblinguserscommonpositions']) {
      raw['siblinguserscommonpositions'].forEach(o => {
        let user = this.siblingUsersCommonPositions.find(u => u.userId == o['siblingUser']);
        if(!user){
          this.siblingUsersCommonPositions.push({
            fisrtName: '',
            fullName: o['siblingUserName'] || '',
            userId: o['siblingUser'] || '',
            positionIDs: [o['siblingPositionId']]
          })
        }
        else user.positionIDs.push(o['siblingPositionId'])
      });
    }
    // this.childUsers = raw['childUsers'] ? raw['childUsers'].map((o) => ({
    //     fisrtName: o['firstName'] || '',
    //     fullName: o['userfullname'] || '',
    //     userId: o['userid'] || ''
    // })) : [];
    this.featureActions = raw['featureActions'] !== null ? raw['featureActions'].map(action => {
      if (action.indskr_name.toLowerCase() === 'Allocation Adjustment'.toLowerCase()) {
        console.log(action.indskr_name);
      }
      return action.indskr_name
    }) : [];
    this.buSettings = raw['buSettings'] || {};
    this.buConfigs = raw['buConfigs'] || {};
    this.currentLocation = raw['currentLocation'];

    // this.featureActions = this.featureActions.filter( fa => fa !== FeatureActionsMap.MESSAGE_ACTIVITY);

    this.maxMeetingStartDays = raw['pastActivityGracePeriod'] || 3650;
    this.distanceConfigurationForGenee = raw['distanceConfiguration'] || 20;
    this.distanceUnit = raw['distanceUnit'] || DistanceUnits.KM;
    this.meeting_duration = raw['meeting_duration'] || 30; // default time slot for scheduler calender is 30min
    this.pastTimeOffGracePeriod = raw['pastTimeOffGracePeriod'] || 3650;
    this.unsolicitedMeetingLimitPeriod = raw['unsolicitedMeetingLimitPeriod'] || 0;
    this.unsolicitedMeetingLimitTotal = raw['unsolicitedMeetingLimitTotal'] || 0;
    this.iqviaCodBase = raw['iqviaCodBase'] ? raw['iqviaCodBase'] : '';
    this.coachingQualifyingScore = raw['coachingQualifyingScore'] ? raw['coachingQualifyingScore'] : '';
    this.isCustomerProfileLaunchSyncEnabled = raw['indskr_CustomerProfileLaunchSync'] ? raw['indskr_CustomerProfileLaunchSync'] : false;
    this.globalCategorySearchLimit = raw['indskr_GlobalCategorySearchLimit'] ? raw['indskr_GlobalCategorySearchLimit'] : 548910001;
    this.customerAvailability = raw['customerAvailability'] ? raw['customerAvailability'] : false;
    this.accountsCriteriaFetchxml = raw['accountsCriteriaFetchxml'] ? raw['accountsCriteriaFetchxml'] : undefined;
    this.contactsCriteriaFetchxml = raw['contactsCriteriaFetchxml'] ? raw['contactsCriteriaFetchxml'] : undefined;
    this.timelineActivites = raw['timelineActivites'] ? raw['timelineActivites'] : undefined;
    this.timelineActivitesValue = raw['timelineActivitesValue'] ? raw['timelineActivitesValue'] : undefined;
    this.objectivesBasedOnOwnership = raw['objectivesBasedOnOwnership'] ? raw['objectivesBasedOnOwnership'] : undefined;
    this.editTheTargetValueFieldInObjectives = raw['editTheTargetValueFieldInObjectives'] ? raw['editTheTargetValueFieldInObjectives'] : undefined;

    this.meetingLimit = raw['meetingLimit'];
    this.meetingProximityWarningPeriod = raw['meetingProximityWarningPeriod'] || 0;
    this.validCallDistance = raw['validCallDistance'] || 1500;
    this.gpsFunctionality = raw['gpsFunctionality'] || false;

    if(!raw['appConfigFields']){
      raw['appConfigFields']=[];
    }
    if (Array.isArray(raw['appConfigFields'])) {
      this.configuredFields = [];
      raw['appConfigFields'].forEach(element => {
        this.configuredFields.push(new ConfiguredFields(element));
      });
      this.configuredFields.sort((a, b) => {
        // return ((a.fieldName  == b.fieldName) ? 0 : ((a.fieldName > b.fieldName ) ? 1 : -1 ));
        return (a.fieldSequence === b.fieldSequence) ? ((a.fieldLabel > b.fieldLabel) ? 1 : -1) : 0;
      });
    }

    // Filter Configure Fields
    this.configuredFields.forEach(configuredField => {
      switch (configuredField?.entityName) {
        case 'appointment':
          this.appointmentConfiguredFields.push(configuredField);
          break;

        case 'phonecall':
          this.phonecallConfiguredFields.push(configuredField);
          break;

        case 'msevtmgt_event':
          (
            SupportedEventConfiguredFieldTypes.includes(configuredField.fieldType)
            && !DefaultEventFieldNamesThatCannotBeConfigured.includes(configuredField.fieldName)
          )
            ? this.eventConfiguredFields.push(configuredField)
            : undefined;
          break;
        case 'indskr_coachingreport':
          (
            SupportedCoachingConfiguredFieldTypes.includes(configuredField.fieldType)
            && !DefaultCoachingFieldNamesThatCannotBeConfigured.includes(configuredField.fieldName)
          )
            ? this.coachingConfiguredFields.push(configuredField)
            : undefined;
          break;
        case 'indskr_procedurecontract':
          SupportedProcedureContractConfiguredFieldTypes.includes(configuredField.fieldType)
          && !DefaultContractFieldNamesThatCannotBeConfigured.includes(configuredField.fieldName)
            ? this.procedureContractConfiguredFields.push(configuredField)
            : undefined;
          break;
        default:
          console.warn('user: unexpected configured field: ', configuredField);
          break;
      }
    });

    // Sort event config fields
    this.eventConfiguredFields.sort(configFieldSortFn);

    //sort contract config fields
    this.procedureContractConfiguredFields.sort(sortConfigFieldsByOrder);

    this.positions = [];

    //If we have an array of positions lets map em
    if (Array.isArray(raw['positions']) && raw['positions'].length > 0) {
      let rawPositions = raw['positions'];
      rawPositions.map(position => this.positions.push(new UserPosition(position)));
    }

    if (Array.isArray(raw['siblingpositions']) && raw['siblingpositions'].length > 0) {
      let rawsiblingpositions = raw['siblingpositions'];
      rawsiblingpositions.map(pos => this.siblingPositions.push(new SiblingPosition(pos)));
    }

    if (Array.isArray(raw['securityRoles']) && raw['securityRoles'].length > 0) {
      this.securityRoles = raw['securityRoles'];
    }

    // io-configurations
    if (raw['ioConfigurations'] && raw['ioConfigurations'].length > 0) {
      this.ioConfigurations = [];
      raw['ioConfigurations'].forEach(c => {
        this.ioConfigurations.push(new ioConfiguration(c));
      });
    }

    if(raw['appIoConfigurations'] && raw['appIoConfigurations'].length > 0){
      raw['appIoConfigurations'].forEach(c => {
        this.ioConfigurations.push(new ioConfiguration(c));
      });
    }

    this.covisitorComplianceCheck = !!raw['covisitorCompliance'];
    this.isCovisitorComplianceMandatory = !!raw['isCovisitorComplianceMandatory'];
    this.covisitorComplianceDescription = raw['covisitorComplianceDescription'] ?? '';

    this.bestTimeRecommendationConfig = raw['bestTimeRecommendationConfig'] || 0;
    this.showBestTimeRecommendation = raw['showBestTimeRecommendation'] || true;

    this.indskr_mapkeymessagestopositiongroup = raw['indskr_mapkeymessagestopositiongroup'] || false;
    this.employeeId = raw['employeeId'] || null;

    this.isInvitationLimitAlertMessageNotification = raw['invitationLimitAlertMessageNotification'] || false;
    this.isDisableInvitationsWhenTheLimitHasReached = raw['disableInvitationsWhenTheLimitHasReached'] || false;

    // To check bestTimeRecommendationConfig from BU is more than 0 then set showBestTimeRecommendation flag
    if (this.bestTimeRecommendationConfig > 0)
      this.showBestTimeRecommendation = true;
    else
      this.showBestTimeRecommendation = false;

    this.hideBestTimeRecommendationCount = raw['hideBestTimeRecommendationCount'] || 0;

    this.sampleLastDispatchLimit = raw['orgsettings'] ? (raw['orgsettings']['os.indskr_samplelastdispatchlimit']) ? raw['orgsettings']['os.indskr_samplelastdispatchlimit'] : 0 : 0;
    this.allowFirstTimeEmailWithoutConsent = raw['orgsettings'] ? (raw['orgsettings']['os.indskr_allowfirsttimeemailwithoutconsent']) ? raw['orgsettings']['os.indskr_allowfirsttimeemailwithoutconsent'] : false : false;
    this.enableMaskingOfEmailOnOptOut = raw['orgsettings'] ? (raw['orgsettings']['os.indskr_enablemaskingofemailonoptout']) ? raw['orgsettings']['os.indskr_enablemaskingofemailonoptout'] : false : false;
    this.mainPhone = raw['mainPhone'] || '';
  }

  static fromStoredObject(raw: object): User {
    let user = new User();
    Object.assign(user, raw);
    user.positions = user.positions.map(position => UserPosition.fromStoredObject(position));
    return user
  }
}

export class UserPosition {
  public name: string;
  public ID: string;
  public userPositionID: string;
  public selectedRepCallPlanFilter: boolean = false;
  public primary: boolean;
  public subPositions: boolean;
  public status: number;

  constructor(raw?: any) {
    if (!raw) return;
    this.name = raw['positionName'];
    this.ID = raw['positionId'];
    this.userPositionID = raw['userPositionId'];

    this.primary = raw['primary'] || false;
    this.subPositions = raw['subPositions'];
    this.status = raw['status'];
  }

  static fromStoredObject(raw: object): UserPosition {
    let userPosition = new UserPosition();
    Object.assign(userPosition, raw);
    return userPosition;
  }
}
export class childPosition {
  childPositionId: string;
  childPosition: string;
  selectedRepCallPlanFilter: boolean;

  constructor(data: object) {
    this.childPosition = data['childposition'];
    this.childPositionId = data['childpositionid'];
    this.selectedRepCallPlanFilter = false;
  }
}

export class SiblingPosition{
  siblingPositionId: string;
  siblingPosition: string;
  parentId: string;
  parentName: string;
  constructor(raw?: any) {
    this.siblingPositionId = raw['siblingposition.positionid']
    this.siblingPosition = raw['siblingposition.name'];
    this.parentId = raw['parentposition.positionid'];
    this.parentName = raw['parentposition.name'];
  }
}

export interface childUser {
  fisrtName: string,
  fullName: string,
  userId: string,
  positionIDs?: any[]
}

export enum FeatureActionsMap {
  BASELINE = 'Baseline Features',
  CALL_PLANS = 'Call Plans Tool',
  TEAM_CALL_PLANS = 'Call Plans Team View',
  REMOTE_FEATURE = 'Remote Features',
  MEETING_AUTO_SUBJECT = 'Meeting Auto-Subject',
  MEETING_NOTES = 'Meeting Notes',
  JOINT_MEETING = 'Joint Meeting',
  COACHING_TOOL = 'Coaching Tool',
  COACHING_TEAM_VIEW = 'Coaching Team View',
  CONTACT_CREATOR = 'Contacts Creator',
  CONTACT_CREATOR_EVENTS = 'Create Event Participant',
  CONTACT_EDITOR = 'Contacts Edit',
  CONTACT_EDITOR_EVENTS = 'Edit Event Participant',
  CONTACTS_GLOBAL_SEARCH = 'Contacts Global Search',
  SCHEDULER = 'Scheduler Tool',
  ALLOCATION_TOOL = 'Allocation Order Tool',
  ALLOCATION_ORDER_SIGNATURE = 'Allocation Order Signature',
  ALLOCATION_TRANSFER = 'Allocation Transfer',
  RECIEPTS_TAB = 'Receipts Tab',
  TIME_OFF_TOOL = 'Time Off Tool',
  TIME_OFF_AUTOSUBJECT = 'Time Off Auto-Subject',
  TIME_OFF_AUTO_APPROVAL = 'Time Off Auto Approval',
  TIME_OFF_TEAM_REQUESTS = 'Time Off Team Requests',
  OMNIPRESENCE_DNA = 'Omnipresence DNA',
  INBOUND_MEETING = 'Inbound Meeting',
  MEETING_SCREENSHARE = "Meeting Screenshare",
  LIVETIME_SCREENSHARE = "Livetime Screenshare",
  RESOURCE_TOOL = 'Resource Tool',
  ERROR_REPORTING_REPLAY = 'Error Reporting Replay',
  VIDEO_BUTTON = 'Video Button',
  MEETING_VOIP = 'Meeting VoIP',
  MEETING_DURATION = 'Meeting Duration',
  MEETING_DURATION_REQUIRED = 'Meeting Duration Required',
  MEETING_REMOVE_END_DATE = 'Meeting Remove End Date',
  GOOGLE_ANALYTCS = 'platform analytics google analytics',
  GENIE_CALL_NOTES = 'Meeting Notes Assistant',
  EMAIL_ACTIVITY = 'Email Activity',
  CONSENT_TOOL = 'Consent Tool',
  CONSENT_SIGNATURE = 'Consent Signature',
  CONTENT_MATCHING = 'Content Matching',
  LIVEMEET = 'Meeting LiveMeet',
  TEAMVIEW_FILTER = 'TeamView Filter',
  ACCOUNT_GLOBAL_SEARCH = 'Account Global Search',
  ACCOUNT_MANAGEMENT = 'Account Management Tool',
  ACCOUNT_FOLLOW_UP_TASK = 'Account Management Tasks',
  CASE_INTAKE = 'Case Tool',
  PHONECALL_ACTIVITY = 'Phone call activity',
  SALES_PROFILE = 'Contact Commercial Profile',
  MEDICAL_PROFILE = 'Contact Scientific Profile',
  EVENT_INFORMATION = 'Contact Event Profile',
  ALLOCATION_ORDER_ACTIVITY = 'Allocation Order Activity',
  SCIENTIFIC_TOOL_PLAN = 'Scientific Plan Tool',
  SCIENTIFIC_TEAM_TOOL_PLAN = 'Scientific Plan Team View',
  SCIENTIFIC_FOLLOW_UP_TASK = 'Scientific Management Tasks',
  THERAPEUTIC_AREA = 'Therapeutic Area',
  TEAM_CASES = 'Case Team View',
  ORDER_MANAGEMENT = 'Order Management Tool',
  ORDER_MANAGEMENT_TEAM_VIEW = 'Order Management Team View',
  ORDER_MANAGEMENT_AUTO_APPROVAL = 'Order Management Auto Approval',
  EVENT_REGISTRATION = 'Event Registration',
  XPERIENCES = "Xperiences Features",
  SETTING_TOOL = "Settings Tool",
  ASSISTED_DIAGNOSTIC = "Assisted Diagnostics",
  MESSAGE_ACTIVITY = "Message Activity",
  FACEBOOK_ACTIVITY = "Facebook Activity",
  WHATSAPP_ACTIVITY = "WhatsApp Activity",
  MESSAGE_NON_NATIVE = "Messages Non-Native",
  SMS_ACTIVITY = "SMS Activity",
  CUSTOMER_INQUIRY_OFFLINE = "Customer Inquiry Offline",
  DATABASE_ENCRYPTION = 'Database Encryption',
  CONTACT_UPDATE = "Contact Update",
  EMAIL_CONSENT_REQUIRED = "Email Consent Required",
  KPI_ASSISTANT = 'KPI Assistant',
  OPPORTUNITY_MANAGEMENT = 'Opportunity Management Tool',
  OPPORTUNITY_MANAGEMENT_TASK = "Opportunity Management Tasks",
  OPPORTUNITY_MANAGEMENT_QUOTES = "Opportunity Management Quotes",
  MESSAGE_CONSENT_REQUIRED = 'Message Consent Required',
  MEETING_CONTENT_DURATION = 'Meeting ContentDuration',
  ACCOUNT_CREATOR = 'Account Creator',
  ACCOUNT_EDIT = 'Accounts Edit',
  CUSTOMER_NEAR_ME = 'Customers Near Me',
  CASE_SIGNATURE = 'Case Signature',
  SEARCH_SHARE = 'Search Share',
  MEETING_TYPE = "Meeting Type",
  MEETING_OBJECTIVES = "Meeting Objectives",
  CUSTOMER_NOTES = "Customer Notes",
  REMOTE_SIGNATURE = 'remote-signature',
  ACCOUNTS_TRENDING_PILL = "Accounts Trending Pill",
  XPERIENCES_FEED = "Xperiences Feed",
  EDGE_ANALYTICS_MEETING = "Edge Analytics",
  CUSTOMER_SCAN_TOOL = "Customer Scan Tool",
  EVENT_TOOL = "Event Tool",
  EVENT_UPDATE = "Event Update",
  EVENT_GOALS = "Event Goals",
  EVENT_PARTNER = "Event Partner",
  ACCOUNTS_CREATE_REQUEST = "Accounts Create Request",
  ACCOUNTS_EDIT_REQUEST = "Accounts Edit Request",
  ACCOUNTS_ONEKEY_SELECTION = "Accounts OneKey Selection",
  ACCOUNTS_VEEVA_GLOBAL_SEARCH = "Accounts Veeva Global Search",
  ACCOUNTS_VEEVA_MY_REQUESTS = "Accounts Veeva My Requests",
  ACCOUNTS_VEEVA_SELECTION = "Accounts Veeva Selection",
  CONTACTS_CREATE_REQUEST = "Contacts Create Request",
  CONTACTS_EDIT_REQUEST = "Contacts Edit Request",
  CONTACTS_ONEKEY_SELECTION = "Contacts OneKey Selection",
  CONTACTS_VEEVA_GLOBAL_SEARCH = "Contacts Veeva Global Search",
  CONTACTS_VEEVA_MY_REQUESTS = "Contacts Veeva My Requests",
  CONTACTS_VEEVA_SELECTION = "Contacts Veeva Selection",
  ONE_TIME_VEEVA_MEETING = "One Time Veeva Meeting",
  CUSTOMER_SCAN_AUTO_SUBJECT = "Customer Scan Auto Subject",
  PROCEDURE_LOG = "Procedure Log",
  MEETING_SOLICITATION_COMPLIANCE = "Meeting Solicitation Compliance",
  MEETING_CALENDAR_INVITE = 'Meeting Calendar Invite',
  ALLOCATION_ADJUSTMENT = "Allocation Adjustment",
  ALLOCATION_ADJUSTMENT_AUTO_APPROVAL = "Allocation Adjustment Auto Approval",
  ALLOCATION_ADJUSTMENT_TEAM_REQUEST = "Allocation Adjustment Team Requests",
  ONEKEY_CONTACT_SEARCH = "Contact OneKey Search",
  ONEKEY_CONTACT_DOWNLOAD = "Contact OneKey Download",
  ONEKEY_ACCOUNT_SEARCH = "Account OneKey Search",
  ONEKEY_ACCOUNT_DOWNLOAD = "Account OneKey Download",
  OPPORTUNITY_REVENUE_USER_PROVIDED = "Opportunity Revenue User Provided",
  OPPORTUNITY_CO_OWNER = "Opportunity Co Owner",
  JOINT_PHONE_CALL = "Joint Phone Call",
  ENABLE_PHONE_CALL_CONSENT_CHECK = 'Enable phone call consent check',
  CHECK_AMA_STATUS_ON_CALL_CREATION = 'Check AMA Status on Call Creation',
  MEETING_REOPEN = "Meeting Reopen",
  MESSAGE_PRODUCT_BUNDLE = "Messages Product Bundle",
  PHONECALL_REOPEN = "Phone call Reopen",
  OPPORTUNITY_ATTACHMENT_UPLOAD = "Opportunity attachment upload",
  CONFIRM_OPPORTUNTIY = 'Confirm opportunity',
  ACCOUNT_NOTES = "Account Notes",
  ACCOUNT_ATTACHMENT_UPLOAD = "Account Attachment upload",
  FOLLOW_UP_ATTACHMENT_UPLOAD = "Follow-up actions attachment upload",
  DISCONTINUE_OPPORTUNTIY = "Discontinue Opportunity",
  CUSTOMER_NOTES_ATTACHMENT_UPLOAD = "Customer Attachment Upload",
  PROCEDURE_TRACKER = "Procedure Tracker",
  ACCOUNT_INFORMATION_DEACTIVATE_RECORDS = "Account Information Deactivate records",
  CONTACT_INFORMATION_DEACTIVATE_RECORDS = "Contact Information Deactivate records",
  COACHING_SHARE = "Coaching Share",
  CUSTOMER_CHANGE_REQUESTS = "Customer Change Requests",
  TEAMS_MEETING = "Teams Meeting",
  CUSTOMER_ASSESS = "Customer Assess",
  TIME_RECOMMENDATION = "Time Recommendation",
  FACE_TO_FACE = "Face To Face",
  MEETING_ATTACHMENT_NOTES = "Meeting Attachment Notes",
  EVENTS_IN_MEETINGS = "Events in Meetings",
  ASSOCIATE_OPPORTUNITIES_TO_MEETINGS = "Associate Opportunities to meetings",
  AUTO_SUBJECT_PROCEDURE_TRACKER = "Auto-Subject Procedure Tracker",
  USER_DATETIME_SETTINGS = "User Datetime Settings",
  MEETING_OBJECTIVE_SELECTION = "Meeting Objective Selection",
  SHOW_SUGGESTED_MEETINGS = "Show Suggested Meetings",
  SHOW_SUGGESTED_WECOM_MESSAGES = "Show Suggested WeCom Messages",
  ENABLE_GPS_CHECK_IN = "Enable GPS Check-in",
  WECOM_ACTIVITY = "WeCom Activity",
  ENABLE_TENCENT_REMOTE_DETAILING = "Enable Tencent Remote Detailing",
  EXPENSES_IN_ACCOUNT_PLAN = "Expenses in Account Plan",
  EVENTS_IN_ACCOUNT_PLAN = "Events in Account Plan",
  FIELD_MATERIAL_MANAGEMENT = "Field Material Management",
  ASSETS = "Assets",
  ALL_ASSETS = "All assets",
  ALL_MY_ASSETS = "All My Assets",
  SEARCH_ALL_ASSETS = "Search all assets",
  EVENT_CUT_OFF_DATE = "Event Cut Off Date",
  EVENT_PARTICIPANT_NOTES = "Event Participant Notes",
  OPPORTUNITY_BY_PRODUCTS = "Opportunity by Products",
  OPPORTUNITY_COMPETITOR_DATA = "Opportunity Competitor Data",
  ASSESSMENT_ADVANCED_SEARCH = "Assessment Advanced Search",
  OPPORTUNITY_OPTIONSET_FIELDS = "Opportunity OptionSet Fields",
  OPPORTUNITY_ORDERS = "Opportunity Orders",
  EVENT_ATTENDANCE_CAPTURE = "Event Attendance Capture",
  EVENT_PASS_PARTICIPANT_ASSOCIATION = "Event Pass Participant Association",
  FORCE_OFFLINE_ACTIVITIES_PUSH = 'Force Offline Activities Push',
  ORDER_MANAGEMENT_AUTO_FULFILL = "Order Management Auto Fulfill",
  MEETING_ATTACHMENT_UPLOAD = "Meeting Attachment Upload",
  MEETING_NOTES_CAPTURE = "Meeting Notes Capture",
  CONSENT_ADD_ADDRESS_DISABLE = "Consent Add Address Disable",
  OPPORTUNITY_AUTO_SUBJECT = "Opportunity Auto-Subject",
  PROCEDURE_LOG_CO_OWNER = "Procedure Log Co-Owner",
  ACCOUNT_PLANS_FOR_MEETING = "Account Plans for Meeting",
  CONTENT_KEYWORD_SEARCH = "Content Keyword Search",
  TAG_ASSETS_TO_MEETINGS = "Tag Assets to Meetings",
  CUSTOMER_ADDITIONAL_INFORMATION = "Customer Additional Information",
  MARKETING_BUSINESS_PLAN = "Marketing Business Plans",
  CUSTOMER_AVAILABILITY = "Customer Availability",
  ACCOUNT_PLAN_PROGRESS_REPORTS_CREATION = "Account Plan Progress Reports Creation",
  ACCOUNT_PLAN_CREATION = "Account Plan Creation",
  CUSTOMER_MAPPING_APPROVAL = "Customer Mapping Approval",
  MEETING_HIDE_SUBTYPE = "Meeting Hide Subtype",
  VISIT_AUTO_SUBJECT = "Visit Auto-Subject",
  ACCOUNT_CONTACT_MANDATORY = "Account and Contact Mandatory",
  CUSTOMER_VISIBILITY_APPROVAL_BASED = "Customer Visibility Approval Based",
  CONTENT_REFERENCES = "Content References",
  QUOTES = "Quotes",
  CUSTOMER_CALL_PLANS = 'Customer Call Plan Tool',
  EVENT_APPROVAL = 'Event Approval and Budget',
  MEETING_PROCEDURE = "Meeting Procedure",
  TIMEOFF_ADMIN_COMMENTS = "Time Off Admin Comments",
  MEETING_NOTES_ACCESSIBILITY = "Meeting Notes Accessibility",
  SALES_DATA_REPORT = "Sales Data Report",
  FOLLOW_UP_ASSIGNMENT_ORGANIZATION = "Follow-Up Assignment Organization",
  PHARMACOVIGILANCE_TOOL = 'Pharmacovigilance Tool',
  ADDITIONAL_MANDATORY_FIELDS_FOR_EVENTS = 'Additional Mandatory Fields For Events',
  ASSESSMENTS_TEAM_VIEW = 'Assessments Team View',
  REMOVE_COMPLETE_EVENT_BUTTON = 'Remove Complete Event Button',
  PHARMACOVIGILANCE_INFORMATION_BUTTONS = 'Pharmacovigilance Information Buttons',
  CUSTOMER_KOL_STATUS = "Key Opinion Leader",
  TIMEOFF_HIDE_SUBJECT_POSITION = "Timeoff Hide Subject Position",
  ALLOW_AFFILIATED_HCO_AND_HCP_SELECTION_ONLY = "Allow Affiliated HCO and HCP Selection Only",
  CUSTOMER_MEDICAL_INSIGHT = "Customer Medical Insights",
  SINGLE_SELECTION_OF_HCO_AND_HCP = "Single Selection of HCO and HCP",
  CUSTOMER_LIST_MANAGEMENT = "Customer List Management",
  FOLLOW_UP_ACTION_OBJECTIVE_SELECTION = "Follow-up Action Objective Selection",
  MEETING_CONTENT_REASON = "Meeting Content Reason",
  CUSTOMER_SURVEY = "Customer Survey",
  ACCOUNT_VISIT = 'Account Visit',
  MEETING_NOTES_RELABEL = 'Meeting Notes Relabel',
  FOLLOWUP_ACTION_NOTES = 'Follow-Up Action Notes',
  MEETING_DISEASE_AREA = 'Meeting Disease Area',
  CAPTURE_REMOTE_DETAILING_DURATION = 'Capture Remote Detailing Duration',
  MEETING_CONSENT_CHECK = 'Meeting Consent Check',
  ENABLE_INDICATIONS = 'Enable Indications',
  MEETING_PRODUCT_INDICATION = 'Meeting Product Indication',
  LIST_MANAGEMENT_NO_WINDOW = 'List Management No Window',
  LIST_MANAGEMENT_CURRENT_UPDATE = "List Management Current Update",
  HIDE_LIVETIME_FOR_F2F_MEETINGS = 'Hide LiveTime field for F2F meetings',
  MEETING_KEY_MESSAGE_SENTIMENT = 'Meeting Key Message Sentiment',
  VOICE_CALL = "Voice Call",
  SET_PRODUCTS_TO_BE_OPTIONAL_SECTION = "Set Products to be optional section",
  SET_THERAPEUTIC_AREAS_TO_BE_OPTIONAL_SECTION = "Set Therapeutic Areas to be optional section",
  FOLLOW_UP_ACTION_ACTIVITY = "Follow up Action Activity",
  CUSTOMER_JOURNEY_TIMELINE = "Customer Journey Timeline",
  ASSIGN_MEETING = "Assign Meeting",
  ASSIGN_OPPORTUNITY = "Assign Opportunity",
  ASSIGN_PROCEDURE_LOG = "Assign Procedure Log",
  ASSIGN_KITBOOKING = "Assign Kit Booking",
  TURN_OFF_SENTIMENT_CAPTURE = "Meeting Disable Sentiment",
  EMAIL_ACCOUNT_INFORMATION_CAPTURE = "Email Account Information Capture",
  ALLOCATION_ORDER_DATE_UPDATE = "Allocation Order Date Update",
  CALL_PLAN_ACTIVITIES = "Call Plan Activities",
  // SCAN_PRODUCT = "Scan Products using Barcode"
  SCAN_PRODUCTS_MEETING = "Scan Products using Barcode on Meeting",
  SCAN_PRODUCTS_PROCEDURE_LOG = "Scan Products using Barcode on Procedure Log",
  ACCESS_COACHING_PLAN = "Access Coaching Plan",
  CUSTOMER360UI = "customer-360-ui-12-3",
  EMAIL_SENT_WARNING = "Email Sent Warning",
  EVENT_FOLLOW_UP_ACTION = 'Event Follow Up Action',
  STORE_CHECK = "Store Check",
  PROMO_CHECK = "Promo Check",
  MEETING_CALL_OBJECTIVE_ACCESSIBILITY = "Meeting Call Objective Accessibility",
  ACCESS_PROCEDURE_CONTRACT = "Access Procedure Contract",
  ACCOUNT_CALL_PLAN = "Account Call Plan",
  GENERATE_QR_CODE_LD = "generate-qr-code",
  ENABLE_MANUAL_GPS_CHECK_IN = "Enable Manual GPS Check-in",
  OFFTAKE_FEATURE = "Offtake Check",
  ACCOUNT_SURVEY = "Account Survey",
  MARKETING_BUSINESS_PLAN_AUTO_SUBJECT = "Marketing Business Plan Auto Subject",
  OPPORTUNITY_PRIMARY_TARGET = "Opportunity Primary Target",
  OPPORTUNITY_STAKEHOLDER_VALUE = "Opportunity Stakeholder Value",
  MBP_FIELDS = "MBP Fields",
  ACCOUNT_VISIT_FORMAT_INSTORE_MR = "Account Visit Format Instore: submit conditions for MR",
  ACCOUNT_VISIT_FORMAT_INSTORE_OSS = "Account Visit Format Instore: submit conditions for OSS",
  DISABLE_GPS_CHECKIN_FEATURE_IN_HCP_MEETINGS = "Disable GPS Checkin feature in HCP Meetings",
  ACTIVATE_GENIE = "Activate Genie",
  FILTER_OUT_FROM_APP_SYNC = "Filter Out From App Sync",
  KIT_BOOKING = 'Kit Booking',
  PROCEDURE_LOG_CO_VISITOR = "Procedure Log Co-Visitor",
  SHORT_CALL_LAUNCHER = 'Short Call Launcher',
  PROCEDURE_LOG_NOTE_ENABLE = "Capture Notes on Procedure Log",//"Procedure Log Notes capture",
  PROCEDURE_LOG_NOTE_AND_ATTACHMENT_ENABLE = "Procedure Log Attachment Upload",
  PROCEDURE_LOG_ATTACHMENT_ENABLE = "Procedure Log Attachment Upload Download",
  EVENT_CREATION_ON_APP = "Event Creation on App",
  EXEEVO_ALPHA_COPILOT = "Exeevo Alpha Copilot",
  SHORT_CALL_LAUNCHER_DISPLAY_CUSTOMER_SEGMENTATION = 'Short Call Launcher - Display Customer Segmentation',
  CALL_PLAN_HOME_SCREEN_STYLE_A = 'Call Plan Home Screen Style A',
  SHOR_CALL_LAUNCHER_DISABLE_APPOINTMENT_IN_NEW_ACTIVITY = 'Short Call Launcher - Disable Appointment in New Activity',
  CALL_PLAN_HOME_SCREEN_STYLE_B = 'Call Plan Home Screen Style B',
  ALL_PRESENTATION_PLAY_DURATION = 'All Presentation Play Duration',
  MEETING_SUPPORTING_MATERIALS = 'Meeting Supporting Materials',
  SHOW_SUGGESTED_TASKS = 'Show Suggested Tasks'
}

export class ioConfiguration {
  configName: string;
  configValue: string;
  ioConfigurationId: string;

  constructor(data: object) {
    this.configName = data['indskr_configname'] ? data['indskr_configname'] : '';
    this.configValue = data['indskr_configvalue'] ? data['indskr_configvalue'] : '';
    this.ioConfigurationId = data['indskr_ioconfigurationid'] ? data['indskr_ioconfigurationid'] : '';
  }
}

// export class BuLevelConfig {
//   indskr_noprocedureday: boolean;
//   indskr_lastupdatedby:boolean;
//   constructor(data: object) {
//     this.indskr_noprocedureday = data['indskr_noprocedureday'] ?? false;
//     this.indskr_lastupdatedby = data['indskr_lastupdatedby'] ?? false;
//   }
// }


export enum AdjustmentApprovalType {
  ApprovalConfiguration  = 600000001,
  Legacy = 600000000
}

export enum EventPeriodType {
  Monthly  = 548910000,
  Quarterly = 548910001,
  Yearly = 548910002
}

export enum MeetingProximityActionOption {
  Warn = 548910000,
  BlockAndCancel = 548910001,
  BlockAndReopen = 548910002
}

export enum OneKeyTokenRplacementLanguage {
  User = 548910000,
  Customer = 548910001
}
