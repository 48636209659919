import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IndHeaderLeftDataModel } from "@omni/models/indHeaderLeftDataModel";
import {IndSectionHeaderViewDataModel} from '@omni/models/indSectionHeaderDataModel';
import { DeviceService } from "@omni/services/device/device.service";
import { FooterService } from "@omni/services/footer/footer.service";
import * as _ from 'lodash';
import { ModalController, PopoverController } from '@ionic/angular';
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { SelectedSuggestionPillDataModel, SuggestionPillType, UserSavedSearchTypes, CustomCategorySearchToolName } from "@omni/models/search-config-data-model";
import { SearchConfigService } from "@omni/services/search/search-config.service";
import { OmniAccordionViewDataModel } from "@omni/models/omniAccordionViewDataModel";
import { AuthenticationService } from "@omni/services/authentication.service";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { SearchConfigDataService } from "@omni/data-services/search-config/search-config-data-service";
import { AccountsModel } from "@omni/classes/account/account.class";
import { ContactsModel } from "@omni/classes/contact/contact.class";
import { IndDateTimeFormViewDataModel } from "@omni/models/indDateTimeFormDataModel";
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from "../ind-datetime-form/ind-datetime-form";
import { takeUntil } from "rxjs/operators";
import { BehaviorSubject, Subject } from "rxjs";
import { ActivityService } from "@omni/services/activity/activity.service";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { DatePipe } from "@angular/common";
import moment from "moment";
import { AlertService } from "@omni/services/alert/alert.service";
import { AgendaFooterService, AgendaFooterView } from "@omni/services/footer/agenda-footer.service";
import { PresentationView, UIService } from "@omni/services/ui/ui.service";
import { SurveyCategory, SurveyType } from "@omni/classes/customer-assessment/assessment-template.class";
import { ActivityType } from "@omni/classes/activity/activity.class";
import { GlobalUtilityService } from "@omni/services/global-utility.service";
import { UserTagService } from "@omni/services/user-tag/user-tag.service";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { isArray } from "lodash";
import { IndDropdownListDetailModel } from "@omni/models/indDropdownListModel";



const MIN_SEARCH_LENGTH = 1;


@Component({
  selector: 'ind-filter-menu',
  templateUrl: 'ind-filter-menu.html',
  styleUrls:['ind-filter-menu.scss']
})
export class IndFilterMenuComponent {
  @Input() viewData: any;
  @Input() selectedFilter: SelectedSuggestionPillDataModel[];
  @Input() from: any;
  @Input() subFrom: any;
  public filterMenuHeaderLeftModel: IndHeaderLeftDataModel;
  public filterMenuSectionHeader: IndSectionHeaderViewDataModel;
  public searchedFilterMenuSectionHeader: IndSectionHeaderViewDataModel;
  public searchInput: string;
  public readOnly = false;
  public isRightPaneNavActive = false;
  public searching = false;
  public categories: OmniAccordionViewDataModel[] = [];
  public searchedCategories: OmniAccordionViewDataModel[] = [];
  public selectedCategories: SelectedSuggestionPillDataModel[] = [];
  public tempSelectedcategories: SelectedSuggestionPillDataModel[] = [];
  public savedSelectedCategories: SelectedSuggestionPillDataModel[] = [];
  public startDateField: IndDateTimeFormViewDataModel;
  public endDateField: IndDateTimeFormViewDataModel;
  private startDateTimeValue: string;
  private startDateValue: string;
  private endDateTimeValue: string;
  private endDateValue: string;
  private _selectedDate: {startDate: string, endDate: string};
  public backgroundUploadInProgress: boolean = false;
  ngDestroy$: Subject<boolean> = new Subject<boolean>();
  public isDisplayDateField: boolean = false;
  public isUpdatedDate: boolean = false;
  public isSaveFilterEnabled: boolean = false;
  public savedFilters: OmniAccordionViewDataModel;
  private _isMyActivitiesDateField: boolean = false;
  public isSelectedMyActivity: boolean = false;
  public selectedCategory:OmniAccordionViewDataModel;
  public optionsList = [];
  public filteredOptionsList = [];
  public displayOptionsList = [];
  public isOptionsSearching:boolean = false;
  private recordCount: number = 30;

  public displayOptionList$ = new BehaviorSubject<any[]>(null);
  public viewPortItems: any;

  constructor(
    public agendaFooterService: AgendaFooterService,
    public footerService: FooterService,
    public translate: TranslateService,
    public device: DeviceService,
    private searchConfigService: SearchConfigService,
    private readonly modalCtrl: ModalController,
    private readonly navService: NavigationService,
    public authService:AuthenticationService,
    public searchConfigDataService: SearchConfigDataService,
    private _cd: ChangeDetectorRef,
    public activityService: ActivityService,
    private popoverCtrl: PopoverController,
    public dateTimeFormatsService: DateTimeFormatsService,
    private datePipe: DatePipe,
    private alertService: AlertService,
    public uiService: UIService,
    private readonly utilityService: GlobalUtilityService,
    private readonly userTagService: UserTagService,
    public contactService: ContactOfflineService,
  ){}

  ngOnInit() {
    if(!_.isEmpty(this.selectedFilter)) this.selectedCategories = _.cloneDeep(this.selectedFilter);
    if(!_.isEmpty(this.selectedCategories)) this.tempSelectedcategories = _.cloneDeep(this.selectedCategories);
    this._init();
    if((this.from == PageName.ContactPageComponent && this.subFrom == ContactsModel.MY_CONTACTS_TAB)
      || (this.from == PageName.AccountPageComponent && this.subFrom == AccountsModel.MY_ACCOUNTS_TAB)  
      || (this.from == PageName.PresentationPageComponent)|| (this.from == PageName.ResourcePageComponent)
      || this.from == PageName.ActivitiesPageComponent) {
        this.isSaveFilterEnabled = true;
    }
    if (this.from != PageName.CustomerCallPlanPage) {
    if(this.isSaveFilterEnabled) {
      this._initSavedSearchDataModels(this._getToolSearchName(), false);
      this.agendaFooterService.footerViewAtHome = this.agendaFooterService.lastFooterView;
      this.agendaFooterService.initButtons(AgendaFooterView.FilterMenu);
      this._enableDisableFooterButtons();
      this.searchConfigService.savedSearchesObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(savedSearches => {
        this._initSavedSearchDataModels(this._getToolSearchName());
      });
    }
  }
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
      this._cd.detectChanges();
    });

    this.searchConfigService.onToggleChildItemObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(toggleChildItem => {
      if(toggleChildItem && this.searchConfigService.childUsersWithPositionsViewData) {
        this.toggleCategoryValue(this.searchConfigService.childUsersWithPositionsViewData,toggleChildItem);
        this.searchConfigService.childUsersWithPositionsViewData = null;
      }
    });
  }

  private _init(isClearFilter?: boolean) {
    this.uiService.isFilterActivated = true;
    this._initFilterMenuHeaderLeft();
    if(this.from == PageName.ContactPageComponent) {  
      if(this.subFrom == ContactsModel.MY_CONTACTS_TAB) {
        this._isMyActivitiesDateField = true;
        this._selectedDate = _.cloneDeep(this.viewData.selectedDate);
        this.isUpdatedDate = !_.isEmpty(this._selectedDate) && !_.isEmpty(this._selectedDate.startDate) && !_.isEmpty(this._selectedDate.endDate);
        this._initDateFormFields(); 
        this._initAccordionViewForContact(isClearFilter);
      }else if(this.subFrom == ContactsModel.CHANGE_REQUESTS_TAB) {
        this._initAccordionViewForContactCR();
      }
    }else if(this.from == PageName.AccountPageComponent) {
      if(this.subFrom == AccountsModel.MY_ACCOUNTS_TAB) {
        this._initAccordionViewForAccount(isClearFilter);
      }else if(this.subFrom == AccountsModel.CHANGE_REQUESTS_TAB) {
        this._initAccordionViewForAccountCR();
      }
    } else if(this.from == PageName.TerritoryManagementDetailsComponent) {
      if(this.subFrom == ContactsModel.MY_CONTACTS_TAB) {
        this._initAccordionViewForContact();
      }
    }else if(this.from == PageName.SurveyPageComponent) {
      this._selectedDate = _.cloneDeep(this.viewData.selectedDate);
      this.isUpdatedDate = !_.isEmpty(this._selectedDate) && !_.isEmpty(this._selectedDate.startDate) && !_.isEmpty(this._selectedDate.endDate);
      this._initDateFormFields();  
      this.isDisplayDateField = this.viewData.isDisplayDateField;
      this._initAccordionViewForSurvey();
    }
    else if(this.from == PageName.CustomerCallPlanPage) {
      this._initAccordionViewForCallPlan(isClearFilter);
    } else if(this.from === PageName.MedicalInsightPageComponent){
      this._initAccordionViewForMedicalInsights(isClearFilter)
    } else if(this.from == PageName.PresentationPageComponent) {
      this._initAccordionViewForPresentation(isClearFilter);
    }
    else if(this.from == PageName.PresentationPageComponent) {
      this._initAccordionViewForPresentation(isClearFilter);
    }else if(this.from == PageName.ActivitiesPageComponent){
      this._initAccordionViewForActivitiesPage(isClearFilter);
    }else if(this.from == PageName.ResourcePageComponent){
      this._initAccordionViewForResource(isClearFilter);
    }
    this._initFilterMenuHeaderLeft();
    this._initFilterMenuSectionHeader();
    
    //sort filter menu items
    if(!_.isEmpty(this.categories)) {
      this._sortCategories();
    }
  }

  private _sortCategories() {
    const tempCategories = _.cloneDeep(this.categories);
    const sortedCategoriesWithFixedFilter = _.orderBy(tempCategories.filter(c=>c.isFixedFilter), ['filterOrder', 'primaryText'], ['asc','asc']);
    const sortedCategoriesWithoutFixedFilter = _.orderBy(tempCategories.filter(c=>!c.isFixedFilter), [category=>category.primaryText.toLowerCase()], ['asc']);
    this.categories = sortedCategoriesWithFixedFilter.concat(sortedCategoriesWithoutFixedFilter);
    //sort child items
    this.categories.map(c=>{
      if(!_.isEmpty(c.childItems)) {
        if(!c.isChildItemHasFixedFilterOrder) {
          c.childItems = _.orderBy(c.childItems, ['label'], ['asc']);
        }else {
          const tempChildItems = _.cloneDeep(c.childItems);
          const sortedChildItemsWithFixedFilter = _.orderBy(tempChildItems.filter(c=>c.isFixedFilter), ['filterOrder', 'label'], ['asc','asc']);
          const sortedChildItemsWithoutFixedFilter = _.orderBy(tempChildItems.filter(c=>!c.isFixedFilter), ['label'], ['asc']);
          c.childItems = sortedChildItemsWithFixedFilter.concat(sortedChildItemsWithoutFixedFilter);
        }
      }
    });
  }

  private _enableDisableFooterButtons() {
    if(!_.isEmpty(this.tempSelectedcategories) && !_.isEqual(this.savedSelectedCategories, this.tempSelectedcategories)) this.agendaFooterService.enableButtons(['saveFilter']);
    else this.agendaFooterService.disableButton(['saveFilter']);
  }

  ngOnDestroy() {
    this.uiService.isFilterActivated = false;
    this.isSaveFilterEnabled = false;
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private _getToolSearchName() {
    let searchToolNameID , toolSearchName;
    if(this.from == PageName.ContactPageComponent) {
      if(this.subFrom == ContactsModel.MY_CONTACTS_TAB) {
        toolSearchName = this.searchConfigService.toolNames.find(o=>o.toolName=='Contacts');
      }else if(this.subFrom == ContactsModel.CHANGE_REQUESTS_TAB) {

      }
    }else if(this.from == PageName.AccountPageComponent) {
      if(this.subFrom == AccountsModel.MY_ACCOUNTS_TAB) {
        toolSearchName = this.searchConfigService.toolNames.find(o=>o.toolName=='Accounts');
      }else if(this.subFrom == AccountsModel.CHANGE_REQUESTS_TAB) {

      }
    } else if(this.from == PageName.TerritoryManagementDetailsComponent) {
      if(this.subFrom == ContactsModel.MY_CONTACTS_TAB) {

      }
    }else if(this.from == PageName.SurveyPageComponent) {
      

    }
    else if(this.from == PageName.PresentationPageComponent) {
      toolSearchName = this.searchConfigService.toolNames.find(o=>o.toolName=='Presentations'); 
    }
    else if(this.from == PageName.ResourcePageComponent) {
      toolSearchName = this.searchConfigService.toolNames.find(o=>o.toolName=="Resources"); 
    } else if(this.from == PageName.ActivitiesPageComponent) {
      toolSearchName = this.searchConfigService.toolNames.find(o=>o.toolName=='Home'); 
    }
    searchToolNameID = toolSearchName?toolSearchName.searchToolNameID:'';
    this.searchConfigService.searchToolID = searchToolNameID;
    return searchToolNameID;  
  }
  
  _initAccordionViewForCallPlan(isClearFilter: boolean = false) {
    this.categories = [];
    const searchIndexConfig = this.searchConfigService.myCallPlansSearchIndexesConfig;
    searchIndexConfig.forEach((config) => {
      let accordionView: OmniAccordionViewDataModel;
      let existingCategoryIdx = -1;
      if(config.isThisToBeAggregated) {
        existingCategoryIdx = config.categoryDisplayName?this.categories.findIndex((cat) => cat.primaryText == config.categoryDisplayName):this.categories.findIndex((cat) => cat.primaryText == config.categoryName);
      }
      if(existingCategoryIdx<0) {
        let label;
        let selected = config.categoryDisplayName?this.tempSelectedcategories.filter((category) => category.categoryDisplayName == config.categoryDisplayName):this.tempSelectedcategories.filter((category) => category.categoryName == config.categoryName);
        if(!_.isEmpty(selected)) {
          label = config.categoryDisplayName?`${config.categoryDisplayName}(${selected.length})`:`${config.categoryName}(${selected.length})`;
        } else {
          label = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
        }
        accordionView = {
          displayType: 'Accordion',
          id: config.configuredFrom == 'Assess' ? config.categoryName : config.categoryRelativePath,
          primaryText: label,
          isExpanded: false,
          showExpandIcon: true,
          showCollapseIcon: true,
          isSearchable: true,
          searchActiveAt: 10,
          isSelectable: true,
          isAggregatedSection: config.isThisToBeAggregated,
          configuredFrom: config.configuredFrom,
          childItems: [],
          // clickHandler:(id?:string, event?:any, specificTarget?:string, dataRef?:any)=>void
        }
      } else {
        accordionView = this.categories[existingCategoryIdx];
      }
      if(!_.isEmpty(config.values)) {
        config.values.forEach((value) => {
          let categoryName = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
          let checked = this.tempSelectedcategories.some((filter) => filter.categoryName == categoryName && filter.selectedFacet == value);
          let idx =config.categoryDisplayName?accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryDisplayName+'_'+value):accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryName+'_'+value);
          if(idx<0) {
              accordionView.childItems.push({
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            })
          } else {
            accordionView.childItems[idx] = {
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            }
          }
          if(checked) {
            accordionView.isExpanded = true;
          }
        })
      } else {
        accordionView.showExpandIcon = false;
        accordionView.showCollapseIcon = false;
      }
      if (accordionView && !_.isEmpty(accordionView.childItems)) {
        if(existingCategoryIdx<0) this.categories.push(accordionView);
        else this.categories[existingCategoryIdx] = accordionView;
      }
    });
    const selected = this.tempSelectedcategories.filter((category) => category.categoryPath == 'status');
    const label = !_.isEmpty(selected) ? `${this.translate.instant("STATUS")}(${selected.length})` : this.translate.instant("STATUS");
    const isCompletedChecked = selected.some(filter => filter.selectedFacet == this.translate.instant("COMPLETED"));
    const isInprogressChecked = selected.some(filter => filter.selectedFacet == this.translate.instant("INPROGRESS"));
    let statusAccordion: OmniAccordionViewDataModel = {
      displayType: 'Accordion',
      id: 'status',
      primaryText: label,
      isExpanded: isCompletedChecked || isInprogressChecked,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: true,
      searchActiveAt: 10,
      isSelectable: true,
      childItems: [{
          id: "completed",
          label: this.translate.instant("COMPLETED"),
          value: 'completed',
          isChecked: isCompletedChecked,
          type: SuggestionPillType.CATEGORY_VALUESEARCH,
        },
        {
          id: "inprogress",
          label: this.translate.instant("INPROGRESS"),
          value: 'inprogress',
          isChecked: isInprogressChecked,
          type: SuggestionPillType.CATEGORY_VALUESEARCH,
        }
      ],
    }
    this.categories.push(statusAccordion);

    if(this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_CALL_PLAN)) {
      const selected = this.tempSelectedcategories.filter((category) => category.categoryPath == 'customerType');
      const label = !_.isEmpty(selected) ? `${this.translate.instant("CUSTOMER_TYPE")}(${selected.length})` : this.translate.instant("CUSTOMER_TYPE");
      const isAllChecked = selected.some(filter => filter.selectedFacet == this.translate.instant("ALL"));
      const isContactChecked = selected.some(filter => filter.selectedFacet == this.translate.instant("CONTACT_SINGULAR"));
      const isAccountChecked = selected.some(filter => filter.selectedFacet == this.translate.instant("ACCOUNT"));

      let customerTypeAccordion: OmniAccordionViewDataModel = {
        displayType: 'Accordion',
        id: 'customerType',
        primaryText: label,
        isExpanded: isAllChecked || isContactChecked || isAccountChecked,
        showExpandIcon: true,
        showCollapseIcon: true,
        isSearchable: true,
        searchActiveAt: 10,
        isSelectable: true,
        isFixedFilter: true,
        filterOrder: '1',
        isChildItemHasFixedFilterOrder: true,
        childItems: [
          {                        
            id: "all",
            label: this.translate.instant("ALL"),
            value: 'all',
            isChecked: isAllChecked,
            type: SuggestionPillType.CATEGORY_VALUESEARCH,
            isFixedFilter: true,
            filterOrder: '1',
          }, {
            id: "contact",
            label: this.translate.instant("CONTACT_SINGULAR"),
            value: 'contact',
            isChecked: isContactChecked,
            type: SuggestionPillType.CATEGORY_VALUESEARCH,
            isFixedFilter: true,
            filterOrder: '2',
          }, {
            id: "account",
            label: this.translate.instant("ACCOUNT"),
            value: 'account',
            isChecked: isAccountChecked,
            type: SuggestionPillType.CATEGORY_VALUESEARCH,
            isFixedFilter: true,
            filterOrder: '3',
          }
        ],
      }
      this.categories.push(customerTypeAccordion);
    }

    if(isClearFilter) {
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          cat.isExpanded = false;
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
        this.searchConfigDataService.filterMenuFilterClear.next(null);
      }
      if(!_.isEmpty(this.categories)) {
        this._sortCategories();
      }
    }

  }


  _initAccordionViewForMedicalInsights(isClearFilter: boolean = false) {
    this.categories = [];
    const searchIndexConfig = this.searchConfigService.medicalInsightsSearchIndexsConfig;
    searchIndexConfig.forEach((config) => {
      let accordionView: OmniAccordionViewDataModel;
      let existingCategoryIdx = -1;
      if(config.isThisToBeAggregated) {
        existingCategoryIdx = config.categoryDisplayName?this.categories.findIndex((cat) => cat.primaryText == config.categoryDisplayName):this.categories.findIndex((cat) => cat.primaryText == config.categoryName);
      }
      if(existingCategoryIdx<0) {
        let label;
        let selected = config.categoryDisplayName?this.tempSelectedcategories.filter((category) => category.categoryDisplayName == config.categoryDisplayName):this.tempSelectedcategories.filter((category) => category.categoryName == config.categoryName);
        if(!_.isEmpty(selected)) {
          label = config.categoryDisplayName?`${config.categoryDisplayName}(${selected.length})`:`${config.categoryName}(${selected.length})`;
        } else {
          label = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
        }
        accordionView = {
          displayType: 'Accordion',
          id: config.configuredFrom == 'Assess' ? config.categoryName : config.categoryRelativePath,
          primaryText: label,
          isExpanded: false,
          showExpandIcon: true,
          showCollapseIcon: true,
          isSearchable: true,
          searchActiveAt: 10,
          isSelectable: true,
          isAggregatedSection: config.isThisToBeAggregated,
          configuredFrom: config.configuredFrom,
          childItems: [],
          // clickHandler:(id?:string, event?:any, specificTarget?:string, dataRef?:any)=>void
        }
      } else {
        accordionView = this.categories[existingCategoryIdx];
      }
      if(!_.isEmpty(config.values)) {
        config.values.forEach((value) => {
          let categoryName = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
          let checked = this.tempSelectedcategories.some((filter) => filter.categoryName == categoryName && filter.selectedFacet == value);
          let idx =config.categoryDisplayName?accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryDisplayName+'_'+value):accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryName+'_'+value);
          if(idx<0) {
              accordionView.childItems.push({
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            })
          } else {
            accordionView.childItems[idx] = {
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            }
          }
          if(checked) {
            accordionView.isExpanded = true;
          }
        })
      } else {
        accordionView.showExpandIcon = false;
        accordionView.showCollapseIcon = false;
      }
      if (accordionView && !_.isEmpty(accordionView.childItems)) {
        if(existingCategoryIdx<0) this.categories.push(accordionView);
        else this.categories[existingCategoryIdx] = accordionView;
      }
    });
    // let selected = this.tempSelectedcategories.filter((category) => (category.categoryPath == 'completed' || category.categoryPath == 'inprogress'));
    // let label = !_.isEmpty(selected) ? `${this.translate.instant("STATUS")}(${selected.length})` : this.translate.instant("STATUS");
    // let isCompletedChecked = this.tempSelectedcategories.some((filter) => filter.categoryPath == 'completed' && filter.selectedFacet == this.translate.instant("COMPLETED"));
    // let isInprogressChecked = this.tempSelectedcategories.some((filter) => filter.categoryPath == 'inprogress' && filter.selectedFacet == this.translate.instant("INPROGRESS"));
    // let statusAccordion: OmniAccordionViewDataModel = {
    //   displayType: 'Accordion',
    //   id: 'status',
    //   primaryText: label,
    //   isExpanded: isCompletedChecked || isInprogressChecked,
    //   showExpandIcon: true,
    //   showCollapseIcon: true,
    //   isSearchable: true,
    //   searchActiveAt: 10,
    //   isSelectable: true,
    //   childItems: [{
    //       id: "completed",
    //       label: this.translate.instant("COMPLETED"),
    //       value: '',
    //       isChecked: isCompletedChecked,
    //       type: SuggestionPillType.CATEGORY_VALUESEARCH,
    //     },
    //     {
    //       id: "inprogress",
    //       label: this.translate.instant("INPROGRESS"),
    //       value: '',
    //       isChecked: isInprogressChecked,
    //       type: SuggestionPillType.CATEGORY_VALUESEARCH,
    //     }
    //   ],
    // }
    // this.categories.push(statusAccordion);

    // if(isClearFilter) {
    //   if(!this.searching && !_.isEmpty(this.categories)) {
    //     this.categories.forEach(cat => {
    //       cat?.childItems.map(c=>c.isChecked = false);
    //       cat.isExpanded = false;
    //     });
    //   }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
    //     this.searchedCategories.forEach(scat => {
    //       scat?.childItems.map(c=>c.isChecked = false);
    //       scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
    //     });
    //     this.searchConfigDataService.filterMenuFilterClear.next();
    //   }
    // }

  }

  _initAccordionViewForPresentation(isClearFilter: boolean = false) {
    this.categories = [];
    let value  = this.userTagService.PresentationTags.map(x=>x.indskr_name);
    this.searchConfigService.presentationsSearchIndexesConfig.find(x=>x.categoryName=="Tags" || x.categoryName == this.translate.instant("Tags")).values = value;
    let copyPresSearchConfig = JSON.parse(JSON.stringify(this.searchConfigService.presentationsSearchIndexesConfig.filter(pre =>pre.categoryName =='Products' || pre.categoryName =='Specialties' ||  pre.categoryName =='Category' || pre.categoryName =='Tags' )));
    let tagValues = copyPresSearchConfig.find(config=> config.categoryName == 'Tags' ).values;
    this.userTagService.PresentationTags.filter(j=>j.filter==false).forEach(x=>{
      let c = tagValues.findIndex(v=>v==x.indskr_name);
      if(c>=0){
        tagValues.splice(c,1);
      }
    })
    if (this.viewData && this.viewData.viewMode === PresentationView.ADDTOMEETING) {
      this.userTagService.resourceTag.forEach(tag => {
        let c = tagValues.findIndex(v => v == tag.indskr_name);
        if(tag.filter == false && c > -1){
          tagValues.splice(c,1);
        } else if (tag.filter == true) {
          tagValues.push(tag.indskr_name);
        }
      });
    }
    const searchIndexConfig = copyPresSearchConfig;
    searchIndexConfig.forEach((config) => {
      let accordionView: OmniAccordionViewDataModel;
      let existingCategoryIdx = -1;
      if(config.isThisToBeAggregated) {
        existingCategoryIdx = config.categoryDisplayName?this.categories.findIndex((cat) => cat.primaryText == config.categoryDisplayName):this.categories.findIndex((cat) => cat.primaryText == config.categoryName);
      }
      if(existingCategoryIdx<0) {
        let label;
        let selected = config.categoryDisplayName?this.tempSelectedcategories.filter((category) => category.categoryDisplayName == config.categoryDisplayName):this.tempSelectedcategories.filter((category) => category.categoryName == config.categoryName);
        if(!_.isEmpty(selected)) {
          label = config.categoryDisplayName?`${config.categoryDisplayName}(${selected.length})`:`${config.categoryName}(${selected.length})`;
        } else {
          label = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
        }
        accordionView = {
          displayType: 'Accordion',
          id:config.categoryRelativePath,
          primaryText: label,
          isExpanded: false,
          showExpandIcon: true,
          showCollapseIcon: true,
          isSearchable: true,
          searchActiveAt: 10,
          isSelectable: true,
          isAggregatedSection: config.isThisToBeAggregated,
          configuredFrom: config.configuredFrom,
          childItems: [],
          // clickHandler:(id?:string, event?:any, specificTarget?:string, dataRef?:any)=>void
        }
      } else {
        accordionView = this.categories[existingCategoryIdx];
      }
      if(!_.isEmpty(config.values)) {
        config.values.forEach((value) => {
          let categoryName = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
          let checked = this.tempSelectedcategories.some((filter) => filter.categoryName == categoryName && filter.selectedFacet == value);
          let idx =config.categoryDisplayName?accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryDisplayName+'_'+value):accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryName+'_'+value);
          if(idx<0) {
              accordionView.childItems.push({
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            })
          } else {
            accordionView.childItems[idx] = {
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            }
          }
          if(checked) {
            accordionView.isExpanded = true;
          }
        })
      } else {
        accordionView.showExpandIcon = false;
        accordionView.showCollapseIcon = false;
        accordionView.isForcedHide = true;
      }
      if (accordionView && !_.isEmpty(accordionView.childItems)) {
        if(existingCategoryIdx<0) this.categories.push(accordionView);
        else this.categories[existingCategoryIdx] = accordionView;
      }
    });

    if(isClearFilter) {
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          cat.isExpanded = false;
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
        this.searchConfigDataService.filterMenuFilterClear.next(null);
      }
      if(!_.isEmpty(this.categories)) {
        this._sortCategories();
      }
    }

  }
  _initAccordionViewForResource(isClearFilter: boolean = false) {
    this.categories = [];
    // for filter user tag is mapped
    let obj = {
      categoryDisplayName : this.translate.instant("Tag"),//"Tag",
      categoryName  : "Tag",
      categoryRelativePath  : "userTag.indskr_name",
      featureActionDependant  : "Tag",
      translationKey  : "Tag",
      values  : []
    }
    obj.values=this.userTagService.resourceTag.filter(y=>y.filter).map(x=>x.indskr_name);
    let copyAccountSearchConfig =[]// JSON.parse(JSON.stringify(this.searchConfigService.accountConfiguredSearchIndexConfig))  
    copyAccountSearchConfig.push(obj);
    const searchIndexConfig = copyAccountSearchConfig;
    searchIndexConfig.forEach((config) => {
      let accordionView: OmniAccordionViewDataModel;
      let existingCategoryIdx = -1;
      if(config.isThisToBeAggregated) {
        existingCategoryIdx = config.categoryDisplayName?this.categories.findIndex((cat) => cat.primaryText == config.categoryDisplayName):this.categories.findIndex((cat) => cat.primaryText == config.categoryName);
      }
      if(existingCategoryIdx<0) {
        let label;
        let selected = config.categoryDisplayName?this.tempSelectedcategories.filter((category) => category.categoryDisplayName == config.categoryDisplayName):this.tempSelectedcategories.filter((category) => category.categoryName == config.categoryName);
        if(!_.isEmpty(selected)) {
          label = config.categoryDisplayName?`${config.categoryDisplayName}(${selected.length})`:`${config.categoryName}(${selected.length})`;
        } else {
          label = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
        }
        accordionView = {
          displayType: 'Accordion',
          id:config.categoryRelativePath,
          primaryText: label,
          isExpanded: false,
          showExpandIcon: true,
          showCollapseIcon: true,
          isSearchable: true,
          searchActiveAt: 10,
          isSelectable: true,
          isAggregatedSection: config.isThisToBeAggregated,
          configuredFrom: config.configuredFrom,
          childItems: [],
          // clickHandler:(id?:string, event?:any, specificTarget?:string, dataRef?:any)=>void
        }
      } else {
        accordionView = this.categories[existingCategoryIdx];
      }
      if(!_.isEmpty(config.values)) {
        config.values.forEach((value) => {
          let categoryName = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
          let checked = this.tempSelectedcategories.some((filter) => filter.categoryName == categoryName && filter.selectedFacet == value);
          let idx =config.categoryDisplayName?accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryDisplayName+'_'+value):accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryName+'_'+value);
          if(idx<0) {
              accordionView.childItems.push({
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            })
          } else {
            accordionView.childItems[idx] = {
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            }
          }
          if(checked) {
            accordionView.isExpanded = true;
          }
        })
      } else {
        accordionView.showExpandIcon = false;
        accordionView.showCollapseIcon = false;
      }
      if (accordionView && !_.isEmpty(accordionView.childItems)) {
        if(existingCategoryIdx<0) this.categories.push(accordionView);
        else this.categories[existingCategoryIdx] = accordionView;
      }
    });

    if(isClearFilter) {
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          cat.isExpanded = false;
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
        this.searchConfigDataService.filterMenuFilterClear.next(null);
      }
    }

  }

  private _initFilterMenuHeaderLeft(): void {
    let buttons = [];
    buttons.push({
      id: "cancel",
      cssClass: 'seventyPercentWidth',
      imgSrc: 'assets/imgs/header_cancel.svg',
      isDisabled: false,
      align: "left",
    },
    );
    if (!this.readOnly) {
      buttons.push({
        id: "done",
        cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/header_complete.svg',
        isDisabled: !this.readOnly ? this.noChanges : true,
        align: "right",
      });
    }
    this.filterMenuHeaderLeftModel = {
      id: 'filter-menu-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('FILTERS'),
      mode: false,
      customHeaderProps: { hasCancel: true },
      controls: buttons,
      isReadOnly: this.readOnly
    };
  }

  private _isDisabledClearFilter(): boolean {
    let isDisabled: boolean = false;
    isDisabled = _.isEmpty(this.tempSelectedcategories) ? true : false;
    
    if(this.isDisplayDateField) {
      if(!_.isEmpty(this._selectedDate) && !_.isEmpty(this._selectedDate.startDate) && !_.isEmpty(this._selectedDate.endDate)) {
        isDisabled = false;
      }
    }
    return isDisabled;
  }

  private _initFilterMenuSectionHeader() {
    let buttons = [];
    buttons.push({
      id: "clear-filter",
      text: this.translate.instant('CLEAR_FILTER'),
      isDisabled: this._isDisabledClearFilter(),
      align: "right"
    });
    //check date field
    let selectedItemCount: number = this.tempSelectedcategories?.length || 0;
    if(this.isDisplayDateField && !_.isEmpty(this._selectedDate) && !_.isEmpty(this._selectedDate.startDate) && !_.isEmpty(this._selectedDate.endDate)) {
      selectedItemCount = selectedItemCount + 1;
    }
    this.filterMenuSectionHeader = {
      id: 'filter-menu-section-header',
      title: selectedItemCount > 0 ? `${this.translate.instant("ALL_FILTERS")} (${selectedItemCount})`: this.translate.instant("ALL_FILTERS"),
      controls: buttons
    }
    this.searchedFilterMenuSectionHeader = {
      id: 'searched-filter-menu-section-header',
      title: !_.isEmpty(this.searchedCategories)?`${this.translate.instant("AL_RESULTS")} (${this.searchedCategories.length})`: this.translate.instant("AL_RESULTS"),
      controls: buttons
    }
  }

  private _initDateFormFields() {
    let dateTimeValue = this.getFormattedDateTimeText();
    if (dateTimeValue) {
      this.startDateTimeValue = dateTimeValue.startDateTime != undefined ? dateTimeValue.startDateTime : undefined;
      this.startDateValue = dateTimeValue.startDate != undefined ? dateTimeValue.startDate : undefined;
      this.endDateTimeValue = dateTimeValue.endDateTime != undefined ? dateTimeValue.endDateTime : undefined;
      this.endDateValue = dateTimeValue.endDate != undefined ? dateTimeValue.endDate : undefined;
    }
    this.startDateField = {
      isDateField: true,
      label: this.translate.instant('DATE_FROM'),
      inputText: this.startDateValue != undefined ? this.startDateValue : '',
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.FilterMenuModal,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress,
      showArrow: true,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.endDateField = {
      isDateField: true,
      label: this.translate.instant('DATE_TO'),
      inputText: this.endDateValue != undefined ? this.endDateValue : '',
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.FilterMenuModal,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress,
      showArrow: true,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  private _initAccordionViewForContact(isClearFilter: boolean = false) {
    this.categories = [];
    let searchIndexConfig = this.from === PageName.TerritoryManagementDetailsComponent ? this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig : this.searchConfigService.contactConfiguredSearchIndexConfig;
    // for filter user tag is mapped
    let obj = {
      categoryDisplayName : this.translate.instant("Tag"),
      categoryName  : "Tag",
      categoryRelativePath  : "userTag.indskr_name",
      featureActionDependant  : "Tag",
      translationKey  : "Tag",
      values  : [],
      controlDataType:"PicklistType",
      entity : "",
      isBooleanTypeCategory : false,
      isMultilingualLookup: null,
      linkEntityFetchXML:"",
      mappingValues: [],
      numOfLinkEntity: "",
    }
    obj.values=this.contactService.contactTags.filter(y=>y.filter).map(x=>x.indskr_name);
    let copyAccountSearchConfig = JSON.parse(JSON.stringify(searchIndexConfig))  
    copyAccountSearchConfig.push(obj);
    
    //Remove custom search category(Customer). This is only used for searches.
    const customCategorySearchToolName = CustomCategorySearchToolName.CONTACTS;
    const customCategorySearchId = `customCategorySearch_${customCategorySearchToolName}`
    const targetIdx = copyAccountSearchConfig.findIndex(config => config.categoryRelativePath == customCategorySearchId);
    if (targetIdx > -1) {
      copyAccountSearchConfig.splice(targetIdx, 1);
    }

    searchIndexConfig = copyAccountSearchConfig;
    copyAccountSearchConfig.forEach((config) => {
      if(config.values?.length > 0) {
        let accordionView: OmniAccordionViewDataModel;
        let existingCategoryIdx = -1;
        if(config.isThisToBeAggregated) {
          existingCategoryIdx = config.categoryDisplayName?this.categories.findIndex((cat) => cat.primaryText == config.categoryDisplayName):this.categories.findIndex((cat) => cat.primaryText == config.categoryName);
        }
        if(existingCategoryIdx<0) {
          let label;
          let selected = config.categoryDisplayName?this.tempSelectedcategories.filter((category) => category.categoryDisplayName == config.categoryDisplayName):this.tempSelectedcategories.filter((category) => category.categoryName == config.categoryName);
          if(!_.isEmpty(selected)) {
            label = config.categoryDisplayName?`${config.categoryDisplayName}(${selected.length})`:`${config.categoryName}(${selected.length})`;
          } else {
            label = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
          }
          const isSubCategory: boolean = config.isSubCategory || false;
  
          accordionView = {
            displayType: 'Accordion',
            id: config.configuredFrom == 'Assess' ? config.categoryName : config.categoryRelativePath,
            primaryText: label,
            isExpanded: false,
            showExpandIcon: true,
            showCollapseIcon: true,
            isSearchable: false,
            searchActiveAt: 20,
            isSelectable: true,
            isAggregatedSection: config.isThisToBeAggregated,
            configuredFrom: config.configuredFrom,
            childItems: [],
            isFixedFilter: config.isFixedFilter || false,
            filterOrder: config.filterOrder || '',
            isForcedHide: config.isForcedHide,
            isSubCategory: isSubCategory,
            isSingleSelect: config.isSingleSelect
          }
        } else {
          accordionView = this.categories[existingCategoryIdx];
        }
        if(!_.isEmpty(config.values)) {
          const valuesToMapped = (config.values.length > 20) ? config.values.slice(0,20) : config.values;
          const valuesInEng = config.optionValuesInEng || [];
          valuesToMapped.forEach((value, index) => {
            let categoryName = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
            let checked = this.tempSelectedcategories.some((filter) => (filter.categoryName == categoryName || filter.categoryDisplayName == categoryName) && filter.selectedFacet == value);
            let idx =config.categoryDisplayName?accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryDisplayName+'_'+value):accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryName+'_'+value);
            if(idx<0) {
                accordionView.childItems.push({
                id: 'filter_menu_'+config.categoryName+'_'+value,
                label: value,
                labelInEng: !_.isEmpty(valuesInEng) ? valuesInEng[index] : '',
                value: '',
                isChecked: checked,
                type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
              })
            } else {
              accordionView.childItems[idx] = {
                id: 'filter_menu_'+config.categoryName+'_'+value,
                label: value,
                labelInEng: !_.isEmpty(valuesInEng) ? valuesInEng[index] : '',
                value: '',
                isChecked: checked,
                type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
              }
            }
            if(checked) {
              accordionView.isExpanded = true;
            }
          })
          if(config.values.length > 20){
            accordionView.showMoreButtonEnabled = true;
          }
        } else {
          accordionView.showExpandIcon = false;
          accordionView.showCollapseIcon = false;
        }
        if (accordionView && !_.isEmpty(accordionView.childItems)) {
          if(existingCategoryIdx<0) this.categories.push(accordionView);
          else this.categories[existingCategoryIdx] = accordionView;
        }
      } //end of if(config.values.length > 0)
      else if(config?.mappingValues?.length > 0) {
        let accordionView: OmniAccordionViewDataModel;
        let existingCategoryIdx = -1;
        if(config.isThisToBeAggregated) {
          existingCategoryIdx = config.categoryDisplayName?this.categories.findIndex((cat) => cat.primaryText == config.categoryDisplayName):this.categories.findIndex((cat) => cat.primaryText == config.categoryName);
        }
        if(existingCategoryIdx<0) {
          let label;
          let selected = config.categoryDisplayName?this.tempSelectedcategories.filter((category) => category.categoryDisplayName == config.categoryDisplayName):this.tempSelectedcategories.filter((category) => category.categoryName == config.categoryName);
          if(!_.isEmpty(selected)) {
            label = config.categoryDisplayName?`${config.categoryDisplayName}(${selected.length})`:`${config.categoryName}(${selected.length})`;
          } else {
            label = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
          }
          const isSubCategory: boolean = config.isSubCategory || false;
  
          accordionView = {
            displayType: 'Accordion',
            id: config.configuredFrom == 'Assess' ? config.categoryName : config.categoryRelativePath,
            primaryText: label,
            isExpanded: false,
            showExpandIcon: true,
            showCollapseIcon: true,
            isSearchable: true,
            searchActiveAt: 10,
            isSelectable: true,
            isAggregatedSection: config.isThisToBeAggregated,
            configuredFrom: config.configuredFrom,
            childItems: [],
            isFixedFilter: config.isFixedFilter || false,
            filterOrder: config.filterOrder || '',
            isForcedHide: config.isForcedHide,
            isSubCategory: isSubCategory,
            isSingleSelect: config.isSingleSelect
          }
        } else {
          accordionView = this.categories[existingCategoryIdx];
        }
        if(!_.isEmpty(config.mappingValues)) {
          const valuesToMapped = (config.mappingValues.length > 20) ? config.mappingValues.slice(0,20) : config.mappingValues;

          if(config.categoryName === 'Teamusers') {
            let isAccordianChecked = this.tempSelectedcategories.find((filter) => (filter.categoryName == 'Teamusers') || (filter.categoryName == 'Team Users'));
            
            if(isAccordianChecked) {
              accordionView.childItems = this.searchConfigDataService.childUsersWithPositions;
              accordionView.isExpanded = true;
            } else {
              // accordionView.isExpanded = false;
              this.searchConfigDataService.unCheckAllChildUsersWithPositions();

              accordionView.childItems = this.searchConfigDataService.childUsersWithPositions;
            }
          } else {
            valuesToMapped.forEach((value) => {
              let categoryName = config.categoryDisplayName;
              let isAccordianChecked = this.tempSelectedcategories.find((filter) => (filter.categoryName == categoryName));
              if(isAccordianChecked) {
                accordionView.childItems.push({
                  id: 'filter_menu_'+config.categoryName+'_'+value.formattedValue,
                  label: value.formattedValue,
                  value: value.actualValue,
                  isChecked: isAccordianChecked.selectedFacet === value.formattedValue,
                  type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH,
                  showArrow:true,
                  arrowType: 'chevron-down-outline',
                  category:config.categoryName
                 })
              } else {
                accordionView.childItems.push({
                  id: 'filter_menu_'+config.categoryName+'_'+value.formattedValue,
                  label: value.formattedValue,
                  value: value.actualValue,
                  isChecked: false,
                  type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH,
                  showArrow:true,
                  arrowType: 'chevron-down-outline',
                  category:config.categoryName
                 })
                }

                if(isAccordianChecked) {
                  accordionView.isExpanded = true;
                } 
              });
          }          
          if(config.mappingValues.length > 20){
            accordionView.showMoreButtonEnabled = true;
          }
        } else {
          accordionView.showExpandIcon = false;
          accordionView.showCollapseIcon = false;
        }
        if (accordionView && !_.isEmpty(accordionView.childItems)) {
          if(existingCategoryIdx<0) this.categories.push(accordionView);
          else this.categories[existingCategoryIdx] = accordionView;
        }
      }; //end of if(config.mappingValues.length > 0)
    });
    if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ASSESS)) {
      let selected = this.tempSelectedcategories.filter((category) => (category.categoryName == 'Assessed' || category.categoryPath == 'Assessed' || category.categoryName == 'UnAssessed' || category.categoryPath == 'UnAssessed' || category.categoryPath == this.translate.instant("Assessments")));
      let label = !_.isEmpty(selected) ? `${this.translate.instant("ASSESSMENTS")}(${selected.length})` : this.translate.instant("ASSESSMENTS");
      let isAssessedChecked = this.tempSelectedcategories.some((filter) => (filter.categoryPath == 'Assessed' || filter.categoryName == 'Assessed' || filter.categoryPath == this.translate.instant("Assessments")) && filter.selectedFacet == this.translate.instant("ASSESSED"));
      let isUnAssessedChecked = this.tempSelectedcategories.some((filter) => (filter.categoryPath == 'UnAssessed' || filter.categoryName == 'UnAssessed' || filter.categoryPath == this.translate.instant("Assessments")) && filter.selectedFacet == this.translate.instant("UNASSESSED"));
      let assessedAccordion: OmniAccordionViewDataModel = {
        displayType: 'Accordion',
        id: 'Assessments',
        primaryText: label,
        isExpanded: isAssessedChecked || isUnAssessedChecked,
        showExpandIcon: true,
        showCollapseIcon: true,
        isSearchable: true,
        searchActiveAt: 10,
        isSelectable: true,
        childItems: [{
            id: "assessed",
            label: this.translate.instant("ASSESSED"),
            value: '',
            isChecked: isAssessedChecked,
            type: SuggestionPillType.CATEGORY_VALUESEARCH,
          },
          {
            id: "unAssessed",
            label: this.translate.instant("UNASSESSED"),
            value: '',
            isChecked: isUnAssessedChecked,
            type: SuggestionPillType.CATEGORY_VALUESEARCH,
          }
        ],
        isFixedFilter: true,
        filterOrder: 'c',
      }
      this.categories.push(assessedAccordion);
    }
    //Clear filter--------------------------------------------------------------------------------------------------------
    if(isClearFilter) {
      this._selectedDate = { startDate: '', endDate: '' };
      this._initDateFormFields();
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          cat.isExpanded = false;
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
        this.searchConfigDataService.filterMenuFilterClear.next(null);
      }
      if(!_.isEmpty(this.categories)) {
        this._sortCategories();
      }
    }
  }

  private _initAccordionViewForContactCR(isClearFilter: boolean = false) {
    this.categories = [];
    let accordionViewStatus:OmniAccordionViewDataModel;
    let accordionViewRequestType:OmniAccordionViewDataModel;
    let accordionViewSourceType:OmniAccordionViewDataModel;
    let labelStatus, labelRequestType, labelSourceType;
    // add status filter
    let selectedStatus = this.tempSelectedcategories.filter((category) => category.categoryPath == 'contactCR_status');
    if(!_.isEmpty(selectedStatus)) {
      labelStatus = `${this.translate.instant('STATUS')}(${selectedStatus.length})`;
    } else {
      labelStatus = this.translate.instant('STATUS');
    }
    accordionViewStatus = {
      displayType: 'Accordion',
      id: 'contactCR_status',
      primaryText: labelStatus,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: []
    };
    this.viewData.data.find(d => d.text == this.translate.instant('STATUS')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryName == this.translate.instant('STATUS') && filter.selectedFacet == item.text);
      accordionViewStatus.childItems.push({
        id: 'filter_menu_status' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewStatus.isExpanded = true;
    });
    this.categories.push(accordionViewStatus);
    // add request type filter
    let selectedRequestType = this.tempSelectedcategories.filter((category) => category.categoryPath == 'contactCR_request_type');
    if(!_.isEmpty(selectedRequestType)) {
      labelRequestType = `${this.translate.instant('REQUEST_TYPE')}(${selectedRequestType.length})`;
    } else {
      labelRequestType = this.translate.instant('REQUEST_TYPE');
    }
    accordionViewRequestType = {
      displayType: 'Accordion',
      id: 'contactCR_request_type',
      primaryText: labelRequestType,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: []
    }
    this.viewData.data.find(d => d.text == this.translate.instant('REQUEST_TYPE')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryName == this.translate.instant('REQUEST_TYPE') && filter.selectedFacet == item.text);
      accordionViewRequestType.childItems.push({
        id: 'filter_menu_request_type' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewRequestType.isExpanded = true;
    });
    this.categories.push(accordionViewRequestType);
    // add source type filter
    let selectedSourceType = this.tempSelectedcategories.filter((category) => category.categoryPath == 'contactCR_source_type');
    if(!_.isEmpty(selectedSourceType)) {
      labelSourceType = `${this.translate.instant('SOURCE_TYPE')}(${selectedSourceType.length})`;
    } else {
      labelSourceType = this.translate.instant('SOURCE_TYPE');
    }
    accordionViewSourceType = {
      displayType: 'Accordion',
      id: 'contactCR_source_type',
      primaryText: labelSourceType,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: [],
    }
    let foundSourceTypeData = this.viewData.data.find(d => d.text == this.translate.instant('SOURCE_TYPE'));
    if(!_.isEmpty(foundSourceTypeData)) {
      foundSourceTypeData.items.forEach((item) => {
        let checked = this.selectedFilter.some((filter) => filter.categoryName == this.translate.instant('SOURCE_TYPE') && filter.selectedFacet == item.text);
        accordionViewSourceType.childItems.push({
          id: 'filter_menu_source_type' + '_' + item.value,
          label: item.text,
          value: item.value,
          isChecked: checked,
          type: 'general'
        })
        if(checked) accordionViewSourceType.isExpanded = true;
      });
      this.categories.push(accordionViewSourceType);
    }

    if(isClearFilter) {
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          cat.isExpanded = false;
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
        this.searchConfigDataService.filterMenuFilterClear.next(null);
      }
    }
  }

  private _initAccordionViewForAccount(isClearFilter: boolean = false) {
    this.categories = [];
    // for filter user tag is mapped
    // let obj = {
    //   categoryDisplayName : "Tag",
    //   categoryName  : "Tag",
    //   categoryRelativePath  : "userTag.indskr_name",
    //   featureActionDependant  : "Tag",
    //   translationKey  : "Tag",
    //   values  : []
    // }
    let values= this.userTagService.accountTags.filter(y=>y.filter).map(x=>x.indskr_name);
    this.searchConfigService.accountConfiguredSearchIndexConfig.find(x=>x.categoryName=="Tag" || x.categoryName == this.translate.instant("Tag")).values = values;
    let copyAccountSearchConfig = JSON.parse(JSON.stringify(this.searchConfigService.accountConfiguredSearchIndexConfig));

    copyAccountSearchConfig.forEach((config) => {
      let accordionView: OmniAccordionViewDataModel;
      let existingCategoryIdx = -1;
      if(config.isThisToBeAggregated) {
        existingCategoryIdx = config.categoryDisplayName?this.categories.findIndex((cat) => cat.primaryText == config.categoryDisplayName):this.categories.findIndex((cat) => cat.primaryText == config.categoryName);
      }
      if(existingCategoryIdx<0) {
        let label;
        let selected = config.categoryDisplayName?this.tempSelectedcategories.filter((category) => category.categoryDisplayName == config.categoryDisplayName):this.tempSelectedcategories.filter((category) => category.categoryName == config.categoryName);
        if(!_.isEmpty(selected)) {
          label = config.categoryDisplayName?`${config.categoryDisplayName}(${selected.length})`:`${config.categoryName}(${selected.length})`;
        } else {
          label = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
        }
        const isSubCategory: boolean = config.isSubCategory || false;

        accordionView = {
          displayType: 'Accordion',
          id: config.configuredFrom == 'Assess' ? config.categoryName : config.categoryRelativePath,
          primaryText: label,
          isExpanded: false,
          showExpandIcon: true,
          showCollapseIcon: true,
          isSearchable: true,
          searchActiveAt: 10,
          isSelectable: true,
          isAggregatedSection: config.isThisToBeAggregated,
          configuredFrom: config.configuredFrom,
          childItems: [],
          isFixedFilter: config.isFixedFilter || false,
          filterOrder: config.filterOrder || '',
          isForcedHide: config.isForcedHide,
          isSubCategory: isSubCategory,
          isSingleSelect: config.isSingleSelect
        }
      } else {
        accordionView = this.categories[existingCategoryIdx];
      }
      if(!_.isEmpty(config.values)) {
        const valuesInEng = config.optionValuesInEng || [];
        config.values.forEach((value, index) => {
          let categoryName = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
          let checked = this.tempSelectedcategories.some((filter) => filter.categoryName == categoryName && filter.selectedFacet == value);
          let idx = config.categoryDisplayName?accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryDisplayName+'_'+value):accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryName+'_'+value);
          if(idx<0) {
              accordionView.childItems.push({
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              labelInEng: !_.isEmpty(valuesInEng) ? valuesInEng[index] : '',
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            })
          } else {
            accordionView.childItems[idx] = {
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              labelInEng: !_.isEmpty(valuesInEng) ? valuesInEng[index] : '',
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            }
          }
          if(checked) {
            accordionView.isExpanded = true;
          }
        })
      } else if(!_.isEmpty(config.mappingValues)) {
        const valuesToMapped = (config.mappingValues.length > 20) ? config.mappingValues.slice(0,20) : config.mappingValues;

        if(config.categoryName === 'Teamusers') {
          let isAccordianChecked = this.tempSelectedcategories.find((filter) => (filter.categoryName == 'Teamusers') || (filter.categoryName == 'Team Users'));
          accordionView.childItems = this.searchConfigDataService.childUsersWithPositions;
          
          if(isAccordianChecked) {
            accordionView.isExpanded = true;
          } 
        } else {
          const valuesInEng = config.optionValuesInEng || [];
          valuesToMapped.forEach((value, index) => {
              let categoryName = config.categoryDisplayName;
              let isAccordianChecked = this.tempSelectedcategories.find((filter) => (filter.categoryName == categoryName));
              if(isAccordianChecked) {
                accordionView.childItems.push({
                  id: 'filter_menu_'+config.categoryName+'_'+value.formattedValue,
                  label: value.formattedValue,
                  labelInEng: !_.isEmpty(valuesInEng) ? valuesInEng[index] : '',
                  value: value.actualValue,
                  isChecked: isAccordianChecked.selectedFacet === value.formattedValue,
                  type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH,
                  showArrow:true,
                  arrowType: 'chevron-down-outline',
                  category:config.categoryName
                })
              } else {
                accordionView.childItems.push({
                  id: 'filter_menu_'+config.categoryName+'_'+value.formattedValue,
                  label: value.formattedValue,
                  labelInEng: !_.isEmpty(valuesInEng) ? valuesInEng[index] : '',
                  value: value.actualValue,
                  isChecked: false,
                  type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH,
                  showArrow:true,
                  arrowType: 'chevron-down-outline',
                  category:config.categoryName
                })
                }

                if(isAccordianChecked) {
                  accordionView.isExpanded = true;
                } 
              });
          }          
          if(config.mappingValues.length > 20){
            accordionView.showMoreButtonEnabled = true;
        }
      } //end of if(config.mappingValues.length > 0)
      else {
        accordionView.showExpandIcon = false;
        accordionView.showCollapseIcon = false;
      }
      if (accordionView && !_.isEmpty(accordionView.childItems)) {
        if(existingCategoryIdx<0) this.categories.push(accordionView);
        else this.categories[existingCategoryIdx] = accordionView;
      }
    });

    if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ASSESS)) {
      let selected = this.tempSelectedcategories.filter((category) => (category.categoryPath == 'Assessed' || category.categoryPath == 'UnAssessed' || category.categoryPath == this.translate.instant("Assessments")));
      let label = !_.isEmpty(selected) ? `${this.translate.instant("ASSESSMENTS")}(${selected.length})` : this.translate.instant("ASSESSMENTS");
      let isAssessedChecked = this.tempSelectedcategories.some((filter) => (filter.categoryPath == 'Assessed' || filter.categoryPath == this.translate.instant("Assessments")) && filter.selectedFacet == this.translate.instant("ASSESSED"));
      let isUnAssessedChecked = this.tempSelectedcategories.some((filter) => (filter.categoryPath == 'UnAssessed' || filter.categoryPath == this.translate.instant("Assessments")) && filter.selectedFacet == this.translate.instant("UNASSESSED"));
      let assessedAccordion: OmniAccordionViewDataModel = {
        displayType: 'Accordion',
        id: 'Assessments',
        primaryText: label,
        isExpanded: isAssessedChecked || isUnAssessedChecked,
        showExpandIcon: true,
        showCollapseIcon: true,
        isSearchable: true,
        searchActiveAt: 10,
        isSelectable: true,
        childItems: [{
            id: "assessed",
            label: this.translate.instant("ASSESSED"),
            value: '',
            isChecked: isAssessedChecked,
            type: SuggestionPillType.CATEGORY_VALUESEARCH,
          },
          {
            id: "unAssessed",
            label: this.translate.instant("UNASSESSED"),
            value: '',
            isChecked: isUnAssessedChecked,
            type: SuggestionPillType.CATEGORY_VALUESEARCH,
          }
        ],
        isFixedFilter: true,
        filterOrder: 'c',
      }
      this.categories.push(assessedAccordion);
    }
    if(isClearFilter && this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
      this.searchedCategories.forEach(scat => {
        scat?.childItems.map(c=>c.isChecked = false);
        scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
      });
      this.searchConfigDataService.filterMenuFilterClear.next(null);
      if(!_.isEmpty(this.categories)) {
        this._sortCategories();
      }
    }
  }

  private _initAccordionViewForAccountCR(isClearFilter: boolean = false) {
    this.categories = [];
    let accordionViewStatus:OmniAccordionViewDataModel;
    let accordionViewRequestType:OmniAccordionViewDataModel;
    let accordionViewSourceType:OmniAccordionViewDataModel;
    let labelStatus, labelRequestType, labelSourceType;
    // add status filter
    let selectedStatus = this.tempSelectedcategories.filter((category) => category.categoryPath == 'accountCR_status');
    if(!_.isEmpty(selectedStatus)) {
      labelStatus = `${this.translate.instant('STATUS')}(${selectedStatus.length})`;
    } else {
      labelStatus = this.translate.instant('STATUS');
    }
    accordionViewStatus = {
      displayType: 'Accordion',
      id: 'accountCR_status',
      primaryText: labelStatus,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: []
    };
    this.viewData.data.find(d => d.text == this.translate.instant('STATUS')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryName == this.translate.instant('STATUS') && filter.selectedFacet == item.text);
      accordionViewStatus.childItems.push({
        id: 'filter_menu_status' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewStatus.isExpanded = true;
    });
    this.categories.push(accordionViewStatus);
    // add request type filter
    let selectedRequestType = this.tempSelectedcategories.filter((category) => category.categoryPath == 'accountCR_request_type');
    if(!_.isEmpty(selectedRequestType)) {
      labelRequestType = `${this.translate.instant('REQUEST_TYPE')}(${selectedRequestType.length})`;
    } else {
      labelRequestType = this.translate.instant('REQUEST_TYPE');
    }
    accordionViewRequestType = {
      displayType: 'Accordion',
      id: 'accountCR_request_type',
      primaryText: labelRequestType,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: []
    }
    this.viewData.data.find(d => d.text == this.translate.instant('REQUEST_TYPE')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryName == this.translate.instant('REQUEST_TYPE') && filter.selectedFacet == item.text);
      accordionViewRequestType.childItems.push({
        id: 'filter_menu_request_type' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewRequestType.isExpanded = true;
    });
    this.categories.push(accordionViewRequestType);
    // add source type filter
    let selectedSourceType = this.tempSelectedcategories.filter((category) => category.categoryPath == 'contactCR_source_type');
    if(!_.isEmpty(selectedSourceType)) {
      labelSourceType = `${this.translate.instant('SOURCE_TYPE')}(${selectedSourceType.length})`;
    } else {
      labelSourceType = this.translate.instant('SOURCE_TYPE');
    }
    accordionViewSourceType = {
      displayType: 'Accordion',
      id: 'accountCR_source_type',
      primaryText: labelSourceType,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: [],
    }
    let foundSourceTypeData = this.viewData.data.find(d => d.text == this.translate.instant('SOURCE_TYPE'));
    if(!_.isEmpty(foundSourceTypeData)) {
      foundSourceTypeData.items.forEach((item) => {
        let checked = this.selectedFilter.some((filter) => filter.categoryName == this.translate.instant('SOURCE_TYPE') && filter.selectedFacet == item.text);
        accordionViewSourceType.childItems.push({
          id: 'filter_menu_source_type' + '_' + item.value,
          label: item.text,
          value: item.value,
          isChecked: checked,
          type: 'general'
        })
        if(checked) accordionViewSourceType.isExpanded = true;
      });
      this.categories.push(accordionViewSourceType);
    }

    if(isClearFilter) {
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          cat.isExpanded = false;
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
        this.searchConfigDataService.filterMenuFilterClear.next(null);
      }
    }
  }

  get noChanges(): boolean {
    let noChanges = _.isEqual(this.selectedCategories, this.tempSelectedcategories);
    if(this.isDisplayDateField || this._isMyActivitiesDateField) {
      if(!_.isEmpty(this._selectedDate) && !_.isEmpty(this._selectedDate.startDate) && !_.isEmpty(this._selectedDate.startDate)) {
        noChanges = false;
      }else {
        if(this.isUpdatedDate) noChanges = false;
      }
    } 
    return noChanges
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
        this.goBack(false);
        break;
      case 'done':
        this.goBack(true);
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public onSectionHeaderControlClick(id: string) {
    switch(id) {
      case 'clear-filter':
        this.tempSelectedcategories = [];
        if(this.from == PageName.ContactPageComponent) {
          if(this.subFrom == ContactsModel.MY_CONTACTS_TAB) {
            this._initAccordionViewForContact(true);
          }else if(this.subFrom == ContactsModel.CHANGE_REQUESTS_TAB) {
            this._initAccordionViewForContactCR(true);
          }
        }else if(this.from == PageName.AccountPageComponent) {
          if(this.subFrom == AccountsModel.MY_ACCOUNTS_TAB) {
            this._initAccordionViewForAccount(true);
          }else if(this.subFrom == AccountsModel.CHANGE_REQUESTS_TAB) {
            this._initAccordionViewForAccountCR(true);
          }
        } else if (this.from == PageName.TerritoryManagementDetailsComponent) {
          if (this.subFrom == ContactsModel.MY_CONTACTS_TAB) {
            this._initAccordionViewForContact(true);
          }
          this._initFilterMenuSectionHeader();
          this._initFilterMenuHeaderLeft();
        }else if(this.from == PageName.SurveyPageComponent) {
          this._initAccordionViewForSurvey(true);
        }
        else if(this.from == PageName.CustomerCallPlanPage) {
          this._initAccordionViewForCallPlan(true);
          this._initFilterMenuSectionHeader();
          this._initFilterMenuHeaderLeft();
        }
        else if(this.from == PageName.PresentationPageComponent) {
          this._initAccordionViewForPresentation(true);
          this._initFilterMenuSectionHeader();
          this._initFilterMenuHeaderLeft();
        } else if(this.from == PageName.MedicalInsightPageComponent){
          this._initAccordionViewForMedicalInsights(true);
          this._initFilterMenuSectionHeader();
          this._initFilterMenuHeaderLeft();
        } else if(this.from == PageName.ActivitiesPageComponent) {
          this._initAccordionViewForActivitiesPage(true);
          this._initFilterMenuSectionHeader();
          this._initFilterMenuHeaderLeft();
        } else if(this.from == PageName.ResourcePageComponent) {
          this._initAccordionViewForResource(true);
          this._initFilterMenuSectionHeader();
          this._initFilterMenuHeaderLeft();
        }
        if(this.isSaveFilterEnabled && this.from != PageName.CustomerCallPlanPage) {
          this._initSavedSearchDataModels(this._getToolSearchName(), false, true);
          this._initFilterMenuSectionHeader();
          this._initFilterMenuHeaderLeft();
          if(this.isSaveFilterEnabled) this.agendaFooterService.disableButton(['saveFilter']);
        }else if(this.from == PageName.SurveyPageComponent) {
          this._initFilterMenuSectionHeader();
          this._initFilterMenuHeaderLeft();
        }
       this.onCloseRightPane();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public async goBack(flag: boolean) {
    let data;
    if (flag) {
      this.selectedCategories = _.cloneDeep(this.tempSelectedcategories);
      if(this.from == PageName.ActivitiesPageComponent) {
        this.categories.find(category => {
          this.selectedCategories.forEach(cat => {
            if(cat.categoryPath == category.id) {
              let foundItem = category.childItems.find(item => item.label == cat.selectedFacet);
              if(foundItem) cat['value'] = foundItem.value;
            }
          })
        })
      }
      this.tempSelectedcategories = [];
      let currentSelectedDate = _.cloneDeep(this._selectedDate);
      this._selectedDate = { startDate: '', endDate: '' };

      if(!_.isEmpty(currentSelectedDate) && !_.isEmpty(currentSelectedDate.startDate) && !_.isEmpty(currentSelectedDate.endDate)) {
        data = { selectedItems: this.selectedCategories, isDone: true, selectedDate: currentSelectedDate };  
      }else {
        data = { selectedItems: this.selectedCategories, isDone: true };
      }
      this.modalCtrl.dismiss(data).then(() => this.navService.isModalViewOpen = false);
    }
    else {
    //   this.revertChanges();
      this.tempSelectedcategories = [];
      data = { selectedItems: this.selectedCategories, isDone: false };
      this.modalCtrl.dismiss(data).then(() => this.navService.isModalViewOpen = false);
    }
  }

  public searchText(ev): void {
    if(_.isEmpty(this.categories)) return;
    this.searchedCategories = [];
    let isFoundCategory: boolean = false;
    let isFoundCategoryValue: boolean = false;
    const val: string = (ev && ev.target && ev.target.value) ? ev.target.value : '';
    if (val.length >= MIN_SEARCH_LENGTH) {
      this.searching = true;
      let tempCategories = _.cloneDeep(this.categories);
      tempCategories = tempCategories.filter(t => !t.isForcedHide);
      tempCategories.filter(category => {
        // search in category
        if(category.primaryText.trim().toLowerCase().includes(val.trim().toLowerCase())) {
          isFoundCategory = true;
          if(!_.isEmpty(this.searchedCategories)) {
            const idx = this.searchedCategories.findIndex(i=>i.id == category.id);
            if(idx < 0) {
              this.searchedCategories.push(category);
            }
          }else {
            this.searchedCategories.push(category);
          }
        }
        // search in category values
        else {
          let tempCat = _.cloneDeep(category);
          let searchedCategoryValues = [];
          searchedCategoryValues = tempCat.childItems.filter((item) => item.label?.trim().toLowerCase().includes(val.trim().toLowerCase()));
          if(!_.isEmpty(searchedCategoryValues)) {
            isFoundCategoryValue = true;
            tempCat.childItems = searchedCategoryValues;
            tempCat.isExpanded = true;
            if(!_.isEmpty(this.searchedCategories)) {
              const idx = this.searchedCategories.findIndex(c=>c.id == category.id);
              if(idx > -1) {
                this.searchedCategories[idx] = tempCat;
              }else {
                this.searchedCategories.push(tempCat);
              }
            }else {
              this.searchedCategories.push(tempCat);
            }
          }
        }
      })
      if(!isFoundCategory && !isFoundCategoryValue) {
        this.searchedCategories = [];
      }
    } else {
      this.searching = false;
      this.searchedCategories = [];
    }
    this._initFilterMenuSectionHeader();
  }

  public selectSavedCategories(filters, selected) {
    // reset
    if(selected && !selected.isChecked && !filters.childItems.some(item => item.isChecked)) {
      this.savedSelectedCategories = [];
      this.tempSelectedcategories = _.cloneDeep(this.savedSelectedCategories);
      this._init(true);
    } else {
      if(!_.isEmpty(selected && selected.value) && selected.isChecked) {
        const savedFilterName: string = filters.childItems.find(i=>i.isChecked)?.label || '';
        this.savedSelectedCategories = selected.value;
        this.savedSelectedCategories.map((s: SelectedSuggestionPillDataModel)=> {
          s.isSavedFilter = true;
          s.savedFilterName = savedFilterName;
        });
        this.tempSelectedcategories = _.cloneDeep(this.savedSelectedCategories);
        if(this.from == PageName.ContactDetailsComponent) {
          this.tempSelectedcategories.forEach(category => {
            let selectedCategory = this.categories.find(cat => cat.id == category.categoryName);
            let configuredFrom = selectedCategory?.configuredFrom;
            let selectedChildItem = selectedCategory?.childItems?.find(item => item.label == category.selectedFacet);
            if(configuredFrom) category['configuredFrom'] = configuredFrom;
            if(selectedChildItem && !category['selectedFacetInEng']) category['selectedFacetInEng'] = selectedChildItem.labelInEng;
          })
        }
        this._init(false);
      // this.savedFilters.childItems.forEach(item => {
      //   if(item.id == selected.id) item.isChecked = selected.isChecked;
      //   else item.isChecked = false;
      // })
      }
    }
    this.agendaFooterService.disableButton(['saveFilter']);
  }

  public toggleCategoryValue(item, selected) {
    //on checking a position, check all the child users for that position
    if(item.id == 'Positions' && 
        (this.authService.user.childUsers?.length>0 || 
          (this.authService.user.buSettings["indskr_homepageteamview"] && 
            (this.authService.user.siblingUsersCommonParent?.length>0 || 
              this.authService.user.siblingUsersCommonPositions?.length>0)))) {
      this.toggleCategoryValueOnPositionSelect(item, selected);
      return;
    }
    let now = new Date()
    if(!_.isEmpty(this.tempSelectedcategories)) {
      let index = this.tempSelectedcategories.findIndex((cat) => cat.categoryPath == item?.id || cat.categoryName == item?.id || ((cat.categoryName == 'Assessed' || cat.categoryPath == 'Assessed'|| cat.categoryName == 'UnAssessed' || cat.categoryPath == 'UnAssessed')&&item?.id=='Assessments'));
      if(index>-1) {
        let lastIndex = item.primaryText.lastIndexOf('(');
        if(lastIndex>-1) item.primaryText = item.primaryText.slice(0,lastIndex);
      }
    } else if(selected.isChecked) {
      let lastIndex = item.primaryText.lastIndexOf('(');
      if(lastIndex>-1) item.primaryText = item.primaryText.slice(0,lastIndex);
    }
    // item.primaryText = item.primaryText.split('(')[0];
    let selectionMade: SelectedSuggestionPillDataModel = {
      selectedFacet: selected.label,
      selectedFacetInEng: selected.labelInEng || '',
      charSearchText: selected.type == SuggestionPillType.BOOLEAN_FIELD ? selected.label : '',
      categoryPath: item?item.id :'',
      categoryName: item?item.primaryText : '',
      categoryDisplayName : item?item.primaryText : '',
      type: selected.type,
      value: selected.value,
      createdOn: now.getTime(),
      isAggregatedSection: item?.isAggregatedSection,
      configuredFrom: item?.configuredFrom,
      subCategories: [],
      isSubCategory: item ? item.isSubCategory || false : false,
    }

    if(selected.category !== "Teamusers") {
      if(!_.isEmpty(this.tempSelectedcategories)) {
        let idx = this.tempSelectedcategories.findIndex((cat) => cat.selectedFacet == selected.label && (cat.categoryPath == item?.id || cat.categoryName == item?.id || ((cat.categoryName == 'Assessed' || cat.categoryPath == 'Assessed' || cat.categoryName == 'UnAssessed' || cat.categoryPath == 'UnAssessed') && item?.id == 'Assessments')));
        if(idx>-1) {
          if(!selected.isChecked) this.tempSelectedcategories.splice(idx,1);
        } else {
          if(selected.isChecked) this.tempSelectedcategories.push(selectionMade);
        }
      } else {
        if(selected.isChecked) this.tempSelectedcategories.push(selectionMade);
      }
    }
    else {
      if(!_.isEmpty(this.tempSelectedcategories)) {
        let idx = this.tempSelectedcategories.findIndex((cat) => cat.selectedFacet == selected.label && (cat.categoryPath == item?.id || cat.categoryName == item?.id || ((cat.categoryName == 'Assessed' || cat.categoryPath == 'Assessed' || cat.categoryName == 'UnAssessed' || cat.categoryPath == 'UnAssessed') && item?.id == 'Assessments')));
        if(idx>-1) {
          if(!selected.isChecked) this.tempSelectedcategories.splice(idx,1);
        } else {
          if(selected.isChecked) {
            this.tempSelectedcategories = this.tempSelectedcategories.filter((item) => !(item.categoryPath === 'hasTeamusers'));

            this.tempSelectedcategories.push(selectionMade);
          }
        }
      } else {
        this.tempSelectedcategories.push(selectionMade);
      }
    }
    
    let index = this.categories.findIndex((cat)=> cat.id == item?.id);
    let numOfFilter = this.tempSelectedcategories.filter((cat) => cat.categoryPath == item?.id || cat.categoryName == item?.id || ((cat.categoryName == 'Assessed' || cat.categoryPath == 'Assessed'||cat.categoryName == 'UnAssessed' || cat.categoryPath == 'UnAssessed')&&item?.id=='Assessments'))?.length;
    //correct numOfFilter if current user is added twice
    if(this.tempSelectedcategories.filter(cat=> (cat.categoryPath == item?.id || cat.categoryName == item?.id) && cat.value == this.authService.user.systemUserID).length>1)
      numOfFilter = numOfFilter-1;
    if(index>-1) {
      if (!_.isEmpty(this.categories[index].childItems)) {
        this.categories[index].childItems.map(c => {
          if (c.id == selected.id) {
            c.isChecked = selected.isChecked;
          }
        });
      }
      if(numOfFilter>0) {
        this.categories[index].category = item.primaryText;
        this.categories[index].primaryText = `${item.primaryText}(${numOfFilter})`;
        this.categories[index].isExpanded = true;
      }
      else this.categories[index].primaryText = this.categories[index].category ? this.categories[index].category : item.primaryText;
    }
    if(this.searching) {
      let idx = this.searchedCategories.findIndex((cat) => cat.id == item?.id);
      if(idx>-1) {
        if (!_.isEmpty(this.categories[index].childItems)) {
          this.searchedCategories[index].childItems.map(c => {
            if (c.id == selected.id) {
              c.isChecked = selected.isChecked;
            }
          });
        }
        if(numOfFilter>0) {
          this.searchedCategories[index].category = item.primaryText;
          this.searchedCategories[idx].primaryText = `${item.primaryText}(${numOfFilter})`;
          this.searchedCategories[idx].isExpanded = true;
        }
        else this.searchedCategories[idx].primaryText = this.searchedCategories[index].category ? this.searchedCategories[index].category : item.primaryText;
      }
    }
    //Check sub criteria items to display
    if(this.from == PageName.ContactPageComponent) {
      const myActivitiesAndSubCategoriesList = ['myActivitiesByContact', 'filterMeetingsStatus', 'filterMessagesStatus', 'filterMessagesAttachment', 'filterMessagesLinkAccessed'];
      if(myActivitiesAndSubCategoriesList.includes(item.id)) {
        this._updateSubCategories(item);
      }

      if (item.id == 'Assessments') {
        this._updateSubCategoriesForAssess(item);
      }

    } else if (this.from == PageName.AccountPageComponent) {
      if (item.id == 'Assessments') {
        this._updateSubCategoriesForAssess(item);
      }
    }else if(this.from == PageName.SurveyPageComponent) {
      const surveySubCategoriesList = ['survey_type'];
      if(surveySubCategoriesList.includes(item.id)) {
        if(item.id == 'survey_type' && selected.value == 600000000) {
          this._updateSubCategories(item);
        }
      }
    }else if(this.from == PageName.ActivitiesPageComponent) {
      //   if(item.id == 'Activity_Type') {
      //     if(selected.value == 'All Activities'){
      //       item.childItems.forEach(childItem => {
      //         childItem.isChecked = selected.isChecked
      //       });
      //     }else if(!selected.isChecked){
      //       item.childItems[0].isChecked = false;
      //     }
      //   }
    }
    
    if(!_.isEmpty(this.savedSelectedCategories)) {
      if(!_.isEqual(this.savedSelectedCategories, this.tempSelectedcategories)) {
        this._initSavedSearchDataModels(this._getToolSearchName(), false, true);
      }
    }
    this._initFilterMenuSectionHeader();
    this._initFilterMenuHeaderLeft();
    this._enableDisableFooterButtons();
  }

  public toggleCategoryValueOnPositionSelect(item, selected) {
    let now = new Date();
    const index_users = this.categories.findIndex(cat => cat.id === 'Users');

    //if a position is unchecked , remove that position and all the users 
    if(!selected.isChecked && !_.isEmpty(this.tempSelectedcategories)){
      for (let i = this.tempSelectedcategories.length - 1; i >= 0; i--) {
        const cat = this.tempSelectedcategories[i];
        if (cat.selectedFacet === selected.label && cat.categoryPath === item?.id) {
            this.tempSelectedcategories.splice(i, 1);
        } else if (cat.categoryPath === 'Users') {
            this.tempSelectedcategories.splice(i, 1);
        }
      }
      let lastIndexuser = this.categories[index_users].primaryText.lastIndexOf('(');
      if(lastIndexuser>-1) this.categories[index_users].primaryText = this.categories[index_users].primaryText.slice(0,lastIndexuser);
    }

    //slice the numbers from primary text of position and users
    if(!_.isEmpty(this.tempSelectedcategories)) {
      let index = this.tempSelectedcategories.findIndex((cat) => cat.categoryPath == item?.id);
      let ind_user = this.tempSelectedcategories.findIndex((cat)=> cat.categoryPath == 'Users' )
      if(index>-1) {
        let lastIndex = item.primaryText.lastIndexOf('(');
        if(lastIndex>-1) item.primaryText = item.primaryText.slice(0,lastIndex);
      }
      if(ind_user>-1) {
        let lastIndex = this.categories[index_users].primaryText.lastIndexOf('(');
        if(lastIndex>-1) this.categories[index_users].primaryText = this.categories[index_users].primaryText.slice(0,lastIndex);
      }
    } else if(selected.isChecked) {
      let lastIndex = item.primaryText.lastIndexOf('(');
      if(lastIndex>-1) item.primaryText = item.primaryText.slice(0,lastIndex);
      let lastIndexuser = this.categories[index_users].primaryText.lastIndexOf('(');
      if(lastIndexuser>-1) this.categories[index_users].primaryText = this.categories[index_users].primaryText.slice(0,lastIndexuser);
    }

    //create new users list for categories and tempSelectedCategories
    if(index_users>-1){
      //create new childitems array
      //if position is checked
        //add child users
        //if there is existing position in tempSelectedcategories, add child users for all the positions
        //set categories[index_users].childitems = new_child_items_array
      //if position is unchecked
        //if there is existing position in tempSelectedcategories, add child users for all the positions
        //if there is no existing position show all users
        //set categories[index_users].childitems = new_child_items_array
      let newChildItemsArray = [];
      //check sibling users flag and iterate on both if true
      let childUsersArray = [];
      if(this.authService.user.buSettings["indskr_homepageteamview"]===548910001){
        let tempchildUsersArray = [...this.authService.user.childUsers,...this.authService.user.siblingUsersCommonParent,...this.authService.user.siblingUsersCommonPositions];
        tempchildUsersArray.forEach(element => {
          let existingUser = childUsersArray.find(x => x.userId == element.userId);
          if(!existingUser){
            childUsersArray.push(element);
          }
          else{
            //push the position in the existing user
            let uniquePositionIDs = new Set([...existingUser.positionIDs, ...element.positionIDs]);
            existingUser.positionIDs = [...uniquePositionIDs];
          }
        });
      }
      else 
        childUsersArray = this.authService.user.childUsers;
      childUsersArray.forEach(childuser=> {
        if(selected.isChecked){
          if(childuser.positionIDs.some(pos => pos==selected.value) || this.tempSelectedcategories.some((filter) => filter.value == childuser.userId)){
            let childItemUser = {
              id: childuser.userId,
              label: childuser.fullName,
              value: childuser.userId,
              isChecked: selected.isChecked || this.tempSelectedcategories.some((filter) => filter.value == childuser.userId),
              type: SuggestionPillType.CATEGORY_VALUESEARCH
            };
            newChildItemsArray.push(childItemUser);
            let selectionUser: SelectedSuggestionPillDataModel = {
              selectedFacet: childItemUser.label,
              selectedFacetInEng: '',
              charSearchText: childItemUser.type == SuggestionPillType.BOOLEAN_FIELD ? childItemUser.label : '',
              categoryPath: 'Users',
              categoryName: this.translate.instant('USERS'),
              categoryDisplayName : this.translate.instant('USERS'),
              type: SuggestionPillType.CATEGORY_VALUESEARCH,
              value: childItemUser.value,
              createdOn: now.getTime(),
              isAggregatedSection: this.categories[index_users]?.isAggregatedSection,
              configuredFrom: this.categories[index_users]?.configuredFrom,
              subCategories: [],
              isSubCategory: this.categories[index_users] ? this.categories[index_users].isSubCategory || false : false,
            }

            if(!_.isEmpty(this.tempSelectedcategories)) {
              let idx = this.tempSelectedcategories.findIndex((cat) => cat.selectedFacet == childItemUser.label && cat.categoryPath == 'Users');
              if(idx>-1) {
                if(!selected.isChecked) this.tempSelectedcategories.splice(idx,1);
              } else {
                if(selected.isChecked) this.tempSelectedcategories.push(selectionUser);
              }
            } else {
              if(selected.isChecked) this.tempSelectedcategories.push(selectionUser);
            }
          }
        }
        else{
          let childItemUser : any;
          if(!_.isEmpty(this.tempSelectedcategories) && this.tempSelectedcategories.some(cat => cat.categoryPath=='Positions')){
            //extract all the position ids to see if any matches the current child user
            this.tempSelectedcategories.filter(cat => cat.categoryPath=='Positions').forEach((cat) => {
              if(childuser.positionIDs.some(pos => pos==cat.value)){
                childItemUser = {
                  id: childuser.userId,
                  label: childuser.fullName,
                  value: childuser.userId,
                  isChecked: true,
                  type: SuggestionPillType.CATEGORY_VALUESEARCH
                };
              }
            });
          }
          else{
            childItemUser = {
              id: childuser.userId,
              label: childuser.fullName,
              value: childuser.userId,
              isChecked: false,
              type: SuggestionPillType.CATEGORY_VALUESEARCH
            };
          }
          if(!_.isEmpty(childItemUser)){
            newChildItemsArray.push(childItemUser);
            let selectionUser: SelectedSuggestionPillDataModel = {
              selectedFacet: childItemUser.label,
              selectedFacetInEng: '',
              charSearchText: childItemUser.type == SuggestionPillType.BOOLEAN_FIELD ? childItemUser.label : '',
              categoryPath: 'Users',
              categoryName: this.translate.instant('USERS'),
              categoryDisplayName : this.translate.instant('USERS'),
              type: SuggestionPillType.CATEGORY_VALUESEARCH,
              value: childItemUser.value,
              createdOn: now.getTime(),
              isAggregatedSection: this.categories[index_users]?.isAggregatedSection,
              configuredFrom: this.categories[index_users]?.configuredFrom,
              subCategories: [],
              isSubCategory: this.categories[index_users] ? this.categories[index_users].isSubCategory || false : false,
            }
            if(selected.category !== "Teamusers") {
              //we want to push users in tempSelectedcategories only in case of existing positions
              //users wont be pushed in tempSelectedcategories when a position is unchecked and there are no exisiting positions
              if(!_.isEmpty(this.tempSelectedcategories) && this.tempSelectedcategories.some(cat => cat.categoryPath=='Positions')) {
                let idx = this.tempSelectedcategories.findIndex((cat) => cat.selectedFacet == childItemUser.label && cat.categoryPath == 'Users');
                if(idx==-1) {
                  this.tempSelectedcategories.push(selectionUser);
                } 
              } else {
                if(selected.isChecked) this.tempSelectedcategories.push(selectionUser);
              }
            }
          }
        }
      });
      this.categories[index_users].childItems = newChildItemsArray;
      let isCurrentUserExisting = this.categories[index_users].childItems.find(x => x.id == this.authService.user.systemUserID);
      if(!isCurrentUserExisting){
        this.categories[index_users].childItems.push({
          id: this.authService.user.systemUserID,
          label: this.authService.user.displayName + ' ('+this.translate.instant('ME')+')',
          value: this.authService.user.systemUserID,
          isChecked: false,
          type: SuggestionPillType.CATEGORY_VALUESEARCH
        });
      }
      else{
        isCurrentUserExisting.label = this.authService.user.displayName + ' ('+this.translate.instant('ME')+')';
        //update tempSelectedcategories as well
        this.tempSelectedcategories.find(cat => cat.categoryPath=='Users' && cat.value== this.authService.user.systemUserID).selectedFacet = this.authService.user.displayName + ' ('+this.translate.instant('ME')+')';
      }
      this.categories[index_users].childItems = _.orderBy(this.categories[index_users].childItems, ['label'], ['asc']);
    } 
    
    //if a position is checked , push in tempSelectedCategories
    if(selected.isChecked){
      let selectionMadePosition: SelectedSuggestionPillDataModel = {
        selectedFacet: selected.label,
        selectedFacetInEng: selected.labelInEng || '',
        charSearchText: selected.type == SuggestionPillType.BOOLEAN_FIELD ? selected.label : '',
        categoryPath: item?item.id :'',
        categoryName: item?item.primaryText : '',
        categoryDisplayName : item?item.primaryText : '',
        type: selected.type,
        value: selected.value,
        createdOn: now.getTime(),
        isAggregatedSection: item?.isAggregatedSection,
        configuredFrom: item?.configuredFrom,
        subCategories: [],
        isSubCategory: item ? item.isSubCategory || false : false,
      }
      if(!_.isEmpty(this.tempSelectedcategories)) {
        let idx = this.tempSelectedcategories.findIndex((cat) => cat.selectedFacet == selected.label && cat.categoryPath == item?.id);
        if(idx==-1) 
          if(selected.isChecked) this.tempSelectedcategories.push(selectionMadePosition);
      } 
      else {
        this.tempSelectedcategories.push(selectionMadePosition);
      }
    }
    
    //add final number of filters to primary text of position and users
    let index = this.categories.findIndex((cat)=> cat.id == item?.id);
    let numOfFilter = this.tempSelectedcategories.filter((cat) => cat.categoryPath == item?.id)?.length;
    let numOfFilterUsers = this.tempSelectedcategories.filter((cat) => cat.categoryPath == 'Users').length;
    if(index>-1) {
      if (!_.isEmpty(this.categories[index].childItems)) {
        this.categories[index].childItems.map(c => {
          if (c.id == selected.id) {
            c.isChecked = selected.isChecked;
          }
        });
      }
      if(numOfFilter>0) {
        this.categories[index].category = item.primaryText;
        this.categories[index].primaryText = `${item.primaryText}(${numOfFilter})`;
        this.categories[index].isExpanded = true;
      }
      else this.categories[index].primaryText = this.categories[index].category ? this.categories[index].category : item.primaryText;
    }
    if(index_users>-1){
      if(numOfFilterUsers>0){
        this.categories[index_users].category = this.translate.instant('USERS');
        this.categories[index_users].primaryText = `${this.categories[index_users].primaryText}(${numOfFilterUsers})`;
        this.categories[index_users].isExpanded = true;
      }
      else
        this.categories[index_users].primaryText = this.categories[index_users].category ? this.categories[index_users].category : this.categories[index_users].primaryText;
    }
    // if(this.searching) {
    //   let idx = this.searchedCategories.findIndex((cat) => cat.id == item?.id);
    //   if(idx>-1) {
    //     if (!_.isEmpty(this.categories[index].childItems)) {
    //       this.searchedCategories[index].childItems.map(c => {
    //         if (c.id == selected.id) {
    //           c.isChecked = selected.isChecked;
    //         }
    //       });
    //     }
    //     if(numOfFilter>0) {
    //       this.searchedCategories[index].category = item.primaryText;
    //       this.searchedCategories[idx].primaryText = `${item.primaryText}(${numOfFilter})`;
    //       this.searchedCategories[idx].isExpanded = true;
    //     }
    //     else this.searchedCategories[idx].primaryText = this.searchedCategories[index].category ? this.searchedCategories[index].category : item.primaryText;
    //   }
    // }
    
    
    // if(!_.isEmpty(this.savedSelectedCategories)) {
    //   if(!_.isEqual(this.savedSelectedCategories, this.tempSelectedcategories)) {
    //     this._initSavedSearchDataModels(this._getToolSearchName(), false, true);
    //   }
    // }
    this._initFilterMenuSectionHeader();
    this._initFilterMenuHeaderLeft();
    this._enableDisableFooterButtons();
  }

  public toggleOptionClick(item,selected) {
    console.log(item);
    if (this.from == PageName.ActivitiesPageComponent) {
      if (item.id == 'Activity_Type') {
        if (selected.value == 'All Activities') {
          item.childItems.forEach(childItem => {
            childItem.isChecked = selected.isChecked
          });
        } else if (!selected.isChecked) {
          item.childItems[0].isChecked = false;
        }
      }
    }else if(this.from == PageName.CustomerCallPlanPage) {
      //single selection mode
      if(item.id == 'customerType' || item.id == 'status') {
        item.childItems.forEach(childItem => {
          if(childItem.id == selected.id) {
            childItem.isChecked = selected.isChecked;
          }else if(selected.isChecked) {
            childItem.isChecked = false;
          }
        });
      }
    }
    if(!_.isEmpty(this.savedSelectedCategories)) {
      if(!_.isEqual(this.savedSelectedCategories, this.tempSelectedcategories)) {
        this._initSavedSearchDataModels(this._getToolSearchName(), false, true);
      }
    }
    this._initFilterMenuSectionHeader();
    this._initFilterMenuHeaderLeft();
    this._enableDisableFooterButtons();
    this._cd.detectChanges();
    this._cd.markForCheck();
  }

  private _initAccordionViewForSurvey(isClearFilter: boolean = false) {
    this.categories = [];
    let accordionViewType:OmniAccordionViewDataModel;
    let accordionViewUseType:OmniAccordionViewDataModel;
    let accordionViewStatus:OmniAccordionViewDataModel;
    let labelSurveyType: string = '';
    let labelSurveyUseType: string = '';
    let labelSurveyStatus: string = '';
    //survey type--------------------------------------------------------------------------------------------------------
    const selectedSurveyType = this.tempSelectedcategories.filter((category) => category.categoryPath == 'survey_type');
    if(!_.isEmpty(selectedSurveyType)) {
      labelSurveyType = `${this.translate.instant('SURVEY_TYPE')}(${selectedSurveyType.length})`;
    } else {
      labelSurveyType = this.translate.instant('SURVEY_TYPE');
    }
    accordionViewType = {
      displayType: 'Accordion',
      id: 'survey_type',
      primaryText: labelSurveyType,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: [],
      isFixedFilter: true,
      filterOrder: '1'
    };
    this.viewData.data.find(d => d.text == this.translate.instant('SURVEY_TYPE')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryPath == 'survey_type' && filter.value == item.value);
      accordionViewType.childItems.push({
        id: 'filter_menu_status' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewType.isExpanded = true;
    });
    this.categories.push(accordionViewType);
    //survey use-type--------------------------------------------------------------------------------------------------------
    const selectedSurveyUseType = this.tempSelectedcategories.filter((category) => category.categoryPath == 'survey_useType');
    if(!_.isEmpty(selectedSurveyUseType)) {
      labelSurveyUseType = `${this.translate.instant('USE_TYPE')}(${selectedSurveyUseType.length})`;
    } else {
      labelSurveyUseType = this.translate.instant('USE_TYPE');
    }
    accordionViewUseType = {
      displayType: 'Accordion',
      id: 'survey_useType',
      primaryText: labelSurveyUseType,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: [],
      isFixedFilter: true,
      filterOrder: '3'
    }
    this.viewData.data.find(d => d.text == this.translate.instant('USE_TYPE')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryPath == 'survey_useType' && filter.value == item.value);
      accordionViewUseType.childItems.push({
        id: 'filter_menu_request_type' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewUseType.isExpanded = true;
    });
    this.categories.push(accordionViewUseType);
    //survey status----Internal Survey only--------------------------------------------------------------------------------
    const selectedSurveyStatus = this.tempSelectedcategories.filter((category) => category.categoryPath == 'survey_status');
    if(!_.isEmpty(selectedSurveyStatus)) {
      labelSurveyStatus = `${this.translate.instant("SURVEY_INTERNAL_SURVEY")} - ${this.translate.instant('STATUS')}(${selectedSurveyStatus.length})`;
    } else {
      labelSurveyStatus = `${this.translate.instant("SURVEY_INTERNAL_SURVEY")} - ${this.translate.instant('STATUS')}`;
    }
    const isSelectedInternalSurvey: boolean = this.selectedCategories.some(c=>c.value == SurveyCategory.INTERNAL) || false;

    accordionViewStatus = {
      displayType: 'Accordion',
      id: 'survey_status',
      primaryText: labelSurveyStatus,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: [],
      isFixedFilter: true,
      filterOrder: '2',
      isSubCategory: true,
      isForcedHide: !isSelectedInternalSurvey
    }
    this.viewData.data.find(d => d.text == this.translate.instant('STATUS')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryPath == 'survey_status' && filter.value == item.value);
      accordionViewStatus.childItems.push({
        id: 'filter_menu_request_type' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewStatus.isExpanded = true;
    });
    this.categories.push(accordionViewStatus);

    //Clear filter--------------------------------------------------------------------------------------------------------
    if(isClearFilter) {
      this._selectedDate = { startDate: '', endDate: '' };
      this._initDateFormFields();
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          cat.isExpanded = false;
          if(cat.id == 'survey_status') {
            cat.isForcedHide = true;
          }
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
        this.searchConfigDataService.filterMenuFilterClear.next(null);
      }
    }
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      if (this.backgroundUploadInProgress) return;
      switch (id) {
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.FilterMenuModal,
          startDateTimeValue: this.startDateTimeValue,
          endDateTimeValue: this.endDateTimeValue
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
        this.isUpdatedDate = true;
        const selectedStartDate = data.data.startTime;
        const selectedStartTime = this.dateTimeFormatsService.getFormattedTimeValue(selectedStartDate);
        // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined.
        // set 'MMM dd, yyyy' and 'en-US' to combine date and time, and changed date format will be adjusted through getFormattedDateTimeText().
        const getStartDate = this.datePipe.transform(selectedStartDate, "MMM dd, yyyy", undefined, 'en-US');
        const setCombinedStartDateTime = moment(getStartDate + ' ' + selectedStartTime).format();
        this.startDateTimeValue = setCombinedStartDateTime;
        // set end date time value
        let setCombinedEndDateTime: string;
        if(data.data.endTime !='') {
          const selectedEndDate = data.data.endTime;
          const selectedEndTime = this.dateTimeFormatsService.getFormattedTimeValue(selectedEndDate);
          const getEndDate = this.datePipe.transform(selectedEndDate, "MMM dd, yyyy", undefined, 'en-US');
          setCombinedEndDateTime = moment(getEndDate + ' ' + selectedEndTime).format();
          this.endDateTimeValue = setCombinedEndDateTime;
        }else {
          setCombinedEndDateTime = this.startDateTimeValue;
          this.endDateTimeValue = setCombinedEndDateTime;
        }
        this._selectedDate = {
          startDate: setCombinedStartDateTime,
          endDate: setCombinedEndDateTime,
        }
        this._initDateFormFields();
        this._initFilterMenuHeaderLeft();
        this._initFilterMenuSectionHeader();
        this._cd.detectChanges();
        this._cd.markForCheck();
      }
    });
    popover.present();
      
    
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.FilterMenuModal,
          startDateTimeValue: this.startDateTimeValue,
          endDateTimeValue: this.endDateTimeValue
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='') {
        this.isUpdatedDate = true;
        const selectedEndDate = data.data.endTime;
        const selectedEndTime = this.dateTimeFormatsService.getFormattedTimeValue(selectedEndDate);
        // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined.
        // set 'MMM dd, yyyy' and 'en-US' to combine date and time, and changed date format will be adjusted through getFormattedDateTimeText().
        const getEndDate = this.datePipe.transform(selectedEndDate, "MMM dd, yyyy", undefined, 'en-US');
        const setCombinedEndDateTime = moment(getEndDate + ' ' + selectedEndTime).format();
        this.endDateTimeValue = setCombinedEndDateTime;
        // set start date time value
        let setCombinedStartDateTime: string;
        if(data.data.startTime !='') {
          const selectedStartDate = data.data.startTime;
          const selectedStartTime = this.dateTimeFormatsService.getFormattedTimeValue(selectedStartDate);
          const getStartDate = this.datePipe.transform(selectedStartDate, "MMM dd, yyyy", undefined, 'en-US');
          setCombinedStartDateTime = moment(getStartDate + ' ' + selectedStartTime).format();
          this.startDateTimeValue = setCombinedStartDateTime;
        }else {
          setCombinedStartDateTime = this.startDateTimeValue;
          this.startDateTimeValue = setCombinedStartDateTime;
        }
        this._selectedDate = {
          startDate: setCombinedStartDateTime,
          endDate: setCombinedEndDateTime,
        }
        this._initDateFormFields();
        this._initFilterMenuHeaderLeft();
        this._initFilterMenuSectionHeader();
        this._cd.detectChanges();
        this._cd.markForCheck();
      }
    });
    popover.present();
  }

  getFormattedDateTimeText(): any {
    let startDateTimeValue: Date = this._selectedDate && this._selectedDate.startDate ? new Date(this._selectedDate.startDate) : undefined;
    let endDateTimeValue: Date = this._selectedDate && this._selectedDate.endDate ? new Date(this._selectedDate.endDate) : undefined;
    let startDay = startDateTimeValue ? this.datePipe.transform(startDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : undefined;
    let endDay = endDateTimeValue ? this.datePipe.transform(endDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : undefined;

    let dateTimeValue: any = {
      startDateTime: undefined,
      startDate: undefined,
      endDateTime: undefined,
      endDate: undefined,
    };

    dateTimeValue = {
      startDateTime: startDateTimeValue,
      startDate: startDay,
      endDateTime: endDateTimeValue,
      endDate: endDay,
    };
    return dateTimeValue;
  }

  private _initSavedSearchDataModels(searchToolID, hasToShowShare = false, isClearFilter?: boolean,initialFilterLoad?:boolean){
    this.savedFilters = {
      displayType: 'Accordion',
      id: 'saved_filter_' + searchToolID,
      primaryText: this.translate.instant('SAVED_FILTER'),
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: true,
      searchActiveAt: 10,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: [],
      clickHandler:((id?:string, event?:any, specificTarget?:string, dataRef?:any)=>{
        if(specificTarget && specificTarget == 'endArrow'){
          this.deleteSavedSearch(dataRef.id);
        }
      })
    }
    let expand: boolean = false;
    this.searchConfigService.savedSearches.forEach(savedSearch=>{
      if(searchToolID && savedSearch.searchToolName == searchToolID && !savedSearch.isDeleted){       
        let sharedSearchFlag =  savedSearch['searchType'] == UserSavedSearchTypes.SHARED ? true : false;
        let sharedByCurrentUser = sharedSearchFlag && (savedSearch['createdbyValue'] == this.authService.user.systemUserID || savedSearch['createdbyValue'] == undefined) ? true : false;
        let checked =  isClearFilter ? false : !_.isEmpty(this.tempSelectedcategories) && savedSearch.categoryValuePairs ? _.isEqual(this.tempSelectedcategories, savedSearch.categoryValuePairs) : false;       
        if (!sharedSearchFlag || sharedByCurrentUser) this.savedFilters.childItems.push({
          id: savedSearch.searchID ||savedSearch.offlineID,
          label: savedSearch.searchName,
          value: savedSearch.categoryValuePairs,
          isChecked: checked,
          type: SuggestionPillType.CATEGORY_VALUESEARCH,
          showArrow: sharedByCurrentUser || !sharedSearchFlag ? true : false,
          arrowType: 'indegene-ios-close-icon',
          isSingleSelect: true,
          isDefaultFilter : savedSearch.indskr_setfilter || false
        })
        expand = expand || checked;
      }
    })
    this.savedFilters.childItems = _.orderBy(this.savedFilters.childItems, [item=>item.label.toLowerCase()], ['asc']);
    this.savedFilters['isExpanded'] = expand;
  }

  deleteSavedSearch(savedSearchID){
    this.alertService.showAlert({ title:this.translate.instant('DELETE_SAVED_FILTER'),
                                  cssClass:'saveSearchAlert',
                                  message:this.translate.instant('FILTER_DELETE_CONFIRMATION'),
                                }, this.translate.instant('DELETE'), this.translate.instant('CANCEL'))
    .then(async (res)=>{
      if(res.role == 'ok'){
        _.remove(this.savedFilters.childItems,(model)=> model.id == savedSearchID);
        this.searchConfigDataService.deleteSavedSearch(savedSearchID);
      }
    })
  }

  public footerButtonClicked(buttonId: string) {
    switch (buttonId) {
      case "saveFilter":
        this.saveAdvancedSearch();
        break;   
      default:
        break;
    }
  }

  async saveAdvancedSearch(failedText?: string){
    let saveSearchName: string
    let searchToolNameID = this._getToolSearchName();
    let currentSavedSearches = this.searchConfigService.savedSearches.filter(o=> o.searchToolName==searchToolNameID);
    this.alertService.showAlert({ title:this.translate.instant('SAVE_FILTER'),
                                  subTitle:failedText?failedText:'',
                                  cssClass:'saveSearchAlert',
                                  message:this.translate.instant('SAVE_FILTER_POPOVER_MESSAGE'),
                                  inputs:[{type:'text', name:"name", placeholder:this.translate.instant('ENTER_NAME')}]
                                }, this.translate.instant('SAVE'), this.translate.instant('CANCEL'))
    .then(async (res)=>{
      if(res.role == 'ok'){
        if(res.data && res.data.values.name){
          saveSearchName = res.data.values.name.trim();
          let sameNameSearches = currentSavedSearches.filter(o=>o.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase())==0);
          let incrementNumber: number = 1;
          if(sameNameSearches.length){
            saveSearchName += ' (';
            _.each(sameNameSearches,(searchData=>{
              if(searchData.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0){
                let currentIncrement = parseInt(searchData.searchName.toLowerCase().charAt(saveSearchName.length));
                if(!isNaN(currentIncrement) && currentIncrement>=incrementNumber) incrementNumber = currentIncrement+1;
              }
            }))
            saveSearchName+= incrementNumber+')';
          }
          this.agendaFooterService.disableButton(['saveFilter']);
          await this.searchConfigDataService.saveAdvancedSearch(saveSearchName,
                                                                searchToolNameID?searchToolNameID:'',
                                                                UserSavedSearchTypes.OWNED,
                                                                this.tempSelectedcategories.slice()
                                                                ).then(()=> {
                                                                  this.agendaFooterService.disableButton(['saveFilter']);
                                                                })

        }
        else if(res.data && res.data.values.name == ""){
          this.saveAdvancedSearch(this.translate.instant('NAME_IS_REQUIRED'));
        }
      }
    })
  }

  private _mapSubCategory(categoryPath: string, cItem: any) {
    const categoryIdx = this.categories.findIndex(c=>c.id == categoryPath);
    if(categoryIdx > -1) this.categories[categoryIdx].isForcedHide = !cItem.isChecked;
    if(!cItem.isChecked) {
      const tempCategoryIdx = this.tempSelectedcategories.findIndex(c=>c.categoryPath == categoryPath);
      if(categoryIdx > -1 && tempCategoryIdx > -1) {
        this.categories[categoryIdx].isExpanded = false;
        this.categories[categoryIdx].childItems.map(i=>{ i.isChecked = false; });
        const label: string = this.tempSelectedcategories[tempCategoryIdx].categoryDisplayName ? this.tempSelectedcategories[tempCategoryIdx].categoryDisplayName: this.tempSelectedcategories[tempCategoryIdx].categoryName;
        if(!_.isEmpty(label)) this.categories[categoryIdx].primaryText = label;
      }
      this.tempSelectedcategories = this.tempSelectedcategories.filter(c=>c.categoryPath != categoryPath);
    }
  }

  private _updateSubCategories(item: any) {
    if(this.from == PageName.ContactPageComponent) {
      if(item.id == "myActivitiesByContact") {
        item.childItems.forEach(cItem=>{
          const isMeetings: boolean = cItem.id.includes(this.translate.instant("MEETINGS"));
          const isMessages: boolean = cItem.id.includes(this.translate.instant("MESSAGES"));
          if(isMeetings) {
            this._mapSubCategory("filterMeetingsStatus", cItem);
          }else if(isMessages) {
            this._mapSubCategory("filterMessagesStatus", cItem);
            this._mapSubCategory("filterMessagesAttachment", cItem);
            this._mapSubCategory("filterMessagesLinkAccessed", cItem);
          }
        });
        //Check the selected activity in my activities to display the date duration field
        this.isSelectedMyActivity = item.childItems.some(item=>item.isChecked);
        if(!this.isSelectedMyActivity) {
          this._selectedDate = { startDate: '', endDate: '' };
          this._initDateFormFields();
        }
      }else {
        let tempSubCategories = this.tempSelectedcategories.find(c=>c.categoryPath == "myActivitiesByContact").subCategories || [];
        const idx = tempSubCategories.findIndex(category=>category.id == item.id);
        if(idx > -1) {
          tempSubCategories[idx] = item;
        }else {
          tempSubCategories.push(item);
        }
        this.tempSelectedcategories.find(c=>c.categoryPath == "myActivitiesByContact").subCategories = tempSubCategories;
      }
    }else if(this.from == PageName.SurveyPageComponent) {
      if(item.id == "survey_type") {
        item.childItems.forEach(cItem=> {
          this._mapSubCategory("survey_status", cItem);
        });
      }
    }else if(this.from == PageName.ActivitiesPageComponent) {
      if(item.id == "Activity_Type") {
        item.childItems.forEach(cItem=>{
          this._mapSubCategory("Activity_Type", cItem);
        });
      }
    }
  }

  onCancel(event) {
    this.searching = false;
    this.searchedCategories = [];
    this._init();
  }

  private _initAccordionViewForActivitiesPage(isClearFilter: boolean = false){
    this.categories = [];
    let accordionView: OmniAccordionViewDataModel;
    let alreadySelectedValues = this.tempSelectedcategories ? this.tempSelectedcategories : [];
    accordionView = {
      displayType: 'Accordion',
      id: 'Activity_Type',
      primaryText: this.translate.instant('ACTIVITY_TYPE'),
      isExpanded: true,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: true,
      searchActiveAt: 10,
      isSelectable: true,
      isFixedFilter: true,
      isAggregatedSection: false,
      configuredFrom: '',
      childItems: [
        {
          id: ActivityType.AllActivity,
          label: this.translate.instant('ALL_ACTIVITIES'),
          value: ActivityType.AllActivity,
          isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.AllActivity) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('ALL_ACTIVITIES')),
          type: SuggestionPillType.CATEGORY_VALUESEARCH
        },
        {
          id: ActivityType.Appointment,
          label: this.translate.instant('MEETINGS'),
          value: ActivityType.Appointment,
          isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.Appointment) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('MEETINGS')),
          type: SuggestionPillType.CATEGORY_VALUESEARCH
        },
        {
          id: 'RemoteMeeting',
          label: this.translate.instant('LIVETIME_MEETINGS'),
          value: 'RemoteMeeting',
          isChecked: alreadySelectedValues.some((filter) => filter.value == 'RemoteMeeting')|| alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('LIVETIME_MEETINGS')),
          type: SuggestionPillType.CATEGORY_VALUESEARCH
        },
      ],
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)) {
      accordionView.childItems.push({
        id: ActivityType.Email,
        label: this.translate.instant('MESSAGES'),
        value: ActivityType.Email,
        isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.Email) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('MESSAGES')),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
      accordionView.childItems.push({
        id: ActivityType.PhoneCall,
        label: this.translate.instant('PHONE_CALLS'),
        value: ActivityType.PhoneCall,
        isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.PhoneCall) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('PHONE_CALLS')),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL)) {
      accordionView.childItems.push({
        id: ActivityType.TimeOff,
        label: this.translate.instant('TIME_OFF'),
        value: ActivityType.TimeOff,
        isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.TimeOff) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('TIME_OFF')),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.JOINT_MEETING)) {
      accordionView.childItems.push({
        id: ActivityType.JointMeeting,
        label: this.translate.instant('JOIN_MEETING'),
        value: ActivityType.JointMeeting,
        isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.JointMeeting) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('JOIN_MEETING')),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY) && this.authService.hasFeatureAction(FeatureActionsMap.JOINT_PHONE_CALL)) {
      accordionView.childItems.push({
        id: ActivityType.JointPhoneCall,
        label: this.translate.instant('JOINT_PHONE_CALLS'),
        value: ActivityType.JointPhoneCall,
        isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.JointPhoneCall) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('JOINT_PHONE_CALLS')),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      })
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)) {
      accordionView.childItems.push({
        id: ActivityType.Sample,
        label: this.translate.instant('ALLOWCATION_ORDERS'),
        value: ActivityType.Sample,
        isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.Sample) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('ALLOWCATION_ORDERS')),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY)) {
      accordionView.childItems.push({
        id: ActivityType.FollowUp,
        label: this.translate.instant('FOLLOW_UP_ACTIONS'),
        value: ActivityType.FollowUp,
        isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.FollowUp) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('FOLLOW_UP_ACTIONS')),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT)) {
      accordionView.childItems.push({
        id: ActivityType.Order,
        label: this.translate.instant('ACCOUNT_TIMELINE_OPTION_ORDERS'),
        value: ActivityType.Order,
        isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.Order) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('ACCOUNT_TIMELINE_OPTION_ORDERS')),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
      accordionView.childItems.push({
        id: ActivityType.CaseIntake,
        label: this.translate.instant('CUSTOMER_INQUIRIES_WITH_TEXT',{globalCustomerText: this.utilityService.globalCustomerText}),
        value: ActivityType.CaseIntake,
        isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.CaseIntake) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('CUSTOMER_INQUIRIES_WITH_TEXT',{globalCustomerText: this.utilityService.globalCustomerText})),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      })
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.LIVEMEET)) {
      accordionView.childItems.push({
        id: ActivityType.LiveMeet,
        label: this.translate.instant('LIVE_MEETING'),
        value: ActivityType.LiveMeet,
        isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.LiveMeet) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('LIVE_MEETING')),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      })
    }
    if (this.authService.user.hasProcedureLog || this.authService.user.hasBulkProcedureLog) {
      accordionView.childItems.push({
        id: ActivityType.SurgeryOrder,
        label: this.translate.instant('PROCEDURE_LOGS'),
        value: ActivityType.SurgeryOrder,
        isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.SurgeryOrder) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('PROCEDURE_LOGS')),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      })
    };
    if (this.authService.hasFeatureAction(FeatureActionsMap.STORE_CHECK)) {
      accordionView.childItems.push({
        id: ActivityType.StoreCheck,
        label: this.translate.instant('STORE_CHECKS'),
        value: ActivityType.StoreCheck,
        isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.StoreCheck) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('STORE_CHECKS')),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      })
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING)) {
      accordionView.childItems.push({
        id: ActivityType.SetBooking,
        label: this.translate.instant('KIT_BOOKINGS'),
        value: ActivityType.SetBooking,
        isChecked: alreadySelectedValues.some((filter) => filter.value == ActivityType.SetBooking) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.translate.instant('KIT_BOOKINGS')),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      })
    }
    accordionView.childItems = _.orderBy(accordionView.childItems, ['label'], ['asc']);
    this.categories.push(accordionView);

    //Positions
    let positionAccordionView: OmniAccordionViewDataModel;
    positionAccordionView={
        displayType: 'Accordion',
        id: 'Positions',
        primaryText: this.translate.instant('POSITIONS'),
        isExpanded: true,
        showExpandIcon: true,
        showCollapseIcon: true,
        isSearchable: true,
        searchActiveAt: 10,
        isFixedFilter: true,
        isSelectable: true,
        isAggregatedSection: false,
        configuredFrom: '',
        childItems: [],
    };
    if(this.authService.user.childPositions && this.authService.user.childPositions.length>0){
      this.authService.user.childPositions.forEach(position =>{
        if(!positionAccordionView.childItems.some(childPos=>{childPos.id == position.childPositionId})){
          positionAccordionView.childItems.push({
            id: position.childPositionId,
            label: position.childPosition,
            value: position.childPositionId,
            isChecked: alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == position.childPosition), //alreadySelectedValues.some((filter) => filter.value == position.childPositionId),
            type: SuggestionPillType.CATEGORY_VALUESEARCH
          });
        }
      })
    }
    //if BU flag Home Page Team View == Child and Sibling Positions, add sibling and my positions to categories
    if(this.authService.user.buSettings["indskr_homepageteamview"]===548910001){
      if(this.authService.user?.siblingPositions?.length>0){
        this.authService.user.siblingPositions.forEach(position =>{
          if(!positionAccordionView.childItems.some(childPos=> childPos.id == position.siblingPositionId)){
            positionAccordionView.childItems.push({
              id: position.siblingPositionId,
              label: position.siblingPosition,
              value: position.siblingPositionId,
              isChecked: alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == position.siblingPosition),
              type: SuggestionPillType.CATEGORY_VALUESEARCH
            });
          }
        })
      }
      // add my positions also
      this.authService.user.positions?.forEach(position =>{
        if(!positionAccordionView.childItems.some(childPos=> childPos.id == position.ID)){
          positionAccordionView.childItems.push({
            id: position.ID,
            label: position.name,
            value: position.ID,
            isChecked: alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == position.name),
            type: SuggestionPillType.CATEGORY_VALUESEARCH
          });
        }
      })
    }
    if(positionAccordionView.childItems?.length > 0){
      positionAccordionView.childItems = _.orderBy(positionAccordionView.childItems, ['label'], ['asc']);
      this.categories.push(positionAccordionView);
    } 
    //remove any positions in tempSelectedCategories on page reload
    this.tempSelectedcategories = this.tempSelectedcategories.filter(x => x.categoryPath!="Positions");

    //Users
    let usersAccordionView: OmniAccordionViewDataModel;
    usersAccordionView = {
      displayType: 'Accordion',
      id: 'Users',
      primaryText: this.translate.instant('USERS'),
      isExpanded: true,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: true,
      searchActiveAt: 10,
      isFixedFilter: true,
      isSelectable: true,
      isAggregatedSection: false,
      configuredFrom: '',
      childItems: [],
    };
    if(this.authService.user.childUsers && this.authService.user.childUsers.length > 0){
      //add my current user
      usersAccordionView.childItems.push({
        id: this.authService.user.systemUserID,
        label: this.authService.user.displayName + ' ('+this.translate.instant('ME')+')',
        value: this.authService.user.systemUserID,
        isChecked: alreadySelectedValues.some((filter) => filter.value == this.authService.user.systemUserID) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.authService.user.displayName + ' ('+this.translate.instant('ME')+')'),
        type: SuggestionPillType.CATEGORY_VALUESEARCH
      });
      //add child users
      this.authService.user.childUsers.forEach(user=> {
        usersAccordionView.childItems.push({
          id: user.userId,
          label: user.fullName,
          value: user.userId,
          isChecked: alreadySelectedValues.some((filter) => filter.value == user.userId)|| alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == user.fullName),
          type: SuggestionPillType.CATEGORY_VALUESEARCH
        });
      })
    }
    //if BU flag Home Page Team View == Child and Sibling Positions, add sibling users to categories
    if(this.authService.user.buSettings["indskr_homepageteamview"]===548910001){
      if(this.authService.user.siblingUsersCommonParent?.length>0){
        this.authService.user.siblingUsersCommonParent.forEach(user=> {
          if(!usersAccordionView.childItems.some(siblingUser => siblingUser.id == user.userId)){
            usersAccordionView.childItems.push({
              id: user.userId,
              label: user.fullName,
              value: user.userId,
              isChecked: alreadySelectedValues.some((filter) => filter.value == user.userId)|| alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == user.fullName),
              type: SuggestionPillType.CATEGORY_VALUESEARCH
            });
          }
        });
      }
      //add other users from my positions
      if(this.authService.user.siblingUsersCommonPositions?.length>0){
        this.authService.user.siblingUsersCommonPositions.forEach(user=> {
          if(!usersAccordionView.childItems.some(siblingUser => siblingUser.id == user.userId)){
            usersAccordionView.childItems.push({
              id: user.userId,
              label: user.fullName,
              value: user.userId,
              isChecked: alreadySelectedValues.some((filter) => filter.value == user.userId)|| alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == user.fullName),
              type: SuggestionPillType.CATEGORY_VALUESEARCH
            });
          }
        });
      }
      //if no child users, add my current user
      if(!usersAccordionView.childItems.some(siblingUser => siblingUser.id == this.authService.user.systemUserID)){
        usersAccordionView.childItems.push({
          id: this.authService.user.systemUserID,
          label: this.authService.user.displayName + ' ('+this.translate.instant('ME')+')',
          value: this.authService.user.systemUserID,
          isChecked: alreadySelectedValues.some((filter) => filter.value == this.authService.user.systemUserID) || alreadySelectedValues.some((filter) => filter.isSavedFilter && filter.selectedFacet == this.authService.user.displayName + ' ('+this.translate.instant('ME')+')'),
          type: SuggestionPillType.CATEGORY_VALUESEARCH
        });
      }
    }
    if(usersAccordionView.childItems?.length > 0){
      usersAccordionView.childItems = _.orderBy(usersAccordionView.childItems, ['label'], ['asc']);
      this.categories.push(usersAccordionView);
    }

    if(isClearFilter) {
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          //cat.isExpanded = false;
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
      }
    }
  }

  public loadMoreOptions(item,selected){
    this.selectedCategory = item;
    this.optionsList = [];
    this.recordCount = 30;
    this.filteredOptionsList = [];
    this.displayOptionsList = [];
    this.isOptionsSearching = false;
    let searchIndexConfig;
    if(this.from === PageName.TerritoryManagementDetailsComponent){
      searchIndexConfig = this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig;
    }else if(this.from === PageName.ContactPageComponent){
      searchIndexConfig = this.searchConfigService.contactConfiguredSearchIndexConfig;
    }
    // tag more search

    let obj = {
      categoryDisplayName : "Tag",
      categoryName  : "Tag",
      categoryRelativePath  : "userTag.indskr_name",
      featureActionDependant  : "Tag",
      translationKey  : "Tag",
      values  : [],
      controlDataType:"PicklistType",
      entity : "",
      isBooleanTypeCategory : false,
      isMultilingualLookup: null,
      linkEntityFetchXML:"",
      mappingValues: [],
      numOfLinkEntity: "",
    }
    obj.values=this.contactService.contactTags.filter(y=>y.filter).map(x=>x.indskr_name);
    let copySearchIndexConfig = JSON.parse(JSON.stringify(searchIndexConfig))
    copySearchIndexConfig.push(obj);
    if(copySearchIndexConfig){
      //id: config.configuredFrom == 'Assess' ? config.categoryName : config.categoryRelativePath,
      let foundCategory = copySearchIndexConfig.find(a=> a.categoryName == item.id || a.categoryRelativePath == item.id);
      if(foundCategory && foundCategory.values?.length > 0){
        const valuesToMapped =  foundCategory.values.slice(20,foundCategory.values.length);
        valuesToMapped.forEach((value) => {
          let categoryName = foundCategory.categoryDisplayName?foundCategory.categoryDisplayName:foundCategory.categoryName;
          let checked = this.tempSelectedcategories.some((filter) => (filter.categoryName == categoryName || filter.categoryDisplayName == categoryName) && filter.selectedFacet == value);
          let idx = foundCategory.categoryDisplayName?this.optionsList?.findIndex((item) => item.id == 'filter_menu_'+foundCategory.categoryDisplayName+'_'+value):this.optionsList?.findIndex((item) => item.id == 'filter_menu_'+foundCategory.categoryName+'_'+value);
          if(idx<0) {
              this.optionsList.push({
              id: 'filter_menu_'+foundCategory.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: foundCategory.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            })
          } else {
            this.optionsList[idx] = {
              id: 'filter_menu_'+foundCategory.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: foundCategory.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            }
          }
        })
      } else if(foundCategory && foundCategory?.mappingValues?.length > 0){
          const valuesToMapped =  foundCategory.mappingValues.slice(20,foundCategory.mappingValues.length);
          valuesToMapped.forEach((value) => {
            let categoryName = foundCategory.categoryDisplayName?foundCategory.categoryDisplayName:foundCategory.categoryName;
            let checked = this.tempSelectedcategories.some((filter) => (filter.categoryName == categoryName || filter.categoryDisplayName == categoryName) && filter.selectedFacet == value);
            let idx = foundCategory.categoryDisplayName?this.optionsList?.findIndex((item) => item.id == 'filter_menu_'+foundCategory.categoryDisplayName+'_'+value):this.optionsList?.findIndex((item) => item.id == 'filter_menu_'+foundCategory.categoryName+'_'+value);
            if(idx<0) {
              this.optionsList.push({
                id: 'filter_menu_'+foundCategory.categoryName+'_'+value.formattedValue,
                label: value.formattedValue,
                value: value.actualValue,
                isChecked: checked,
                showArrow:true,
                arrowType: 'chevron-down-outline',
                category: foundCategory.categoryName,
                type: foundCategory.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
              })
            } else {
              this.optionsList[idx] = {
                id: 'filter_menu_'+foundCategory.categoryName+'_'+value.formattedValue,
                label: value.formattedValue,
                value: value.actualValue,
                isChecked: checked,
                showArrow:true,
                arrowType: 'chevron-down-outline',
                category: foundCategory.categoryName,
                type: foundCategory.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
              }
            }
          })
      }
    }     
    this.optionsList = _.orderBy(this.optionsList, ['label'], ['asc']);
    this.setToFullList();
    // this.displayOptionsList = this.sliceItems(0, this.recordCount);
    this.isRightPaneNavActive = true;
  }

  public onCloseRightPane(){
    this.selectedCategory = null;
    this.optionsList = [];
    this.filteredOptionsList = [];
    this.displayOptionsList = [];
    this.isOptionsSearching = false;
    this.isRightPaneNavActive = false;
  }

  public searchOptions(ev){
    const val: string = (ev && ev.target && ev.target.value) ? ev.target.value : '';
    if (val.length >= 1) {
      this.isOptionsSearching = true;
      let foundValues = [];
      const formattedSearchText = this.searchConfigService.convertFormattedString(val).trim().toLowerCase();
      this.optionsList.filter(i => {
        const formattedItem = this.searchConfigService.convertFormattedString(i.label).trim().toLowerCase();
        if(formattedItem.includes(formattedSearchText)) {
          if(!_.isEmpty(foundValues)) {
            const idx = foundValues.findIndex(v=>v.id == i.id);
            if(idx < 0) {
              foundValues.push(i);  
            } 
          }else {
            foundValues.push(i);
          }
        }
      })
      this.filteredOptionsList = foundValues;
      this.setToFullList();
      // this.displayOptionsList = this.sliceItems(0, this.recordCount);
    } else {
      this.isOptionsSearching = false;
      this.filteredOptionsList = [];
      this.setToFullList();
      // this.displayOptionsList = this.sliceItems(0, this.recordCount);
    }
  }

  // public doInfinite(eventDetails,event) {
  //   this.displayOptionsList.push(...this.sliceItems(this.recordCount, 30));
  //   this.recordCount = this.displayOptionsList.length;
  //   event.target.complete();
  // }

  // private sliceItems(startIndex: number, count: number) {
  //   if(this.isOptionsSearching){
  //     return this.filteredOptionsList.length < startIndex + count ? this.filteredOptionsList.slice(startIndex) : this.filteredOptionsList.slice(startIndex, startIndex + count);;
  //   }else{
  //     return this.optionsList.length < startIndex + count ? this.optionsList.slice(startIndex) : this.optionsList.slice(startIndex, startIndex + count);;
  //   }
  // }

  private setToFullList():void {
    if(this.isOptionsSearching){
      this.displayOptionList$.next(this.filteredOptionsList);
    }else{
      this.displayOptionList$.next(this.optionsList)
    }
  }

  public selectItem(item) {
    item.isChecked = !item.isChecked;
    this.toggleCategoryValue(this.selectedCategory, item)
  }

  private _updateSubCategoriesForAssess(item: any) {
    item.childItems.forEach(cItem=>{
      if (cItem.id == 'assessed') {
        this.categories.map(c => {
          if (c.configuredFrom && c.configuredFrom == 'Assess') {
            if (!cItem.isChecked) {
              if (c.category) {
                c.primaryText = c.category;
              }
              if (!_.isEmpty(c.childItems)) {
                c.isExpanded = false;
                c.childItems.map(cc => {
                  cc.isChecked = false;
                });
              }
            }
            c.isForcedHide = !cItem.isChecked;
          }
        });

        if (!cItem.isChecked && !_.isEmpty(this.tempSelectedcategories)) {
          this.tempSelectedcategories = this.tempSelectedcategories.filter(t=>(t.configuredFrom && t.configuredFrom != 'Assess') || !t.configuredFrom);
        }
        //searched categories
        if (this.searching) {
          this.searchedCategories.map(c => {
            if (c.configuredFrom && c.configuredFrom == 'Assess') {
              if (!cItem.isChecked) {
                if (c.category) {
                  c.primaryText = c.category;
                }
                if (!_.isEmpty(c.childItems)) {
                  c.isExpanded = false;
                  c.childItems.map(cc => {
                    cc.isChecked = false;
                  });
                }
              }
              c.isForcedHide = !cItem.isChecked;
            }
          }); 
        }
      }
    });
  }

  public selectDefultOption(item,event?){
    console.log(item,event)
    this.selectSavedCategories(item,event);
  }

}
