import { ContactMeetingState } from '@omni/classes/contact/contact.class';
import { MSEventsService } from './../../services/ms-events.service';
import { LiveMeetActivity, MeetingObjective } from './../../classes/activity/appointment.activity.class';
import { Injectable } from "@angular/core";
import { Endpoints } from "../../../config/endpoints.config";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { ActivityService } from "../../services/activity/activity.service";
import { AuthenticationService } from "../../services/authentication.service";
import { LogService } from "../../services/logging/log-service";
import { MeetingStatus } from "../../classes/meeting/meeting.class";
import { Activity, MeetingActivityType, ActivityTypeCodeRaw, MeetingActivityTypeCode, FormatType, ActivityType } from "../../classes/activity/activity.class";
import { DeviceService } from "../../services/device/device.service";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME, OFFLINE_DB_LINKED_ENTITY_NAME } from "../../services/disk/disk.service";
import { AppointmentActivity, OFFLINE_ID_PREFIX } from "../../classes/activity/appointment.activity.class";
import { Account } from "../../classes/account/account.class";
import {
  Presentation,
  Products
} from "../../classes/presentation/presentation.class";
import { Contact } from "../../classes/contact/contact.class";

import { Events } from '@omni/events';
import { ActivityDataService } from "../activity/activity.service";
import { GlobalErrorHandler } from "../../services/error-handler/error-handler-service";
import { ContactOfflineService } from "../../services/contact/contact.service";
import { format, isValid, addMinutes, addSeconds } from "date-fns";
import { DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { AccompainedUser } from "../../classes/activity/accompained-user.class";
import { UIService } from "../../services/ui/ui.service";
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ToastStyle } from '../../services/notification/notification.service';
import { Resource } from '../../classes/resource/resource.class';
import { GlobalUtilityService } from '../../services/global-utility.service';
import { cloneDeep, intersectionBy, intersectionWith, isEmpty, isEqual, xorBy, xorWith } from 'lodash';
import { PhoneActivity } from '../../classes/activity/phone.activity.class';
import { ConfiguredFields } from '@omni/classes/authentication/configured.field.class';
import { mergeMap, retryWhen, timeout } from 'rxjs/operators';
import { ErrorCode } from './../../enums/exception/general-error-codes.enum';
import { GeneralException } from './../../classes/exception/general-exception.class';
import { DynamicsBoolean } from '../../enums/shared-enums';
import { LoadingController } from '@ionic/angular';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { AuthenticationDataService } from '../authentication/authentication.service';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { Guid } from 'typescript-guid';
import { IONote } from '@omni/classes/io/io-note.class';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { AlertService } from '@omni/services/alert/alert.service';
import _ from 'lodash';
import * as moment from 'moment';
import { throwError, timer } from 'rxjs';
import { UploadedPhoto } from '@omni/classes/store-check/uploaded-photo';
import { GPSActivityPhoto, Photo, PhotoResponse } from '@omni/classes/store-check/photo';
import { AppealDataService } from '../appeal/appeal.data.service';
import { ActivityPresentationSlideContact } from '@omni/classes/presentation/activity-slide-contacts.class';
import { SENTIMENT } from '../websocket/payloads/sentiment.payload';
import { SecInfoConfigDataService } from '../sec-info-config/sec-info-config-data-service';
import { AccountOfflineService, AccountSelectedFor } from '@omni/services/account/account.offline.service';
import { Brand } from '@omni/classes/brand/brand.class';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';
import { LocationCoordinates } from '@omni/interfaces/shared/shared.interface';
import { MeetingChartId } from '@omni/enums/edge-analytics/meeting/meeting-report.enum';

export interface MeetingCreateResponseDTO {
  activityId: string,
  indskr_isofflinemeeting: boolean,
  indskr_meetingurl: string,
  meetingTopicName: string,
  offlineMeetingId: string
};

enum ActivityContentShareTypeOptionSetValue {
  Audio = 1,
  Video,
  Screen,
  ShareContentLinkToMessagingApp,
};

@Injectable({
  providedIn: 'root'
})
export class MeetingDataService {
  public isErrorStartDateCreateMeeting: boolean = false;
  public isErrorStartDateUpdateMeeting: boolean = false;
  public meetingObjectives: MeetingObjective[] = [];
  constructor(
    private http: HttpClient,
    private activityService: ActivityService,
    private activityDataService: ActivityDataService,
    private authenticationService: AuthenticationService,
    private authDataService: AuthenticationDataService,
    private contactService: ContactOfflineService,
    private logService: LogService,
    private deviceService: DeviceService,
    private disk: DiskService,
    private events: Events,
    private errorHandler: GlobalErrorHandler,
    private uiService: UIService,
    private msEventService: MSEventsService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private globalUtility: GlobalUtilityService,
    private loadingCtrl: LoadingController,
    private dynamics: DynamicsClientService,
    private alertService: AlertService,
    private appealDataService: AppealDataService,
    private secondaryInfoService: SecInfoConfigDataService,
    private accountService: AccountOfflineService,
  ) { }

  private readonly THREE_DAYS = 4;


  getContactAttendeePayload(contact: Contact, clearJoinStatus?: boolean) {
    if (!contact) {
      return null;
    }
    const contactAttendee = {
      indskr_contactid: contact.ID,
      indskr_name: contact.fullName.trim(),
      indskr_isguest: contact.isguest,
      indskr_joinstatus: contact.connectionState,
      indskr_isremote: contact.isremote,
      indskr_leftmanually: contact.isleftmanually,
    };
    if (clearJoinStatus) {
      contactAttendee.indskr_leftmanually = false;
      contactAttendee.indskr_joinstatus = ContactMeetingState.NOTJOINED;
    } else {
      if (contact.join_date && contact.join_date != "" && contact.join_date != "null") {
        contactAttendee['indskr_joineddate'] = isValid(new Date(contact.join_date)) ? format(contact.join_date, 'YYYY-MM-DDTHH:mm:ssZ') : format(parseFloat(contact.join_date), 'YYYY-MM-DDTHH:mm:ssZ');
      }
      if (contact.left_date && contact.left_date != "" && contact.left_date != "null") {
        contactAttendee['indskr_leftdate'] = isValid(new Date(contact.left_date)) ? format(contact.left_date, 'YYYY-MM-DDTHH:mm:ssZ') : format(parseFloat(contact.left_date), 'YYYY-MM-DDTHH:mm:ssZ');
      }
    }
    return contactAttendee;
  }

  /**
   * Maps the current selected activity contacts then makes a network request
   *
   * @param {Activity} activity
   * @memberof MeetingDataService
   */
  public async addContactsToMeeting(activity: Activity, clearJoinStatus?: boolean): Promise<boolean> {
    if (this.deviceService.isOffline || this.deviceService.isDeviceRealOffline || this.activityService.hasOfflineMeetingData(activity.ID) ||
      activity.ID.includes('offline') || !(activity instanceof AppointmentActivity)) {
      return false;
    }

    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.meeting.ADD_CONTACTS_TO_MEETING.replace(
        "{activity_id}",
        activity.ID
      );

    let headers = new HttpHeaders();
    headers = headers.set(
      "X-PositionId",
      this.authenticationService.user.xPositionID
    );

    

    let contacts = cloneDeep(activity.contacts);
    let payload = [];

    contacts.map(contact => {
      const contactAttendeePayload = this.getContactAttendeePayload(contact, clearJoinStatus);
      if (contactAttendeePayload !== null) {
        payload.push(contactAttendeePayload);
      }
    });
    // # # # View Contacts in sequence the way they were selected during meeting creation # # # //

    if(this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_KEY_MESSAGE_SENTIMENT) && (activity as AppointmentActivity).activityContactSentiments && (activity as AppointmentActivity).activityContactSentiments.length){
      (activity as AppointmentActivity).activityContactSentiments.forEach(conSent=> {
        let foundObj = payload.find(a=> a.indskr_contactid == conSent.indskr_contactid);
        if(foundObj){
          foundObj['activityKeyMessageSentiments'] = conSent.activityKeyMessageSentiments;
        }
      })
    }

    try {
      await this.http.put(url, payload, { headers }).toPromise();
      for (let contact of activity.contacts) {
        if (contact.isUsedInOneTimeMeeting) {
          this.dynamics.dwa.updateSingleProperty(contact.ID, "contacts", { omniveev_isusedinonetimemeeting: true });
        }
        if (contacts.length > 0) {
          this.copyPageSentiments(activity, contacts);
          this.mapPageSentimentsInLocalDB(activity);
        }
      }
      return true;
    } catch (error) {
      console.error('addContactsToMeeting: ', error);
      return false;
    }
  }


  public async removePageSentiments(activity: Activity, contacts) {
    if (!this.deviceService.isOffline) {
      let removePageSentimentsUrl: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
        Endpoints.meeting.COPY_SENTIMENT_TO_ATTENDEESLIDE.replace(
          "{activity_id}",
          activity.ID
        );

      let headers = new HttpHeaders();
      headers = headers.set(
        "X-PositionId",
        this.authenticationService.user.xPositionID
      );

      let customers = contacts.map(con => {
        return ({
          "indskr_contactid": con.ID,
          "indskr_name": con.fullName.trim(),
        }
        )
      })
      let removePageSentimentsPayload = {
        "presentations": [],
        "contacts": [],
        "removedContacts": customers
      }

      try {
        await this.http.put(removePageSentimentsUrl, removePageSentimentsPayload, { headers }).toPromise();
      } catch (error) {
        console.error('addContactsToMeeting: ', error);
        return false;
      }
    } else {
      let presSlide = [];
      (activity as AppointmentActivity).activityPresentations.forEach(sentiment => {
        presSlide = sentiment.activityPresentationSlides
      })
      presSlide.forEach(sentiment => {
        if (sentiment.activityPresentationSlideContacts) {
          sentiment.activityPresentationSlideContacts = [];
        }
      })
    }

  }

  public async copyPageSentiments(activity: Activity, contacts) {
    let copyPageSentimentsUrl: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.meeting.COPY_SENTIMENT_TO_ATTENDEESLIDE.replace(
        "{activity_id}",
        activity.ID
      );

    let headers = new HttpHeaders();
    headers = headers.set(
      "X-PositionId",
      this.authenticationService.user.xPositionID
    );
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_KEY_MESSAGE_SENTIMENT) && (activity as AppointmentActivity).activityPresentations && (activity as AppointmentActivity).activityPresentations.length) {
      let presentations = (activity as AppointmentActivity).activityPresentations.map(presenatation => {
        return ({
          "indskr_iopresentationid": presenatation.presentationId
        })
      });
      let customers = contacts.map(con => {
        return ({
          "indskr_contactid": con.ID,
          "indskr_name": con.fullName.trim(),
        }
        )
      })
      let copyPageSentimentsPayload = {
        presentations,
        "contacts": customers,
        "removedContacts": []
      }
      try {
        await this.http.put(copyPageSentimentsUrl, copyPageSentimentsPayload, { headers }).toPromise();
      } catch (error) {
        console.error('addContactsToMeeting: ', error);
        return false;
      }
    }
    return true;
  }

  public async mapPageSentimentsInLocalDB(activity) {
    let presSlide;
    (activity as AppointmentActivity).activityPresentations.forEach(sentiment => {
      presSlide = sentiment.activityPresentationSlides
    })
    if(presSlide) {
      presSlide.forEach(sentiment => {
        for (let slideContact of activity.contacts) {
          let contact = new ActivityPresentationSlideContact({
            indskr_contactid: slideContact.ID,
            indskr_name: `${slideContact.firstName} ${slideContact.lastName}`,
            indskr_pagestartdate: sentiment.starttime ? sentiment.starttime : null,
            indskr_pageenddate: sentiment.endtime ? sentiment.endtime : null,
          });
          if (sentiment.activityPresentationSlideContacts) {
            sentiment.activityPresentationSlideContacts.push(contact);
          }
          contact.pagesentiment = sentiment.pagesentiment ? sentiment.pagesentiment : SENTIMENT.NEUTRAL;
        }
      })
    }
  }
  public async loadOfflineMeetings() {
    let offlineMeetingDocument = await this.disk.loadOfflineMeetings();
    if (
      offlineMeetingDocument &&
      offlineMeetingDocument.meetings &&
      Array.isArray(offlineMeetingDocument.meetings)
    ) {
      offlineMeetingDocument.meetings.map(offlineMeeting => {
        //Create the activity
        let activity = new AppointmentActivity(offlineMeeting);

        //Check the state and status code
        if (activity.state === 2) return;
        if (activity.status === 4) return;

        //Let activity service update fn do its magic
        this.activityDataService._appendMeetingDetailsToActivity(
          activity,
          offlineMeeting
        );
        //this.activityService.appointmentActivites.push(activity);
        this.activityService.displayActivities.push(activity);
        console.log(activity);
      });
    }
    if (!this.uiService.toolsActivityActive) {
      this.events.publish('refreshAgenda');
    } else this.uiService.agendaRefreshRequired = true;
  }

  /**
   * Returns meetings for a time frame
   *
   * @param {number} startDateTime
   * @param {number} endDateTime
   * @memberof MeetingDataService
   */
  public async getMeetingsWithDateFilter(
    startDateTime: number,
    endDateTime: number
  ) {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.GET_MEETINGS_WITH_DATE_FILTER;
    url.replace("{startDate}", startDateTime.toString());
    url.replace("{endDate}", endDateTime.toString());

    let response = await this.http.get(url).toPromise();
  }

  /**
   * Updates an activity with the payload details
   *
   * @param {AppointmentActivity} activity
   * @param {UpdateMeetingPayload} payload
   * @memberof MeetingDataService
   */
  public async updateMeeting(
    activity: AppointmentActivity,
    payload: UpdateMeetingPayload,
    updateMeetingType: boolean = false,
    notifyCalendarInviteUpdate: boolean = false,
    doNotPublishActivityEvent: boolean = false,
    isUpdateStartDate: boolean = false,
    isUpdateStartDateMeetingPresentaion: boolean = false,
  ) {
    const hasOfflineData = this.activityService.hasOfflineMeetingData(activity.ID);
    this.isErrorStartDateUpdateMeeting = false;
    activity.modified = new Date();
    // this.activityService.isActivityUpdate = true; //
    // We check if there's any existing offline data for this meeting
    if (!this.deviceService.isOffline && !hasOfflineData) {
      // Normal flow

      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.PATCH_MEETING;

      url = url.replace("{activity_id}", activity.ID);

      let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
      headers.headers = headers.headers.set(
        "X-PositionId",
        this.authenticationService.user.xPositionID
      );
      const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions();
      if (timeZone) {
        headers.headers = headers.headers.set(
          'X-Zone-Id',
          timeZone,
        );
      }
      const isGracePeriodCheck: string = (!this.deviceService.isOffline && (isUpdateStartDate || isUpdateStartDateMeetingPresentaion)).toString();
      const pastActivityGracePeriod: string = isGracePeriodCheck ? (this.authenticationService.user.maxMeetingStartDays).toString() : '';
      let headerAndPrarms = {
        ...headers,
        params: {
          'isGracePeriodCheck': isGracePeriodCheck,
          'pastActivityGracePeriod': pastActivityGracePeriod,
        }
      }

      try {
        let response;
        if (payload.meetingOutcomeId) {
          try {
            let outcomeUrl = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING_OUTCOME;
            outcomeUrl = outcomeUrl.replace("{activity_id}", activity.ID);

            await this.http
              .patch(outcomeUrl, { "indskr_caseoutcome": payload.meetingOutcomeId }, headers)
              .toPromise();
            response = 'success';
          } catch (error) {
            console.log(error)
          }
        } else {
          response = await this.http
          .patch(url, payload.getRequestBody(), headerAndPrarms)
          .toPromise();
        } 

        this.logService.logDebug(response);

        if (response) {
          // Notify calendar invite update
          if (notifyCalendarInviteUpdate) {
            this.activityService.notifyCalendarInviteUpdate(activity);
          }

          if(payload.subject) activity.subject = payload.subject;
          activity.location = payload.location;
          if (payload.scheduledStart) activity.scheduledStart = payload.scheduledStart;
          if (payload.scheduledEnd)activity.scheduledEnd = payload.scheduledEnd;
          if (payload.notes) activity.notes = payload.notes;
          if (payload.indskr_meetingtype) activity.indskr_meetingtype = payload.indskr_meetingtype;
          if (payload.meetingOutcomeId) {
            activity.meetingOutcomeId = payload.meetingOutcomeId;
            activity.meetingOutcomeString = payload.meetingOutcomeString;
          }
          if (payload.indskr_kitbooking) activity.indskr_kitbooking = payload.indskr_kitbooking;
          await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity as AppointmentActivity, true);
          if (!doNotPublishActivityEvent) {
            // this.activityService.publishActivityEvent({ action: "Update", activity: activity });
            // Date Time is updated on calendar view
            // After the update, calendar view needs to refresh the cell data from displayActivities array
            // Hence, we want to update the record in displayActivities array before publishing an update event
            // We can publish refreshAgenda event but try to update only the record in the array instead
            // because running refreshAgenda is expensive.
            const displayActivity = this.activityService.displayActivities.find(a => a.ID === activity.ID);
            if (displayActivity) {
              displayActivity.scheduledStart = new Date(activity.scheduledStart);
              displayActivity.scheduledEnd = new Date(activity.scheduledEnd);
              this.activityService.publishActivityEvent({action: "Update", activity: activity});
            }
          }
          return true;
        }
      } catch (httpError) {
        this.errorHandler.handleError(httpError);
        const errorInfo = (httpError.hasOwnProperty('error')) ? httpError.error : httpError;
        if (errorInfo.errorCode == "ERR_IO_ME12") {
          this.isErrorStartDateUpdateMeeting = true;
          if (!isUpdateStartDateMeetingPresentaion) {
            this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_CAN_NOT_SCHEDULE_MEETING', { maxMeetingStartDays: this.authenticationService.user.maxMeetingStartDays }), 'Date Time Picker', 'top', ToastStyle.DANGER);
          }
        }
        if (updateMeetingType) {
          return Promise.reject(httpError);
        }
      }
    }
    else if (!this.deviceService.isOffline && hasOfflineData) {
      // Since we have offline data for this meeting which hasn't been uploaded yet,
      // we will try to update our local db with current meeting object and then upload it to the server all together
      try {
        if (payload.indskr_meetingtype)
          activity.indskr_meetingtype = payload.indskr_meetingtype;

        if (payload.meetingOutcomeId) {
          activity.meetingOutcomeId = payload.meetingOutcomeId;
          activity.meetingOutcomeString = payload.meetingOutcomeString;
        }
        await this.activityService.upsertMeetingsOfflineData(activity as AppointmentActivity);
        //await Utility.delay(50);
        //await this.activityDataService.uploadOneOfflineMeetingRecord(activity.ID);
      } catch (error) {
        console.error('updateMeeting: ', error);
        if (updateMeetingType) {
          return Promise.reject(error);
        }
      }
    }
    else if (this.deviceService.isOffline) {
      try {
        if (payload.indskr_meetingtype)
          activity.indskr_meetingtype = payload.indskr_meetingtype;

        if (payload.meetingOutcomeId) {
          activity.meetingOutcomeId = payload.meetingOutcomeId;
          activity.meetingOutcomeString = payload.meetingOutcomeString;
        }
        await this.activityService.upsertMeetingsOfflineData(activity as AppointmentActivity);
        //await Utility.delay(50);
        //await this.activityDataService.uploadOneOfflineMeetingRecord(activity.ID);
      } catch (error) {
        console.error('updateMeeting: ', error);
        if (updateMeetingType) {
          return Promise.reject(error);
        }
      }
    }
  }
  public async updateappConfigFields(
    activity: AppointmentActivity,
    appConfigFields: Array<ConfiguredFields>,
    updateMeetingType: boolean = false
  ) {
    //hasOfflineData = this.activityService.hasOfflineMeetingData(activity.ID);

    // this.activityService.isActivityUpdate = true; //
    // We check if there's any existing offline data for this meeting
    // if (!this.deviceService.isOffline) {
    // Normal flow

    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_APP_CONFIGFIELDS_TO_MEETING;

    url = url.replace("{activity_id}", activity.ID);

    let requestBody = {
      'appconfigfields': [...appConfigFields]
    };

    let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
    headers.headers = headers.headers.set(
      "X-PositionId",
      this.authenticationService.user.xPositionID
    );
    try {
      let response = await this.http
        .patch(url, requestBody, headers)
        .toPromise();
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity as AppointmentActivity, true);
      this.activityService.publishActivityEvent({ action: "Update", activity: activity });
      this.logService.logDebug(response);


    } catch (e) {
      this.errorHandler.handleError(e);
      if (updateMeetingType) {
        return Promise.reject(e);
      }
    }
    // }
    // else{
    //   this.activityService.appConfigFields(activity.ID);
    // }
  }

  public async updateActivityLookupFields(
    activity: AppointmentActivity,
    appconfiglookupfields: UpdateTypeAndSubTypeActivityPayLoad,
    updateMeetingType: boolean = false
  ) {
    const hasOfflineData = this.activityService.hasOfflineMeetingData(activity.ID);

    //this.activityService.isActivityUpdate = true; //
    // We check if there's any existing offline data for this meeting
    if (!this.deviceService.isOffline && !hasOfflineData) {
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_APP_CONFIGFIELDS_TO_MEETING;

      url = url.replace("{activity_id}", activity.ID);

      let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
      headers.headers = headers.headers.set(
        "X-PositionId",
        this.authenticationService.user.xPositionID
      );
      try {
        let response = await this.http
          .patch(url, appconfiglookupfields.getRequestBody(), headers)
          .toPromise();
        this.logService.logDebug(response);
        if (response) {
          await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity as AppointmentActivity, true);
        }
      } catch (e) {
        this.errorHandler.handleError(e);
        if (updateMeetingType) {
          return Promise.reject(e);
        }
      }
    } else if (!this.deviceService.isOffline && hasOfflineData) {
      try {
        await this.activityService.upsertMeetingsOfflineData(activity as AppointmentActivity);
      } catch (error) {
        console.error('updateMeeting: ', error);
        if (updateMeetingType) {
          return Promise.reject(error);
        }
      }
    } else if (this.deviceService.isOffline) {
      try {
        await this.activityService.upsertMeetingsOfflineData(activity as AppointmentActivity);
      } catch (error) {
        console.error('updateMeeting: ', error);
        if (updateMeetingType) {
          return Promise.reject(error);
        }
      }
    }
  }

  public async updateActivityTypeAndActivitySubType(activity: AppointmentActivity | PhoneActivity, payload, activitytypecode: ActivityTypeCodeRaw, entity: string, disableLoader = false) {
    const previousInstance = cloneDeep(this.activityService.selectedActivity);
    const hasOfflineData = activity instanceof AppointmentActivity ? this.activityService.hasOfflineMeetingData(activity.ID) : this.activityService.hasOfflinePhoneCallData(activity.ID);
    let agendaActivity = this.activityService.getActivityByID(this.activityService.selectedActivity.ID);
    if (isEqual(entity, "indskr_activitytype")) {
      agendaActivity.activityTypeName = this.activityService.selectedActivity.activityTypeName = payload.activityTypeName;
      agendaActivity.indskr_activitytype = this.activityService.selectedActivity.indskr_activitytype = payload.indskr_activitytype;
    }
    agendaActivity.activitySubTypeName = this.activityService.selectedActivity.activitySubTypeName = payload.activitySubTypeName;
    agendaActivity.indskr_activitysubtype = this.activityService.selectedActivity.indskr_activitysubtype = payload.indskr_activitysubtype;
    if (payload.hasOwnProperty('subject')) {
      agendaActivity.subject = this.activityService.selectedActivity.subject = payload['subject'];
    }
    if (!disableLoader) {
      this.uiService.displayLoader();
    }
    if (this.deviceService.isOffline || hasOfflineData) {
      try {
        if (isEqual(activitytypecode, ActivityTypeCodeRaw.Appointment)) {
          await this.activityService.upsertMeetingsOfflineData(activity as AppointmentActivity)
        } else {
          await this.activityService.upsertPhoneCallOfflineData(activity as PhoneActivity);
        }
        this.logService.logDebug("Offline --> Updated activity type/sub type successfully");
      } catch (error) {
        this.activityService.selectedActivity = agendaActivity = previousInstance;
        console.error('error occurred while updating activity/subtype in db: ', error);
      }
    } else {
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.UPDATE_ACTIVITY_TYPE_SUB_TYPE;
      url = url.replace("{entityName}", activitytypecode);
      url = url.replace("{activityId}", activity.ID);
      let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
      headers.headers = headers.headers.set("X-PositionId", this.authenticationService.user.xPositionID);
      try {
        const response = await this.http.patch(url, payload, headers).toPromise();
        this.logService.logDebug("Updated activity type/sub type successfully");
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity, true);
      } catch (e) {
        this.activityService.selectedActivity = agendaActivity = previousInstance;
        this.errorHandler.handleError(e);
      }
    }
    if (!disableLoader) {
      this.uiService.dismissLoader();
    }
  }

  public async updateMeetingSentiment(
    activity: Activity,
    payload: Object
  ) {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.PUT_MEETING.replace('{activity_id}', activity.ID);

    let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
    headers.headers = headers.headers.set(
      "X-PositionId",
      this.authenticationService.user.xPositionID
    );
    try {
      let response = await this.http
        .put(url, payload, headers)
        .toPromise();
      this.logService.logDebug(response);
      if (payload.hasOwnProperty('activityResources') || payload.hasOwnProperty('activityPresentations'))
        await this.activityService.removeOfflineMeetingPres(activity as AppointmentActivity);
    } catch (e) {
      this.errorHandler.handleError(e);

    }
  }


  /**
   *  Need to switch all products/key message update
   */

  public async updateMeetingProductKeyMessages(activity: AppointmentActivity, keyMsgFor: UpdateKeyMessagesFor = { products: true, indications: false, diseaseAreas: false }) {
    // let loader = await this.loadingCtrl.create();
    // loader.present();
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING.replace('{activity_id}', activity.ID);

    let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
    headers.headers = headers.headers.set(
      "X-PositionId",
      this.authenticationService.user.xPositionID
    );

    try {

      let payload = {
        activityProducts: [],
        activityProductIndications: [],
        activityDiseaseAreas: [],
      };
      if (keyMsgFor.products) {
        const productsBody = activity.productsDTO;
        productsBody.push(...activity.proceduresDTO);
        payload.activityProducts = productsBody;
        this.events.publish('activityproductkeymessagesupdated', this.activityService.selectedActivity.products);
      } else {
        delete payload.activityProducts;
      }
      if (keyMsgFor.diseaseAreas) {
        let daobj = [];
        activity['activityDiseaseAreas'].forEach((obj, idx) => {
          if (obj.isSelected) {
            daobj.push({
              "indskr_diseaseareaid": obj.indskr_diseaseareaid,
              "indskr_sequence": obj.indskr_sequence ? obj.indskr_sequence : idx + 1,
              "indskr_automaticallyselected": obj.indskr_automaticallyselected,
              "indskr_geneeselected": obj.indskr_geneeselected,
              "indskr_name": obj.indskr_name,
            });
          }
        });
        payload.activityDiseaseAreas = daobj;
      } else {
        delete payload.activityDiseaseAreas;
      }
      if (keyMsgFor.indications) {
        let piobj = [];
        activity['activityProductIndications'].forEach((obj, idx) => {
          if (obj.isSelected || obj.indskr_automaticallyselected) {
            let pi = {
              "productIndicationId": obj.productIndicationId,
              "indskr_sequence": obj.indskr_sequence ? obj.indskr_sequence : idx + 1,
              "indskr_automaticallyselected": obj.indskr_automaticallyselected,
              //"indskr_geneeselected": obj.indskr_geneeselected,
              "indskr_name": obj.indskr_name,
              "activityProductIndicationKeyMessages": [],
            };
            if (obj.activityProductIndicationKeyMessages) {
              obj.activityProductIndicationKeyMessages.forEach((obj2, idx2) => {
                if (obj2.isSelected || obj2.indskr_automaticallyselected) {
                  pi.activityProductIndicationKeyMessages.push({
                    "indskr_keymessageid": obj2.indskr_keymessageid,
                    "indskr_automaticallyselected": obj2.indskr_automaticallyselected,
                    "indskr_name": obj2.indskr_name,
                  });
                }
              });
            }
            piobj.push(pi);
          }
        });
        payload.activityProductIndications = piobj;
      } else {
        delete payload.activityProductIndications
      }

      let csobj = this._populateContactSentimentKeyMessages(activity as AppointmentActivity);
      if (csobj && Array.isArray(csobj) && csobj.length > 0) {
        payload['contactAttendees'] = csobj;
      }
      let response = await this.http
        .put(url, payload, headers) // send only the required payload
        .toPromise();
      this.logService.logDebug(response);
      // loader.dismiss();
    } catch (e) {
      this.errorHandler.handleError(e);
      // loader.dismiss();
      this.notificationService.notify(
        this.translate.instant('XPERIENCES_ERROR'),
        'Activity Detail',
        'top',
        ToastStyle.DANGER,
      );
      throw e;
    }

    try { // I/O operations needs to be wrap in a try and catch block
      await this.activityService.upsertMeetingsOfflineData(activity); // offline saving
      // loader.dismiss();
    } catch (e) {
      console.error('Caught error trying to save products and key messages offline', e);
      // loader.dismiss();
      throw e;
    }
  }

  public async updateMeetingProcedures(activity: AppointmentActivity): Promise<boolean> {
    let isSuccess: boolean = true;
    let loader = await this.loadingCtrl.create();
    loader.present();
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING_PRODUCTS.replace('{activity_id}', activity.ID);

    let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
    headers.headers = headers.headers.set(
      "X-PositionId",
      this.authenticationService.user.xPositionID
    );

    try {
      const requestBody = activity.proceduresDTO;
      requestBody.push(...activity.productsDTO);
      let response = await this.http
        .put(url,requestBody , headers) // send only the required payload
        .toPromise();
      this.logService.logDebug(response);
      loader.dismiss();
    } catch (e) {
      isSuccess = false;
      this.errorHandler.handleError(e);
      loader.dismiss();
    }

    try { // I/O operations needs to be wrap in a try and catch block
      this.activityService.upsertMeetingsOfflineData(activity); // offline saving
      loader.dismiss();
    } catch (e) {
      console.error('Caught error trying to save products and key messages offline', e);
      isSuccess = false;
      loader.dismiss();
    }
    return isSuccess;
  }

  public async updateMeetingSupportingMaterials(activity: AppointmentActivity): Promise<boolean> {
    let isSuccess: boolean = true;
    let loader = await this.loadingCtrl.create();
    loader.present();
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING_SUPPORTING_MATERIALS.replace('{activity_id}', activity.ID);
    let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
    headers.headers = headers.headers.set(
      "X-PositionId",
      this.authenticationService.user.xPositionID
    );

    try {
      const requestBody = activity.activitySupportingMaterialsDTO;
      let response = await this.http
        .put(url,requestBody , headers) // send only the required payload
        .toPromise();
      this.logService.logDebug(response);
      loader.dismiss();
    } catch (e) {
      isSuccess = false;
      this.errorHandler.handleError(e);
      loader.dismiss();
    }

    try { // I/O operations needs to be wrap in a try and catch block
      this.activityService.upsertMeetingsOfflineData(activity); // offline saving
      loader.dismiss();
    } catch (e) {
      console.error('Caught error trying to save products and key messages offline', e);
      isSuccess = false;
      loader.dismiss();
    }
    return isSuccess;
  }

  public async updateKitBookings(activity: AppointmentActivity, setBookings): Promise<boolean> {
    let isSuccess: boolean = true;
    let loader = await this.loadingCtrl.create();
    loader.present();
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING_SETBOOKING.replace('{activity_id}', activity.ID);

    let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
    headers.headers = headers.headers.set(
      "X-PositionId",
      this.authenticationService.user.xPositionID
    );

    try {
      let response = await this.http
        .put(url,setBookings , headers) // send only the required payload
        .toPromise();
      this.logService.logDebug(response);
      loader.dismiss();
    } catch (e) {
      isSuccess = false;
      this.errorHandler.handleError(e);
      loader.dismiss();
    }

    try { // I/O operations needs to be wrap in a try and catch block
      this.activityService.upsertMeetingsOfflineData(activity); // offline saving
      loader.dismiss();
    } catch (e) {
      console.error('Caught error trying to save products and key messages offline', e);
      isSuccess = false;
      loader.dismiss();
    }
    return isSuccess;
  }

  async validateMeetingCompletionDatetime(activity: AppointmentActivity) {
    if (!activity || !activity.scheduledStart || !activity.scheduledEnd) {
      return;
    }

    try {
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.VALIDATE_RETROACTIVE_COMPLETION_LIMIT;
      const headers = Endpoints.headers.content_type.json;
      const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions();
      if (timeZone) {
        headers.headers = headers.headers.set(
          'X-Zone-Id',
          timeZone,
        );
      }

      // Set start & end timestamp req params
      url = url.replace("{startTimestamp}", activity.scheduledStart.getTime() + '');
      url = url.replace("{endTimestamp}", activity.scheduledEnd.getTime() + '');

      // Get retroactive limit values
      const retroactiveMeetingCompletionLimitInDays = !isNaN(this.authenticationService.user.buConfigs['indskr_retroactivemeetingcompletionlimitindays'])
        ? this.authenticationService.user.buConfigs['indskr_retroactivemeetingcompletionlimitindays']
        : null;
      const retroactiveMeetingCompletionLimitInHours = !isNaN(this.authenticationService.user.buConfigs['indskr_retroactivemeetingcompletionlimit'])
        ? this.authenticationService.user.buConfigs['indskr_retroactivemeetingcompletionlimit']
        : null;

      // Set limit value req param
      if (
        retroactiveMeetingCompletionLimitInDays !== null
        && retroactiveMeetingCompletionLimitInDays >= 0
      ) {
        url = url + '&retroLimit=' + retroactiveMeetingCompletionLimitInDays
      } else if (
        retroactiveMeetingCompletionLimitInHours !== null
        && retroactiveMeetingCompletionLimitInHours > 0
      ) {
        url = url + '&retroLimitInHours=' + retroactiveMeetingCompletionLimitInHours
      } else {
        // If retroactive completion limit config does not exist, no need to validate
        return;
      }

      await this.http.get(url, headers).toPromise();
    } catch (error) {
      // Meeting completion date validation error. Throw to upper caller
      throw error;
    }
  }

  public async updateMeetingStatus(activity: AppointmentActivity, status: MeetingStatus, dateCompleted?: number, isDeleted?: boolean, extraPayload?: any, selfClaim?: boolean, bypassRetroLimitCheck = false) {

    const stateCode = this.getActivityStateCode(status);
    let subject = activity.subject;
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
      if (!activity.indskr_shortcall && !activity.indskr_kitbooking && dateCompleted && !this.secondaryInfoService.isAppointmentSecInfoConfigured) {
        subject = this.getMeetingSubject(activity);
      } else if (this.secondaryInfoService.isAppointmentSecInfoConfigured) {
        subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForAppointment(this.activityService.selectedActivity, SecondaryInfoEntityName.Appointment);
      }
      if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
        if (!activity.indskr_shortcall && !activity.indskr_kitbooking && dateCompleted && !this.secondaryInfoService.isAppointmentSecInfoConfigured) {
          subject = this.getMeetingSubject(activity);
        } else if (this.secondaryInfoService.isAppointmentSecInfoConfigured) {
          subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForAppointment(this.activityService.selectedActivity, SecondaryInfoEntityName.Appointment);
        }
      }
      // Prevent account visit offline request
      const accountVisitRecordResponse = this.activityService.accountVisitRecordCheck(activity);
      if (this.activityService.accountVisitOfflineCheck(
        accountVisitRecordResponse,
        true,
      )) {
        return Promise.reject(new Error('updateMeetingStatus: offline attempt for account visit'));
      }

      if (this.deviceService.isOffline || this.activityService.hasOfflineMeetingData(activity.ID)) {
        try {
          const idx = this.activityService.activities.findIndex(item => item.ID === activity.ID);

          this.activityService.activities[idx].state = stateCode;
          if (status === MeetingStatus.CANCELED) {
            this.activityService.activities[idx].status = 4;
            activity.status = 4;
          }
          if (dateCompleted) {
            this.activityService.activities[idx].omnip_datecompleted = dateCompleted.toString();
            activity.omnip_datecompleted = dateCompleted.toString();
            activity.subject = subject;
          }
          if (selfClaim)
            activity.indskr_selfclaimbyuseronappttimemodification = selfClaim;
          activity.state = stateCode;
          if (isDeleted == true) {
            activity.deleted = true;
          }
          await this.activityService.upsertMeetingsOfflineData(activity);
          console.log('updateMeetingStatus offline update DB success: ');
          if (MeetingStatus.COMPLETED === status) {
            await this.globalUtility.updateInteraction(activity.contacts, activity.accounts, 'Meetings');
          }
          return Promise.resolve();
        }
        catch (err) {
          return Promise.reject(err);
        }

      } else {
        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
          Endpoints.meeting.UPDATE_MEETING_STATUS.replace("{activity_id}", activity.ID);

        //Added indskr_meetingcontentreasonsid for No Detailing in a Meeting
        let requestBody = {
          statuscode: status,
          omnip_datecompleted: dateCompleted,
          subject: subject,
          indskr_meetingcontentreasonsid: activity.indskr_meetingcontentreasonsid,
          scheduledstart: activity.scheduledStart?.getTime(),
          scheduledend: activity.scheduledEnd?.getTime(),
          indskr_reasonforcancellation: activity.indskr_reasonforcancellation,
          indskr_cancellationreasonother: activity.indskr_cancellationreasonother
        };
        if (extraPayload) {
          requestBody = { ...requestBody, ...extraPayload }
        }
        if (selfClaim) {
          requestBody['indskr_selfclaimbyuseronappttimemodification'] = selfClaim;
        }
        try {
          const headers = Endpoints.headers.content_type.json;
          if (!bypassRetroLimitCheck) {
            const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions();
            if (timeZone) {
              headers.headers = headers.headers.set(
                'X-Zone-Id',
                timeZone,
              );
            }
            const retroactiveMeetingCompletionLimitInDays = !isNaN(this.authenticationService.user.buConfigs['indskr_retroactivemeetingcompletionlimitindays'])
              ? this.authenticationService.user.buConfigs['indskr_retroactivemeetingcompletionlimitindays']
              : null;
            const retroactiveMeetingCompletionLimitInHours = !isNaN(this.authenticationService.user.buConfigs['indskr_retroactivemeetingcompletionlimit'])
              ? this.authenticationService.user.buConfigs['indskr_retroactivemeetingcompletionlimit']
              : null;
            if (
              retroactiveMeetingCompletionLimitInDays !== null
              && retroactiveMeetingCompletionLimitInDays >= 0
            ) {
              url = url + '?retroLimit=' + retroactiveMeetingCompletionLimitInDays;
              if (selfClaim) {
                url += '&selfClaim=' + true;
              }
            } else if (
              retroactiveMeetingCompletionLimitInHours !== null
              && retroactiveMeetingCompletionLimitInHours > 0
            ) {
              url = url + '?retroLimitInHours=' + retroactiveMeetingCompletionLimitInHours;
              if (selfClaim) {
                url += '&selfClaim=' + true;
              }
            } else if (selfClaim) {
              url += '?selfClaim=' + true;
            }
          } else {
            if (selfClaim) {
              url += '?selfClaim=' + true;
            }
          }

          await this.http.put(url, requestBody, headers).toPromise();

          if (MeetingStatus.COMPLETED === status) {
            await this.globalUtility.updateInteraction(activity.contacts, activity.accounts, 'Meetings');
          }
          let activityToUpdate = this.activityService.getActivityByID(activity.ID);

          if (!activityToUpdate) {
            console.error('updateMeetingStatus: ', activity);
            activityToUpdate = activity;
          }

          activityToUpdate.state = stateCode;     //update activities array
          if (status === MeetingStatus.OPEN) {
            activityToUpdate.status = 1;
          }
          if (status === MeetingStatus.CANCELED) {
            activityToUpdate.status = 4;
          }
          if (dateCompleted) {
            activityToUpdate.omnip_datecompleted = dateCompleted.toString();
            activityToUpdate.omnip_datecompleted = activity.omnip_datecompleted = dateCompleted.toString();
            this.activityService.selectedActivity.subject = subject;
          }

          console.log('updateMeetingStatus: update service success: ');
          try {
            activity.state = stateCode;
            await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity, true);
            console.log('updateMeetingStatus: update DB success: ');
          } catch (error) {
            console.warn('updateMeetingStatus: db update error: ', error);
            // TODO
          }
          return Promise.resolve();
        }
        catch (err) {
          console.error('updateMeetingStatus: ', err);

          // Meeting completion date validation error. Throw to upper caller
          const errorInfo = (err.hasOwnProperty('error')) ? err.error : err;
          if (errorInfo?.errorCode?.includes('ERR_IO_ME')) {
            return Promise.reject(err);
          }
          // if network call failed
          let activityToUpdate = this.activityService.getActivityByID(activity.ID);

          if (dateCompleted) {
            activityToUpdate.omnip_datecompleted = dateCompleted.toString();
            activityToUpdate.omnip_datecompleted = activity.omnip_datecompleted = dateCompleted.toString();
            this.activityService.selectedActivity.subject = subject;
          }

          activityToUpdate.state = stateCode;     //update activities array
          if (status === MeetingStatus.COMPLETED) {
            activityToUpdate.status = 1;
          }

          this.activityService.upsertMeetingsOfflineData(activity)
          return Promise.reject(err);
        }
      }
    }
  }

  public getMeetingSubject(activity) {
    let subject: string = this.translate.instant('MEETING');
    let subPrefix = ` - ${subject}`
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) { //added for auto subject name suffixed with rep's name if she has auto subject FA.
      subPrefix = ' - '.concat([subject, activity.activityTypeName, this.authenticationService.user.displayName].filter(Boolean).join(' - '));
    }
    const isAcinoBaseUser = this.authenticationService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
    if (isAcinoBaseUser) {
      subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}`+this.translate.instant('VISIT');
    }
    switch (activity.contacts.length) {
      case 0:
        subject = activity.subject
        break;
      case 1:
        subject = (activity.contacts[0].fullname ? activity.contacts[0].fullname : ((!isEmpty(activity.contacts[0].firstName)) ? this.contactService.getFullNameForContactDisplaySequence(activity.contacts[0]) : activity.contacts[0].lastName)) + subPrefix;
        if (isAcinoBaseUser) {
          subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}`+this.translate.instant('VISIT') + ` - ${(!isEmpty(activity.contacts[0].firstName)) ? this.contactService.getFullNameForContactDisplaySequence(activity.contacts[0]) : activity.contacts[0].lastName}`;
        }
        break;
      default:
        if (activity.contacts.length > 1) {
          activity.contacts.sort((contactA, contactB): number => {
            let contactAFullName =  activity.contacts[0].fullname ? activity.contacts[0].fullname : (!isEmpty(contactA.firstName)) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
            let contactBFullName = activity.contacts[0].fullname ? activity.contacts[0].fullname : (!isEmpty(contactA.firstName)) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
            if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase())
              return 1;
            if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase())
              return -1;

            return 0;
          });
          subject =(activity.contacts[0].fullname ? activity.contacts[0].fullname :((!isEmpty(activity.contacts[0].firstName)) ? this.contactService.getFullNameForContactDisplaySequence(activity.contacts[0]) : activity.contacts[0].lastName)) + ' + ' + (activity.contacts.length - 1) + subPrefix;
          if (isAcinoBaseUser) {
            subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}`+this.translate.instant('VISIT') + ` - ${activity.contacts[0].fullName ? activity.contacts[0].fullName : (!isEmpty(activity.contacts[0].firstName)) ? this.contactService.getFullNameForContactDisplaySequence(activity.contacts[0]) : activity.contacts[0].lastName}`;
          }
        }
    }
    return subject;
  }

  public getActivityStateCode(status: MeetingStatus) {
    switch (status) {
      case MeetingStatus.OPEN:
        return 0;
      case MeetingStatus.COMPLETED:
        return 1;
      case MeetingStatus.CANCELED:
        return 2;
      case MeetingStatus.SCHEDULED:
        return 3;
      default:
        return 0;
    }
  }


  /**
   * Updates a meetings status
   *
   * @param {AppointmentActivity} activity
   * @param {MeetingStatus} status
   * @memberof MeetingDataService
   */
  // public async updateMeetingStatusOld(
  //   activity: AppointmentActivity,
  //   status: MeetingStatus,
  //   dateCompleted?: number
  // ) {
  //   let oldStatus = activity.state;
  //   const foundActivity = this.activityService.getActivityByID(activity.ID);
  //   switch (status) {
  //     case MeetingStatus.OPEN:
  //       activity.state = 0;
  //       foundActivity.state = 0;
  //       break;

  //     case MeetingStatus.COMPLETED:
  //       activity.state = 1;
  //       foundActivity.state = 1;
  //       break;

  //     case MeetingStatus.CANCELED:
  //       activity.state = 2;
  //       foundActivity.state = 2;
  //       break;

  //     case MeetingStatus.SCHEDULED:
  //       activity.state = 3;
  //       foundActivity.state = 3;
  //       break;

  //     default:
  //       console.error(
  //         "Unhandled switch case for updating meeting status",
  //         status
  //       );
  //   }

  //   if(!this.deviceService.isOffline){
  //     let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
  //       Endpoints.meeting.UPDATE_MEETING_STATUS.replace(
  //         "{activity_id}",
  //         activity.ID
  //       );

  //     let requestBody = { statuscode: status, omnip_datecompleted: dateCompleted };

  //     try {
  //       let response = await this.http
  //       .put(url, requestBody, Endpoints.headers.content_type.json)
  //       .toPromise();

  //       try {
  //         await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity, true);
  //         //await this.disk.updateOrInsertMeetingToBulkRawDocument(activity);
  //       } catch (error) {
  //         console.warn('updateMeetingStatus: db update error: ', error);
  //         // TODO
  //       }

  //       return Promise.resolve();
  //     } catch (httpError) {
  //       console.error('updateMeetingStatus: httpError: ', httpError);
  //       // Real error since it failed to update server.
  //       // Revert
  //       activity.state = foundActivity.state = oldStatus;
  //       return Promise.reject('');
  //     }
  //   }
  //   else{
  //     try {
  //       await this.activityService.upsertMeetingsOfflineData(activity);
  //     } catch (error) {
  //       console.error('updateMeetingStatus: offline update error: ', error);
  //       // Real error since it failed to update offline db.
  //       // Revert
  //       activity.state = foundActivity.state = oldStatus;
  //       return Promise.reject('');
  //     }
  //   }
  // }

  /**
   * Endpoint access to associate accounts to an appointment activity
   *
   * @param {AppointmentActivity} activity
   * @memberof MeetingDataService
   */
  public async addAccountsToMeeting(activity: AppointmentActivity) {
    // ! need to add try catch block and in catch block revert account selection if GPS FA enabled

    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.meeting.ADD_ACCOUNTS_TO_MEETING.replace(
        "{activity_id}",
        activity.ID
      );
    let payload = [];

    activity.accounts.map(account => {
      payload.push({
        indskr_accountid: account.id,
        indskr_name: account.accountName
      });
    });

    let response = await this.http.put(url, payload).toPromise();
    console.log(response);
    for (let account of activity.accounts) {
      if (account.isUsedInOneTimeMeeting) {
        this.dynamics.dwa.updateSingleProperty(account.id, "accounts", { omniveev_isusedinonetimemeeting: true });
      }
    }
  }

  public async addOpportunitiesToMeeting(activity: AppointmentActivity, payload: any) {
    // Handle update to offline database
    const hasOfflineData = this.activityService.hasOfflineMeetingData(activity.ID);
    if (!this.deviceService.isOffline && !hasOfflineData) {
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
        Endpoints.meeting.ADD_OPPORTUNITIES_TO_MEETING.replace(
          "{activity_id}",
          activity.ID
        );
      let response = await this.http.put(url, payload).toPromise();
      console.log(response);
      if (response) {
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity as AppointmentActivity, true);
        return true;
      }
    } else {
      if (payload) {
        await this.activityService.upsertMeetingsOfflineData(activity as AppointmentActivity);
      }
    }
  }

  public async addAccountPlansToMeeting(activity: AppointmentActivity, payload: any) {
    // Handle update to offline database
    const hasOfflineData = this.activityService.hasOfflineMeetingData(activity.ID);
    if (!this.deviceService.isOffline && !hasOfflineData) {
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
        Endpoints.meeting.ADD_ACCOUNTPLANS_TO_MEETING.replace(
          "{activity_id}",
          activity.ID
        );
      let response = await this.http.put(url, payload).toPromise();
      console.log(response);
      if (response) {
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity as AppointmentActivity, true);
        return true;
      }
    } else {
      if (payload) {
        await this.activityService.upsertMeetingsOfflineData(activity as AppointmentActivity);
      }
    }
  }

  public async addEventsToMeeting(activity: AppointmentActivity, payload: any) {
    // Handle update to offline database
    const hasOfflineData = this.activityService.hasOfflineMeetingData(activity.ID);
    if (!this.deviceService.isOffline && !hasOfflineData) {
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
        Endpoints.meeting.ADD_EVENTS_TO_MEETING.replace(
          "{activity_id}",
          activity.ID
        );
      let response = await this.http.put(url, payload).toPromise();
      console.log(response);
      if (response) {
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity as AppointmentActivity, true);
        return true;
      }
    } else {
      if (payload) {
        await this.activityService.upsertMeetingsOfflineData(activity as AppointmentActivity);
      }
    }
  }


  /**
   * Updates the appointment activity with a new presentation
   *
   * @param {Presentation} presentation
   * @param {AppointmentActivity} activity
   * @memberof MeetingDataService
   */
  public async addContentToMeeting(
    content: Array<Presentation | Resource>,
    activity: AppointmentActivity,
    updateContentToActivity = false,
  ) {
    console.log("service call add presentation and resources to meeting");

    //If offline, add to the activity object and updateOfflineMeeting on disk
    //Fuck it save offline anyways, takes a ms and loads faster


    //ToDO: Save resources in db and dynamics
    console.log("***** Meeting content  ", content);

    if (this.deviceService.isOffline || this.activityService.hasOfflineMeetingData(activity.ID)) {
      if (updateContentToActivity) {
        this.activityService.selectedActivity['presentations'] = content;
      }
      this.activityService.upsertMeetingsOfflineData(activity);
      return;
    }

    let headers = new HttpHeaders();
    headers = headers.set("X-PositionId", this.authenticationService.user.xPositionID);
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.meeting.ADD_PRESENTATION_TO_MEETING.replace(
        "{activity_id}",
        activity.ID
      );
    let presDtoArray = [];
    for (let pres of content) {
      presDtoArray.push(pres.DTO);
    }

    try {
      await this.http.put(url, presDtoArray, { headers }).toPromise();
      await this.activityService.upsertMeetingsOfflineData(activity, false, false)
    } catch (error) {
      console.log('addContentToMeeting: ', error);
      this.activityService.upsertMeetingsOfflineData(activity, false, true);
    } finally {
      this.events.publish('contentIsAdded', content);
      if (updateContentToActivity) {
        this.activityService.selectedActivity['presentations'] = content;
      }
    }

  }
  /**
   * @shouldInsertActivityInAgenda - default true to add the activity to agenda. It can be false if activity is created form Tools 
   * Agenda can be refreshed on Tool's master page close 
   */
  async createNewMeeting(payload: InitiateMeetingPayload, shouldWaitForMeetingCreationResponse = false, shouldInsertActivityInAgenda = true, 
    contactFullName = null, isFromClone: boolean = false): Promise<AppointmentActivity | undefined> {
    const reqBody = payload.getRequestBody();

    let newMeeting: AppointmentActivity = undefined;
    const rawNewMeeting = payload.getRequestBody();

    rawNewMeeting['indskr_positionid'] = this.authenticationService.user.xPositionID;
    rawNewMeeting['statecode'] = 0;
    rawNewMeeting['statuscode'] = 1;
    rawNewMeeting['activityid'] = payload.offlineMeetingId;
    rawNewMeeting['indskr_ownerid'] = this.authenticationService.user.systemUserID;
    rawNewMeeting['indskr_ownerfullname'] = this.authenticationService.user.displayName;
    rawNewMeeting['eventId'] = payload.eventId;
    const activityTypes = this.activityService.configuredActivityTypes(MeetingActivityTypeCode.MEETING, FormatType.HCP_MEETING);
    if (!isEmpty(activityTypes)) {
      this.logService.logDebug("Creating meeting with activity type");
      const defaultActivityType = reqBody["indskr_kitbooking"] && !!activityTypes.find(at => at.indskr_defaultformatforkitbooking) ? activityTypes.find(at => at.indskr_defaultformatforkitbooking) : activityTypes.length == 1 && activityTypes[0].indskr_mandatory ? activityTypes[0] : activityTypes.find(at => at.indskr_default);
      if (!isEmpty(defaultActivityType)) {
        reqBody["indskr_activitytype"] = rawNewMeeting["indskr_activitytype"] = defaultActivityType.indskr_activitytypeid;
        reqBody["activityTypeName"] = rawNewMeeting["activityTypeName"] = defaultActivityType.indskr_name;
        if (isFromClone) {
          reqBody["subject"] = rawNewMeeting["subject"];
        } else {
          if (payload.shortCall) {
            reqBody["subject"] = rawNewMeeting["subject"] = `${reqBody.subject} - ${defaultActivityType.indskr_name} - ${this.authenticationService.user.displayName}`;
          }
          else {
            if (this.authenticationService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT)) {
              reqBody["subject"] = rawNewMeeting["subject"] = `${defaultActivityType.indskr_name} ${this.translate.instant('VISIT')}`;
            } 
            // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
            else if (this.secondaryInfoService.isAppointmentSecInfoConfigured) {
              reqBody["subject"] = rawNewMeeting["subject"] = `${this.translate.instant('MEETING')}`;
            }
            // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
            else if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
              reqBody["subject"] = rawNewMeeting["subject"] = `${this.translate.instant('MEETING')} - ${defaultActivityType.indskr_name} - ${rawNewMeeting['indskr_ownerfullname']}`;
            }  else {
              reqBody["subject"] = rawNewMeeting["subject"] = `${this.translate.instant('MEETING')} - ${defaultActivityType.indskr_name} - ${rawNewMeeting['indskr_ownerfullname']}`;
              if (contactFullName) {
                reqBody["subject"] = rawNewMeeting["subject"] = `${contactFullName} - ${rawNewMeeting['subject']}`;
              }
            }
          }
        }
        const activitySubTypes = this.activityService.getActivitySubTypesByActivityType(MeetingActivityTypeCode.MEETING, defaultActivityType.indskr_activitytypeid);
        if (!isEmpty(activitySubTypes)) {
          const defaultActivitySubType = activitySubTypes.length == 1 ? activitySubTypes[0] : activitySubTypes.find(ast => ast.indskr_default);
          if (!isEmpty(defaultActivitySubType)) {
            reqBody["indskr_activitysubtype"] = rawNewMeeting["indskr_activitysubtype"] = defaultActivitySubType.indskr_activitysubtypeid;
            reqBody["activitySubTypeName"] = rawNewMeeting["activitySubTypeName"] = defaultActivitySubType.indskr_name;
          }
        }
      }
    }

    if (this.deviceService.isNativeApp) {

      //[OMNI-6296]Pass Phone Call ActivityId to link to activity ID while creating meeting from Dynamics Call Center
      this.activityService.contactCenterActivityInputs$.subscribe((value) => {
        if (value && value.hasOwnProperty('phoneCallId')) {
          reqBody['phoneCallId'] = value['phoneCallId'];
          if (value.hasOwnProperty('contactId')) {
            //Add contact to new appointment activity
            let contact = this.contactService.getContactByID(
              value['contactId']
            );
            if (contact) {
              reqBody.subject = `${this.contactService.getFullNameForContactDisplaySequence(contact)} - Meeting`;
              this.activityService.contactCenterContactInputs$.next(contact);
            }
          }
        }
      }).unsubscribe();
      this.activityService.contactCenterActivityInputs$.next(null);

      // App flow
      if (!shouldWaitForMeetingCreationResponse) {
        // Don't block UI for creation request
        try {
          const res: MeetingCreateResponseDTO = await this._createNewMeetingReq(reqBody);
          if (payload.eventId)
            await this.msEventService.associateEvent(payload.eventId, res.activityId).then(() => res).catch(e => res);
          // upsert offline data
          rawNewMeeting['indskr_isofflinemeeting'] = true;
          newMeeting = new (rawNewMeeting.location === 'LiveMeet' ? LiveMeetActivity : AppointmentActivity)(rawNewMeeting);
          const success: boolean = await this.activityService.upsertMeetingsOfflineData(newMeeting);
          if (!success) return;
          if (shouldInsertActivityInAgenda) this.events.publish('insertNewActivity', newMeeting);
          // creation response is back from server.
          if (res) {
            await this._handleMeetingCreationResponse(res, newMeeting);
          }
        } catch (httpError) {
          console.error('!shouldWaitForMeetingCreationResponse: createNewMeeting: ', httpError);
          const errorInfo = (httpError.hasOwnProperty('error')) ? httpError.error : httpError;
          if (errorInfo.errorCode == "ERR_IO_ME12") {
            this.isErrorStartDateCreateMeeting = true;
            this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_CAN_NOT_SCHEDULE_MEETING', { maxMeetingStartDays: this.authenticationService.user.maxMeetingStartDays }), 'Date Time Picker', 'top', ToastStyle.DANGER);
            return;
          } else {
            // upsert offline data
            rawNewMeeting['indskr_isofflinemeeting'] = true;
            newMeeting = new (rawNewMeeting.location === 'LiveMeet' ? LiveMeetActivity : AppointmentActivity)(rawNewMeeting);
            const success: boolean = await this.activityService.upsertMeetingsOfflineData(newMeeting);
            if (!success) return;
            if (shouldInsertActivityInAgenda) this.events.publish('insertNewActivity', newMeeting);
          }
        }
      } else {
        // Wait for the response
        try {
          const response: MeetingCreateResponseDTO = await this._createNewMeetingReq(reqBody);
          rawNewMeeting['indskr_isofflinemeeting'] = true;
          newMeeting = new (rawNewMeeting.location === 'LiveMeet' ? LiveMeetActivity : AppointmentActivity)(rawNewMeeting);
          const success: boolean = await this.activityService.upsertMeetingsOfflineData(newMeeting);
          if (!success) return;
          if (shouldInsertActivityInAgenda) this.events.publish('insertNewActivity', newMeeting);
          if (response) {
            await this._handleMeetingCreationResponse(response, newMeeting);
          }
        } catch (httpError) {
          console.error('createNewMeeting: ', httpError);
          const errorInfo = (httpError.hasOwnProperty('error')) ? httpError.error : httpError;
          if (errorInfo.errorCode == "ERR_IO_ME12") {
            this.isErrorStartDateCreateMeeting = true;
            this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_CAN_NOT_SCHEDULE_MEETING', { maxMeetingStartDays: this.authenticationService.user.maxMeetingStartDays }), 'Date Time Picker', 'top', ToastStyle.DANGER);
            return;
          } else {
            // upsert offline data
            rawNewMeeting['indskr_isofflinemeeting'] = true;
            newMeeting = new (rawNewMeeting.location === 'LiveMeet' ? LiveMeetActivity : AppointmentActivity)(rawNewMeeting);
            if (shouldInsertActivityInAgenda) this.events.publish('insertNewActivity', newMeeting);
            const success: boolean = await this.activityService.upsertMeetingsOfflineData(newMeeting);
            if (!success) return;
          }
        }
      }
    } else {
      // Non app flow (Assume there's no offline document since we're heading towards it)
      try {
        // Block UI for non app flow
        const response: MeetingCreateResponseDTO = await this._createNewMeetingReq(reqBody);
        if (!this.deviceService.isOffline && this.authenticationService.hasFeatureAction(FeatureActionsMap.TEAMS_MEETING)) {
          this.createTeamsMeeting(reqBody, response.activityId);
        }
        if (response && response.activityId) {
          rawNewMeeting['indskr_meetingurl'] = response.indskr_meetingurl;
          rawNewMeeting['activityid'] = response.activityId;
          rawNewMeeting['lastUpdatedTime'] = new Date().getTime();
          rawNewMeeting['_id'] = DB_KEY_PREFIXES.MEETING_ACTIVITY + response.activityId;

          newMeeting = new AppointmentActivity(rawNewMeeting);
          // Event pub sub takes up quite time during meeting creation flow. Publishing as early as possible..
          if (shouldInsertActivityInAgenda) this.events.publish('insertNewActivity', newMeeting);
          // Subscribe to ws
          this.events.publish('ws:subscribeToMeetingTopic', newMeeting);
        }
      } catch (httpError) {
        console.error('createNewMeeting: ', httpError);
        const errorInfo = (httpError.hasOwnProperty('error')) ? httpError.error : httpError;
        if (errorInfo.errorCode == "ERR_IO_ME12") {
          this.isErrorStartDateCreateMeeting = true;
          this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_CAN_NOT_SCHEDULE_MEETING', { maxMeetingStartDays: this.authenticationService.user.maxMeetingStartDays }), 'Date Time Picker', 'top', ToastStyle.DANGER);
          return;
        }

      }
    }

    this.activityService.addActivity(newMeeting);
    return newMeeting;
  }

  private createTeamsMeeting(reqBody, activityId) {
    const teamsConfig = this.authenticationService.userConfig.teamsConfig;
    const crmInstance = this.authenticationService.userConfig.activeInstance;
    const teamsMeetingPayload = {
      startDateTime: reqBody.scheduledstart,
      endDateTime: reqBody.scheduledend,
      subject: reqBody.subject,
      contentUrl: teamsConfig.contentUrl,
      teamsAppID: teamsConfig.teamsCustomAppId,
      meetingId: activityId,
      dynamicsInstance: crmInstance.url,
      entryPointUrl: crmInstance.entryPointUrl
    }
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('X-MS-Graph-Token', 'true');
    const url: string = crmInstance.entryPointUrl + Endpoints.activites.TEAM_MEETING_ACTIVITY;
    this.http.post(url, teamsMeetingPayload, { headers: headers }).toPromise().then(async (data: any) => {
      console.log(data)
      if (this.activityService.selectedActivity?.ID == activityId) {
        let activity: AppointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
        activity.indskr_teamslink = data.joinUrl;
        activity.indskr_teams_meeting_id = data.id;
        await this.dynamics.update(activityId, "appointments", { indskr_teamslink: data.joinUrl, indskr_teams_meeting_id: data.id });
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity, true);
        this.events.publish("detectChangesOnActivityDetails");
      }
    });
  }

  private async _createNewMeetingReq(reqBody: any): Promise<MeetingCreateResponseDTO> {
    if (this.deviceService.isOffline) {
      return;
    }
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.INITIATE_MEETING;
    const headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
    headers.headers = headers.headers.set(
      'X-BusinessUnitId',
      this.authenticationService.user.xBusinessUnitId
    ).set(
      "X-PositionId",
      this.authenticationService.user.xPositionID
    );
    const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions();
    if (timeZone) {
      headers.headers = headers.headers.set(
        'X-Zone-Id',
        timeZone,
      );
    }
    const pastActivityGracePeriod: string = !this.deviceService.isOffline ? (this.authenticationService.user.maxMeetingStartDays).toString() : '';
    let headerAndPrarms = {
      ...headers
    }
    return await this.http.post<MeetingCreateResponseDTO>(url, reqBody, headerAndPrarms).toPromise();
  }

  public async uploadPhotosToBlobStorgae(payload: Photo[]) {
    const activity = this.activityService.selectedActivity as AppointmentActivity;
    const createdAt = new Date().getTime();
    payload = payload.map((image) => {
      const base64String = image.base64String.replace(/^data:image\/\w+;base64,/, '');
      return { ...image, base64String, createdAt: createdAt };
    });
    try {
      const headers = Endpoints.authentication.AUTHENTICATE_USER_STATUS;
      const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.photos.UPLOAD_PHOTOS;
      const photosUploaded: UploadedPhoto[] = await this.http.post<UploadedPhoto[]>(url, payload, headers).toPromise();
      await this.updateInStorePhotos(photosUploaded.map(photo => { return { indskr_photoattachmentid: photo.photoId, photoAttachmentId: photo.photoId, indskr_photourl: photo.photoUrl, ...photo } }));
    } catch (error) {
      console.error("Failed to upload photos: ", error);
    }
  }

  public async updateInStorePhotos(payload: any[]) {
    try {
      const activity = this.activityService.selectedActivity as AppointmentActivity;
      const headers = Endpoints.authentication.AUTHENTICATE_USER_STATUS;
      const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_INSTORE_PHOTOS.replace("{activityId}", activity.ID);
      await this.http.put<any>(url, payload, headers).toPromise();
      if (_.isEmpty(activity.inStorePhotos)) {
        activity.inStorePhotos = payload.filter(req => !req['deleted']);
      } else {
        const deletedIds = payload.filter(req => req['deleted']).map(req => req['photoAttachmentId']);
        activity.inStorePhotos = activity.inStorePhotos.filter(photo => !deletedIds.includes(photo.indskr_photoattachmentid));
        activity.inStorePhotos.push(...payload.filter(req => !req['deleted']).map(photo => { return { createdOn: photo['createdAt'], ...photo } }));
      }
      await this.activityService.upsertMeetingsOfflineData(activity);
    } catch (error) {
      console.error("Failed to upload updateInStorePhotos: ", error);
    }
  }

  private async _handleMeetingCreationResponse(res: MeetingCreateResponseDTO, activity: AppointmentActivity, noAsyncFlow = false) {
    // Updates object and offline doc, and creates a meeting doc.
    if (res.activityId && res.offlineMeetingId === activity.offlineMeetingId) {
      activity.meetingURL = res.indskr_meetingurl;
      activity.ID = res.activityId;
      activity.lastUpdatedTime = new Date().getTime();
      activity.isOfflineMeeting = false;
      this.activityService.contactCenterContactInputs$.subscribe((value) => {
        if (value) {
          activity.contacts.push(value);
          this.activityService.selectedActivity = activity;
          this.addContactsToMeeting(activity);
          this.events.publish("detectChangesOnActivityDetails"); // refresh meeting structure
          if (!this.uiService.toolsActivityActive) {
            this.events.publish('refreshAgenda');
          } else this.uiService.agendaRefreshRequired = true;
        }
      }).unsubscribe();
      this.activityService.contactCenterContactInputs$.next(null);

      const _id = activity._id = DB_KEY_PREFIXES.MEETING_ACTIVITY + res.activityId;

      if (!noAsyncFlow) {
        // Subscribe to ws
        this.events.publish('ws:subscribeToMeetingTopic', activity);
      }

      // Handle offline doc
      try {
        await this.disk.updateOrInsert('offlineMeetings', (offlineMeetingsDoc: { meetings: any[] }) => {
          if (!offlineMeetingsDoc || !Array.isArray(offlineMeetingsDoc.meetings)) {
            console.warn('_handleMeetingCreationResponse: offline meeting document is empty..');
            offlineMeetingsDoc = { meetings: [] };
          } else {
            const idx = offlineMeetingsDoc.meetings.findIndex(m => m.offlineMeetingId === res.offlineMeetingId);
            if (idx >= 0) {
              const rawOfflineMeeting = offlineMeetingsDoc.meetings[idx];
              if (rawOfflineMeeting.hasOfflineChange) {
                // Something changed while waiting for the meeting creation response. Update offline doc and treat as offline doc
                rawOfflineMeeting['indskr_meetingurl'] = activity.meetingURL;
                rawOfflineMeeting['activityid'] = activity.ID;
                rawOfflineMeeting['lastUpdatedTime'] = activity.lastUpdatedTime;
                rawOfflineMeeting['indskr_isofflinemeeting'] = false;
              } else {
                // Nothing changed while waiting for the meeting creation response. Remove from offline doc
                this.activityService.deleteFromOfflineMeetingIds(activity.offlineMeetingId);
                offlineMeetingsDoc.meetings.splice(idx, 1);
              }
            } else {
              console.warn(`_handleMeetingCreationResponse: couldn't find ${res.offlineMeetingId} from offline document`);
            }
          }

          // Track offline data count
          this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING, offlineMeetingsDoc.meetings.length);
          return offlineMeetingsDoc;
        });
      } catch (error) {
        console.error('_handleMeetingCreationResponse: ', error);
      }

      // Create a meeting record
      try {
        await this.disk.createDocument(_id, activity.DTO);
      } catch (error) {
        console.error('_handleMeetingCreationResponse: createDocument: ', error);
      }

      // Stream to other pages
      this.uiService.setUIServiceData({
        view: "new-activity", data: {}
      });
    } else {
      console.warn(`_handleMeetingCreationResponse: offline meeting ID ${activity.offlineMeetingId} does not match with ${res.offlineMeetingId}`);
    }
  }


  public addGuestToMeetingContacts(guest, isInboundFlow = false) {
    console.log(guest);
    let isContact = this.contactService.getContactByID(guest['indskr_contactid']);
    if (!isContact) {
      let guestName = guest.indskr_name.split(' ');
      let lastName = guestName.splice(1)
      let rawObj = {
        'contactid': guest['indskr_contactid'],
        'firstname': guestName[0],
        'lastname' : lastName,
        'indskr_joinstatus': guest['indskr_joinstatus'],
        'indskr_isguest': guest['indskr_isguest'],
        'indskr_joineddate': guest['indskr_joineddate'],
        'indskr_leftdate': guest['indskr_leftdate'],
        'indskr_isremote': guest['indskr_isremote'] || true,
        'indskr_leftmanually': guest['indskr_leftmanually'] || false,
        'indskr_sequence': guest['indskr_sequence'] || 0,
        'statuscode': guest['statuscode'] || 1
      }

      let contact = new Contact(rawObj);
      if (!isInboundFlow) {
        // Only add for remote meeting.
        // This is not to break existing remote meeting feature. Clean up needs to be done.
        this.contactService.contacts.push(contact);
        //this.contactDataService.addContactToOfflineContacts(contact.rowDTO);
        this.contactService.upsertRawContactToLocalDB(rawObj, false, true);
      }
      (this.activityService.selectedActivity['contacts']) ? this.activityService.selectedActivity['contacts'].push(contact) : undefined;
      this.events.publish("refreshSeletecContacts");
      // Commenting this below line for OMNI-17393 (On the contact tool, the contact count does not increase)
      //this.events.publish('updateSelectedActivityDetails');
    }
  }


  public async uploadInteractiveHTMLEvent() {
    //If offline, ignore
    if (this.deviceService.isOffline) return;

    let offlineEvents = await this.disk.retrieve('ihtml_events', true);

    if (offlineEvents && offlineEvents.events) {

      offlineEvents.events.map(async event => {
        let firstEvent = event;

        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
          Endpoints.ihtml.UPLOAD_TRACKING;

        //Replace important variables in url
        url = url.replace('{activityId}', firstEvent.IDs.activity);
        url = url.replace('{slideId}', firstEvent.IDs.slide);
        url = url.replace('{presentationId}', firstEvent.IDs.presentation);


        let response = await this.http.post(url, offlineEvents.events).toPromise();
        console.log(response);
      });



      this.disk.remove('ihtml_events');
    }
  }

  public async addRemoveAccompaniedUsers(raw: AccompainedUser[]) {
    //For UI Loading perspective
    //this.activityDataService.activityDetailsLoaded = false;
    //online case
    if (!this.deviceService.isOffline) {
      //online activity registered on Dynamics
      if (!this.activityService.selectedActivity.ID.includes('offline_')) {
        try {
          const res = await this.activityDataService.addAccompaniedUser(raw, this.activityService.selectedActivity.ID).toPromise();
          await this.activityService.setAccompaniedUser(raw);
          await this.storeOfflineJointMeeting(raw);
          //this.activityDataService.activityDetailsLoaded = true;
          this.activityService.notifyCalendarInviteUpdate(this.activityService.selectedActivity as AppointmentActivity);
        } catch (error) {
          //someerror occured
          //proceed for offline saving data
          console.error("addRemoveAccompaniedUsers: ", error);
          //this.activityDataService.activityDetailsLoaded = true;
          await this.storeOfflineJointMeeting(raw);
          //To clear the selection data for next cycle
        }
      }
      //offline id detected, data to be stored for delta sync in disk service
      else {
        await this.storeOfflineJointMeeting(raw);
      }
    }
    //device set to offline
    else {
      // logic for offline saving the data
      this.activityDataService.activityDetailsLoaded = true;
      await this.storeOfflineJointMeeting(raw);
      //To clear the selection data for next cycle
    }
  }

  public async updateUserNotes() {

  }

  //common logic for storing offline joint meeting data to disk service for delta sync
  private async storeOfflineJointMeeting(raw: AccompainedUser[]) {
    let activity: any = this.activityService.selectedActivity;
    await this.activityService.setAccompaniedUser(raw);
    console.log("offline joint meeting detected, initiating storage for data");
    try {
      await this.activityService.upsertMeetingsOfflineData(activity);
    } catch (error) {
      //return Promise.reject('');
    }
  }

  public async createMeetingFromPresentation(pres: Presentation[]) {

    //Going to need a payload for initiation
    let startTime, endTime;
    startTime = new Date();
    endTime = new Date();
    let meetingDuration = this.authenticationService.user.meeting_duration || 30;
    endTime = addMinutes(startTime, meetingDuration);

    let payload: InitiateMeetingPayload = new InitiateMeetingPayload(
      "Meeting",
      "",
      startTime.getTime(),
      endTime.getTime(),
      "",
      OFFLINE_ID_PREFIX + new Date().getTime()
    );

    try {
      if (this.authenticationService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT)) {
        payload["subject"] = `${this.translate.instant("VISIT")}`;
      } else {
        payload["subject"] = this.translate.instant('MEETING') + ' - ' + this.authenticationService.user.displayName;
      }
      let newMeeting = await this.createNewMeeting(payload, true, false);

      if (newMeeting) {
        let data;
        if (!newMeeting.createdOffline) {
          // Online created meeting
          data = { id: newMeeting.ID, ...payload }
        } else {
          // Offline created meeting
          data = {
            id: newMeeting.ID,
            subject: newMeeting.subject,
            location: newMeeting.location,
            scheduledStart: newMeeting.scheduledStart,
            scheduledEnd: newMeeting.scheduledEnd,
          };
        }

        this.uiService.setUIServiceData({
          view: "new-activity", data,
        }); // this is an observable stream

        this.activityService.selectedActivityAtHome = this.activityService.selectedActivity = newMeeting;
        this.uiService.showRightPane = true;
        this.events.publish('initActivityKeyMessages');
        if (pres) {

          if (this.activityService.selectedActivity instanceof AppointmentActivity) {
            if (!this.activityService.selectedActivity['presentations']) this.activityService.selectedActivity['presentations'] = [];
            pres.forEach(p => {
              p.isAutoLoaded = false;
              this.activityService.selectedActivity['presentations'].push(p);
            });

            if (!this.deviceService.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
              try {
                await this.addContentToMeeting(this.activityService.selectedActivity.presentations, this.activityService.selectedActivity)
              } catch (error) {
                this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity, false, true)
                  .catch(err => this.notificationService.notify(this.translate.instant("ERROR_UPDATING_MEETING_PRESENTATIONS"), 'Meeting Data Service'));
              }
            } else {
              this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
            }
          }
        }
      }
      Promise.resolve();
    } catch (error) {
      Promise.reject(error);
    }

  }

  async sendMeetingInvite(activity: AppointmentActivity) {
    if (!activity) {
      console.error('sendMeetingInvite: Invalid activity provided', activity);
      throw new GeneralException(ErrorCode.E00, 'Invalid activity.');
    }
    if (activity && activity.isInviteSent === DynamicsBoolean.true) {
      console.error('sendMeetingInvite: Invite has been sent already', activity);
      throw new GeneralException(ErrorCode.E00, 'Invite has been sent already.');
    }
    if (this.deviceService.isOffline) {
      console.error('sendMeetingInvite: Device offline', activity);
      throw new GeneralException(ErrorCode.E01, 'Device is offline. Please try again later.');
    }
    if (this.activityService.hasOfflineMeetingData(activity.ID)) {
      console.error('sendMeetingInvite: There is local offline data', activity);
      throw new GeneralException(ErrorCode.E01, 'Meeting has un-synced local data. Please sync and try again later.');
    }

    // Online
    const url: string = (this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.SEND_MEETING_INVITE).replace('{activity_id}', activity.ID);
    let headers = new HttpHeaders();
    headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID);

    // Server upload
    try {
      const response = await this.http.patch(url, {}, { headers }).pipe(timeout(30000)).toPromise();
    } catch (error) {
      console.error('sendMeetingInvite: server upload: ', error);
      if (error instanceof HttpErrorResponse && error.error.errorCode === 'ERR_IO_ME11') {
        // Invite has been sent already
        throw new GeneralException(ErrorCode.E03, 'Invite has been sent already. Please run a manual sync and update agenda.');
      } else {
        throw new GeneralException(ErrorCode.E01, 'Failed to send invite. Please try again later.');
      }
    }

    // Local update
    try {
      activity.isInviteSent = DynamicsBoolean.true;
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity, true);
    } catch (error) {
      console.error('sendMeetingInvite: local update: ', error);
      throw new GeneralException(ErrorCode.E03, 'Invite has been sent but failed to update local data. Please run a manual sync.');
    }
  }

  async updateMeetingDescription(activity: AppointmentActivity, description: string) {
    if (!activity || description === undefined || description === null) {
      console.error('updateMeetingDescription: Invalid param(s) provided', activity, description);
      throw new GeneralException(ErrorCode.E00, 'Invalid input.');
    }

    if (this.deviceService.isOffline || this.activityService.hasOfflineMeetingData(activity.ID)) {
      // Offline
      try {
        activity.description = description;
        await this.activityService.upsertMeetingsOfflineData(activity);
      } catch (error) {
        console.error('updateMeetingDescription: ', error);
        throw new GeneralException(ErrorCode.E01, 'Failed to update description. Please try again later.');
      }
    } else {
      // Online
      const url: string = (this.authenticationService.userConfig.activeInstance.entryPointUrl
        + Endpoints.meeting.PATCH_MEETING).replace('{activity_id}', activity.ID);
      let headers = new HttpHeaders();
      headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID)

      // Server upload
      try {
        const response = await this.http.patch(url, { description }, { headers })
          .pipe(timeout(30000)).toPromise();
      } catch (error) {
        console.error('updateMeetingDescription: server upload: ', error);
        throw new GeneralException(
          ErrorCode.E01,
          'Failed to update description. Please try again later.'
        );
      }

      // Local update
      try {
        activity.description = description;
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity, true);
      } catch (error) {
        console.error('updateMeetingDescription: local update: ', error);
        throw new GeneralException(
          ErrorCode.E03,
          'Description has been updated but failed to update local data. Please run a manual sync.'
        );
      }
    }
  }

  async updateCovisitorComplianceAck(activity: AppointmentActivity, acked: boolean, description: string) {
    if (!activity || acked === undefined || acked === null) {
      console.error(
        'updateCovisitorComplianceAck: Invalid param(s) provided',
        activity,
        acked,
        description
      );
      throw new GeneralException(ErrorCode.E00, 'Invalid input');
    }

    const prevAcked: boolean = activity.covisitorComplianceAcked;
    const prevDescription: string = activity.covisitorComplianceDescription;

    activity.covisitorComplianceAcked = acked;
    activity.covisitorComplianceDescription = acked === true ? description : '';

    if (this.deviceService.isOffline || this.activityService.hasOfflineMeetingData(activity.ID)) {
      // Offline
      try {
        await this.activityService.upsertMeetingsOfflineData(activity);
      } catch (error) {
        console.error('updateCovisitorComplianceAck: ', error);
        activity.covisitorComplianceAcked = prevAcked;
        activity.covisitorComplianceDescription = prevDescription;

        const errorMsg: string = this.translate.instant('MEETING_COVISITOR_COMPLIANCE_UPDATE_FAIL_1')
          + ' ' + this.translate.instant('PLEASE_TRY_AGAIN_LATER');
        throw new GeneralException(
          ErrorCode.E01,
          errorMsg,
        );
      }
    } else {
      // Online
      const url: string = (this.authenticationService.userConfig.activeInstance.entryPointUrl
        + Endpoints.meeting.PATCH_MEETING).replace('{activity_id}', activity.ID);
      let headers = new HttpHeaders();
      headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID)

      // Server
      try {
        const response = await this.http.patch(
          url,
          {
            indskr_covisitorComplianceAcked: activity.covisitorComplianceAcked,
            indskr_covisitorComplianceDescription: activity.covisitorComplianceDescription,
          },
          { headers }
        )
          .pipe(timeout(30000))
          .toPromise();
      } catch (error) {
        console.error('updateCovisitorComplianceAck: server update: ', error);
        activity.covisitorComplianceAcked = prevAcked;
        activity.covisitorComplianceDescription = prevDescription;

        const errorMsg: string = this.translate.instant('MEETING_COVISITOR_COMPLIANCE_UPDATE_FAIL_1')
          + ' ' + this.translate.instant('PLEASE_TRY_AGAIN_LATER');
        throw new GeneralException(
          ErrorCode.E01,
          errorMsg,
        );
      }

      // Local
      try {
        await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity, true);
      } catch (error) {
        console.error('updateCovisitorComplianceAck: local update: ', error);
        const errorMsg: string = this.translate.instant('MEETING_COVISITOR_COMPLIANCE_UPDATE_FAIL_2')
          + ' ' + this.translate.instant('PLEASE_RUN_MANUAL_SYNC');
        throw new GeneralException(
          ErrorCode.E03,
          errorMsg,
        );
      }
    }
  }

  //Add or update meeting notes attachment
  async updateMeetingAttachments(payload) {
    let activity: AppointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
    if (activity.associatedAttachments.length) {
      await this.dynamics.update(activity.associatedAttachments[0].annotationId, 'annotations', payload).then(async (res) => {
        if (res) {
          activity.associatedAttachments[0].attachmentName = res['filename'];
          console.log(res);
        }
      }).catch((e) => {
        console.log("Error updating meeting attachment", e);
      })
    } else {
      payload['annotationid'] = Guid.create().toString();
      await this.dynamics.create(payload, 'annotations').then(async (res) => {
        if (res) {
          let attachment = {
            annotationId: res['annotationid'],
            attachmentName: res['filename']
          };
          activity.associatedAttachments.push(attachment);
        }
      }).catch((e) => {
        console.log("Error adding meeting attachment", e);
      })
    }
    await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity as AppointmentActivity, true);
  }

  async removeMeetingAttachment() {
    let activity: AppointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
    if (activity.associatedAttachments.length) {
      await this.dynamics.delete(activity.associatedAttachments[0].annotationId, 'annotations').then(async (res) => {
        activity.associatedAttachments = [];
        console.log(res);
      }).catch((e) => {
        console.log("Error deleting meeting attachment", e);
      })
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity as AppointmentActivity, true);
    }
  }

  public async uploadMeetingNotesOnline() {
      let offlineNotes = await this.disk.retrieve(DB_KEY_PREFIXES.OFFLINE_MEETING_NOTES);
      if(offlineNotes && offlineNotes.raw && Array.isArray(offlineNotes.raw) && offlineNotes.raw.length != 0){
        const payload = offlineNotes.raw.filter(item => item.pendingPushForDynamics).map(item=>{
        if(item.annotationid.includes('offlinmeetingnote')) {
          return {
            activityid: item.activityid,
            createdon: item.createdon,
            notetext: item.notetext,
            ownerid: item.ownerid,
            filename: item.filename,
            filesize: item.filesize,
            documentbody: item.documentbody,
            mimetype: item.mimetype,
            deleted: item.deleted
          };
        } else {
          if(item.deleted){
            return {
              deleted: true,
              annotationid: item.annotationid
            };
          } else if(item.fileupdated){
            return {
              notetext: item.notetext,
              annotationid: item.annotationid,
              filename: item.filename,
              filesize: item.filesize,
              documentbody: item.documentbody,
              mimetype: item.mimetype
            };
          } else if(item.fileremoved){
            return {
              notetext: item.notetext,
              annotationid: item.annotationid,
              filename: item.filename,
              filesize: item.filesize,
              documentbody: item.documentbody,
              mimetype: item.mimetype
            };
          } else {
            return {
              notetext: item.notetext,
              annotationid: item.annotationid
            };
          }
        }
      });
      const url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPLOAD_MEETING_NOTES;
      const response = await this.http.post(url, payload, Endpoints.headers.content_type.json).toPromise();
      if(response && Array.isArray(response) && response.length != 0){
        for(let idx=0;idx<response.length;idx++) {
          let res = response[idx];
          if(res.hasOwnProperty('annotationid')){
            // Add to real offline db for meeting notes
            try {
                  // let activity: AppointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
                  let activity: AppointmentActivity = this.activityService.getActivityByID(offlineNotes.raw[idx]['activityid']) as AppointmentActivity;
                  if(activity && activity.meetingNotes && activity.meetingNotes.length>0) {
                    let noteIdx = activity.meetingNotes.findIndex(item => item['noteId'] == offlineNotes.raw[idx]['annotationid'])
                    if(noteIdx >=0) {
                      if(payload[idx].deleted == true){
                        activity.meetingNotes.splice(noteIdx,1);
                      } else {
                        activity.meetingNotes[noteIdx]['noteId'] = res['annotationid'];
                        activity.meetingNotes[noteIdx]['noteText'] = offlineNotes.raw[idx]['notetext'];
                        activity.meetingNotes[noteIdx]['documentName'] = offlineNotes.raw[idx]['filename'];
                        activity.meetingNotes[noteIdx]['documentMimeType'] = offlineNotes.raw[idx]['mimetype'];
                        activity.meetingNotes[noteIdx]['pendingPushForDynamics'] = false;
                        if(activity.meetingNotes[noteIdx]['activityId'].includes('offline_meeting_')) {
                          activity.meetingNotes[noteIdx]['activityId'] = offlineNotes.raw[idx]['activityid'];
                        }
                      }
                    }
                  }
                  if(activity) await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity as AppointmentActivity, true);
                  offlineNotes.raw[idx].pendingPushForDynamics = false;
            } catch (error) {
                console.log(error);
            }
          }
        }
        offlineNotes.raw = offlineNotes.raw.filter(item => item.pendingPushForDynamics);
        offlineNotes.count = offlineNotes.raw.length;
        this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING_NOTES, offlineNotes.count);
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.OFFLINE_MEETING_NOTES, doc => {
            doc = offlineNotes;
            return doc;
        }).catch(error => console.error('Save Offline Meeting Notes to DB error: ', error));
      }
      return response;
    } else {
      return null;
    }
}
  public async fetchMeetingObjectives(loadFromDBOnly: boolean) {
    if(!this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_OBJECTIVE_SELECTION)) return;
    if (loadFromDBOnly) {
      await this.disk.retrieve(DB_KEY_PREFIXES.MEETING_OBJECTIVES).then((data) => {
        if (data && data['raw']) {
            this.meetingObjectives = data['raw'];
            console.log(`Meeting objectives from disk : ${data['raw'].length}`);
        }
    }).catch((err) => console.error("loadEventsFromDB: Error saving events data to offline db!", err));
    } else {
      return await this.dynamics.executeFetchQuery('indskr_meetingobjectiveses', fetchQueries.meetingObjectives).then(async (response) => {
        this.meetingObjectives = response;
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.MEETING_OBJECTIVES, doc => ({ raw: response })).catch((err) => console.error("syncEvents: Error saving meeting objectives data to offline db!", err));
      })
    }
  }

  public async saveMeetingObjectives(payload) {
    if (!this.deviceService.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
      const url: string = (this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.SAVE_MEETING_OBJECTIVES).replace('{activity_id}', this.activityService.selectedActivity.ID);
      await this.http
        .put(url, payload)
        .toPromise().catch((err) => console.error(err));
    }
    await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity, false, false)
  }

  public async updateMeetingCheckInCheckout(activity, payload, data?: Photo[],  gpsActivitiyPayload?) {
    try {
      // const activity = (this.activityService.selectedActivity) as AppointmentActivity;
      const createdAt = new Date().getTime();
      if(!_.isEmpty(data)) {
        data = data.map((image) => {
          const base64String = image.base64String.replace(/^data:image\/\w+;base64,/, '');
          return { ...image, base64String, createdAt: createdAt };
        });

        payload['gpsActivityPhotos'] = data;
      }
      let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.GPS_CHECK_IN;
      url = url.replace("{activity_id}", activity.ID);
      const response: any = await this.http.put(url, payload).toPromise();
      const photosUploaded = response['gpsActivityPhotos'];
      if (response && !_.isEmpty(photosUploaded) && !_.isEmpty(gpsActivitiyPayload) ) {
        photosUploaded.forEach((photoUploaded)=>{
          gpsActivitiyPayload[0]['photoAttachmentIds'].push(photoUploaded.photoId);
        })
        gpsActivitiyPayload[0]['indskr_gpscheckindetailsid'] = response['indskr_gpscheckindetailsid'];
        await this.appealDataService.updateGPSActivityPhotos(activity.ID, gpsActivitiyPayload);
        const photos: PhotoResponse[] = photosUploaded.map(p => {
          return { indskr_photoattachmentid: p.photoId, indskr_photoorigin: p.photoOrigin, indskr_photourl: p.photoUrl, name: p.name }
        });
        const gpsActivityPhoto: GPSActivityPhoto = {
          indskr_gpscheckindetailsid: gpsActivitiyPayload[0]['indskr_gpscheckindetailsid'],
          indskr_type: gpsActivitiyPayload[0]['indskr_type'],
          photoAttachments:photos
        }
        activity.gpsActivityPhotos.push(gpsActivityPhoto);
      }
      return response;
    } catch (error) {
      console.log('check error', error);
      if (error.status === 0) {
        let message = payload.indskr_gpscheckindetailsid ? 'CHECK_OUT_FAILED' : 'CHECK_IN_FAILED';
        this.notificationService.notify(this.translate.instant(message), 'Check In', 'top', ToastStyle.DANGER, 3000);
      }
      return false;
    }
  }


  /**
   * Leverage offline upload service to crud multiple meetings online
   */
  async crudMeetingsOnlineWithOfflineUploadEndpoint(meetings: AppointmentActivity[], refreshAgendaLater: boolean = false): Promise<boolean> {
    if (
      this.deviceService.isOffline
      || !Array.isArray(meetings)
      || meetings.length <= 0
    ) {
      return false;
    }

    // Run payload sanity check & make request
    let response: any;
    try {
      const DTOs = meetings.map(m => m.DTO);
      
      try {
        DTOs.forEach(this.activityDataService.offlineMeetingPayloadSanityCheck);
      } catch (error) {
        console.error('crudMeetingsOnlineWithOfflineUploadEndpoint: ', error);
        return false;
      }
      const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.offline.UPLOAD_BULK_MEETING;
      const headers = Endpoints.presentations.FAVOURITE_PRESENTATION_HEADER;
      headers.headers = headers.headers.set('X-SystemUserId', this.authenticationService.user.systemUserID);
      const body = DTOs;
      response = await this.http.put(url, body, headers).toPromise();
    } catch (httpError) {
      console.error('crudMeetingsOnlineWithOfflineUploadEndpoint: ', httpError);
      return false;
    }

    let success = false;
    if (response) {
      success = true;

      // If one of them fails, consider as total failure
      for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
          const meetingResponse = response[key];
          if (!meetingResponse.hasOwnProperty('activityId') || !meetingResponse.activityId) {
            // Something's wrong
            console.error('crudMeetingsOnlineWithOfflineUploadEndpoint: activityId is missing', response);
            success = false;
          } else if (meetingResponse.hasOwnProperty('errorId')) {
            // Handle Errors
            console.error('crudMeetingsOnlineWithOfflineUploadEndpoint: ', meetingResponse);
            success = false;
          }
        }
      }

      if (success) {
        // Update meeting instances. No DB update.
        for (let i = 0; i < meetings.length; i++) {
          const meeting = meetings[i];
          const meetingResponse = response[meeting.offlineMeetingId];

          const isNewRecord = meeting.ID.includes('offline');

          if (meetingResponse) {
            if (meeting.status === 4 || meeting.state === 2) {
              await this.activityService.removeActivity(meeting);
            } else {
              if (isNewRecord) {
                meeting.ID = meetingResponse.activityId;
                meeting.lastUpdatedTime = 0;
                meeting._id = DB_KEY_PREFIXES.MEETING_ACTIVITY + meetingResponse.activityId;
              }

              await this.activityService.addActivity(meeting, true);
            }
          } else {
            console.error('crudMeetingsOnlineWithOfflineUploadEndpoint: meetingRespons is missing: ', meeting, response);
          }
        }

        for (const meeting of meetings) {
          const rawDoc = meeting.DTO;
          await this.disk.updateOrInsert(meeting._id, doc => rawDoc);
        }
      }
    }
    if (!this.uiService.toolsActivityActive && !refreshAgendaLater) {
      this.events.publish("refreshAgenda");
    } else this.uiService.agendaRefreshRequired = true;

    return success;
  }

  async updateAccountToAccountVisit(
    accountVisit: AppointmentActivity,
    oldAccounts: Account[],
    loader,
  ): Promise<boolean> {
    let oldSubject = accountVisit.subject;
    let oldContacts = accountVisit.contacts;
    let origAppointmentActivity: AppointmentActivity;
    let needToRevert = false;
    let success = false;
    const nestedMeetings: AppointmentActivity[] = this.activityService.getAccountVisitNestedMeetings(accountVisit.ID);
    const hadAccountAndContactsBefore = Array.isArray(oldAccounts)
      && oldAccounts.length > 0
      && Array.isArray(accountVisit.contactAttendees)
      && Array.isArray(nestedMeetings)
      && nestedMeetings.length > 0;

    if (hadAccountAndContactsBefore) {
      const hasAnyCompletedAccountVisitNestedMeeting = this.activityService.hasAnyCompletedAccountVisitNestedMeeting(accountVisit);
      if (hasAnyCompletedAccountVisitNestedMeeting) {
        // Revert account
        this.notificationService.notify(
          this.translate.instant('ACCOUNT_VISIT_HAS_COMPLETED_NESTED_MEETING_ACCOUNT_CHANGE_MESSAGE'),
          'AccountVisit',
          'top',
          ToastStyle.DANGER,
        );
        accountVisit.accounts = oldAccounts;
        return false;
      }

      // Check if nested meetings are scrap-able
      const scrapStatusResponse = this.activityService.getAccountVisitNestedMeetingsScrapStatus(nestedMeetings, true);
      if (scrapStatusResponse.shouldHalt) {
        // Revert account
        accountVisit.accounts = oldAccounts;
        return false;
      }

      // Alert user about nested meeting deletion and get confirm
      // await loader?.dismiss();
      await this.uiService.dismissLoader();
      const response = await this.alertService.showAlert(
        {
          title: this.translate.instant('CHANGE_ACCOUNT'),
          message: this.translate.instant('ACCOUNT_VISIT_NESTED_MEETING_WILL_BE_SCRAPPED_CONFIRM'),
        },
        this.translate.instant('CONFIRM'),
      );
      if (
        !response
        || response.role !== 'ok'
      ) {
        // Revert account
        accountVisit.accounts = oldAccounts;
        return;
      }
      await this.uiService.displayLoader();
      // await loader?.present();
    }

    // Update subject
    const newAccount = Array.isArray(accountVisit?.accounts) && accountVisit.accounts[0];
    if (newAccount) {
      let newSubject = [newAccount.accountName, this.translate.instant('ACCOUNT_VISIT'), accountVisit.activityTypeName].filter(Boolean).join(' - ');
      accountVisit.subject = newSubject;
      origAppointmentActivity = this.activityService.getActivityByID(accountVisit.ID) as AppointmentActivity;
      if (origAppointmentActivity) {
        origAppointmentActivity.subject = newSubject;
      }
    } else {
      accountVisit.subject = [this.translate.instant('ACCOUNT_VISIT'), accountVisit.activityTypeName].filter(Boolean).join(' - ');
    }

    // Prep payload
    const payload: AppointmentActivity[] = [accountVisit];

    // If account visit already has nested meetings, need to remove contacts & scrap nested meetings
    if (hadAccountAndContactsBefore) {
      // Remove contacts from account visit
      accountVisit.contacts = [];

      // Scrap nested appointments
      payload.push(
        ...nestedMeetings.map(a => {
          return new AppointmentActivity({
            _id: a._id ?? DB_KEY_PREFIXES.MEETING_ACTIVITY + a.ID,
            _rev: a._rev ?? undefined,
            activityid: a.ID,
            offlineMeetingId: a.offlineMeetingId,
            subject: a.subject,
            scheduledstart: a.scheduledStart,
            scheduledend: a.scheduledEnd,
            indskr_parentcallid: a.indskr_parentcallid,
            stateCode: 2,
          });
        })
      );
    }

    try {
      success = await this.crudMeetingsOnlineWithOfflineUploadEndpoint(payload);
      needToRevert = !success;
    } catch (error) {
      console.error('updateAccountToAccountVisit: ', error);
    }

    // If request failed and need to revert changes
    if (needToRevert) {
      accountVisit.accounts = oldAccounts;
      accountVisit.subject = oldSubject;
      if (origAppointmentActivity) {
        origAppointmentActivity.subject = oldSubject;
      }
      if (oldContacts) {
        accountVisit.contacts = oldContacts;
      }
    }

    return success;
  }

  public async scrapAccountVisit(activity: AppointmentActivity): Promise<boolean> {
    let success = false;

    const payload: AppointmentActivity[] = [];
    if (activity.indskr_isparentcall) {
      // Parent Account Visit
      // Scrap parent
      payload.push(new AppointmentActivity({
        _id: activity._id ?? DB_KEY_PREFIXES.MEETING_ACTIVITY + activity.ID,
        _rev: activity._rev ?? undefined,
        activityid: activity.ID,
        offlineMeetingId: activity.offlineMeetingId,
        subject: activity.subject,
        scheduledstart: activity.scheduledStart,
        scheduledend: activity.scheduledEnd,
        indskr_isparentcall: true,
        stateCode: 2,
      }));

      const nestedMeetings = this.activityService.getAccountVisitNestedMeetings(activity.ID);
      if (
        Array.isArray(nestedMeetings)
        && nestedMeetings.length > 0
      ) {
        // Scrap children
        payload.push(
          ...nestedMeetings.map(a => {
            return new AppointmentActivity({
              _id: a._id ?? DB_KEY_PREFIXES.MEETING_ACTIVITY + a.ID,
              _rev: a._rev ?? undefined,
              activityid: a.ID,
              offlineMeetingId: a.offlineMeetingId,
              subject: a.subject,
              scheduledstart: a.scheduledStart,
              scheduledend: a.scheduledEnd,
              indskr_parentcallid: a.indskr_parentcallid,
              stateCode: 2,
            });
          })
        );
      }
    } else if (activity.indskr_parentcallid) {
      // Scrap nested meeting
      payload.push(
        new AppointmentActivity({
          _id: activity._id ?? DB_KEY_PREFIXES.MEETING_ACTIVITY + activity.ID,
          _rev: activity._rev ?? undefined,
          activityid: activity.ID,
          offlineMeetingId: activity.offlineMeetingId,
          subject: activity.subject,
          scheduledstart: activity.scheduledStart,
          scheduledend: activity.scheduledEnd,
          indskr_parentcallid: activity.indskr_parentcallid,
          stateCode: 2,
        })
      );

      // Remove contact from parent
      // Since the crud function requires fully loaded activity object as payload,
      // we have to make sure to append the meeting details to the activity object.
      const parent = await this.activityDataService
        .getNewDetailAppendedAppointmentActivityInstanceFromLocalDB(activity.indskr_parentcallid);
      if (
        !parent
        || !parent.contacts
      ) {
        console.error('scrapAccountVisit: Could not find the parent account visit or contact is not available.', activity, parent);
        return success;
      }

      // ! meed 
      const contact = Array.isArray(activity.contacts) ? activity.contacts[0] : undefined;
      if (contact) {
        const contactIdx = parent.contacts?.findIndex(c => c.ID === contact.ID) ?? -1;
        if (contactIdx >= 0) {
          parent.contacts.splice(contactIdx, 1);
        }
      }
      payload.unshift(parent);
    } else {
      console.error('scrapAccountVisit: not an account visit?', activity);
    }

    try {
      if (this.deviceService.isOffline) {
        //In Home screen, if we dont allow this there is no way to come of the screen : user will be blocked
        for (let meeting of payload) {
          await this.activityService.upsertMeetingsOfflineData(meeting);
        }
        success = true;
      } else {
        success = payload.length > 0 ? await this.crudMeetingsOnlineWithOfflineUploadEndpoint(payload) : false;
      }
    } catch (error) {
      console.error('scrapAccountVisit: ', error);
    }

    return success;
  }

  async updateContactToAccountVisit(accountVisit: AppointmentActivity, oldContacts: Contact[]): Promise<boolean> {
    let success = false;
    const newContacts = accountVisit.contacts ?? [];
    // const oldAndNewInersectingContacts = intersectionBy(
    //   newContacts, oldContacts, 'ID'
    // );
    const xorContacts = xorBy(newContacts, oldContacts, 'ID');
    const contactsToAdd = intersectionBy(xorContacts, newContacts, 'ID');
    const contactsToRemove = intersectionBy(xorContacts, oldContacts, 'ID');

    // Prep payload
    const payload: AppointmentActivity[] = [accountVisit];
    const accountVisitDTO: any = accountVisit.DTO;
    const newNestedMeetings: AppointmentActivity[] = [];
    if (contactsToAdd.length > 0) {
      // Create new nested meetings
      const timestamp = new Date().getTime();
      for (let i = 0; i < contactsToAdd.length; i++) {
        const contact = contactsToAdd[i];
        const nestedOfflineId = addSeconds(timestamp, i).getTime();
        // Inherit some fields from account visit
        const newRawMeeting = {
          offlineMeetingId: OFFLINE_ID_PREFIX + nestedOfflineId,
          activitytypecode: 'appointment',
          activityAccounts: accountVisitDTO.activityAccounts,
          activityAccountPlans: accountVisitDTO.activityAccountPlans,
          activityAccompaniedUsers: accountVisitDTO.activityAccompaniedUsers,
          activityAttachments: accountVisitDTO.activityAttachments,
          activityPlaylists: accountVisitDTO.activityPlaylists,
          activityProcedures: accountVisitDTO.activityProcedures,
          activityProducts: accountVisitDTO.activityProducts,
          activityObjectives: accountVisitDTO.activityObjectives,
          activityOpportunities: accountVisitDTO.activityOpportunities,
          activityTherapeuticAreas: accountVisitDTO.activityTherapeuticAreas,
          activityProductIndications : accountVisitDTO.activityProductIndications,
          activityDiseaseAreas : accountVisitDTO.activityDiseaseAreas,
          contactAttendees: [{
            indskr_contactid: contact.ID,
            indskr_name: contact.fullname,
          }],
          indskr_ownerfullname: accountVisitDTO.indskr_ownerfullname,
          indskr_ownerid: accountVisitDTO.indskr_ownerid,
          indskr_jointmeeting: accountVisitDTO.indskr_jointmeeting,
          indskr_notes: accountVisitDTO.indskr_notes,
          accountPlanId : accountVisitDTO.accountPlanId,
          actualend: accountVisitDTO.actualend,
          scheduledend: accountVisitDTO.scheduledend,
          scheduledstart: accountVisitDTO.scheduledstart,
          actualstart: accountVisitDTO.actualstart,
          indskr_iomeetingtype: 100000000,
          indskr_subtype: 100000001,
          indskr_type: 100000000,
          location: accountVisitDTO.location,
          stateCode: 0,
          statuscode: 1,
          subject: accountVisitDTO.subject,
          indskr_activitytype: accountVisitDTO.indskr_activitytype,
          activityTypeName: accountVisitDTO.activityTypeName,
          indskr_positionid: accountVisitDTO.indskr_positionid,
          indskr_isparentcall: false,
          indskr_parentcallid: accountVisitDTO.activityId,
        };
        const newNestedMeeting = new AppointmentActivity(newRawMeeting);
        await this.activityDataService._appendMeetingDetailsToActivity(newNestedMeeting, newRawMeeting);
        // newNestedMeeting.contacts = [contact];
        // newNestedMeeting.accounts = [...accountVisit.accounts];
        // newNestedMeeting.presentations = [...accountVisit.presentations];

        newNestedMeetings.push(newNestedMeeting);
        payload.push(newNestedMeeting);
      }
    }
    if (contactsToRemove.length > 0) {
      const nestedMeetingsToBeDeleted: AppointmentActivity[] = [];
      const nestedMeetings = this.activityService.getAccountVisitNestedMeetings(accountVisit.ID);
      // Scrap nested meetings as long as not complete
      for (const contact of contactsToRemove) {
        const nestedMeetingsToDelete = nestedMeetings?.filter(m =>
            Array.isArray(m.contacts)
            && m.contacts[0]?.ID === contact.ID
          );
          if (nestedMeetingsToDelete.length > 0) {
            for(const nestedMeetingToDelete of nestedMeetingsToDelete){
              nestedMeetingsToBeDeleted.push(nestedMeetingToDelete);
              const tempActivityForPayload = new AppointmentActivity({
                _id: nestedMeetingToDelete._id ?? DB_KEY_PREFIXES.MEETING_ACTIVITY + nestedMeetingToDelete.ID,
                _rev: nestedMeetingToDelete._rev ?? undefined,
                activityid: nestedMeetingToDelete.ID,
                offlineMeetingId: nestedMeetingToDelete.offlineMeetingId,
                subject: nestedMeetingToDelete.subject,
                scheduledstart: nestedMeetingToDelete.scheduledStart,
                scheduledend: nestedMeetingToDelete.scheduledEnd,
                indskr_parentcallid: nestedMeetingToDelete.indskr_parentcallid,
                stateCode: 2,
              });
              payload.push(tempActivityForPayload);
            }
          }
        // if (nestedMeetingToDelete) {
        //   nestedMeetingsToBeDeleted.push(nestedMeetingToDelete);
        //   const tempActivityForPayload = new AppointmentActivity({
        //     _id: nestedMeetingToDelete._id ?? DB_KEY_PREFIXES.MEETING_ACTIVITY + nestedMeetingToDelete.ID,
        //     _rev: nestedMeetingToDelete._rev ?? undefined,
        //     activityid: nestedMeetingToDelete.ID,
        //     offlineMeetingId: nestedMeetingToDelete.offlineMeetingId,
        //     subject: nestedMeetingToDelete.subject,
        //     scheduledstart: nestedMeetingToDelete.scheduledStart,
        //     scheduledend: nestedMeetingToDelete.scheduledEnd,
        //     indskr_parentcallid: nestedMeetingToDelete.indskr_parentcallid,
        //     stateCode: 2,
        //   });
        //   payload.push(tempActivityForPayload);
        // }
      }

      // Check if nested meetings to be deleted are scrap-able
      const scrapStatusResponse = this.activityService.getAccountVisitNestedMeetingsScrapStatus(nestedMeetingsToBeDeleted, true);
      if (scrapStatusResponse.shouldHalt) {
        // Revert contacts
        accountVisit.contacts = oldContacts;
        return false;
      }
    }

    try {
      success = await this.crudMeetingsOnlineWithOfflineUploadEndpoint(payload);
    } catch (error) {
      console.error('updateContactToAccountVisit: ', error);
    }

    if (!success) {
      // Revert contacts
      accountVisit.contacts = oldContacts;
    }

    return success;
  }

  async createdNestedAccountVisitWithoutContact(accountVisit: AppointmentActivity): Promise<boolean> {
    let success = false;
    // Prep payload
    const payload: AppointmentActivity[] = [accountVisit];
    const accountVisitDTO: any = accountVisit.DTO;
    // const newNestedMeetings: AppointmentActivity[] = [];
      // Create new nested meetings
      const timestamp = new Date().getTime();
        const newRawMeeting = {
          offlineMeetingId: OFFLINE_ID_PREFIX + timestamp,
          activitytypecode: 'appointment',
          activityAccounts: accountVisitDTO.activityAccounts,
          activityAccountPlans: accountVisitDTO.activityAccountPlans,
          activityAccompaniedUsers: accountVisitDTO.activityAccompaniedUsers,
          activityAttachments: accountVisitDTO.activityAttachments,
          activityPlaylists: accountVisitDTO.activityPlaylists,
          activityProcedures: accountVisitDTO.activityProcedures,
          activityProducts: accountVisitDTO.activityProducts,
          activityObjectives: accountVisitDTO.activityObjectives,
          activityOpportunities: accountVisitDTO.activityOpportunities,
          activityTherapeuticAreas: accountVisitDTO.activityTherapeuticAreas,
          indskr_ownerfullname: accountVisitDTO.indskr_ownerfullname,
          indskr_ownerid: accountVisitDTO.indskr_ownerid,
          indskr_jointmeeting: accountVisitDTO.indskr_jointmeeting,
          indskr_notes: accountVisitDTO.indskr_notes,
          accountPlanId : accountVisitDTO.accountPlanId,
          actualend: accountVisitDTO.actualend,
          scheduledend: accountVisitDTO.scheduledend,
          scheduledstart: accountVisitDTO.scheduledstart,
          actualstart: accountVisitDTO.actualstart,
          indskr_iomeetingtype: 100000000,
          indskr_subtype: 100000001,
          indskr_type: 100000000,
          location: accountVisitDTO.location,
          stateCode: 0,
          statuscode: 1,
          subject: accountVisitDTO.subject,
          indskr_activitytype: accountVisitDTO.indskr_activitytype,
          activityTypeName: accountVisitDTO.activityTypeName,
          indskr_positionid: accountVisitDTO.indskr_positionid,
          indskr_isparentcall: false,
          indskr_parentcallid: accountVisitDTO.activityId,
          activityProductIndications : accountVisitDTO.activityProductIndications,
          activityDiseaseAreas : accountVisitDTO.activityDiseaseAreas,
        };
        const newNestedMeeting = new AppointmentActivity(newRawMeeting);
        await this.activityDataService._appendMeetingDetailsToActivity(newNestedMeeting, newRawMeeting);
        // newNestedMeetings.push(newNestedMeeting);
        payload.push(newNestedMeeting);
    
    try {
      success = await this.crudMeetingsOnlineWithOfflineUploadEndpoint(payload, true);
      
      this.uiService.setUIServiceData({
        view: "new-activity", newNestedMeeting,
      }); // this is an observable stream

      this.activityService.selectedActivityAtHome = this.activityService.selectedActivity = newNestedMeeting;
      // this.uiService.showRightPane = true;
      // this.events.publish('initActivityKeyMessages');
    } catch (error) {
      console.error('updateContactToAccountVisit: ', error);
    }

    if (!success) {
      // Revert contacts
      // accountVisit.contacts = oldContacts;
    }

    return success;
  }

  getContentId(content: Presentation | Resource) {
    return content['ioPresentationId'] ? content['ioPresentationId'] : (content['ioDocumentId'] ? content['ioDocumentId'] : content['ioResourceId']);
  }

  async addRemoveContentToAccountActivity(
    isAccountVisitRecord: boolean,
    origContents: (Presentation | Resource)[],
    loader,
    skipContentRemoveConfirm = false,
  ) {
    const activity: AppointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
    const payload: AppointmentActivity[] = [activity];
    const nestedMeetings = isAccountVisitRecord ? this.activityService.getAccountVisitNestedMeetings(activity.ID) : [];

    if (
      isAccountVisitRecord
      && Array.isArray(nestedMeetings)
      && nestedMeetings.length > 0
    ) {
      const comparator = (value, other) => {
        if (
          (
            value.ioPresentationId && other.ioPresentationId
            && value.ioPresentationId === other.ioPresentationId
          ) || (
            value.ioResourceId && other.ioResourceId
            && value.ioResourceId === other.ioResourceId
          ) || (
            value.ioDocumentId && other.ioDocumentId
            && value.ioDocumentId === other.ioDocumentId
          )
        ) {
          return true;
        }
      };
      const newContents = activity.presentations;
      const _origContents = Array.isArray(origContents)
      ? origContents
      : [];
      const xorContents = xorWith(
        newContents,
        _origContents,
        comparator,
      );
      const contentToAdd = intersectionWith(
        xorContents,
        newContents,
        comparator,
      );
      const contentToRemove = intersectionWith(
        xorContents,
        _origContents,
        comparator,
      );

      if (contentToRemove.length > 0 && !skipContentRemoveConfirm) {
        await loader?.dismiss();
        // Warn user about nested meeting content remove and get confirm
        const response = await this.alertService.showAlert(
          {
            title: this.translate.instant('MEETING_STRUCTURE_REMOVE_CONTENT_TITLE'),
            message: this.translate.instant('ACCOUNT_VISIT_CONTENT_REMOVE_CONFIRM'),
          },
          this.translate.instant('REMOVE'),
        );

        if (!response || response.role !== 'ok') {
          throw(new Error('ignore'));
        }
        await loader?.present();
        await this.uiService.displayLoader();
      }

      if (contentToAdd.length > 0 || contentToRemove.length > 0) {
        for (let i = 0; i < nestedMeetings.length; i++) {
          const nestedMeeting = nestedMeetings[i];
          if (
            nestedMeeting.state !== 1
            && nestedMeeting._id
          ) {
            try {
              // Need to load full detail..
              const raw = await this.disk.retrieve(nestedMeeting._id, true);
              if (raw) {
                await this.activityDataService._appendMeetingDetailsToActivity(nestedMeeting, raw);

                if (!Array.isArray(nestedMeeting.presentations)) {
                  nestedMeeting.presentations = [];
                }
                const idxToRemove = [];
                for (let i = 0; i < nestedMeeting.presentations.length; i++) {
                  const content = nestedMeeting.presentations[i];
                  const id = this.getContentId(content);
                  if (contentToRemove.length <= 0) {
                    break;
                  }
                  const has = contentToRemove.some((p: any) => {
                    return p.ioPresentationId === id
                      || p.ioResourceId === id
                      || p.ioDocumentId === id
                  });
                  if (has) {
                    idxToRemove.unshift(i);
                  }
                }

                if (idxToRemove.length > 0) {
                  for (let i = 0; i < idxToRemove.length; i++) {
                    const idx = idxToRemove[i];
                    nestedMeeting.presentations.splice(idx, 1);
                  }
                }

                // Add contents to nested meeting
                if (
                  contentToAdd.length > 0
                ) {
                  if (nestedMeeting.presentations.length > 0) {
                    // Don't add duplicate contents
                    const xor = xorWith(
                      contentToAdd,
                      nestedMeeting.presentations,
                      comparator,
                    );
                    const toAdd = intersectionWith(
                      xor,
                      contentToAdd,
                      comparator,
                    );
                    nestedMeeting.presentations.push(...toAdd);
                  } else {
                    nestedMeeting.presentations.push(...contentToAdd);
                  }
                }
                payload.push(nestedMeeting);
              }
            } catch (error) {
              console.error('addRemoveContentToAccountActivity: ', error);
            }
          }
        }
      }
    }

    let success = false;

    if (payload.length > 0) {
      success = await this.crudMeetingsOnlineWithOfflineUploadEndpoint(payload);
    }

    if (!success) {
      throw(new Error('addToAccountActivity: failed to update'));
    }
  }

  /**
   * Function to update meeting location
   * @param meeting 
   * @returns 
   */
  async updateMeetingLocation(meeting: AppointmentActivity) {
    await this.uiService.displayLoader();
    let payload = new UpdateMeetingPayload(
      meeting.subject,
      meeting.location,
      meeting.scheduledStart,
      meeting.scheduledEnd,
      meeting.notes,
      meeting.isBestTime,
      meeting.meetingURL,
      meeting.indskr_meetingtype,
      meeting.meetingNotes,
      meeting.indskr_meetinglocationlatitude ?? 0,
      meeting.indskr_meetinglocationlongitude ?? 0
    );

    return this.updateMeeting(this.activityService.selectedActivity as AppointmentActivity, payload, true, false, true).then(async () => {
      if (this.activityService.selectedActivity instanceof AppointmentActivity) {
        this.activityService.selectedActivity.location = meeting.location;
        this.activityService.selectedActivity.indskr_meetinglocationlatitude = meeting.indskr_meetinglocationlatitude;
        this.activityService.selectedActivity.indskr_meetinglocationlongitude = meeting.indskr_meetinglocationlongitude;
      }
     
      let activity = this.activityService.getActivityByID(this.activityService.selectedActivity.ID);
      if (activity instanceof AppointmentActivity) {
        activity.location = meeting.location;
        activity.indskr_meetinglocationlatitude = meeting.indskr_meetinglocationlatitude;
        activity.indskr_meetinglocationlongitude = meeting.indskr_meetinglocationlongitude;
        // this.activityService.getActivityByID(this.activityService.selectedActivity.ID) = activity;
      }

      this.uiService.dismissLoader();
    }).catch(error => {
        console.error('onCloseModal: ', error);
        this.uiService.dismissLoader();
        this.activityService.upsertMeetingsOfflineData(meeting, false, true);
      });
  }
  
  async updateRemoteDetailingDuration(activity: AppointmentActivity, { start, end }: { start: Date, end: Date }) {
    try {
      const duration = end.getTime() - start.getTime();
      let durationMins = 0;
      if (duration > 0) {
        durationMins = _.round(moment.duration(duration).asMinutes());
      }
      if (duration <= 0 || durationMins <= 0) {
        console.log(`Couldn't update meeting duration since Meeting ended in ${moment.duration(duration).asSeconds()} seconds`);
        return;
      }
      const existingDuration = activity.actualdurationminutes ? _.toNumber(activity.actualdurationminutes) : 0;
      console.log(`updating meeting duration Existing  Duaration ${existingDuration} and current Duration ${durationMins}` );
      const actualdurationminutes = existingDuration + durationMins;
      (this.activityService.selectedActivity as AppointmentActivity).actualdurationminutes = activity.actualdurationminutes = actualdurationminutes;
      await this.dynamics.update(activity.ID, "appointments", { actualdurationminutes: activity.actualdurationminutes});
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity, true);
    } catch (err) {
      console.error("Error while updating remote duration", err);
    }
  }

  public async convertToRemoteMeeting() {
    const currentActivity: AppointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
    const meetingFormats = this.activityService.configuredActivityTypes(MeetingActivityTypeCode.MEETING, FormatType.HCP_MEETING);
    if (currentActivity.indskr_activitytype) {
      const existingFormat = meetingFormats.find(mf => mf.indskr_activitytypeid == currentActivity.indskr_activitytype);
      if (existingFormat.indskr_remotechannel) return;
    }
    currentActivity['isRemoteDetailing'] = currentActivity.isRemoteDetailing = true;
    const remoteChannelFormats = meetingFormats.filter(mf => mf.indskr_remotechannel);
    if (remoteChannelFormats.length == 0) return;
    const remoteFormat = remoteChannelFormats[0];
    const defaultActivitySubType = this.activityService.getActivitySubTypesByActivityType(MeetingActivityTypeCode.MEETING, remoteFormat.indskr_activitytypeid).find(ast => ast.indskr_default);
    const payload = {
      indskr_activitytype: remoteFormat.indskr_activitytypeid,
      activityTypeName: remoteFormat.indskr_name,
      indskr_activitysubtype: defaultActivitySubType ? defaultActivitySubType.indskr_activitysubtypeid : null,
      activitySubTypeName: defaultActivitySubType ? defaultActivitySubType.indskr_name : null,
      //Subject = HCP Name - Activity - Format - Rep Name
      subject: this.activityService.getAppointmentActivitySubject(currentActivity, remoteFormat.indskr_name),
    };

    await this.updateActivityTypeAndActivitySubType(
      currentActivity,
      payload,
      ActivityTypeCodeRaw.Appointment,
      'indskr_activitytype',
      true,
    );
  }

  async updateContactJoinDetailsLeftDate(activity, useSlideTime: boolean = false) {
    let meeting = activity as AppointmentActivity;
    if (!meeting.isRemoteDetailing || _.isEmpty(meeting.contacts) || this.deviceService.isOffline) return;
    let leftDate = new Date().getTime();
    if (useSlideTime && !_.isEmpty(meeting.activityPresentations)) {
      console.log("Updating endtime using slide");
      const slidesShared = meeting.activityPresentations.map(a => a.activityPresentationSlides).reduce((c1, c2) => c1.concat(c2), []);
      const latestSlide = _.maxBy(slidesShared, (o) => _.toNumber(o.endtime));
      console.log("Latest slide", latestSlide);
      console.log("Latest slide endTime", latestSlide?.endtime);
      if (latestSlide) {
        leftDate = _.toNumber(latestSlide?.endtime);
      }
    }
    const payload = meeting.contacts?.map(c => ({ "indskr_contactid": c.ID, "indskr_leftdate": leftDate }));
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.UPDATE_CUSTOMER_JOIN_DETAILS_LEFT_DATE;
    url = url.replace('{activityId}', meeting.ID);
    await this.http.patch(url, payload).toPromise();
    await this.updateMeetingDuration(meeting, activity);
  }

  private async updateMeetingDuration(meeting: AppointmentActivity, activity: any) {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.CAPTURE_REMOTE_DETAILING_DURATION) && !_.isEmpty(meeting.contacts)) {
      let fetchXml = fetchQueries.fetchContactJoinDetails;
      fetchXml = fetchXml.replace('{activityId}', activity.ID);
      fetchXml = fetchXml.replace('{contactId}', meeting.contacts[0].ID);
      const activityattendeejoiningdetails = await this.dynamics.executeFetchQuery("indskr_activityattendeejoiningdetails", fetchXml);
      meeting.activityattendeejoiningdetails = (activity as AppointmentActivity).activityattendeejoiningdetails = activityattendeejoiningdetails;
      await this.updateActualDurationInSeconds(meeting, this.activityService.actualDuration);
    }
  }

  async updateActualDurationInSeconds(activity: AppointmentActivity, duration: number) {
    try {
      await this.dynamics.update(activity.ID, "appointments", { indskr_duration: duration });
      await this.disk.updateOrInsertActivityToActivityDetailRawDocument(activity, true);
    } catch (err) {
      console.error("Error while updating remote duration", err);
    }
  }
  
  async getActiveGeneralConsent(contactId: string) {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.consent.GET_ACTIVE_GENERAL_CONSENT;
    url = url.replace('{contactId}', contactId);
    const response: any = await this.http.get(url).toPromise();
    let activeConsent = response && response.indskr_consentid ? true : false;
    this.events.publish('active-consent', activeConsent);
    return activeConsent;
  }

  private _populateContactSentimentKeyMessages(activity: AppointmentActivity){
    let obj = [];
    let isDirty = false;
    if(this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_KEY_MESSAGE_SENTIMENT) && activity.activityContactSentiments && activity.activityContactSentiments.length){
      activity.activityContactSentiments.forEach(acs=> {
        let oldKeyMessagesList = acs.activityKeyMessageSentiments;
        let uniqueKeyMessagesList = []; 
        if((this.activityService.selectedActivity as AppointmentActivity).products){
          (this.activityService.selectedActivity as AppointmentActivity).products.forEach(p=> {
            if((p.isSelected || p.isAutoSelected) && p.keyMessages){
              p.keyMessages.forEach(k=> {
                if(k.isSelected || k.isAutoSelected){
                  if(!uniqueKeyMessagesList.some(a=> a.indskr_keymessageid == k.ID)){
                    let alreadyPresetObj = oldKeyMessagesList.find(b=> b.indskr_keymessageid == k.ID);
                    isDirty = true;
                    uniqueKeyMessagesList.push({
                      indskr_keymessageid: k.ID.toString(),
                      indskr_name: k.name.toString(),
                      indskr_keymessagesentiment: alreadyPresetObj ? alreadyPresetObj.indskr_keymessagesentiment : 'NEUTRAL',
                    });
                  }
                }
              })
            }
          })
        }
        if((this.activityService.selectedActivity as AppointmentActivity).activityProductIndications){
          (this.activityService.selectedActivity as AppointmentActivity).activityProductIndications.forEach(p=> {
            if(p.activityProductIndicationKeyMessages){
              p.activityProductIndicationKeyMessages.forEach(k=> {
                  if(!uniqueKeyMessagesList.some(a=> a.indskr_keymessageid == k.indskr_keymessageid)){
                    let alreadyPresetObj = oldKeyMessagesList.find(b=> b.indskr_keymessageid == k.indskr_keymessageid);
                    isDirty = true;
                    uniqueKeyMessagesList.push({
                      indskr_keymessageid: k.indskr_keymessageid,
                      indskr_name: k.indskr_name,
                      indskr_keymessagesentiment: alreadyPresetObj ? alreadyPresetObj.indskr_keymessagesentiment : 'NEUTRAL',
                    });
                  }
              })
            }
          })
        }
        if(isDirty){
          uniqueKeyMessagesList = uniqueKeyMessagesList.sort((a,b) => {
            let c = a['indskr_name'].toLowerCase();
            let d = b['indskr_name'].toLowerCase();
            if (c < d)
                return -1;
            if (c > d)
                return 1;
            return 0;
          });
          let contact = activity.contacts.find(c=> c.ID == acs.indskr_contactid);
          if(contact){
            let conObj = {
              indskr_contactid: contact.ID,
              indskr_name: contact.fullName.trim(),
              indskr_isguest: contact.isguest,
              indskr_joinstatus: contact.connectionState,
              indskr_isremote: contact.isremote,
              indskr_leftmanually: contact.isleftmanually,
              indskr_sequence: contact.indskr_sequence,
            };
            if (contact.join_date && contact.join_date != "" && contact.join_date != "null") {
              conObj['indskr_joineddate'] = isValid(new Date(contact.join_date)) ? format(contact.join_date, 'YYYY-MM-DDTHH:mm:ssZ') : format(parseFloat(contact.join_date), 'YYYY-MM-DDTHH:mm:ssZ');
            }
            if (contact.left_date && contact.left_date != "" && contact.left_date != "null") {
              conObj['indskr_leftdate'] = isValid(new Date(contact.left_date)) ? format(contact.left_date, 'YYYY-MM-DDTHH:mm:ssZ') : format(parseFloat(contact.left_date), 'YYYY-MM-DDTHH:mm:ssZ');
            }
            conObj['activityKeyMessageSentiments'] = uniqueKeyMessagesList;
            acs.activityKeyMessageSentiments = uniqueKeyMessagesList;
            obj.push(conObj)
          }
        }
      })
    }
    return obj;
  }

  public async createMeetingFromShortCallHome(account: Account, contact: Contact, presentation: Presentation, ecard: boolean, accountLocation: string, accountCoordinates: LocationCoordinates) {
    const start = new Date().getTime();
    const meetingDuration = ecard ? 5 : this.authenticationService.user?.meeting_duration || 30;
    const end = addMinutes(start, meetingDuration).getTime();
    const subject = `${contact.fullName} - ${this.translate.instant('SHORT_MEETING')}`;
    const location = accountLocation ?? '';
    const shortCallPayload = new InitiateMeetingPayload(subject, location, start, end, '', OFFLINE_ID_PREFIX + start, '', ecard);
    const newMeeting = await this.createNewMeeting(shortCallPayload, true, false, contact.fullName);
    if (accountCoordinates) {
      newMeeting.indskr_meetinglocationlongitude = accountCoordinates.longitude;
      newMeeting.indskr_meetinglocationlatitude = accountCoordinates.latitude;
    }
    this.activityService.selectedActivity = newMeeting;
    this.accountService.moveAccountToSelected(account);
    this.activityService.selectedActivity['contacts']= [contact];
    this.activityService.selectedActivity['presentations'] = [presentation];
    let shortcall = this.activityService.selectedActivity as AppointmentActivity;
    Promise.all([
      this.addAccountsToMeeting(shortcall),
      this.addContactsToMeeting(shortcall, true),
      this.addContentToMeeting(shortcall.presentations, shortcall)
    ]).then(async (data) => {
      if (Array.isArray(data) && data.length == 3 && data[1] == false) {
        await this.activityService.upsertMeetingsOfflineData(shortcall, false, true);
      }
    }).catch(async err => {
      console.error('createMeetingFromShortCallHome: onAddingMeetingDetails: ', err);
      await this.activityService.upsertMeetingsOfflineData(shortcall, false, true);
    });
    await this.disk.updateOrInsertActivityToActivityDetailRawDocument(shortcall);
  }



  async generateMeetingUrl(meetingId){
    let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.GENERATE_MEETING_URL;
    url = url.replace('{meetingId}', meetingId);
    let headers = new HttpHeaders();
    headers = headers.set(
      "X-BusinessUnitId",
      this.authenticationService.user.xBusinessUnitId
    );
    const response: any = await this.http.put(url,{},{headers}).toPromise();
    return response;
  }

  async assignOwner(payload, meetingId) : Promise<boolean> {
    try {
      let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.ASSIGN_MEETING_OWNER;
      url = url.replace('{activityId}', meetingId);
      await this.http.patch(url, payload).toPromise();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async updateMeetingMarketingBusinessPlanOffline(meetingId, marketingBusinessPlan){
    let activity = this.activityService.activities.find(item => item.ID === meetingId) as AppointmentActivity;
    activity.marketingBusinessPlanId = marketingBusinessPlan.ID ? marketingBusinessPlan.ID : "";
    activity.marketingBusinessPlanName = marketingBusinessPlan.brandPlanName ? marketingBusinessPlan.brandPlanName : "";
    await this.activityService.upsertMeetingsOfflineData(activity);
  }

  async updateMeetingCoachingPlanOffline(meetingId, coachingPlan){
    let activity = this.activityService.activities.find(item => item.ID === meetingId) as AppointmentActivity;
    activity.indskr_coachingplan = coachingPlan && coachingPlan.id ? coachingPlan.id : "";
    activity.indskr_coachingplanname = coachingPlan && coachingPlan.primaryTextRight ? coachingPlan.primaryTextRight : "";
    await this.activityService.upsertMeetingsOfflineData(activity);
  }

  async updateMeetingMarketingBusinessPlanOnline(mode, payload, meetingId) {
    try {
      let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING_MARKETING_BUSINESS_PLAN;
      url = url.replace('{activityId}', meetingId);
      if (mode === 'Delete') {
        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          body: payload
        };
        await this.http.delete(url, options).toPromise();
      } else {
        await this.http.patch(url, payload).toPromise();
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async updateMeetingCoachingPlanOnline(mode, payload, meetingId) {
    try {
      let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING_COACHING_PLAN;
      url = url.replace('{activityId}', meetingId);
      await this.http.patch(url, payload).toPromise();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async recordShortCallContentShare(presentation: Presentation, activityId: string, contactId: string) {
    if (!presentation || !activityId || !contactId) {
      return;
    }
    const url = this.authenticationService.userConfig.activeInstance.entryPointUrl
      + Endpoints.meeting.CONTENT_SHARE.replace(
        '{activity_id}',
        activityId,
      );
    const payload = {
      indskr_contactid: contactId,
      indskr_iopresentationid: presentation.contentId,
      indskr_startdate: '' + new Date().getTime(),
      indskr_contenttype: ActivityContentShareTypeOptionSetValue.ShareContentLinkToMessagingApp,
      indskr_presentationlink: presentation.indskr_externalcontentlink,
      indskr_user: this.authenticationService.user.xSystemUserID,
      indskr_name: presentation.name
    };
    const headers = Endpoints.headers.content_type.json;
    try {
      await this.http.post(url, payload, headers).toPromise();
    } catch (error) {
      console.error('recordShortCallContentShare: ', error);
    }
  }

  async appendMeetingLatAndLongForGpsDistanceCalculation(activity: AppointmentActivity) {
    if (
      this.authenticationService.user.buSettings
      && this.authenticationService.user.buSettings['indskr_capturegpsdistanceaccountwhencontentplaye']
      && (
        !activity.indskr_meetinglocationlongitude
        || !activity.indskr_meetinglocationlatitude
      )
    ) {
      const account = Array.isArray(activity?.accounts) && activity?.accounts[0] ? activity.accounts[0] : undefined;
      if (account) {
        const selectedAccountAddress = await this.accountService.getPrimaryAddressOfAccount(account.id);
        if (selectedAccountAddress) {
          activity.indskr_meetinglocationlongitude = selectedAccountAddress.longitude;
          activity.indskr_meetinglocationlatitude = selectedAccountAddress.latitude;
        }
      }
    }
  }

  toastNotification(data, notification) {
    if (notification == 'fromContact') {
      this.notificationService.notify(`${data.fullname} ${this.translate.instant("ACCOUNT_AND_CONTACT_NOTIFICATION_FOR_MEETING")}`, "");
      return;
    } else if (notification == 'fromAccount') {
      this.notificationService.notify(`${data.accountName} ${this.translate.instant("ACCOUNT_AND_CONTACT_NOTIFICATION_FOR_MEETING")}`, "");
      return;
    }
  }



  // ===== new ui related api calls ===== //

  setMeetingSubject(meeting) {
    if (this.secondaryInfoService.isAppointmentSecInfoConfigured) {
      // if (this.secondaryInfoService.checkIfSchemaIsConfig('indskr_accountid', SecondaryInfoEntityName.Appointment)) {
      const subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForAppointment(meeting, SecondaryInfoEntityName.Appointment);
      return subject;
    } else if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
      meeting.subject = [this.translate.instant('MEETING'), meeting.activityTypeName, meeting.meetingOwnerName].filter(Boolean).join(" - ");
      const subject = this.getMeetingSubject(meeting);
      return subject;
    }

    return meeting.subject;
  }

  setMeetingSubjectFromContact(meeting, ownerName) {
    const isAcinoBaseUser = this.authenticationService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
    const isAutoSubjectEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT);

    if (isAutoSubjectEnabled || isAcinoBaseUser) {
      let subject: string = this.translate.instant('MEETING');
      let subPrefix = ` - ${subject} - ${meeting.activityTypeName ? meeting.activityTypeName + ' - ' : ' '} ${ownerName}`;
      const selectedContacts = Array.isArray(meeting.contacts)
        ? meeting.contacts
        : [];
      if (isAcinoBaseUser) {
        subject = `${meeting.activityTypeName ? meeting.activityTypeName + ' ' : ''}${this.translate.instant('VISIT')}`;
      }
      switch (selectedContacts.length) {
        case 0:
          if (!isAcinoBaseUser)
            subject = `${this.translate.instant('MEETING')} - ${meeting.activityTypeName ? meeting.activityTypeName + ' - ' : ' '} ${ownerName}`;
          break;
        case 1:
          subject = `${this.contactService.getFullNameForContactDisplaySequence(selectedContacts[0])}${subPrefix}`;
          if (isAcinoBaseUser) {
            subject = `${meeting.activityTypeName ? meeting.activityTypeName + ' ' : ''}${this.translate.instant('VISIT')} - ${this.contactService.getFullNameForContactDisplaySequence(selectedContacts[0])}`;
          }
          break;
        default:
          if (selectedContacts.length > 1) {
            if (!this.contactService.isMeetingContactSequenceEnabled) {
              selectedContacts.sort((contactA, contactB): number => {
                let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
                let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
                if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
                if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;

                return 0;
              });
            } else {
              selectedContacts.sort((contactA, contactB) => {
                return contactA.indskr_sequence - contactB.indskr_sequence;
              });
            }
            subject = `${this.contactService.getFullNameForContactDisplaySequence(selectedContacts[0])} + ${selectedContacts.length - 1}${subPrefix}`;
            if (isAcinoBaseUser) {
              subject = `${meeting.activityTypeName ? meeting.activityTypeName + ' ' : ''}${this.translate.instant('VISIT')} - ${this.contactService.getFullNameForContactDisplaySequence(selectedContacts[0])}`;
            }
          }
      }
      if (this.activityService.selectedActivity.subject !== subject) {
        this.activityService.selectedActivity.subject = subject;
      }
    }
  }

  async updateMeetingWithAccounts(action: String, accountsToUpdate: Account) {
    await this.uiService.displayLoader();
    let affiliatedContact: Contact;
    const meeting = this.activityService.selectedActivity as AppointmentActivity;
    const currentUser = this.authenticationService.user;
    let meetingSubjectUpdate = false;
    const isAcinoBaseUser = this.authenticationService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
    const isAutoSubjectEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT);
    
    if (action === 'add') {

      if (meeting.accounts.length === 0 && meeting.contacts.length === 0) {
        const affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([accountsToUpdate]);
        if (affiliatedContacts.length === 1) {
          affiliatedContact = affiliatedContacts[0];
        }
      }

      this.accountService.moveAccountToSelected(accountsToUpdate);

      if (affiliatedContact) {
        this.contactService.moveContactToSelected(affiliatedContact);

        if (this.activityService.selectedActivity.location !== "LiveMeet") {
          if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) || (this.authenticationService.user.buConfigs && this.authenticationService.user.buConfigs['indskr_populateaccountaddress'])) {
            await this.contactService.setLocationField(affiliatedContact);
          }
        }
      }

      if ((this.authenticationService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) || (this.authenticationService.user.buConfigs && this.authenticationService.user.buConfigs['indskr_populateaccountaddress'])) && !this.activityService.isRemoteChannelEnabled((this.activityService.selectedActivity as AppointmentActivity).indskr_activitytype)) {
        await this.addAccountAddressToMeeting();
      }

      meetingSubjectUpdate = this.secondaryInfoService.checkIfSchemaIsConfig('indskr_accountid', SecondaryInfoEntityName.Appointment) ||
      (affiliatedContact && this.secondaryInfoService.checkIfSchemaIsConfig('indskr_contactid', SecondaryInfoEntityName.Appointment));

      this.setMeetingSubjectFromContact(meeting, meeting.meetingOwnerName);
    
      if (this.secondaryInfoService.isAppointmentSecInfoConfigured && meetingSubjectUpdate) {
        meeting.subject = await this.setMeetingSubject(meeting);
      }

      if (!this.deviceService.isOffline) {
        try {
          if ((this.secondaryInfoService.isAppointmentSecInfoConfigured && meetingSubjectUpdate) || isAutoSubjectEnabled || isAcinoBaseUser) {
            let payload = new UpdateMeetingPayload(
              meeting.subject,
              meeting.location,
              meeting.scheduledStart,
              meeting.scheduledEnd,
              meeting.notes,
              false
            );
            this.updateMeeting(meeting, payload);
          }
          const response: any = await this.appendAccountsToMeeting(meeting.ID, [accountsToUpdate]);

          if ((this.authenticationService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) || (currentUser.buConfigs && currentUser.buConfigs['indskr_populateaccountaddress'])) && !this.activityService.isRemoteChannelEnabled(meeting.indskr_activitytype)) {
            this.updateMeetingLocation(meeting);
          }

          if (affiliatedContact) {
            const response = await this.appendContactsToMeeting(meeting.ID, [affiliatedContact]);
            let activityContacts = meeting._raw_contactAttendees;
            if (activityContacts) {
              activityContacts = [...activityContacts, ...response];
            } else {
              activityContacts = [...response];
            }
            meeting._raw_contactAttendees = activityContacts;
          }

          let activityAccounts = meeting._raw_activityAccounts;
          if (activityAccounts) {
            activityAccounts = [...activityAccounts, ...response];
          } else {
            activityAccounts = [...response];
          }

          meeting._raw_activityAccounts = activityAccounts;
          this.updateActivities(meeting);
          await this.activityService.upsertMeetingsOfflineData(meeting);
          await this.uiService.dismissLoader();
        } catch (error) {
          await this.uiService.dismissLoader();
          await this.activityService.upsertMeetingsOfflineData(meeting, false, true);
        }
      } else {
        await this.activityService.upsertMeetingsOfflineData(meeting);
        await this.uiService.dismissLoader();
      }

    } else if (action === 'remove') {
      let contactToRemove;
      // if (meeting.contacts.length === 1 && meeting.accounts.length === 1) {
      //   let affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(meeting.accounts);
      //   if (affiliatedContacts.length === 1 && affiliatedContacts[0].ID == meeting.contacts[0].ID) {
      //     contactToRemove = affiliatedContacts[0]
      //   }
      // }

      meeting.accounts = meeting.accounts.filter((account) => account.id !== accountsToUpdate.id);

      if ((this.authenticationService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) || (currentUser.buConfigs && currentUser.buConfigs['indskr_populateaccountaddress'])) && !this.activityService.isRemoteChannelEnabled(meeting.indskr_activitytype)) {
        this.addAccountAddressToMeeting() // this is used to reset the location as well
      }

      if (contactToRemove) {
        meeting.contacts = meeting.contacts.filter((con) => con.ID !== contactToRemove.ID);
      }

      meetingSubjectUpdate = this.secondaryInfoService.checkIfSchemaIsConfig('indskr_accountid', SecondaryInfoEntityName.Appointment) ||
      (affiliatedContact && this.secondaryInfoService.checkIfSchemaIsConfig('indskr_contactid', SecondaryInfoEntityName.Appointment));

      this.setMeetingSubjectFromContact(meeting, meeting.meetingOwnerName);

      if (meetingSubjectUpdate && this.secondaryInfoService.isAppointmentSecInfoConfigured) {
        meeting.subject = await this.setMeetingSubject(meeting);
      }

      if (!this.deviceService.isOffline) {
        try {
          const response: any = await this.removeAccountsFromMeeting(this.activityService.selectedActivity.ID, [accountsToUpdate]);
          meeting._raw_activityAccounts = this.removeRawActivityAccounts(meeting, accountsToUpdate.id);
          if (contactToRemove) {
            await this.removeContactsFromMeeting(meeting.ID, [contactToRemove]);
            meeting._raw_contactAttendees = this.removeRawContactAttendies(meeting, contactToRemove.ID);
          }

          if ((this.authenticationService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) || (currentUser.buConfigs && currentUser.buConfigs['indskr_populateaccountaddress'])) && !this.activityService.isRemoteChannelEnabled(meeting.indskr_activitytype)) {
            this.updateMeetingLocation(meeting);
          }

          if ((this.secondaryInfoService.isAppointmentSecInfoConfigured && meetingSubjectUpdate) || isAutoSubjectEnabled || isAcinoBaseUser) {
            let payload = new UpdateMeetingPayload(
              meeting.subject,
              meeting.location,
              meeting.scheduledStart,
              meeting.scheduledEnd,
              meeting.notes,
              false
            );
            this.updateMeeting(meeting, payload);
          }

          await this.uiService.dismissLoader();
          await this.activityService.upsertMeetingsOfflineData(meeting);
        } catch (error) {
          await this.uiService.dismissLoader();
          await this.activityService.upsertMeetingsOfflineData(meeting, false, true);
        }
      } else {
        await this.uiService.dismissLoader();
        await this.activityService.upsertMeetingsOfflineData(meeting);
      }
    }
  }

  async addAccountAddressToMeeting() {
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {
      let meeting = this.activityService.selectedActivity;
      if (this.activityService.selectedActivity['accounts'].length != 0) {
        const selectedAddress = await this.accountService.getPrimaryAddressOfAccount(this.activityService.selectedActivity['accounts'][0].id);
        if (selectedAddress) {
          meeting.location = selectedAddress.compositeAdd;
          meeting.indskr_meetinglocationlongitude = selectedAddress.longitude ? selectedAddress.longitude : null;
          meeting.indskr_meetinglocationlatitude = selectedAddress.latitude ? selectedAddress.latitude : null;
        } else {
          meeting.location = '';
          meeting.indskr_meetinglocationlongitude = null;
          meeting.indskr_meetinglocationlatitude = null;
        }
      } else {
        meeting.location = '';
        meeting.indskr_meetinglocationlongitude = null;
        meeting.indskr_meetinglocationlatitude = null;
      }
    }
  }

  async updateMeetingWithContacts(action: String, contactToUpdate: Contact) {
    await this.uiService.displayLoader();
    let affiliatedAccount: Account;
    const meeting = this.activityService.selectedActivity as AppointmentActivity;
    const currentUser = this.authenticationService.user;
    let meetingSubjectUpdate = false;
    const isAcinoBaseUser = this.authenticationService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
    const isAutoSubjectEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT);

    if (action === 'add') {

      if (meeting.accounts.length === 0 && meeting.contacts.length === 0) {
        const affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([contactToUpdate]);
        if (affiliatedAccounts.length === 1) {
          affiliatedAccount = affiliatedAccounts[0];
        } else if (contactToUpdate.primaryAccount) {
          affiliatedAccount = affiliatedAccounts.find((affiliatedAccount) => affiliatedAccount.id === contactToUpdate.primaryAccount.id);
        }
      }

      this.contactService.moveContactToSelected(contactToUpdate);

      if (affiliatedAccount) {
        this.accountService.moveAccountToSelected(affiliatedAccount);

        if (this.activityService.selectedActivity.location !== "LiveMeet") {
          if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) || (this.authenticationService.user.buConfigs && this.authenticationService.user.buConfigs['indskr_populateaccountaddress'])) {
            await this.contactService.setLocationField(contactToUpdate);
          }
        }
      }

      meetingSubjectUpdate = this.secondaryInfoService.checkIfSchemaIsConfig('indskr_contactid', SecondaryInfoEntityName.Appointment) ||
      (affiliatedAccount && this.secondaryInfoService.checkIfSchemaIsConfig('indskr_accountid', SecondaryInfoEntityName.Appointment));

      this.setMeetingSubjectFromContact(meeting,meeting.meetingOwnerName);
      if (this.secondaryInfoService.isAppointmentSecInfoConfigured && meetingSubjectUpdate) {
        meeting.subject = await this.setMeetingSubject(meeting);
      }

      if (!this.deviceService.isOffline) {
        try {
          if ((this.secondaryInfoService.isAppointmentSecInfoConfigured && meetingSubjectUpdate) || isAutoSubjectEnabled || isAcinoBaseUser) {
            let payload = new UpdateMeetingPayload(
              meeting.subject,
              meeting.location,
              meeting.scheduledStart,
              meeting.scheduledEnd,
              meeting.notes,
              false
            );
            this.updateMeeting(meeting, payload);
          }
          const response: any = await this.appendContactsToMeeting(meeting.ID, [contactToUpdate]);
          let activityContacts = meeting._raw_contactAttendees;
          if (activityContacts) {
            activityContacts = [...activityContacts, ...response];
          } else {
            activityContacts = [...response];
          }
          meeting._raw_contactAttendees = activityContacts;

          if ((this.authenticationService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) || (currentUser.buConfigs && currentUser.buConfigs['indskr_populateaccountaddress'])) && !this.activityService.isRemoteChannelEnabled(meeting.indskr_activitytype)) {
            this.updateMeetingLocation(meeting);
          }

          if (affiliatedAccount) {
            const response = await this.appendAccountsToMeeting(meeting.ID, [affiliatedAccount]);
            let activityAccounts = meeting._raw_activityAccounts;
            if (activityAccounts) {
              activityAccounts = [...activityAccounts, ...response];
            } else {
              activityAccounts = [...response];
            }
            meeting._raw_activityAccounts = activityAccounts;
          }
          await this.activityService.upsertMeetingsOfflineData(meeting);
          await this.uiService.dismissLoader();
        } catch (error) {
          await this.uiService.dismissLoader();
          await this.activityService.upsertMeetingsOfflineData(meeting, false, true);
        }
      } else {
        await this.uiService.dismissLoader();
        await this.activityService.upsertMeetingsOfflineData(meeting);
      }

    } else if (action === 'remove') {
      let accountToRemove;
      // if (meeting.contacts.length === 1 && meeting.accounts.length === 1) {
      //   let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting(meeting.contacts);
      //   if (affiliatedAccounts.length === 1 && affiliatedAccounts[0].id == meeting.accounts[0].id) {
      //     accountToRemove = affiliatedAccounts[0]
      //   } else if (affiliatedAccounts.length > 1) {
      //     const selectedAccount = affiliatedAccounts.find((affiliatedAccount) => affiliatedAccount.id === meeting.accounts[0].id);
      //     if (selectedAccount) {
      //       accountToRemove = selectedAccount;
      //     }
      //   }
      //  }

      meeting.contacts = meeting.contacts.filter((con) => con.ID !== contactToUpdate.ID);

      if (accountToRemove) {
        meeting.accounts = meeting.accounts.filter((account) => account.id !== accountToRemove.id);
      }

      this.setMeetingSubjectFromContact(meeting,meeting.meetingOwnerName);

      meetingSubjectUpdate = this.secondaryInfoService.checkIfSchemaIsConfig('indskr_contactid', SecondaryInfoEntityName.Appointment) ||
      (accountToRemove && this.secondaryInfoService.checkIfSchemaIsConfig('indskr_accountid', SecondaryInfoEntityName.Appointment));

      if (this.secondaryInfoService.isAppointmentSecInfoConfigured && meetingSubjectUpdate) {
        meeting.subject = await this.setMeetingSubject(meeting);
      }

      if (!this.deviceService.isOffline) {
        try {
          await this.removeContactsFromMeeting(meeting.ID, [contactToUpdate]);
          meeting._raw_contactAttendees = this.removeRawContactAttendies(meeting, contactToUpdate.ID);
          if (accountToRemove) {
            const response: any = await this.removeAccountsFromMeeting(meeting.ID, [accountToRemove]);
            meeting._raw_activityAccounts = this.removeRawContactAttendies(meeting, accountToRemove.id);
          }

          if ((this.secondaryInfoService.isAppointmentSecInfoConfigured && meetingSubjectUpdate) || isAutoSubjectEnabled || isAcinoBaseUser) {
            let payload = new UpdateMeetingPayload(
              meeting.subject,
              meeting.location,
              meeting.scheduledStart,
              meeting.scheduledEnd,
              meeting.notes,
              false
            );
            this.updateMeeting(meeting, payload);
          }

          await this.uiService.dismissLoader();
          this.updateActivities(meeting);
          await this.activityService.upsertMeetingsOfflineData(meeting);
        } catch (error) {
          await this.uiService.dismissLoader();
          await this.activityService.upsertMeetingsOfflineData(meeting, false, true);
        }
      } else {
        await this.uiService.dismissLoader();
        await this.activityService.upsertMeetingsOfflineData(meeting);
      }
    }
  }

  async appendContactsToMeeting(meetingId: string, contacts: Contact[]) {
    let payload = [];
    contacts.forEach((contact) => {
      payload.push({
        "contactId": contact.ID,
        "action": "create",
        "indskr_activitycontactid": Guid.create().toString()
      });
    });

    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING_CONTACT.replace(
      "{activityId}",
      meetingId
    );

    let response: any = await this.http.patch(url, payload).toPromise();
    response = response.map((x) => {
      let xy = { indskr_activitycontactid: x.indskr_activitycontactid, indskr_contactid: x.contactId }
      return xy;
    })
    console.log(response);
    return response;
  }

  async removeContactsFromMeeting(meetingId: string, removingContacts: Contact[]) {
    let payload = []
    const activityContacts = (this.activityService.selectedActivity as AppointmentActivity)._raw_contactAttendees;
    if (!activityContacts) return;

    removingContacts.forEach((contact) => {
      let activityContact:any = activityContacts.find((activityContact) => activityContact.indskr_contactid === contact.ID);
      if (!activityContact) return;
      payload.push({
        "contactId": contact.ID,
        "action": "delete",
        "indskr_activitycontactid": activityContact.indskr_activitycontactid ? activityContact.indskr_activitycontactid : activityContact.contactAttendeeId
      });
    });

    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING_CONTACT.replace(
      "{activityId}",
      meetingId
    );

    let response = await this.http.patch(url, payload).toPromise();
    return response;
  }

  async appendAccountsToMeeting(meetingId: string, accounts: Account[]) {

    const requestPayload = accounts.map(account => ({
      accountId: account.id,
      action: 'create',
      indskr_activityaccountid: Guid.create().toString(),
    }));

    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING_ACCOUNTS.replace("{activityId}", meetingId);

    try {
      let response: any = await this.http.patch(url, requestPayload).toPromise();
      const formattedResponse = response.map((item: any) => ({
        indskr_activityaccountid: item.indskr_activityaccountid,
        indskr_accountid: item.accountId,
      }));
      return formattedResponse;
    } catch (error) {
      throw error;
    }
  }

  async removeAccountsFromMeeting(meetingId: string, removingAccounts: Account[]) {
    let payload = []
    const activityAccounts = (this.activityService.selectedActivity as AppointmentActivity)._raw_activityAccounts;
    if (!activityAccounts) return;

    removingAccounts.forEach((account) => {
      let activityAccount = activityAccounts.find((activityAccount) => activityAccount.indskr_accountid === account.id);
      if (!activityAccount || !activityAccount.indskr_activityaccountid) return;
      payload.push({
        "accountId": account.id,
        "action": "delete",
        "indskr_activityaccountid": activityAccount.indskr_activityaccountid
      });
    });

    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.meeting.UPDATE_MEETING_ACCOUNTS.replace(
      "{activityId}",
      meetingId
    );

    let response = await this.http.patch(url, payload).toPromise();

    console.log(response);
    return response;

  }

  removeRawActivityAccounts(meeting: AppointmentActivity, accountId: string) {
    if (!meeting.accounts || meeting.accounts.length == 0) return [];
    let activityAccounts = meeting._raw_activityAccounts.filter((activityAccount) => activityAccount.indskr_accountid !== accountId);
    return activityAccounts;
  }

  removeRawContactAttendies(meeting: AppointmentActivity, contactId: string) {
    if (!meeting.contacts || meeting.contacts.length == 0) return [];
    let activityContacts = meeting._raw_contactAttendees.filter((contact) => contact.indskr_contactid !== contactId);
    return activityContacts;
  }

  fetchActivityAccountsByMeetingId(meetingId: string) {
    return new Promise(async (resolve, reject) => {
      let fetchXML = fetchQueries.fetchActivityAccountsByMeetingId;
      fetchXML = fetchXML.replace('{appointmentId}', meetingId);
      try {
        await this.dynamics.executeFetchQuery('indskr_activityaccounts', fetchXML).then(async (response) => {
          console.log(response);
          let activityAccounts = []
          if (response && response.length > 0) {
            activityAccounts = response.map((respAccount) => {
              const mappedAccount = {
                activityId: meetingId,
                indskr_accountid: respAccount['_indskr_accountid_value'],
                indskr_activityaccountid: respAccount['indskr_activityaccountid'] ,
                indskr_name: respAccount['indskr_name'],
              }
    
              return mappedAccount;
            })
          }
          resolve(activityAccounts)
        });
      } catch (error) {
        reject([])
      }
    });
  }

  fetchActivityContactAttendiesByMeetingId(meetingId: string) {
    return new Promise(async (resolve, reject) => {
      let fetchXML = fetchQueries.fetchActivityContactAttendessByMeetingId;
      fetchXML = fetchXML.replace('{appointmentId}', meetingId);
      try {
        await this.dynamics.executeFetchQuery('indskr_activitycontacts', fetchXML).then(async (response) => {
          console.log(response);
          let activityContact = []
          if (response && response.length > 0) {
            activityContact = response.map((respContact) => {
              const mappedAccount = {
                activityId: meetingId,
                indskr_contactid: respContact['_indskr_contactid_value'],
                indskr_activitycontactid: respContact['indskr_activitycontactid'],
                indskr_name: respContact['indskr_name'],
                //                 indskr_isguest: false
                // indskr_isremote: false
                // indskr_leftmanually: false
              }
    
              return mappedAccount;
            })
          }
          resolve(activityContact)
        });
      } catch (error) {
        reject([])
      }
    });
  }

  async addActivityAccountsAndContactAttendiesToMeeting(meeting: AppointmentActivity) {
    if (this.deviceService.isOffline) return;

    if (meeting.accounts.length > 0) {
      const activityAccounts: any = await this.fetchActivityAccountsByMeetingId(meeting.ID);
      if (activityAccounts) {
        meeting._raw_activityAccounts = activityAccounts;
      }
    }

    if (meeting.contacts.length > 0) {
      const activityContacts: any = await this.fetchActivityContactAttendiesByMeetingId(meeting.ID);
      if (activityContacts) {
        meeting._raw_contactAttendees = activityContacts;
      }
    }

    this.updateActivities(meeting);
  }

  updateActivities(meeting){
    let index = this.activityService.activities.findIndex(a => a.ID === meeting.ID);
    if(index >= 0){
      this.activityService.activities[index] = meeting;
    }
  }
  
}


export class UpdateAppointmentActivityPayload {
  public activityAccounts?: Array<Account>;
  public activityPresentations?: Array<Presentation>;
  public activityProducts?: Array<Products>;
  public contactAttendees?: Array<Contact>;

  constructor(activity: AppointmentActivity) { }
}

export class UpdateMeetingPayload {
  public subject?: string;
  public location?: string;
  public scheduledStart?: Date;
  public scheduledEnd?: Date;
  public isBestTime?: boolean;
  public notes?: string;
  public meetingURL: string;
  public indskr_meetingtype: number;
  public meetingNotes?: Array<IONote>;
  public indskr_meetinglocationlatitude?:number;
  public indskr_meetinglocationlongitude?:number;
  public meetingOutcomeId?:string;
  public meetingOutcomeString?:string;
  public indskr_kitbooking?: string;
  public indskr_shortcall?:Boolean;
  public indskr_suggestionrescheduled?: Boolean

  constructor(
    subject?: string,
    location?: string,
    start?: Date,
    end?: Date,
    notes?: string,
    isBestTime?: boolean,
    meetingURL?: string,
    indskr_meetingtype?: number,
    meetingNotes?: Array<IONote>,
    indskr_meetinglocationlatitude?:number,
    indskr_meetinglocationlongitude?:number,
    meetingOutcomeId?:string,
    meetingOutcomeString?:string,
    indskr_kitbooking?: string,
    indskr_shortcall?: Boolean,
    indskr_suggestionrescheduled?: Boolean
  ) {
    this.scheduledStart = start;
    this.scheduledEnd = end;
    this.location = location;
    this.subject = subject;
    this.notes = notes;
    this.isBestTime = isBestTime;
    this.meetingURL = meetingURL;
    this.indskr_meetingtype = indskr_meetingtype;
    this.meetingNotes = meetingNotes;
    this.indskr_meetinglocationlatitude = indskr_meetinglocationlatitude;
    this.indskr_meetinglocationlongitude = indskr_meetinglocationlongitude;
    this.meetingOutcomeId = meetingOutcomeId;
    this.meetingOutcomeString = meetingOutcomeString;
    this.indskr_kitbooking = indskr_kitbooking;
    this.indskr_shortcall = indskr_shortcall;
    this.indskr_suggestionrescheduled = indskr_suggestionrescheduled;
  }

  public getRequestBody() {
    return {
      location: this.location,
      scheduledstart: this.scheduledStart,
      scheduledend: this.scheduledEnd,
      isbesttime: this.isBestTime,
      subject: this.subject,
      indskr_notes: this.notes,
      indskr_meetingtype: this.indskr_meetingtype,
      indskr_meetinglocationlatitude : this.indskr_meetinglocationlatitude,
      indskr_meetinglocationlongitude : this.indskr_meetinglocationlongitude,
      indskr_kitbooking: this.indskr_kitbooking,
      indskr_shortcall: this.indskr_shortcall,
      indskr_suggestionrescheduled: this.indskr_suggestionrescheduled
    };
  }
}


export class UpdateTypeAndSubTypeActivityPayLoad {
  public name?: string;
  public pluralEntityName?: string;
  public entity?: string;
  public id?: string;

  constructor(
    name?: string,
    pluralEntityName?: string,
    entity?: string,
    id?: string,
  ) {
    this.name = name;
    this.pluralEntityName = pluralEntityName;
    this.entity = entity;
    this.id = id;
  }

  public getRequestBody() {
    let requestBody = {
      'appconfiglookupfields': [{ name: this.name, pluralEntityName: this.pluralEntityName, entity: this.entity, id: this.id }]
    };
    return requestBody;
  }
}

export class InitiateMeetingPayload {
  public subject: string;
  public location: string;
  public scheduledStart: number;
  public scheduledEnd: number;
  public notes: string;
  public offlineMeetingId: string;
  public eventId: string;
  public accountPlanId: string;
  public shortCall: boolean;
  public kitBookingId: string;

  constructor(
    subject: string,
    location: string,
    start: number,
    end: number,
    notes: string,
    offlineMeetingId: string,
    accountPlanId?: string,
    shortCall?: boolean,
    kitBookingId?: string
  ) {
    this.scheduledStart = start;
    this.scheduledEnd = end;
    this.location = location;
    this.subject = subject;
    this.notes = notes;
    this.offlineMeetingId = offlineMeetingId;
    this.accountPlanId = accountPlanId;
    this.shortCall = shortCall;
    this.kitBookingId = kitBookingId;
  }

  public getRequestBody() {
    return {
      location: this.location,
      scheduledstart: this.scheduledStart,
      scheduledend: this.scheduledEnd,
      subject: this.subject,
      indskr_notes: this.notes,
      offlineMeetingId: this.offlineMeetingId,
      accountPlanId: this.accountPlanId,
      indskr_shortcall: this.shortCall,
      indskr_kitbooking: this.kitBookingId
    };
  }
}

export class MeetingProduct {
  private productId: string;
}

export interface UpdateKeyMessagesFor {
  products:boolean;
  diseaseAreas:boolean;
  indications:boolean;
}
