<div class="inner-content">
  <ion-toolbar class="draw-hr" *ngIf="title">
    <ion-title class="modal-title">{{ title }}</ion-title>
    <ion-icon [name]="titleEndIcon" slot="end" class="heading-icon ion-margin primary" (click)="onHeaderClick()"></ion-icon>
  </ion-toolbar>

  <ion-list *ngIf="showSearch">
    <ion-item class="search-item">
      <ion-label position="stacked">{{ searchInputConfig.label }}</ion-label>
      <ion-input #listSearchInput [placeholder]="searchInputConfig.placeholder" type="search" class="modal-input"
        (ionInput)="onSearch(($event).target.value)" [(ngModel)]="searchText" [ngClass]="isDisabled ? 'input-disabled' : ''"></ion-input>
      <ion-icon name="searchblue" slot="end" class="endicon" size="small"></ion-icon>
    </ion-item>
  </ion-list>

  <ng-container *ngFor="let section of clonedSections">
    <ion-list class="suggesstion-list" lines="none" *ngIf="section.data.length > 0 || (section.showFilteredData && searchText != '')">
      <ng-container *ngIf="section.data.length > 0">
        <ion-list-header>
          <ion-label>{{ section.title }}</ion-label>
          <ion-text>{{ section.data.length > section.maxResultsView ? section.maxResultsView : section.data.length }}/{{
            section.data.length
            }}</ion-text>
        </ion-list-header>
  
        <ng-container *ngIf="!section.expand">
          <ng-container *ngTemplateOutlet="chipTemplate; context: { data: section.data, section }"></ng-container>
        </ng-container>
  
        <ng-container *ngIf="section.expand">
  
          <ion-item class="result-list-item" *ngFor="let data of section.data | slice:0:section.maxResultsView;let i=index;">
            <ng-container *ngTemplateOutlet="listttemTemplate; context: { data , index: i }"></ng-container>
          </ion-item>
    
        </ng-container>
      </ng-container>

      <div *ngIf="section.data.length == 0 && section.showFilteredData" class="ion-text-center no-search-div">
        <p class="no-search-text">{{ 'NO_RESULTS_FOR_THIS_SEARCH' | translate }}</p>
      </div>

      
    </ion-list>
    <div class="view-all-item" *ngIf="showBottomButton(section)" (click)="onButtonClick(section)">{{ setButtonLabel(section) }}</div>
  </ng-container>
  

  <div class="cloader-container" *ngIf="searchInProgress">
    <div class="cloader"></div>
    <p>{{ 'SEARCHING' | translate }}...</p>
  </div>
</div>

<ng-template #chipTemplate let-data="data" let-section="section">
  <ion-item>
    <ion-chip class="custom-chip">
      <ion-avatar>
        <img *ngIf="data[0].image" alt="Silhouette of a person's head" [src]="data[0].image" />
        <div class="initial-div" *ngIf="!data.image">{{ getInitials(data[0].title) }}</div>
      </ion-avatar>
      <ion-label>{{data[0].title}}</ion-label>
      <ion-icon name="closebutton" class="chip-icon" (click)="onChipClick(data[0], 'closebutton')" [ngClass]="isDisabled ? 'disabled' : ''"></ion-icon>
    </ion-chip>

    <ion-chip class="custom-chip" *ngIf="data.length === 2">
      <ion-avatar>
        <img *ngIf="data[1].image" alt="Silhouette of a person's head" [src]="data[1].image" />
        <div class="initial-div" *ngIf="!data.image">{{ getInitials(data[1].title) }}</div>
      </ion-avatar>
      <ion-icon name="chevron-down-outline" size="small" (click)="sectionExpand(section, true)"></ion-icon>
    </ion-chip>

    <ion-chip class="custom-chip" *ngIf="data.length > 2">
      <ion-label>+{{ data.length - 1 }}</ion-label>
      <ion-icon name="chevron-down-outline" size="small" (click)="sectionExpand(section, true)" class="end-icon"></ion-icon>
    </ion-chip>
  </ion-item>
</ng-template>

<ng-template #listttemTemplate let-data="data" let-index="index">
  <ion-avatar slot="start">
    <img *ngIf="data.image" alt="Silhouette of a person's head" [src]="data.image" />
     <div class="initial-div" *ngIf="!data.image">{{ getInitials(data.title) }}</div>
  </ion-avatar>
  <ion-label class="list-label" (click)="onItemClick(data)"> {{ data.title }} </ion-label>
  <ion-icon *ngIf="data.endIcon" [name]="data.endIcon" size="small" class="end-icon" [ngClass]="isDisabled ? 'disabled' : ''" (click)="onChipClick(data, data.endIcon)"></ion-icon>
</ng-template>

 