import { OrderContact } from '@omni/classes/activity/surgery-order.activity.class';
import { Activity } from './activity.class';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { addMinutes } from 'date-fns';
import { TrackAction } from '../../utility/common-enums';
import { Utility } from '../../utility/util';
import { OrderProductClass, OrderTypes } from './order.activity.class';
import * as moment from 'moment';
import { IONote } from '../io/io-note.class';
import * as _ from 'lodash'
export class ProcedureTrackerActivity extends Activity {

  public offlineId: string;
  public ownerNameString: string;
  public accountId: string;
  public accountNameString: string;
  public isAccountActive: boolean;
  public createdDate: Date;
  public pendingPushToDynamics: boolean;
  public isTeamOrder: boolean = false;
  public isHardDeleted: boolean = false;
  public orderType: any;
  public orderTypeFormatted: OrderTypes;
  public weekOfYear: number;
  public year: number;
  public month: number;
  public appointmentId: string;
  public offlineMeetingId: string;
  public procedureNotes: Array<IONote> = [];
  public indskr_datecompleted: string;
  public ordernumber: string;
  public divisionId: string;
  public divisionName: string;
  public modifiedby: string;
  public modifiedByName: string;
  public modifiedon: string;
  public procedureTrackers: Array<ProcedureTracker> = [];
  public coOwners: CoOwner[] = [];
  public opportunityNameString?: string;
  public opportunityId?: string;
  public offlineOpportunityId: string;
  public opportunities: Opportunities[] = [];


  constructor(raw: any) {
    super(raw);
    if (!this.ID) {
      this.ID = raw['salesorderid'];
      if (!this.ID) {
        this.ID = raw['indskr_externalid'];
      }
    }
    this.ordernumber = (raw['ordernumber']) ? raw['ordernumber'] : '';
    this.divisionId = (raw['divisionId']) ? raw['divisionId'] : '';
    this.divisionName = (raw['divisionName']) ? raw['divisionName'] : '';
    this.offlineId = (raw['indskr_externalid']) ? raw['indskr_externalid'] : '';
    this.ownerId = raw['ownerid'] || '';
    this.modifiedby = raw['modifiedby'] || '';
    this.modifiedon = raw['modifiedon'] || '';
    this.ownerNameString = (raw['ownerName']) ? raw['ownerName'] : '';
    this.modifiedByName = (raw['modifiedByName']) ? raw['modifiedByName'] : '';
    if (raw['statuscode']) {
      this.status = parseInt(raw['statuscode']);
    }
    if (raw['procedureTrackers'] && !_.isEmpty(raw['procedureTrackers'])) {
      const procedureTrackers = raw['procedureTrackers'].map(pt => new ProcedureTracker(pt));
      this.procedureTrackers = _.orderBy(procedureTrackers, ['createdon'], ['desc']);
    }
    this.subject = (raw['name']) ? raw['name'] : 'Bulk Procedure Log';
    this.accountId = (raw['customerid']) ? raw['customerid'] : '';
    this.appointmentId = (raw['appointmentId']) ? raw['appointmentId'] : '';
    this.offlineMeetingId = (raw['offlineMeetingId']) ? raw['offlineMeetingId'] : '';
    this.accountNameString = (raw['customerName']) ? raw['customerName'] : '';
    this.createdDate = (raw['createdon']) ? new Date(parseInt(raw['createdon'])) : null;
    if (raw['createdon'] && !raw['scheduledstart']) { //indskr_scheduleddate
      // Manually create the schedule start and scheduled end from createdon field untill MSE introduces these fields in st.
      let roundedDownTimeForAgenda = Utility.getRoundedDownTime(new Date(parseInt(raw['createdon'])));
      this.scheduledStart = roundedDownTimeForAgenda;
      this.scheduledEnd = addMinutes(roundedDownTimeForAgenda, 30);
      this.scheduledStartLocale = this.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    }
    this.isHardDeleted = (raw['track_action'] && raw['track_action'] == TrackAction.Deleted) ? true : (raw['isHardDeleted'] ? raw['isHardDeleted'] : false);
    this.pendingPushToDynamics = (raw['pendingPushToDynamics']) ? raw['pendingPushToDynamics'] : null;
    this.opportunityId = raw['opportunityid'] || '';
    this.opportunityNameString = raw['opportunityName'] || '';
    this.offlineOpportunityId = this.opportunityId.indexOf('offline')>-1?this.opportunityId:'';

    if (raw['notes'] && raw['notes'].length > 0) {
      this.procedureNotes = [];
      raw['notes'].forEach(note => {
        this.procedureNotes.push(new IONote({
          annotationid: note['annotationid'] || '',
          createdon: note['createdon'] || null,
          notetext: note['notetext'] || '',
          ownerid: note['ownerid'] || '',
          ownerName: note['ownerName'] || '',
          deleted: note['deleted'] || false,
          updated: note['updated'] || false,
        }));
      });
    }
    this.orderType = 548910002;
    this.orderTypeFormatted = OrderTypes.Surgery;
    //this.location = raw['indskr_location'] || this.location || '';

    if (raw['indskr_scheduleddate']) {
      this.scheduledStart = new Date(parseInt(raw['indskr_scheduleddate']));
    }

    if (raw['indskr_scheduledenddate']) {
      this.scheduledEnd = new Date(parseInt(raw['indskr_scheduledenddate']));
      this.setYearMonthWeek();
    } else {
      this.scheduledEnd = addMinutes(this.scheduledStart, 30);
    }
    if (raw['accountStatusCode'] === 2 || raw['accountStatusCode'] == 548910003 || raw['accountStatusCode'] == 548910010 || raw['accountStatusCode'] == 548910011 || raw['accountStatusCode'] == 548910012 || raw['accountStatusCode'] == 548910013) {
      this.isAccountActive = false;
    } else {
      this.isAccountActive = true;
    }
    this.indskr_datecompleted = raw['indskr_datecompleted'];
    this.coOwners = raw['coOwners'] || [];
    this.opportunities = raw['opportunities'] || [];
  }

  public get offlineDBId(): string {
    if (this.offlineId) {
      return DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY + this.offlineId;
    } else {
      return DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY + this.ID;
    }
  }

  public get procedureTrackerStatusString(): string {
    let str: string = '';
    switch (this.status) {
      case 100001:
      case 548910001:
        str = 'Completed';
        break;
      default:
        str = 'Draft';
    }
    return str;
  }


  public get serviceDTO(): any {

    let notes = [];
    this.procedureNotes.map(note => {
      notes.push({
        annotationid: (note.noteId && !note.noteId.includes('offline')) ? note.noteId : '',
        notetext: note.noteText,
        deleted: note.isDeleted,
        updated: note.updated
      })
    })
    let payload = {
      indskr_externalid: this.offlineId ? this.offlineId : '',
      customerid_account: (this.accountId) ? this.accountId : '',
      ownerid: this.ownerId,
      createdon: this.createdDate.getTime() + '',
      name: this.subject,
      indskr_ordertype: this.orderType,
      indskr_scheduledenddate: this.scheduledEnd.getTime() + '',
      indskr_scheduleddate: this.scheduledStart.getTime() + '',
      notes: notes,
      indskr_datecompleted: this.indskr_datecompleted,
    };
    if(this.opportunityId) {
      payload['opportunityid'] = this.opportunityId.indexOf('offline')>-1?"indskr_externalid='"+this.opportunityId+"',indskr_externalsource='"+this.opportunityId+"'":this.opportunityId;
    }
    if (this.appointmentId) {
      payload['appointmentId'] = this.appointmentId;
    } else if (this.offlineMeetingId) {
      payload['offlineMeetingId'] = this.offlineMeetingId;
    }
    if (!this.ID.includes('offline')) {
      payload['salesorderid'] = this.ID;
    }

    if(this.procedureTrackers.length > 0){
      let newPayload = this.procedureTrackers.map((procedureTracker:any) => {
        let trackerContacts = procedureTracker.trackerContacts.map(({ ID }) => ({ contactId: ID }));
        let tempTracker = {
          indskr_casessupported: procedureTracker.indskr_casessupported,
          procedureId: procedureTracker.procedureId,
          quantity : procedureTracker.indskr_quantity
        }
        tempTracker['trackerContacts'] = trackerContacts;
        return tempTracker;
      })
      payload['procedureTrackers'] = newPayload;
    }

    if (this.divisionId) {
      payload['divisionId'] = this.divisionId;
    }
    if ((this.state == 2 && this.status == 4) || (this.state == 1 && this.status == 548910001)) {
      payload['statecode'] = this.state + '';
      payload['statuscode'] = this.status + '';
    }
    if(this.opportunities){
      if (this.ID.includes('offline')) {
        payload['opportunities'] = this.opportunities.filter((opp: any) => !opp?.deleted);
      } else {
        payload['opportunities'] = this.opportunities;
      }
    }
    return payload;
  }

  public get statusUpdateDTO(): any {
    if (!this.ID) return {};
    return {
      salesorderid: this.ID,
      statecode: this.state + '',
      statuscode: this.status + '',
      indskr_datecompleted: this.indskr_datecompleted
    };
  }

  public get coOwnersUpdateDTO(): any {
    if (!this.coOwners || !this.ID) return {};
    return {
      salesorderid: this.ID,
      coOwners: this.coOwners
    }
  }
  public get opportunitiesUpdateDTO(): any {
    if (!this.opportunities || !this.ID) return {};
    return {
      salesorderid: this.ID,
      opportunities: this.opportunities
    }
  }

  public get offlineDataDTO(): any {
    return {
      indskr_externalid: this.offlineId,
      salesorderid: this.ID,
      ownerId: this.ownerId,
      ownerName: this.ownerNameString,
      modifiedby: this.modifiedby,
      modifiedByName: this.modifiedByName,
      customerid: this.accountId,
      customerName: this.accountNameString,
      pendingPushToDynamics: this.pendingPushToDynamics,
      isHardDeleted: this.isHardDeleted,
      statuscode: this.status,
      statecode: this.state,
      ownerid: this.ownerId,
      activitytypecode: this.type,
      createdon: this.createdDate.getTime() + '',
      modifiedon: this.modifiedon + '',
      name: this.subject,
      ordernumber: this.ordernumber,
      scheduledstart: this.scheduledStart.getTime() + '',
      scheduledend: this.scheduledEnd.getTime() + '',
      indskr_ordertype: this.orderType,
      indskr_scheduledenddate: this.scheduledEnd.getTime() + '',
      indskr_scheduleddate: this.scheduledStart.getTime() + '',
      appointmentId: this.appointmentId ? this.appointmentId : '',
      offlineMeetingId: this.offlineMeetingId ? this.offlineMeetingId : '',
      indskr_datecompleted: this.indskr_datecompleted,
      divisionId: this.divisionId,
      divisionName: this.divisionName,
      opportunityid: this.opportunityId,
      opportunityName : this.opportunityNameString,
      offlineOpportunityId: this.offlineOpportunityId,
      notes: this.procedureNotes.map(note => {
        return {
          annotationid: note.noteId,
          notetext: note.noteText,
          deleted: note.isDeleted,
          ownerid: note.ownerId,
          ownerName: note.ownerName,
          updated: note.updated,
          createdon: note.createdTime ? note.createdTime.getTime().toString() : 0,
        };
      }),
      procedureTrackers: this.procedureTrackers.map(pt => pt.offlineDto),
      coOwners: this.coOwners,
      opportunities: this.opportunities
    }

  }

  resetScheduledStartLocale() {
    this.scheduledStartLocale = this.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
  }

  setYearMonthWeek() {
    if (this.scheduledEnd) {
      this.weekOfYear = moment(this.scheduledEnd).week();
      this.year = this.scheduledEnd.getFullYear();
      this.month = this.scheduledEnd.getMonth() + 1;
    }
  }
}

export class CoOwner {
  constructor(
    public userId: string,
    public indskr_procedurelogcoownerid: string,
    public userFullName: string,
    public userType? : string[],
    public primaryPosition? : string
  ) { }
}

export class CoVisitor {
  constructor(
    public userId: string,
    public indskr_procedurelogcovisitorid: string,
    public userFullName: string,
    public userType? : string[],
    public primaryPosition? : string
  ) { }
}
export class Opportunities {
  public indskr_opportunity: string
  public indskr_procedurelogopportunityid: string
  public opportunityName?: string
  public opportunityId?: string
  deleted: boolean = false
  constructor(raw) {
    this.indskr_opportunity = raw['opportunityId'];
    this.indskr_procedurelogopportunityid = raw['indskr_procedurelogopportunityid'];
    this.opportunityName = raw['opportunityName'];
    this.indskr_opportunity = raw['opportunityId'];
  }
}

export enum UserTypes {
  PRODUCT_MANAGER = '600000000',
  SALES_REP = '600000001'
}

export class ProcedureTracker {
  public trackerContacts: Array<OrderContact> = [];
  public procedureId: string;
  public procedureName: string;
  public indskr_quantity: number;
  public indskr_casessupported: string;
  public indskr_proceduretrackerid: string;
  public createdon: string;
  constructor(raw) {
    if (raw) {
      this.trackerContacts = [];
      if (!_.isEmpty(raw['trackerContacts'])) {
        raw['trackerContacts'].forEach(contact => {
          this.trackerContacts.push(new OrderContact({
            id: contact['id'] || '',
            firstName: contact['firstName'] || '',
            title: contact['title'] || '',
            middleName: contact['middleName'] || '',
            lastName: contact['lastName'] || '',
            stateCode: contact['stateCode'],
            statusCode: contact['statusCode'],
          }));
        });
      }
      this.procedureId = raw['procedureId'] || '';
      this.procedureName = raw['procedureName'] || '';
      this.indskr_quantity = raw['indskr_quantity'] || '';
      this.indskr_proceduretrackerid = raw['indskr_proceduretrackerid'] || '';
      this.indskr_casessupported = raw['indskr_casessupported'] || '';
      this.createdon = raw['createdon'] || '';
    }
  }
  public get currentCustomerString(): string {
    let data: OrderContact[] = this.trackerContacts;
    let str = '';
    if (!_.isEmpty(data)) {
      if (data.length === 1) {
        str = `${data[0].fullname}`;
        if (data[0].statusCode !== 1 && data[0].statusCode !== 548910000) {
          str += ' (Inactive)';
        }
      } else if (data.length >= 2) {
        data.sort((a, b): number => {
          if (a.fullname > b.fullname) return 1;
          if (a.fullname < b.fullname) return -1;
          return 0;
        });
        let frstStr = '';
        let scndStr = '';
        if (data[0].statusCode !== 1 && data[0].statusCode !== 548910000) {
          frstStr = ' (Inactive)';
        } else if (data.some(a => a.statusCode !== 1 && a.statusCode !== 548910000)) {
          scndStr = ' (Inactive)';
        }
        str = `${data[0].fullname}${frstStr} +${data.length - 1}${scndStr}`;
      }
    }
    return str;
  }

  get offlineDto() {
    return {
      trackerContacts: this.trackerContacts,
      procedureId: this.procedureId,
      procedureName: this.procedureName,
      indskr_quantity: this.indskr_quantity,
      indskr_casessupported: this.indskr_casessupported,
      indskr_proceduretrackerid: this.indskr_proceduretrackerid
    }
  }

  get dto() {
    let payload = {
      procedureId: this.procedureId,
      quantity: this.indskr_quantity,
      indskr_casessupported: this.indskr_casessupported
    }
    if (!_.isEmpty(this.trackerContacts)) payload['trackerContacts'] = this.trackerContacts.map(c => ({ contactId: c.ID }));
    return payload;
  }
}
