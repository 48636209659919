<div class="searchSuggestionWrapper" *ngIf="recentSearchesCardModels.length || suggestionsData.length || assessmentSearchesCardModels.length || customerTagsSearchesCardModels.length || userTagsSearchesCardModels.length ||  resourceTagsSearchesCardModels.length || presentationTagsSearchesCardModels.length==0">
  <div class='defaultSearchSuggestion' *ngIf="!suggestionsData || !suggestionsData.length">
    <ind-section-header *ngIf="recentSearchesCardModels.length" [viewData]='recentSearchesHeaderModel'>
    </ind-section-header>
    <main-card (click)="handleRecentSearchSuggestionClick($event, search)" *ngFor="let search of recentSearchesCardModels"
      [viewData]="search"></main-card>
    <ind-section-header *ngIf="assessmentSearchesCardModels.length" [viewData]='asessmentHeaderModel'>
    </ind-section-header>
    <main-card (click)="handleAssessmentSearchClick($event, search)" *ngFor="let search of assessmentSearchesCardModels"
      [viewData]="search"></main-card>
    <ind-section-header *ngIf="savedSearchesCardModels.length" [viewData]='savedSearchesHeaderModel'>
    </ind-section-header>
    <main-card *ngFor="let search of savedSearchesCardModels" [viewData]="search"></main-card>
    <ind-section-header *ngIf="sharedSearchesCardModels.length" [viewData]='sharedSearchesHeaderModel'>
    </ind-section-header>
    <main-card *ngFor="let search of sharedSearchesCardModels" [viewData]="search"></main-card>

    <ng-container *ngIf="customerTagsSearchesCardModels.length && !isFromConsentTool">
      <ind-section-header [viewData]='customerTagHeaderModel'>
      </ind-section-header>
      <main-card *ngFor="let search of customerTagsSearchesCardModels" [viewData]="search"></main-card>
    </ng-container>
    <ng-container *ngIf="userTagsSearchesCardModels.length && !isFromConsentTool">
      <ind-section-header [viewData]='userTagHeaderModel'>
      </ind-section-header>
      <main-card *ngFor="let search of userTagsSearchesCardModels" [viewData]="search"></main-card>
    </ng-container>
    <ng-container *ngIf="presentationTagsSearchesCardModels.length">
      <ind-section-header [viewData]='presentationTagHeaderModel'>
      </ind-section-header>
      <main-card *ngFor="let search of presentationTagsSearchesCardModels" [viewData]="search"></main-card>
    </ng-container>
    <ng-container *ngIf="resourceTagsSearchesCardModels.length">
      <ind-section-header [viewData]='resourceTagHeaderModel'>
      </ind-section-header>
      <main-card *ngFor="let search of resourceTagsSearchesCardModels" [viewData]="search"></main-card>
    </ng-container>
  </div>
  <div *ngIf="suggestionsData && suggestionsData.length">
    <div class="filteredSearchSuggestion">
      <ind-section-header *ngIf="suggestionsData[0].header" [viewData]='suggestionsData[0].header'></ind-section-header>
      <main-card *ngFor="let value of suggestionsData[0].listData"
        [ngClass]="{'italics':value.id=='categoryCharacterSearch' || value.id=='entityCharacterSearch' || value.id=='customEntityCharacterSearch' }"
        (click)="handleSuggestionClick($event, value, suggestionsData[0])" [viewData]='value' 
        [highlightMatchKey]="value.id!='categoryCharacterSearch' && value.id!='entityCharacterSearch' && value.id!='customEntityCharacterSearch' && searchKeyValue ? searchKeyValue : null" ></main-card>
    </div>
    <div class="filteredSearchSuggestion" *ngIf="headerModelSaved && savedSearchesCardModels && savedSearchesCardModels.length">
      <ind-section-header  *ngIf="savedSearchesCardModels.length" [viewData]='savedSearchesHeaderModel' >
      </ind-section-header>
      <main-card *ngFor="let value of savedSuggestionsData"
        [ngClass]="{'italics':value.id=='categoryCharacterSearch' || value.id=='entityCharacterSearch' || value.id=='customEntityCharacterSearch' }"
        [viewData]='value' 
        [highlightMatchKey]="value.id!='categoryCharacterSearch' && value.id!='entityCharacterSearch' && value.id!='customEntityCharacterSearch' && searchKeyValue ? searchKeyValue : null" ></main-card>
    </div>
    <div class="filteredSearchSuggestion" *ngIf="headerModelShared && sharedSearchesCardModels && sharedSearchesCardModels.length"  >
      <ind-section-header *ngIf="sharedSearchesCardModels.length" [viewData]='sharedSearchesHeaderModel'>
      </ind-section-header>
      <main-card *ngFor="let value of SharedSuggestionsData"
        [ngClass]="{'italics':value.id=='categoryCharacterSearch' || value.id=='entityCharacterSearch' || value.id=='customEntityCharacterSearch' }"
        [viewData]='value' 
        [highlightMatchKey]="value.id!='categoryCharacterSearch' && value.id!='entityCharacterSearch' && value.id!='customEntityCharacterSearch' && searchKeyValue ? searchKeyValue : null" ></main-card>
    </div>
    <div class="filteredSearchSuggestion">
      <div *ngFor="let category of suggestionsData | slice:1 ">
        <ind-section-header *ngIf="category.header" [viewData]='category.header'></ind-section-header>
        <main-card *ngFor="let value of category.listData"
          [ngClass]="{'italics':value.id=='categoryCharacterSearch' || value.id=='entityCharacterSearch' || value.id=='customEntityCharacterSearch' }"
          (click)="handleSuggestionClick($event, value, category)" [viewData]='value' 
          [highlightMatchKey]="value.id!='categoryCharacterSearch' && value.id!='entityCharacterSearch' && value.id!='customEntityCharacterSearch' && searchKeyValue ? searchKeyValue : null" ></main-card>
      </div>
    </div>
  </div>
</div>
