import { SelectListData } from "@omni/components/popover/popover";

/**
 * Interface representing metadata for a form field view data model.
 * @interface
 */
export interface IndFormFieldViewDataModel {
  [x: string]: any,

  /** Unique identifier for the form field */
  id: string;

  /** Label for the form field */
  label: string;

  /** Secondary label for additional information */
  secondaryInfoLabel?: string;

  /** Additional text for secondary information */
  secondaryInfoText?: string;

  /** Text input value */
  inputText: string;

  /** Type of input (e.g., text, number) */
  inputType?: string;

  /** Value of the input field */
  inputValue?: any;

  /** Minimum allowed value for numeric inputs */
  inputMin?: number;

  /** Maximum allowed value for numeric inputs */
  inputMax?: number;

  /** Debounce time for input events */
  inputDebounceTime?: number;

  /** Maximum length of input text */
  maxLength?: number;

  /** Symbol for formatting input text */
  formattingSymbol?: string;

  /** Flag indicating if the field is read-only */
  isReadOnly?: boolean;

  /** Flag indicating if the field is disabled */
  isDisabled?: boolean;

  /** Flag indicating if the field is hidden */
  isHidden?: boolean;

  /** Placeholder text for the input field */
  placeholderLabel?: string;

  /** Flag indicating whether to skip translation for placeholder */
  skipPlaceholderTranslation?: boolean;

  /** Custom placeholder text for the input field */
  customPlaceholderLabel?: string;

  /** Flag indicating whether to show an arrow icon */
  showArrow?: boolean;

  /** Type of form field */
  formFieldType?: string;

  /** Regular expression for input validation */
  inputRegEx?: RegExp;

  /** Flag indicating whether to trigger invalid input value */
  invalidInputValueTrigger?: boolean;

  /** Input validation function */
  inputValidator?: (id: string, value: any, event?: any, eventName?: string) => boolean;

  /** Event handler function */
  eventHandler?: (id?: string, event?: any, eventName?: string, dataRef?: any) => void;

  /** Dropdown values */
  dropdown?: {
    values?: (() => Promise<SelectListData[]> | SelectListData[]) | SelectListData[];
  };

  /** Icon for phone input */
  phoneIcon?: string;

  /** Source URL for image input */
  imgSrc?: string;

  /** CSS class for custom icon */
  cssIcon?: string;

  /** Flag indicating whether it's a date-time field */
  isDateTimeField?: boolean;

  /** Metadata for post-label icon */
  postLabelIcon?: {
    domId?: string;
    domClass?: string;
    clickable?: boolean;
    isClickEnabled?: boolean;
    src?: string;
  };

  /** Flag indicating whether it's an accordion form */
  isAccordionForm?: boolean;

  /** Error message for invalid input */
  errorMessage?: string;

  /** Additional controls */
  additionalcontrols?: Array<{
    id?: string;
    src?: string;
    arrowType?: string;
    disabled?: boolean;
  }>;

  /** Flag indicating whether the field is required */
  isRequired?: boolean;

  /** Flag indicating whether the field is invalid */
  isInvalid?: boolean;

  /** Flag indicating whether an empty required field is present */
  isEmptyRequiredField?: boolean;

  /** Informational text */
  infoText?: string;

  /** Flag indicating whether to display informational text */
  displayInfoText?: boolean;

  /** Event handler for informational text */
  infoTextEventHandler?: (id: string, value: any, event?: any, eventName?: string) => void;

  /** Flag indicating whether to show icon next to text */
  isTextNextIcon?: boolean;

  /** Sub-identifier for the field */
  subId?: string;

  /** Flag indicating whether to show tooltip icon */
  ShowToolTipIcon?: boolean;
}

export enum FormFieldType {
  POPOVER_SELECT = 'popover-select',
  NEW_PAGE_SELECT = 'new-page-select',
  INLINE_INPUT = 'inline-input',
  DATA_SUBMITTED = 'data-submitted',
  OPTIONS_NOT_AVAILABLE = 'options-not-available',
  DESCRIPTION_AND_CONFIRM = 'description-and-confirm',
  TOGGLE = 'toggle',
  CHECK_BOX = 'check-box',
   LIST="dropdown-list"
}
