import { SecureStorage, SecureStorageObject } from '@awesome-cordova-plugins/secure-storage/ngx';
import { Injectable, Injector } from "@angular/core";
import { UserTagForContact } from "@omni/services/contact/contact.service";
import PouchDB from "pouchdb";
import PouchDBUpsert from "pouchdb-upsert";
import PouchDBFind from "pouchdb-find";
import * as CryptoJS from 'crypto-js';
import { encode, decode } from 'base64-arraybuffer';
import * as _ from 'lodash';
import { DeviceService } from "../device/device.service";
import { LogService } from "../logging/log-service";
import { UserConfig } from "../../classes/authentication/user-config.class";
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { GlobalErrorHandler } from '../error-handler/error-handler-service';
import { TimeOffActivity } from "../../classes/activity/timeoff.class";
import { InteractiveHTMLEvent } from '../../classes/ihtml/ihtml-event.class';
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES, DB_ALLDOCS_QUERY_OPTIONS } from '../../config/pouch-db.config';
import { format } from "date-fns";
import { AllDocsResponse, AllDocsRow } from "../../models/pouch-db-alldoc-response-model";
import { SampleActivity } from '../../classes/activity/sample.activity.class';
import { AlertController, Platform } from "@ionic/angular";
import { EmailActivity } from "../../classes/activity/email.activity.class";
import { CaseActivity } from "../../classes/case-intake/case-activity.class";
import { EventRegistrationResponse } from "../../classes/customer-event/customer-event.class";
import { TranslateService } from "@ngx-translate/core";
import { Report } from "../../classes/coaching/report.class";
import { NotificationService, ToastStyle } from "../notification/notification.service";
import { Store } from '@ngrx/store';
import { ResourceState } from "../../store/application.state";
import * as ResourceAction from '../../store/io-file-service/actions/resource.actions';
import { PhoneActivity } from '../../classes/activity/phone.activity.class';
import { MarketScan } from "../../classes/market-scan/market-scan.class";
import { IPouchDBDocument, IPouchDBResponse } from '../../interfaces/shared/shared.interface';
import { IFeatureRevisionStore } from '../../interfaces/shared/feature-revision.interface';
import { TagEntityType, UserTag } from '../user-tag/user-tag.service';
import { Utility } from '@omni/utility/util';
import { StoreCheckActivity } from '@omni/classes/activity/store-check.activity.class';
import { ActivityTypeCode } from '@omni/classes/activity/activity.class';
// import { UserTagForPresentation } from '../presentation/presentation.service';


export const SECURE_STORAGE_NAME = 'omni_secure_storage';
export const OFFLINE_DATA_COUNT_ENTITY_NAME = {
  MEETING: 'meeting',
  MEETING_PRESENTATIONS: 'meeting_presentations',
  PHONECALL_MEETING: 'phonecall_meeting',
  TIME_OFF: 'timeOff',
  SAMPLE_ORDER: 'sampleOrder',
  EMAIL: 'email',
  CUSTOMER_INQUIRY: 'customerInquiry',
  FOLLOW_UP: 'followUp',
  PROGRESS_REPORT: 'progressReport',
  CONSENT: 'consent',
  ORDER: 'order',
  POLL: 'poll',
  PRESENTATION_FAVORITE: 'presentationFavorite',
  CONTACT_EVENT: 'contactEvent',
  COACHING_REPORT: 'coachingReport',
  OPPORTUNITY: 'opportunity',
  SAVED_SEARCH: 'savedSearch',
  CONTACT_NOTES: 'contactnotes',
  ACCOUNT_NOTES: 'accountnotes',
  NEXT_CALL_OBJECTIVES: 'nextCallObjectives',
  MARKET_SCANS: 'market_scans',
  EVENT_TOOL_ACTIVITY: 'event_tool_activity',
  SURGERY_ORDER: 'surgery_order',
  HYPERLINK_LIST: 'hyperlink_list',
  CONTACT_TAG: 'contact_tag',
  PREFERRED_ADDRESS: ' preferred_address',
  PROCEDURE_TRACKER: 'procedure_tracker',
  MEETING_NOTES: 'meetingnotes',
  CONTACT: 'contact',
  LINKED_ENTITIES: 'linkedEntities',
  CONTACT_ASSESSMENT: 'contact_assessment',
  ACCOUNT_ASSESSMENT: 'account_assessment',
  BUSINESS_CHANGE_REQUEST: 'businessChangeRequest',
  ONE_KEY_CHANGE_REQUEST: 'oneKeyChangeRequest',
  USER_TAG: 'user_tag',
  CONTACT_KOL_STATUS: 'contact_kol_status',
  CONTACT_SURVEY: 'contact_survey',
  ACCOUNT_SURVEY: 'account_survey',
  PROCEDURE_CONTRACT:'Procedure_contract',
  PRESENTATION_TAG:'Presentation_tag',
  PRESENTATION:'presentation',
  SET_BOOKING: 'set_booking',
  CONTACT_META_ADD: 'contact_meta_add'
};

export const OFFLINE_DB_LINKED_ENTITY_NAME = {
  ADDRESSES: 'indskr_indskr_customeraddress_v2',
  ACCOUNT_CONTACT_AFFILIATION: 'indskr_accountcontactaffiliation',
  ACCOUNT_ACCOUNT_AFFILIATION: 'indskr_accountaccountaffiliation',
  EMAIL: 'indskr_email_address',
  CONTACT_NOTES: 'annotation',
  ACCOUNT_NOTES: 'annotation',
  CONTACT_MEDICAL_INSIGHTS: 'indskr_customerinteractioninsights',
  KIT_BOOKING_NOTES: 'annotation'
};

const MAX_DB_REOPEN_RETRY_COUNT = 3;

declare var cordova: any;
@Injectable({
  providedIn: 'root'
})
export class DiskService {
  private _db;
  public working: boolean;
  public wasThereOfflineDataUpload = false;

  private readonly UPDATE_CONFLICT = 409;
  private isInitSyncStateRan = false;
  private secureStorage: SecureStorageObject;
  private _isDBEncryptionEnabled = null;
  private readonly DB_ENC_KEY_STORAGE_KEY_SUFFIX = '-dbp';
  private _dbEncryptionKey = '';
  private _cryptoKey;
  private _translate : TranslateService;
  private get translate() {
    if (!this._translate) {
      this._translate = this._injector.get(TranslateService);
    }
    return this._translate;
  }
  private _offlineDataCountHashMap: Map<string, number> = new Map();
  private _featureRevStoreDoc: IPouchDBDocument<IFeatureRevisionStore>;
  private _userConfig: UserConfig;

  /**
   * Creates an instance of DiskService.
   * Loads the lastModified hashmap
   *
   * @memberof DiskService
   */
  constructor(
    private device: DeviceService,
    private logService: LogService,
    private globalErrorHandler: GlobalErrorHandler,
    private alert: AlertController,
    private platform: Platform,
    private notificationService: NotificationService,
    private _secureStorage: SecureStorage,
    private store: Store<ResourceState>,
    private _injector: Injector,
    ) {
    this.working = false;

    this.initCountHashmap();

    // Check for DB connection and try re-connect if lost
    this.device.dbConnectionCheckStart.asObservable().subscribe(async () => {
      let dbConnected = await this.isDBConnected();
      if (this.isInitSyncStateRan && !dbConnected && this._userConfig) {
        await this.setUserDB(this._userConfig);

        // Still fail? reload..
        dbConnected = await this.isDBConnected();
        if (!dbConnected) {
          await this.refreshAppWithAlert();
          return;
        }
      }

      // Signal back DB connection success
      this.device.dbConnectionCheckDone.next(null);
    });
  }

  /**
   * DB Encryption flag getter & setter.
   * Setter is in the form of a function to prevent
   * accident flag overwrite.
   */
  get isDBEncryptionEnabled() {
    return this._isDBEncryptionEnabled;
  }
  setIsDBEncryptionEnabled(enabled: boolean) {
    this._isDBEncryptionEnabled = enabled;
  }

  /**
   * Android Secure Storage helper functions START ------------------
   */
  private async ensureSecureStorage() {
    if (this.secureStorage || !this.device.isNativeApp) return;
    try {
        await this.platform.ready();
        this.secureStorage = await this._secureStorage.create(SECURE_STORAGE_NAME);
    }
    catch (error) {
      console.error('ensureSecureStorage: ', error);
      this.alert.create({
        header: this.translate.instant('DEVICE_NOT_SECURE'),
        message: this.translate.instant('DEVICE_REQUIRES_SECURITY_USE_THE_APP'),
        buttons: [{
          text: this.translate.instant('OK'),
          handler: () => {
            this.secureStorage.secureDevice()
              .catch(() => {})
              .then(() => this.ensureSecureStorage());
          }
        }]
      }).then((alert)=> alert.present())

    }
  }
  async saveToSecureStorage(key: string, value: string) {
    await this.ensureSecureStorage();
    if (this.device.isNativeApp && this.secureStorage) {
        return await this.secureStorage.set(key, value);
    }
  }

  async getFromSecureStorage(key: string) {
    await this.ensureSecureStorage();
    if (this.device.isNativeApp && this.secureStorage) {
      let value: string;
      try {
        value = await this.secureStorage.get(key);
      } catch (error) {
        console.error('getFromSecureStorage: ' + 'key: ' + key + ' error:', error);
      }
      return value;
    }
  }

  async getAllKeysFromSecureStorage() {
    await this.ensureSecureStorage();
    if (this.device.isNativeApp && this.secureStorage) {
      return this.secureStorage.keys();
    }
  }
  async removeFromSecureStorage(key: string){
    await this.ensureSecureStorage();
    if (this.device.isNativeApp && this.secureStorage) {
        return this.secureStorage.remove(key);
    }
  }
  async clearSecureStorage() {
    await this.ensureSecureStorage();
    if (this.device.isNativeApp && this.secureStorage) {
      return this.secureStorage.clear();
    }
  }
  /**
   * Android Secure Storage helper functions END ------------------
   */


  /**
   * Creates / loads a CryptoKey object depending on the feature action and history.
   * @param userEmail
   */
  async initDBEncryptionKey(userEmail: string) {
    // For now, there's no fallback implementation if browser doesn't support WebCrypto
    if (!window.crypto || !window.crypto.subtle) {
      let alert = this.alert.create({
        header: this.translate.instant('DISK_ENCRYPTION_NOT_SUPPORTED'),
        message: this.translate.instant('DISK_DOES_NOT_SUPPORT_DB_ENCRYPTION'),
        buttons: [{
          text: this.translate.instant('OK'),
          handler: () => {}
        }]
      }).then((alert)=> alert.present());
      return;
    }
    if (this.isDBEncryptionEnabled && !this._cryptoKey) {
      // DB encryption feature is on and cryptoKey is empty.
      // Load or generate a key.
      if (this.device.isNativeApp) {
        // Android.
        const storageKey = userEmail + this.DB_ENC_KEY_STORAGE_KEY_SUFFIX;
        let rawKey = await this.getFromSecureStorage(storageKey);
        if (!rawKey) {
          // Since we cannot save CryptoKey object to Android secure storage as is,
          // first create an extractable key, export and save the raw key to the secure storage
          // and then generate a non extractable CryptoKey by importing it again.
          // This way, we can save the key to secure storage and runtime CryptoKey object remains as non-extractable.
          const tempKey = await this._generateKey(true);
          const exported = await window.crypto.subtle.exportKey('raw', tempKey);
          rawKey = this.ab2str(new Uint8Array(exported));

          // Save to secure storage for later import
          await this.saveToSecureStorage(storageKey, rawKey);
        }
        // Import non extractable key
        this._cryptoKey = await this._importKey(rawKey);
      } else {
        // Browser.
        // CryptoKey object can be relatively safely stored in indexed db.
        const cryptoKeyDoc = await this.retrieve(DB_KEY_PREFIXES.DB_CRYPTO_KEY, true, true);
        if (cryptoKeyDoc && cryptoKeyDoc.k) {
          this._cryptoKey = cryptoKeyDoc.k;
        } else {
          this._cryptoKey = await this._generateKey();
          await this.updateOrInsert(DB_KEY_PREFIXES.DB_CRYPTO_KEY, doc => ({ k: this._cryptoKey }), true);
        }
      }
    }
  }
  private async _generateKey(extractable = false) {
    return await window.crypto.subtle.generateKey(
      {
        name: 'AES-GCM',
        length: 256,
      },
      extractable,
      ['encrypt', 'decrypt']
    );
  }
  private async _importKey(raw: string) {
    return await window.crypto.subtle.importKey(
      'raw',
      this.str2ab(raw),
      'AES-GCM',
      true,
      ['encrypt', 'decrypt']
    );
  }
  generateRandomKey(password: string) {
    const salt = CryptoJS.lib.WordArray.random(128 / 8);
    return CryptoJS.PBKDF2(password, salt, { keySize: 256 / 32, iterations: 1000 }).toString();
  }

  public async setUserDB(user: UserConfig) {
    if (!user) return;
    this._userConfig = user;
    this._db = new PouchDB(`io-cystine-${(user.fullName || user.givenName ).trim()}-${user.activeInstance.friendlyName.trim()}`, { revs_limit: 1, auto_compaction: true });
    PouchDB.plugin(PouchDBUpsert);
    PouchDB.plugin(PouchDBFind);

    if (!this.isInitSyncStateRan) {
      await Promise.all([
        this.initSyncStates(),
        this.initFeatureRevisionStore(),
      ]);
      this.isInitSyncStateRan = true;
    }
  }

  private isDBOutOfMemoryError(error): boolean {
    if (error?.name?.includes('indexed_db_went_bad') && !this.device.isNativeApp) {
      console.error("out of memory error, ", error);
      return true;
    }
    return false;
  }

  private isDBCloseError(error): boolean {
    const msg = error?.message;
    return msg?.includes('connection is closing') || msg?.includes('database is closed');
  }

  private async outOfMemoryAppWithAlert() {
    const nestedAlert = await this.alert.create({
      header: 'Something went wrong',
      message: `You have a browser memory issue, clear the browser cache and reload the page. Please note that your most recent action will be discarded.`,
      backdropDismiss: false,
      buttons: [],
    });
    await nestedAlert.present();
  }


  private async refreshAppWithAlert() {
    const nestedAlert = await this.alert.create({
      header: 'Something went wrong',
      message: `We need to restart the app to fix the issue, and unfortunately, we'll have to discard your most recent action. We apologize for any inconvenience this may cause.`,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Restart',
          handler: async () => {
            (navigator as any)?.splashscreen?.show();
            if (window.location.protocol.startsWith('file') && !window.location.href.endsWith('.html')) {
              window.location.href += `${!window.location.href.endsWith('/') ? '/' : ''}index.html`;
            } else {
              window.location.reload();
            }
          }
        },
      ],
    });
    await nestedAlert.present();
  }
  async isDBConnected(): Promise<boolean> {
    let isConnected = true;

    try {
      await this._db?.get(DB_KEY_PREFIXES.USER);
    } catch (error) {
      isConnected = !this.isDBCloseError(error);
    }

    return isConnected;
  }

  async getSyncState(key: string) {
    try {
      const stateDoc = await this.retrieve(key, true, true);
      return stateDoc ? stateDoc : null;
    } catch (error) {
      console.error('getSyncState: ', error);
      return null;
    }
  }
  async updateSyncState(syncStateDoc: any, upsert = false) {
    try {
      if (upsert) {
        delete syncStateDoc._rev;
        await this.updateOrInsert(syncStateDoc._id, doc => syncStateDoc);
      } else {
        await this.updateDocWithIdAndRev(syncStateDoc, true);
      }
    } catch (error) {
      console.error('updateSyncState: ', error);
    }
  }

  public getCurrentGMT0Timestamp(): number {
    // Due to dynamics issue, activity delta depends on 'GMT 0 Date' at the moment
    // Until resolved, make sure to send timestamp for GMT 0 Date @ 12:00 AM
    const todayGMT0 = format(new Date(), 'YYYY-MM-DD [00:00:00 GMT-0000]');
    return (new Date(todayGMT0)).getTime();
  }

  /*
    options.include_docs: Include the document itself in each row in the doc field. Otherwise by default you only get the _id and _rev properties.
    options.conflicts: Include conflict information in the _conflicts field of a doc.
    options.attachments: Include attachment data as base64-encoded string.
    options.binary: Return attachment data as Blobs/Buffers, instead of as base64-encoded strings.
    options.startkey & options.endkey: Get documents with IDs in a certain range (inclusive/inclusive).
    options.inclusive_end: Include documents having an ID equal to the given options.endkey. Default: true.
    options.limit: Maximum number of documents to return.
    options.skip: Number of docs to skip before returning (warning: poor performance on IndexedDB/LevelDB!).
    options.descending: Reverse the order of the output documents. Note that the order of startkey and endkey is reversed when descending:true.
    options.key: Only return documents with IDs matching this string key.
    options.keys: Array of string keys to fetch in a single shot.
    Neither startkey nor endkey can be specified with this option.
    The rows are returned in the same order as the supplied keys array.
    The row for a deleted document will have the revision ID of the deletion, and an extra key "deleted":true in the value property.
    The row for a nonexistent document will just contain an "error" property with the value "not_found".
    For details, see the CouchDB query options documentation.
    options.update_seq: Include an update_seq value indicating which sequence id of the underlying database the view reflects.
  */
  async batchFetch(options, returnDocsOnly = true, doNotDecrypt = false, retryCount = 0): Promise<AllDocsResponse | any[]> {
    if (!options || Object.keys(options).length === 0) {
      return Promise.reject('batchFetch: options param is required');
    }

    try {
      const results: AllDocsResponse = await this._db.allDocs(options);
      if (returnDocsOnly) {
        let docs: any[] = [];
        for (let i = 0; i < results.rows.length; i++) {
          const row: AllDocsRow = results.rows[i];
          if (row.doc && row.doc.hasOwnProperty('_id')) {
            if (!doNotDecrypt && this.isDBEncryptionEnabled && this._cryptoKey) {
              const mask = this._getDBEncryptionMaskingFields(row.doc._id);
              try {
                const decryptedDoc = await this._decryptData(row.doc, mask);
                docs.push(decryptedDoc);
              } catch (error) {
                console.error('batchFetch: decryption failed!', row.doc);
              }
            } else {
              docs.push(row.doc);
            }
          } else {
            console.warn(`batchFetch: No doc or '_id' found`, row);
          }
        }

        return docs;
      } else {
        return results;
      }
    } catch (error) {
      if (this.isDBCloseError(error)) {
        if (retryCount > MAX_DB_REOPEN_RETRY_COUNT) {
          await this.refreshAppWithAlert();
          return;
        }
        await this.setUserDB(this._userConfig);
        return await this.batchFetch(options, returnDocsOnly, doNotDecrypt, retryCount + 1);
      } else if ((this.isDBOutOfMemoryError(error))) {
        await this.outOfMemoryAppWithAlert();
        return;
      }
      return Promise.reject(error);
    }
  }

  /*
  request param
    selector Defines a selector to filter the results. Required.
      $lt Match fields "less than" this one.
      $gt Match fields "greater than" this one.
      $lte Match fields "less than or equal to" this one.
      $gte Match fields "greater than or equal to" this one.
      $eq Match fields equal to this one.
      $ne Match fields not equal to this one.
      $exists True if the field should exist, false otherwise.
      $type One of: "null", "boolean", "number", "string", "array", or "object".
      $in Matches if all the selectors in the array match.
      $and Matches if all the selectors in the array match.
      $nin The document field must not exist in the list provided.
      $all Matches an array value if it contains all the elements of the argument array.
      $size Special condition to match the length of an array field in a document.
      $or Matches if any of the selectors in the array match. All selectors must use the same index.
      $nor Matches if none of the selectors in the array match.
      $not Matches if the given selector does not match.
      $mod Matches documents where (field % Divisor == Remainder) is true, and only when the document field is an integer.
      $regex A regular expression pattern to match against the document field.
      $elemMatch Matches all documents that contain an array field with at least one element that matches all the specified query criteria.

    fields (Optional) Defines a list of fields that you want to receive. If omitted, you get the full documents.

    sort (Optional) Defines a list of fields defining how you want to sort. Note that sorted fields also have to be selected in the selector.

    limit (Optional) Maximum number of documents to return.

    skip (Optional) Number of docs to skip before returning.
  */
  async find(request, doNotDecrypt = false, retryCount = 0): Promise<any[]> {
    if (!request || Object.keys(request).length === 0) {
      return Promise.reject('find: request param is required');
    }
    try {

      let result;
      if (this._db)
        result = await this._db.find(request);

      if (!doNotDecrypt && this.isDBEncryptionEnabled && this._cryptoKey && result && Array.isArray(result.docs)) {
        const decryptedDocs = [];
        for (let i = 0; i < result.docs.length; i++) {
          const doc = result.docs[i];

          if (doc.hasOwnProperty('_id')) {
            const mask = this._getDBEncryptionMaskingFields(doc._id);
            try {
              const decryptedDoc = await this._decryptData(doc, mask);
              decryptedDocs.push(decryptedDoc);
            } catch (error) {
              console.error('batchFetch: decryption failed!', doc);
            }
          } else {
            console.warn(`DiskService: find: No '_id' found.`, doc);
          }
        }
        return decryptedDocs;
      } else {
        return result.docs;
      }
    } catch (error) {
      if (this.isDBCloseError(error)) {
        if (retryCount > MAX_DB_REOPEN_RETRY_COUNT) {
          await this.refreshAppWithAlert();
          return;
        }
        await this.setUserDB(this._userConfig);
        return await this.find(request, doNotDecrypt, retryCount + 1);
      } else if (this.isDBOutOfMemoryError(error)) {
        await this.outOfMemoryAppWithAlert();
        return;
      }
      return Promise.reject(error);
    }
  }

  async createDocument(_id, doc, doNotEncrypt = false, retryCount = 0): Promise<any> {
    if (!_id || !doc) {
      return Promise.reject('createDocument: _id and doc params are required');
    }

    try {
      let documentToUpdate = doc;
      documentToUpdate['_id'] = _id;
      if (!doNotEncrypt && this.isDBEncryptionEnabled && this._cryptoKey) {
        const mask = this._getDBEncryptionMaskingFields(doc._id);
        documentToUpdate = await this._encryptData(doc, mask);
      }
      return await this._db.put(documentToUpdate);
    } catch (error) {
      if (this.isDBCloseError(error)) {
        if (retryCount > MAX_DB_REOPEN_RETRY_COUNT) {
          await this.refreshAppWithAlert();
          return;
        }
        await this.setUserDB(this._userConfig);
        return await this.createDocument(_id, doc, doNotEncrypt, retryCount + 1);
      } else if ((this.isDBOutOfMemoryError(error))) {
        await this.outOfMemoryAppWithAlert();
        return;
      }
      console.error(`createDocument: ${error?.message}`, error);
    }
  }

  async updateDocWithIdAndRev(document, doNotEncrypt = false, forceUpdate = false, retryCount = 0): Promise<any> {
    if (!document.hasOwnProperty('_id') || !document.hasOwnProperty('_rev')) {
      return Promise.reject('updateDocWithIdAndRev: _id and _rev required');
    }

    try {
      let documentToUpdate = document;
      if (!doNotEncrypt && this.isDBEncryptionEnabled && this._cryptoKey) {
        const mask = this._getDBEncryptionMaskingFields(document._id);
        documentToUpdate = await this._encryptData(document, mask);
      }
      return await this._db.put(documentToUpdate, { force: forceUpdate });
    } catch (error) {
      if (this.isDBCloseError(error)) {
        if (retryCount > MAX_DB_REOPEN_RETRY_COUNT) {
          await this.refreshAppWithAlert();
          return;
        }
        await this.setUserDB(this._userConfig);
        return await this.updateDocWithIdAndRev(document, doNotEncrypt, forceUpdate, retryCount + 1);
      } else if (this.isDBOutOfMemoryError(error)) {
        await this.outOfMemoryAppWithAlert();
        return;
      }
      console.error(`updateDocWithIdAndRev: ${error?.message}`, error);
    }
  }
  async updateOfflineCoachingNote(note){
  let key = DB_KEY_PREFIXES.COACHING_NOTE + note.noteId; 

  const doc = await this.retrieve(key, true);
  if (doc) {
    return await this.updateOrInsert(key, () => {
      return note;
    }).catch(e => console.log(e));
  }
  else {
    return await this.updateOrInsert(key, doc => note)
      .catch(error => console.error('failed note inserting  error'));
  }
  }

  /**
 * Method: deleteAllFromDbUsingAlldocsQuery
 * 
 * Purpose:
 * This method is responsible for deleting all documents from the database that match a given query.
 * It fetches the documents in batches, marks them for deletion, and then processes the deletions
 * in bulk. The method includes robust error handling to manage database-related errors, including
 * scenarios where the database might be closed unexpectedly or run out of memory.
 * 
 * Parameters:
 * - alldocsQuery: The query object used to fetch the documents from the database.
 * - retryCount (optional): An integer representing the number of times this operation has been retried
 *   in case of specific database errors. Defaults to 0 on initial invocation.
 * 
 * Functionality:
 * 1. The method begins by fetching all documents that match the provided query. This is handled
 *    by the batchFetch method, which may support pagination or batch processing.
 * 2. If documents are found, each document is marked for deletion by setting the `_deleted` property
 *    to true. This ensures that the database understands the documents should be deleted.
 * 3. The method then processes these deletions in bulk, which is efficient for databases that support
 *    such operations.
 * 4. The method returns a resolved promise upon successful deletion of documents.
 * 5. In case of an error, the method handles specific database errors:
 *    - If the database is closed unexpectedly (`isDBCloseError`), the method attempts to reopen the
 *      database and retries the deletion up to a maximum retry count (`MAX_DB_REOPEN_RETRY_COUNT`).
 *      If the maximum retry count is exceeded, the app is refreshed, and the user is alerted.
 *    - If the database runs out of memory (`isDBOutOfMemoryError`), the method alerts the user and
 *      attempts to recover the app.
 * 6. If the error is not related to the database being closed or out of memory, the promise is rejected
 *    with the error.
 * 
 * Error Handling:
 * The method logs errors with detailed information, including the query's `include_docs` parameter.
 * It provides specific error handling for database closure and out-of-memory scenarios, with
 * recovery mechanisms such as retries and user alerts.
 * 
 * Returns:
 * - Promise<void>: The method returns a resolved or rejected promise depending on the success or
 *   failure of the operation.
 */

  async deleteAllFromDbUsingAlldocsQuery(alldocsQuery: { include_docs: boolean, startkey: string, endkey: string }, retryCount = 0) {
    try {
      // Fetches all documents matching the query. The batchFetch method is likely to handle pagination or batch processing.
      const allDocs: any[] = await (this.batchFetch(alldocsQuery, true, true)) as any[];
    
      // Checks if the fetched documents array is non-empty.
      if (Array.isArray(allDocs) && allDocs.length > 0) {
        // Iterates through all the documents and marks each one for deletion by setting the _deleted property to true.
        for (let i = 0; i < allDocs.length; i++) {
          allDocs[i] = { _id: allDocs[i]._id, _rev: allDocs[i]._rev, _deleted: true };
        }
        // Sends the updated documents in bulk to the database to apply the deletions.
        await this.bulk(allDocs, true);
      }
    
      // Resolves the promise successfully if all deletions were processed without errors.
      return Promise.resolve();
    
    } catch (error) {
      // Logs an error message along with the query's include_docs parameter value.
      console.error('deleteAllFromDbUsingAlldocsQuery: [' + alldocsQuery.include_docs + ']', error);
    
      // Handles a specific database error where the DB may be closed unexpectedly.
      if (this.isDBCloseError(error)) {
        // If the maximum retry count is exceeded, refreshes the app and alerts the user.
        if (retryCount > MAX_DB_REOPEN_RETRY_COUNT) {
          await this.refreshAppWithAlert();
          return;
        }
        // Reopens the database connection and retries the deletion operation.
        await this.setUserDB(this._userConfig);
        return await this.deleteAllFromDbUsingAlldocsQuery(alldocsQuery, retryCount + 1);
      } 
      // Handles a scenario where the database runs out of memory.
      else if (this.isDBOutOfMemoryError(error)) {
        // Alerts the user about the out-of-memory error and attempts to recover the app.
        await this.outOfMemoryAppWithAlert();
        return;
      }
    
      // Rejects the promise with the error if it doesn't match any of the specific cases handled.
      return Promise.reject(error);
    }    
  }

  async resetDb() {
    // Delete documents except 'users' & 'MyAssistantData' for now..
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACTIVITIES);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_FOLLOW_UP_ACTIVITIES);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACTIVE_CONSENTS);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONSENT_TERMS);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONSENT_TERMS_ALLOCATION_ORDERS);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONSENT_CHANNELS);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_MY_CASES);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_TEAM_CASES);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ALLOCATION_RELATED_DATA);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUSTOMER_SAMPLE_ALLOCATIONS);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_EMAIL_TEMPLATES);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_LOTS);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_SCHEDULER_RELATED_DATA);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_TIMEOFFS);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_MY_COACHING);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_TEAM_COACHING);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_COACHING_RELATED_DATA);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_POLL_RESULTS);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_EVENTS);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ALLOC_TRANSFER_RELATED_DATA);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUSTOMER_LICENSES);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_EDGE_ANALYTICS_DATA);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_SURGERY_ORDER_RELATED_DATA);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_TIMELINE);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_REGISTRATION_EVENTS_TIMELINE);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_CHECKIN_EVENTS_TIMELINE);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_COMPLETED_EVENTS_TIMELINE);
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACTS);
    await this.remove(DB_KEY_PREFIXES.ACCOUNT);
    await this.remove(DB_KEY_PREFIXES.ACCOUNT_PLANS);
    await this.remove(DB_KEY_PREFIXES.ACCOUNT_PLAN_PROGRESS_REPORT);
    await this.remove(DB_KEY_PREFIXES.PRODUCT);
    await this.remove(DB_KEY_PREFIXES.PRESENTATION);
    await this.remove(DB_KEY_PREFIXES.RESOURCE);
    await this.remove(DB_KEY_PREFIXES.OFFLINE_RESOURCE);
    await this.remove(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS);
    await this.remove(DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS);
    await this.remove(DB_KEY_PREFIXES.USER_POSITIONS);
    await this.remove(DB_KEY_PREFIXES.CUSTOMER_SEGMENT);
    await this.remove(DB_KEY_PREFIXES.ACCOMPANIED_USERS);
    await this.remove(DB_KEY_PREFIXES.TIMEOFF_REASONS);
    await this.remove(DB_KEY_PREFIXES.MY_TIMEOFF_POSITIONS);
    await this.remove(DB_KEY_PREFIXES.MY_TIMEOFF_USERS);
    await this.remove(DB_KEY_PREFIXES.COUNTRIES);
    await this.remove(DB_KEY_PREFIXES.SCIENTIFIC_PLANS);
    await this.remove(DB_KEY_PREFIXES.SCIENTIFIC_PLANS_USERS);
    await this.remove(DB_KEY_PREFIXES.THERAPEUTIC_AREA);
    await this.remove(DB_KEY_PREFIXES.SHIPMENT_LOSS_REASONS);
    await this.remove(DB_KEY_PREFIXES.ALLOC_ADJUST_REASONS);
    await this.remove(DB_KEY_PREFIXES.CONTENT_MATCHING);
    await this.remove(DB_KEY_PREFIXES.DOMAINS);
    await this.remove(DB_KEY_PREFIXES.SYNC_SCHEDULE_COUNT);
    await this.remove(DB_KEY_PREFIXES.EXPERT_CATEGORIES);
    await this.remove(DB_KEY_PREFIXES.RESPONSE_PREFERENCE);
    await this.remove(DB_KEY_PREFIXES.CASE_PRODUCTS);
    await this.remove(DB_KEY_PREFIXES.SAME_LEVEL_AND_CHILD_USERS_LIST);
    await this.remove(DB_KEY_PREFIXES.PROFESSIONAL_DESIGNATION);
    await this.remove(DB_KEY_PREFIXES.NOTE_ASSISTANT_CONFIG);
    await this.remove(DB_KEY_PREFIXES.XPERIENCE_CUSTOMERS);
    await this.remove(DB_KEY_PREFIXES.XPERIENCE_OPTION_SETS);
    await this.remove(DB_KEY_PREFIXES.XPERIENCE_INTEREST);
    await this.remove(DB_KEY_PREFIXES.CUSTOMER_LICENSES);
    await this.remove(DB_KEY_PREFIXES.XPERIENCES_TRENDING_ACCOUNTS);
    await this.remove(DB_KEY_PREFIXES.MEETING_ASSETS);
    await this.remove(DB_KEY_PREFIXES.MARKETING_PLANS);
    await this.remove(DB_KEY_PREFIXES.ADDRESS_BUILDINGS);
    await this.remove(DB_KEY_PREFIXES.EVENT_GOALS);
    await this.remove(DB_KEY_PREFIXES.PHARMACOVIGILANCE_REPORTS);
    await this.remove(DB_KEY_PREFIXES.PHARMACOVIGILANCE_REPORTS_INFO_BTNS_DATA);
    await this.remove(DB_KEY_PREFIXES.PREFERRED_ADDRESS);
    await this.remove(DB_KEY_PREFIXES.ACCOUNT_VISIT_ALLOWED_FORMAT_IDS);
    await this.remove(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS);
    await this.remove(DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS);
    await this.remove(DB_KEY_PREFIXES.USER_POSITION_EDGE_ANALYTICS_METRICS);
    await this.remove(DB_KEY_PREFIXES.ACTIVE_CONSENTS);
    await this.remove(DB_KEY_PREFIXES.USER_TODO_DATA);
    await this.remove(DB_KEY_PREFIXES.CONTRACT_TYPES);
    /*************Edit business information in offline - Non-OneKey contacts*************/
    await this.remove(DB_KEY_PREFIXES.DYNAMIC_FORMS_ALL_LOOKUP_FIELDS);
    /*************Edit business information in offline - Non-OneKey contacts*************/

    if (this.device.isNativeApp) this.store.dispatch(new ResourceAction.deleteAlldownloadedResources());

    // Delete sync states docs
    await this.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_SYNC_STATES);

    // Re-init sync states
    await this.initSyncStates();
    this.initCountHashmap();
  }

  private async initSyncStateIfDoesNotExist(key: string) {
    try {
      const doc = await this.retrieve(key, true, true);
      if (!doc) {
        const newDoc = { lastUpdatedTime: null };
        await this.createDocument(key, newDoc, true);
      }
    } catch (error) {
      console.error('initSyncStateIfDoesNotExist: ', error);
    }
  }

  private async initMasterSyncStateIfDoesNotExist(key: string) {
    try {
      const doc = await this.retrieve(key, true, true);
      if (!doc) {
        const newDoc = {
          syncType: 'initial',
          status: null,
          lastStartTime: null, 
          lastUpdatedTime: null };
        await this.createDocument(key, newDoc, true);
      }
    } catch (error) {
      console.error('initSyncStateIfDoesNotExist: ', error);
    }
  }

  public async initSyncStateForContactPosition(key) {
    await this.initSyncStateIfDoesNotExist(key);
  }
  
  private async initSyncStatesIfDoesNotExist(key: string) {
    try {
      const doc = await this.retrieve(key, true, true);
      if (!doc) {
        const newDoc = { syncStates: null };
        await this.createDocument(key, newDoc, true);
      }
    } catch (error) {
      console.error('initSyncStatesIfDoesNotExist: ', error);
    }
  }

  async initSyncStates() {
    // Needs to be added here if new sync state is added
    try {
      await Promise.all([
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_STATUS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_DAILY),
        this.initMasterSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_MASTER_STATUS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ACTIVITY),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ACCOUNT),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_MY_CALLPLAN),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_TEAM_CALLPLAN),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CONTACT),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_PRESENTATION),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_RESOURCE),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_SAMPLE_ALLOC),
        this.initSyncStatesIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CUST_SAMPLE_ALLOC),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_LOT),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ALLOC_SHIPMENT),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ALLOC_ADJUSTMENT),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ALLOC_TEAM_ADJUSTMENT),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_MY_COACHING),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_TEAM_COACHING),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_COACHING_TEMPLATES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_EMAIL_TEMPLATES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CONSENTS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CONSENT_CHANNELS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CONSENT_TERMS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CONSENT_TERMS_ALLOCATION_ORDERS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_EMAIL_ACTIVITIES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ORDER_ACTIVITIES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CONTENT_MATCHING),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_POLL_RESULTS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_MEDICAL_PROFILE),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_MARKETING_INFO),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_INQUIRY),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CONTACT_EVENT),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_SETTINGS_ABOUT),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_LICENSES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ADVANCED_SHARED_NOTIFICATION),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CONTACT_DF),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_MARKET_SCAN),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_REVIEW_CUSTOMER_POSITION_LIST),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_APPROVED_CUSTOMER_POSITION_LIST),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_REJECTED_CUSTOMER_POSITION_LIST),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_EVENTS_TOOL),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CONTACT_CR),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ACCOUNT_CR),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_EDGE_ANALYTICS_MEETING),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_EDGE_ANALYTICS_MESSAGE),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_EDGE_ANALYTICS_COACHING),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CONTENT_MATCHING_MESSAGES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_MEETINGS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_TIMEOFFS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ALLOCATION_ORDERS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_FOLLOWUP_TASKS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_PHONECALLS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_SURGERY_ORDER_ACTIVITIES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_TAG),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_ASSESS_TEMPLATES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_PROCEDURE_TRACKER_ACTIVITIES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_ASSESS_FOR_SEARCH),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ACCOUNT_ASSESS_FOR_SEARCH),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_ASSETS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ASSET_TRANSFERS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ASSET_NOTES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ALL_USERS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_MEETING_ASSETS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ALL_CONTACT_ASSESS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ALL_ACCOUNT_ASSESS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ALLOCATION_INVENTORY),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_TEAM_ORDER_NOTIFICATIONS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ACCOUNT_TAG),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CUSTOM_NOTIFICATIONS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_KOL_STATUSES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_DISEASE_AREAS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ALL_CONTACT_SURVEY),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ALL_ACCOUNT_SURVEY),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ALL_CONTACT_SURVEY_APPT),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_PRODUCT_INDICATIONS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ALL_INTERNAL_SURVEY),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_SURVEY_TEMPLATES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_MEDICAL_INSIGHTS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_PRESENTATION_TAG),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_RESOURCE_TAG),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_SUB_SPECIALTIES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_ACCOUNT_ASSESS_FOR_SEARCH),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_COACHING_PLANS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_COACHING_PLANS_ACTIVITY),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_REVIEW_APPEAL_STATUS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_PENDING_FOR_SUBMISSION_APPEAL_STATUS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_APPROVED_APPEAL_STATUS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_REJECTED_APPEAL_STATUS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_MARKETING_BUSINESS_PLAN_TYPES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_SET_BOOKING_ACTIVITIES),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_PROCEDURE_CONTRACT_POSITION_GROUP_PRODUCTS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_SUPPORTING_MATERIALS),
        this.initSyncStateIfDoesNotExist(DB_SYNC_STATE_KEYS.SYNC_CONTACT),
      ]);
    } catch (error) {
      console.error('initSyncStates: ', error);
    }
  }

  async resetSyncState(key: string) {
    await this.updateOrInsert(key, doc => {
      return { lastUpdatedTime: null };
    }, true);
  }

  async loadDBEncryptionState(initIfNot = false): Promise<boolean | null> {
    let encState = await this.retrieve(DB_KEY_PREFIXES.DB_ENCRYPTION_STATE, true, true);
    if (!encState && initIfNot) {
      // Probably Empty DB
      encState = { enabled: false };
      await this.createDocument(DB_KEY_PREFIXES.DB_ENCRYPTION_STATE, encState, true);
    }
    return encState ? encState.enabled : null;
  }

  async saveDBEncryptionState(enabled: boolean) {
    await this.updateOrInsert(DB_KEY_PREFIXES.DB_ENCRYPTION_STATE, doc => ({ enabled }), true);
  }

  public async updateOrInsert(id: string, updateFn: Function, doNotEncDec = false, throwError = false, retryCount = 0) {
    try {
      if (!doNotEncDec && this.isDBEncryptionEnabled && this._cryptoKey) {
        let doc;
        try {
          doc = await this._db.get(id);
        } catch (error) {
          if (this.isDBCloseError(error)) {
            if (retryCount > MAX_DB_REOPEN_RETRY_COUNT) {
              await this.refreshAppWithAlert();
              return;
            }
            await this.setUserDB(this._userConfig);
            return await this.updateOrInsert(id, updateFn, doNotEncDec, throwError, retryCount + 1);
          } else if (this.isDBOutOfMemoryError(error)) {
            await this.outOfMemoryAppWithAlert();
            return;
          }
        }

        const mask = this._getDBEncryptionMaskingFields(id);
        if (doc) {
          // Update flow
          const rev = doc._rev;

          // decrypt
          const decryptedDoc = await this._decryptData(doc, mask);

          // update doc
          const newDoc = updateFn(decryptedDoc);
          newDoc._id = id;
          newDoc._rev = rev;

          const encryptedDoc = await this._encryptData(newDoc, mask);

          return await this._db.put(encryptedDoc);
        } else {
          // Insert flow
          const newDoc = updateFn();
          newDoc._id = id;

          const encryptedDoc = await this._encryptData(newDoc, mask);

          return await this._db.put(encryptedDoc);
        }
      } else {
        return await this._db.upsert(id, updateFn);
      }
    } catch (error) {
      if (this.isDBCloseError(error)) {
        if (retryCount > MAX_DB_REOPEN_RETRY_COUNT) {
          await this.refreshAppWithAlert();
          return;
        }
        await this.setUserDB(this._userConfig);
        await Utility.delay(300);
        return await this.updateOrInsert(id, updateFn, doNotEncDec, throwError, retryCount + 1);
      } else if (this.isDBOutOfMemoryError(error)) {
        await this.outOfMemoryAppWithAlert();
        return;
      }
      console.error(`updateOrInsert: ${error?.message}`, error);
    }
  }

  /**
   * Simple wrapper for db.get
   *
   * @param {string} key
   * @returns {Promise<any>}
   * @memberof DiskService
   */
  async retrieve(key: string, ignoreMissingError = false, doNotDecrypt = false, retryCount = 0): Promise<any> {
    try { // please wrap I/O ops in try and catch block
      if (!doNotDecrypt && this.isDBEncryptionEnabled && this._cryptoKey) {
        const doc = await this._db.get(key);
        if (doc) {
          const mask = this._getDBEncryptionMaskingFields(doc._id);
          return await this._decryptData(doc, mask);
        }
      } else {
        return await this._db.get(key);
      }
    } catch (e) {
      //i dont care
      //this.globalErrorHandler.handleError(new Error(e));
      if (ignoreMissingError && e.error && e.name === 'not_found') return Promise.resolve();
      // console.warn(`retrieve: key: ${key} e: `, e);
      if (this.isDBCloseError(e)) {
        if (retryCount > MAX_DB_REOPEN_RETRY_COUNT) {
          await this.refreshAppWithAlert();
          return;
        }
        await this.setUserDB(this._userConfig);
        return await this.retrieve(key, ignoreMissingError, doNotDecrypt, retryCount + 1);
      }  else if (this.isDBOutOfMemoryError(e)) {
        await this.outOfMemoryAppWithAlert();
        return;
      }
      console.error(`retrieve: ${e?.message}`, e);
    }
  }

  /**
   * Sanitizes the objects passed in and then bulkDocs adds to DB
   *
   * @param {Array<any>} objects
   * @memberof DiskService
   */
  async bulk(objects: Array<any>, doNotEncrypt = false, retryCount = 0): Promise<{ id: string, ok: boolean, rev: string }[]> {
    let docs = objects;
    if (!doNotEncrypt && this.isDBEncryptionEnabled && this._cryptoKey) {
      docs = [];
      for (let i = 0; i < objects.length; i++) {
        const row = objects[i];
        if (row && row.hasOwnProperty('_id')) {
          const mask = this._getDBEncryptionMaskingFields(row._id);
          try {
            const encryptedDoc = await this._encryptData(row, mask);
            docs.push(encryptedDoc);
          } catch (error) {
            console.error('bulk: encryption failed!', row);
          }
        } else {
          console.warn(`bulk: No '_id' found`, row);
        }
      }
    }

    try {
      return await this._db.bulkDocs(docs);
    } catch (error) {
      if (this.isDBCloseError(error)) {
        if (retryCount > MAX_DB_REOPEN_RETRY_COUNT) {
          await this.refreshAppWithAlert();
          return;
        }
        await this.setUserDB(this._userConfig);
        return await this.bulk(objects, doNotEncrypt, retryCount + 1);
      } else if ((this.isDBOutOfMemoryError(error))) {
        await this.outOfMemoryAppWithAlert();
        return;
      }
      console.error(`bulk: ${error?.message}`, error);
    }
  }

  /**
   * Removes a document from the DB
   * @param key
   * @param payload
   */
  async remove(key: string, retryCount = 0) {
    try {
      const document = await this._db.get(key);
      if (document) {
        return await this._db.put({ _id: document._id, _rev: document._rev, _deleted: true });
      }
    } catch (error) {
      this.logService.logError("Nothing to delete for " + key + "=>" + error);
      if (this.isDBCloseError(error)) {
        if (retryCount > MAX_DB_REOPEN_RETRY_COUNT) {
          await this.refreshAppWithAlert();
          return;
        }
        await this.setUserDB(this._userConfig);
        return await this.remove(key, retryCount + 1);
      }  else if (this.isDBOutOfMemoryError(error)) {
        await this.outOfMemoryAppWithAlert();
        return;
      }
    }
  }

  /**
   * Retrieve the DB Enc / Dec Masking fields so that
   * these fields can be ignored during the enc / dec
   * @param keyOrId
   */
  private _getDBEncryptionMaskingFields(keyOrId: string) {
    let mask = ['_id', '_rev', '_deleted', 'pendingPushToDynamics'];
    if (keyOrId.startsWith(DB_KEY_PREFIXES.ACTIVITY)) {
      mask = [...mask, 'scheduledstart', 'scheduledend'];
    } else if (keyOrId.startsWith(DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY)) {
      mask = [...mask, 'scheduledstart','activityid'];
    } else if (keyOrId.startsWith(DB_KEY_PREFIXES.ORDER_ACTIVITY)) {
      mask = [...mask, 'salesorderid'];
    } else if (keyOrId.startsWith(DB_KEY_PREFIXES.CONTACT_EVENT)) {
      mask = [...mask, 'availableEvents', 'upcomingEvents'];
    } else if (keyOrId.startsWith(DB_KEY_PREFIXES.ALLOC_SHIPMENT)) {
      mask = [...mask, 'indskr_lotvalidtodate'];
    } else if (keyOrId.startsWith(DB_KEY_PREFIXES.CUST_SAMPLE_ALLOC)) {
      mask = [...mask, 'indskr_startdate', 'indskr_enddate'];
    } else if (keyOrId.startsWith(DB_KEY_PREFIXES.LOT)) {
      mask = [...mask, 'indskr_lotvalidtodate'];
    } else if (keyOrId.startsWith(DB_KEY_PREFIXES.TIMEOFF)) {
      mask = [...mask, 'indskr_starttime', 'indskr_endtime'];
    } else if (keyOrId.startsWith(DB_KEY_PREFIXES.MY_CASES) || keyOrId.startsWith(DB_KEY_PREFIXES.TEAM_CASES)) {
      mask = [...mask, 'caseResolutionDate'];
    } else if (keyOrId.startsWith(DB_KEY_PREFIXES.POLL_RESULTS)) {
      mask = [...mask, 'contactId','pollTemplateId','submitted'];
    } else if(keyOrId.startsWith(DB_KEY_PREFIXES.MARKET_SCANS)) {
      mask = [...mask, 'indskr_date'];
    }

    return mask;
  }

  public async loadOfflinePhoneCallMeetings(): Promise<any> {
    try {
      let offlineMeetingDocument = await this.retrieve('offlinePhoneCalls', true);
      offlineMeetingDocument && Array.isArray(offlineMeetingDocument.meetings) && offlineMeetingDocument.meetings.length > 0
        ? this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PHONECALL_MEETING, offlineMeetingDocument.meetings.length)
          : this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PHONECALL_MEETING, 0);
      return offlineMeetingDocument;
    } catch (diskError) {
      //console.log('Disk error caught', diskError);
      this.globalErrorHandler.handleError(new Error(diskError));
    }
  }

  public async loadOfflineMeetings(): Promise<any> {
    try {
      let offlineMeetingDocument = await this.retrieve('offlineMeetings', true);
      offlineMeetingDocument && Array.isArray(offlineMeetingDocument.meetings) && offlineMeetingDocument.meetings.length > 0
        ? this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING, offlineMeetingDocument.meetings.length)
          : this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING, 0);
      return offlineMeetingDocument;
    } catch (diskError) {
      //console.log('Disk error caught', diskError);
      this.globalErrorHandler.handleError(new Error(diskError));
    }
  }

  public async loadOfflineMeetingPresentationss(): Promise<any> {
    try {
      const offlineMeetingPresDocument = await this.retrieve('offlineMeetingPresentationss', true);
      !_.isEmpty(offlineMeetingPresDocument) ?
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING_PRESENTATIONS, offlineMeetingPresDocument.meetings.length)
        : this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING_PRESENTATIONS, 0);
      return offlineMeetingPresDocument;
    } catch (diskError) {
      this.globalErrorHandler.handleError(new Error(diskError));
    }
  }

  public async loadOfflineSampleOrders(): Promise<any> {
    try {
      let offlineSamplesDocument = await this.retrieve('offlineSampleOrders', true);
      offlineSamplesDocument && Array.isArray(offlineSamplesDocument.orders) && offlineSamplesDocument.orders.length > 0
        ? this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SAMPLE_ORDER, offlineSamplesDocument.orders.length)
          : this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SAMPLE_ORDER, 0);
      return offlineSamplesDocument;
    } catch (diskError) {
      //console.log('Disk error caught', diskError);
      this.globalErrorHandler.handleError(new Error(diskError));
    }
  }

  public async loadOfflineEmails(): Promise<any> {
    try {
      let offlineEmailDocument = await this.retrieve('offlineEmails', true);
      offlineEmailDocument && Array.isArray(offlineEmailDocument.emails) && offlineEmailDocument.emails.length > 0
        ? this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EMAIL, offlineEmailDocument.emails.length)
          : this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EMAIL, 0);
      return offlineEmailDocument;
    } catch (diskError) {
      //console.log('Disk error caught', diskError);
      this.globalErrorHandler.handleError(new Error(diskError));
    }
  }

  public async loadOfflineCapturedEventRegResponse(): Promise<any> {
    try {
      let offlineEventRegResponseCaptured = await this.retrieve('offlineEventRegResponse', true);
      offlineEventRegResponseCaptured && Array.isArray(offlineEventRegResponseCaptured.eventRegResponse) && offlineEventRegResponseCaptured.eventRegResponse.length > 0
        ? this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_EVENT, offlineEventRegResponseCaptured.eventRegResponse.length)
          : this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_EVENT, 0);
      return offlineEventRegResponseCaptured;
    } catch (diskError) {
      //console.log('Disk error caught', diskError);
      this.globalErrorHandler.handleError(new Error(diskError));
    }
  }

  public async loadOfflineCoachingReports(): Promise<any> {
    try {
      let offlineCoachingReports = await this.retrieve('offlineCoachingReports', true);
      offlineCoachingReports && Array.isArray(offlineCoachingReports.coachingReports) && offlineCoachingReports.coachingReports.length > 0
        ? this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.COACHING_REPORT, offlineCoachingReports.coachingReports.length)
          : this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.COACHING_REPORT, 0);
      return offlineCoachingReports;
    } catch (diskError) {
      //console.log('Disk error caught', diskError);
      this.globalErrorHandler.handleError(new Error(diskError));
    }
  }


  public async updateOfflineRepCallPlan(repCallPlan) {
    let offlineCallPlans = await this.retrieve(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS, true);
    if (offlineCallPlans && offlineCallPlans.raw?.length) {
      let currentRepPlanIndex = offlineCallPlans.raw.findIndex(o=>o.indskr_customercallplanid == repCallPlan.indskr_customercallplanid);
      if(currentRepPlanIndex) offlineCallPlans.raw[currentRepPlanIndex] = repCallPlan
      return await this.updateDocWithIdAndRev(offlineCallPlans);
    }
  }

  /**
   *  Updates our offline repcallplan document with the updated change
   *
   */
  // public async updateOfflineRepCallPlan(callPlan, repCallPlan) {
  //   let offlineCallPlanDetailDocument = await this.retrieve(`${callPlan.ID}_details`, true);

  //   if (offlineCallPlanDetailDocument && offlineCallPlanDetailDocument.hasOwnProperty('raw') && Array.isArray(offlineCallPlanDetailDocument.raw)) {
  //     const index = offlineCallPlanDetailDocument.raw.findIndex(rawRepCallPlan => repCallPlan.ID === rawRepCallPlan.indskr_customercallplanid);
  //     if (index) {
  //       //Is valid index?
  //       if (index >= 0) {
  //         offlineCallPlanDetailDocument.raw[index] = repCallPlan.DTO;
  //       } else {
  //         console.error('Tried updating an offline repcallplan document with a new entity and couldnt find the index');
  //       }

  //       return await this.updateDocWithIdAndRev(offlineCallPlanDetailDocument);
  //     }
  //   }
  // }

  /**
   *  Update or insert an individual Activity detail to the db as DTO.
   *  Saving as DTO format since these will act as an api call response for offline or quick initial data load.
   *
   * @param {AppointmentActivity | TimeOffActivity} activity
   * @returns
   * @memberof DiskService
   */
  public async updateOrInsertActivityToActivityDetailRawDocument(activity: AppointmentActivity | TimeOffActivity | SampleActivity | EmailActivity | 
    PhoneActivity | StoreCheckActivity, doNotInsert = false, timeOffType: string = null) {
    if (activity.ID && activity.ID.includes('offline')) return Promise.resolve({ result: 'Ignoring offline created activity' });

    let key: string;
    if (activity instanceof AppointmentActivity) {
      key = DB_KEY_PREFIXES.MEETING_ACTIVITY + activity.ID;
    } else if (activity instanceof TimeOffActivity) {
      if (timeOffType === 'My') {
        key = activity._id = DB_KEY_PREFIXES.MY_TIMEOFF + activity.ID;
      } else if (timeOffType === 'Team') {
        key = activity._id = DB_KEY_PREFIXES.TEAM_TIMEOFF + activity.ID;
      }
    } else if (activity instanceof SampleActivity) {
      key = DB_KEY_PREFIXES.SAMPLE_ACTIVITY + activity.ID;
    } else if (activity instanceof EmailActivity) {
      key = DB_KEY_PREFIXES.EMAIL_ACTIVITY + activity.ID;
    } else if (activity instanceof PhoneActivity) {
      key = DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY + activity.ID;
    } else if (activity instanceof StoreCheckActivity) {
      key = DB_KEY_PREFIXES.STORE_CHECK_ACTIVITY + activity.ID;
    }

    if (!key) return Promise.reject('updateOrInsertActivityToActivityDetailRawDocument: id is undefined');

    let meetingDocument = await this.retrieve(key, true);

    if (meetingDocument) {
      const rev: string = meetingDocument._rev;
      const id: string = meetingDocument._id || key;
      if (activity instanceof AppointmentActivity) {
        meetingDocument = activity.detailDTO;
      } else if (activity instanceof TimeOffActivity) {
        meetingDocument = activity.DTO;
      }
      else if (activity instanceof SampleActivity) {
        meetingDocument = (activity as SampleActivity).DTO;
      } else if (activity instanceof EmailActivity) {
        meetingDocument = (activity as EmailActivity).DTO;
      }else if (activity instanceof PhoneActivity) {
        meetingDocument = (activity as PhoneActivity).DTO;
      } else if (activity instanceof StoreCheckActivity) {
        meetingDocument = (activity as StoreCheckActivity).DTO;
      }
      meetingDocument._id = id;
      meetingDocument._rev = rev;
      if (activity.lastUpdatedTime) {
        meetingDocument.lastUpdatedTime = activity.lastUpdatedTime;
      }

      return await this.updateDocWithIdAndRev(meetingDocument);
    } else {
      if (!doNotInsert) {
        let newDoc;
        if (activity instanceof AppointmentActivity) {
          newDoc = activity.detailDTO;
        } else {
          newDoc = activity.DTO;
        }

        return await this.updateOrInsert(key, doc => newDoc)
          .catch(error => console.error('updateOrInsertActivityToActivityDetailRawDocument: ', error));
      }
      return Promise.resolve({ result: 'DATA_NOT_FOUND' });
    }
  }

  public async createOfflineMeeting(activity: AppointmentActivity): Promise<object> {
    activity.createdOffline = true;

    try {
      await this.updateOrInsert('offlineMeetings', document => {
        //If we don't have a document for offlineMeetings then create one with our appointmentActivity as the only item
        activity.subject = (activity.subject) ? activity.subject : 'New Offline Meeting';

        if (!document.meetings) {
          document = {
            meetings: [],
          };
          document.meetings = [activity.DTO];
        } else {
          //If we do have a document, add our appointmentactivity to the array.
          document.meetings.push(activity.DTO);
        }

        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.MEETING, document.meetings.length);
        return document;
      });

      return activity.DTO;
    } catch (diskError) {
      this.createOfflineMeetingCollection(activity);
      //Handle error
      this.notificationService.notify(this.translate.instant('DISK_ERROR_CAUGHT'),'Home Page');
      console.log('Caught a disk error trying to create offline meeting', diskError);
    }
  }

  public async createOfflineEmailActivity(activity: EmailActivity) {
    try {
      await this.updateOrInsert('offlineEmails', document => {

        if (!document || !document.emails) {
          document = {
            emails: [],
            count: 0
          };
          document.emails = [activity.DTO];
          document.count = 1;
        }
        else {
          document.count++;
          document.emails.push(activity.DTO);
        }

        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EMAIL, document.emails.length);
        return document;
      });
    } catch (diskError) {
      console.log('Caught a disk error trying to create offline email activity', diskError);
    }
  }

  public async captureEventRegResponse(captureEventRegResponse: EventRegistrationResponse) {
    try {
      await this.updateOrInsert('offlineEventRegResponse', document => {
        if (!document || !document.eventRegResponse) {
          document = {
            eventRegResponse: [],
            count: 0
          };
          document.eventRegResponse = [captureEventRegResponse];
          document.count = 1;
        }
        else {
          document.count++;
          document.eventRegResponse.push(captureEventRegResponse);
        }

        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_EVENT, document.eventRegResponse.length);
        return document;
      });
      console.log('Captured Event Registration Response in local ', captureEventRegResponse);
    } catch (diskError) {
      console.log('Caught a disk error trying to capture event registration response in offline', diskError);
    }
  }

  private async createOfflineMeetingCollection(activity: AppointmentActivity) {
    try {
      const newDoc = {
        meetings: [],
        count: 0
      }

      await this.createDocument('offlineMeetings', newDoc);
    } catch (diskError) {
      console.log('Caught a diskError trying to create offline meeting collection', diskError)
    }
  }

  public async createOfflineSampleOrder(activity: SampleActivity) {
    activity.createdOffline = true;

    try {
      const raw: any = activity.DTO;
      await this.updateOrInsert('offlineSampleOrders', document => {
        //If we don't have a document for offlineMeetings then create one with our appointmentActivity as the only item
        if (!document || !Array.isArray(document.orders)) {
          document = {
            orders: [],
            count: 0
          };
          document.orders = [raw];
          document.count = 1;
        } else {
          //If we do have a document, add our appointmentactivity to the array and increase count.
          document.count++;
          document.orders.push(raw);
        }

        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SAMPLE_ORDER, document.orders.length);
        return document;
      });

      return raw;
    } catch (diskError) {
      //this.createOfflineMeetingCollection(activity);
      console.log('Caught a disk error trying to create offline sample order', diskError);
    }
  }

  public async updateofflineSampleOrderDocument(activity: SampleActivity) {
    try {
      await this.updateOrInsert('offlineSampleOrders', document => {
        //update the exisiting entry in the document for this activity
        let updatedIndex;
        document.orders.map((o, index) => {
          if (o.activityid == activity.ID) {
            updatedIndex = index
          } else if (o.offlineActivityId == activity.offlineActivityId) {
            updatedIndex = index
          }
        })
        document.orders[updatedIndex] = activity.DTO;
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SAMPLE_ORDER, document.orders.length);
        return document;
      });

      return activity.DTO;
    } catch (diskError) {
      //this.createOfflineMeetingCollection(activity);
      console.log('Caught a disk error trying to create offline sample order', diskError);
    }
  }

  public async updateofflineEmailActivtyDocument(activity: EmailActivity) {
    try {
      await this.updateOrInsert('offlineEmails', document => {
        //Fix for offline resource upload, f75445e
        //No email on pouch found, create new entity to map the DTO for upload and increase the count
        if (!document.emails) {
          document = {
            emails: [],
            count: 0
          };
          document.emails.push(activity.DTO);
          document.count = 1;
          this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EMAIL, document.emails.length);
          return document;
        }
        else {
          //update the exisiting entry in the document for this activity
          let updatedIndex;
          document.emails.map((o, index) => {
            if (o.activityid == activity.ID) {
              updatedIndex = index
            } else if (o.offlineActivityId == activity.offlineActivityId) {
              updatedIndex = index
            }
          })
          document.emails[updatedIndex] = activity.DTO;
          this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EMAIL, document.emails.length);
          return document;
        }
      });
    } catch (diskError) {
      //this.createOfflineMeetingCollection(activity);
      console.log('Caught a disk error trying to update offline email', diskError);
    }
  }

  public async updateofflineCoachingReportDocument(report) {
    try {
      await this.updateOrInsert('offlineCoachingReports', document => {
        //Fix for offline resource upload, f75445e
        //No coaching report on pouch found, create new entity to map the DTO for upload and increase the count
        if (!document || !document.coachingReports) {
          document = {
            coachingReports: [],
            count: 0
          };
          if (report instanceof Report) {
            document.coachingReports.push(report.DTO);
          } else {
            document.coachingReports.push(report);
          }
          document.count = 1;
          this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.COACHING_REPORT, document.coachingReports.length);
          return document;
        }
        else {
          //update the exisiting entry in the document for this report
          let updatedIndex = document.coachingReports.findIndex(o => {
            return (report.indskr_coachingreportid && report.indskr_coachingreportid.length > 0 && (o.indskr_coachingreportid === report.indskr_coachingreportid)) || (o.offlineCoachingReportId === report.offlineCoachingReportId)
          });
          if (updatedIndex >= 0) {
            if (report instanceof Report) {
              document.coachingReports[updatedIndex] = report.DTO;
            } else {
              document.coachingReports[updatedIndex] = report;
            }
          }
          this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.COACHING_REPORT, document.coachingReports.length);
          return document;
        }
      });
      console.log("Updated offline coaching report document");
    } catch (diskError) {
      console.log('Caught a disk error trying to update offline coaching report', diskError);
    }
  }

  public async removeOfflineCreatedEmailFromDocument(activity: EmailActivity) {
    await this.updateOrInsert('offlineEmails', document => {
      if (!document || !Array.isArray(document.emails)) {
        document = {
          emails: [],
          count: 0
        };
      }
      //update the exisiting entry in the document for this activity
      let indexOfEmailActivityToRemove;
      document.emails.map((o, index) => {
        if (o.offlineActivityId == activity.ID) {
          indexOfEmailActivityToRemove = index
        }
      })

      if (indexOfEmailActivityToRemove >= 0) {
      document.emails.splice(indexOfEmailActivityToRemove, 1);
      document.count--;
      }
      this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.EMAIL, document.emails.length);
      return document;
    });
  }

  public async removeOfflineCreatedCoachingReportFromDocument(report) {
    await this.updateOrInsert('offlineCoachingReports', document => {
      if (!document || !Array.isArray(document.coachingReports)) {
        document = {
          coachingReports: [],
          count: 0
        };
      }
      //update the exisiting entry in the document for this activity
      let indexOfCoachingReportToRemove = document.coachingReports.findIndex(o => {
        return o.offlineCoachingReportId == report.offlineCoachingReportId
      })

      if (indexOfCoachingReportToRemove >= 0) {
      document.coachingReports.splice(indexOfCoachingReportToRemove, 1);
      document.count--;
      }
      this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.COACHING_REPORT, document.coachingReports.length);
      return document;
    });
  }

  /* Time Off Related Offline Logic */
  /* =================================== */

  /* Used while performing the sync operation from master service */
  public async loadOfflineTimeOffs(): Promise<any> {
    try {
      let offlineTimeOffDocument = await this.retrieve('offlineTimeOffs', true);
      this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.TIME_OFF, offlineTimeOffDocument && Array.isArray(offlineTimeOffDocument.myTos) ? offlineTimeOffDocument.myTos.length : 0);
      return offlineTimeOffDocument;
    } catch (diskError) {
      //console.log('Disk error caught', diskError);
      this.globalErrorHandler.handleError(new Error(diskError));
    }
  }

  /* Logic to add/update time-off request created in offilne mode on the update payload/
  /**
   * Adds a raw generated response to our Timeoff disk to simulate
   *
   * @param {object} tot
   * @returns
   * @memberof DiskService
  */
  public async insertOfflineTimeOffToDatabase(tot: TimeOffActivity) {
    let offlineTimeOffs;
    try {
      offlineTimeOffs = await this.retrieve('offlineTimeOffs', true);
    } catch (diskError) {
      console.error('Caught error trying to access offline timeoff', diskError);
    }

    if (!offlineTimeOffs || offlineTimeOffs == undefined) {
      try {
        await this.updateOrInsert('offlineTimeOffs', doc => ({ myTos: JSON.parse(JSON.stringify([])) }));
        this.logService.logInfo("Successfully saved offlineTO");
        this.insertOfflineTimeOffToDatabase(tot);
      } catch (error) {
        this.logService.logError("Failed saving offlineTO");
      }
      offlineTimeOffs = await this.retrieve('offlineTimeOffs');
    }
    else {
      if (Array.isArray(offlineTimeOffs['myTos'])) {
        let index = this.getOfflineIndex(offlineTimeOffs['myTos'], tot);
        let obj: object = {
          indskr_starttime: new Date(tot.totStartTime).valueOf(),
          indskr_endtime: new Date(tot.totEndTime).valueOf(),
          indskr_name: tot.name,
          indskr_positionid: tot.positionId,
          indskr_isalldayevent: tot.totIsAlldayEvent,
          indskr_reason: tot.timeOffReason,
          indskr_local_timeoffrequestid: tot.timeOffRequestId,
          'indskr_reason@OData.Community.Display.V1.FormattedValue': tot.reason,
          indskr_comments: tot.comments
        }
        //update offline timeoff data
        if (index >= 0) {
          //replace the existing value with new obj
          offlineTimeOffs.myTos[index] = obj;
        }
        //new offline timeoff data
        else {
          offlineTimeOffs.myTos.push(obj);
        }
        await this._saveOfflineTimeOffData(offlineTimeOffs['myTos']);
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.TIME_OFF, offlineTimeOffs['myTos'].length);
      }
    }
  }

  /* Logic to update time-off request which have been already registered on CRM for the update payload */
  /**
 * Adds a raw generated response to our Timeoff disk to simulate
 *
 * @param {object} tot
 * @returns
 * @memberof DiskService
*/
  public async updateOfflineTimeOffToPayload(tot: TimeOffActivity) {
    let offlineTimeOffs;
    try {
      offlineTimeOffs = await this.retrieve('offlineTimeOffs', true);
    } catch (diskError) {
      console.error('Caught error trying to access offline timeoff', diskError);
    }

    if (!offlineTimeOffs || offlineTimeOffs == undefined) {
      try {
        await this.updateOrInsert('offlineTimeOffs', doc => ({ myTos: JSON.parse(JSON.stringify([])) }));
        this.logService.logInfo("Successfully saved offlineTO");
        this.updateOfflineTimeOffToPayload(tot);
      } catch (error) {
        this.logService.logError("Failed saving offlineTO");
      }
    }
    else {
      if (Array.isArray(offlineTimeOffs["myTos"])) {
        let index = offlineTimeOffs["myTos"].findIndex((t: object) => {
          if (t.hasOwnProperty('indskr_timeoffrequestid')) {
            return t["indskr_timeoffrequestid"] === tot.timeOffRequestId;
          }
          if (t.hasOwnProperty('indskr_local_timeoffrequestid')) {
            return t["indskr_local_timeoffrequestid"] === tot.timeOffRequestId;
          }
          return false;
        });

        //update offline timeoff data
        if (index >= 0) {
          //update offline created timeoff
          if (tot.timeOffRequestId.includes('offline')) {
            let obj: object = {
              indskr_starttime: new Date(tot.totStartTime).valueOf(),
              indskr_endtime: new Date(tot.totEndTime).valueOf(),
              indskr_name: tot.name,
              indskr_positionid: tot.positionId,
              indskr_isalldayevent: tot.totIsAlldayEvent,
              indskr_reason: tot.timeOffReason,
              indskr_local_timeoffrequestid: tot.timeOffRequestId,
              'indskr_reason@OData.Community.Display.V1.FormattedValue': tot.reason,
              indskr_comments: tot.comments
            }
            offlineTimeOffs.myTos[index] = obj;
          }
          else {
            //replace the existing value with new obj
            let obj: object = {
              indskr_reason: tot.timeOffReason,
              indskr_name: tot.name,
              statecode: tot.statecode,
              statuscode: tot.statuscode,
              indskr_positionid: tot.positionId,
              indskr_isalldayevent: tot.totIsAlldayEvent,
              indskr_starttime: new Date(tot.totStartTime).valueOf(),
              indskr_endtime: new Date(tot.totEndTime).valueOf(),
              indskr_timeoffrequestid: tot.timeOffRequestId,
              'indskr_reason@OData.Community.Display.V1.FormattedValue': tot.reason,
              indskr_comments: tot.comments
            }
            offlineTimeOffs.myTos[index] = obj;
          }
        }
        //new update offline timeoff data
        else {
          let obj: object = {
            indskr_reason: tot.timeOffReason,
            indskr_name: tot.name,
            statecode: tot.statecode,
            statuscode: tot.statuscode,
            indskr_positionid: tot.positionId,
            indskr_isalldayevent: tot.totIsAlldayEvent,
            indskr_starttime: new Date(tot.totStartTime).valueOf(),
            indskr_endtime: new Date(tot.totEndTime).valueOf(),
            indskr_timeoffrequestid: tot.timeOffRequestId,
            'indskr_reason@OData.Community.Display.V1.FormattedValue': tot.reason,
            indskr_comments: tot.comments
          }
          offlineTimeOffs.myTos.push(obj);
        }
      }
      await this._saveOfflineTimeOffData(offlineTimeOffs['myTos']);
      this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.TIME_OFF, offlineTimeOffs['myTos'].length);
    }
  }

  /* Logic to remove time-off request from the update payload */
  /**
   * Adds a raw generated response to our Timeoff disk to simulate
   *
   * @param {object} tot
   * @returns
   * @memberof DiskService
  */
  public async removeTimeOffFromDatabase(tot: TimeOffActivity) {
    let offlineTimeOffs;
    try {
      offlineTimeOffs = await this.retrieve('offlineTimeOffs', true);
    } catch (diskError) {
      console.error('Caught error trying to access offline timeoff', diskError);
    }

    if (!offlineTimeOffs || offlineTimeOffs == undefined) {

      try {
        await this.updateOrInsert('offlineTimeOffs', doc => ({ myTos: JSON.parse(JSON.stringify([])) }));
        this.logService.logInfo("Successfully saved offlineTO");
        this.removeTimeOffFromDatabase(tot);
      } catch (error) {
        this.logService.logError("Failed saving offlineTO");
      }
    }
    else {
      if (Array.isArray(offlineTimeOffs['myTos'])) {

        //offline time-off request delete the new offline request from the payload
        if (tot.timeOffRequestId.includes("offline")) {
          let index = this.getOfflineIndex(offlineTimeOffs['myTos'], tot);

          //update offline timeoff data
          if (index >= 0) {
            offlineTimeOffs['myTos'].splice(index, 1);
          }
        }
        //new offline deletion for TOT registered on CRM
        else {
          if (!tot.timeOffRequestId.includes("offline")) {
            let obj: object = {
              statuscode: 100000001,
              indskr_timeoffrequestid: tot.timeOffRequestId,
            }
            offlineTimeOffs['myTos'].push(obj);
          }
          console.error("invalid scenario, offline timeoff not detected in payload!!");
        }
        await this._saveOfflineTimeOffData(offlineTimeOffs['myTos']);
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.TIME_OFF, offlineTimeOffs['myTos'].length);
      }
    }
  }

  private async _saveOfflineTimeOffData(raw: any[]) {
    await this.updateOrInsert('offlineTimeOffs', doc => ({ myTos: raw }))
      .catch(error => this.logService.logError("Failed saving offlineTO", error));
  }

  /* deleting the local list of timeoff on index_db */
  public async deleteOfflineTimeOff(tot: TimeOffActivity, isTeamTot: boolean) {
    const key = isTeamTot ? DB_KEY_PREFIXES.TEAM_TIMEOFF + tot.ID : DB_KEY_PREFIXES.MY_TIMEOFF + tot.ID;

    const doc = await this.retrieve(key, true);
    if (doc && doc._id && doc._rev) {
      try {
        await this.updateDocWithIdAndRev({ _id: doc._id, _rev: doc._rev, _deleted: true });
      } catch (error) {
        console.error(`deleteOfflineTimeOff: `, error);
      }
    } else {
      console.warn(`deleteOfflineTimeOff: doc not found for ${tot.ID}: ${tot.name}`);
    }
  }

  /* deleting the local list of timeoff on index_db */
  public async removeOfflineTimeOffFromOfflineTable(tot: TimeOffActivity) {
    let offlineTimeOffs;
    try {
      offlineTimeOffs = await this.retrieve('offlineTimeOffs', true);
    } catch (diskError) {
      console.error('Caught error trying to access offline timeoff', diskError);
    }

    if (!offlineTimeOffs || offlineTimeOffs == undefined) {
      try {
        await this.updateOrInsert('offlineTimeOffs', doc => ({ myTos: JSON.parse(JSON.stringify([])) }));
        this.logService.logInfo("Successfully saved offlineTO");
        this.removeTimeOffFromDatabase(tot);
      } catch (error) {
        this.logService.logError("Failed saving offlineTO");
      }
    }
    else {
      if (Array.isArray(offlineTimeOffs['myTos'])) {

        //get index of timeoff in offline table
        let index = this.getOfflineIndex(offlineTimeOffs['myTos'], tot, true);

        //remove timeoff record in offline table
        if (index >= 0) {
          offlineTimeOffs['myTos'].splice(index, 1);
          await this._saveOfflineTimeOffData(offlineTimeOffs['myTos']);
          this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.TIME_OFF, offlineTimeOffs['myTos'].length);
        }
      }
    }
  }

  private getOfflineIndex(data: any[], tot: TimeOffActivity, offlineTimeOffRequestId?: boolean): number {
    let i: number;
    let totID = offlineTimeOffRequestId ? tot.offlineTimeOffRequestId : tot.timeOffRequestId
    data.forEach((e: any, index: number) => {
      if (e.hasOwnProperty('indskr_local_timeoffrequestid')) {
        if (e.indskr_local_timeoffrequestid === totID) {
          i = index;
        }
      }
    });
    console.log("payload detected at index " + i);
    return i === undefined ? -1 : i;
  }


  public async loadOfflineCases(): Promise<any> {
    try {
      let offlineMIDocument = await this.retrieve('offlineCustomerInquiries', true);
      offlineMIDocument && Array.isArray(offlineMIDocument.myCases) && offlineMIDocument.myCases.length > 0
        ? this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CUSTOMER_INQUIRY, offlineMIDocument.myCases.length)
          : this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CUSTOMER_INQUIRY, 0)
      return offlineMIDocument;
    } catch (diskError) {
      this.globalErrorHandler.handleError(new Error(diskError));
    }
  }

  public async insertOfflineCustomerInquiries(iCase: CaseActivity) {
    let offlineCustomerInquiries;
    try {
      offlineCustomerInquiries = await this.retrieve('offlineCustomerInquiries', true);
    } catch (diskError) {
    }

    if (!offlineCustomerInquiries || offlineCustomerInquiries == undefined) {
      try {
        await this.updateOrInsert('offlineCustomerInquiries', doc => ({ myCases: JSON.parse(JSON.stringify([])) }));
        this.insertOfflineCustomerInquiries(iCase);
      } catch (error) {
        console.error('insertOfflineCustomerInquiries: ', error);
      }
      offlineCustomerInquiries = await this.retrieve('offlineCustomerInquiries');
    }
    else {
      if (Array.isArray(offlineCustomerInquiries['myCases'])) {
        let calc = (data: any[], c: CaseActivity) => {
          let i: number;
          let id = c.ID;
          data.forEach((e: any, index: number) => {
            if (e.hasOwnProperty('offlineCaseId')) {
              if (e.offlineCaseId === id) {
                i = index;
              }
            }
          });
          console.log("payload detected at index " + i);
          return i === undefined ? -1 : i;
        }
        let index = calc(offlineCustomerInquiries['myCases'], iCase);
        if (index >= 0) {
          offlineCustomerInquiries.myCases[index] = iCase.offlineDTO;
        }
        else {
          offlineCustomerInquiries.myCases.push(iCase.offlineDTO);
        }
        await this.updateOrInsert('offlineCustomerInquiries', doc => offlineCustomerInquiries)
          .then(() => this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CUSTOMER_INQUIRY, offlineCustomerInquiries.myCases.length))
          .catch(error => console.error('insertOfflineCustomerInquiries: ', error));
      }
    }
  }

  public async addCaseToCollection(data: CaseActivity, update?: boolean) {

    let key;

    let tid = update ? data.offline_ID : data.ID;

    key = DB_KEY_PREFIXES.MY_CASES + tid;

    let doc = await this.retrieve(key, true);

    let payload = data.generatedRaw;

    payload["lastUpdatedTime"] = new Date().getTime();
    payload._id = DB_KEY_PREFIXES.MY_CASES + data.ID;


    if (doc) {
      return await this.updateOrInsert(key, () => {
        return payload;
      }).catch(e => console.log(e));
    }
    else {
      return await this.updateOrInsert(key, doc => payload)
        .catch(error => console.error('addCaseToCollection: ', error));
    }

  }

  public async updateTeamCaseToCollection(data: CaseActivity) {

    let key = DB_KEY_PREFIXES.TEAM_CASES + data.ID;

    let doc = await this.retrieve(key, true);

    let payload = data.generatedRaw;

    payload["lastUpdatedTime"] = new Date().getTime();
    payload._id = DB_KEY_PREFIXES.TEAM_CASES + data.ID;


    if (doc) {
      return await this.updateOrInsert(key, () => {
        return payload;
      }).catch(e => console.log(e));
    }

  }

  public async removeCaseFromOfflinePayload(data: CaseActivity) {
    let offlineCustomerInquiries;

    try {
      offlineCustomerInquiries = await this.retrieve('offlineCustomerInquiries', true);
    }
    catch (diskError) {
      console.log("error fetching offline customer inquiry");
    }

    if (!offlineCustomerInquiries || offlineCustomerInquiries == undefined) {
      console.log("Wooaaahh!!! This should never happen, something gone wrong");
      return data;
    }
    else {
      /* Got hold of the offline DB collection for cases */

      if (Array.isArray(offlineCustomerInquiries['myCases'])) {
        let idx = offlineCustomerInquiries.myCases.findIndex(e => e.offlineCaseId === data.offline_ID);
        if (idx > -1) {
          offlineCustomerInquiries.myCases.splice(idx, 1);
        }
        await this.updateOrInsert('offlineCustomerInquiries', doc => offlineCustomerInquiries)
          .then(() => this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CUSTOMER_INQUIRY, offlineCustomerInquiries.myCases.length))
          .catch(error => console.error('removeCaseFromOfflinePayload: ', error));
      }

      return data;
    }
  }

  public async createOfflineCoachingReport(report) {
    await this.updateOrInsert('offlineCoachingReports', (document) => {
      if (!document || !document.coachingReports) {
        document = {
          coachingReports: [],
          count: 0
        };
        document.coachingReports = [report];
        document.count = 1;
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.COACHING_REPORT, document.coachingReports.length);
        return document;
      } else {
        document.count++;
        document.coachingReports.push(report);
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.COACHING_REPORT, document.coachingReports.length);
        return document;
      }
    })
      .catch(error => this.logService.logError("Failed saving offline coaching report", error));
  }

  /*************Edit business information in offline - Non-OneKey contacts*************/
  public async createOfflineContact(contact) {
    await this.updateOrInsert('offlineContacts', (document) => {
      if (!document || !document.contacts) {
        document = {
          contacts: [],
          count: 0
        };
        document.contacts = [contact];
        document.count = 1;
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT, document.contacts.length);
        return document;
      } else {
        document.count++;
        document.contacts.push(contact);
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT, document.contacts.length);
        return document;
      }
    })
      .catch(error => this.logService.logError("Failed saving offline contact", error));
  }

  public async loadOfflineContacts(): Promise<any> {
    try {
      let offlineContacts = await this.retrieve('offlineContacts', true);

      offlineContacts && _.isArray(offlineContacts.contacts) && !_.isEmpty(offlineContacts)
        ? this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT, offlineContacts.contacts.length)
          : this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT, 0);

      return offlineContacts;
    } catch (diskError) {
      //console.log('Disk error caught', diskError);
      this.globalErrorHandler.handleError(new Error(diskError));
    }
  }

  public async createOfflineLinkedEntity(linkedEntity) {
    linkedEntity.values['entityName'] = linkedEntity.entity;
    linkedEntity.values['addedNewDataId'] = linkedEntity.addedNewDataId;

    await this.updateOrInsert('offlineLinkedEntities', (document) => {
      if (!document || !document.linkedEntities) {
        document = {
          linkedEntities: [],
          count: 0
        };
        document.linkedEntities = [linkedEntity.values];
        document.count = 1;
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.LINKED_ENTITIES, document.linkedEntities.length);
        return document;
      } else {
        document.count++;
        document.linkedEntities.push(linkedEntity.values);
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.LINKED_ENTITIES, document.linkedEntities.length);
        return document;
      }
    })
      .catch(error => this.logService.logError("Failed saving offline linked entity", error));
  }

  public async loadOfflineLinkedEntities(): Promise<any> {
    try {
      let offlineLinkedEntities = await this.retrieve('offlineLinkedEntities', true);
      offlineLinkedEntities && Array.isArray(offlineLinkedEntities.linkedEntities) && offlineLinkedEntities.linkedEntities.length > 0
        ? this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.LINKED_ENTITIES, offlineLinkedEntities.linkedEntities.length)
          : this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.LINKED_ENTITIES, 0);
      return offlineLinkedEntities;
    } catch (diskError) {
      //console.log('Disk error caught', diskError);
      this.globalErrorHandler.handleError(new Error(diskError));
    }
  }
  /*************Edit business information in offline - Non-OneKey contacts*************/

  /*************Edit business Change Request in offline*************/
  public async createOfflineBusinessCR(contactcr) {
    await this.updateOrInsert('offlineBusinessCRs', (document) => {
      if (!document || !document.businessCRs) {
        document = {
          businessCRs: [],
          count: 0
        };
        document.businessCRs = [contactcr];
        document.count = 1;
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.BUSINESS_CHANGE_REQUEST, document.businessCRs.length);
        return document;
      } else {
        document.count++;
        document.businessCRs.push(contactcr);
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.BUSINESS_CHANGE_REQUEST, document.businessCRs.length);
        return document;
      }
    })
      .catch(error => this.logService.logError("Failed saving offline business change request", error));
  }

  public async loadOfflineBusinessCRs(): Promise<any> {
    try {
      let offlineBusinessCRs = await this.retrieve('offlineBusinessCRs', true);
      offlineBusinessCRs && Array.isArray(offlineBusinessCRs.businessCRs) && offlineBusinessCRs.businessCRs.length > 0
        ? this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.BUSINESS_CHANGE_REQUEST, offlineBusinessCRs.businessCRs.length)
          : this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.BUSINESS_CHANGE_REQUEST, 0);
      return offlineBusinessCRs;
    } catch (diskError) {
      //console.log('Disk error caught', diskError);
      this.globalErrorHandler.handleError(new Error(diskError));
    }
  }

  public async createOfflineBusinessCRWithEmailCR(contactcr) {
    await this.updateOrInsert('offlineBusinessCRs', (document) => {
      if (!document || !document.businessCRs) {
        document = {
          businessCRs: [],
          count: 0
        };
        document.businessCRs = [contactcr];
        document.count = 1;
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.BUSINESS_CHANGE_REQUEST, document.businessCRs.length);
        return document;
      } else {
        const crId = contactcr['addedNewOneKeyCRId'] || '';
        const idx = document.businessCRs?.findIndex(cr => cr['addedNewOneKeyCRId'] == crId);
        if (idx > -1) {
          document.businessCRs[idx] = contactcr;
        } else {
          document.count++;
          document.businessCRs.push(contactcr);
        }
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.BUSINESS_CHANGE_REQUEST, document.businessCRs.length);
        return document;
      }
    })
      .catch(error => this.logService.logError("Failed saving offline business change request", error));
  }
  /*************Edit business Change Request in offline*************/

  /*************Create OneKey Change Request in offline*************/
  public async createOneKeyCR(contactcr) {
    await this.updateOrInsert('offlineOneKeyCRs', (document) => {
      if (!document || !document.oneKeyCRs) {
        document = {
          oneKeyCRs: [],
          count: 0
        };
        document.oneKeyCRs = [contactcr];
        document.count = 1;
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ONE_KEY_CHANGE_REQUEST, document.oneKeyCRs.length);
        return document;
      } else {
        document.count++;
        document.oneKeyCRs.push(contactcr);
        this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ONE_KEY_CHANGE_REQUEST, document.oneKeyCRs.length);
        return document;
      }
    })
      .catch(error => this.logService.logError("Failed saving offline one key change request", error));
  }

  public async loadOfflineOneKeyCRs(): Promise<any> {
    try {
      let offlineOneKeyCRs = await this.retrieve('offlineOneKeyCRs', true);
      offlineOneKeyCRs && Array.isArray(offlineOneKeyCRs.oneKeyCRs) && offlineOneKeyCRs.oneKeyCRs.length > 0
        ? this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ONE_KEY_CHANGE_REQUEST, offlineOneKeyCRs.oneKeyCRs.length)
          : this.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ONE_KEY_CHANGE_REQUEST, 0);
      return offlineOneKeyCRs;
    } catch (diskError) {
      //console.log('Disk error caught', diskError);
      this.globalErrorHandler.handleError(new Error(diskError));
    }
  }
  /*************Create OneKey Change Request in offline*************/


  /**
   * Encrypt an object
   * @param rawObj
   * @param mask
   */
  private async _encryptData(rawObj: any, mask: any) {
    if (mask.length === 0) {
      // If no mask, consider as don't encrypt.
      return rawObj;
    } else if (!mask.includes('_id')) {
      // If there's a mask and '_id' is not included, it is invalid mask.
      console.error(`_encryptData: mask does not include '_id' field. It is required.`, mask);
      return undefined;
    } else {
      try {
        const fieldsToBeEncrypted = _.omit(rawObj, mask);
        //const encrypted = this.device.isHybridApp ? this._cryptoJsEnc(fieldsToBeEncrypted) : this._subtleEnc(fieldsToBeEncrypted);
        const data = await this._subtleEnc(fieldsToBeEncrypted);
        const encryptedDataToBeSaved: any = { data };

        for (let i = 0; i < mask.length; i++) {
          const key = mask[i];
          encryptedDataToBeSaved[key] = rawObj[key];
        }

        return encryptedDataToBeSaved;
      } catch (error) {
        console.error('_encryptData: encrypting: ', error);
        return undefined;
      }
    }
  }
  /**
   * Decrypt an encrypted object
   * @param encryptedData
   * @param mask
   */
  private async _decryptData(encryptedData: any, mask: any) {
    if (!encryptedData.hasOwnProperty('data')) {
      // Probably not encrypted or wrong data. Just ignore it.
      return encryptedData;
    } else if (!mask.includes('_id') || !mask.includes('_rev')) {
      // If encrypted,
      console.error(`_decryptData: mask does not include '_id' or '_rev' field..`, mask);
      return undefined;
    } else {
      try {
        const decryptedObj = await this._subtleDec(encryptedData.data);
        for (let i = 0; i < mask.length; i++) {
          const key = mask[i];
          if (encryptedData[key] !== undefined) {
            decryptedObj[key] = encryptedData[key];
          }
        }

        return decryptedObj;
      } catch (error) {
        console.error(error);
        return undefined;
      }
    }
  }

  /**
   * Encryption using CryptoJs library (later use for fallback)
   * @param objectToBeEncrypted
   */
  private _cryptoJsEnc(objectToBeEncrypted: any) {
    return CryptoJS.AES.encrypt(JSON.stringify(objectToBeEncrypted), this._dbEncryptionKey).toString();
  }
  /**
   * Encryption using WebCrypto
   * @param objectToBeEncrypted
   */
  private async _subtleEnc(objectToBeEncrypted: any) {
    const message = JSON.stringify(objectToBeEncrypted);
    const enc = new TextEncoder();
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const buf = await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv
      },
      this._cryptoKey,
      enc.encode(message)
    );
    return { iv: this.ab2str(iv), data: this.ab2str(buf) };
  }

  /**
   * Decryption using CryptoJs library (later use for fallback)
   * @param encryptedData
   */
  private async _cryptoJsDec(encryptedData: any) {
    return JSON.parse((CryptoJS.AES.decrypt(encryptedData, this._dbEncryptionKey)).toString(CryptoJS.enc.Utf8));
  }
  /**
   * Decryption using WebCrypto
   * @param encryptedData
   */
  private async _subtleDec(encryptedData: { iv: Uint8Array, data: Uint8Array }) {
    const ivAB = this.str2ab(encryptedData.iv);
    const uint8array = this.str2ab(encryptedData.data);

    const alg = { name: 'AES-GCM', iv: ivAB };
    const ptBuffer = await window.crypto.subtle.decrypt(alg, this._cryptoKey, uint8array);
    const plainTxt = new TextDecoder().decode(ptBuffer);
    return JSON.parse(plainTxt);
  }

  /**
   * Convert Array buffer to string
   * @param buf
   */
  ab2str(buf) {
    return encode(buf);
  }
  /**
   * Convert string to Array buffer
   * @param str
   */
  str2ab(str) {
    return decode(str);
  }


  /**
   * Offline data count for quick offline data size tracking
   */
  initCountHashmap() {
    this._offlineDataCountHashMap = new Map();
    for (const key in OFFLINE_DATA_COUNT_ENTITY_NAME) {
      if (OFFLINE_DATA_COUNT_ENTITY_NAME.hasOwnProperty(key)) {
        const element = OFFLINE_DATA_COUNT_ENTITY_NAME[key];
        this._offlineDataCountHashMap.set(element, 0);
      }
    }
  }

  getOfflineDataCount(key: string): number {
    if (!this._offlineDataCountHashMap.has(key)) {
      console.error('DiskService: getOfflineDataCount: Not a valid key: ', key);
      return -1;
    }
    const count = this._offlineDataCountHashMap.get(key);
    if (isNaN(count) || count < 0) {
      console.error('DiskService: getOfflineDataCount: Not a valid count: ', key);
      return -1;
    }
    return count;
  }
  setOfflineDataCount(key: string, count: number) {
    if (!this._offlineDataCountHashMap.has(key)) {
      console.error('DiskService: setOfflineDataCount: Not a valid key: ', key);
      return;
    }
    if (count < 0) {
      console.error('DiskService: setOfflineDataCount: Cannot set negative count: ', count);
      return;
    }
    this._offlineDataCountHashMap.set(key, count);
  }
  addOfflineDataCount(key: string, addCount: number): number {
    if (!this._offlineDataCountHashMap.has(key)) {
      console.error('DiskService: addOfflineDataCount: Not a valid key: ', key);
      return -1;
    }
    if (addCount < 0) {
      console.error('DiskService: addOfflineDataCount: Cannot set negative count: ', addCount);
      return -1;
    }
    let count = this._offlineDataCountHashMap.get(key);
    if (isNaN(count) || count < 0) {
      console.error('DiskService: addOfflineDataCount: Not a valid count: ', key);
      count = 0;
    }

    const newCount = count + addCount;
    this._offlineDataCountHashMap.set(key, newCount);
    return newCount;
  }

  subtractOfflineDataCount(key: string, subCount: number): number {
    if (!this._offlineDataCountHashMap.has(key)) {
      console.error('DiskService: subtracktOfflineDataCount: Not a valid key: ', key);
      return -1;
    }
    if (subCount < 0) {
      console.error('DiskService: subtracktOfflineDataCount: Cannot set negative count: ', subCount);
      return -1;
    }
    let count = this._offlineDataCountHashMap.get(key);
    if (isNaN(count) || count < 0) {
      console.error('DiskService: subtracktOfflineDataCount: Count is already zero: ', key);
      return -1;
    }

    const newCount = count - subCount;
    if (newCount < 0) {
      console.error(`DiskService: subtracktOfflineDataCount: Cannot go negative count: Total: ${count}  Sub: ${subCount}`);
      return -1;
    }

    this._offlineDataCountHashMap.set(key, newCount);
    return newCount;
  }

  isThereOfflineDataToBeUploaded(): boolean {
    let count = 0;
    this._offlineDataCountHashMap.forEach(dataCount => {
      if (!isNaN(dataCount)) {
        count += dataCount;
      }
    });

    //console.log('---- offline data count: ', count);
    return count > 0 ? true : false;
  }

  public async saveOrUpdatePresToDB(raw: any[]) {
    if (raw && raw.length > 0) {
      try {
        await this.updateOrInsert(DB_KEY_PREFIXES.PRESENTATION, (doc) => {
          if (!doc || !doc.raw) {
            doc = {
              raw: []
            };
          }
          doc.raw = raw;
          return doc;
        });
      }
      catch (error) {
        console.error('saveOrUpdatePresToDB: ', error);
      }
    }
  }

  public async saveOrUpdateResourcesToDB(rawResources: any[]) {
    if (rawResources.length > 0) {
      try {
        await this.updateOrInsert(DB_KEY_PREFIXES.RESOURCE, (doc) => {
          if (!doc || !doc.raw) {
            doc = {
              raw: []
            };
          }
          doc.raw = rawResources;
          return doc;
        });
      }
      catch (error) {
        console.error('saveOrUpdateResourcesToDB: ', error);
      }
    }
  }

  public async saveAccountsToDB(rawAccounts: any[]) {
    try {
      await this.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT, (doc) => {
        if (!doc || !doc.raw) {
          doc = {
            raw: []
          };
        }
        doc.raw = rawAccounts;
        return doc;
      });
    }
    catch (error) {
      console.error('saveAccountsToDB: ', error);
    }
  }

  public async saveContactsToDB(rawContacts: any[], key) {
    try {
      await this.updateOrInsert(key, (doc) => {
        if (!doc || !doc.raw) {
          doc = {
            raw: []
          };
        }
        doc.raw = rawContacts;
        return doc;
      });
    }
    catch (error) {
      console.error('saveContactsToDB: ', error);
      // TODO: handle error..
    }
  }

  /**
 * Method: saveDataToDynamics
 * 
 * Purpose:
 * This method saves a collection of bucketed contacts to Dynamics by transforming the provided
 * data into an array of documents and performing a bulk insertion into the disk storage.
 * 
 * Parameters:
 * - bucketedContacts: A record object where each key corresponds to a document ID and each value
 *   contains the data associated with that ID. This data is expected to be saved in Dynamics.
 * 
 * Functionality:
 * 1. The method first checks if `bucketedContacts` is non-empty. If the object is empty, the method
 *    exits early, avoiding unnecessary operations.
 * 2. If the object contains data, it is transformed into an array of documents. Each document consists
 *    of an `_id` (the key from the original object) and a `raw` property (the associated value).
 * 3. The transformed array is then saved to the disk in bulk, leveraging the bulk method for efficiency.
 * 
 * Usage:
 * This method is useful when there is a need to persist multiple contacts or data entries to Dynamics
 * in a single operation, ensuring that the data is saved efficiently and consistently.
 * 
 * Notes:
 * - The method assumes that the bulk method on `this` is correctly implemented to handle the insertion
 *   of multiple documents in one go.
 * - Proper error handling should be considered in the context where this method is used to handle potential
 *   failures during the bulk save operation.
 */

   async saveDataToDynamics(bucketedContacts: Record<string, any>) {
    // Check if bucketedContacts is non-empty before proceeding.
    if (bucketedContacts && Object.keys(bucketedContacts).length) {
        // Map the bucketedContacts into an array of documents for bulk insertion.
        const docs = Object.entries(bucketedContacts).map(([key, value]) => ({
            _id: key,
            raw: value
        }));

        // Save the documents in bulk to the disk.
        await this.bulk(docs);
    }
}

  public async upsertMarketScan(data: MarketScan) {

    let key = DB_KEY_PREFIXES.MARKET_SCANS + data.indskr_externalid;

    let doc = await this.retrieve(key, true);

    data._id = key;

    if (doc) {
      return await this.updateOrInsert(key, () => {
        return data;
      }).catch(e => console.log(e));
    }
    else {
      return await this.updateOrInsert(key, doc => data)
        .catch(error => console.error('failed inserting market scan: ', error));
    }
  }

  public async upsertUserTagListToDisk(contactTagDetails: UserTagForContact[],forEntity?:TagEntityType) {
    contactTagDetails.forEach(tag =>{
      this.upsertUserTag(tag,forEntity);
    });
}

  public async upsertUserTag(tag: UserTagForContact|UserTag,forEntity?:TagEntityType) {
    // console.log("adding tag to disk",tag);

      let key;
      if(forEntity){
        switch(forEntity){
          case TagEntityType.ACCOUNT:
            key = DB_KEY_PREFIXES.USER_TAG + tag.indskr_externalid;
            break;
          case TagEntityType.CONTACT:
            key = DB_KEY_PREFIXES.CONTACT_TAG + tag.indskr_externalid;
            break;
          case TagEntityType.PRESENTATION:
            key = DB_KEY_PREFIXES.USER_TAG + tag.indskr_externalid;
          break;
          case TagEntityType.RESOURCE:
            key = DB_KEY_PREFIXES.USER_TAG + tag.indskr_externalid;
          break;
          default:
            key = DB_KEY_PREFIXES.USER_TAG + tag.indskr_externalid;
        }
      }else{
        key = DB_KEY_PREFIXES.USER_TAG + tag.indskr_externalid;
      }

      const doc = await this.retrieve(key, true);
      if (doc) {
        return await this.updateOrInsert(key, () => {
          return tag;
        }).catch(e => console.log(e));
      }
      else {
        return await this.updateOrInsert(key, doc => tag)
          .catch(error => console.error('failed inserting '+forEntity+' tag: ', error));
      }

  }

  public async upsertContactCRs(data: any) {

    let key = DB_KEY_PREFIXES.CONTACT_CR + data.indskr_contactcrid;

    let doc = await this.retrieve(key, true);

    data._id = key;

    if (doc) {
      return await this.updateOrInsert(key, () => {
        return data;
      }).catch(e => console.log(e));
    }
    else {
      return await this.updateOrInsert(key, doc => data)
        .catch(error => console.error('failed inserting contact CR: ', error));
    }
  }

  public async upsertAccountCRs(data: any) {

    let key = DB_KEY_PREFIXES.ACCOUNT_CR + data.indskr_accountcrid;

    let doc = await this.retrieve(key, true);

    data._id = key;

    if (doc) {
      return await this.updateOrInsert(key, () => {
        return data;
      }).catch(e => console.log(e));
    }
    else {
      return await this.updateOrInsert(key, doc => data)
        .catch(error => console.error('failed inserting account CR: ', error));
    }
  }


  /** ----------------------------------------------------------------------------------------
   *  Feature revision store
   */
  private async initFeatureRevisionStore() {
    let doc: IPouchDBDocument<IFeatureRevisionStore> = await this.retrieve(DB_KEY_PREFIXES.FEATURE_REVISION_STORE, true, true);
    if (!doc || doc.raw === null || typeof doc.raw !== 'object') {
      doc = {
        raw: {}
      };

      const response: IPouchDBResponse = await this.updateOrInsert(DB_KEY_PREFIXES.FEATURE_REVISION_STORE, () => doc, true);

      if (response && response.rev) {
        doc._rev = response.rev;
      } else {
        console.warn(`initFeatureRevisionStore: Init couldn't set _rev: `, response);
      }
    }

    this._featureRevStoreDoc = doc;
  }
  getFeatureRevision(featureKey: string): number | null {
    let rev: number;

    if (this._featureRevStoreDoc?.raw?.hasOwnProperty(featureKey)) {
      rev = this._featureRevStoreDoc.raw[featureKey];
    }

    return !isNaN(rev) && rev >= 0 ? rev : null;
  }
  async setFeatureRevision(featureKey: string, rev: number, setOnlyIfHigher: boolean = false): Promise<boolean> {
    let isSet: boolean = true;

    if (!featureKey) {
      isSet = false;
      console.error('setFeatureRevision: featureKey not provided: ', featureKey, rev);
    }
    if (isNaN(rev) || rev < 0) {
      isSet = false;
      console.error('setFeatureRevision: invalid rev value: ', featureKey, rev);
    }

    if (isSet) {
      try {
        const prevRev = this._featureRevStoreDoc.raw[featureKey];
        this._featureRevStoreDoc.raw[featureKey] = rev;

        if (!isNaN(prevRev) && prevRev > rev) {
          if (setOnlyIfHigher) {
            isSet = false;
            this._featureRevStoreDoc.raw[featureKey] = prevRev;
            console.error(`setFeatureRevision: prevRev(${prevRev}) is higher than ${rev}`);
          } else {
            console.warn(`setFeatureRevision: prevRev(${prevRev}) is higher than ${rev}`);
          }
        }
        if (isSet) {
          const response: IPouchDBResponse = this._featureRevStoreDoc._rev && this._featureRevStoreDoc._id
          ? await this.updateDocWithIdAndRev(this._featureRevStoreDoc, true, true)
          : this._featureRevStoreDoc.raw && typeof this._featureRevStoreDoc === 'object'
            ? await this.updateOrInsert(DB_KEY_PREFIXES.FEATURE_REVISION_STORE, () => ({ raw: this._featureRevStoreDoc.raw }), true)
            : undefined;

          if (response !== undefined && response.rev) {
            this._featureRevStoreDoc._rev = response.rev;
          }
        }
      } catch (error) {
        isSet = false;
        console.error('setFeatureRevision: ', error);
      }
    }

    return isSet;
  }

  //presentation

  public async upsertUserTagPresentation(tag: UserTag,forEntity?:TagEntityType) {
    console.log("adding tag to disk",tag);

      let key;
      if(forEntity){
        switch(forEntity){
          case TagEntityType.ACCOUNT:
            key = DB_KEY_PREFIXES.USER_TAG + tag.indskr_externalid;
            break;
          case TagEntityType.PRESENTATION:
            key = DB_KEY_PREFIXES.USER_TAG + tag.indskr_externalid;
            break;
          case TagEntityType.RESOURCE:
            key = DB_KEY_PREFIXES.RESOURCE + tag.indskr_externalid;
            break;
          default:
            key = DB_KEY_PREFIXES.USER_TAG + tag.indskr_externalid;
        }
      }else{
        key = DB_KEY_PREFIXES.USER_TAG + tag.indskr_externalid;
      }

      const doc = await this.retrieve(key, true);
      if (doc) {
        return await this.updateOrInsert(key, () => {
          return tag;
        }).catch(e => console.log(e));
      }
      else {
        return await this.updateOrInsert(key, doc => tag)
          .catch(error => console.error('failed inserting '+forEntity+' tag: ', error));
      }

  }
}
