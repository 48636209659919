<ion-grid no-padding class="content-page" [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed)?'preview-active':'preview-inactive'">
    <ion-row no-padding>
        <ion-col class="account-left-pane" no-padding>
            <account-list [accountListAccessedFrom]="'AccountPage'" [accountListMode]="accountListMode"
            [callbackEvent]="callbackEvent" [selectedAccountId]="selectedAccountId" [accountSearchText]="accountSearchText" [activateSearchOtherAccounts]="activateSearchOtherAccounts"
            [selectedAccountIds]="selectedAccountIds" [accountIdfromIndDynamic]="accountIdfromIndDynamic" [selectedContactIds]="selectedContactIds"
            [paramAccount]="paramAccount" [hidesearchSuggestionsDropDown]="hidesearchSuggestionsDropDown" (closeModal)="closeAccountModal()">
            </account-list>
        </ion-col>
        <ion-col class="account-right-pane" no-padding>
            <ion-nav #accountpagerightpane></ion-nav>
        </ion-col>
    </ion-row>
</ion-grid>
