import { Component, OnInit, ViewChild } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { addMinutes, format } from 'date-fns';
import { ActivityType } from '../../../classes/activity/activity.class';
import { OrderActivity } from '../../../classes/activity/order.activity.class';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { ActivityDataService } from '../../../data-services/activity/activity.service';
import { OrderActivityDataService } from '../../../data-services/order-activity/order-activity.data.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { ActivityService } from '../../../services/activity/activity.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { DeviceService } from '../../../services/device/device.service';
import { EventsService } from '../../../services/events/events.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { ChildNavNames, NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { OrderManagementService } from '../../../services/order-management/order-management.service';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { Utility } from '../../../utility/util';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { MultiSelectPopover } from '../../multi-select-popover/multi-select-popover';
import { OrderDetailsComponent } from '../order-details/order-details';
import { GlobalUtilityService } from './../../../services/global-utility.service';
import _ from 'lodash';
import {IndHeaderLeftDataModel} from "../../../models/indHeaderLeftDataModel";
import { DatePipe } from '@angular/common';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IndKeyControlsAreaModel } from '@omni/models/indKeyControlsAreaModel';
import { OrderSelectionViewDataModel } from '@omni/models/order-selection-page-models';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { QuotesService } from '@omni/services/quote/quote-tool.service';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

/**
 * Generated class for the OrdersListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'orders-list',
  templateUrl: 'orders-list.html',
  styleUrls:['orders-list.scss']
})
export class OrdersListComponent implements OnInit {

  private readonly allOrdersList: Array<OrderActivity> = [];
  public slicedFilteredOrdersList: Array<OrderActivity> = [];
  public _slicedFilteredOrdersList: Array<MainCardViewDataModel> = [];
  //public filteredOrdersList: Array<OrderActivity> = [];
  public isTeamOrdersViewEnabled = false;
  public isNewOrderCreationEnabled = false;
  private recordCount = 40;
  //private isTeamTabClickedFirstTime = true;

  @ViewChild('Content', {static:true}) content: HTMLIonContentElement;
  filterPopoverData: { text: string; value: string; items: any; handler(selectedItem: any, item: any, itemRef: any): void; }[];
  filterPopoverDataTeamOrders: { text: string; value: string; items: any; handler(selectedItem: any, item: any, itemRef: any): void; }[];
  public sortBy: any = { text: this.translate.instant('DATE'), value: 'createdDate' };
  public teamSortBy: any = { text: this.translate.instant('DATE'), value: 'createdDate' };
  public ordersFilter: { statusString: string, products: string, searchText: string } = { statusString: '', products: '', searchText: '' };
  public ordersFilterTeamOrders: { statusString: string, products: string, searchText: string } = { statusString: '', products: '', searchText: '' };
  myProductFilterList: {
    text: string,
    value: string
  }[];
  teamProductFilterList: {
    text: string,
    value: string
  }[];
  orderSearchInput ='';
  filterValueSelected = false;
  sortPopoverData: { text: string; value: string; items: { text: string; value: string}[]; handler(selectedItem: any, item: any, itemRef: any): void; }[];
  isTeamTabActive = false;
  teamSortPopoverData: { text: string; value: string; items: { text: string; value: string}[]; handler(selectedItem: any, item: any, itemRef: any): void; }[];
  NoOrderMessage = 'OM_NO_ORDERS';
  orderListCount = 0;
  tabsData: IndTabsDataModel[];
  indHeaderLeftModel: IndHeaderLeftDataModel;
  filterBadgeCount: number = 0;
  filterText = this.getFilterText();
  private ngDestroy$ = new Subject<boolean>();

  public from: PageName;
  public viewData: OrderSelectionViewDataModel;
  public orderKeyControlAreaModel: IndKeyControlsAreaModel;
  private _isDirty: boolean = false;
  public isReadOnlyMode: boolean = false;
  public selectedItems: Array<MainCardViewDataModel> = [];
  public selectedHeader: IndSectionHeaderViewDataModel;

  constructor(
    public navService: NavigationService,
    private readonly authService: AuthenticationService,
    public uiService: UIService,
    public device: DeviceService,
    public trackingService: TrackService,
    public orderManagementService: OrderManagementService,
    private readonly orderActivityDataService: OrderActivityDataService,
    private readonly events: EventsService,
    private readonly activityService: ActivityService,
    public repService: RepServices,
    public popover: PopoverController,
    private readonly activityDataService: ActivityDataService,
    public translate: TranslateService,
    public globalUtil: GlobalUtilityService,
    private readonly datePipe: DatePipe,
    public footerService: FooterService,
    private navParams: NavParams,
    private quoteOfflineService : QuotesService,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
  ) {
    
    if (this.navParams.data && this.navParams.data.viewData) {
      this.viewData = this.navParams.data.viewData;
      this.from = this.navParams.data.from;
      this.isReadOnlyMode = this.viewData.isReadOnlyMode;
      this._initSelectedViewItems();
    }
    this.initOrdersHeaderLeft();
  }

  public filteredOrdersList(): Array<OrderActivity> {
    let list = this.activityService.activities.filter(order => order instanceof OrderActivity && order.type === ActivityType.Order && !order.isHardDeleted) as Array<OrderActivity>;
    if(this.from === PageName.QuoteDetailsComponent){
      list = this.setSalesOrders(list);
    }
    let teamOrderList = this.orderManagementService.teamOrder;
    if (teamOrderList && teamOrderList.length > 0) {
      list.push(...teamOrderList);
    }
    return list.sort((a, b) => (a.createdDate < b.createdDate) ? 1 : -1);
  }

  private setSalesOrders(list) : OrderActivity[] {
    const currentQuote = this.quoteOfflineService.selectedQuotes$.getValue()
    if (!_.isEmpty(currentQuote.accountId)) {
      list = list.filter((salesOrder) => salesOrder.accountId === currentQuote.accountId);
    } 
    return list;
  }

  ngOnInit() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT_TEAM_VIEW)) {
      this.isTeamOrdersViewEnabled = true;
      //this.getTeamOrderData();
    }
    this.setTabsData();
    if (this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT)) {
      this.isNewOrderCreationEnabled = true;
    }
    this.myProductFilterList = [];
    this.filteredOrdersList().forEach(ord => {
      if (ord.products && ord.products.length && ord.ownerId === this.authService.user.systemUserID) {
        ord.products.forEach(prod => {
          if (!this.myProductFilterList.some(o => o.value === prod.productId)) this.myProductFilterList.push({ text: prod.productName, value: prod.productId });
        });
      }
    });

    // this.teamProductFilterList = [];
    // this.filteredOrdersList().forEach(ord => {
    //   if (ord.products && ord.products.length && ord.ownerId !== this.authService.user.systemUserID) {
    //     ord.products.forEach(prod => {
    //       if (!this.teamProductFilterList.some(o => o.value === prod.productId)) this.teamProductFilterList.push({ text: prod.productName, value: prod.productId });
    //     });
    //   }
    // });
    this.slicedFilteredOrdersList = this.sliceOrders(0, this.recordCount);

    //this.teamProductFilterList.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1);
    this.filterPopoverData = [
      {
        text: '',
        value: 'All',
        items: [{ text: this.translate.instant('ALL_ORDERS_S'), value: 'All' }],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          this.ordersFilter = {...this.ordersFilter,  statusString: '', products: ''};
          this.updateOrdersToDisplay();
        }
      },
      {
        text: this.translate.instant('STATUS'),
        value: '',
        items: [
          { text: this.translate.instant('OM_DRAFT'), value: 'Draft' },
          { text: this.translate.instant('FOR_REVIEW'), value: 'For Review' },
          { text: this.translate.instant('APPROVED'), value: 'Approved' },
          { text: this.translate.instant('OM_FULFILLED'), value: 'Fulfilled' },
          { text: this.translate.instant('CANCELLED'), value: 'Canceled' }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          this.ordersFilter = {...this.ordersFilter,  statusString: item.value};
          if (this.ordersFilter.statusString === '' && this.ordersFilter.products === '') {
            itemRef.parent.items[0].value = 'All';
          }
          this.updateOrdersToDisplay();
        }
      },
      {
        text: this.translate.instant('PRODUCT'),
        value: '',
        items: this.myProductFilterList,
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          this.ordersFilter.products = item.value;
          this.ordersFilter = {...this.ordersFilter,  products: item.value};
          if (this.ordersFilter.statusString === '' && this.ordersFilter.products === '') {
            itemRef.parent.items[0].value = 'All';
          }
          this.updateOrdersToDisplay();
        }
      }
    ];
    // this.filterPopoverDataTeamOrders = [
    //   {
    //     text: '',
    //     value: 'All',
    //     items: [{ text: this.translate.instant('ALL_ORDERS_S'), value: 'All' }],
    //     handler: (selectedItem, item, itemRef) => {
    //       itemRef.parent.items.map((o) => {
    //         o.value = '';
    //       });
    //       item.value = selectedItem.value;
    //       this.ordersFilterTeamOrders = {...this.ordersFilterTeamOrders,  statusString: '', products: ''};
    //       this.updateOrdersToDisplay();
    //     }
    //   },
    //   {
    //     text: this.translate.instant('STATUS'),
    //     value: '',
    //     items: [
    //       { text: this.translate.instant('FOR_REVIEW'), value: 'For Review' },
    //       { text: this.translate.instant('APPROVED'), value: 'Approved' },
    //       { text: this.translate.instant('OM_FULFILLED'), value: 'Fulfilled' },
    //       { text: this.translate.instant('CANCELLED'), value: 'Canceled' }
    //     ],
    //     handler: (selectedItem, item, itemRef) => {
    //       itemRef.parent.items[0].value = '';
    //       item.value = item.value === selectedItem.value ? '' : selectedItem.value;
    //       this.ordersFilterTeamOrders = {...this.ordersFilterTeamOrders,  statusString: item.value};
    //       if (this.ordersFilterTeamOrders.statusString === '' && this.ordersFilterTeamOrders.products === '') {
    //         itemRef.parent.items[0].value = 'All';
    //       }
    //       this.updateOrdersToDisplay();
    //     }
    //   },
    //   {
    //     text: this.translate.instant('PRODUCT'),
    //     value: '',
    //     items: this.teamProductFilterList,
    //     handler: (selectedItem, item, itemRef) => {
    //       itemRef.parent.items[0].value = '';
    //       item.value = item.value === selectedItem.value ? '' : selectedItem.value;
    //       this.ordersFilterTeamOrders.products = item.value;
    //       this.ordersFilterTeamOrders = {...this.ordersFilterTeamOrders,  products: item.value};
    //       if (this.ordersFilterTeamOrders.statusString === '' && this.ordersFilterTeamOrders.products === '') {
    //         itemRef.parent.items[0].value = 'All';
    //       }
    //       this.updateOrdersToDisplay();
    //     }
    //   }
    // ];
    this.sortPopoverData=[
        {
          text: '',
          value: 'createdDate',
          items: [
                  { text:this.translate.instant('DATE'), value: 'createdDate'},
                  { text:this.translate.instant('ACCOUNT'), value: 'accountNameString'},
                ],
          handler: (selectedItem, item, itemRef) => {
            item.value = selectedItem.value;
            this.sortBy = selectedItem;
            this.updateOrdersToDisplay();
          }
        }
    ];
    // this.teamSortPopoverData = [
    //   {
    //     text: '',
    //     value: 'createdDate',
    //     items: [
    //       { text: this.translate.instant('DATE'), value: 'createdDate'},
    //       { text: this.translate.instant('OM_OWNER'), value: 'ownerNameString'}
    //     ],
    //     handler: (selectedItem, item, itemRef) => {
    //       item.value = selectedItem.value;
    //       this.teamSortBy = selectedItem;
    //       this.updateOrdersToDisplay();
    //     }
    //   }
    // ];
    this.content.scrollToTop(0);
    this.updateOrdersToDisplay();
    this.events.subscribe('updateOrdersList', () => this.updateOrdersToDisplay());
    this.updateEmptyMessage();

    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      if(this.isTeamTabActive){
        this.uiService.dismissLoader();
      }
      this.setTabsData();
    });
  }

  // selction pane
  private _initSelectedViewItems() {
    const list = this.activityService.activities.filter(order => order instanceof OrderActivity && order.type === ActivityType.Order && !order.isHardDeleted) as Array<OrderActivity>;
    if (this.viewData.selectedOrders) {
      this.viewData.selectedOrders = _.orderBy(this.viewData.selectedOrders, 'createdDate');
      let selectedIds = this.viewData.selectedOrders.map(mbp => mbp.ID);
      selectedIds.forEach((selectedId) => {
        const index = list.findIndex(event => event.ID === selectedId);
        const foundItem = index > -1 ? list[index] : this.viewData.selectedOrders.find(event => event.ID === selectedId);
        if (foundItem) {
          this.selectedItems.push({
            id: foundItem.ID,
            primaryTextLeft: this.getFormattedDate(foundItem.createdDate),
            primaryTextRight: this.isTeamTabActive ? foundItem.ownerNameString : (foundItem.orderNumber ? this.translate.instant('NEW_ACTIVITY_SALES_ORDER') + ' - ' + foundItem.orderNumber : this.translate.instant('OM_NEW_ORDER')),
            secondaryTextRight: !this.isTeamTabActive ? foundItem.accountNameString ? foundItem.accountNameString : this.translate.instant('NO_ACCOUNT') : foundItem.orderNumber,
            secondaryTextRightTwo: this.getStrStatus(foundItem.orderStatusString),
            isSelected: foundItem.ID === this.orderManagementService.selectedOrder?.ID,
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: true,
            endIconType: 'indegene-selectors-remove-icon',
            endIconCssClass: 'remove-icon',
            showArrow: false,
            arrowType: '',
            clickHandler: async (id: string, event, eventName, refData) => this.handleCardItemClick(id, event, eventName, refData)
          })
        }
      });
      this._initSelectedHeader();
    }
  }

  private _initSelectedHeader() {
    this.selectedHeader = {
      id: 'selected-header',
      title: this.translate.instant('SELECTED') + ' (' + this.selectedItems.length + ')',
      controls: [],
    };
  }

  private _resetIsDirty() {
    this._isDirty = _.xor(this.viewData.selectedOrders.map(a => a.ID), this.selectedItems.map(b => b.id)).length !== 0;
  }

  updateEmptyMessage() {
    this.uiService.updateNothingSelectedScreenMessageFor(this.slicedFilteredOrdersList.length);
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.events.unsubscribe('updateOrdersList');
    this.orderManagementService.teamOrder = [];
    this.orderManagementService.ordersListMode = 'my_orders';
  }

  public get searchPlaceholderString(): string {
    let str = this.translate.instant('SEARCH');
    if (this.orderManagementService.ordersListMode === 'my_orders') {
      str = this.translate.instant('SEARCH_MY_ORDERS');
    } else if (this.orderManagementService.ordersListMode === 'team_orders') {
      str = this.translate.instant('SEARCH_TEAM_ORDERS');
    }
    return str;
  }

  public getFilterText(): string {
    let str: string;
    this.filterBadgeCount = 0;
    if (this.isTeamTabActive) {
      if (this.ordersFilterTeamOrders.statusString) {
        str = this.getStrStatus(this.ordersFilterTeamOrders.statusString);
        this.filterBadgeCount++;
        if (this.ordersFilterTeamOrders.products) {
          const product = this.teamProductFilterList.find(p => p.value == this.ordersFilterTeamOrders.products);
          str += ', ' + product.text;
          this.filterBadgeCount++;
        }
      }
      else if (this.ordersFilterTeamOrders.products) {
        const product = this.teamProductFilterList.find(p => p.value == this.ordersFilterTeamOrders.products);
        str = product.text;
        this.filterBadgeCount++;
      }
      else if (this.ordersFilterTeamOrders.searchText) {
        str = 'Results';
      }
      else {
        this.ordersFilterTeamOrders = { statusString: '', products: '', searchText: '' };
        str = 'All Orders';
      }
    } else {
      if (this.ordersFilter.statusString) {
        str = this.getStrStatus(this.ordersFilter.statusString);
        this.filterBadgeCount++;
        if (this.ordersFilter.products) {
          const product = this.myProductFilterList.find(p => p.value == this.ordersFilter.products);
          str += ', ' + product.text;
          this.filterBadgeCount++;
        }
      }
      else if (this.ordersFilter.products) {
        const product = this.myProductFilterList.find(p => p.value == this.ordersFilter.products);
        str = product.text;
        this.filterBadgeCount++;
      }
      else if (this.ordersFilterTeamOrders.searchText) {
        str = 'Results';
      }
      else {
        this.ordersFilter = { statusString: '', products: '', searchText: '' };
        str = 'All Orders';
      }
    }
    switch (str) {
      case 'Results':
        str = this.translate.instant('RESULTS');
        break;
      case 'All Orders':
        str = this.translate.instant('ALL_ORDERS');
        break;
      case 'Draft':
        str = this.translate.instant('OM_DRAFT');
        break;
      case 'For Review':
        str = this.translate.instant('FOR_REVIEW');
        break;
      case 'Approved':
        str = this.translate.instant('APPROVED');
        break;
      case 'Fulfilled':
        str = this.translate.instant('OM_FULFILLED');
        break;
      case 'Canceled':
        str = this.translate.instant('CANCELLED');
        break;
      default:
        break;
    }
    return str.toLowerCase();
  }

  getFilterButtonBadgeCount() {
    return this.filterBadgeCount;
  }

  public getStrStatus(status): string {
    let str = status;
    switch (str) {
      case 'Results':
        str = this.translate.instant('RESULTS');
        str = str.toLowerCase();
        break;
      case 'All Orders':
        str = this.translate.instant('ALL_ORDERS');
        str = str.toLowerCase();
        break;
      case 'Draft':
        str = this.translate.instant('OM_DRAFT');
        break;
      case 'For Review':
        str = this.translate.instant('FOR_REVIEW');
        break;
      case 'Approved':
        str = this.translate.instant('APPROVED');
        break;
      case 'Fulfilled':
        str = this.translate.instant('OM_FULFILLED');
        break;
      case 'Canceled':
        str = this.translate.instant('CANCELLED');
        break;
      default:
        str = str;
        break;
    }
    return str; //.toLowerCase();

  }

  public getFormattedDate(value): string {
    return this.datePipe.transform(value, 'MMM dd', undefined, this.translate.currentLang);
  }

  public isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

  public openOrdersFilter(ev): void {
    this.filterValueSelected = true;
    this.ordersFilter = {...this.ordersFilter, searchText:''};
    this.ordersFilterTeamOrders = {...this.ordersFilterTeamOrders, searchText:''};
    this.orderSearchInput = '';
    this.popover
      .create({component: MultiSelectPopover,componentProps: { root: (this.isTeamTabActive) ? this.filterPopoverDataTeamOrders : this.filterPopoverData },event})
      .then((data)=> data.present());
  }
  public sortOrdersList() {
    if (this.isTeamTabActive) {
      this.popover
        .create({component: MultiSelectPopover,componentProps: { root: this.teamSortPopoverData }, event})
        .then((data)=> data.present());
    }
    else {
      this.popover
        .create({component: MultiSelectPopover,componentProps: { root: this.sortPopoverData }, event})
        .then((data)=> data.present());
    }
  }

  public openOrderDetails(order: OrderActivity): void {
    let foundOrder;
    if (this.isTeamTabActive) {
      foundOrder = (this.orderManagementService.teamOrder.find(tor => tor.ID == order.ID) as OrderActivity)
    } else {
      foundOrder = (this.activityService.getActivityByID(order.ID) as OrderActivity);
    }
    if (foundOrder && !foundOrder.isHardDeleted) {
      if (this.isTeamTabActive && foundOrder.isTeamOrder) {
        this.trackingService.tracking('TeamOrderInfo', TrackingEventNames.ORDERMANAGEMENT);
      }
      this.orderManagementService.selectedOrder = foundOrder;
      this._slicedFilteredOrdersList.forEach(order=>{
        order.isSelected = false;
        if(order.id === foundOrder.ID) order.isSelected = true;
      });
      this.activityService.selectedActivity = foundOrder;
      this.orderManagementService.isRightPaneNavActive = true;
      if(!this.device.isOffline && !this.isTeamTabActive){
        let statusString = foundOrder.orderStatusString;
        this.activityDataService.getRealTimeActivityDetails(foundOrder).then(newOrder => {
          if(statusString != (newOrder as OrderActivity).orderStatusString){
            this.updateOrdersToDisplay();
          }
          this.orderActivityDataService.updateOrderActivity({ onDynamics: false, onLocalDatabase: true, onLocalCopy: true }, [newOrder as OrderActivity], new Date().getTime())
        });
      }
      this.navService.setChildNavRoot(OrderDetailsComponent, PageName.OrderDetailsComponent, PageName.OrderManagementPageComponent, { from: 'OrderManagementTool', activity: foundOrder });
    }
  }

  public onClosePage(event): void {
    this.activityService.selectedActivity = null;
    this.orderManagementService.selectedOrder = null;
    this.navService.popChildNavCtrlFromStack(ChildNavNames.OrderManagementNavigation);
    this.navService.popWithPageTracking().then(succ => {
      if (this.activityService.selectedActivityAtHome) {
        this.activityService.selectedActivity = this.activityService.selectedActivityAtHome
        this.uiService.activeView = this.uiService.getActiveViewBasedOnActivity(this.activityService.selectedActivity);
      } else {
        this.uiService.activeView = this.uiService.prevView;
        // Short call feature requires to bring the tab back to short call home
        if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
          this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
          this.uiService.setAgendaTab('shortCallHome');
        }
      }
      if (this.uiService.activeView === 'customer_inquiry' || this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent) {
        this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
      }
    });
  }

  public segmentChanged(selectedTab: string): void {
    this.content.scrollToTop(0);
    if (selectedTab === 'my_orders') {
      this.trackingService.tracking('MyOrdersClicked', TrackingEventNames.ORDERMANAGEMENT);
      this.isTeamTabActive = false;
      this.updateOrdersToDisplay();
      this.NoOrderMessage = 'OM_NO_ORDERS';
    } else if (selectedTab === 'team_orders') {
      this.trackingService.tracking('TeamOrdersClicked', TrackingEventNames.ORDERMANAGEMENT);
      this.isTeamTabActive = true;
      this.NoOrderMessage = 'OM_NO_TEAM_ORDERS';
      //this.updateOrdersToDisplay();
      this.getTeamOrderData().then(() => {
        this.prepareTeamFilter();
        this.updateOrdersToDisplay();
        this.updateEmptyMessage();
      });

    }
    this.orderSearchInput = '';
  }

  private prepareTeamFilter(): void {

    this.teamProductFilterList = [];
    this.filteredOrdersList().forEach(ord => {
      if (ord.products && ord.products.length && ord.ownerId !== this.authService.user.systemUserID) {
        ord.products.forEach(prod => {
          if (!this.teamProductFilterList.some(o => o.value === prod.productId)) this.teamProductFilterList.push({ text: prod.productName, value: prod.productId });
        });
      }
    });
    this.teamProductFilterList.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1);

    this.filterPopoverDataTeamOrders = [
      {
        text: '',
        value: 'All',
        items: [{ text: this.translate.instant('ALL_ORDERS_S'), value: 'All' }],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          this.ordersFilterTeamOrders = { ...this.ordersFilterTeamOrders, statusString: '', products: '' };
          this.updateOrdersToDisplay();
        }
      },
      {
        text: this.translate.instant('STATUS'),
        value: '',
        items: [
          { text: this.translate.instant('FOR_REVIEW'), value: 'For Review' },
          { text: this.translate.instant('APPROVED'), value: 'Approved' },
          { text: this.translate.instant('OM_FULFILLED'), value: 'Fulfilled' },
          { text: this.translate.instant('CANCELLED'), value: 'Canceled' }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          this.ordersFilterTeamOrders = { ...this.ordersFilterTeamOrders, statusString: item.value };
          if (this.ordersFilterTeamOrders.statusString === '' && this.ordersFilterTeamOrders.products === '') {
            itemRef.parent.items[0].value = 'All';
          }
          this.updateOrdersToDisplay();
        }
      },
      {
        text: this.translate.instant('PRODUCT'),
        value: '',
        items: this.teamProductFilterList,
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          this.ordersFilterTeamOrders.products = item.value;
          this.ordersFilterTeamOrders = { ...this.ordersFilterTeamOrders, products: item.value };
          if (this.ordersFilterTeamOrders.statusString === '' && this.ordersFilterTeamOrders.products === '') {
            itemRef.parent.items[0].value = 'All';
          }
          this.updateOrdersToDisplay();
        }
      }
    ];

    this.teamSortPopoverData = [
      {
        text: '',
        value: 'createdDate',
        items: [
          { text: this.translate.instant('DATE'), value: 'createdDate' },
          { text: this.translate.instant('OM_OWNER'), value: 'ownerNameString' }
        ],
        handler: (selectedItem, item, itemRef) => {
          item.value = selectedItem.value;
          this.teamSortBy = selectedItem;
          this.updateOrdersToDisplay();
        }
      }
    ];

  }

  public getOrderIcon(order: OrderActivity): string {
    let path = 'assets/imgs/order_open.svg';
    switch (order.status) {
      case 100001:
        path = 'assets/imgs/orders_complete.svg';
        break;
      case 0:
      case 1:
      case 4:
      default:
        break;
    }
    return path;
  }

  public async handlePlusButton() {
    this.orderManagementService.ordersListMode = 'my_orders';
    const errorOccured = false;
    const currentTime = new Date().getTime();
    let start, end;
    start = format(Utility.getRoundedDownTime(new Date()));
    end = format(addMinutes(start, 30));
    const payload = {
      salesorderid: 'offline_order_' + currentTime,
      indskr_externalid: 'offline_order_' + currentTime,
      ordernumber: 'New Order',
      subject: 'New Order',
      name: 'New Order',
      pendingPushToDynamics: true,
      statuscode: 1,
      statecode: 0,
      activitytypecode: 'indskr_order',
      createdon: new Date(currentTime).getTime(),
      scheduledstart: new Date(start).getTime(),
      scheduledend: new Date(end).getTime(),
    };

    const newOrder = new OrderActivity(payload);
    newOrder.statusString = 'Open';
    newOrder.ownerId = this.authService.user.systemUserID;
    newOrder.ownerNameString = this.authService.user.displayName;
    this.uiService.displayLoader();
    await this.orderActivityDataService.createOrderActivity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true }, [newOrder], currentTime, false).then(succ => {
          //Successfully created new order activity
          //this.initOrdersList();
          this.trackingService.tracking('OrderCreate',TrackingEventNames.ORDER);
          this.uiService.agendaRefreshRequired = true;
          this.activityService.selectedActivity = newOrder;
          this.orderManagementService.selectedOrder = newOrder;
          this.updateOrdersToDisplay();
          this.orderManagementService.isRightPaneNavActive = true;
          this.trackingService.tracking('OrderManagementNewOrder', TrackingEventNames.ORDERMANAGEMENT);
          this.navService.setChildNavRoot(OrderDetailsComponent, PageName.OrderDetailsComponent, PageName.OrderManagementPageComponent, { from: 'OrderManagementTool', activity: newOrder });
    }).catch(err => {
      // Handle any error occured during order activity creation
    });
    this.uiService.dismissLoader();
  }

  public handleSearch() {
    this.filterValueSelected = false;
    if (this.orderSearchInput.length > 0) {
      this.filterPopoverData.forEach(o => {
        o.value = '';
      });
      if(this.filterPopoverDataTeamOrders){
        this.filterPopoverDataTeamOrders.forEach(o => {
          o.value = '';
        });
      }
      
      this.ordersFilter = {...this.ordersFilter,  statusString: '', products: '', searchText: this.orderSearchInput};
      this.ordersFilterTeamOrders = {...this.ordersFilterTeamOrders,  statusString: '', products: '', searchText: this.orderSearchInput};
      // this.filteredOrdersList.filter((o)=>{
      //   return o.orderNumber.toLowerCase().indexOf(this.orderSearchInput.trim().toLowerCase())>-1
      //         || this.isTeamTabActive?o.ownerNameString.toLowerCase().indexOf(this.orderSearchInput.trim().toLowerCase())>-1
      //             : o.accountNameString.toLowerCase().indexOf(this.orderSearchInput.trim().toLowerCase())>-1
      // })
    }
    else {
      this.onSearchCancel();
    }
    this.updateOrdersToDisplay();
  }
  onSearchCancel() {
    this.ordersFilter = {...this.ordersFilter,  searchText: ''};
    this.ordersFilterTeamOrders = {...this.ordersFilterTeamOrders,  searchText: ''};
  }

  public getMainCardDataModel(order: OrderActivity) {
    let viewData: MainCardViewDataModel;
    viewData = {
      id: order.ID,
      fixedHeight: true,
      primaryTextLeft: this.getFormattedDate(order.createdDate),
      primaryTextRight: this.isTeamTabActive ? order.ownerNameString : (order.orderNumber ? this.translate.instant('NEW_ACTIVITY_SALES_ORDER') + ' - ' + order.orderNumber : this.translate.instant('OM_NEW_ORDER')),
      secondaryTextRight: !this.isTeamTabActive ? order.accountNameString ? order.accountNameString : this.translate.instant('NO_ACCOUNT') : order.orderNumber,
      secondaryTextRightTwo: this.getStrStatus(order.orderStatusString),
      isSelected: order.ID === this.orderManagementService.selectedOrder?.ID,
      showArrow: false,
      clickHandler: (id: string, event, specificTarget,refData) => {
        this.handleCardItemClick(id, event, specificTarget, refData)
      }
    };


    if (this.viewData && this.viewData.viewMode === ComponentViewMode.ADDNEW) {
      const isSelected = this.selectedItems.some(a => a.id == order.ID);
      viewData['mainItemCssClass'] = 'selector-item';
      viewData['isItemSelectedForSelectionView'] = true;
      viewData['endIconType'] = isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon';
      viewData['endIconCssClass'] = isSelected ? 'checkmark-icon' : 'add-icon';
      viewData['showEndIcon'] = !this.isReadOnlyMode;
    }


    return viewData;
  }

  private handleCardItemClick(id: string, event, specificTarget,refData){
    if(specificTarget === 'mainItem'){
      const foundOrder: OrderActivity = (this.activityService.getActivityByID(id) as OrderActivity);
      if(this.orderManagementService.ordersListMode === 'team_orders'){
        const foundTeamOrder: OrderActivity = (this.orderManagementService.teamOrder.find(to => to.ID == id) as OrderActivity);
        if(foundTeamOrder){
          this.openOrderDetails(foundTeamOrder);
        }
      }
      else if (foundOrder) {
        this.openOrderDetails(foundOrder);
      }
    } else if(specificTarget === 'endIcon'){
      if (refData && refData.showEndIcon) {
        if (refData.endIconType === 'indegene-selectors-add-icon')
          this._handleAddOrder(id);
        if (refData.endIconType === 'indegene-selectors-remove-icon')
          this._handleRemoveOrder(id);
      }
    }
  }


  private _handleAddOrder(id) {
 
    
    const foundItem = this._slicedFilteredOrdersList.find(a => a.id == id);
    if (foundItem) {
      let isAlreadyAddedIndex = this.selectedItems.findIndex(a => a.id == id);
      if (isAlreadyAddedIndex < 0) {
        this.selectedItems = [{
          ...foundItem,
          endIconType: 'indegene-selectors-remove-icon',
          endIconCssClass: 'remove-icon'
        }]
        this._resetIsDirty();
        this._initSelectedHeader();
        this.updateOrdersToDisplay();
        this.initOrdersHeaderLeft();
      }
    }
  }

  private _handleRemoveOrder(id) {
    let foundItemIndex;
    foundItemIndex = this.selectedItems.findIndex(a => a.id == id);
    if (foundItemIndex >= 0) {
      this.selectedItems.splice(foundItemIndex, 1);
      this._initSelectedHeader();
      this.updateOrdersToDisplay();
      this._resetIsDirty();
      this.initOrdersHeaderLeft();
    }
  }

  private sliceOrders(startIndex: number, count: number) {
    let filterBy;
    let sortBy;
    let list;
    if (this.isTeamTabActive) {
      filterBy = this.ordersFilterTeamOrders;
      sortBy = this.teamSortBy;
      list = this.filteredOrdersList().filter(order => order.isTeamOrder && order.orderStatusString !== 'Draft' && !order.isHardDeleted);
    } else {
      sortBy = this.sortBy;
      filterBy = this.ordersFilter;
      list = this.filteredOrdersList().filter(order => !order.isTeamOrder && !order.isHardDeleted);
    }

    if (filterBy.statusString) {
      list = list.filter(order => order.orderStatusString === filterBy.statusString);
    }
    if (filterBy.products) list = list.filter(order => order.products.some(p => p.productId === filterBy.products));

    if (filterBy.searchText) {
      list = list.filter((o) => {
        return o.orderNumber.toLowerCase().indexOf(filterBy.searchText.trim().toLowerCase()) > -1
          || (this.isTeamTabActive ? o.ownerNameString.toLowerCase().indexOf(filterBy.searchText.trim().toLowerCase()) > -1
            : o.accountNameString.toLowerCase().indexOf(filterBy.searchText.trim().toLowerCase()) > -1);
      });
    }
    this.orderListCount = list.length;
    list = list.sort((a, b) => {
      if ((sortBy.value !== 'createdDate' && a[sortBy.value] >= b[sortBy.value]) || (sortBy.value === 'createdDate' && b[sortBy.value] >= a[sortBy.value])) return 1;
      else return -1;
    });
    list = list.length < startIndex + count ? list.slice(startIndex) : list.slice(startIndex, startIndex + count);
    return list;
  }

  updateOrdersToDisplay() {
    this.filterText = this.getFilterText();
    this.recordCount = 0;
    this.slicedFilteredOrdersList = this.sliceOrders(this.recordCount, 20);
    this.recordCount = 20;
    this._slicedFilteredOrdersList = this.slicedFilteredOrdersList.map(ord=>{
      return this.getMainCardDataModel(ord);
    });
    this.updateEmptyMessage();
  }

  doInfinite(eventDetails,event) {
    const ordersTobePushed = this.sliceOrders(this.recordCount, 30);
    this.slicedFilteredOrdersList.push(...ordersTobePushed);
    this._slicedFilteredOrdersList = _.concat(this._slicedFilteredOrdersList, ordersTobePushed.map(ord=>{
      return this.getMainCardDataModel(ord);
    }));
    this.recordCount = this.slicedFilteredOrdersList.length;
    event.target.complete();
  }

  setTabsData() {
    this.tabsData = [
      {
         displayText: this.translate.instant('OM_MY_ORDERS'),
         value: "my_orders"
      },
      {
       displayText: this.isTeamOrdersViewEnabled ? this.translate.instant('APPROVALS') : '',
       value: "team_orders",
       disable: !this.isTeamOrdersViewEnabled || (this.device.isOffline && this.orderManagementService.teamOrder.length == 0)
      },
    ];
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.onClosePage(id);
        break;
      case 'plusNew':
        this.handlePlusButton();
        break;
      case 'back':
        this.closeSelectionPage();
        break;
      case 'done':
        this.mapMarketingPlans();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  private async getTeamOrderData() {
    if (this.orderManagementService.teamOrder.length == 0) {
      this.uiService.displayLoader(null,true);
      await this.orderActivityDataService.getTeamOrderDataOnline().then(succ => {
        this.uiService.dismissLoader();
      }).catch(err => {
        this.uiService.dismissLoader();
      });
      this.uiService.dismissLoader();
    }
  }

  private initOrdersHeaderLeft(): void {
    let buttons = [];
    if (this.viewData && (this.viewData.viewMode === ComponentViewMode.READONLY || this.viewData.viewMode === ComponentViewMode.ADDNEW)) {
      buttons.push({
        id: "back",
        imgSrc: 'assets/imgs/header_cancel.svg',
        cssClass: 'seventyPercentWidth',
        isDisabled: false,
        align: "left",
      },
        {
          id: "done",
          imgSrc: 'assets/imgs/header_complete.svg',
          cssClass: 'seventyPercentWidth',
          isDisabled: !this._isDirty || (this.device.isOffline && this.viewData.disableDoneButtonInOffline),
          align: "right",
        });
    }else {
      buttons.push({
        id: "close",
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        isDisabled: false,
        align: "left",
      },
      {
        id: "plusNew",
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        isDisabled: false,
        align: "right",
      });
    }


    
    this.indHeaderLeftModel = {
      id: 'orders-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('SALES_ORDERS'),
      mode: true,
      controls: buttons,
    };
  }

  public closeSelectionPage(): void {
    this.navService.popChildNavCtrlFromStack(ChildNavNames.OrderManagementNavigation);
    this.navService.popWithPageTracking();
  }

  private mapMarketingPlans() {
    const selectedItems = this.selectedItems.map(item => {
      return {
        ID: item.id,
        name: item.primaryTextRight
      } as OrderActivity
    })
    this.viewData.callbackFromSelectionComponent("DONEBUTTONCLICK", selectedItems, null);
    this.closeSelectionPage();
  }

}
