import { Activity } from '@omni/classes/activity/activity.class';
import { AuthenticationService } from '@omni/services/authentication.service';
import { Account } from '@omni/classes/account/account.class';
import { Contact, ContactAddress } from '@omni/classes/contact/contact.class';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { DeviceService } from '@omni/services/device/device.service';
import {
  DEFAULT_ACCOUNT_SCONDARY_INFO,
  DEFAULT_CONTACT_SECONDARY_INFO,
  ISecInfoConfig,
  SecondaryInfoEntityName,
} from '../../classes/sec-info-config/sec-info.class';
import { DiskService } from '@omni/services/disk/disk.service';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { Injectable } from '@angular/core';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import _, { String } from 'lodash';
import { LocalizationService } from '@omni/services/localization/localization.service';
import { multilingualLanguageToAttributeMapping } from '@omni/config/dynamic-forms/default-contact/default-contact-create';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { OrderActivity } from '@omni/classes/activity/order.activity.class';
import { TranslateService } from '@ngx-translate/core';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { SetBookingActivity } from '@omni/classes/activity/set-booking.activity.class';

@Injectable({
  providedIn: 'root',
})
export class SecInfoConfigDataService {
  public secondaryInfoConfigList = [];
  public fetchedAttributes;
  public isContactSecInfoUpdated = false;
  public isAccountSecInfoUpdated = false;
  public isKitBookingSecInfoUpdated = false;
  // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
  public isAppointmentSecInfoConfigured = false;
  public isProcedureLogSecInfoConfigured = false;
  public isKitBookingSecInfoConfigured = false;
  public proceduretypeTitle = '';
  public accounttypeString = '';
  // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //

  constructor(
    private readonly disk: DiskService,
    public dynamics: DynamicsClientService,
    public device: DeviceService,
    private readonly authenticationService: AuthenticationService,
    private localizationService: LocalizationService,
    public translate: TranslateService
  ) {}

  public SecondaryInfoFetchXML(entityName: string) {
    const secInfo = this.secondaryInfoConfigList.filter(
      (info) => info.entity === entityName
    );
    return secInfo[0] ? secInfo[0].fetchXMLStr : '';
  }

  public SecondaryInfoFormatedString(entityName: string) {
    const secInfo = this.secondaryInfoConfigList.filter(
      (info) => info.entity === entityName
    );
    return secInfo[0] ? secInfo[0].attributes : '';
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public async getSecInfoConfig(loadFromDBOnly = false): Promise<void> {
    try {
      let offlineSecInfo = [];

      const document = await this.disk
        .retrieve(DB_KEY_PREFIXES.SEC_INFO_CONFG)
        .catch((err) => console.log(err));
      if (document && document.raw) {
        offlineSecInfo = [...document.raw];
      }

      if (loadFromDBOnly || this.device.isOffline) {
        if (document && Array.isArray(document.raw) && document.raw.length > 0) {
          this.secondaryInfoConfigList = [...document.raw];

          // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
          let secondaryInfoAppointment: string = this.SecondaryInfoFormatedString(SecondaryInfoEntityName.Appointment);
          this.isAppointmentSecInfoConfigured = secondaryInfoAppointment ? true : false;
          let secondaryInfoSalesOrder = this.SecondaryInfoFormatedString(SecondaryInfoEntityName.SalesOrder);
          this.isProcedureLogSecInfoConfigured = secondaryInfoSalesOrder ? true : false;
          let secondaryInfoKitBooking = this.SecondaryInfoFormatedString(SecondaryInfoEntityName.Kitbooking);
          this.isKitBookingSecInfoConfigured = secondaryInfoKitBooking ? true : false;
          // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //

        } else {
          // Have default configs if no config available
          this.secondaryInfoConfigList = [];
          this.insertDefault();
        }
        return;
      }

      let fetchXML: string =
        fetchQueries.configuredFormFetchXMLs.fetchSecondaryInfoConfiguration;
      const now = new Date();

      fetchXML = fetchXML.replace(
        '{systemUserId}',
        this.authenticationService.user.systemUserID
      );
      fetchXML = fetchXML.replace(
        '{userBUId}',
        this.authenticationService.user.xBusinessUnitId
      );

      const resSecondaryInfo = await this.dynamics.executeFetchQuery(
        'indskr_secondaryinfoconfigurations',
        fetchXML
      );

      this.secondaryInfoConfigList = [];
      if (resSecondaryInfo && resSecondaryInfo.length > 0) {
        resSecondaryInfo.forEach((res) => {
          this.fetchSecondaryInfo(res);
        });
        this.insertDefault();
        this.isConfigDataChanged(offlineSecInfo, this.secondaryInfoConfigList);

        // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
        let secondaryInfoAppointment: string = this.SecondaryInfoFormatedString(SecondaryInfoEntityName.Appointment);
        this.isAppointmentSecInfoConfigured = secondaryInfoAppointment ? true : false;
        let secondaryInfoSalesOrder = this.SecondaryInfoFormatedString(SecondaryInfoEntityName.SalesOrder);
        this.isProcedureLogSecInfoConfigured = secondaryInfoSalesOrder ? true : false;
        let secondaryInfoKitBooking = this.SecondaryInfoFormatedString(SecondaryInfoEntityName.Kitbooking);
        this.isKitBookingSecInfoConfigured = secondaryInfoKitBooking ? true : false;
        // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //

        this.disk
          .updateOrInsert(DB_KEY_PREFIXES.SEC_INFO_CONFG, (doc) => {
            doc = {
              raw: this.secondaryInfoConfigList,
              lastModified: now.getTime(),
            };
            return doc;
          })
          .catch((error) => {
            console.error('Save Forms to DB error: ', error);
          });
      } else {
        this.disk
          .updateOrInsert(DB_KEY_PREFIXES.SEC_INFO_CONFG, (doc) => {
            doc = {
              raw: [],
              lastModified: now.getTime(),
            };
            return doc;
          })
          .catch((error) => {
            console.error('Save Forms to DB error: ', error);
          });
        this.isContactSecInfoUpdated = this.isAccountSecInfoUpdated = this.isKitBookingSecInfoUpdated =
          offlineSecInfo.length !== 0;
        this.insertDefault();
      }
    } catch (exception) {
      this.insertDefault();
      console.error(`exception in getSecInfoConfig ${exception}`);
    }
  }

  private insertDefault() {
    if (
      !this.secondaryInfoConfigList.some(
        (item) => item.entity === SecondaryInfoEntityName.Contact
      )
    ) {
      this.fetchSecondaryInfo({
        indskr_secondaryinfoconfigurationid: '_contactdefault_12345',
        modifiedon: 1602120785,
        indskr_entityname: DEFAULT_CONTACT_SECONDARY_INFO.indskr_entityname,
        indskr_secondaryinfoconfigdata: JSON.stringify(
          DEFAULT_CONTACT_SECONDARY_INFO.indskr_secondaryinfoconfigdata
        ),
      });
    }
    if (
      !this.secondaryInfoConfigList.some(
        (item) => item.entity === SecondaryInfoEntityName.Account
      )
    ) {
      this.fetchSecondaryInfo({
        indskr_secondaryinfoconfigurationid: '_accountdefault_12345',
        modifiedon: 1602120785,
        indskr_entityname: DEFAULT_ACCOUNT_SCONDARY_INFO.indskr_entityname,
        indskr_secondaryinfoconfigdata: JSON.stringify(
          DEFAULT_ACCOUNT_SCONDARY_INFO.indskr_secondaryinfoconfigdata
        ),
      });
    }
  }

  private isConfigDataChanged(localDBList, newList): void {
    this.isContactSecInfoUpdated = !_.isEqual(
      localDBList.filter((o) => o.entity === SecondaryInfoEntityName.Contact),
      newList.filter((o) => o.entity === SecondaryInfoEntityName.Contact)
    );
    this.isAccountSecInfoUpdated = !_.isEqual(
      localDBList.filter((o) => o.entity === SecondaryInfoEntityName.Account),
      newList.filter((o) => o.entity === SecondaryInfoEntityName.Account)
    );
    this.isKitBookingSecInfoUpdated = !_.isEqual(
      localDBList.filter((o) => o.entity === SecondaryInfoEntityName.Kitbooking),
      newList.filter((o) => o.entity === SecondaryInfoEntityName.Kitbooking)
    );
  }

  private getAttributeText(props: ISecInfoConfig,navigationPropertyName?: string) {
    if (props.customText) {
      this.fetchedAttributes.push(props);
      return undefined;
    }
    if (props.from) {
      let linkEntityStr = `<link-entity name="${props.name}" from="${props.from}" to="${props.to}" link-type="outer">`;
      if (props.attributes) {
        for (const attr of props.attributes) {
          linkEntityStr += this.getAttributeText(attr, props.navigationPropertyName);
        }
        if (props.filter) {
          linkEntityStr += `<filter type="${props.filter.type}">`;
          linkEntityStr += props.filter.conditions
            .map(
              (cond) =>
                `<condition attribute="${cond.attribute}" operator="${cond.op}" value="${cond.value||''}" />`
            )
            .join('');
          linkEntityStr += `</filter>`;
        }
      }
      linkEntityStr += `</link-entity>`;
      return linkEntityStr;
    }

    if(navigationPropertyName) {
      props.navigationPropertyName = navigationPropertyName;
    }

    this.fetchedAttributes.push(props);
    return `<attribute name='${props.name}' alias='${props.alias}'/>`;
  }
  private fetchSecondaryInfo(response: any) {
    if (
      !this.secondaryInfoConfigList.some(
        (item) => item.entity === response.indskr_entityname
      )
    ) {
      const configData: ISecInfoConfig = JSON.parse(
        response.indskr_secondaryinfoconfigdata
      );
      this.fetchedAttributes = [];
      this.secondaryInfoConfigList.push({
        configId: response.indskr_secondaryinfoconfigurationid,
        modifiedOn: new Date(response.modifiedon).getTime(),
        entity: response.indskr_entityname,
        fetchXMLStr: configData.attributes
          .map((res) => this.getAttributeText(res))
          .join(''),
        seperator: configData.seperator,
        attributes: [...this.fetchedAttributes],
        prefix: configData.prefix || '',
        suffix: configData.suffix || ''
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public getSecondaryInfoFormatedText(entity: Contact | Account, entityName) {
    const secInfo = this.secondaryInfoConfigList.filter(
      (info) => info.entity === entityName
    )[0];
    let formatedSecondaryInfo = '';
    if (secInfo) {
      formatedSecondaryInfo = secInfo.attributes
        .map((attr) => {
          if (attr.customText) {
            return attr.customText;
          }
          //onekey code label multilingual check
          if(attr.name.includes("omnione_lu") && !_.isEmpty(this.localizationService.multiLingualFieldsData)) {
            const onekeyCodesLabelsId = entity.raw[`${attr.alias}`] || entity.raw[`_${attr.name}_value`] || '';
            if(onekeyCodesLabelsId) {
              const foundRecord = this.localizationService.multiLingualFieldsData.find(a=> a['omnione_onekeycodeslabelsid'] == onekeyCodesLabelsId);
              if(foundRecord) {
                let translatedLang: string = '';
                let key = this.localizationService.getOneKeyTableAttributes();
                if(foundRecord) translatedLang = foundRecord[key] ? foundRecord[key] || '' : foundRecord['omnione_en_long_label'] || '';
                // let translatedLang = foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authenticationService.user.localeId]] ? foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authenticationService.user.localeId]] || '' : foundRecord['omnione_en_long_label'] || '';
                if(translatedLang) {
                  return translatedLang;
                }
              }
            }
          }
          if (attr.alias && entity && entity.raw) {
            return (
              entity.raw[`${attr.alias}_Formatted`] ||
              entity.raw[attr.alias] ||
              entity.raw[
                `${attr.alias}@OData.Community.Display.V1.FormattedValue`
              ]
            );
          }
          return undefined;
        })
        .filter(Boolean)
        .join(`${secInfo.seperator}`);
    }
    return formatedSecondaryInfo;
  }

  public checkIfSchemaIsConfig(schemaname: string, entityname): boolean {
    const secInfo = this.secondaryInfoConfigList?.find(info => info.entity == entityname);
    if (secInfo) {
      return secInfo.attributes.some(attr => attr.schemaName == schemaname);
    }
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public getSecondaryInfoFormatedTextForAppointment(entity: Activity, entityName) {
    console.warn(`getSecondaryInfoFormatedTextForAppointment entityName: ${entityName}`);
    const secInfo = this.secondaryInfoConfigList.filter(
      (info) => info.entity === entityName
    )[0];
    let formatedSecondaryInfo = '';
    if (secInfo) {
      formatedSecondaryInfo = secInfo.attributes
        .map((attr) => {
          //This is for Accounts
          if(attr.schemaName === 'indskr_accountid' && attr.navigationPropertyName === 'indskr_appointment_activityaccount') {
            if(entity['accounts'] && entity['accounts'].length > 0) {
              if(entity['accounts'].length === 1)
                return `${entity['accounts'][0].accountName}`;
              else
                return `${entity['accounts'][0].accountName} + ${entity['accounts'].length - 1}`;
            }
          }
           //This is for Account type
           if(attr.schemaName === 'indskr_accounttype') {
            if(entity['accounts'] && entity['accounts'].length > 0) {
              if(entity['accounts'].length === 1) {
                let accounttypeString = entity['accounts'][0]['raw']['indskr_accounttype_Formatted'];
                if(accounttypeString) {
                  return `${accounttypeString}`;
                }
              }
              else {
                let accounttypeString = entity['accounts'][0]['raw']['indskr_accounttype_Formatted'];
                if(accounttypeString) {
                  return `${accounttypeString} + ${entity['accounts'].length - 1}`;
                }
              }
            }
          }
          //This is for Contacts
          if(attr.schemaName === 'indskr_contactid') {
            if(entity['contacts'] && entity['contacts'].length > 0) {
              if(entity['contacts'].length === 1)
                return `${this.getFullNameForContactDisplaySequence(entity['contacts'][0])}`;
              else
                return `${this.getFullNameForContactDisplaySequence(entity['contacts'][0])} + ${entity['contacts'].length - 1}`;
            }
          }
          //This is for Location
          if(attr.schemaName === 'Location') {
            return entity['location'];
          }
          //This is for Activity Type
          if(attr.schemaName === 'indskr_ActivityType') {
            return entity['activityTypeName'];
          }
          //This is for Meeting Duration
          if(attr.schemaName === 'indskr_duration') {
            return `${entity['durationInMinutes']} ${this.translate.instant('MINUTES_SHORT')}`;
          }
          //This is for Products
          if(attr.schemaName === 'indskr_competitorproduct') {
            if(entity['products'] && entity['products'].length > 0) {
              let selectedProducts = entity['products'].filter(prod => prod.isSelected === true);
              if(selectedProducts.length > 0) {
                if(selectedProducts.length === 1)
                  return `${selectedProducts[0].name}`;
                else
                  return `${selectedProducts[0].name} + ${selectedProducts.length - 1}`;
              }
            }
          }
          if(attr.schemaName === 'OwnerId') {
            if(entity['meetingOwner']) {
              return entity['meetingOwner'];
            } else if(entity['meetingOwnerName']) {
              return entity['meetingOwnerName'];
            }
          }
          // if (attr.name && entity[attr.name]) {
          //   return entity[attr.name];
          // }
          // if (attr.displayName && entity[attr.displayName]) {
          //   return entity[attr.displayName];
          // }
          // if (attr.schemaName && entity[attr.schemaName]) {
          //   return entity[attr.schemaName];
          // }
        })
        .filter(Boolean)
        .join(` ${secInfo.seperator} `);
        formatedSecondaryInfo = secInfo.prefix ? `${secInfo.prefix} ${secInfo.seperator} ${formatedSecondaryInfo}` : formatedSecondaryInfo;
        formatedSecondaryInfo = secInfo.suffix ? formatedSecondaryInfo + ` ${secInfo.seperator} ${secInfo.suffix}` : formatedSecondaryInfo;
    }

    if(formatedSecondaryInfo === '' && entityName === SecondaryInfoEntityName.Appointment) {
     formatedSecondaryInfo = `${this.translate.instant('MEETING')}`
    }

    console.log(formatedSecondaryInfo);
    return formatedSecondaryInfo;
  }

  getFullNameForContactDisplaySequence(contact) {
    const contactDisplaySequence = Number.parseInt(localStorage.getItem("contactdisplaysequence"));
    if (contactDisplaySequence == 548910000 ) {
      return  contact['fullname'] ? contact['fullname'] : contact['firstName'] + " " + contact['lastName'];
    } else if (contactDisplaySequence == 548910001) {
      return contact['fullname'] ? contact['fullname']: contact['lastName'] + " " + (contact['firstName']);
    }
    else{
      return  contact['fullname'] ? contact['fullname'] :contact['firstName'] + " " + (contact['lastName'] ? contact['lastName'] : '');
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public getSecondaryInfoFormatedTextForSalesOrder(entity: SurgeryOrderActivity, entityName) {
    console.warn(`getSecondaryInfoFormatedTextForSalesOrder entityName: ${entityName}`);
    const secInfo = this.secondaryInfoConfigList.filter(
      (info) => info.entity === entityName
    )[0];
    let formatedSecondaryInfo =  secInfo.prefix || '';
    if (secInfo) {
      formatedSecondaryInfo += secInfo.attributes
        .map((attr) => {
          //This is for Account name
          if(attr.schemaName === 'Name' && attr.navigationPropertyName === 'customerid_account') {
            if(entity['accountNameString']) {
              return entity['accountNameString'];
            }
          }
          //This is for Account type
          if(attr.schemaName === 'indskr_accounttype') {
            if(this.accounttypeString) {
              return this.accounttypeString;
            }
          }
          //This is for Contacts
          if(attr.schemaName === 'FullName') {
            if(entity['customers'] && entity['customers'].length > 0) {
              if(entity['customers'].length === 1)
                return `${this.getFullNameForContactDisplaySequence(entity['customers'][0])}`;
              else
                return `${this.getFullNameForContactDisplaySequence(entity['customers'][0])} + ${entity['customers'].length - 1}`;
            }
          }
          //This is for Procedure Type
          if(attr.schemaName === 'indskr_ProcedureTypes') {
            if(this.proceduretypeTitle) {
              return this.proceduretypeTitle;
            }
          }
          //This is for Products
          if(attr.schemaName === 'indskr_Product') {
            if(entity['products'] && entity['products'].length > 0) {
              let selectedProducts = entity['products'].filter(prod => !prod.isDeleted)
              if(selectedProducts.length > 0) {
                if(selectedProducts.length === 1)
                  return `${selectedProducts[0].productName}`;
                else {
                  selectedProducts.sort((a, b): number => {
                    if (a.productName > b.productName) return 1;
                    if (a.productName < b.productName) return -1;
                    return 0;
                  });
                  return `${selectedProducts[0].productName} + ${selectedProducts.length - 1}`;
                }
              }
            }
          }
          //This is for Owner
          if(attr.schemaName === 'OwnerId') {
            if(entity['ownerNameString']) {
              return entity['ownerNameString'];
            }
          }
          // === // === // === This is for JnJ Japan Shorei field  // === //  === //
          if(attr.schemaName === 'jnjjapan_Shorei') {
            if(entity['jnjjapan_shoreiname']) {
              return entity['jnjjapan_shoreiname'];
            }
          }
          // === // === // === This is for JnJ Japan Shorei field  // === //  === //

          // if (attr.name && entity[attr.name]) {
          //   return entity[attr.name];
          // }
          // if (attr.displayName && entity[attr.displayName]) {
          //   return entity[attr.displayName];
          // }
          // if (attr.schemaName && entity[attr.schemaName]) {
          //   return entity[attr.schemaName];
          // }
        })
        .filter(Boolean)
        .join(` ${secInfo.seperator} `);
        formatedSecondaryInfo = secInfo.suffix ? formatedSecondaryInfo + `${secInfo.suffix}` : formatedSecondaryInfo;
    }

    if(formatedSecondaryInfo === '' && entityName === SecondaryInfoEntityName.SalesOrder) {
      formatedSecondaryInfo = `Procedure Log`
    }

    console.log(formatedSecondaryInfo);
    return formatedSecondaryInfo;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public getSecondaryInfoFormatedTextForCustomerAddress(addressesList: Array<ContactAddress>=[], entityName) {
    console.warn(`getSecondaryInfoFormatedTextForCustomerAddress`);

    let addresses: ContactAddress;

    if (addressesList.length == 0) return undefined;

    let primaryAddFound: ContactAddress;
    //For each email, check if it is primary
    addressesList.forEach(add => {
      if (add.isPrimary) primaryAddFound = add;
    });

    //Do we have a first choice?
    if (primaryAddFound) {
      addresses = primaryAddFound;
    } else {
      //Fallback to any choice
      addresses = addressesList[0];
    }

    if (!addresses) return undefined;

    const secInfo = this.secondaryInfoConfigList.filter(
      (info) => info.entity === entityName
    )[0];
    let formatedSecondaryInfo = '';
    if (secInfo) {
      formatedSecondaryInfo = secInfo.attributes
        .map((attr) => {
          //This is for Country
          if(attr.schemaName === 'Country') {
            return addresses.country;
          }
          //This is for StateOrProvince
          if(attr.schemaName === 'StateOrProvince') {
            return addresses.state;
          }
          //This is for City
          if(attr.schemaName === 'City') {
            return addresses.city;
          }
          //This is for Line1
          if(attr.schemaName === 'Line1') {
            return addresses.street;
          }
          //This is for Line2
          if(attr.schemaName === 'Line2') {
            return addresses.street2;
          }
          //This is for Line3
          if(attr.schemaName === 'Line3') {
            return addresses.street3;
          }
          //This is for Postal code
          if(attr.schemaName === 'PostOfficeBox') {
            return addresses.postal;
          }
          return undefined;
        })
        .filter(Boolean)
        .join(`${secInfo.seperator}`);
    }

    console.log(formatedSecondaryInfo);
    return formatedSecondaryInfo;
  }

  public getSecondaryInfoFormatedTextForKitBooking(entity: SetBookingActivity, entityName) {
    const secInfo = this.secondaryInfoConfigList.filter(
      (info) => info.entity === entityName
    )[0];
    let formatedSecondaryInfo = '';
    if (secInfo) {
      formatedSecondaryInfo = secInfo.attributes
        .map((attr) => {
          // if(attr.schemaName === 'indskr_status') {
          //   if(entity['rawData']['indskr_status']) {
          //     return entity['rawData']['indskr_status'];
          //   }
          // }
          // if(entity['rawData'][attr.schemaName]) {
          //   return entity['rawData'][attr.schemaName];
          // }
          if (attr.alias && entity && entity.rawData) {
            return (
              entity.rawData[`${attr.alias}_Formatted`] ||
              entity.rawData[attr.alias] ||
              entity.rawData[
                `${attr.alias}@OData.Community.Display.V1.FormattedValue`
              ]
            );
          }
        })
        .filter(Boolean)
        .join(` ${secInfo.seperator} `);
    }
    //console.log(formatedSecondaryInfo);
    return formatedSecondaryInfo;
  }
}
