import { Activity } from './activity.class';
import { Contact } from '../contact/contact.class';
import { Account } from '../account/account.class';
import { Presentation } from '../presentation/presentation.class';
import { Brand } from '../brand/brand.class';
import { isValid } from 'date-fns';
import { ActivityPresentation } from '../presentation/activity-presentation.class';
import { ActivityResource } from '../resource/activity-resource.class';
import { SharedResourceStatus, Resource } from '../resource/resource.class';
import { AccompainedUser } from './accompained-user.class';
import { TherapeuticArea } from '../therapeutic-area/therapeutic-area.class';
import {Utility} from "../../utility/util";
import _ from 'lodash';
import { UpdateTypeAndSubTypeActivityPayLoad } from '@omni/data-services/meeting/meeting.data.service';
import { ConfiguredFields } from '../authentication/configured.field.class';
import { DynamicsBoolean } from '../../enums/shared-enums';
import { IONote } from '../io/io-note.class';
import { PhotoOrigin } from '../store-check/photo';
import { PhotoAttachment } from '../store-check/photo-attachment';
import { GPSActivityPhoto } from '../store-check/photo';
import { SetBookingActivity } from './set-booking.activity.class';
import { ReasonForLocationCaptureFailure } from '@omni/enums/presentation/gps-data.enum';


export const OFFLINE_ID_PREFIX = 'offline_meeting_';

export interface ContactAttendees {
    activityId: string,
    indskr_activitycontactid: string,
    indskr_contactid: string,
    indskr_name: string,
    indskr_joineddate: string,
    indskr_leftdate: string,
    indskr_sequence: number
}

export interface MeetingTypeDTO {
    value: number,
    type: string
}

export interface MeetingObjective {
  indskr_meetingobjectivesid: string;
  indskr_name: string
}
export interface GpsDetails {
  indskr_checkinstatus?: number;
  indskr_checkindatetime?: Date;
  indskr_checkoutdatetime?: Date;
  indskr_checkoutstatus?: number;
  indskr_gpscheckindetailsid?: string;
  statuscode?: number;
  indskr_checkinlatitude?: number;
  indskr_checkinlongitude?: number;
  indskr_checkoutlatitude?: number;
  indskr_checkoutlongitude?: number;
}

export interface ActivityDiseaseArea{
  indskr_diseaseareaid:string;
  indskr_sequence:number;
  indskr_automaticallyselected:boolean;
  indskr_geneeselected:boolean;
  indskr_name:string;
  isSelected?:boolean;
}

export interface ActivityProductIndication{
  productIndicationId:string;
  indskr_sequence:number;
  indskr_automaticallyselected:boolean;
  indskr_geneeselected:boolean;
  indskr_name:string;
  isSelected?:boolean;
  activityProductIndicationKeyMessages:Array<ActivityProductIndicationKeyMessages>;
}

export interface ActivityProductIndicationKeyMessages{
  indskr_keymessageid:string;
  indskr_automaticallyselected:boolean;
  indskr_geneeselected:boolean;
  indskr_name:string;
  isSelected?:boolean;
}

export interface ActivityContactSentiments {
  indskr_contactid: string;
  activityKeyMessageSentiments: Array<ActivityKeyMessageSentiments>;
}

export interface ActivityKeyMessageSentiments {
  indskr_keymessageid:string;
  indskr_name:string;
  indskr_keymessagesentiment:string;
}


export class AppointmentActivity extends Activity {

    public actualDurationMinutes:number;
    public actualStart:string;
    public actualEnd:string;
    public location:string;
    public meetingURL:string;
    public notes: string;
    public meetingNotes: Array<IONote> = [];

    public createdOffline: boolean;
    public contacts: Array<Contact>;
    public accounts: Array<Account>;
    public products: Array<Brand>;
    public activityProducts: Array<Brand>
    public activityPresentations: Array<ActivityPresentation>
    public activityResources: Array<ActivityResource>
    public activityTherapeuticAreas: Array<TherapeuticArea>
    public activityDiseaseAreas:Array<ActivityDiseaseArea> = [];
    public activityProductIndications:Array<ActivityProductIndication> = []
    public activityContactSentiments:Array<ActivityContactSentiments> = [];
    public presentations: (Presentation | Resource) [];
    public presPlayList: (Presentation | Resource)[];
    public appconfiglookupfields: UpdateTypeAndSubTypeActivityPayLoad[] = [];
    public appConfigFields: ConfiguredFields[] = [];

    public subtype: number;

    public indskr_totreason: number;
    public indskr_totreasonFormattedValue: string;

    public _raw_activityAccounts: Array<any>;
    private _raw_activityPlaylists: Array<any>;
    private _raw_activityPresentations: Array<any>;
    private _raw_activityProducts: Array<any>;
    private _raw_activitySharedResources: Array<any>;
    private _raw_activitySurveys: Array<any>;
    public _raw_contactAttendees: Array<ContactAttendees>;

    private _raw_indskr_accesstoken: string;
    private _raw_indskr_positionid: string;
    private _raw_isalldayevent: number;
    private _raw_meetingTopicName: string;
    public indskr_teamslink: string;
    public indskr_teams_meeting_id: string;
    public actualdurationminutes: number = 0;
    public activityattendeejoiningdetails: any[] = [];
    public indskr_selfclaimbyuseronappttimemodification: boolean = false;
    public indskr_meetingdistance: number;
    public indskr_reasonforlocationcapturefailure: ReasonForLocationCaptureFailure;

    public accountPlanId : string;

    public associatedOpportunities:Array<{
      opportunityId:string,
      opportunityName:string,
    }>;
  public associatedAccountPlans:Array<{
    accountPlanId:string,
    accountPlanName:string,
  }>;
    public associatedEvents:Array<{
      eventId:string,
      name:string,
    }>;
    public associatedAttachments:Array<{
      annotationId:string,
      attachmentName:string,
    }>;

    get offlineMeetingId(): string {
      return this.offlineId;
    }
    set offlineMeetingId(offlineId: string) {
      this.offlineId = offlineId;
    }

    private readonly DEFAULT_LOCATION_MESSAGE = 'No Location';
    public sharedResources: SharedResourceStatus[] = [];
    public get isLiveMeet() {
      return this.location === 'LiveMeet';
    }

    public accompaniedUserList: AccompainedUser[] = [];
    public accompaniedUserString:string;
    public isJointmeeting:boolean;
    public isMeetingPresentationDetailed:boolean = false;
    public positionId: string;
    public isOfflineMeeting: boolean;
    public senton: string;
    public readOn: string;
    public channelTypeName: string;
    public indskr_dnascheduler: boolean;
    /**
     * indskr_meetingtype
     * Value: 548910000, Label: Face To Face
     * Value: 548910001, Label: Tele Call
     * Value: 600000000, Label: Account Call
     * Value: 600000001, Label: Contact Call
     */
    public indskr_meetingtype: number;
    public isRemoteDetailing: boolean;

    /* array of id's of case intakes for a particular meeting */
    public customerInquiries: any[] = [];
    public isInviteSent: DynamicsBoolean;
    public description: string;
    public regardingObjectId: string;
    public regardingObjectIdValue:string;
    public covisitorComplianceAcked: boolean;
    public covisitorComplianceDescription: string;
    public activityProcedures: Brand[] = [];
    public activitySupportingMaterials:Array<{
      name:string,
      id:string,
    }> = [];
    public indskr_suggest: boolean;
    public indskr_reason: string;
    public activityObjectives: MeetingObjective[] = [];
    public gpsCheckInDetails : GpsDetails;
    public latitude : number;
    public longitude : number;
    public indskr_gpscheckindetailsid : string;
    public indskr_meetinglocationlongitude:number;
    public indskr_meetinglocationlatitude:number;
    public indskr_isparentcall: boolean;
    public indskr_parentcallid: string;
    public meetingOutcomeId: string;
    public meetingOutcomeString: string;
    public marketingBusinessPlanId : string;
    public marketingBusinessPlanName : string;
    public indskr_coachingplan : string;
    public indskr_coachingplanname : string;
    public competitorProducts: Array<ActivityCompetitorProduct> = [];
    public hasLinkedToMarketingBusinessPlan?: boolean;
    public inStorePhotos: InStorePhoto[] = [];
    public photoAttachments: PhotoAttachment[] = [];
    public gpsActivityPhotos: GPSActivityPhoto[] = [];
    public indskr_reasonforcancellation : string
    public indskr_cancellationreasonother : string;
    public indskr_reasonforcancellationname : string;
    public cancellationreason:string;
    public deleted:boolean =false;
    public indskr_kitbooking: string;
    public indskr_shortcall: boolean = false;
    public indskr_suggestionrescheduled: boolean = false;

     constructor(raw:Object) {
        super(raw);

        if (!this.ID && raw.hasOwnProperty('offlineMeetingId')) {
            this.ID = raw['offlineMeetingId'];
       }

       this.contacts = [];
       if (raw['contactAttendees'] && Array.isArray(raw['contactAttendees'])) {
         raw['contactAttendees'].forEach(rawContact => {
           const rawContactObj = {
             'contactid': rawContact['indskr_contactid'],
             'firstname': rawContact['indskr_name'],
             'indskr_isguest': rawContact['indskr_isguest'],
             'indskr_joineddate': rawContact['indskr_joineddate'],
             'indskr_leftdate': rawContact['indskr_leftdate'],
             'indskr_isremote': rawContact['indskr_isremote'] || true,
             'indskr_leftmanually': rawContact['indskr_leftmanually'] || false,
             'indskr_sequence': rawContact["indskr_sequence"] ? parseInt(rawContact['indskr_sequence']) : 0
           };
           let contact = new Contact(rawContactObj);
           contact.isActive = true;
           this.contacts.push(contact);
           this.activityContactSentiments.push({
            indskr_contactid: rawContact['indskr_contactid'],
            activityKeyMessageSentiments: rawContact['activityKeyMessageSentiments'] ? rawContact['activityKeyMessageSentiments'] : [],
           });
         });
       }
        this.accounts = [];
       if (raw['activityAccounts'] && Array.isArray(raw['activityAccounts'])) {
         raw['activityAccounts'].forEach(activityAcc => {
           if (activityAcc.indskr_accountid) {
             activityAcc = {
               accountid: activityAcc.indskr_accountid,
               name: activityAcc.indskr_name
             }
             const account = new Account(activityAcc);
             this.accounts.push(account);
           }
         })
       }
        this.presentations = [];
        this.indskr_selfclaimbyuseronappttimemodification = raw['indskr_selfclaimbyuseronappttimemodification'];
        this.indskr_meetingtype = raw['indskr_meetingtype'];
        this._raw_activityAccounts = raw['activityAccounts'] || undefined;
        this.activityProducts = raw['activityProducts'] || [];
        this._raw_contactAttendees = raw['contactAttendees'] || undefined;
        this.presPlayList = this._raw_activityPlaylists = raw['activityPlaylists'];
        this.presentations = this._raw_activityPresentations = raw['activityPresentations'];
        this.activityPresentations = [];
        this.activityResources = [];
        this.appconfiglookupfields = raw['appconfiglookupfields'] || [];
        this.isRemoteDetailing = raw['isRemoteDetailing'] || raw['indskr_isremotedetailing'];
        if (raw['activityPresentations'] && Array.isArray(raw['activityPresentations'])) {
            for (let i = 0; i < raw['activityPresentations'].length; i++) {
                const pres = raw['activityPresentations'][i];
                this.activityPresentations.push(new ActivityPresentation(pres));
            }
        }
        if (raw['activityResources'] && Array.isArray(raw['activityResources'])) {
            for (let i = 0; i < raw['activityResources'].length; i++) {
                const resource = raw['activityResources'][i];
                this.activityResources.push(new ActivityResource(resource));
            }
        }

        this.indskr_meetinglocationlongitude = raw['indskr_meetinglocationlongitude'] || null;
        this.indskr_meetinglocationlatitude = raw['indskr_meetinglocationlatitude'] || null;

        this.activityTherapeuticAreas = [];
        if (raw['activityTherapeuticAreas'] && Array.isArray(raw['activityTherapeuticAreas'])) {
            raw['activityTherapeuticAreas'].forEach(ta => {
                let newTa = new TherapeuticArea(ta);
                newTa.isSelected = true;
                this.activityTherapeuticAreas.push(newTa);
            });
        }

        if (raw['activityDiseaseAreas'] && Array.isArray(raw['activityDiseaseAreas'])) {
          raw['activityDiseaseAreas'].forEach(ta => {
              this.activityDiseaseAreas.push(ta);
          });
        }

        if (raw['activityProductIndications'] && Array.isArray(raw['activityProductIndications'])) {
          raw['activityProductIndications'].forEach(ta => {
              this.activityProductIndications.push(ta);
          });
          this.activityProductIndications.forEach(a=>{
            a.isSelected = true;
            if(a.activityProductIndicationKeyMessages){
              a.activityProductIndicationKeyMessages.forEach(b=>{
                b.isSelected = true;
              })
            }
          })
        }

        if(raw['accountPlanId']){
          this.accountPlanId = raw['accountPlanId'];
        }

        this.createdOffline = (raw['createdOffline'] ? raw['createdOffline'] : false);
        this.isOfflineMeeting = !!raw['indskr_isofflinemeeting'];
        if(raw['ownerid']){
            this.meetingOwnerName = raw['ownerid'].Name;
            this.meetingOwnerId =  raw['ownerid'].Id;
        }else if(raw['indskr_ownerfullname']){
            this.meetingOwnerName = (raw['indskr_ownerfullname']) ? raw['indskr_ownerfullname'] : this.meetingOwnerName;
            this.meetingOwnerId = (raw['indskr_ownerid']) ? raw['indskr_ownerid'] : this.meetingOwnerId;
        }
        if (!this.meetingOwnerId) {
            this.meetingOwnerId = raw['indskr_ownerid'];
        }
        this.actualDurationMinutes = 0;
        this.actualStart = raw['actualstart'];
        this.actualEnd = raw['actualend'];
        this.location = raw['location'] ? raw['location'] : raw['app_location'];
        this.meetingURL = raw['indskr_meetingurl'];
        this.notes = raw['indskr_notes'];
        this.meetingNotes = [];
        if(raw && raw.hasOwnProperty('notes') && raw['notes']) {
          raw['notes'].forEach(note => {

            if (note.hasOwnProperty('filesize') && note['filesize'] > 0) { // check if File size is greter than 0 then it has valid document
              note['isdocument'] = true;
            }

            let filteredNotes = this.meetingNotes.filter(noteFromObject => noteFromObject.noteId === note['annotationid'])
            //Some how duplicate notes are coming from service, So filter out the notes which are already present in teh activity.
            if (_.isEmpty(filteredNotes))
              this.meetingNotes.push(new IONote(note));
          });
        }
        this.subtype == raw['indskr_subtype'];
        if (!this.location) this.location = this.DEFAULT_LOCATION_MESSAGE;
        this.indskr_totreason = raw['indskr_totreason'] || undefined;
        this.indskr_totreasonFormattedValue = raw['indskr_totreasonFormattedValue'] || '';
        this.accompaniedUserList = this.fetchUserList(raw);
        this.accompaniedUserString = this.fetchUserString(raw);
        this.isJointmeeting = this.getJointMeetingStatus(raw) || raw['indskr_jointmeeting'];
        this.offlineMeetingId = raw['offlineMeetingId'] ? raw['offlineMeetingId'] : undefined;
        this._raw_activitySharedResources = raw['activitySharedResources'] ? raw['activitySharedResources'] : undefined;
        // this.sharedResources = _.isEmpty(this._raw_activitySharedResources) ? [] : this._raw_activitySharedResources;
        this.positionId = raw['indskr_positionid'] ? raw['indskr_positionid'] : undefined;
        this.senton = raw['senton'];
        this.readOn = raw['readOn'];
        this.channelTypeName = raw['channelTypeName'] || undefined;
        this.indskr_dnascheduler = raw['indskr_dnascheduler'];
        this.indskr_activitytype = raw['indskr_activitytype'];
        this.indskr_activitysubtype = raw['indskr_activitysubtype'];
        this.activityTypeName = raw['activityTypeName'];
        this.activitySubTypeName = raw['activitySubTypeName'];
        this.isInviteSent = raw['indskr_invitesent'] && !isNaN(raw['indskr_invitesent']) ? raw['indskr_invitesent'] : DynamicsBoolean.false;
        this.description = raw['description'] || '';
        this.indskr_suggest = raw['indskr_suggest'] || false;
        this.indskr_reason = raw['indskr_reason'] || false;
        if(raw['omnip_datecompleted']) this.omnip_datecompleted = raw['omnip_datecompleted'];
        this.regardingObjectId = raw['regardingobjectid'] || '';
        this.regardingObjectIdValue = raw['regardingobjectidValue'] || '';
        if(raw['indskr_teams_meeting_id']) this.indskr_teams_meeting_id = raw['indskr_teams_meeting_id'] || '';
        if (raw['indskr_teams_meeting_id']) this.indskr_teamslink = raw['indskr_teamslink'] || '';
        this.actualdurationminutes = raw['actualdurationminutes'] || 0;
        this.activityattendeejoiningdetails = raw['activityattendeejoiningdetails'] || [];
        this.indskr_cancellationreasonother = raw['indskr_cancellationreasonother'] || '';
        this.indskr_reasonforcancellation = raw['indskr_reasonforcancellation'] || '';
        this.indskr_reasonforcancellationname = raw['indskr_reasonforcancellationname'] || '';
        this.cancellationreason = raw['cancellationreason'] || '';

        if (raw.hasOwnProperty('configuredFields')) {

          const rawConfigFields = raw['configuredFields'];

          this.indskr_activitytype = rawConfigFields['_indskr_activitytype_value'] ? rawConfigFields['_indskr_activitytype_value'] : this.indskr_activitytype;
          this.indskr_activitysubtype = rawConfigFields['_indskr_activitysubtype_value'] ? rawConfigFields['_indskr_activitysubtype_value'] : this.indskr_activitysubtype;
          this.activityTypeName = rawConfigFields['_indskr_activitytype_value@OData.Community.Display.V1.FormattedValue'] ? rawConfigFields['_indskr_activitytype_value@OData.Community.Display.V1.FormattedValue'] : this.activityTypeName;
          this.activitySubTypeName = rawConfigFields['_indskr_activitysubtype_value@OData.Community.Display.V1.FormattedValue'] ? rawConfigFields['_indskr_activitysubtype_value@OData.Community.Display.V1.FormattedValue'] : this.activitySubTypeName;

          this.appConfigFields = rawConfigFields;
          this.indskr_gpscheckindetailsid = raw['indskr_gpscheckindetailsid'];
        }

        this.covisitorComplianceAcked = raw['indskr_covisitorComplianceAcked'] ?? false;
        this.covisitorComplianceDescription = raw['indskr_covisitorComplianceDescription'] ?? null;

       this.associatedAttachments = raw['activityAttachments'] || [];

       if(raw && raw.hasOwnProperty('activityAttachments')){
         this.associatedAttachments = [];
         raw['activityAttachments'].forEach(item => {
           this.associatedAttachments.push({
             annotationId: item['annotationId'],
             attachmentName: item['attachmentName'],
           })
         });
       }else {
         this.associatedAttachments = [];
       }

        this.associatedOpportunities = raw['activityOpportunities'] || [];

        if(raw && raw.hasOwnProperty('activityOpportunities')){
          this.associatedOpportunities = [];
          raw['activityOpportunities'].forEach(item => {
            this.associatedOpportunities.push({
              opportunityId: item['opportunityId'],
              opportunityName: item['opportunityName'],
            })
          });
        }else {
          this.associatedOpportunities = [];
        }

       this.associatedAccountPlans = raw['activityAccountPlans'] || [];

       if(raw && raw.hasOwnProperty('activityAccountPlans')){
         this.associatedAccountPlans = [];
         raw['activityAccountPlans'].forEach(item => {
           this.associatedAccountPlans.push({
             accountPlanId: item['accountPlanId'],
             accountPlanName: item['accountPlanName'],
           })
         });
       }else {
         this.associatedAccountPlans = [];
       }
       if(raw && raw.hasOwnProperty('gpsCheckInDetails')){
        this.gpsCheckInDetails = {
          indskr_checkinstatus : raw['gpsCheckInDetails'].indskr_checkinstatus,
          indskr_checkindatetime : raw['gpsCheckInDetails'].indskr_checkindatetime,
          indskr_checkoutdatetime : raw['gpsCheckInDetails'].indskr_checkoutdatetime,
          indskr_checkoutstatus : raw['gpsCheckInDetails'].indskr_checkoutstatus,
          indskr_gpscheckindetailsid :raw['gpsCheckInDetails'].indskr_gpscheckindetailsid,
          statuscode :raw['gpsCheckInDetails'].statuscode
        }
      }else{
        this.gpsCheckInDetails = {};
      }

       this.gpsActivityPhotos = raw['gpsActivityPhotos'] || [];
      
       this.associatedEvents = raw['activityEvents'] || [];

       if(raw && raw.hasOwnProperty('activityEvents')){
         this.associatedEvents = [];
         raw['activityEvents'].forEach(item => {
           this.associatedEvents.push({
             eventId: item['eventId'],
             name: item['eventName'],
           })
         });
       }else {
         this.associatedEvents = [];
       }
       this.activityProcedures = raw['activityProcedures'] ? raw['activityProcedures'] : []; 
       if (raw['activitySupportingMaterials'] && Array.isArray(raw['activitySupportingMaterials'])) {
        raw['activitySupportingMaterials'].forEach(sm => {
            this.activitySupportingMaterials.push({
              id: sm['supportingMaterialId'],
              name: sm['supportingMaterialName'],
            });
        });
      } 
       this.activityObjectives = _.orderBy(raw['activityObjectives'], 'indskr_name') || [];

      this.indskr_isparentcall = !!(raw['indskr_isparentcall']);
      this.indskr_parentcallid = raw['indskr_parentcallid'] ?? null;
      this.meetingOutcomeId = raw['caseOutcome'] ?? null;
      this.meetingOutcomeString = raw['caseOutcomeName'] ?? null;
      this.marketingBusinessPlanId = raw['marketingBusinessPlanId'] ?? null;
      this.marketingBusinessPlanName = raw['marketingBusinessPlanName'] ?? null;
      this.indskr_coachingplan = raw['indskr_coachingplan'] ?? null;
      this.indskr_coachingplanname = raw['indskr_coachingplanname'] ?? null;
      this.hasLinkedToMarketingBusinessPlan = raw['hasLinkedToMarketingBusinessPlan'] ?? null;
      this.inStorePhotos = raw['inStorePhotos'] ?? [];
      this.indskr_shortcall = raw['indskr_shortcall'] ?? false;
      this.indskr_kitbooking = raw['indskr_kitbooking'] ?? null;
      this.indskr_meetingdistance = raw['indskr_meetingdistance'] ?? undefined;
      this.indskr_reasonforlocationcapturefailure = raw['indskr_reasonforlocationcapturefailure'] ?? undefined;
      this.indskr_suggestionrescheduled = raw['indskr_suggestionrescheduled'] ?? false;
    }

    getJointMeetingStatus(data: any): boolean {

        let raw:any[];

        if(data["accompaniedUsers"] && Array.isArray(data["accompaniedUsers"])) {
            raw = data["accompaniedUsers"];
        }
        //Changes due to bulk data changes in TC-294, key changes handled at class level
        else if(data["activityAccompaniedUsers"] && Array.isArray(data["activityAccompaniedUsers"])){
            raw = data["activityAccompaniedUsers"];
        }

        if(raw && Array.isArray(raw)) {
            //Accompanied user added, mark as joint meeting
            if(raw.length > 0) {
                return true;
            }
            //No Accompanied user, empty array received.
            else {
                return false;
            }
        }
        // Invalid input not a joint meeting
        else {
          return false;
        }
    }

    get firstPresentation(): Presentation {
        if (this.presentations.length === 0) return undefined;
        return this.getPresentationsFromContent(this.presentations)[0];
    }

    /**
     * Returns a DTO version of our contacts associated with this activity for network payloads
     *
     * @readonly
     * @type {Array<Object>}
     * @memberof AppointmentActivity
     */
    get contactsDTO(): Array<Object> {
      if (_.isEmpty(this.contacts)) return [];
        let contactArray = [];
        this.contacts.map((contact, idx) => {
          let foundcon = this.activityContactSentiments.find(c => c.indskr_contactid == contact.ID);
          let activitycontactId = this._raw_contactAttendees?.find((_raw_contactAttendee) => _raw_contactAttendee.indskr_contactid === contact.ID)?.indskr_activitycontactid;
          // const activityContact:any = this.contactAttendees?.find(c => c.indskr_contactid == contact.ID);
          contactArray.push({
            indskr_contactid: contact.ID,
            indskr_name: contact.fullname,
            indskr_joineddate: contact?.join_date || '',
            indskr_leftdate: contact?.left_date || '',
            indskr_sequence: contact?.indskr_sequence || idx + 1,
            activityKeyMessageSentiments: foundcon ? foundcon.activityKeyMessageSentiments : [],
            indskr_activitycontactid : activitycontactId ?? undefined
          });
        });
      return contactArray;
    }

    /**
     * Returns a DTO version of our associated accounts
     *
     * @readonly
     * @type {Array<Object>}
     * @memberof AppointmentActivity
     */
    get accountsDTO(): Array<Object> {
      if (_.isEmpty(this.accounts)) return [];
        let accountsArray = [];
        
        this.accounts.map(account => {
          let activityAccounttId = this._raw_activityAccounts?.find((_raw_activityAccount) => _raw_activityAccount.indskr_accountid === account.id)?.indskr_activityaccountid;
            accountsArray.push({
                indskr_accountid: account.id,
                indskr_name: account.accountName,
                indskr_activityaccountid :activityAccounttId ?? undefined
            });
        });

        return accountsArray;
    }

    /**
     * Returns a DTO version of our associated products
     *
     * @readonly
     * @type {Array<Object>}
     * @memberof AppointmentActivity
     */
    get productsDTO(): Array<Object> {

      if (_.isEmpty(this.products) && _.isEmpty(this.competitorProducts)) return [];

        let productsArray = [];
        //reset the products array
        let _priority = 1;
        this.products.map((product , index)=> {
            if(!product.isSelected && !product.isGenieSelected) return;
            let activityProductKeyMessages = [];
            if(product.isSelected || product.isGenieSelected){
                product.priority = _priority
                if(product.keyMessages){
                    product.keyMessages.map((keyMsg , index) => {

                            if(!keyMsg.isSelected && !keyMsg.isGenieSelected) return;
                            activityProductKeyMessages.push({
                                indskr_keymessageid: keyMsg.ID,
                                indskr_name : keyMsg.name,
                                indskr_isautomaticallyselected: keyMsg.isAutoSelected,
                                indskr_geneeselected: keyMsg.isGenieSelected
                            });
                    });
                }
                productsArray.push({
                    indskr_productid: product.ID,
                    indskr_isautomaticallyselected: product.isAutoSelected,
                    indskr_geneeselected: product.isGenieSelected,
                    indskr_sequence: _priority,
                    indskr_name: product.name,
                    activityProductKeyMessages: activityProductKeyMessages,
                    type: product['productStructure']
                });
                _priority++;
            }

        });

        this.competitorProducts.forEach(compProd => {
          productsArray.push({
            indskr_productid: compProd.indskr_productid,
            indskr_isautomaticallyselected: compProd.indskr_isautomaticallyselected,
            indskr_geneeselected: compProd.indskr_geneeselected,
            indskr_sequence: compProd.indskr_sequence,
            indskr_name: compProd.productName,
            activityProductKeyMessages: [],
            type: compProd.productStructure,
            isCompetitor: true,
          });
        });

        //console.log("Whats been sent to back end=> " , productsArray);
        return productsArray;
    }

    get proceduresDTO(): Array<Object> {

      if (_.isEmpty(this.activityProcedures)) return [];

      let productsArray = [];
      //reset the products array
      let _priority = 1;
      this.activityProcedures.map((product, index) => {
        productsArray.push({
          indskr_productid: product.ID,
          indskr_isautomaticallyselected: product.isAutoSelected,
          indskr_geneeselected: product.isGenieSelected,
          indskr_sequence: _priority,
          indskr_name: product.name,
          activityProductKeyMessages: [],
          productStructure: product.productStructure ? product.productStructure : 3
        });
        _priority++;
      });
      //console.log("Whats been sent to back end=> " , productsArray);
      return productsArray;
    }

    get activitySupportingMaterialsDTO():Array<Object> {
      if (_.isEmpty(this.activitySupportingMaterials)) return [];
      let materials = [];
      this.activitySupportingMaterials.map((mat) => {
        materials.push({
          supportingMaterialId: mat.id,
          supportingMaterialName: mat.name,
        });
      });
      return materials;
    }

    get presentationsDTO(): Array<Object> {
        let presentationArray = [];

        if (_.isEmpty(this.presentations)) return [];

      presentationArray = this.presentations.map(pres => {
        if (pres) {
          if (pres instanceof Presentation)
            return pres.DTO
          else if (pres instanceof Resource)
            return pres.DTO
          else
            return new Presentation(pres).DTO;
        }
      });
        return presentationArray;
    }

    get activityPresentationsDTO(): Array<Object> {
      if (_.isEmpty(this.activityPresentations)) return [];
      let activityPresentationArray = [];
      activityPresentationArray = this.activityPresentations.map(pres => pres.DTO);
      return activityPresentationArray;
    }

    get activityResourcesDTO(): Array<Object> {
      if (_.isEmpty(this.activityResources)) return [];
        let activityResourceArray = [];
        activityResourceArray = this.activityResources.map(resource => resource.DTO);
        return activityResourceArray;
    }

    get activityTherapeuticAreaDTO(): Array<Object>{
      if (_.isEmpty(this.activityTherapeuticAreas)) return [];
      let activityTherapeuticAreas = [];
      activityTherapeuticAreas = this.activityTherapeuticAreas.map(ta => ta.TherapeuticAreaMeetingDTO);
      return activityTherapeuticAreas;
    }

    get activityDiseaseAreaDTO(): Array<Object>{
      if (_.isEmpty(this.activityDiseaseAreas)) return [];
      let activityDiseaseAreas = [];
      activityDiseaseAreas = this.activityDiseaseAreas.map((obj,idx) => {
        return {
          "indskr_diseaseareaid": obj.indskr_diseaseareaid,
          "indskr_sequence": obj.indskr_sequence ? obj.indskr_sequence : idx+1,
          "indskr_automaticallyselected": obj.indskr_automaticallyselected,
          "indskr_geneeselected": obj.indskr_geneeselected,
          "indskr_name": obj.indskr_name,
        }
      });
      return activityDiseaseAreas;
    }

    get activityProductIndicationDTO(): Array<Object>{
      if (_.isEmpty(this.activityProductIndications)) return [];
      let activityProductIndications = [];
      activityProductIndications = this.activityProductIndications.map((obj,idx) => {
        let pi = {
          "productIndicationId": obj.productIndicationId,
          "indskr_sequence": obj.indskr_sequence ? obj.indskr_sequence : idx+1,
          "indskr_automaticallyselected": obj.indskr_automaticallyselected,
          "indskr_geneeselected": obj.indskr_geneeselected,
          "indskr_name": obj.indskr_name,
          "isSelected" : obj.isSelected,
          "activityProductIndicationKeyMessages": [],
        }
        if(obj.activityProductIndicationKeyMessages){
          obj.activityProductIndicationKeyMessages.forEach((obj2,idx2)=> {
            pi.activityProductIndicationKeyMessages.push({
              "indskr_keymessageid": obj2.indskr_keymessageid,
              "indskr_automaticallyselected": obj2.indskr_automaticallyselected,
              "indskr_name": obj2.indskr_name,
              "isSelected" : obj2.isSelected,
              "indskr_geneeselected": obj2.indskr_geneeselected,
            })
          });
        }
        return pi;
      });
      return activityProductIndications;
    }
  /**
   * Returns a string depending on the current accounts associated with the appointment
   *
   * @readonly
   * @type {string}
   * @memberof AppointmentActivity
   */
  get accountString(): string {
    //Only one contact, return his name
    if (this.accounts.length === 1) {
      let accountString: string = `${this.accounts[0].accountName}`;
      if(this.accounts[0].status === 2 || this.accounts[0].status == 548910003 || this.accounts[0].status == 548910010 || this.accounts[0].status == 548910011 || this.accounts[0].status == 548910012 || this.accounts[0].status == 548910013) {
        return accountString += ' (Inactive)';
      }
      return accountString;
    } else if (this.accounts.length >= 2) {
      //find first alphabetical account
      this.accounts.sort((accountA, accountB): number => {
        if (accountA.accountName > accountB.accountName) return 1;
        if (accountA.accountName < accountB.accountName) return -1;
        return 0;
      });

      let accountString = `${this.accounts[0].accountName}`;
      if (this.accounts[0].status === 2 || this.accounts[0].status == 548910003 || this.accounts[0].status == 548910010 || this.accounts[0].status == 548910011 || this.accounts[0].status == 548910012 || this.accounts[0].status == 548910013) {
        accountString += ` (Inactive) +${this.accounts.length - 1}`;
      } else if (this.accounts.some((acc, index) => {
        if (index == 0) return false;
        return (acc.status === 2 || acc.status == 548910003 || acc.status == 548910010 || acc.status == 548910011 || acc.status == 548910012 || acc.status == 548910013);
      })) {
        accountString = `${this.accounts[0].accountName} +${this.accounts.length - 1} (Inactive)`;
      } else {
        accountString = `${this.accounts[0].accountName} +${this.accounts.length - 1}`;
      }
      return accountString;
    }
    return '';
  }

    /**
     * Returns a string depending on the current contacts associated with the appointment
     *
     * @readonly
     * @type {string}
     * @memberof AppointmentActivity
     */
    get contactString(): string {
        let contactString:string = `${this.isLiveMeet ? 'Participants' : Utility.globalCustomersText}`;

        //find first alphabetical account
        this.contacts.sort((contactA, contactB): number => {
            let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
            let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
            if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
            if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;
            return 0;
        });

        //Only one contact, return his name
        if (this.contacts.length === 1) {
            contactString = `${this.contacts[0].fullName}`;
            if(!this.contacts[0].isActive && String(this.contacts[0].fullName).trim() != ''){
                contactString += ' (Inactive)';
            }
        } else if (this.contacts.length >= 2) {
            contactString =  `${this.contacts[0].fullName}`;
            if(!this.contacts[0].isActive){
                contactString += ` (Inactive) +${this.contacts.length-1}`;
            }else if(this.contacts.some((con,index) =>{
                if(index == 0) return false;
                return (!con.isActive);
            })){
                contactString =  `${this.contacts[0].fullName} +${this.contacts.length-1} (Inactive)`;
            }else{
                contactString =  `${this.contacts[0].fullName} +${this.contacts.length-1}`;
            }

            // if(this.contacts[this.contacts.length - 1] === undefined){
            //     return;
            // }else{
            //     return `${this.contacts[this.contacts.length - 1].fullName} +${this.contacts.length-1}`;
            // }
        }

        if(!contactString || contactString == undefined || contactString == 'undefined ') {
            console.log(this.contacts);
            return "";
        }
        else
        return contactString;
    }

    public assignOfflineAppointment(raw: object) {
        Object.assign(this, raw);
    }

    update(response: object, contacts: Array<Contact>, accounts: Array<Account>, presentations: Array<Presentation | Resource>, products: Array<Brand>, sharedResources: SharedResourceStatus[],therapeuticAreas:Array<any>,
      activityProcedures: Array<Brand>) {
        this.meetingURL = response['indskr_meetingurl'];
        this.notes= response['indskr_notes'] || '';
        this.contacts = contacts ? contacts : [];
        this.accounts = accounts;
        this.presentations = presentations;
        this.products = products;
        this.activityProducts = products;
        this.activityTherapeuticAreas = therapeuticAreas;
        //Update basic fields
        this.location = response['location'];
        this.subject = response['subject'];
        this.actualEnd = response['actualend'];
        this.actualStart = response['actualstart'];
        this.completed = response['omnip_datecompleted'];
        this.omnip_datecompleted = response['omnip_datecompleted'];
        this.sharedResources = sharedResources;
        this.subtype == response['indskr_subtype'];
        this._raw_indskr_accesstoken = response['indskr_accesstoken'];
        this._raw_indskr_positionid = response['indskr_positionid'];
        this.indskr_subtype = response['indskr_subtype'];
        this.indskr_type = response['indskr_type'];
        this.indskr_meetingtype = response['indskr_meetingtype'];
        this._raw_isalldayevent = response['isalldayevent'];
        this._raw_meetingTopicName = response['meetingTopicName'];
        this._raw_activitySharedResources = response['activitySharedResources'] ? response['activitySharedResources'] : undefined;
        this.indskr_activitytype = response['indskr_activitytype'];
        this.indskr_activitysubtype = response['indskr_activitysubtype'];
        this.activityTypeName = response['activityTypeName'] ? response['activityTypeName'] : "";
        this.activitySubTypeName = response['activitySubTypeName'] ? response['activitySubTypeName'] : "";
        this.isRemoteDetailing = response['indskr_isremotedetailing'] || response['isRemoteDetailing'];
        this.covisitorComplianceAcked = response['indskr_covisitorComplianceAcked'] ?? null;
        this.covisitorComplianceDescription = response['indskr_covisitorComplianceDescription'] ?? null;
        this.activityProcedures = activityProcedures;
        this.indskr_isparentcall = response['indskr_isparentcall'] ?? false;
        this.indskr_parentcallid = response['indskr_parentcallid'] ?? null;
        if(response['gpsCheckInDetails']){
          this.gpsCheckInDetails = {
            indskr_checkinstatus:  response['gpsCheckInDetails'].indskr_checkinstatus ?? null,
            indskr_checkindatetime: response['gpsCheckInDetails'].indskr_checkindatetime ?? null,
            indskr_checkoutdatetime: response['gpsCheckInDetails'].indskr_checkoutdatetime ?? null,
            indskr_checkoutstatus:  response['gpsCheckInDetails'].indskr_checkoutstatus ?? null,
            indskr_gpscheckindetailsid:  response['gpsCheckInDetails'].indskr_gpscheckindetailsid ?? null,
            statuscode:  response['gpsCheckInDetails'].statuscode ?? null
          }
        }
        if(response['gpsActivityPhotos']){
          this.gpsActivityPhotos = response['gpsActivityPhotos'];
        }
        this.indskr_meetinglocationlatitude = response['indskr_meetinglocationlatitude'];
        this.indskr_meetinglocationlongitude = response['indskr_meetinglocationlongitude'];
        this.actualdurationminutes = response['actualdurationminutes'] || 0;
        this.activityattendeejoiningdetails = response['activityattendeejoiningdetails'] || [];
        this.inStorePhotos = response['inStorePhotos'] ?? [];
        this.indskr_shortcall = response['indskr_shortcall'] ?? false;
        this.indskr_kitbooking = response['indskr_kitbooking'] ?? null;
        this.indskr_meetingdistance = response['indskr_meetingdistance'] ?? undefined;
        this.indskr_reasonforlocationcapturefailure = response['indskr_reasonforlocationcapturefailure'] ?? undefined;
        this.indskr_suggestionrescheduled = response['indskr_suggestionrescheduled'] ?? false;
    }

    /**
     * Returns an AppointmentActivity DTO for updating details
     *
     * @readonly
     * @type {Object}
     * @memberof AppointmentActivity
     */
    public get DTO(): Object {
        const dto = {
            activityAccounts: this.accountsDTO,
            activityPresentations: this.activityPresentationsDTO,
            activityResources: this.activityResourcesDTO,
            activityTherapeuticAreas: this.activityTherapeuticAreaDTO,
            activityDiseaseAreas: this.activityDiseaseAreaDTO,
            activityProductIndications: this.activityProductIndicationDTO,
            activityPlaylists: this.presentationsDTO,
            activityProducts: this.productsDTO,
            activitytypecode: 'appointment',
            contactAttendees: this.contactsDTO,
            activityAttachments: this.associatedAttachments,
            notes: this.meetingNotes,
            activityOpportunities: this.associatedOpportunities,
            activityAccountPlans: this.associatedAccountPlans,
            activityEvents: this.associatedEvents,
            actualdurationminutes: this.actualdurationminutes,
            activityattendeejoiningdetails: this.activityattendeejoiningdetails,
            indskr_notes: this.notes,
            activityId: this.ID,
            indskr_ownerfullname: this.meetingOwnerName || this.meetingOwner,
            indskr_ownerid: this.meetingOwnerId,
            createdonbehalfby: this.createdonbehalfby,
            createdonbehalfbyName: this.createdonbehalfbyName,
            createdby: this.createdby,
            createdbyName: this.createdbyName,
            indskr_jointmeeting: this.isJointmeeting,
            //Dont even ask.
            activityid: this.ID,
            accountPlanId : this.accountPlanId,
            createdOffline: this.createdOffline,
            indskr_isofflinemeeting: !!this.isOfflineMeeting,
            actualend: '' + this.scheduledEnd.getTime(),
            scheduledend: '' + this.scheduledEnd.getTime(),
            scheduledstart: '' + this.scheduledStart.getTime(),
            actualstart: '' + this.scheduledStart.getTime(),
            indskr_iomeetingtype: 100000000,
            indskr_subtype: 100000001,
            indskr_type: 100000000,
            location: this.location,
            offlineMeetingId: (this.offlineMeetingId ? this.offlineMeetingId : undefined),
            stateCode: this.state,
            statuscode: this.status,
            deleted :this.deleted,
            subject: this.subject,
            indskr_meetingurl: this.meetingURL,
            indskr_meetingtype: this.indskr_meetingtype,
            indskr_activitytype: this.indskr_activitytype,
            indskr_activitysubtype: this.indskr_activitysubtype,
            activityTypeName: this.activityTypeName,
            activitySubTypeName:this.activitySubTypeName,
            regardingobjectid: this.regardingObjectId,
            regardingobjectidValue: this.regardingObjectIdValue,
            appconfiglookupfields: this.getAppConfigLookupFields(this.appconfiglookupfields),
            appconfigfields: this.appConfigFields,
            inStorePhotos: this.inStorePhotos,
            gpsActivityPhotos: this.gpsActivityPhotos,
            indskr_meetingcontentreasonsid: this.indskr_meetingcontentreasonsid,
            activitySharedResources: this.sharedResources.reduce(
                (list, { resource, contact, statuses }) => list.concat(...statuses.map(s=> ({
                  indskr_contactid: contact.ID,
                  indskr_resultstatus: s.status - 1,
                  indskr_resulttime: `${s.date.getTime()}`,
                  indskr_ioresourceid: resource.ioResourceId,
                  indskr_iodocumentid: resource.ioDocumentId,
                }))), []),
            omnip_datecompleted: this.omnip_datecompleted,
            //Added for Joint Meetings
            activityAccompaniedUsers: this.getAccompaniedUser(this.accompaniedUserList),
            indksr_invitesent: this.isInviteSent,
            description: this.description,
            isRemoteDetailing: this.isRemoteDetailing,
            activityProcedures: this.proceduresDTO, 
            activitySupportingMaterials: this.activitySupportingMaterialsDTO,
            indskr_suggest: this.indskr_suggest,
            indskr_reason: this.indskr_reason,
            activityObjectives: this.activityObjectives,
            indskr_selfclaimbyuseronappttimemodification: this.indskr_selfclaimbyuseronappttimemodification,
            gpsCheckInDetails : {
              indskr_checkinstatus :this.gpsCheckInDetails.indskr_checkinstatus ?? null,
              indskr_checkindatetime : this.gpsCheckInDetails?.indskr_checkindatetime,
              indskr_checkoutdatetime : this.gpsCheckInDetails?.indskr_checkoutdatetime,
              indskr_checkoutstatus : this.gpsCheckInDetails.indskr_checkoutstatus ?? null,
              indskr_gpscheckindetailsid : this.gpsCheckInDetails?.indskr_gpscheckindetailsid,
              statuscode : this.gpsCheckInDetails.statuscode ?? null,
            }
        };
        dto["marketingBusinessPlanId"] =  this.marketingBusinessPlanId;
        dto["marketingBusinessPlanName"] = this.marketingBusinessPlanName;
        dto["indskr_coachingplan"] =  this.indskr_coachingplan;
        dto["indskr_coachingplanname"] = this.indskr_coachingplanname;
        dto["marketBusinessPlan"] = this.marketingBusinessPlanId;
        if(this.hasLinkedToMarketingBusinessPlan != null){
          dto["hasLinkedToMarketingBusinessPlan"] = this.hasLinkedToMarketingBusinessPlan;
        }

        if (this.positionId || this._raw_indskr_positionid) {
            dto['indskr_positionid'] = this.positionId || this._raw_indskr_positionid;
        }
        if (this.covisitorComplianceAcked !== null) {
          dto['indskr_covisitorComplianceAcked'] = this.covisitorComplianceAcked;
        }
        if (this.covisitorComplianceDescription !== null) {
          dto['indskr_covisitorComplianceDescription'] = this.covisitorComplianceDescription;
        }
        if (this.indskr_isparentcall) {
          dto['indskr_isparentcall'] = this.indskr_isparentcall;
        }
        if (this.indskr_parentcallid) {
          dto['indskr_parentcallid'] = this.indskr_parentcallid;
        }
        if (this.indskr_shortcall) {
          dto['indskr_shortcall'] = this.indskr_shortcall;
        }
        if (this.indskr_kitbooking) {
          dto['indskr_kitbooking'] = this.indskr_kitbooking;
        }

        if(this.indskr_suggestionrescheduled) {
          dto['indskr_suggestionrescheduled'] = this.indskr_suggestionrescheduled;
        }
        if (this.indskr_meetingdistance) {
          dto['indskr_meetingdistance'] = this.indskr_meetingdistance;
        }
        if (this.indskr_reasonforlocationcapturefailure) {
          dto['indskr_reasonforlocationcapturefailure'] = this.indskr_reasonforlocationcapturefailure;
        }
        dto['indskr_meetinglocationlatitude'] = this.indskr_meetinglocationlatitude;
        dto['indskr_meetinglocationlongitude'] = this.indskr_meetinglocationlongitude;


        if(this.meetingOutcomeId){
          dto['caseOutcome'] = this.meetingOutcomeId;
          dto['indskr_caseoutcome'] = this.meetingOutcomeId;
        }
        if(this.meetingOutcomeString){
          dto['caseOutcomeName'] = this.meetingOutcomeString;
        }
        if (this.indskr_reasonforcancellation) {
          dto["indskr_reasonforcancellation"] = this.indskr_reasonforcancellation;
        }
  
        if(this.indskr_cancellationreasonother){
          dto ['indskr_cancellationreasonother'] = this.indskr_cancellationreasonother;
        }
        return dto;
    }

    public get uniqueSharedResources(): Resource[] {
      if (_.isEmpty(this.sharedResources)) {
        return [];
      } else {
        return [...new Set(this.sharedResources.map(sr=>sr.resource))];
      }
    }

  getAppConfigLookupFields(raw: UpdateTypeAndSubTypeActivityPayLoad[]): any[] {
    if (_.isEmpty(raw)) return [];
      let res: any[]=[];
      raw.map(e => {res.push(
        {
            "name": e.name,
            'pluralEntityName': e.pluralEntityName,
            'entity': e.entity,
            'id': e.id,
        }
    )});
      return res;
  }

  getAppConfigFields(raw: ConfiguredFields[]): any[] {
    if (_.isEmpty(raw)) return [];
       let res: any[]=[];
       raw.map( e => {
        if (e.datatype) {
          res.push(
            {
             "fieldname": e.fieldName,
             'datatype': e.datatype,
             'value': e.value,
           });
        } else if (e.fieldType) {
          res.push(
            {
             "fieldname": e.fieldName,
             'datatype': e.fieldType,
             'value': e.value,
           });
        } else {
          console.error('Datatype/Fieldtype is missing for the ConfiguredField:', e.fieldName);
        }
       });
       return res;
   }

    public get bulkDTO(): Object {
        let dto = {
            activityid: this.ID,
            activitytypecode: this.type,
            indskr_ownerfullname: this.meetingOwner,
            indskr_ownerid: this.ownerId,
            scheduledend: '' + this.scheduledEnd.getTime(),
            scheduledstart: '' + this.scheduledStart.getTime(),
            satecode: this.state,
            statuscode: this.status,
            subject: this.subject,
        }

        // Doing this because server doesn't send below keys if there's no value.
        if (this.location && this.location !== this.DEFAULT_LOCATION_MESSAGE) {
            dto['location'] = this.location;
        }
        if (this.meetingURL) {
            dto['indskr_meetingurl'] = this.meetingURL;
        }
        if (this._raw_activityPlaylists && this._raw_activityPlaylists.length > 0) {
            dto['activityPlaylists'] = this._raw_activityPlaylists;
        }
        if (this._raw_activityPresentations && this._raw_activityPresentations.length > 0) {
            dto['activityPresentations'] = this._raw_activityPresentations;
        }
        if (this._raw_activityProducts && this._raw_activityProducts.length > 0) {
            dto['activityProducts'] = this._raw_activityProducts;
        }
        if (this._raw_contactAttendees && this._raw_contactAttendees.length > 0) {
            dto['contactAttendees'] = this._raw_contactAttendees;
        }
        if (this._raw_activitySharedResources && this._raw_activitySharedResources.length > 0) {
            dto['activitySharedResources'] = this._raw_activitySharedResources;
        }
        if (this._raw_activitySurveys && this._raw_activitySurveys.length > 0) {
            dto['activitySurveys'] = this._raw_activitySurveys;
        }
        if (this.notes) {
            dto['indskr_notes'] = this.notes;
        }
        dto['omnip_datecompleted'] = this.omnip_datecompleted;
        if (this.indskr_type) {
            dto['indskr_type'] = this.indskr_type;
        }
        if (this.indskr_typeFormattedValue) {
            dto['indskr_typeFormattedValue'] = this.indskr_typeFormattedValue;
        }
        if (this.indskr_subtype) {
            dto['indskr_subtype'] = this.indskr_subtype;
        }
        if (this.indskr_subtypeFormattedValue) {
            dto['indskr_subtypeFormattedValue'] = this.indskr_subtypeFormattedValue;
        }
        if (this.indskr_totreason) {
            dto['indskr_totreason'] = this.indskr_totreason;
        }
        if (this.indskr_totreasonFormattedValue) {
            dto['indskr_totreasonFormattedValue'] = this.indskr_totreasonFormattedValue;
        }

        if (this.activityTypeName) {
          dto['activityTypeName'] = this.activityTypeName;
        }

        if (this.indskr_activitytype) {
          dto['indskr_activitytype'] = this.indskr_activitytype;
        }

        if (this.indskr_activitysubtype) {
          dto['indskr_activitysubtype'] = this.indskr_activitysubtype;
        }

        if (this.activitySubTypeName) {
          dto['activitySubTypeName'] = this.activitySubTypeName;
        }

        if (this.appConfigFields && this.appConfigFields.length) {
          dto['configuredFields'] = this.appConfigFields;
        }
        if (this.indskr_shortcall) {
          dto['indskr_shortcall'] = this.indskr_shortcall;
        }
        if (this.indskr_kitbooking) {
          dto['indskr_kitbooking'] = this.indskr_kitbooking;
        }
        if(this.indskr_suggestionrescheduled) {
          dto['indskr_suggestionrescheduled'] = this.indskr_suggestionrescheduled;
        }
        if (this.indskr_meetingdistance) {
          dto['indskr_meetingdistance'] = this.indskr_meetingdistance;
        }
        if (this.indskr_reasonforlocationcapturefailure) {
          dto['indskr_reasonforlocationcapturefailure'] = this.indskr_reasonforlocationcapturefailure;
        }
        return dto;
    }

    public get detailDTO(): Object {
        let dto = {
            activityAccounts: this.accountsDTO || [],
            activityId: this.ID,
            activityPlaylists: this.presentationsDTO || [],
            activityPresentations: this.activityPresentationsDTO || [],
            activityProducts: this.productsDTO || [],
            activityProcedures : this.proceduresDTO || [],
            activityTherapeuticAreas: this.activityTherapeuticAreaDTO || [],
            activityDiseaseAreas: this.activityDiseaseAreaDTO || [],
            activityProductIndications: this.activityProductIndicationDTO || [],
            activityAttachments: this.associatedAttachments || [],
            notes: this.meetingNotes || [],
            activityOpportunities: this.associatedOpportunities || [],
            activityAccountPlans: this.associatedAccountPlans || [],
            activityEvents: this.associatedEvents || [],
            activitySurveys: this._raw_activitySurveys || [],
            actualend: this.actualEnd,
            actualstart: this.actualStart,
            contactAttendees: this.contactsDTO || [],
            indskr_accesstoken: this._raw_indskr_accesstoken,
            indskr_meetingurl: this.meetingURL,
            indskr_positionid: this._raw_indskr_positionid,
            indskr_subtype: this.indskr_subtype,
            indskr_type: this.indskr_type,
            isalldayevent: this._raw_isalldayevent,
            meetingTopicName: this._raw_meetingTopicName,
            omnip_datecompleted: this.omnip_datecompleted,
            scheduledend: '' + this.scheduledEnd.getTime(),
            scheduledstart: '' + this.scheduledStart.getTime(),
            offlineMeetingId: (this.offlineMeetingId ? this.offlineMeetingId : undefined),
            stateCode: this.state,
            statuscode: this.status,
            subject: this.subject,
            indskr_ownerid : this.meetingOwnerId,
            indskr_ownerfullname: this.meetingOwnerName,
            createdonbehalfby: this.createdonbehalfby,
            createdonbehalfbyName: this.createdonbehalfbyName,
            createdby: this.createdby,
            createdbyName: this.createdbyName,
            regardingobjectid: this.regardingObjectId,
            regardingobjectidValue: this.regardingObjectIdValue,
            activitySharedResources: this.sharedResources.reduce(
                (list, { resource, contact, statuses }) => list.concat(...statuses.map(s=> ({
                  indskr_contactid: contact.ID,
                  indskr_resultstatus: s.status - 1,
                  indskr_resulttime: `${s.date.getTime()}`,
                  indskr_ioresourceid: resource.ioResourceId,
                  indskr_iodocumentid: resource.ioDocumentId,
                }))), []),
            //Added for Joint Meetings
            activityAccompaniedUsers: this.getAccompaniedUser(this.accompaniedUserList),
            activitytypecode: 'appointment',
            indskr_jointmeeting: this.isJointmeeting,
            createdOffline: this.createdOffline,
            indskr_iomeetingtype: 100000000,
            indskr_meetingtype: this.indskr_meetingtype,
            indskr_invitesent: this.isInviteSent,
            description: this.description,
            isRemoteDetailing: this.isRemoteDetailing,
            indskr_teamslink: this.indskr_teamslink,
            indskr_teams_meeting_id: this.indskr_teams_meeting_id,
            actualdurationminutes: this.actualdurationminutes,
            activityattendeejoiningdetails: this.activityattendeejoiningdetails,
            activityObjectives: this.activityObjectives,
            indskr_suggest: this.indskr_suggest,
            indskr_reason: this.indskr_reason,
            indskr_meetingcontentreasonsid: this.indskr_meetingcontentreasonsid,
            indskr_selfclaimbyuseronappttimemodification: this.indskr_selfclaimbyuseronappttimemodification,
            gpsCheckInDetails : {
              indskr_checkinstatus :this.gpsCheckInDetails?.indskr_checkinstatus,
              indskr_checkindatetime : this.gpsCheckInDetails?.indskr_checkindatetime,
              indskr_checkoutdatetime : this.gpsCheckInDetails?.indskr_checkoutdatetime,
              indskr_checkoutstatus : this.gpsCheckInDetails?.indskr_checkoutstatus,
              indskr_gpscheckindetailsid : this.gpsCheckInDetails?.indskr_gpscheckindetailsid,
              statuscode : this.gpsCheckInDetails?.statuscode,
            },
            inStorePhotos: this.inStorePhotos,
            gpsActivityPhotos: this.gpsActivityPhotos,
            modified: this.modified
        }

        if (this.positionId || this._raw_indskr_positionid) {
            dto['indskr_positionid'] = this.positionId || this._raw_indskr_positionid;
        }

        // Doing this because server doesn't send below keys if there's no value.
        if (this.notes) {
            dto['indskr_notes'] = this.notes;
        }
        if (this.location && this.location !== this.DEFAULT_LOCATION_MESSAGE) {
            dto['location'] = this.location;
        }
        if (this.indskr_typeFormattedValue) {
            dto['indskr_typeFormattedValue'] = this.indskr_typeFormattedValue;
        }
        if (this.indskr_subtypeFormattedValue) {
            dto['indskr_subtypeFormattedValue'] = this.indskr_subtypeFormattedValue;
        }
        if (this.indskr_totreason) {
            dto['indskr_totreason'] = this.indskr_totreason;
        }
        if (this.indskr_totreasonFormattedValue) {
            dto['indskr_totreasonFormattedValue'] = this.indskr_totreasonFormattedValue;
        }

        if (this.activityTypeName) {
          dto['activityTypeName'] = this.activityTypeName;
        }

        if (this.indskr_activitytype) {
          dto['indskr_activitytype'] = this.indskr_activitytype;
        }

        if (this.indskr_activitysubtype) {
          dto['indskr_activitysubtype'] = this.indskr_activitysubtype;
        }

        if (this.activitySubTypeName) {
          dto['activitySubTypeName'] = this.activitySubTypeName;
        }

        if (this.appConfigFields && this.appConfigFields.length) {
          dto['configuredFields'] = this.appConfigFields;
        }
        if (this.covisitorComplianceAcked !== null) {
          dto['indskr_covisitorComplianceAcked'] = this.covisitorComplianceAcked;
        }
        if (this.covisitorComplianceDescription !== null) {
          dto['indskr_covisitorComplianceDescription'] = this.covisitorComplianceDescription;
        }
        if (this.indskr_isparentcall) {
          dto['indskr_isparentcall'] = this.indskr_isparentcall;
        }
        if (this.indskr_parentcallid) {
          dto['indskr_parentcallid'] = this.indskr_parentcallid;
        }

        if(this.indskr_meetinglocationlatitude){
          dto['indskr_meetinglocationlatitude'] = this.indskr_meetinglocationlatitude;
        }
        
        if(this.indskr_meetinglocationlongitude){
          dto['indskr_meetinglocationlongitude'] = this.indskr_meetinglocationlongitude;
        }

        if(this.meetingOutcomeId){
          dto['caseOutcome'] = this.meetingOutcomeId;
        }

        if(this.meetingOutcomeString){
          dto['caseOutcomeName'] = this.meetingOutcomeString;
        }

        dto['marketingBusinessPlanId'] = this.marketingBusinessPlanId;
        dto['marketingBusinessPlanName'] = this.marketingBusinessPlanName;
        dto['hasLinkedToMarketingBusinessPlan'] = this.hasLinkedToMarketingBusinessPlan;
        dto['indskr_coachingplan'] = this.indskr_coachingplan;
        dto['indskr_coachingplanname'] = this.indskr_coachingplanname;   
        
        if (this.indskr_reasonforcancellation) {
          dto["indskr_reasonforcancellation"] = this.indskr_reasonforcancellation;
        }
  
        if(this.indskr_cancellationreasonother){
          dto ['indskr_cancellationreasonother'] = this.indskr_cancellationreasonother;
        }

        if (this.indskr_shortcall) {
          dto['indskr_shortcall'] = this.indskr_shortcall;
        }
        if (this.indskr_kitbooking) {
          dto['indskr_kitbooking'] = this.indskr_kitbooking;
        }
        if(this.indskr_suggestionrescheduled) {
          dto['indskr_suggestionrescheduled'] = this.indskr_suggestionrescheduled;
        }
        if (this.indskr_meetingdistance) {
          dto['indskr_meetingdistance'] = this.indskr_meetingdistance;
        }
        if (this.indskr_reasonforlocationcapturefailure) {
          dto['indskr_reasonforlocationcapturefailure'] = this.indskr_reasonforlocationcapturefailure;
        }
        return dto;
    }

    public fetchUserList(data: any): AccompainedUser[] {
        let raw:any[];

        if(data["accompaniedUsers"] && Array.isArray(data["accompaniedUsers"])) {
            raw = data["accompaniedUsers"];
        }
        //Changes due to bulk data changes in TC-294, key changes handled at class level
        else if(data["activityAccompaniedUsers"] && Array.isArray(data["activityAccompaniedUsers"])){
            raw = data["activityAccompaniedUsers"];
        }

        let userList: AccompainedUser[]= [];
        if(raw != undefined){
            raw.map(e=> userList.push(new AccompainedUser(e, true)));
        }
        return userList;
    }

    fetchUserString(data:any): string {

        let raw:any[];

        if(data["accompaniedUsers"] && Array.isArray(data["accompaniedUsers"])) {
            raw = data["accompaniedUsers"];
        }
        //Changes due to bulk data changes in TC-294, key changes handled at class level
        else if(data["activityAccompaniedUsers"] && Array.isArray(data["activityAccompaniedUsers"])){
            raw = data["activityAccompaniedUsers"];
        }

        let userString: string= "";
        if(raw != undefined){
            //For 1 or less users
            if(raw.length <= 1) {
                let names = raw.map(e=> e.userfullname);
                userString = names.join(",");
            }
            else {
                //First element and the number of other inputs
                userString = raw[0].userfullname +" +" +(raw.length - 1);
            }
        }
        return userString;
    }

    getAccompaniedUser(raw:AccompainedUser[]): any[] {
        let res: any[]=[];
        raw.map(e => {res.push(
            {
                "indskr_user": e.id,
                'userfullname': e.name,
                'indskr_joinstatus': e.remoteMeetingJoinStatus,
            }
        )});
        return res;
    }

    private getPresentationsFromContent(contents: (Presentation | Resource)[]) {
      if (_.isEmpty(contents)) return [];
      const presentations =  contents.filter(content => content instanceof Presentation).map(content => <Presentation>content);
      return presentations? presentations: [];
    }

    public get isReopened(): boolean {
      return (this.state === 0 || Number(this.state) === 0) && !_.isEmpty(this.omnip_datecompleted);
    }
  
  public get contactAttendees() {
    return this._raw_contactAttendees;
  }
}

export class LiveMeetActivity extends AppointmentActivity {
    public eventId: string;
    constructor(raw:Object) {
        super(raw);
        this.eventId = raw['eventId'];
    }
    public get DTO(): Object {
        return {
            ...super.DTO,
            eventId: this.eventId,
        }
    }
}

export enum CHECKOUT_OPTIONS  {
  CHECKOUT_COMPLETE = 1,
  CHECKOUT = 2,
  CANCEL = 0
}

export class ActivityCompetitorProduct {
  indskr_activityproductid:string;
  indskr_geneeselected:boolean;
  indskr_isautomaticallyselected:boolean;
  indskr_productid:string;
  indskr_sequence:number;
  productName:string;
  productStructure:number;
  isCompetitor:boolean;
  constructor(raw){
    this.indskr_activityproductid = raw['indskr_activityproductid'] ? raw['indskr_activityproductid'] : '';
    this.indskr_geneeselected = raw['indskr_geneeselected'] ? raw['indskr_geneeselected'] : false;
    this.indskr_isautomaticallyselected = raw['indskr_isautomaticallyselected'] ? raw['indskr_isautomaticallyselected'] : false;
    this.indskr_productid = raw['indskr_productid'] ? raw['indskr_productid'] : '';
    this.indskr_sequence = raw['indskr_sequence'] ? raw['indskr_sequence'] : 1;
    this.productName = raw['productName'] ? raw['productName'] : '';
    this.productStructure = raw['productStructure'] ? raw['productStructure'] : null;
    this.isCompetitor = raw['isCompetitor'] ? raw['isCompetitor'] : false;
  }
}

export interface InStorePhoto {
  isSelected?: boolean;
  indskr_photoattachmentid: string;
  indskr_photoorigin: PhotoOrigin;
  name: string;
  indskr_photourl: string;
  createdOn: string;
}

export enum CovisitorAccess {
  READ_ACCESS = 548910000,
  MINIMUM_ACCESS = 548910001,
  FULL_ACCESS = 548910002
}
