<!-- <ion-item *ngIf="viewData && !viewData.isHidden" [ngClass]="(isInvalidValue)?'invalid-input-value':''"> -->
<!-- label for the field -->
<!-- <ion-label class='form-field-header-label' position="fixed">
        {{viewData.label}}
        <span *ngIf="viewData.secondaryInfoLabel" class="form-field-secondary-label">
        <p>{{viewData.secondaryInfoLabel}}</p>
      </span>
    </ion-label> -->
<!-- text value of the field for readonly type fields-->
<!-- <div slot="end" item-content text-wrap class="form-field-text ion-float-right ion-margin-end" [ngClass]="(viewData.isReadOnly || !editingInput)?'':'hide-content'" (click)="onInputTextClick($event)" tappable>
        <span *ngIf="viewData.inputText" class="form-field-user-input-text">
            {{viewData.inputText}}
          </span>
        <span *ngIf="!viewData.inputText && (viewData.placeholderLabel || viewData.customPlaceholderLabel)" class="" [ngClass]="viewData.formFieldType === formFieldType.DATA_SUBMITTED ? 'form-field-placeholder-text-data-submitted' : 'form-field-placeholder-text'">
            {{formFieldPlaceholder}}
          </span>
        <a *ngIf="viewData.phoneIcon && viewData.inputText && !viewData.isDisabled" class="align-img" [href]="'tel:'+viewData.inputText"><img src="../../../assets/imgs/phone_icon.svg" alt="phone"></a>
        <a *ngIf="viewData.phoneIcon && (!viewData.inputText || viewData.isDisabled)" class="align-img-disabled"><img src="../../../assets/imgs/phone-call.png" alt="phone"></a>
    </div> -->
<!-- text input editing area for the fields-->
<!-- <ion-input #input *ngIf="!viewData.isReadOnly && editingInput," class='form-field-input' [class.placeholder-text]="!viewData.inputText || viewData.inputText == ''" type="{{(viewData.inputType?viewData.inputType:'text')}}" [(ngModel)]="viewData.inputValue"
        [readonly]="viewData.isReadOnly" maxlength="{{viewData.maxLength?viewData.maxLength:200}}" [disabled]="viewData.isDisabled" [placeholder]="formFieldPlaceholder" (ionBlur)="handleMainEvent($event,'input_focus_out')" (ionChange)="handleMainEvent($event,'input_value_change')"
        (keyup.enter)="handleEnterEvent($event)" (keydown)="validateInput($event)">
    </ion-input>
    <ion-icon slot="end" class='arrow-icon' [name]="arrowType" *ngIf="viewData.showArrow" (click)="onInputTextClick($event)"></ion-icon>
</ion-item> -->
<ng-container *ngIf="!isSkeleton; else skeleton;">

<ng-container *ngIf="viewData" [ngSwitch]="viewData.formFieldType">
  <ng-container *ngSwitchCase="'description-and-confirm'">
    <ng-container *ngIf="!viewData.isHidden">
      <p class="description-and-confirm-header" [ngClass]="biggerFontLabel ? 'form-field-label-bigger-text' : 'form-field-label-text'">
        <ion-text>{{viewData.label}}</ion-text>
        <ion-icon class='mandatory-icon' *ngIf="viewData.isRequired"></ion-icon>
        <img class="field-next-icon"  *ngIf="viewData?.isFieldNextIcon && viewData?.imgSrc" src="{{viewData.imgSrc}}"/>
      </p>
      <ion-item
        class="description-and-confirm"
        [ngClass]="viewData && (viewData.isRequired && viewData.isEmptyRequiredField) ? 'form-field-invalid-input-container' : 'form-field-container'"
      >
        <ion-label class="form-field-header-label">
          {{viewData?.inputText}}
        </ion-label>
        <ion-checkbox slot="end" class="ion-float-right" [(ngModel)]="viewData.inputValue" [disabled]="viewData.isDisabled" (ionChange)="onCheckboxChange($event)"></ion-checkbox>
      </ion-item>
      <ion-item *ngIf="viewData && (viewData.isRequired && viewData.isEmptyRequiredField) && viewData.errorMessage" class="message-container">
        <ion-label class="error-message-text">{{viewData.errorMessage}}</ion-label>
      </ion-item>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'toggle'">
    <ion-item class="form-field-container">
      <ion-label class="form-field-header-label">
        <ion-text>{{viewData.label}}</ion-text>
        <ion-icon class='toggle mandatory-icon' *ngIf="viewData.isRequired"></ion-icon>
      </ion-label>
      <ion-toggle slot="end" class="ion-float-right" [(ngModel)]="viewData.inputValue"
        (ionChange)="onToggleChange($event)"></ion-toggle>
    </ion-item>
  </ng-container>
  <ng-container *ngSwitchCase="'check-box'">
    <ion-item class="form-field-container">
      <ion-checkbox slot="start" [(ngModel)]="viewData.inputValue" [disabled]="viewData.isDisabled"
        (ngModelChange)="onCheckboxChange($event)"></ion-checkbox>
      <ion-label class="check-box-label">{{viewData.label}}<span style="color:red">*</span></ion-label>
    </ion-item>
  </ng-container>

  <ng-container *ngSwitchCase="'dropdown-list'">
    <div class="dropdown-container">
      <ion-item class="form-field-container">
  
        <ion-label class='form-field-header-label'>
          <p class='form-field-label-text'>
              <ion-text>{{viewData.label}}</ion-text>
              <ion-icon class='mandatory-icon' *ngIf="viewData.isRequired"></ion-icon>
              <span *ngIf="viewData.primaryTextRightTwo" class="tert-margin-text"
                [ngClass]="viewData.isprimaryTextRightTwoPillType ? 'displayPill': ''">
                {{viewData.primaryTextRightTwo}}
              </span>
          </p>
          <p class="form-field-input expanded-formFieldValue">
              <input #dropinput id="dropinput" type="{{(viewData.inputType?viewData.inputType:'text')}}" placeholder="{{formFieldPlaceholder}}" value="{{viewData.inputValue}}"
              [min]="viewData.inputMin" [max]="viewData.inputMax" [maxLength]="viewData.maxLength ? viewData.maxLength : 524288" class="dropinput" 
              (focus)="openSearch($event)" (input)="openSearch($event)" [ngClass]="viewData.isDisabled ? 'input-disabled' : ''" *ngIf="viewData?.selectedData?.length === 0 || isSearching" autocomplete="off" autocapitalize="off" spellcheck="false">
          </p>
                    
          <div *ngIf="viewData.secondaryInfoText" class='secondary-input-text'><ion-text>{{viewData.secondaryInfoText}}</ion-text></div>

          <div *ngIf="viewData.selectedData?.length > 0 && !isSearching" class="chip-container" (click)="enableSearch()">
  
            <ion-chip class="custom-chip">
              <ion-avatar>
                <img *ngIf="viewData.selectedData[0].image" alt="Silhouette of a person's head" [src]="viewData.selectedData[0].image" />
                <div class="initial-div" *ngIf="!viewData.selectedData[0].image">{{ getInitials(viewData.selectedData[0].title) }}</div>
              </ion-avatar>
              <ion-label>{{viewData.selectedData[0].title }}</ion-label>
              <ion-icon name="closebutton" class="chip-icon" (click)="onChipClick($event, 'closebutton', 0)" [ngClass]="viewData.isDisabled ? 'disabled' : ''"></ion-icon>
            </ion-chip>
    
            <ion-chip class="custom-chip" *ngIf="viewData.selectedData.length === 2">
              <ion-avatar>
                <div class="initial-div" *ngIf="!viewData.selectedData[1].image">{{ getInitials(viewData.selectedData[1].title) }}
                </div>
              </ion-avatar>
              <ion-icon name="chevron-down-outline" size="small" (click)="onChipClick($event, 'view')"></ion-icon>
            </ion-chip>
    
            <ion-chip class="custom-chip" *ngIf="viewData.selectedData.length > 2">
              <ion-label>+{{ viewData.selectedData.length - 1 }}</ion-label>
              <ion-icon name="chevron-down-outline" class="down-icon" size="small" (click)="onChipClick($event ,'view')"></ion-icon>
            </ion-chip>
    
          </div>

      </ion-label>

      <ion-icon *ngFor="let endIcon of viewData.endIcons" [name]="endIcon.iconName" slot="end" size="small" class="list-endicon ion-float-right" (click)="onIconClick(endIcon.iconName)" [ngClass] = "viewData.isDisabled && !endIcon.overRideDisabledProperty ? 'disabled' : ''"></ion-icon>

      <!-- <ion-icon name="searchblue" slot="end" size="small" class="ion-float-right" (click)="enableSearch()" [ngClass] = "viewData.isDisabled ? 'disabled' : ''"></ion-icon>
      <ion-icon name="expandblue" slot="end" size="small" class="ion-float-right" [ngClass] = "viewData.isDisabled ? 'disabled' : ''" (click)="onAdditionalControlClick({ id : 'open-tool' })"></ion-icon> -->
      </ion-item>

      <div class="dropdown-content" *ngIf="shouldShowDropDown()">
        <ind-form-list [showSearch]="false" [searchText]="searchText" [filterMethod]="viewData.filterMethod" [isViewOnly]="isViewOnly"
          [parentInputRef]="dropinput?.nativeElement" [sections]="viewData.sections" (dismiss)="handleData($event)"
          (buttonClick)="dropDownButtonClicked($event)" [isDisabled]="viewData.isDisabled">
        </ind-form-list>
      </div>
    </div>
  </ng-container>



<ng-container *ngSwitchDefault>
<ion-item *ngIf="viewData && !viewData.isHidden && !viewData.isAccordionForm" class="{{viewData.customCssClass?viewData.customCssClass:''}}" [ngClass]="viewData && (isInvalidValue || (viewData.isRequired && viewData.isEmptyRequiredField))?'form-field-invalid-input-container':(isInputInFocus?'form-field-container-in-focus':'form-field-container')">
    <!-- <ion-label position="stacked" class='form-field-header-label'>
    {{viewData.label}}
    <span *ngIf="viewData.secondaryInfoLabel" class="form-field-secondary-label">
      <p>{{viewData.secondaryInfoLabel}}</p>
    </span>
  </ion-label> -->
    <ion-label class='form-field-header-label'>
        <p [ngClass]="biggerFontLabel?'form-field-label-bigger-text':'form-field-label-text'">
            <ion-text>{{viewData.label}}</ion-text>
            <ion-icon class='mandatory-icon' *ngIf="viewData.isRequired"></ion-icon>
            <ion-icon  class="customer-assess-tooltip" *ngIf="viewData.ShowToolTipIcon" slot="end" src="assets/imgs/info_default.svg"  (click)="onClickOfToolTipIcon($event)" ></ion-icon>
            <img class="field-next-icon" *ngIf="viewData?.isFieldNextIcon && viewData?.imgSrc" src="{{viewData.imgSrc}}"/>
        </p>
        <p class='form-field-secondary-label' *ngIf="viewData.secondaryInfoLabel">
            <ion-text>{{viewData.secondaryInfoLabel}}</ion-text>
        </p>
        <div *ngIf="viewData.inputText" class='form-field-value' [ngClass]="(viewData.isReadOnly || !isInputInFocus)?'':'hide-content'" (click)="onInputTextClick($event)" tappable>
            <!-- <ion-text>{{viewData.inputText}}</ion-text> -->
            <!-- <div class="inline" [innerHTML]="linkableText(viewData.inputText)" (click)="openUrl($event,viewData.inputText)"></div> -->
            <div class="inline" [innerHTML]="viewData.inputText | transformLinkableText" (click)="openUrl($event,viewData.inputText)"></div>
            <a *ngIf="viewData?.isTextNextIcon && viewData?.imgSrc"><img class="text-next-icon" src="{{viewData?.imgSrc}}"></a>
            <a *ngIf="viewData.phoneIcon && viewData.inputText && !viewData.isDisabled" class="align-img">
              <span class="phone-timer" *ngIf="viewData.phoneStatus"> {{viewData.phoneStatus}}</span>
              <img [src]="viewData.phoneIcon" alt="phone">
            </a>
            <span *ngIf="viewData.secondaryInputText" class="secondary-text">{{viewData.secondaryInputText}}</span>
        </div>
        <p *ngIf="!viewData.inputText && (viewData.placeholderLabel || viewData.customPlaceholderLabel) && !isInputInFocus" [ngClass]="viewData.formFieldType === formFieldType.DATA_SUBMITTED ? 'form-field-placeholder-text-data-submitted' : 'form-field-placeholder-text'"
            (click)="onInputTextClick($event)" tappable>
            <ion-text>{{formFieldPlaceholder}}</ion-text>
            <a *ngIf="viewData.phoneIcon && (!viewData.inputText || viewData.isDisabled)" class="align-img-disabled"><img src="assets/imgs/phone-call.png" alt="phone"></a>
        </p>
        <p *ngIf="isInputInFocus" class="form-field-input" [ngClass]="viewData.inlineInputCssClass ? viewData.inlineInputCssClass : ''">
            <input #input type="{{(viewData.inputType?viewData.inputType:'text')}}" placeholder="{{formFieldPlaceholder}}" value="{{viewData.inputValue}}" (blur)="handleInputEvent($event,'input_focus_out')" (input)="handleInputEvent($event,'input_value_change')" (keyup.enter)="handleEnterEvent($event)"
                (keydown)="validateInput($event)"
                [min]="viewData.inputMin" [max]="viewData.inputMax" [maxLength]="viewData.maxLength ? viewData.maxLength : 524288"
            >
        </p>
        <div *ngIf="viewData.secondaryInfoText" class='secondary-input-text'><ion-text>{{viewData.secondaryInfoText}}</ion-text></div>
    </ion-label>
    <!-- <div *ngIf="isInputInFocus" [ngClass]="(isInvalidValue)?'form-field-invalid-input-container':(isInputInFocus?'form-field-input-container-in-focus':'form-field-input-container')" class="{{!viewData.inputText?'form-field-placeholder-container':''}}">
    <ion-input #input class='form-field-input' type="{{(viewData.inputType?viewData.inputType:'text')}}" [readonly]="viewData.isReadOnly" [placeholder]="formFieldPlaceholder" [value]="viewData.inputValue" (click)="onInputTextClick($event)"
    (ionBlur)="handleMainEvent($event,'input_focus_out')" (ionChange)="handleMainEvent($event,'input_value_change')" (keyup.enter)="handleEnterEvent($event)" (keydown)="validateInput($event)"></ion-input> -->
    <!-- <ion-icon class='ion-float-right arrow-icon {{arrowType}}' [name]="arrowType" *ngIf="viewData.showArrow" (click)="onInputTextClick($event)"></ion-icon> -->
    <!-- </div> -->
    <ion-icon slot="end" class="ion-float-right arrow-icon {{arrowType}} {{viewData?.cssIcon}}" [ngClass] = "viewData.isDisabled && viewData.infoText ? 'disabled' : ''" [name]="arrowType" [src]="viewData?.imgSrc" *ngIf="viewData.showArrow" (click)="onInputTextClick($event)"></ion-icon>

    <ion-icon slot="end" class="ion-float-right additional-icon arrow-icon" style="margin-right: 0 !important" *ngIf="viewData?.dropdown?.values" name="chevron-down-outline" (click)="showDropdown($event)"></ion-icon>
    <ng-container *ngFor="let control of viewData.additionalcontrols">
      <ion-icon slot="end" class="ion-float-right additional-icon" *ngIf="control.src" [src]="control.src" (click)="onAdditionalControlClick(control)" [ngClass]="control.disabled ? 'disable-additional-controls' : ''"></ion-icon>
      <ion-icon slot="end" class="ion-float-right additional-icon arrow-icon" style="margin-right: 0 !important" *ngIf="!control.src && control.iconName" [name]="control.iconName" (click)="onAdditionalControlClick(control)"></ion-icon>
    </ng-container>
</ion-item>

<ion-item *ngIf="viewData && !viewData.isHidden && viewData.isAccordionForm" class='accordion-form-field'
  [ngClass]="viewData && (isInvalidValue || (viewData.isRequired && viewData.isEmptyRequiredField)) ? 'form-field-invalid-input-container': (isInputInFocus ? 'form-field-container-in-focus': 'form-field-container')">
    <ion-label class='form-field-header-label expanded-form-field-label'>
        <p class='primary-text'>
            <ion-text>{{viewData.label}}</ion-text>
            <ion-icon class='mandatory-icon' *ngIf="viewData.isRequired"></ion-icon>
            <span *ngIf="viewData.primaryTextRightTwo" class="tert-margin-text"
              [ngClass]="viewData.isprimaryTextRightTwoPillType ? 'displayPill': ''">
              {{viewData.primaryTextRightTwo}}
            </span>
        </p>
        <p class='secondary-text' *ngIf="viewData.secondaryInfoLabel">
            <ion-text>{{viewData.secondaryInfoLabel}}</ion-text>
        </p>
        <p class='form-field-value expanded-formFieldValue' [ngClass]="(viewData.isReadOnly || !isInputInFocus)?'':'hide-content'" (click)="onInputTextClick($event)" tappable>
            <ion-text>{{viewData.inputText}}</ion-text>
        </p>
        <p *ngIf="!viewData.inputText && (viewData.placeholderLabel || viewData.customPlaceholderLabel) && !isInputInFocus" class='expanded-formFieldValue' [ngClass]="viewData.formFieldType === formFieldType.DATA_SUBMITTED ? 'form-field-placeholder-text-data-submitted' : 'form-field-placeholder-text'"
            (click)="onInputTextClick($event)" tappable>
            <ion-text>{{formFieldPlaceholder}}</ion-text>
        </p>
        <p *ngIf="isInputInFocus" class="form-field-input expanded-formFieldValue">
            <input #input type="{{(viewData.inputType?viewData.inputType:'text')}}" placeholder="{{formFieldPlaceholder}}" value="{{viewData.inputValue}}" (blur)="handleInputEvent($event,'input_focus_out')" (input)="handleInputEvent($event,'input_value_change')"
            [min]="viewData.inputMin" [max]="viewData.inputMax" [maxLength]="viewData.maxLength ? viewData.maxLength : 524288"
            (keyup.enter)="handleEnterEvent($event)" (keydown)="validateInput($event)">
        </p>
    </ion-label>
    <ion-icon slot="end" class="ion-float-right arrow-icon {{arrowType}}" [ngClass] = "viewData.isDisabled && viewData.infoText ? 'disabled' : ''" [name]="arrowType" *ngIf="viewData.showArrow" (click)="onInputTextClick($event)"></ion-icon>
    
</ion-item>
<ion-item *ngIf="viewData && (isInvalidValue || viewData.isRequired && viewData.isEmptyRequiredField) && viewData.errorMessage" class="message-container">
  <ion-label class="error-message-text">{{viewData.errorMessage}}</ion-label>
</ion-item>
<ion-item *ngIf="viewData && viewData.infoText && viewData.displayInfoText" class="message-container">
  <ion-label class="info-message-text"
  [ngClass]="viewData.infoTextCSSClass ? viewData.infoTextCSSClass : 'defaultInfoTextCSSClass'"
  >{{viewData.infoText}}</ion-label>
</ion-item>
</ng-container>
</ng-container>
</ng-container>

<ng-template #skeleton>
  <ion-item class="form-field-container">
    <ion-label class='form-field-header-label'>
      <p class='form-field-label-text' style="height: 14px;">
        <ion-skeleton-text class="skeleton-text-animated" style="line-height: 12px; margin: 1px 0;"
            [ngStyle]="{ width: skeletonConfig?.labelWidth ? skeletonConfig.labelWidth : defaultLabelWidth }"></ion-skeleton-text>
      </p>
      <div class='form-field-value'>
        <ion-skeleton-text class="skeleton-text-animated" style="line-height: 18px; margin: 0;"
            [ngStyle]="{ width: skeletonConfig?.valueWidth ? skeletonConfig.valueWidth : defaultValueWidth }"></ion-skeleton-text>
      </div>
  </ion-label>
  </ion-item>
</ng-template>
