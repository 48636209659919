import { Component, Input, OnInit, SimpleChanges, OnChanges, EventEmitter, Output, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { cloneDeep } from 'lodash';
export interface BottomButtonConfig {
  exceedMaxResultButton?: {
    id: string;
    label: string;
  };
  lessThanResultButton?: {
    id: string;
    label: string;
  };
}

export interface Section {
  id: string;
  title: string; // Text for the section title
  showCountOnTitle?: boolean; // Whether to show a count next to the title
  maxResultsView?: number; // Maximum number of results to show
  bottomButtonShow?: boolean; // Whether the bottom button is visible
  expand?: boolean; // Whether the section is expanded
  showBottomButton?: boolean; // Whether to display the bottom button
  bottomButtonConfig?: BottomButtonConfig; // Configuration for bottom buttons
  showFilteredData?: boolean; // Whether to show filtered data (specific to "Results")
  data: any[]; // Data for the section
}

@Component({
  selector: 'ind-form-list',
  templateUrl: './ind-form-list.component.html',
  styleUrls: ['./ind-form-list.component.scss']
})
export class IndFormListComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('listSearchInput') listSearchInput!: any;

  @Input() title?: any;
  @Input() showSearch = false;
  @Input() searchInProgress = false;
  @Input() parentInputRef!: HTMLElement;
  @Input() searchInputConfig = { label: 'Search', placeholder: 'Type..' };
  @Input() titleEndIcon = ''
  @Input() searchText = '';
  @Input() sections = [];
  @Input() filterMethod!: (query: string) => any[];
  @Input() isViewOnly = false;
  @Input() isDisabled = false;

  @Output() dismiss = new EventEmitter();
  @Output() mousedown = new EventEmitter<void>();
  @Output() buttonClick = new EventEmitter();

  clonedSections: any[] = [];

  constructor(
    private modalController: ModalController,
    private readonly platform: Platform,
    private elementRef: ElementRef,
    public _cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.clonedSections = cloneDeep(this.sections);
      this.setCustomStyles();
    }

    if(this.isViewOnly){
      const sectionToupdate = this.clonedSections.find((section) => !section.showFilteredData);
      if(sectionToupdate){
        this.sectionExpand(sectionToupdate, true)
      }
    }

    document.addEventListener('click', this.handleOutsideClick, true);
    setTimeout(() => {
      if (this.showSearch) {
        this.listSearchInput.setFocus();
      }
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['sections']) {
      this.clonedSections = cloneDeep(this.sections);
    }

    if (changes['searchText']) {
      this.onSearch(this.searchText)
    }
  }

  onSearch(event: any) {
    if(this.isDisabled) return;
    
    const query = event.trim().toLowerCase() || '';

    if (!query) {
      this.searchInProgress = false;
      this.setSectionData([]);
      return;
    }

    this.searchInProgress = true;

    const filteredData = this.filterMethod(query);
    this.setSectionData(filteredData)

    this.searchInProgress = false;
  }

  setSectionData(dataToUpdate) {
    const sectionToupdate = this.clonedSections.find((section) => section.showFilteredData);
    if (!sectionToupdate) return;
    sectionToupdate.data = [...dataToUpdate];
    if (dataToUpdate.length > 0) {
      this.sectionExpand(sectionToupdate, true)
    } else {
      this.sectionExpand(sectionToupdate, false)
    }
    this._cd.detectChanges();
  }

  sectionExpand(section, flag) {
    this.clonedSections.forEach((section) => section.expand = false);
    section.expand = flag;
    this._cd.detectChanges();
  }

  private handleOutsideClick = (event: MouseEvent) => {
    const clickedInside = this.elementRef?.nativeElement.contains(event.target);
    const clickedInsideInput = this.parentInputRef?.contains(event.target as Node);
    const clickedOnChip = (event.target as HTMLElement).closest('.custom-chip');

    if (!clickedInside && !clickedInsideInput && !clickedOnChip) {
      this.dismiss.emit();
    }
  };

  async onItemClick(data: any) {
    this.dismiss.emit({ event: 'item-click', data });
    const topModal = await this.modalController.getTop();
    if (topModal) {
      this.modalController.dismiss({ event: 'item-click', data });
    }
  }

  async onChipClick(data: any = 0, iconName: string) {
    this.dismiss.emit({ data, event: iconName });
    const topModal = await this.modalController.getTop();
    if (topModal) {
      this.modalController.dismiss({ data, event: iconName });
    }
  }

  setCustomStyles() {
    const modalRef = document.querySelector(
      `ion-modal.auto-height`
    ) as HTMLElement;
    if (!modalRef) return;
    const modalWrapper = modalRef.shadowRoot?.querySelector(
      '.modal-wrapper'
    ) as HTMLElement;

    if (modalWrapper) {
      modalWrapper.style.setProperty('position', 'absolute');
      modalWrapper.style.setProperty('bottom', '0');
      modalWrapper.style.setProperty('border-top-left-radius', '24px');
      modalWrapper.style.setProperty('border-top-right-radius', '24px');
      modalWrapper.style.paddingBottom = '0px';
    }
  }

  getButtonID(section: any): String {
    return section.data.length > section.maxResultsView
      ? section.bottomButtonConfig.exceedMaxResultButton.id
      : section.bottomButtonConfig.lessThanResultButton.id;
  }

  setButtonLabel(section: any) {
    return section.data.length > section.maxResultsView
      ? section.bottomButtonConfig.exceedMaxResultButton?.label
      : section.bottomButtonConfig.lessThanResultButton?.label;
  }

  showBottomButton(section) {
    if(this.searchInProgress) return false;

    if (section.showFilteredData) {
      if (section.data.length === 0) {
        const hasSearchInput =
          (this.showSearch && this.listSearchInput?.value) ||
          (this.parentInputRef instanceof HTMLInputElement && this.parentInputRef.value);
        return hasSearchInput;
      }
  
      // Use >= for filtered data
      return this.shouldShowExceedOrEqualMaxButton(section) || this.shouldShowLessThanMaxButton(section);
    }

    return this.shouldShowExceedMaxButton(section) || this.shouldShowLessThanMaxButton(section);
  }

  shouldShowExceedMaxButton(section) {
    return (
      section.expand &&
      section.data.length > section.maxResultsView &&
      section.bottomButtonConfig.exceedMaxResultButton?.label
    );
  }
  
  shouldShowExceedOrEqualMaxButton(section) {
    return (
      section.expand &&
      section.data.length >= section.maxResultsView &&
      section.bottomButtonConfig.exceedMaxResultButton?.label
    );
  }
  
  shouldShowLessThanMaxButton(section) {
    return (
      section.data.length < section.maxResultsView &&
      section.bottomButtonConfig.lessThanResultButton?.label
    );
  }

  async onButtonClick(section: any) {
    const buttonId = this.getButtonID(section);
    const topModal = await this.modalController.getTop();

    const payload = { id: buttonId };
    if (this.showSearch) {
      payload['text'] = this.listSearchInput.value;
    }

    if (topModal) {
      this.modalController.dismiss(payload);
    } else {
      this.buttonClick.emit(payload);
    }
  }

  getInitials(name) {
    if (!name) return '';
    const parts = name.trim().split(' ');
    if (parts.length === 1) {
      return parts[0].substring(0, 2).toUpperCase();
    }
    return (parts[0].charAt(0) + parts[1].charAt(0)).toUpperCase();
  }

  async onHeaderClick() {
    const topModal = await this.modalController.getTop();

    if (this.showSearch) {
      if (topModal) {
        this.modalController.dismiss({ id: 'view-all', text: this.listSearchInput.value });
      } else {
        this.buttonClick.emit({ id: 'view-all', text: this.listSearchInput.value })
      }
    } else {
      if (topModal) {
        this.modalController.dismiss({ id: 'view-all' });
      } else {
        this.buttonClick.emit({ id: 'view-all' })
      }
    }
  }

  ngOnDestroy() {
    // Remove the event listeners
    document.removeEventListener('click', this.handleOutsideClick, true);
    this.setSectionData([]);
    this.searchText = '';
  }
}