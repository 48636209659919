import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: "highlightMatchedValue"
})
export class HighlightMatchedValuePipe implements PipeTransform{

    constructor(private sanitizer: DomSanitizer) {}

    private escapeRegExp(string: string): string {
      return string.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&');
    }

    transform(text: string, highlightMatchKey: string): SafeHtml {
      if (!highlightMatchKey) return this.sanitizer.bypassSecurityTrustHtml(text);

      const escapedKey = this.escapeRegExp(highlightMatchKey);
      const regex = new RegExp(`(${escapedKey.split('').join('|')})`, 'gi');
      const highlightedText = text.replace(regex, '<strong style="color: #007bff;">$1</strong>');
      
      return this.sanitizer.bypassSecurityTrustHtml(highlightedText);
    }

}
